<template>
  <div class="edit">
    <h1>{{ newModel ? 'Nieuw model' : 'Model ' + data.name }}</h1>

    <form class="default-form edit-form" @submit.prevent="">

      <div class="default-form-field">
        <label class="default-form-field-label" for="name">Naam</label>
        <input type="text" class="default-form-field-input" id="name" v-model.trim="data.name" >
      </div>

      <div class="default-form-field">
        <label class="default-form-field-label" for="type">Type</label>
        <select name="type" id="type" v-model="data.type">
          <option v-for="(type, i) in types" :key="i" :value="type">{{ type.name }}</option>
        </select>
      </div>

      <div class="default-form-field">
        <label class="default-form-field-label" for="infobox">Infobox</label>
        <select name="type" id="type" v-model="data.infobox">
          <option v-for="(infobox, i) in infoboxes" :key="i" :value="infobox">{{ `Nr.${infobox.uiIndex} ${infobox.name}` }}</option>
        </select>
        <label class="default-form-field-label" for="infoboxIsOnline">Infobox Online</label>
        <input class="checkbox" type="checkbox" :id="`trueOnlineInfobox-checkbox`" name="trueOnlineInfobox-checkbox" v-model="data.infoboxIsOnline">
        <label :for="`trueOnlineInfobox-checkbox`" class="checkbox-label">
          <mdicon name="check-bold" class="checkbox-label-icon" :class="data.infoboxIsOnline ? 'checkbox-label-icon-active' : ''" size="15" />
        </label>
      </div>

      <div class="default-form-field">
        <label class="default-form-field-label" for="category">Categorie</label>
        <select name="type" id="category" v-model="data.category">
          <option v-for="(category, i) in categories" :key="i" :value="category">{{ category.name }}</option>
        </select>
      </div>

      <div class="default-form-field">
        <label class="default-form-field-label" for="trueOnline">Online</label>
        <input class="checkbox" type="checkbox" :id="`trueOnline-checkbox`" name="trueOnline-checkbox" v-model="data.online">
        <label :for="`trueOnline-checkbox`" class="checkbox-label">
          <mdicon name="check-bold" class="checkbox-label-icon" :class="data.online ? 'checkbox-label-icon-active' : ''" size="15" />
        </label>
      </div>

      <div class="default-form-field">
        <label class="default-form-field-label" for="maatwerk">Maatwerk</label>
        <input class="checkbox" type="checkbox" :id="`maatwerk-checkbox`" name="maatwerk-checkbox" v-model="data.maatwerk.enabled">
        <label :for="`maatwerk-checkbox`" class="checkbox-label">
          <mdicon name="check-bold" class="checkbox-label-icon" :class="data.maatwerk.enabled ? 'checkbox-label-icon-active' : ''" size="15" />
        </label>
      </div>

      <div v-if="data.maatwerk.enabled">
        <div style="text-align: left; margin-bottom: 8px; margin-left: 32px" >
          <label class="default-form-field-label" for="maatwerkBreedteMin" style="width: 250px; display: inline-block">Minimale breedte</label>
          <input type="number" class="default-form-field-input" id="maatwerkBreedteMin" v-model.number="data.maatwerk.width.min">
        </div>

        <div style="text-align: left; margin-bottom: 8px; margin-left: 32px">
          <label class="default-form-field-label" for="maatwerkBreedteMin" style="width: 250px; display: inline-block">Maximale breedte</label>
          <input type="number" class="default-form-field-input" id="maatwerkBreedteMin" v-model.number="data.maatwerk.width.max">
        </div>

        <div style="text-align: left; margin-bottom: 8px; margin-left: 32px">
          <label class="default-form-field-label" for="maatwerkBreedteMin" style="width: 250px; display: inline-block">Minimale diepte</label>
          <input type="number" class="default-form-field-input" id="maatwerkBreedteMin" v-model.number="data.maatwerk.depth.min">
        </div>

        <div style="text-align: left; margin-bottom: 8px; margin-left: 32px">
          <label class="default-form-field-label" for="maatwerkBreedteMin" style="width: 250px; display: inline-block">Maximale diepte</label>
          <input type="number" class="default-form-field-input" id="maatwerkBreedteMin" v-model.number="data.maatwerk.depth.max">
        </div>
      </div>

      <div class="default-form-field">
        <label class="default-form-field-label" for="standaardmatenDisabled">Standaard maten uit</label>
        <input class="checkbox" type="checkbox" :id="`standaardmatenDisabled-checkbox`" name="standaardmatenDisabled-checkbox" v-model="data.standaardmatenDisabled">
        <label :for="`standaardmatenDisabled-checkbox`" class="checkbox-label">
          <mdicon name="check-bold" class="checkbox-label-icon" :class="data.standaardmatenDisabled ? 'checkbox-label-icon-active' : ''" size="15" />
        </label>
        <label class="default-form-field-label light-grey" for="standaardmatenDisabled">(Vul nog wel minimaal één diepte, en één breedte, stap volledig in)</label>
      </div>

      <div class="default-form-field">
        <label class="default-form-field-label" for="trueOnline">Minimale muur breedte</label>
        <input type="number" class="default-form-field-input" id="minWallWidth" v-model.number="data.wall.minWallWidth" >

        <label class="default-form-field-label" for="needsSpaceForStaander-checkbox">Heeft ruimte nodig voor staander</label>
        <input class="checkbox" type="checkbox" :id="`needsSpaceForStaander`" name="needsSpaceForStaander-checkbox" v-model="data.wall.needsSpaceForStaander">
        <label :for="`needsSpaceForStaander`" class="checkbox-label">
          <mdicon name="check-bold" class="checkbox-label-icon" :class="data.wall.needsSpaceForStaander ? 'checkbox-label-icon-active' : ''" size="15" />
        </label>
      </div>

      <div class="sizes">
        <label class="default-form-field-label" style="margin-bottom: 6px;">Dakafwerkingen:</label>
        <div v-for="(roofFinish, i) in data.roofFinishes" :key="i" class="rooffinish-item">
          <label class="default-form-field-input" :class="textures.filter(t => t.id == roofFinish)[0] ? '' : 'error'">{{textures.filter(t => t.id == roofFinish)[0] ? textures.filter(t => t.id == roofFinish)[0].name : 'ERROR: Texture not Found.'}} {{textures.filter(t => t.id == roofFinish)[0] ? colors.filter(c => c.id == textures.filter(t => t.id == roofFinish)[0].color)[0].name : ''}}</label>
          <button class="default-form-field-button" @mousedown="removeRoofFinish(i)">
            <mdicon name="minus" class="default-form-field-button-icon" size="18" />
          </button>
        </div>
        <select ref="roofFinishSelector" class="default-form-field-input" style="margin-bottom: 10px;">
          <option hidden value="hidden">Selecteer een dakafwerking</option>
          <option v-for="(texture, i) in filteredTextures" :key="i" :value="texture.id">{{texture.name}} {{colors.filter(c => c.id == texture.color)[0].name}}</option>
        </select><button @click="addRoofFinish()" class="default-form-field-input" style="margin-bottom: 10px; cursor: pointer;">Voeg Toe!</button>
      </div>

      <div class="sizes">
        <label class="default-form-field-label" style="margin-bottom: 6px;">Wand afwerkingen:</label>
        <div style="display: grid; gridTemplateColumns: 30px 1fr; gap: 0px">
          <input class="checkbox" type="checkbox" id="userShortName" name="overstekKlosRound" v-model="userShortName">
          <label for="userShortName" class="checkbox-label" style="margin-right: 0px;">
            <mdicon name="check-bold" class="checkbox-label-icon" :class="userShortName ? 'checkbox-label-icon-active' : ''" size="15" />
          </label><label class="default-form-field-label" style="margin-bottom: 6px;">Gebruik korte naam</label>
        </div>

        <div style="display: grid; gridTemplateColumns: 300px 1fr 80px; gap: 0px">

          <div>
            <div style="height: 40px"></div>
            <div v-for="(wallFinish, i) in allWallFinishes" :key="'wallFinishName'+i" class="rooffinish-item" style="margin-top: 6px; margin-bottom: 18px;">
              <label class="breedte-regel-first">{{textures.filter(t => t.id == wallFinish.id)[0].name}}</label>
            </div>
          </div>

          <div style="overflow-x:scroll; max-width: 820px">
            <table class="wallfinishesTable">
              <tr>
                <th v-for="(wallPosition, i) in wallTypesAndsides" :key="'typeHeader'+i" style="text-align: left">{{userShortName ? wallPosition.short : wallPosition.full}}</th>
              </tr>
              <tr v-for="(wallFinish, i) in allWallFinishes" :key="'wallFinish'+i">
                <td v-for="(wallPosition, j) in wallTypesAndsides" :key="'type'+i+'_'+j">
                  <input class="checkbox" type="checkbox" :id="`wallFinishEnabled_${i}_${j}`" name="overstekKlosRound" :checked="data.wallFinishes[wallPosition.id].includes(wallFinish.id)" @change="changeWallfinish($event, wallPosition.id, wallFinish.id)">
                  <label :for="`wallFinishEnabled_${i}_${j}`" class="checkbox-label" style="margin-right: 0px">
                    <mdicon name="check-bold" class="checkbox-label-icon" :class="data.wallFinishes[wallPosition.id].includes(wallFinish.id) ? 'checkbox-label-icon-active' : ''" size="15" />
                  </label>
                </td>
              </tr>
            </table>
          </div>



          <div>
            <div style="height: 40px"></div>
            <div v-for="(wallFinish, i) in allWallFinishes" :key="'wallFinishRemove'+i" class="rooffinish-item texture-order">
              <button class="default-form-field-button texture-order-button" @click="moveTextureUp(i)">
                <mdicon name="chevron-up" class="default-form-field-button-icon" size="25" />
              </button>

              <button class="default-form-field-button texture-order-button" @click="moveTextureDown(i)">
                <mdicon name="chevron-down" class="default-form-field-button-icon" size="25" />
              </button>
            </div>
          </div>

        </div>


        <div style="display: grid; gridTemplateColumns: 300px 1fr 80px; gap: 0px" v-if="data.defaultSizes[0] && data.defaultSizes[0].wall.height">

          <div>
            <div style="height: 40px"></div>
            <div v-for="(wallDimension, i) in wallDimensions" :key="'wallFinishName'+i" class="rooffinish-item" style="margin-top: 6px; margin-bottom: 18px;">
              <label class="breedte-regel-first">{{wallDimension}}</label>
            </div>
          </div>

          <div style="overflow-x:scroll; max-width: 820px">
            <table class="wallfinishesTable">
              <tr>
                <th v-for="(wallPosition, i) in wallTypesAndsidesforDimensions" :key="'typeHeader'+i" style="text-align: left">{{userShortName ? wallPosition.short : wallPosition.full}}</th>
              </tr>
              <tr v-for="(wallDimension, i) in wallDimensions" :key="'wallFinish'+i">
                <td v-for="(wallPosition, j) in wallTypesAndsidesforDimensions" :key="'type'+i+'_'+j">
                  <input type="number" class="default-form-field-input" style="width:50px" v-model.number="data.defaultSizes[0].wall.height.find(height => height.position === wallPosition.id).value">
                </td>
              </tr>
            </table>
          </div>



          <div>
          </div>

        </div>

        
        
        <!-- <select ref="wallFinishSelector" class="default-form-field-input" style="margin-bottom: 10px;">
          <option hidden value="hidden">Selecteer een dakafwerking</option>
          <option v-for="(texture, i) in allWallFinishes" :key="'wallFinishOption'+i" :value="texture.id">{{texture.name}}</option>
        </select><button @click="addWallFinish()" class="default-form-field-input" style="margin-bottom: 10px; cursor: pointer;">Voeg Toe!</button> -->

        

      </div>

      <div class="default-form-field">
        <label class="default-form-field-label" for="overstekKlosZ">Overstekklos afgerond</label>
        <div>
          <input class="checkbox" type="checkbox" id="overstekKlosRound" name="overstekKlosRound" v-model="data.overstekKlosRound">
          <label for="overstekKlosRound" class="checkbox-label" style="margin-right: 0px">
            <mdicon name="check-bold" class="checkbox-label-icon" :class="data.overstekKlosRound ? 'checkbox-label-icon-active' : ''" size="15" />
          </label>
        </div>
      </div>

      <div class="default-form-field">
        <label class="default-form-field-label" style="width: 100%;">Opties:</label>
        <div class="optionslist">
          <div class="optionslist-item">
            <input class="checkbox" type="checkbox" id="fundering" name="fundering" v-model="data.availableOptions.fundering">
            <label for="fundering" class="checkbox-label" style="margin-right: 10px; display: inline-block">
              <mdicon name="check-bold" class="checkbox-label-icon optionslist-checkbox" :class="data.availableOptions.fundering ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>
            <label class="default-form-field-label" for="fundering">Fundering</label>
          </div>

          <div class="optionslist-item">
            <input class="checkbox" type="checkbox" id="dakgoot" name="dakgoot" v-model="data.availableOptions.dakgoot">
            <label for="dakgoot" class="checkbox-label" style="margin-right: 10px; display: inline-block">
              <mdicon name="check-bold" class="checkbox-label-icon optionslist-checkbox" :class="data.availableOptions.dakgoot ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>
            <label class="default-form-field-label" for="dakgoot">Dakgoot</label>
          </div>

          <div class="optionslist-item">
            <input class="checkbox" type="checkbox" id="lichtstraten" name="lichtstraten" v-model="data.availableOptions.lichtstraten">
            <label for="lichtstraten" class="checkbox-label" style="margin-right: 10px; display: inline-block">
              <mdicon name="check-bold" class="checkbox-label-icon optionslist-checkbox" :class="data.availableOptions.lichtstraten ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>
            <label class="default-form-field-label" for="lichtstraten">Lichtstraten</label>
          </div>

          <div class="optionslist-item">
            <input class="checkbox" type="checkbox" id="ledverlichting" name="ledverlichting" v-model="data.availableOptions.ledverlichting">
            <label for="ledverlichting" class="checkbox-label" style="margin-right: 10px; display: inline-block">
              <mdicon name="check-bold" class="checkbox-label-icon optionslist-checkbox" :class="data.availableOptions.ledverlichting ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>
            <label class="default-form-field-label" for="ledverlichting">LED verlichting</label>
          </div>
        </div>
      </div>

      <div class="sizes" v-for="(size, i) in data.defaultSizes" :key="i">
        <div class="sizes-header">
          <span class="sizes-header-name">{{ 'Diepte stap ' + (i+1) }}</span>

          <div class="spacer"></div>

          <button class="default-form-field-button" @mousedown="removeSize(i)">
            <mdicon name="minus" class="default-form-field-button-icon" size="18" />
          </button>

          <input class="checkbox" type="checkbox" :id="`diepte-enabled-checkbox_${i}`" name="trim-checkbox" v-model="size.enabled">
          <label :for="`diepte-enabled-checkbox_${i}`" class="checkbox-label sizes-form-checkbox" style="margin-left: 10px">
            <mdicon name="check-bold" class="checkbox-label-icon" :class="size.enabled ? 'checkbox-label-icon-active' : ''" size="15" />
          </label>

          <button class="default-form-field-button sizes-form-header-button" @click="groupshown[i].diepte = !groupshown[i].diepte">
              <mdicon :name="groupshown[i].diepte ? 'chevron-down' : 'chevron-up'" class="default-form-field-button-icon" size="30" />
          </button>

          

        </div>

        <div v-if="groupshown[i].diepte">


          <div class="sizes-form-header" @click="groupshown[i].afmetingen = !groupshown[i].afmetingen">
            <h1 class="default-form-field-label sizes-form-title">Afmetingen:</h1>
            <button class="default-form-field-button sizes-form-header-button" @click="groupshown[i].afmetingen = !groupshown[i].afmetingen">
              <mdicon :name="groupshown[i].afmetingen ? 'chevron-down' : 'chevron-up'" class="default-form-field-button-icon" size="30" />
            </button>
          </div>
          <div class="sizes-form" :class="groupshown[i].afmetingen ? '' : 'sizes-form-collapsed'">
            <label class="default-form-field-label" for="diepte">Diepte</label>
            <input type="number" class="default-form-field-input BottomM" id="diepte" v-model.trim="size.diepte" >

            <label class="default-form-field-label" for="hoogtePaal">Hoogte paal</label>
            <input type="number" class="default-form-field-input BottomM" id="hoogtePaal" v-model.trim="size.hoogtePaal" >

            <label class="default-form-field-label" for="maximaleMuurBreedte">Maximale muur breedte</label>
            <input type="number" class="default-form-field-input BottomM" id="maximaleMuurBreedte" v-model.trim="size.maximaleMuurBreedte">

            <label class="default-form-field-label" for="staanderDikte">Staander dikte</label>
            <input type="number" class="default-form-field-input BottomM" id="staanderDikte" v-model.trim="size.staanderDikte" >

            <label class="default-form-field-label" for="poerDikte">Poer dikte</label>
            <input type="number" class="default-form-field-input BottomM" id="poerDikte" v-model.trim="size.poer.z" >

            <label class="default-form-field-label" for="poerLength">Poer lengte</label>
            <input type="number" class="default-form-field-input BottomM" id="poerLength" v-model.trim="size.poer.y" >
          </div>

          <div class="sizes-form-header">
            <h1 class="default-form-field-label sizes-form-title">Schoor:</h1>
            <button class="default-form-field-button sizes-form-header-button" @click="groupshown[i].schoor = !groupshown[i].schoor">
              <mdicon :name="groupshown[i].schoor ? 'chevron-down' : 'chevron-up'" class="default-form-field-button-icon" size="30" />
            </button>
            <input class="checkbox" type="checkbox" :id="`schoor-checkbox`+i" name="schoor-checkbox" v-model="size.schoor.enabled">
            <label :for="`schoor-checkbox`+i" class="checkbox-label sizes-form-checkbox">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.schoor.enabled ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>
          </div>
          <div class="sizes-form" :class="groupshown[i].schoor ? '' : 'sizes-form-collapsed'">
            <label class="default-form-field-label" for="schoorSz">Schoor breedte</label>
            <input type="number" class="default-form-field-input" id="schoorSz" v-model.trim="size.schoor.sz" >

            <label class="default-form-field-label" for="schoorSy">Schoor hoogte</label>
            <input type="number" class="default-form-field-input" id="schoorSy" v-model.trim="size.schoor.sy" >

            <label class="default-form-field-label" for="schoorSx">Schoor dikte</label>
            <input type="number" class="default-form-field-input" id="schoorSx" v-model.trim="size.schoor.sx" >

            <label class="default-form-field-label" for="schoorSox">Schoor gat</label>
            <input type="number" class="default-form-field-input BottomM" id="schoorSox" v-model.trim="size.schoor.sox">

            <label class="default-form-field-label" for="schoorSox">Minimale muur breedte</label>
            <input type="number" class="default-form-field-input BottomM" id="schoorSox" v-model.trim="size.schoor.wallMinWidth">
          </div>


          <div class="sizes-form-header">
            <h1 class="default-form-field-label sizes-form-title">Ringbalk:</h1>
            <button class="default-form-field-button sizes-form-header-button" @click="groupshown[i].ringBalk = !groupshown[i].ringBalk">
              <mdicon :name="groupshown[i].ringBalk ? 'chevron-down' : 'chevron-up'" class="default-form-field-button-icon" size="30" />
            </button>
          </div>
          <div class="sizes-form" :class="groupshown[i].ringBalk ? '' : 'sizes-form-collapsed'">
            <label class="default-form-field-label" for="ringBalkY">Ringbalk hoogte</label>
            <input type="number" class="default-form-field-input" id="ringBalkY" v-model.trim="size.ringBalk.y">

            <label class="default-form-field-label" for="ringBalkZ">Ringbalk breedte</label>
            <input type="number" class="default-form-field-input BottomM" id="ringBalkZ" v-model.trim="size.ringBalk.z">
          </div>


          <div class="sizes-form-header">
            <h1 class="default-form-field-label sizes-form-title">Extra ringbalk:</h1>
            <button class="default-form-field-button sizes-form-header-button" @click="groupshown[i].extraRingBalk = !groupshown[i].extraRingBalk">
              <mdicon :name="groupshown[i].extraRingBalk ? 'chevron-down' : 'chevron-up'" class="default-form-field-button-icon" size="30" />
            </button>
            <input class="checkbox" type="checkbox" :id="`extraRingBalk-checkbox`+i" name="extraRingBalk-checkbox" v-model="size.extraRingBalk.enabled">
            <label :for="`extraRingBalk-checkbox`+i" class="checkbox-label sizes-form-checkbox">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.extraRingBalk.enabled ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>
          </div>
          <div class="sizes-form" :class="groupshown[i].extraRingBalk ? '' : 'sizes-form-collapsed'">
            <label class="default-form-field-label" for="gordingVorm" style="margin-right:10px">gebruik gording vorm</label>
            <input class="checkbox" type="checkbox" :id="`gordingVorm`+i" name="boeideel-checkbox" v-model="size.extraRingBalk.gordingVorm">
            <label :for="`gordingVorm`+i" class="checkbox-label sizes-form-checkbox" style="float: none; display: inline-flex; margin-right:40px">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.extraRingBalk.gordingVorm ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>

            <label class="default-form-field-label" for="ringBalkY">Extra ringbalk hoogte</label>
            <input type="number" class="default-form-field-input" id="ringBalkY" v-model.trim="size.extraRingBalk.y">

            <label class="default-form-field-label" for="ringBalkZ">Extra ringbalk breedte</label>
            <input type="number" class="default-form-field-input BottomM" id="ringBalkZ" v-model.trim="size.extraRingBalk.z">

            <label class="default-form-field-label" for="lijstPosition">positie</label>
            <select class="default-form-field-input" @change="size.extraRingBalk.position = $event.target.value">
              <option :selected="!size.extraRingBalk.position" :value="false">Buiten</option>
              <option :selected="size.extraRingBalk.position" :value="true">Binnen</option>
            </select>
          </div>

          <div class="sizes-form-header">
            <h1 class="default-form-field-label sizes-form-title">Ringbalk tussen paal:</h1>
            <button class="default-form-field-button sizes-form-header-button" @click="groupshown[i].ringbalkTussenPaal = !groupshown[i].ringbalkTussenPaal">
              <mdicon :name="groupshown[i].ringbalkTussenPaal ? 'chevron-down' : 'chevron-up'" class="default-form-field-button-icon" size="30" />
            </button>
            <input class="checkbox" type="checkbox" :id="`ringbalkTussenPaal-checkbox`+i" name="ringbalkTussenPaal-checkbox" v-model="size.ringbalkTussenPaal.enabled">
            <label :for="`ringbalkTussenPaal-checkbox`+i" class="checkbox-label sizes-form-checkbox">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.ringbalkTussenPaal.enabled ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>
          </div>
          <div class="sizes-form" :class="groupshown[i].ringbalkTussenPaal ? '' : 'sizes-form-collapsed'">
            <label class="default-form-field-label" for="ringBalkY">Ringbalk tussen paal hoogte</label>
            <input type="number" class="default-form-field-input" id="ringBalkY" v-model.trim="size.ringbalkTussenPaal.y">

            <label class="default-form-field-label" for="ringBalkZ">Ringbalk tussen paal breedte</label>
            <input type="number" class="default-form-field-input BottomM" id="ringBalkZ" v-model.trim="size.ringbalkTussenPaal.z">
          </div>


          <div class="sizes-form-header">
            <h1 class="default-form-field-label sizes-form-title">Gordingen:</h1>
            <button class="default-form-field-button sizes-form-header-button" @click="groupshown[i].gording = !groupshown[i].gording">
              <mdicon :name="groupshown[i].gording ? 'chevron-down' : 'chevron-up'" class="default-form-field-button-icon" size="30" />
            </button>
          </div>
          <div class="sizes-form" :class="groupshown[i].gording ? '' : 'sizes-form-collapsed'">
            <label class="default-form-field-label" for="gordingBalkY">Gordingbalk hoogte</label>
            <input type="number" class="default-form-field-input" id="gordingBalkY" v-model.trim="size.gordingBalk.y">

            <label class="default-form-field-label" for="gordingBalkZ">Gordingbalk breedte</label>
            <input type="number" class="default-form-field-input BottomM" id="gordingBalkZ" v-model.trim="size.gordingBalk.z">

            <label class="default-form-field-label" for="afstandTussenGordingen">Afstand tussen gordingen</label>
            <input type="number" class="default-form-field-input BottomM" id="afstandTussenGordingen" v-model.trim="size.gordingBalk.afstandTussenGordingen">

            <label class="default-form-field-label" for="pos">Verticale positionering</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.gordingBalk.position">

            <label class="default-form-field-label" for="pos">offset x-as</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.gordingBalk.ox">

            <label class="default-form-field-label" for="pos">offset y-as</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.gordingBalk.oy">

            <label class="default-form-field-label" for="pos">verticale lengte</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.gordingBalk.oyl">
          </div>


          <div class="sizes-form-header">
            <h1 class="default-form-field-label sizes-form-title">Ringbalk gording:</h1>
            <button class="default-form-field-button sizes-form-header-button" @click="groupshown[i].ringBalkGording = !groupshown[i].ringBalkGording">
              <mdicon :name="groupshown[i].ringBalkGording ? 'chevron-down' : 'chevron-up'" class="default-form-field-button-icon" size="30" />
            </button>
          </div>
          <div class="sizes-form" :class="groupshown[i].ringBalkGording ? '' : 'sizes-form-collapsed'">
            <label class="default-form-field-label" for="gordingBalkY">Ringbalk gording hoogte</label>
            <input type="number" class="default-form-field-input" id="gordingBalkY" v-model.trim="size.ringBalkGording.y">

            <label class="default-form-field-label" for="gordingBalkZ">Ringbalk gording breedte</label>
            <input type="number" class="default-form-field-input BottomM" id="gordingBalkZ" v-model.trim="size.ringBalkGording.z">

            <label class="default-form-field-label" for="gordingBalkPosition">Verticale positionering</label>
            <input type="number" class="default-form-field-input" id="gordingBalkPosition" v-model.trim="size.ringBalkGording.position">
          </div>


            
          <div class="sizes-form-header">
            <h1 class="default-form-field-label sizes-form-title">Overstekklos:</h1>
            <button class="default-form-field-button sizes-form-header-button" @click="groupshown[i].overstekKlos = !groupshown[i].overstekKlos">
              <mdicon :name="groupshown[i].overstekKlos ? 'chevron-down' : 'chevron-up'" class="default-form-field-button-icon" size="30" />
            </button>
            <input class="checkbox" type="checkbox" :id="`overstekKlos-checkbox`+i" name="overstekKlos-checkbox" v-model="size.overstekKlos.enabled">
            <label :for="`overstekKlos-checkbox`+i" class="checkbox-label sizes-form-checkbox">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.overstekKlos.enabled ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>
          </div>
          <div class="sizes-form" :class="groupshown[i].overstekKlos ? '' : 'sizes-form-collapsed'">

            <label class="default-form-field-label" for="zDik">Overstekklos dikte tussen</label>
            <input type="number" class="default-form-field-input" id="zDik" v-model.trim="size.overstekKlos.zDik">

            <label class="default-form-field-label" for="zDikPaal">Overstekklos dikte paal</label>
            <input type="number" class="default-form-field-input" id="zDikPaal" v-model.trim="size.overstekKlos.zDikPaal">


            
            <label class="default-form-field-label" for="klosVoEnabled" style="margin-right:10px">Klos Voor Aan</label>
              <input class="checkbox" type="checkbox" :id="`klosVoEnabled`+i" name="boeideel-checkbox" v-model="size.overstekKlos.klosVoEnabled">
              <label :for="`klosVoEnabled`+i" class="checkbox-label sizes-form-checkbox" style="float: none; display: inline-flex; margin-right:40px">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.overstekKlos.klosVoEnabled ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>

            <label class="default-form-field-label" for="klosVoEditable" style="margin-right:10px">Klos Voor uitzetbaar</label>
            <input class="checkbox" type="checkbox" :id="`klosVoEditable`+i" name="boeideel-checkbox" v-model="size.overstekKlos.klosVoEditable">
            <label :for="`klosVoEditable`+i" class="checkbox-label sizes-form-checkbox" style="float: none; display: inline-flex; margin-right:40px">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.overstekKlos.klosVoEditable ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>

            <label class="default-form-field-label" for="xBovVo">Klos Voor Lengte Boven</label>
            <input type="number" class="default-form-field-input" id="xBovVo" v-model.trim="size.overstekKlos.xBovVo">

            <label class="default-form-field-label" for="xOndVo">Klos Voor Lengte Onder</label>
            <input type="number" class="default-form-field-input" id="xOndVo" v-model.trim="size.overstekKlos.xOndVo">

            <label class="default-form-field-label" for="yHooVo">Klos Voor Hoogte</label>
            <input type="number" class="default-form-field-input" id="yHooVo" v-model.trim="size.overstekKlos.yHooVo">

            <label class="default-form-field-label" for="yKopVo">Klos Voor Hoogte Kopkant</label>
            <input type="number" class="default-form-field-input" id="yKopVo" v-model.trim="size.overstekKlos.yKopVo">



            <label class="default-form-field-label" for="klosAcEnabled" style="margin-right:10px">Klos Achter Aan</label>
              <input class="checkbox" type="checkbox" :id="`klosAcEnabled`+i" name="boeideel-checkbox" v-model="size.overstekKlos.klosAcEnabled">
              <label :for="`klosAcEnabled`+i" class="checkbox-label sizes-form-checkbox" style="float: none; display: inline-flex; margin-right:40px">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.overstekKlos.klosAcEnabled ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>

            <label class="default-form-field-label" for="klosAcEditable" style="margin-right:10px">Klos Achter uitzetbaar</label>
            <input class="checkbox" type="checkbox" :id="`klosAcEditable`+i" name="boeideel-checkbox" v-model="size.overstekKlos.klosAcEditable">
            <label :for="`klosAcEditable`+i" class="checkbox-label sizes-form-checkbox" style="float: none; display: inline-flex; margin-right:40px">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.overstekKlos.klosAcEditable ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>

            <label class="default-form-field-label" for="xBovAc">Klos Achter Lengte Boven</label>
            <input type="number" class="default-form-field-input" id="xBovAc" v-model.trim="size.overstekKlos.xBovAc">

            <label class="default-form-field-label" for="xOndAc">Klos Achter Lengte Onder</label>
            <input type="number" class="default-form-field-input" id="xOndAc" v-model.trim="size.overstekKlos.xOndAc">

            <label class="default-form-field-label" for="yHooAc">Klos Achter Hoogte</label>
            <input type="number" class="default-form-field-input" id="yHooAc" v-model.trim="size.overstekKlos.yHooAc">

            <label class="default-form-field-label" for="yKopAc">Klos Achter Hoogte Kopkant</label>
            <input type="number" class="default-form-field-input" id="yKopAc" v-model.trim="size.overstekKlos.yKopAc">



            <label class="default-form-field-label" for="klosLiEnabled" style="margin-right:10px">Klos Links Aan</label>
              <input class="checkbox" type="checkbox" :id="`klosLiEnabled`+i" name="boeideel-checkbox" v-model="size.overstekKlos.klosLiEnabled">
              <label :for="`klosLiEnabled`+i" class="checkbox-label sizes-form-checkbox" style="float: none; display: inline-flex; margin-right:40px">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.overstekKlos.klosLiEnabled ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>

            <label class="default-form-field-label" for="klosLiEditable" style="margin-right:10px">Klos Links uitzetbaar</label>
            <input class="checkbox" type="checkbox" :id="`klosLiEditable`+i" name="boeideel-checkbox" v-model="size.overstekKlos.klosLiEditable">
            <label :for="`klosLiEditable`+i" class="checkbox-label sizes-form-checkbox" style="float: none; display: inline-flex; margin-right:40px">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.overstekKlos.klosLiEditable ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>

            <label class="default-form-field-label" for="xBovLi">Klos Links Lengte Boven</label>
            <input type="number" class="default-form-field-input" id="xBovLi" v-model.trim="size.overstekKlos.xBovLi">

            <label class="default-form-field-label" for="xOndLi">Klos Links Lengte Onder</label>
            <input type="number" class="default-form-field-input" id="xOndLi" v-model.trim="size.overstekKlos.xOndLi">

            <label class="default-form-field-label" for="yHooLi">Klos Links Hoogte</label>
            <input type="number" class="default-form-field-input" id="yHooLi" v-model.trim="size.overstekKlos.yHooLi">

            <label class="default-form-field-label" for="yKopLi">Klos Links Hoogte Kopkant</label>
            <input type="number" class="default-form-field-input" id="yKopLi" v-model.trim="size.overstekKlos.yKopLi">



            <label class="default-form-field-label" for="klosReEnabled" style="margin-right:10px">Klos Rechts Aan</label>
              <input class="checkbox" type="checkbox" :id="`klosReEnabled`+i" name="boeideel-checkbox" v-model="size.overstekKlos.klosReEnabled">
              <label :for="`klosReEnabled`+i" class="checkbox-label sizes-form-checkbox" style="float: none; display: inline-flex; margin-right:40px">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.overstekKlos.klosReEnabled ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>

            <label class="default-form-field-label" for="klosReEditable" style="margin-right:10px">Klos Rechts uitzetbaar</label>
            <input class="checkbox" type="checkbox" :id="`klosReEditable`+i" name="boeideel-checkbox" v-model="size.overstekKlos.klosReEditable">
            <label :for="`klosReEditable`+i" class="checkbox-label sizes-form-checkbox" style="float: none; display: inline-flex; margin-right:40px">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.overstekKlos.klosReEditable ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>

            <label class="default-form-field-label" for="xBovRe">Klos Rechts Lengte Boven</label>
            <input type="number" class="default-form-field-input" id="xBovRe" v-model.trim="size.overstekKlos.xBovRe">

            <label class="default-form-field-label" for="xOndRe">Klos Rechts Lengte Onder</label>
            <input type="number" class="default-form-field-input" id="xOndRe" v-model.trim="size.overstekKlos.xOndRe">

            <label class="default-form-field-label" for="yHooRe">Klos Rechts Hoogte</label>
            <input type="number" class="default-form-field-input" id="yHooRe" v-model.trim="size.overstekKlos.yHooRe">

            <label class="default-form-field-label" for="yKopRe">Klos Rechts Hoogte Kopkant</label>
            <input type="number" class="default-form-field-input" id="yKopRe" v-model.trim="size.overstekKlos.yKopRe">

          </div>


          <div class="sizes-form-header">
            <h1 class="default-form-field-label sizes-form-title">Boeideel:</h1>
            <button class="default-form-field-button sizes-form-header-button" @click="groupshown[i].boeideel = !groupshown[i].boeideel">
              <mdicon :name="groupshown[i].boeideel ? 'chevron-down' : 'chevron-up'" class="default-form-field-button-icon" size="30" />
            </button>
          </div>
          <div class="sizes-form" :class="groupshown[i].boeideel ? '' : 'sizes-form-collapsed'">
            <label class="default-form-field-label" for="boeideel">Boeideel aan</label>
            <input class="checkbox" type="checkbox" :id="`boeideel-checkbox`+i" name="boeideel-checkbox" v-model="size.boeideel.enabled">
            <label :for="`boeideel-checkbox`+i" class="checkbox-label sizes-form-checkbox">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.boeideel.enabled ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>

            <label class="default-form-field-label" for="boeideelY">Boeideel hoogte</label>
            <input type="number" class="default-form-field-input" id="boeideelY" v-model.trim="size.boeideel.y">

            <label class="default-form-field-label" for="boeideelZ">Boeideel dikte</label>
            <input type="number" class="default-form-field-input BottomM" id="boeideelZ" v-model.trim="size.boeideel.z">

            <label class="default-form-field-label" for="byl">boeideel hoogte verticaal zaagstuk voorzijde</label>
            <input type="number" class="default-form-field-input BottomM" id="byl" v-model.trim="size.boeideel.byl">

            <label class="default-form-field-label" for="byl">binnenkant boeideel horizontale offset t.o.v. het frame</label>
            <input type="number" class="default-form-field-input BottomM" id="byl" v-model.trim="size.boeideel.horizontaleOffset">

            <label class="default-form-field-label" for="pos">Boeideel positionering</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.boeideel.position">

            <label class="default-form-field-label" for="boeilijst">Boeilijst aan</label>
            <input class="checkbox" type="checkbox" :id="`boeilijst-checkbox`+i" name="boeideel-checkbox" v-model="size.boeideel.lijstEnabled">
            <label :for="`boeilijst-checkbox`+i" class="checkbox-label sizes-form-checkbox">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.boeideel.lijstEnabled ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>

            <label class="default-form-field-label" for="boeilijstY">Boeilijst hoogte</label>
            <input type="number" class="default-form-field-input" id="boeilijstY" v-model.trim="size.boeideel.ly">

            <label class="default-form-field-label" for="boeilijstZ">Boeilijst dikte</label>
            <input type="number" class="default-form-field-input BottomM" id="boeilijstZ" v-model.trim="size.boeideel.lz">

            <label class="default-form-field-label" for="lijstPosition">Boeilijst positie</label>
            <select class="default-form-field-input" @change="changeBoeilijstPosition(i, $event.target.value)">
              <option :selected="!size.lijstPosition" :value="false">Buiten</option>
              <option :selected="size.lijstPosition" :value="true">Binnen</option>
            </select>

            <label class="default-form-field-label" for="dpz">Dekplank breedte</label>
            <input type="number" class="default-form-field-input" id="dpz" v-model.trim="size.boeideel.dpz">

            <label class="default-form-field-label" for="dpy">Dekplank dikte</label>
            <input type="number" class="default-form-field-input BottomM" id="dpy" v-model.trim="size.boeideel.dpy">

            <label class="default-form-field-label" for="dpOffsetKopkant">Dekplank offset kopkant</label>
            <input type="number" class="default-form-field-input BottomM" id="dpOffsetKopkant" v-model.trim="size.boeideel.dpOffsetKopkant">

            <label class="default-form-field-label" for="dpOffsetGootzijde">Dekplank offset gootzijde</label>
            <input type="number" class="default-form-field-input BottomM" id="dpOffsetGootzijde" v-model.trim="size.boeideel.dpOffsetGootzijde">

            <!-- <label class="default-form-field-label" for="nokpositie">Nok positionering</label>
            <input type="number" class="default-form-field-input BottomM" id="nokpositie" v-model.trim="size.boeideel.nokpositie"> -->

            <label class="default-form-field-label" for="douglasPlanken">Douglas Planken</label>
            <input class="checkbox" type="checkbox" :id="`douglasPlanken-checkbox`+i" name="boeideel-checkbox" v-model="size.boeideel.douglasPlanken">
            <label :for="`douglasPlanken-checkbox`+i" class="checkbox-label sizes-form-checkbox">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.boeideel.douglasPlanken ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>
          </div>


          <div class="sizes-form-header">
            <h1 class="default-form-field-label sizes-form-title">Trim:</h1>
            <button class="default-form-field-button sizes-form-header-button" @click="groupshown[i].trim = !groupshown[i].trim">
              <mdicon :name="groupshown[i].trim ? 'chevron-down' : 'chevron-up'" class="default-form-field-button-icon" size="30" />
            </button>
            <input class="checkbox" type="checkbox" :id="`trim-checkbox`+i" name="trim-checkbox" v-model="size.trim.enabled">
            <label :for="`trim-checkbox`+i" class="checkbox-label sizes-form-checkbox">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.trim.enabled ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>
          </div>
          <div class="sizes-form" :class="groupshown[i].trim ? '' : 'sizes-form-collapsed'">
            <label class="default-form-field-label" for="pos">Hoogte</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.trim.y">

            <label class="default-form-field-label" for="pos">Dikte</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.trim.z">

            <label class="default-form-field-label" for="pos">overhang</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.trim.overhang">
          </div>
          <div>
            <div class="sizes-form" :class="groupshown[i].trim ? '' : 'sizes-form-collapsed'">
              <label class="default-form-field-label" for="pos">kleur</label>
              <div>
                <div class="sizes-form" style="grid-template-columns: 80px 1fr 32px; margin-left: 0px;" v-for="(color, c) in size.trim.color" :key="c">
                  <div class="default-form-field-input trimColorBlock" :style="`background-image: url('${previewsServer}/textures/${textures.filter(textures => textures.id === color)[0] ? textures.filter(textures => textures.id === color)[0].url : ''}'`"></div>
                    <label class="default-form-field-input" style="margin-left:10px">{{textures.filter(colors => colors.id === color)[0] ? textures.filter(textures => textures.id === color)[0].name : ''}}</label>
                    <button class="default-form-field-button" @mousedown="removeTrimColor(i,c)">
                      <mdicon name="minus" class="default-form-field-button-icon" size="18" />
                    </button>
                  </div>
                  <div class="sizes-form" style="grid-template-columns: 1fr 32px; margin-left: 0px; margin-bottom:0px;">
                  <select :ref="'trimColorSelector'+i" class="default-form-field-input">
                    <option hidden value="hidden">Selecteer een kleur</option>
                    <option v-for="(color, j) in textures" :key="j" :value="color.id">{{color.name}}</option>
                  </select>
                  <button class="default-form-field-button" @mousedown="addTrimColor(i)">
                    <mdicon name="plus" class="default-form-field-button-icon" size="18" />
                  </button>
                </div>
              </div>
            </div>
            <!--<select class="default-form-field-input" v-model="size.trim.color">
              <option v-for="(color, i) in colors" :key="i" :value="color.id">{{color.name}}</option>
            </select><div class="trimColorBlock" :style="{backgroundColor: colors.filter(color => color.id === size.trim.color)[0]?colors.filter(color => color.id === size.trim.color)[0].hexCode:'#ffffff'}"></div>-->
          </div>
          <div class="sizes-form" :class="groupshown[i].trim ? '' : 'sizes-form-collapsed'">
            <label class="default-form-field-label" for="pos">Positie</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.trim.position">
          </div>


          <div class="sizes-form-header">
            <h1 class="default-form-field-label sizes-form-title">Dak:</h1>
            <button class="default-form-field-button sizes-form-header-button" @click="groupshown[i].dak = !groupshown[i].dak">
              <mdicon :name="groupshown[i].dak ? 'chevron-down' : 'chevron-up'" class="default-form-field-button-icon" size="30" />
            </button>
          </div>
          <div class="sizes-form" :class="groupshown[i].dak ? '' : 'sizes-form-collapsed'">          
            <label class="default-form-field-label" for="pos">Dakbeschot offset</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.dak.offset">
          </div>
          <div class="sizes-form" :class="groupshown[i].dak ? '' : 'sizes-form-collapsed'">          
            <label class="default-form-field-label" for="pos">Dakbeschot overhang</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.dak.overhang">
          </div>
          <div class="sizes-form" :class="groupshown[i].dak ? '' : 'sizes-form-collapsed'">          
            <label class="default-form-field-label" for="pos">Dakbeschot dikte</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.dak.beschotDikte">
          </div>
          <div class="sizes-form" :class="groupshown[i].dak ? '' : 'sizes-form-collapsed'">          
            <label class="default-form-field-label" for="pos">Dakhelling</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.dak.helling">
          </div>
          <div class="sizes-form" :class="groupshown[i].dak ? '' : 'sizes-form-collapsed'">          
            <label class="default-form-field-label" for="pos">Dakpan overhang lengte voorkant</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.dak.dakpanOverhang">
          </div>
          <div class="sizes-form" :class="groupshown[i].dak ? '' : 'sizes-form-collapsed'">          
            <label class="default-form-field-label" for="pos">Dakpan overhang lengte achterkant</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.dak.dakpanOverhangAchter">
          </div>
          <div class="sizes-form" :class="groupshown[i].dak ? '' : 'sizes-form-collapsed'">          
            <label class="default-form-field-label" for="pos">Gording shoor hoek</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.dak.gordingSchoorHoek">
          </div>
          <div class="sizes-form" :class="groupshown[i].dak ? '' : 'sizes-form-collapsed'">          
            <label class="default-form-field-label" for="pos">Gording schoor offset</label>
            <input type="number" class="default-form-field-input" id="pos" v-model.trim="size.dak.gordingSchoorOffset">
          </div>

          <div class="sizes-form-header">
            <h1 class="default-form-field-label sizes-form-title">Dakgoot:</h1>
            <button class="default-form-field-button sizes-form-header-button" @click="groupshown[i].dakgoot = !groupshown[i].dakgoot">
              <mdicon :name="groupshown[i].dakgoot ? 'chevron-down' : 'chevron-up'" class="default-form-field-button-icon" size="30" />
            </button>
            <input class="checkbox" type="checkbox" :id="`dakgoot-checkbox`+i" name="dakgoot-checkbox" v-model="size.dakgoot.enabled">
            <label :for="`dakgoot-checkbox`+i" class="checkbox-label sizes-form-checkbox">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.dakgoot.enabled ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>
          </div>
          <div class="sizes-form" :class="groupshown[i].dakgoot ? '' : 'sizes-form-collapsed'">
            <label class="default-form-field-label" for="ringBalkY">Hoogte</label>
            <input type="number" class="default-form-field-input" id="ringBalkY" v-model.trim="size.dakgoot.y">

            <label class="default-form-field-label" for="ringBalkZ">Afstand tussen frame en goot</label>
            <input type="number" class="default-form-field-input BottomM" id="ringBalkZ" v-model.trim="size.dakgoot.offset">

            <label class="default-form-field-label" for="ringBalkZ" title="De dakgootlengte is gelijk aan de breedte van de dakpannen/dakbeplating.">Dakgootlengte voor 2 regenpijpen</label>
            <input type="number" class="default-form-field-input BottomM" id="ringBalkZ" v-model.trim="size.dakgoot.twoPipesWidth">

            <label class="default-form-field-label" for="ringBalkZ" title="De dakgootlengte is gelijk aan de breedte van de dakpannen/dakbeplating.">Dakgootlengte voor 3 regenpijpen</label>
            <input type="number" class="default-form-field-input BottomM" id="ringBalkZ" v-model.trim="size.dakgoot.threePipesWidth">
          </div>

          <div class="sizes-form-header">
            <h1 class="default-form-field-label sizes-form-title">Wand:</h1>
            <button class="default-form-field-button sizes-form-header-button" @click="groupshown[i].wall = !groupshown[i].wall">
              <mdicon :name="groupshown[i].wall ? 'chevron-down' : 'chevron-up'" class="default-form-field-button-icon" size="30" />
            </button>
          </div>
          <div class="sizes-form" :class="groupshown[i].wall ? '' : 'sizes-form-collapsed'">
            <label class="default-form-field-label" for="ringBalkY">Wand positie</label>
            <select class="default-form-field-input" @change="size.wall.position = $event.target.value">
              <option :selected="size.wall.position !== 'tussen' && size.wall.position !== 'buiten'" hidden :value="'tussen'">Selecteer een positie</option>
              <option :selected="size.wall.position === 'tussen'" :value="'tussen'">Tussen</option>
              <option :selected="size.wall.position === 'buiten'" :value="'buiten'">Buiten</option>
            </select>

            <label class="default-form-field-label" for="ringBalkZ">Wand plank dikte</label>
            <input type="number" class="default-form-field-input BottomM" id="ringBalkZ" v-model.trim="size.wall.wz">
          </div>

          <div class="sizes-form-header">
            <h1 class="default-form-field-label sizes-form-title">Omlijsting palen:</h1>
            <input class="checkbox" type="checkbox" :id="`omlijstingPalen-checkbox`+i" name="trim-checkbox" v-model="size.omlijstingPalen">
            <label :for="`omlijstingPalen-checkbox`+i" class="checkbox-label sizes-form-checkbox" style="margin-right:40px">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.omlijstingPalen ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>
          </div>

          <div class="sizes-form-header">
            <h1 class="default-form-field-label sizes-form-title">Veranda muur:</h1>
            <button class="default-form-field-button sizes-form-header-button" @click="groupshown[i].veranda = !groupshown[i].veranda">
              <mdicon :name="groupshown[i].veranda ? 'chevron-down' : 'chevron-up'" class="default-form-field-button-icon" size="30" />
            </button>
            <input class="checkbox" type="checkbox" :id="`veranda-checkbox`+i" name="veranda-checkbox" v-model="size.veranda.enabled">
            <label :for="`veranda-checkbox`+i" class="checkbox-label sizes-form-checkbox">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.veranda.enabled ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>
          </div>
          <div class="sizes-form" :class="groupshown[i].veranda ? '' : 'sizes-form-collapsed'">
            <label class="default-form-field-label" for="ringBalkY">Hoogte</label>
            <input type="number" class="default-form-field-input" id="ringBalkY" v-model.trim="size.veranda.hoogte">

            <label class="default-form-field-label" for="ringBalkY">Dikte</label>
            <input type="number" class="default-form-field-input" id="ringBalkY" v-model.trim="size.veranda.dikte">

            <label class="default-form-field-label" for="ringBalkY">Extra breedte</label>
            <input type="number" class="default-form-field-input" id="ringBalkY" v-model.trim="size.veranda.extraBreedte">

            <label class="default-form-field-label" for="ringBalkY">Materiaal</label>
            <select v-model="size.veranda.material" class="default-form-field-input item">
              <option v-for="(material, j) in materials" :key="i+'-'+j" :value="material.id">{{material.name}}</option>
            </select>
          </div>

          <div class="sizes-form-header">
            <h1 class="default-form-field-label sizes-form-title" style="text-align: left">Breedtes:</h1>
            <input class="checkbox" type="checkbox" :id="`omlijstingPalen-checkbox`+i" name="trim-checkbox" v-model="size.omlijstingPalen">
            <label :for="`omlijstingPalen-checkbox`+i" class="checkbox-label sizes-form-checkbox" style="margin-right:40px">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="size.omlijstingPalen ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>
          </div>

          <div style="margin-left:15px">

            <div style="margin-left:10px; text-align: left" :style="{display: 'grid', gridTemplateColumns: `590px 435px 110px`, gap: '0px', position: 'relative'}">

              <div>
                <div style="display: grid; gridTemplateColumns: 110px 150px 150px 150px; gap: 10px; position: relative">
                  <label class="breedte-regel-header" for="ingeschakeld">Breedte:</label>
                  <label class="breedte-regel-header" for="ingeschakeld">Type:</label>
                  <label class="breedte-regel-header" for="nVakken">Artikelnummer:</label>
                  <label class="breedte-regel-header" for="nVakken">Aantal vakken:</label>
                </div>

                <div v-for="(breedte, j) in size.breedtes" :key="`leftInput-${i}-${j}`" style="display: grid; gridTemplateColumns: 110px 150px 150px 150px; gap: 10px; position: relative; text-align: left" :style="{marginTop: j ? '60px' : '0px'}">

                  <label type="text" class="breedte-regel-first" :class="wallDistribution[i][j].valid ? '' : 'breedte-regel-unvalid'">{{breedte.breedte}}</label>
                  <select class="default-form-field-input breedte-regel" v-model="breedte.bergingType" style="padding-bottom:0px; padding-top: 0px;">
                    <option hidden value="">Kies een type</option>
                    <option v-for="(type, b) in bergingTypes" :key="`berging_${i}_${j}_${b}`" :value="type.id">{{type.name}}</option>
                  </select>
                  <!-- <input type="text" class="default-form-field-input breedte-regel" v-model="breedte.artikelNummer"> -->
                  <div class="artikelmouseover">
                    <input type="text" class="default-form-field-input breedte-regel" :class="{ 'red-border': showRedBorder }" :value="breedte.artikelNummer" @input="updateArtikelNummer($event, breedte)" @keydown="handleKeydown">
                    <div v-if="showNoSpaceWarning" class="no-space-warning">SPATIE niet toegestaan</div>
                    </div>
                  <input type="number" min="1" class="default-form-field-input breedte-regel" v-model.number="wallDistribution[i][j].amount" @input="addVak($event, i, j)">

                </div>
              </div>



              <div :style="{display: 'grid', gridTemplateColumns: `435pxpx`, gap: '0px', gridTemplateRows: `repeat(${size.breedtes.length}, 90px)`}">

                <div v-for="(breedte, j) in size.breedtes" :key="i+'-'+j" style="text-align: left; margin-bottom:6px;" :style="{display: 'grid', gridTemplateColumns: `repeat(${breedte.wallDivision.length}, 100px)`, gap: '0px', position: 'relative', gridTemplateRows: `34px`}" class="vak-scrollbar">
          
                  <label class="breedte-regel-header" v-for="(vakN, k) in breedte.wallDivision" :key="`label_${i}_${k}`" style="margin-left: 10px;">Vak {{k+1}}</label>

                  <div v-for="(vakInputN, l) in wallDistribution[i][j].amount" :key="`input_${i}_${j}_${l}`">
                    <input type="number" class="default-form-field-input" @input="recalculateVakken(i,j)" v-model.number="breedte.wallDivision[l].width" min="500" :max="size.maximaleMuurBreedte" step=".1" style="width: 50px; border-radius: 5px 0px 0px 5px; margin-left: 10px;">
                    <button @click="setFixed(i, j, l)" class="vak-lock" :class="breedte.wallDivision[l].bergingEnabled ? 'vak-lock-selected' : ''">
                      <mdicon name="check-bold" size="18" style="margin-left:-1px" :class="breedte.wallDivision[l].bergingEnabled ? 'vak-lock-icon-selected' : 'vak-lock-icon'"/>
                    </button>
                  </div>

                </div>

              </div>



              <div>


                <div v-for="(breedte, j) in size.breedtes" :key="`rightButtons-${i}-${j}`" style="display: grid; gridTemplateColumns: 40px 40px 30px; gap: 0px" :style="{marginTop: j ? '59px' : '34px'}">


                  <button class="remove-breedte" @click="copyBreedte(i,j)">
                    <mdicon name="plus" class="default-form-field-button-icon" size="18" style="margin-right:10px"/>
                  </button>
                  <button class="remove-breedte" @click="removeBreedte(i,j)">
                    <mdicon name="minus" class="default-form-field-button-icon" size="18" style="margin-right:10px"/>
                  </button>
                  <div>
                    <input class="checkbox" type="checkbox" :id="`setting-enabled-checkbox_${i}_${j}`" name="trim-checkbox" v-model="breedte.enabled">
                    <label :for="`setting-enabled-checkbox_${i}_${j}`" class="checkbox-label sizes-form-checkbox" style="margin-right: 0px">
                      <mdicon name="check-bold" class="checkbox-label-icon" :class="breedte.enabled ? 'checkbox-label-icon-active' : ''" size="15" />
                    </label>
                  </div>

                  </div>

              </div>

            </div>

            <button class="add-breedte" @click="addBreedte(i)">Voeg een breedte toe!</button>
          </div>

        </div>

      </div>

      <div class="sizes-header">
        <span class="sizes-header-name">Diepte stap toevoegen</span>

        <div class="spacer"></div>

        <button class="default-form-field-button" @click="addSize()">
          <mdicon name="plus" class="default-form-field-button-icon" size="18" />
        </button>
      </div>

      <div class="default-form-button">
        <LoadingButton 
          @click="newModel ? create() : update()" 
          :loading="loading" 
          :disabled="!formValid"
        >
          {{ newModel ? 'Creëren' : 'Update' }}
        </LoadingButton>
      </div>

    </form>
  </div>
</template>

<script>
  import isEqual from 'lodash.isequal'
  import { mapGetters } from 'vuex'
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'
  import { ref } from 'vue'

  export default {
    name: "ModelDetails",

    components: {
      LoadingButton
    },

    data () {
      return {
        id: null,
        loading: false,
        newModel: false,
        data: {
          name: "",
          type: {
            name: "",
            id: null,
          },
          infobox: {
            name: "",
            uiIndex: "",
            id: null,
          },
          infoboxIsOnline: false,
          category: {
            name: "",
            id: null,
          },
          online: false,
          maatwerk: {
            enabled: false,
            width: {
              min: 0,
              max: 0,
            },
            depth: {
              min: 0,
              max: 0,
            },
          },
          roofFinishes: [],
          wallFinishes: {
            wandBuiten: [],
            wandBinnen: [],
            tussenWandLinks: [],
            tussenWandRechts: [],
            topgevelBuiten: [],
            topgevelBinnen: [],
            topgevelLinks: [],
            topgevelRechts: [],
          },
          overstekKlosRound : false,
          availableOptions: {
            fundering: true,
            dakgoot: false,
            lichtstraten: true,
            ledverlichting: true
          },
          wall: {
            minWallWidth: 500,
            needsSpaceForStaander: true
          },
          defaultSizes: []
        },
        model: {},
        widths: [],
        formValid: false,
        filteredTextures: null,
        allWallFinishes: [],
        groupshown: [],
        wallDistribution: [],
        wallTypesAndsides: [
          {id: 'wandBuiten', full: 'Wand Buiten', short: 'WBui'},
          {id: 'wandBinnen', full: 'Wand Binnen', short: 'WBi'},
          {id: 'tussenWandLinks', full: 'Tussenwand Links', short: 'TWL'},
          {id: 'tussenWandRechts', full: 'Tussenwand Rechts', short: 'TWR'},
          {id: 'topgevelBuiten', full: 'Topgevel Buiten', short: 'TGBui'},
          {id: 'topgevelBinnen', full: 'Topgevel Binnen', short: 'TGBi'},
          {id: 'topgevelLinks', full: 'Topgevel Links', short: 'TGL'},
          {id: 'topgevelRechts', full: 'Topgevel Rechts', short: 'TGR'},
        ],
        wallTypesAndsidesforDimensions: [
          {id: 'wandBuiten', full: 'Wand Buiten', short: 'WBui'},
          {id: 'wandBinnen', full: 'Wand Binnen', short: 'WBi'},
          {id: 'tussenWandLinks', full: 'Tussenwand Links', short: 'TWL'},
          {id: 'tussenWandRechts', full: 'Tussenwand Rechts', short: 'TWR'},
        ],
        wallDimensions: [
          'hoogte'
        ],
        userShortName: true,
        previewsServer: import.meta.env.VITE_VUE_APP_SERVER,
      }
    },

    setup() {
    const showRedBorder = ref(false)
    const showNoSpaceWarning = ref(false)
    return { showRedBorder, showNoSpaceWarning }
    },

    methods: {
      
      updateArtikelNummer(event, breedte) {
      const newValue = event.target.value.replace(/\s/g, '')
      if (newValue !== breedte.artikelNummer) {
        breedte.artikelNummer = newValue
      }
    },

      handleKeydown(event) {
        if (event.keyCode === 32) {
          event.preventDefault()
          this.showRedBorder = true
          this.showNoSpaceWarning = true
          setTimeout(() => {
            this.showRedBorder = false
            this.showNoSpaceWarning = false
          }, 2000)
        // console.log('After update:', JSON.stringify(this.data));
        // console.log('Is form valid?', this.formValid);
        // console.log('isGelijk' , JSON.stringify(this.data) === this.formValid)
        }
      },  
      setId () {
        this.id = this.$route.params.id

        if (this.id === 'new') {
          this.newModel = true
        }
      },

      async get () {
        await this.$store.dispatch('getModelsInfo')

        if (!this.newModel) {
          this.model = this.models.find((m) => m.id === this.id)
          this.data = JSON.parse(JSON.stringify(this.model))
        }
        this.data.defaultSizes.forEach(() => {
          this.groupshown.push({
            diepte: true,
            afmetingen: true,
            schoor: false,
            ringBalk: false,
            extraRingBalk: false,
            ringbalkTussenPaal: false,
            gording: false,
            ringBalkGording: false,
            overstekKlos: false,
            boeideel: false,
            trim: false,
            dak: false,
            dakgoot: false,
            wall: false,
            veranda: false,
            breedtes: false,
          })
        })

        this.data.defaultSizes.forEach((diepte) => {
          const depth = []
          diepte.breedtes.forEach((breedte) => {
            depth.push({
              amount: breedte.wallDivision.length,
              valid: true,
            })
          })
          this.wallDistribution.push(depth)
        })

        console.log("wallDistribution", this.wallDistribution)
      },

      addSize () {
        this.groupshown.push({
          diepte: true,
          afmetingen: true,
          schoor: false,
          ringBalk: false,
          extraRingBalk: false,
          ringbalkTussenPaal: false,
          gording: false,
          ringBalkGording: false,
          overstekKlos: false,
          boeideel: false,
          trim: false,
          dak: false,
          dakgoot: false,
          breedtes: [],
        })
        if (this.data.defaultSizes.length > 0) {
          const newDepth = JSON.parse(JSON.stringify(this.data.defaultSizes[this.data.defaultSizes.length - 1]))
          newDepth.breedtes = []
          this.data.defaultSizes.push(newDepth)
        } else {
          this.data.defaultSizes.push({
            enabled: true,
            diepte: 3270,
            hoogtePaal: 2300,
            maximaleMuurBreedte: 3480,
            staanderDikte: 145,
            poer: {
              z: 150,
              y: 600
            },
            schoor: {
                enabled: true,
                sz: 530,
                sy: 530,
                sx: 65,
                sox: 205,
              wallMinWidth: 1500
            },
            ringBalk: {
                y: 145,
                z: 145
            },
            extraRingBalk: {
                enabled: false,
                gordingVorm: false,
                y: 95,
                z: 65,
                position: false
            },
            ringbalkTussenPaal: {
                enabled: false,
                y: 0,
                z: 0
            },
            gordingBalk: {
                y: 145,
                z: 65,
                afstandTussenGordingen: 700,
                position: 2300,
                ox: 0,
                oy: 0,
                oyl: 0
            },
            ringBalkGording: {
                y: 145,
                z: 145
            },
            overstekKlos: {
                enabled: true,
                z: 65,
                zp: 145,
                xb: 220,
                xo: 60,
                y: 145,
                yk: 70,
                position: {
                  left: true,
                  right: true
                },
                zDik: 65,
                zDikPaal: 145,
                klosVoEnabled: true,
                klosVoEditable: false,
                xBovVo: 220,
                xOndVo: 60,
                yHooVo: 145,
                yKopVo: 70,
                klosAcEnabled: true,
                klosAcEditable: false,
                xBovAc: 220,
                xOndAc: 60,
                yHooAc: 145,
                yKopAc: 70,
                klosLiEnabled: true,
                klosLiEditable: false,
                xBovLi: 220,
                xOndLi: 60,
                yHooLi: 145,
                yKopLi: 70,
                klosReEnabled: true,
                klosReEditable: false,
                xBovRe: 220,
                xOndRe: 60,
                yHooRe: 145,
                yKopRe: 70
            },
            boeideel: {
                enabled: true,
                y: 195,
                z: 25,
                position: 66,
                lijstEnabled: true,
                ly: 60,
                lz: 25,
                lijstPosition: false,
                dpy: 0,
                dpz: 0,
                dpOffsetKopkant: 0,
                dpOffsetGootzijde: 0,
                nokpositie: 0,
                byl: 0,
                douglasPlanken: false
            },
            trim: {
                enabled: true,
                y: 40,
                z: 15,
                overhang: 8,
                color: "62d1675487ae1ef94728a5ff",
                position: 0
            },
            dak: {
              offset: 0,
              helling: 0,
              overhang: 0,
              beschotDikte: 18,
              dakpanOverhang: 0,
              gordingSchoorHoek: 0,
              gordingSchoorOffset: 0
            },
            dakgoot: {
              enabled: false,
              y: 0,
              offset: 0,
              twoPipesWidth: 0,
              threePipesWidth: 0
            },
            wall: {
              position: "tussen",
              wz: 18,
              height: [
                {
                  position: 'wandBuiten',
                  value: 2300
                },
                {
                  position: 'wandBinnen',
                  value: 2300
                },
                {
                  position: 'tussenWandLinks',
                  value: 2300
                },
                {
                  position: 'tussenWandRechts',
                  value: 2300
                }
              ]
            },
            omlijstingPalen: false,
            breedtes: [],
          })
          this.wallDistribution.push([])
        }
      },

      removeSize (i) {
        this.data.defaultSizes.splice(i, 1)
        this.groupshown.splice(i, 1)
      },

      addBreedte (i) {
        this.data.defaultSizes[i].breedtes.push({
          breedte: 0,
          bergingType: '',
          artikelNummer: '',
          wallDivision: [],
          enabled: true
        })

        this.wallDistribution[i].push({
          amount: 0,
          valid: false,
        })
      },

      copyBreedte (i, j) {
        this.data.defaultSizes[i].breedtes.push(JSON.parse(JSON.stringify(this.data.defaultSizes[i].breedtes[j])))
        this.wallDistribution[i].push(JSON.parse(JSON.stringify(this.wallDistribution[i][j])))
      },

      async create () {
        this.loading = true
        try {
          await this.$store.dispatch('createModel', this.data)
        } catch (err) {
          console.log('error',err)
          this.loading = false
        }
        this.loading = false
      },

      async update () {
        this.loading = true
        try {
          this.allWallFinishes.forEach((wallFinish, i) => {
            this.textures.find((texture) => texture.id === wallFinish.id).order = i+1
          });
          console.log(JSON.parse(JSON.stringify(this.textures)))
          await this.$store.dispatch('updateTextures', {remove: [], update: this.textures, insert: []})
          await this.$store.dispatch('getTextures')
          this.filterTextures()

          for (let i = 1; i < this.data.defaultSizes.length; i++) {
            this.data.defaultSizes[i].wall.height = this.data.defaultSizes[0].wall.height
          }

          // console.log(this.data.defaultSizes)
          // console.log('this.data.infobox',this.data.infobox)
          await this.$store.dispatch('updateModel', this.data)
          await this.get()
        } catch (err) {
          console.log(err)
          this.loading = false
        }
        this.loading = false
      },

      filterTextures() {
        this.filteredTextures = this.textures.filter(texture => texture.textureType === "62e3e09a990280c3716422dd")
        this.allWallFinishes = this.textures.filter(texture => texture.textureType === "62cd857a7c20bc17b85af2cd")
      },

      addRoofFinish() {
        if(this.$refs.roofFinishSelector.value === "hidden"){return}
        this.data.roofFinishes.push(this.$refs.roofFinishSelector.value)
        this.$refs.roofFinishSelector.value = "hidden"
        console.log(this.data.roofFinishes)
        //this.data.roofFinishes = this.data.roofFinishes
      },

      removeRoofFinish(i) {
        this.data.roofFinishes.splice(i, 1)
        //this.data.roofFinishes = this.data.roofFinishes
      },

      addWallFinish() {
        if(this.$refs.wallFinishSelector.value === "hidden"){return}
        if(this.data.wallFinishes.find(finish => finish.id === this.$refs.wallFinishSelector.value)){return}

        const objectToPush = {
          id: this.$refs.wallFinishSelector.value,
          enabled: {}
        }
        
        this.wallTypesAndsides.forEach(wallType => {
          objectToPush.enabled[wallType.id] = false
        })

        this.data.wallFinishes.push(objectToPush)
        this.$refs.wallFinishSelector.value = "hidden"
      },

      removeWallFinish(i) {
        this.data.wallFinishes.splice(i, 1)
      },

      changeWallfinish(event, positionId, wallFinish) {
        console.log(event.target.checked, positionId, wallFinish)
        if(event.target.checked) {
          console.log(this.data.wallFinishes[positionId])
          this.data.wallFinishes[positionId].push(wallFinish)
        } else {
          this.data.wallFinishes[positionId].splice(this.data.wallFinishes[positionId].indexOf(wallFinish), 1)
        }
        //this.data.wallFinishes[event.index].enabled[event.wallType] = event.value
      },

      addTrimColor(i) {
        let selectedRef = this.$refs['trimColorSelector'+i][0]
        if(selectedRef.value === "hidden"){return}
        this.data.defaultSizes[i].trim.color.push(selectedRef.value)
        selectedRef.value = "hidden"
      },

      removeTrimColor(i, c) {
        this.data.defaultSizes[i].trim.color.splice(c,1)
      },

      changeBoeilijstPosition(i, value) {
        console.log(value)
        console.log(JSON.parse(JSON.stringify(this.data.defaultSizes[0].boeideel)))
        this.data.defaultSizes[i].boeideel.lijstPosition = value=="true"
        console.log(JSON.parse(JSON.stringify(this.data.defaultSizes[0].boeideel)))
      },

      compare( a, b ) {
        if ( a.breedte < b.breedte ){
          return -1;
        }
        if ( a.last_nom > b.last_nom ){
          return 1;
        }
        return 0;
      },

      addVak(event, diepte, breedte) {
        console.log(event.target.value, this.data.defaultSizes[diepte].breedtes[breedte].wallDivision.length)
        while(event.target.value > this.data.defaultSizes[diepte].breedtes[breedte].wallDivision.length) {
          this.data.defaultSizes[diepte].breedtes[breedte].wallDivision.push({width: 0, bergingEnabled: false})
        } 
        while(event.target.value < this.data.defaultSizes[diepte].breedtes[breedte].wallDivision.length) {
          this.data.defaultSizes[diepte].breedtes[breedte].wallDivision.pop()
        }
        console.log(event.target.value, diepte, breedte)
        console.log(this.data.defaultSizes[diepte].breedtes[breedte].wallDivision)
        this.recalculateVakken(diepte, breedte)
      },

      setFixed(diepte, breedte, division) {
        this.data.defaultSizes[diepte].breedtes[breedte].wallDivision[division].bergingEnabled = !this.data.defaultSizes[diepte].breedtes[breedte].wallDivision[division].bergingEnabled
      },

      recalculateVakken(diepte ,breedte) {
        const staanderDikte = this.data.defaultSizes[diepte].staanderDikte
        let totalWidth = staanderDikte
        let valid = true
        this.data.defaultSizes[diepte].breedtes[breedte].wallDivision.forEach(vak => {
          if(vak.width) {
            totalWidth += vak.width
          }
          totalWidth += staanderDikte
          if(vak.width < 500 || vak.width > this.data.defaultSizes[diepte].maximaleMuurBreedte) {
            valid = false
          }
        })
        this.data.defaultSizes[diepte].breedtes[breedte].breedte = totalWidth
        this.wallDistribution[diepte][breedte].valid = valid
      },

      removeBreedte(diepte, breedte) {
        this.data.defaultSizes[diepte].breedtes.splice(breedte,1)
        this.wallDistribution[diepte].splice(breedte,1)
      },

      moveTextureUp(i) {
        if (i === 0) return
        this.allWallFinishes.splice(i - 1, 0, this.allWallFinishes.splice(i, 1)[0])
      },

      moveTextureDown(i) {
        if (i === this.allWallFinishes.length - 1) return
        this.allWallFinishes.splice(i + 1, 0, this.allWallFinishes.splice(i, 1)[0])
      },

    },


    computed: {
      ...mapGetters({
        models: "getModels",
        types: "getTypes",
        infoboxes: "getInfoboxes",
        categories: "getModelCategories",
        textures: "getTextures",
        materials: "getMaterials",
        colors: "getMenuColors",
        glasTypes: "getMenuGlasTypes",
        bergingTypes: "getBergingTypes",
      }),
    },

    async created () {
      console.log(this.$refs)
      this.setId()
      await this.get()
      await this.$store.dispatch('getTextures')
      await this.$store.dispatch('getMaterials')
      await this.$store.dispatch('getMenuColors')
      await this.$store.dispatch('getMenuGlasTypes')
      this.filterTextures()

      if(this.data.defaultSizes.length) {
        this.wallTypesAndsidesforDimensions.forEach((wallType) => {
          if(!this.data.defaultSizes[0].wall.height.find(h => h.position === wallType.id)) {
            this.data.defaultSizes[0].wall.height.push({
              position: wallType.id,
              value: 0
            })
          }
        })
      }

      document.addEventListener('keydown', e => {
        if (e.ctrlKey && e.key === 's') {
          e.preventDefault();
          this.update()
        }
      });
    },

    watch: {
      data: {
        deep: true,
        immediate: false,
        handler () {
          if (this.newModel) {
            this.formValid = true
          } else {
            if (isEqual(this.model, this.data)) {
              this.formValid = false
            } else {
              this.formValid = true
            }
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.trimColorBlock {
  width: 100%;
  height: 20px;
}

.edit {
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  min-width: min(100%, 800px);

  &-form {
    padding: 25px 0px;
    min-width: min(100%, 1200px);
  }
}

.sizes {
  display: flex;
  flex-direction: column;


  &-header {
    display: flex;
    margin-top: 25px;
    margin-bottom: 10px;

    &-name {
      font-size: 1.1rem;
      font-weight: 500;
    }
  }

  &-form {
    margin-left: 25px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 10px;
    animation: height 1s ease-in-out;

    &-drie {
      margin-left: 25px;
      margin-bottom: 10px;
      display: grid;
      grid-template-columns: 300px 1fr 100px;
      gap: 10px;
    }

    &-collapsed {
      display: none;
      margin-bottom: 0px;
    }

    &-header {
      margin-top: 0px;
      text-align: left;
      margin-bottom: 10px;

      &-button {
        float: right;
      }
    }

    &-title {
      display: inline;
      margin-left: 15px;
      font-weight: bold;
      margin-top: 0px;
    }

    &-checkbox {
      float: right;
      width: 26px;
      height: 26px;
      margin-right: 10px;
      svg {
        width: 18px;
        height: 18px;
      }
      span {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.rooffinish-item {
  text-align: left;
  label {
    display: inline-block;
    width: calc(100% - 50px)
  }
  button {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

.breedte-input {
  width: calc(100% - 130px);
  margin-right: 10px;
  margin-bottom: 10px;
}

.breedte-regel {
  height: 20px;
}
select.breedte-regel {
  height: 30px;
}
.breedte-regel-first {
  height: 24px;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 500;
  min-width: 100px;
  text-align: left;
}
.breedte-regel-unvalid {
  color: red;
}

.vak-scrollbar {
  max-width: 425px;
  width: 425px;
  overflow: auto;
  scrollbar-width: thin;
}
.vak-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: $c-background-1;
}

.vak-scrollbar::-webkit-scrollbar-thumb {
  background: $c-secondary;
}

.vak-lock {
  width: 30px; 
  border-radius: 0px 5px 5px 0px; 
  vertical-align: top; 
  height: 30px; 
  cursor: pointer;
  border: 2px solid $c-primary;

  &-selected {
    background-color: $c-primary;
  }

  &-icon {
    color: $c-primary;

    &-selected {
      color: $c-secondary;
    }
  }
}

.vak-lock > span > svg {
  margin-left: -1px;
}

.add-setting {
  width: 150px;
  border-radius: 5px;
  padding: 5px;
  margin: 0px;
  font-weight: 500;
  font-size: 0.95rem;
  border: none;
  background-color: $c-background-1;
  cursor: pointer;
  margin-bottom: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.breedte-regel-header {
  height: 24px;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 500;
  min-width: 100px;
  text-align: left;
  margin-bottom: 10px;
}

.fixedWallWidth {
  outline: 2px solid #2C5CDD;
}

.remove-breedte {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  padding: 5px;
  margin: 0px;
  font-weight: 500;
  font-size: 0.95rem;
  border: none;
  background-color: $c-background-1;
  cursor: pointer;
}

.remove-breedte:hover {
  background-color: $c-secondary;
}

.add-breedte {
  border-radius: 5px;
  padding: 5px;
  margin: 0px;
  font-weight: 500;
  font-size: 0.95rem;
  border: none;
  background-color: $c-background-1;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
}

.add-breedte:hover {
  background-color: $c-secondary;
}

.artikelmouseover {
  position: relative;
}

.no-space-warning {
  position: absolute;
  bottom: -20px;
  left: 0;
  color: red;
  font-size: 12px;
}

// .red-border {
//   border: 2px solid red !important;
// }

.artikelmouseover .default-form-field-input {
  width: 140px;
}
.artikelmouseover .tooltiptext {
  visibility: hidden;
  width: 110px;
  background-color: $c-primary;
  font-size: small;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  position: absolute;
  z-index: 1;
}
.artikelmouseover:hover .tooltiptext {
  visibility: visible;
}

.optionslist {
  display: block;
  width: 100%;
  margin-top: -10px;

  &-item {
    text-align: left;
  }

  &-checkbox {
    top: -3px; 
    position: relative;
  }
}

.wallfinishesTable tr th {
  padding: 0px 50px 10px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 30px;
}

.wallfinishesTable tr td {
  padding: 0px 50px 10px 0px;
  height: 30px;
}

.texture-order {
  margin-top: 2px;

  &-button {
    padding-left: 3px;
  }
}

.error {
  color: red;
  font-weight: bold;
}

.light-grey {
  color: #999;
}

</style>