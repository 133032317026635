<script>
import CounterButton from '@/components/inputs/buttons/CounterButton.vue'

export default {
  name: "SpotjesCard",
  components: {
    CounterButton
  },

  props: {
    spotje: Object,
    cardIndex: Number,
  },

  emits: ['select', 'updateArticle'],

  data () {
    return {
      models: import.meta.env.VITE_VUE_APP_MODELS_LOCATION,
      previewsServer: import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER,
      currentColorIndex: 0,
      currentColorCode: '',
      allowUpdate: true,
    }
  },

  mounted() {
    // console.log('spotje', this.spotje)
    this.currentColorCode = this.spotje.colors[0].code;
  },

  methods: {
      handleColorClick(colorIndex) {
        console.log('handleColorClick(colorindex)',colorIndex)
        this.allowUpdate = false;
        this.currentColorIndex = colorIndex;
        this.currentColorCode = this.spotje.colors[colorIndex].code;
        setTimeout(() => {
          this.allowUpdate = true;
        }, 100);
        // this.$emit('select', {code: this.currentColorCode, colorIndex: colorIndex, cardIndex: this.index,});
      },
      updateArticle(counter) {
        // console.log('SpotjesCard updateArticle', counter)
        if (this.allowUpdate && this.currentColorCode) {
          this.$emit('updateArticle', { 
            counter: counter, 
            cardIndex: this.cardIndex, 
            colorIndex: this.currentColorIndex,
            code: this.currentColorCode });
          }
        }
    },
}
</script>

<template>
  <section id="cardwrapper">
    <div class="card">
      <div class="card-header">
        <img 
          :src="`${previewsServer + models}previews/${spotje.colors[currentColorIndex].code}.png`" 
          :alt="spotje.code"
          @error="setBackupImage($event, data)"
        >

        <!-- COLORS -->
        <section style="display: flex;">
          <div
            v-if="spotje.colors"
            class="card-header-colors"
          >
            <div 
              v-for="(_, i) in spotje.colors" 
              :key="i"
              class="card-header-colors-cntdisplay"
              :class="(i === currentColorIndex && spotje.colors[i]?.counter > 0) ? 'card-header-colors-cntdisplay-active' : ''"
            >
              {{ spotje.colors[i].counter ? `${spotje.colors[i].counter}x` : '' }}
            </div>
          </div>
          <div 
            v-if="spotje.colors"
            class="card-header-colors" 
          >
            <button 
              v-for="(color, i) in spotje.colors" 
              :key="i" 
              class="card-header-colors-button" 
              :title="`${color.name} (${color.code})`"
              :style="`background-color: ${color.hexCode};`"
              :class="i === currentColorIndex ? 'card-header-colors-button-active' : ''"
              @click="handleColorClick(i)"
            />
          </div>
        </section>
      </div>

      <!-- CARD INFO -->
      <div class="card-info">
        <h3>{{ spotje.colors[currentColorIndex].componentName }}</h3>
      </div>

      <!-- COUNT -->
      <div class="card-info-counter">
        <!-- <CounterButton style="max-width: 125px;" @update="updateCounter" :value="data.counter" /> -->
        <CounterButton 
          v-if="spotje.colors[currentColorIndex]"
          style="max-width: 125px;" 
          :value="spotje.colors[currentColorIndex].counter" 
          @update="updateArticle($event)"
        />
      </div>
    </div>
  </section>

</template>

<style lang="scss" scoped>

.card {
  display: flex;
  flex-grow: 1;
  height: calc(100% - 20px);
  flex-direction: column;
  outline: 3px solid $c-border-1;
  border-radius: $radius-m;
  padding: 10px;
  transition: outline-color 100ms ease;
  border: none;
  background-color: transparent;
  scroll-margin: 150px;

  &:hover {
    outline-color: $c-secondary;
    cursor: pointer;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    height: 150px;
    max-width: 100%;

    & img {
      min-height: 150px;
      max-height: 150px;
      width: calc(100% - 24px);
      object-fit: contain;
    }

    &-colors {
      display: flex;
      flex-direction: column;
      gap: 6px;

      &-button {
        height: 24px;
        width: 24px;
        outline: 3px solid $c-border-1;
        outline-offset: -2px;
        border: none;
        border-radius: $radius-s;
        transition: outline-color 100ms ease;
        cursor: pointer;

        &:hover {
          outline-color: $c-secondary;
        }

        &-active {
          outline-color: $c-secondary;
        }
      }

      &-cntdisplay {
        height: 24px;
        width: 24px;
        // outline: 3px solid $c-border-1;
        // outline-offset: -2px;
        border: none;
        border-radius: $radius-s;

        &-active {
          // color: $c-secondary;
          font-weight:bolder;
        }
      }
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;

    min-height: 2.8rem;

    & h3 {
      font-size: 1rem;
      text-align: left;
    }

    &-size {
      display: flex;
      align-items: center;
      gap: 15px;
      font-weight: 500;
    }

    &-counter {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
}

#cardwrapper {
  border: none;
  display: flex;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  min-height: 100%;
}

</style>