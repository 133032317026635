<template>
  <div class="components" ref="components">
    <div class="components-array">
      <SingleComponent 
        v-if="isSplit ? selectedComponentL : false" 
        :component="selectedComponentL" 
        @refresh="refreshGrid" 
        @newData="newGridData"
        :height="topHeight"
        :width="rightWidth"
        :isSplit="isSplit"
        :actions="false"
        side="L"
        :setCopyData="copyDataLeft"
        @copy="copyData"
      />

      <SingleComponent
        v-if="selectedComponentR" 
        :component="selectedComponentR" 
        @refresh="refreshGrid" 
        @newData="newGridData"
        :height="topHeight"
        :width="isSplit ? leftWidth : 1"
        @splitview="splitview()"
        :isSplit="isSplit"
        side="R"
        :setCopyData="copyDataRight"
        @copy="copyData"
      />
    </div>

    <div 
      class="resize-bar" 
      ref="resizeBar" 
      :style="`top: ${(windowHeight - 60) * topHeight - 10}px`" 
      @mousedown="resizeActive = true"
    />

    <ag-grid-vue :style="`width: ${windowWidth}px; height: ${Math.max((windowHeight - 60) * bottomHeight, 50)}px;`"
        class="ag-theme-alpine-dark"
        :columnDefs="columnDefs"
        :rowData="rowData"
        rowSelection="multiple"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        @filter-changed="onFilterChanged"
      >
    </ag-grid-vue>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { AgGridVue } from "ag-grid-vue3";
  import SingleComponent from "@/views/cms/Component.vue"
  import "ag-grid-community/styles/ag-grid.css";
  import "ag-grid-community/styles/ag-theme-alpine.css";

  export default {
    name: "ComponentsOverview",

     components: {
      SingleComponent,
      AgGridVue,
    },

    data () {
      return {
        site: import.meta.env.VITE_VUE_APP_SERVER,
        columnDefs: null,
        rowData: null,
        frameworkComponents: null,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        gridOptions: null,
        selectedComponentR: null,
        selectedComponentL: null,
        gridApi: null,
        topHeight: 0.5,
        bottomHeight: 0.5,
        resizeActive: false,
        isSplit: false,
        leftWidth: 0.5,
        rightWidth: 0.5,
        activeFilter: null,
        focusedSide: 'RIGHT',
        copyDataRight: null,
        copyDataLeft: null,
      }
    },

    methods: {
      handleResize () {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
      },

      async getComponents () {
        if (this.CMSComponents.length === 0) {
          let categories = ['Deur', 'Raam', 'Steellook']
          // TASK-1394 : more categories needed for filtering in the configurator
          // let categories = ['Deur', 'Raam', 'Steellook', 'Steellook Raam', 'Steellook Deur']

          this.loading = true

          try {
            await this.$store.dispatch('getCMSComponents', {categories: categories})
          } catch (err) {
            this.loading = false
          }

          this.loading = false
          this.rowData = this.CMSComponents
          this.selectedComponentR = this.rowData[0]
        } else {
          this.rowData = this.CMSComponents
          this.selectedComponentR = this.rowData[0]
        }
      },

      async updateCell (event) {

        const c = this.CMSComponents.find((w) => w.id === event.data.id)
        await this.$store.dispatch('updateComponent', c)

      },

      rowClick (event) {

        if (event.event.altKey && event.event.type === "click" && event.event.ctrlKey) {
          this.selectedComponentL = event.data
        } else if (event.event.altKey && event.event.type === "click") {
          this.selectedComponentR = event.data
        }

      },

      onGridReady (params) {
        console.log("params", params)
        this.gridApi = params.api;

        const allColIds = params.api.getColumns().map(column => column.colId);
        this.gridApi.autoSizeColumns(allColIds)
      },

      refreshGrid ({ c, side}) {
        if (side === 'L') {
          this.selectedComponentL = c
        } else {
          this.selectedComponentR = c
        }
        const rowNode = this.gridApi.getRowNode(c.id);
        rowNode.setData(c)
        this.gridApi.flashCells({ rowNodes: [rowNode] });
      },

      onFilterChanged(e) {
        this.activeFilter = e.api.getFilterModel()
      },

      newGridData () {
        const filters = this.gridApi.getColumnState()
        const activeFilter = this.activeFilter

        this.gridApi.setRowData(JSON.parse(JSON.stringify(this.CMSComponents)))

        this.gridApi.applyColumnState({
          state: filters,
          applyOrder: true,
        });

        this.gridApi.setFilterModel(activeFilter);
      },

      splitview () {
        if (this.isSplit) {
          this.isSplit = false
        } else {
          this.isSplit = true
          this.selectedComponentL = this.selectedComponentR
          // console.log('selectedComponentR', this.selectedComponentR)
        }
      },

      copyData (copy) {
        if (copy.s === 'L') {
          this.copyDataRight = JSON.parse(JSON.stringify(copy))
        } else if (copy.s === 'R') {
          this.copyDataLeft = JSON.parse(JSON.stringify(copy))
        }
      }
    },

    computed: {
      ...mapGetters({
        CMSComponents: 'getCMSComponents',
      }),
    },

    created () {
      this.getComponents()
      window.addEventListener('resize', this.handleResize);
    },

    beforeMount() {

      const stnd = {
        sortable: true, 
        filter: true, 
        resizable: true,
        cellStyle: {textAlign: 'left'}
      }

      this.columnDefs = [
        { field: "code", width: 140, checkboxSelection: true, ...stnd },
        { field: "combinedCode", width: 140, ...stnd },
        { field: "category",  width: 70, editable: true,  ...stnd } ,
        { field: "name", width: 600, editable: true, ...stnd },
        { field: "shortName",  width: 600, editable: true,  ...stnd },
        { field: "size", width: 200, editable: true, ...stnd  },
        { field: "trueOnline",  width: 70, editable: true, ...stnd },        
        { field: "dimensions.x", width: 200, editable: true, ...stnd },        
        { field: "glasTypes.0.name", width: 120, editable: false, ...stnd },
        { field: "glasTypes.1.name", width: 120, editable: false, ...stnd },
        { field: "glasTypes.2.name", width: 120, editable: false, ...stnd },
        { field: "keyWords", width: 500, editable: false, ...stnd },        
        { field: "keyWords.0", width: 500, editable: false, ...stnd }, 
        { field: "keyWords.1", width: 500, editable: false, ...stnd }, 
        { field: "keyWords.2", width: 500, editable: false, ...stnd }, 
        { field: "keyWords.3", width: 500, editable: false, ...stnd }, 
        { field: "keyWords.4", width: 500, editable: false, ...stnd }, 
        { field: "keyWords.5", width: 500, editable: false, ...stnd }, 
        { field: "keyWords.6", width: 500, editable: false, ...stnd }, 

      ];

      this.gridOptions = {
        onRowClicked: event => this.rowClick(event),
        onCellValueChanged: event => this.updateCell(event),
        getRowId: data => {
          return data.data.id;
        }
      }
    },

    mounted () {
      this.$refs.components.addEventListener('mousemove', (event) => {
        if (this.resizeActive) {
          const mouseY = event.clientY - 60
          const percentage = mouseY / (this.windowHeight - 60)
          this.topHeight = percentage
          this.bottomHeight = 1 - percentage
        }
      })

      this.$refs.components.addEventListener('mouseup', () => {
        if (this.resizeActive) {
          this.resizeActive = false
        }
        // this.mouse.y =event.clientY
      })
    },

    unmounted() {
      window.removeEventListener('resize', this.handleResize);
    },
  }
</script>

<style lang="scss" scoped>

// @import "ag-grid-community/styles/ag-grid.css";
// @import "ag-grid-community/styles/ag-theme-alpine-dark.css";

h1 {
  margin: 0px;
}

.components {
  display: grid;
  // grid-template-rows: 1fr 1fr;
  max-height: calc(100vh - 60px);
  position: relative;

  &-array {
    display: flex;
  }

  &-table {
    border-collapse: collapse;
    table-layout: fixed;

    td, th {
      border: 1px solid #000000;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #dddddd;
    }
  }
}

.gridjs-table {
  display: flex;
}

.resize-bar {
  position: absolute;
  content: " ";
  min-height: 4px;
  min-width: 100vw;
  background-color: $c-secondary;
  z-index: 90;
  cursor: n-resize;
}

</style>