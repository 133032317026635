<template>
  <div class="roof">
    <!-- <div class="roof-section">
      <h3>Dakafwerking</h3>

      <div class="roof-section-option">
        <button @click="toggleEPDM()">
          <img :src="require(`@/assets/dak/epdm.png`)" alt="" :class="epdm ? 'roof-section-option-img-active' : 'roof-section-option-img'">
        </button>

        <span>EPDM</span>
      </div>

       <div class="roof-section">
        <h3>Isolatie</h3>
      </div>
    </div> -->

    <!-- <div class="top-page-header">
      <TabSelectorIcons :tabs="tabs" :position="getCurrentPosition" extension="" @change="changeTab"/>
    </div> -->
    <div class="top-page-header">
      <TabSelectorNoScrolling :tabs="tabs" extension="" @change="changeTab"/>
    </div>

    <RouterView />

    <!-- <ExpansionCard title="Dakafwerking">
      <ColorSelectorPreview :options="roofFinishes" :selected="selectedDakafwerking" class="roof-page-preview" @change="updateDakafwerking" />
    </ExpansionCard>

    <div v-if="selectedDakafwerking.id === '62e7c729de63cb0cfe032453'">
      <OptionCard 
        v-for="(ins, index) in insulation" 
        :key="index"
        :data="ins" 
        :selected="selectedInsulation === ins.code"
        @select="setSelectedInsulation(ins)"
      />
    </div> -->

   
  </div>
</template>

<script>
  // import TabSelectorIcons from '@/components/inputs/TabSelectorIcons.vue'
  import TabSelectorNoScrolling from '@/components/inputs/TabSelectorNoScrolling.vue'
  import { mapGetters } from 'vuex'
  // import ColorSelectorPreview from '@/components/inputs/ColorSelectorPreview.vue'
  // import ExpansionCard from '@/components/layout/ExpansionCard.vue'
  // import OptionCard from '@/components/inputs/OptionCard.vue'

  export default {
    components: {
      // TabSelectorIcons,
      TabSelectorNoScrolling,
      // ColorSelectorPreview,
      // ExpansionCard,
      // OptionCard
    },

    data() {
      return {
        roofFinishes: [],
        insulation: [],
        tabs: [
          {name: "Dakafwerking", icon: "home-roof", route: "/"},
          // add placeholder elements for the sake of calculating positions in TabSelectorIcons.vue
          {name: "", icon: "home-roof", route: "/", placeholder: true},
          {name: "", icon: "home-roof", route: "/", placeholder: true},
          // {name: "", icon: "home-roof", route: "/", placeholder: true},
        ],
        // dynamic tabs will change depending on config type
        dynamicTabs: [
          {name: "Dakgoot", icon: "pipe", route: "/dakgoot"},
          {name: "Uitloop", icon: "pipe", route: "/uitloop"},
          {name: "Isolatie", icon: "shape-rectangle-plus", route: "/dakisolatie"},
          {name: "Lichtstraten", icon: "sun-angle-outline", route: "/lichtstraten"},
		      // {name: "Spotjes", icon: "wall-sconce-flat", route: "/spotjes"}, // blijft in Opties
        ],
      }
    },

    methods: {
      changeTab (tab) {
        this.$router.push('/cfg/roof' + this.tabs[tab].route)
      },
      filterTabs() {
        // console.log('config.type',this.config.type)
        this.tabs = this.tabs.filter(tab => !tab.placeholder);

        if (this.config.type === 'Plat dak' || this.config.type === 'Aanbouwveranda') {
          this.tabs.push(this.dynamicTabs.find(tab => tab.name === "Uitloop"))
          this.tabs.push(this.dynamicTabs.find(tab => tab.name === "Isolatie"))
          this.tabs.push(this.dynamicTabs.find(tab => tab.name === "Lichtstraten"))
          // this.tabs.push(this.dynamicTabs.find(tab => tab.name === "Spotjes")) // blijft in Opties
        } else {
          this.tabs.push(this.dynamicTabs.find(tab => tab.name === "Dakgoot"))
        }
      },
      updateDakafwerking(color) {
        if (color.name !== this.selectedDakafwerking.name) {
          this.$store.dispatch('updateDakafwerking', color)
        }
      },

      setSelectedInsulation(insulation) {
        if(this.selectedInsulation !== insulation.code) {
          this.$store.commit('setSelectedInsulation', insulation.code)
          this.$store.commit('setConfigInsulation', insulation.id)
        } else {
          this.$store.commit('setSelectedInsulation', '')
          this.$store.commit('setConfigInsulation', '')
        }
      },

      async refreshRoofFinishes() {
        if (!this.insulation?.length) {
          await this.$store.dispatch('getInsulation')
          this.insulation = this.getInsulation
        }
        await this.$store.dispatch('getTextures')
        const filteredTextures1 = this.getTextures.filter(texture => texture.textureType === "62e3e09a990280c3716422dd")
        // console.log(this.dakafwerking)
        const filteredTextures = filteredTextures1.filter(texture => this.dakafwerking.includes(texture.id))
        this.roofFinishes = []
        for(let i=0; i<filteredTextures.length; i++) {
          // added line to filter on color aand loop trough all colors to pick geen and the selected color from the frame color
          // be sure the data like scaling is in the texture in the database, other wise it will not work and you will get no texture
          if(filteredTextures[i].name.toLowerCase() === "geen" ? (this.selectedFrameColor.colorId === filteredTextures[i].color)  : true) {
            this.roofFinishes.push({
              name: filteredTextures[i].name,
              previewURL: filteredTextures[i].url,
              id: filteredTextures[i].id,
              scalingX: filteredTextures[i].scalingX,
              scalingY: filteredTextures[i].scalingY,
              emissive: filteredTextures[i].emissive,
              emissiveColor: filteredTextures[i].emissiveColor,
              metalness: filteredTextures[i].metalness,
            })
          }
        }
        console.log(this.roofFinishes)
        // added line to sort, so that "Geen" is always on top
      this.roofFinishes.sort((a, b) => {
        if (a.name === "Geen") return -1;
        if (b.name === "Geen") return 1;
        return 0;
      });
      }
    },

    async created () {
      this.refreshRoofFinishes()
    },

    mounted() {
      this.refreshRoofFinishes()

      this.filterTabs()
    },

    computed: {
      ...mapGetters({
        config: 'getConfig',
        selectedDakafwerking: 'getSelectedDakafwerking',
        dakafwerking: 'getRoofFinishes',
        getTextures: 'getTextures',
        selectedFrameColor: 'getSelectedFrameColor',
        getInsulation: 'getInsulation',
        selectedInsulation: 'getSelectedInsulation',
        typeId: 'getTypeId',
      }),
    },
  }
</script>

<style lang="scss" scoped>

.top-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
  min-width: 100%;

  &-header {
    display: flex;
    flex-direction: column;
    align-items: center; //start;
  }
}

.roof {

  &-section {
    text-align: left;

    &-option {
      display: flex;
      gap: 25px;
      align-items: center;

      & button {
        border: none;
        padding: 0px;
        margin: 0px;
        display: flex;
        background-color: transparent;
        cursor: pointer;
      }

      & img {
        height: 150px;
        width: 150px;
        object-fit: cover;
        border-radius: $radius-m;
        padding: 10px;
        transition: opacity 100ms ease, border-color 100ms ease;
      }

      &-img {
        border: 2px solid $c-border-1;
        opacity: 0.5;

        &-active {
          border: 2px solid $c-secondary;
          opacity: 1;
        }
      }

      & span {
        flex-grow: 1;
        text-align: center;
        font-weight: 500;
        font-size: 1.25rem;
      }
    }
  }
}

.roof-page-preview {
  padding-bottom: 25px;
}

</style>