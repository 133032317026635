<template>
  <div class="edit-colors">
    <h1>Menu Kleuren</h1>

    <form class="default-form edit-colors-form" @submit.prevent="">

      <div class="default-form-field">

        <div class="default-form-field-colors">
          <div class="default-form-field-colors-header default-form-field-colors-edit-row">
            <span>Name</span>
            <span>hexCode</span>
            <span>preview</span>
            <span>open</span>
            <span></span>
          </div>

          <div v-for="(color, i) in colors" :key="i" class="default-form-field-colors-edit-row">
            <input type="text" class="default-form-field-input" v-model.trim="color.name">
            <input type="text" class="default-form-field-input" v-model.trim="color.hexCode">
            <div class="edit-colors-form-display" :style="`background-color: ${color.hexCode};`"></div>

            <input class="checkbox" type="checkbox" :id="`open${i}`" :name="`open${i}`" v-model="color.open">
            <label :for="`open${i}`" class="checkbox-label" style="margin-right: 0px;">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="color.open ? 'checkbox-label-icon-active' : ''" size="22" />
            </label>

            <button class="default-form-field-button" @click="removeColor(i)">
              <mdicon name="minus" class="default-form-field-button-icon" size="25" />
            </button>
          </div>

          <button class="default-form-field-button" @click="addColor()">
            <mdicon name="plus" class="default-form-field-button-icon" size="25" />
          </button>
        </div>
      </div>

      <div class="default-form-button">
        <LoadingButton @click="updateColors()" :loading="loading" :disabled="!formValid">Update</LoadingButton>
      </div>

    </form>
  </div>
</template>

<script>
  import isEqual from 'lodash.isequal'
  import { mapGetters } from 'vuex'
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'

  export default {
    name: "MenuColors",

    components: {
      LoadingButton
    },

    data () {
      return {
        colors: [],
        formValid: false,
        loading: false,
      }
    },

    methods: {
      async getMenuColors () {
        await this.$store.dispatch('getMenuColors')
        this.colors = JSON.parse(JSON.stringify(this.menuColors))
      },

      async updateColors () {
        this.loading = true

        try {
          await this.$store.dispatch('updateMenuColors', this.colors)
        } catch (err) {
          console.log(err)
          this.loading = false
        }

        this.loading = false
        this.colors = JSON.parse(JSON.stringify(this.menuColors))
      },
      
      addColor () {
        this.colors.push({
          name: "",
          hexCode: "",
          open: false
        })
      },

      removeColor (i) {
        this.colors.splice(i, 1)
      }
    },

    computed: {
      ...mapGetters({
        menuColors: "getMenuColors"
      }),
    },

    created () {
      this.getMenuColors()
    },

    watch: {
      colors: {
        deep: true,
        immediate: false,
        handler () {
          if (isEqual(this.colors, this.menuColors)) {
            this.formValid = false
          } else {
            this.formValid = true
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

.edit-colors {
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  min-width: min(100%, 800px);

  &-form {
    padding: 25px 0px;
    gap: 50px;
    min-width: min(100%, 800px);

    &-display {
      border-radius: $radius-s;
      outline: 3px solid $c-background-1;
      outline-offset: -1px;
      height: 29px;
      align-self: center;
    }
  }
}

.default-form-field-colors-edit-row {
  grid-template-columns: repeat(3, 1fr) 30px 30px;
}

.checkbox-label {
  width: 26px;
  height: 26px;
}

</style>