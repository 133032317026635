<template>
  <div class="bdoors-page">
    <ComponentCard 
      v-for="(component, index) in categoryFilter(wallComponents)"
      :key="index" 
      ref="component"
      :data="component" 
      :selected="component.code === selectedComponent.code"
      :cselected="getComponentSelected(component)"
      :scolor="selectedComponentSettings.color"
      :soption="selectedComponentSettings.option"
      :svariant="selectedComponentSettings.variant"
      :sglasType="selectedComponentSettings.glasType"
      @select="changeSelectedComponent(component, index)"
      />
      <!-- {{ JSON.stringify(wallComponents) }} -->
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ComponentCard from '@/components/inputs/ComponentCard.vue'

  export default {
    name: "DoorsAndWindows",

    components: {
      ComponentCard
    },

    data () {
      return {
        // selectedComponent: 0,
      }
    },

    methods: {
      categoryFilter(array){
        return array.filter(item => 
          item.category === 'Deur');
      },
      changeSelectedComponent (component, index, shouldNotReplaceModel) {
        const comp = this.$refs.component[index];
        const sglasType = comp.selectedGlasType;
        const sVariant = comp.selectedVariant;
        const sColor = comp.selectedColor;
        const sOption = comp.selectedOption;
        const sLastCode = comp.lastCodeSelection;

        const settings = {
          glasType: component.glasTypes && component.glasTypes.length>1 ? component.glasTypes[sglasType] : component.glasTypes ? component.glasTypes[0] : null,
          variant: component.variants && component.variants.length>1 ? component.variants[sVariant] : component.variants ? component.variants[0] : null,
          color: component.colors.length>1 ? component.colors[sColor] : component.colors[0],
          option: component.options.length>1 ? component.options[sOption] : component.options[0],
          lastCode: sLastCode
        }
        // const settings = {
        //   color: component.colors.length>1 ? component.colors[this.$refs.component[index].selectedColor] : component.colors[0],
        //   option: component.options.length>1 ? component.options[this.$refs.component[index].selectedOption] : component.options[0],
        // }
        // console.log('test settings',settings)
        this.$store.dispatch('changeSelectedWallComponent', {component: component, settings: settings})

        if(this.selectedElement !== null && !shouldNotReplaceModel) {
          // console.log(this.selectedElement.metaData.ModelPosition)
          const muur = this.getMuurInstances.find((muur) => muur.group.uuid === this.selectedElement.parent.parent.uuid)
          // console.log(muur)
          // console.log(this.selectedElement.metaData)
          // const oldPosition = this.selectedElement.metaData.ModelPosition

          /*
          const muur = this.getMuurInstances.find((muur) => muur.group.uuid === this.selectedElement.parent.parent.uuid)
          const side = muur.wallButtonIndex[this.selectedElement.metaData.ModelPosition].side
          const plusIcon = muur.plusIconGroupDisabled[this.selectedElement.metaData.ModelPosition*2+side]
          //console.log(muur)

          this.$store.dispatch('changeElement', {obj: this.selectedElement, wall: plusIcon, component: this.selectedComponent, settings: this.selectedComponentSettings})
          */

          this.$store.dispatch('changeElement', {
            obj: this.selectedElement, 
            wall: null, 
            component: this.selectedComponent, 
            settings: this.selectedComponentSettings,
            Ewall: muur,
            Elocation: this.selectedElement.metaData.ModelPosition,
            Eside: this.selectedElement.metaData.Side
          })

          //this.$store.dispatch('selectElement', this.currentIntersect.object.parent)
          //const muur2 = this.getMuurInstances.find((muur) => muur.group.uuid === this.selectedElement.parent.parent.uuid)
          //muur.wallButtonIndex[this.selectedElement.metaData.ModelPosition].modelBox
          // console.log(oldPosition)
          //this.$store.dispatch('selectElement', muur.wallButtonIndex[oldPosition].modelBox)
          //this.$store.dispatch('selectElement', muur.group.children[8].children[0])
        }
      },

      async getComponents () {
        if (this.wallComponents.length === 0) {          
          let categories = ['Deur', 'Raam', 'Steellook Deur', 'Steellook Raam', 'Schuifwand']
          let trueOnline = true

          // await this.$store.dispatch('getComponents', {categories: categories, trueOnline: trueOnline})
          await Promise.all([
            this.$store.dispatch('getComponents', {categories: categories, trueOnline: trueOnline}),
            this.$store.dispatch('getGlassVariantsMain', {categories: categories, trueOnline: trueOnline})
          ])
        }
      },

      getComponentSelected (component) {
        if(this.selectedElement === null) {return false}
        // console.log(this.getMuurInstances)
        // console.log(this.selectedElement)
        const muur = this.getMuurInstances.find((muur) => muur.group.uuid === this.selectedElement.parent.parent.uuid)
        const muurMetadata = muur.wallButtonIndex[this.selectedElement.metaData.ModelPosition]
        // console.log(muurMetadata)
        return component === muurMetadata.component
      }
    },

    computed: {
      ...mapGetters({
        wallComponents: 'getInWallComponents',
        selectedComponent: 'getSelectedWallComponent',
        selectedComponentSettings: 'getSelectedWallComponentSettings',
        selectedElement: 'getSelectedElement',
        getMuurInstances: 'getMuurInstances',
      }),
    },

    async mounted () {
      if(this.wallComponents.length == 0) await this.getComponents();
      // console.log('wallComponents X',this.wallComponents.filter(component => component.code.includes('X')))
      // console.log('wallComponents test', this.wallComponents);
      if (this.selectedElement == null) {
        this.changeSelectedComponent(this.categoryFilter(this.wallComponents)[0], 0, true);
      }
    }
  }
</script>

<style lang="scss" scoped>

.bdoors-page {
  display: grid;
  padding: 25px 0px;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
}

</style>