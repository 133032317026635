<template>
  <div class="edit-glastypes">
    <h1>Menu glastypes</h1>

    <form class="default-form edit-glastypes-form" @submit.prevent="">

      <div class="default-form-field">

        <div class="default-form-field-glastypes">
          <div class="default-form-field-glastypes-header default-form-field-glastypes-edit-row">
            <span>Name</span>
            <span>Acronym</span>            
          </div>

          <div v-for="(glastype, i) in glastypes" :key="i" class="default-form-field-glastypes-edit-row">
            <input type="text" class="default-form-field-input" v-model.trim="glastype.name">
            <input type="text" class="default-form-field-input" v-model.trim="glastype.acronym">            
            <button class="default-form-field-button" @click="removeGlastype(i)">
              <mdicon name="minus" class="default-form-field-button-icon" size="25" />
            </button>
          </div>

          <button class="default-form-field-button" @click="addGlastype()">
            <mdicon name="plus" class="default-form-field-button-icon" size="25" />
          </button>
        </div>
      </div>

      <div class="default-form-button">
        <LoadingButton @click="updateGlastypes()" :loading="loading" :disabled="!formValid">Update</LoadingButton>
      </div>

    </form>
  </div>
</template>

<script>
  import isEqual from 'lodash.isequal'
  import { mapGetters } from 'vuex'
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'

  export default {
    name: "MenuGlasTypes",

    components: {
      LoadingButton
    },

    data () {
      return {
        glastypes: [],
        formValid: false,
        loading: false,
      }
    },

    methods: {
      async getMenuGlasTypes () {
        await this.$store.dispatch('getMenuGlasTypes')
        this.glastypes = JSON.parse(JSON.stringify(this.menuGlasTypes))
      },

      async updateGlastypes () {
        this.loading = true

        try {
          await this.$store.dispatch('updateMenuGlasTypes', this.glastypes)
        } catch (err) {
          console.log(err)
          this.loading = false
        }

        this.loading = false
        this.glastypes = JSON.parse(JSON.stringify(this.menuGlasTypes))
      },
      
      addGlastype () {
        this.glastypes.push({
          name: "",
          acronym: "",
        })
      },

      removeGlastype (i) {
        this.glastypes.splice(i, 1)
      }
    },

    computed: {
      ...mapGetters({
        menuGlasTypes: "getMenuGlasTypes"
      }),
    },

    created () {
      this.getMenuGlasTypes()
    },

    watch: {
      glastypes: {
        deep: true,
        immediate: false,
        handler () {
          if (isEqual(this.glastypes, this.menuGlasTypes)) {
            this.formValid = false
          } else {
            this.formValid = true
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

.edit-glastypes {
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  min-width: min(100%, 800px);

  &-form {
    padding: 25px 0px;
    gap: 50px;
    min-width: min(100%, 800px);

    &-display {
      border-radius: $radius-s;
      outline: 3px solid $c-background-1;
      outline-offset: -1px;
      height: 29px;
      align-self: center;
    }
  }
}

</style>