<script>
// import { mapGetters } from 'vuex';

  export default {
    name: "ComponentCardSimple",

    props: {
      data: Object,
      datatype: String,
      isselected: Boolean,
      // cselected: Boolean,
      // wSelected: Object, // selected wall data
      scolor: Object,
      soption: null,
      // svariant: Object,
      // sglasType: Object,
    },

    emits: ['select', 'change'],

    data () {
      // console.log('component card data',this.data)
      return {
        models: import.meta.env.VITE_VUE_APP_MODELS_LOCATION,
        previewsServer: import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER,
        host: import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION,
        selectedColorIndex: 0,
        selectedOptionIndex: 0,
        // lastCodeSelection: '' // for choosing .glb file
      }
    },

    computed: {
      // ...mapGetters({
    },

    watch: {
      // wSelected: "setCurrentComponentSettings",
    },

    mounted() {
      // this.setCurrentComponentSettings()
    },

    methods: {
      handleCardClick(data) {
        // console.log('handleCardClick')
        this.$emit('select', data)
      },
      changeColor (color) {
        this.$emit('change', color)
      }
    },
  }
</script>

<template>
  <!-- <button class="button-wrapper" @click="$emit('select')"> -->
  <button class="button-wrapper" @click="handleCardClick(data)">
    <div class="card" :class="isselected ? 'card-selected' : '' ">
      <div class="card-header">
        <img 
          v-if="datatype === 'dakafwerking' && data.previewURL" 
          :src="host + data.previewURL" 
          alt=""
          @error="setBackupImage($event, data)"
        >
        <img 
          v-if="datatype === 'lichtstraten' && data.colors[data.colorPicked]?.code"
          :src="`${previewsServer + models}previews/${data.colors[data.colorPicked].code}.png`" 
          :alt="data.code"
          @error="setBackupImage($event, data)"
        >

        <!-- COLORS -->
        <div class="card-header-colors" v-if="data.colors">
          <button 
            v-for="(color, index) in data.colors" 
            :key="index" 
            class="card-header-colors-button" 
            :title="`${color.name} (${color.code})`"
            :style="`background-color: ${color.hexCode};`"
            :class="isselected ? scolor?.code === color.code ? 'card-header-colors-button-active' : '' : index === selectedColorIndex ? 'card-header-colors-button-active' : ''"
            @click.stop="selectedColorIndex = index; lastCodeSelection = color.code; $emit('select')"
          />
        </div>
      </div>

      <!-- CARD INFO -->
      <!-- title = hover text over button -->
      <div class="card-info">
        <h3>{{ data.colors[selectedColorIndex].componentName }}</h3>
      </div>
      <div v-if="datatype === 'lichtstraten'" class="card-info-size">
        <mdicon class="card-info-size-icon" name="ruler-square" size="25" />
        <span class="card-info-size-text">{{ data.size }} mm</span>
      </div>

      <!-- OPTIONS -->
      <!-- title = hover text over button -->
      <!-- <div class="card-options" v-if="data.options?.length > 0">
        <button 
          v-for="(option, index) in data.options" 
          :key="index"
          :class="cselected ? soption.subCode === option.subCode ? 'card-options-active' : '' : index === selectedOptionIndex ? 'card-options-active' : ''"    
          @click="selectedOptionIndex = index; $emit('select')"
          >
          {{ option.name }}
        </button>
      </div> -->
    </div>
  </button>
</template>

<style lang="scss" scoped>

.card {
  display: flex;
  flex-grow: 1;
  height: calc(100% - 20px);
  flex-direction: column;
  outline: 3px solid $c-border-1;
  border-radius: $radius-m;
  padding: 10px;
  transition: outline-color 100ms ease;
  border: none;
  background-color: transparent;
  scroll-margin: 150px;

  &:hover {
    outline-color: $c-secondary;
    cursor: pointer;
  }

  &-selected {
    outline-color: $c-secondary;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    height: 150px;
    max-width: 100%;

    & img {
      min-height: 150px;
      max-height: 150px;
      width: calc(100% - 24px);
      object-fit: contain;
    }

    &-colors {
      display: flex;
      flex-direction: column;
      gap: 6px;

      &-button {
        height: 24px;
        width: 24px;
        outline: 3px solid $c-border-1;
        outline-offset: -2px;
        border: none;
        border-radius: $radius-s;
        transition: outline-color 100ms ease;
        cursor: pointer;

        &:hover {
          outline-color: $c-secondary;
        }

        &-active {
          outline-color: $c-secondary;
        }
      }
    }

    &-glastypes {
      display: flex;
      flex-direction: column;
      gap: 6px;

      &-button {
        height: 24px;
        width: 32px;
        outline: 3px solid $c-border-1;
        outline-offset: -2px;
        border: none;
        border-radius: $radius-s;
        transition: outline-color 100ms ease;
        cursor: pointer;
        margin-right: 0.4em;

        &:hover {
          outline-color: $c-secondary;
        }

        &-active {
          outline-color: $c-secondary;
        }        
      }
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;

    min-height: 2.8rem;

    & h3 {
      font-size: 1rem;
      text-align: left;
    }

    &-size {
      display: flex;
      align-items: center;
      gap: 15px;
      font-weight: 500;
    }
  }

  &-options {
    display: flex;
    gap: 15px;

    & button {
      flex-grow: 1;
      background-color: $c-background-1;
      border: none;
      border-radius: $radius-s;
      padding: 5px;
      font-weight: 600;
      font-size: 0.9rem;
      color: $c-primary;
      transition: color 100ms ease, background-color 100ms ease;
      cursor: pointer;
    }

    &-active {
      background-color: $c-primary !important;
      // color: $c-secondary  !important;
      color: $c-secondary-bright  !important;
    }
  }
}

.button-wrapper {
  border: none;
  display: flex;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  min-height: 100%;
}

</style>