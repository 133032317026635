<script>
import OptionCard from '@/components/inputs/OptionCard.vue'
import { mapGetters } from "vuex";
export default {
    components: {
      OptionCard
    },

    data() {
      return {
        parsedOptions: null,
        basis: [],
      }
    },

    computed: {
      ...mapGetters({
        options: 'getOptions',
        config: 'getConfig', 
      }),
    },

    created () {
      this.getOptions()
      setTimeout(() => {
        // console.log(this.config)
      }, 5000);
    },

    methods: {
      async getOptions () {
        if (this.options.length === 0) {
          await this.$store.dispatch('getOptions')
        }

        this.parseOptions()
        this.basis = this.filterCategory(this.parsedOptions, 'Fundering')
      }, 

      parseOptions() {
        this.parsedOptions = this.options
      },

      filterCategory (options, category) {
        return options.filter((o) => o.keyWords.some((k) => k === category))
      },

      updateBasisOption () {
        this.$store.dispatch('updateFoundation', !this.config.fundering)
      },
    }
  }
</script>
<template>
  <section 
    v-if="basis.length > 1"
    id="mainsection"
  >
    <OptionCard 
      v-for="(optie, index) in basis" 
      :key="index"
      :data="optie" 
      :selected="config.fundering"
      :has-automargin="false"
      @select="updateBasisOption(optie)"
    />
  </section>
  <section 
    v-else
    id="mainsectionsolo"
  >
    <OptionCard 
      v-for="(optie, index) in basis" 
      :key="index"
      :data="optie" 
      :selected="config.fundering"
      :has-automargin="true"
      :has-widthlimit="false"
      @select="updateBasisOption(optie)"
    />
  </section>
</template>
<style lang="scss" scoped>
#mainsection {
  // margin: 1rem;
  // margin: 1rem 1rem 1rem 3px;
  display: grid;
  padding: 25px 0px;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
  // width: 100%;
}
#mainsectionsolo {
  padding: 25px 0px;
}
</style>