<template>
    <div class="sideBarMenu"> <!-- Made a new class for the sidebar menu -->
        <!-- ConfirmDelete.vue part -->
      <div class="confirmDelete">
        <!-- <button @click="back">
          <mdicon class="menu-button-icon left" name="arrow-u-left-top" size="30"/>
        </button>
        <button @click="forward">
          <mdicon class="menu-button-icon right" name="arrow-u-right-top" size="30"/>
        </button> -->
        <button title="Creeër retour link" @click="save">
          <mdicon class="menu-button-icon" name="share-variant" size="30"/>
        </button>
        <button v-if="fullScreenPossible" title="Fullscreen" @click="toggleFullScreen">
          <mdicon class="menu-button-icon" :class="fullScreen ? 'active' : ''" :name="fullScreen ? 'fullscreen-exit' : 'fullscreen'" size="30"/>
        </button>
        <button v-if="wallSelected || elementSelected || selectedLichtstraat" @click="saveButton()" > <!--:style="{width: wallSelected ? '33.3%' : '50%' }"-->
          <mdicon class="menu-button-icon save" name="check" size="30"/>
        </button>
        <button v-if="wallSelected || elementSelected || selectedLichtstraat" @click="deleteButton()" > <!--:style="{width: wallSelected ? '33.3%' : '50%'-->
          <mdicon class="menu-button-icon delete" name="delete" size="30"/>
        </button>
        <button v-if="wallSelected && wallSelected.wall.parent.name != 'wallBox' && wallSelected.wall.name != 'topgevelSelectbox' && !this.selectedWallObject?.isBorstwering" @click="changeLock()"> <!--:style="{width: wallSelected ? '33.3%' : '50%'--> <!-- v-if="wallSelected && wallSelected.wall.parent.name != 'wallBox'" -->
          <mdicon class="menu-button-icon wallSelect" :class="selectedWall.isLocked ? 'delete' : 'save'" :name="selectedWall.isLocked ? 'lock' : 'lock-open'" size="30"/>
        </button>
        <!-- <button class="menu-button">Offerte</button> -->
        <button v-if="wallSelected && wallSelected.wall.parent.name != 'wallBox' && wallSelected.wall.name != 'topgevelSelectbox' && !this.selectedWallObject?.isBorstwering" @click="changeSnap()">
          <mdicon class="menu-button-icon wallSelect" :title="`snappen van muren in achtergevel`" :class="snappingEnabled ? 'snapping-on' : 'snapping-off'" :name="snappingEnabled ? 'magnet-on' : 'magnet'" size="30"/>
        </button>
      </div>

      <div class="switchView">
        <button @click="enableMapMode(false)">
          <mdicon class="menu-button-icon rotate3D" name="rotate-3d" size="30" :class="!isMapOn ? 'active' : '' " />
        </button>
        <button @click="enableMapMode(true)">
          <mdicon class="menu-button-icon enableMap" name="floor-plan" size="30" :class="isMapOn ? 'active' : '' " />
        </button>
        <!-- <button class="menu-button">Offerte</button> -->
      </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "switch-menu",

    methods: {
      enableMapMode(val) {
        this.$store.dispatch('enableMapMode', val)
      },

      saveButton() {
        if (this.wallSelected) {
          this.saveWall()
        } else if (this.elementSelected) {
          this.saveElement()
        } else if (this.selectedLichtstraat) {
          this.saveLichtstraat()
        }
      },

      deleteButton() {
        if (this.wallSelected) {
          this.deleteWall()
        } else if (this.elementSelected) {
          this.deleteElement()
        } else if (this.selectedLichtstraat) {
          this.deleteLichtstraat()
        }
      },

      saveWall() {
        this.$store.dispatch('deselectWall')
      },

      deleteWall() {
        // console.log(this.wallSelected.wall)
        this.$store.dispatch('removeWall', this.wallSelected.wall)
        this.$store.dispatch('deselectWall')
      },

      saveElement() {
        this.$store.dispatch('deselectElement')
      },

      deleteElement() {
        // console.log(this.elementSelected)
        this.$store.dispatch('removeElement', this.elementSelected)
      },

      saveLichtstraat() {
        this.$store.dispatch('setSelectedLichtstraat', {lichtstraat: null})
      },

      deleteLichtstraat() {
        this.$store.commit('removeSelectedLichtstraat')
      },

      changeLock() {
        //console.log(this.selectedWall)
        //this.selectedWall.isLocked = !this.selectedWall.isLocked <!-- hoe het eerst was 4-7-2022 --> 
        this.wallSelected.isLocked = !this.wallSelected.isLocked // Hoe het nu is opgelost maar even kijken of alles verder nog oké is 4-7-2022
        this.$store.dispatch('changeLockMode', this.selectedWall)
      },

      changeSnap() {
        this.$store.dispatch('toggleSnapping')
      },

      back () {
        this.$store.dispatch('back')
      },

      forward () {
        this.$store.dispatch('forward')
      },

      save () {
        this.$store.dispatch('createShareLink')
      },

      toggleFullScreen() {
        this.$store.dispatch('toggleFullScreen')
      }
    },

    computed: {
      ...mapGetters({
        isMapOn: 'getIsMapActive',
        wallSelected: 'getSelectedWall',
        selectedWall: 'getSelectedWallObject',
        // selectedWall: 'getSelectedWall',
        elementSelected: 'getSelectedElement',
        selectedComponent: 'getSelectedWallComponent',
        snappingEnabled: 'getSnapping',
        selectedWallObject: 'getSelectedWallObject',
        fullScreen: 'getFullScreen',
        fullScreenPossible: 'getFullScreenPossible',
        selectedLichtstraat: 'getSelectedLichtstraat'
      })
    },
  }
    
</script>

<style lang="scss" scoped>
// styling for the whole sidebar
.sideBarMenu {
  position: fixed;
  width: 45px;
  height: calc(100vh - 50px);
  left: 00px;
  top: 25px;
  border-radius: 0px $radius-l $radius-l 0px;
  overflow: hidden;
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
  background-color: $c-primary;
}


// styling switch view part
.switchView { // styling van de 3D/Plattegrond knop
  height: 20%;
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-top: 20px;
    border: none;
    background-color: $c-primary;
    color: $c-primary-text;
    cursor: pointer;
  }
  
  button:last-child {
    margin-bottom: 30px;
  }
  
}

.confirmDelete {
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  button {
    margin-bottom: 20px;
    border: none;
    background-color: $c-primary;
    color: $c-primary-text;
    cursor: pointer;
  }

  button:first-child {
    margin-top: 30px;
  }
}

.active {
  color: $c-secondary;
}

.delete {
  color: #ff0000;
}
.save {
  color: $c-secondary;
}

.snapping-on {
  color: $c-secondary;
}

.snapping-off {
  color: $c-background-1;
}

@media (max-width: 999px) {
  .sideBarMenu {
   height: 45vh;
  }
//   .switchView { // styling van de 3D/Plattegrond knop
//     width: 20%;
//     display: flex; 
//     flex-direction: row;
//     align-items: center;
//     justify-content: space-evenly;

//   button {
//     margin-top: 0px;
//     border: none;
//     background-color: $c-primary;
//     color: $c-primary-text;
//     cursor: pointer;
//   }
  
//   button:last-child {
//     margin-bottom: 0px;
//   }
  
// }
//   .confirmDelete {
//     width: 50%;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: space-evenly;
  
//     button {
//       margin-bottom: 0px;
//       border: none;
//       background-color: $c-primary;
//       color: $c-primary-text;
//       cursor: pointer;
//     }

//     button:first-child {
//       margin-top: 0px;
//     }
//   }
}

</style>