<template>
  <div class="create">
    <div class="create-card">
      <form @submit.prevent="createAccount()" class="default-form">
        
        <h1 class="create-card-title">Account aanmaken</h1>

        <p class="create-card-info">Voer de email in waarop u de uitnodiging hebt ontvangen en kies uw wachtwoord.</p>

        <div class="default-form-field">
          <label for="email" class="default-form-field-label">Email</label>
          <input type="email" id="email" name="email" class="default-form-field-input" v-model.trim="account.email">
        </div>

        <div class="default-form-field">
          <label for="password" class="default-form-field-label">Wachtwoord</label>
          <input type="password" id="password" name="password" class="default-form-field-input" v-model.trim="account.password">
        </div>

        <div class="default-form-field">
          <label for="passwordCheck" class="default-form-field-label">Wachtwoord check</label>
          <input type="password" id="passwordCheck" name="passwordCheck" class="default-form-field-input" v-model.trim="account.passwordCheck">
        </div>

        <div class="default-form-button">
          <LoadingButton type="submit" :loading="loading" :disabled="!formValid">creëer account</LoadingButton>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'

  export default {
    name: "CreateAccount",

    components: {
      LoadingButton
    },

    data () {
      return {
        account: {
          token: null,
          email: "",
          password: "",
          passwordCheck: "",
        },
        loading: false,
        formValid: false,
      }
    },

    methods: {
      setToken () {
        this.account.token = this.$route.params.token
      },

      validate () {
        if (!this.account.email) {
          this.formValid = false
        } else if (!this.account.password) {
          this.formValid = false
        } if (!this.account.passwordCheck) {
          this.formValid = false
        } else if (this.account.password !== this.account.passwordCheck) {
          this.formValid = false
        } else {
          this.formValid = true
        }
      },

      async createAccount () {
        this.loading = true

        try {

          await this.$store.dispatch('createAccount', this.account)

        } catch (err) {
          this.loading = false
        }

        this.loading = false
      },
    },

    created () {
      this.setToken()
    },

    watch: {
      $route () {
        this.setToken()
      },
      account: {
        deep: true,
        immediate: false,
        handler () {
          this.validate()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

.default-form-field-label {
  min-width: 160px;
}

.create {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &-card {
    display: flex;
    padding: 25px;
    margin: 15px;
    border-radius: $radius-m;
    box-shadow: $shadow-1;

    &-title {
      font-weight: 600;
      margin: 0px;
    }

    &-info {
      text-align: start;
      font-weight: 500;
    }
  }
}
</style>