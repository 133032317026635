<template>
  <!-- { component: data, colorIndex: selectedColor, optionIndex: selectedOption, variantIndex: selectedVariant })"> -->
  <button :class="onMobile ? 'button-wrapper-mobile' : 'button-wrapper'">
    <div class="card" :class="selected ? 'card-selected' : '' ">
      <div class="card-header">
        <!-- <img :src="`${previewsServer + models}previews/${data.code +  (data.options.length > 0 ? data.options[selectedOption].subCode : '')}.png`" alt="" @error="setBackupImage($event, data)"> -->
        <img :src="`${previewsServer}/textures/${imgUrl}`" 
          alt="" @error="setBackupImage($event)">

        <!-- COLORS -->
        <!-- <div class="card-header-colors" v-if="data.colors.length > 0"> -->
          <!-- v-for="(color, index) in data.colors" -->
        <div class="card-header-colors" v-if="data.colors">
          <button 
            class="card-header-colors-button" 
            v-for="(color, index) in data.colors" 
            :key="index" 
            :title="`${color.name} (${color.code})`"
            :style="`background-color: ${color.hexCode};`"
            :class="scolor ? scolor.code === color.code ? 'card-header-colors-button-active' : '' : ''"
            @click="
            $emit('setSchuifwandKleur', color)"
          >
          </button>
        </div>
      </div>

      <h3 style="text-align: left; margin-bottom: 5px">{{ variantText }}</h3>
      <!-- VARIANTS -->
      <!-- <div class="card-options" v-if="data.variants && data.variants.length > 0"> -->
      <div class="card-options" v-if="data.variants" style="margin-bottom: 10px;">
        <button 
          v-for="(variant, index) in data.variants" 
          :key="index"
          :class="svariant ? svariant === variant ? 'card-options-active' : '' : ''"
          @click="
          $emit('setSchuifwandRichting', variant)"
          >
          {{ variant }}
        </button>
      </div>

      <div v-if="variantText2">
        <h3 style="text-align: left">{{ variantText2 }}</h3>
        <!-- VARIANTS -->
        <!-- <div class="card-options" v-if="data.variants && data.variants.length > 0"> -->
        <div class="card-options" v-if="variants2">
          <button 
            v-for="(variant, index) in variants2" 
            :key="index"
            :class="index === svariant2 ? 'card-options-active' : ''"
            @click="
            $emit('setSchuifwandPreference', variant)"
            >
            {{ variant.glasplaatAantal }} panelen ({{ variant.glasplaatBreedte }}mm)
          </button>
        </div>
      </div>

    </div>
  </button>
</template>

<script>
// import { mapGetters } from 'vuex';

  export default {
    name: "ComponentCard",

    props: {
      data: Object,
      selected: Boolean,
      variantText: String,
      variantText2: String,
      variants2: Array,
      cselected: Boolean,
      wSelected: Object, // selected wall data
      scolor: Object,
      svariant: String, // feature 1394
      svariant2: Number,
      imgUrl: String,
    },

    data () {
      // console.log('component card data',this.data)
      return {
        models: import.meta.env.VITE_VUE_APP_MODELS_LOCATION,
        previewsServer: import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER,
        lastCodeSelection: '', // for choosing .glb file
        onMobile: false,
      }
    },

    created() {
      let regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      this.onMobile = regex.test(navigator.userAgent)
    },

    methods: {
      setBackupImage(e) {
        e.target.src = `https://fakeimg.pl/400x400/?text=${this.imgUrl}&font=bebas`
      },
    },
  }
</script>

<style lang="scss" scoped>

.card {
  display: flex;
  flex-grow: 1;
  // height: calc(100% - 20px);
  flex-direction: column;
  outline: 3px solid $c-border-1;
  border-radius: $radius-m;
  padding: 10px;
  transition: outline-color 100ms ease;
  border: none;
  background-color: transparent;
  scroll-margin: 150px;

  &:hover {
    outline-color: $c-secondary;
    cursor: context-menu;
  }

  &-selected {
    outline-color: $c-secondary;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    height: 150px;
    max-width: 100%;

    & img {
      min-height: 150px;
      max-height: 150px;
      width: calc(100% - 24px);
      object-fit: contain;
    }

    &-colors {
      display: flex;
      flex-direction: column;
      gap: 6px;

      &-button {
        height: 24px;
        width: 24px;
        outline: 3px solid $c-border-1;
        outline-offset: -2px;
        border: none;
        border-radius: $radius-s;
        transition: outline-color 100ms ease;
        cursor: pointer;

        &:hover {
          outline-color: $c-secondary;
        }

        &-active {
          outline-color: $c-secondary;
        }
      }
    }

    &-glastypes {
      display: flex;
      flex-direction: column;
      gap: 6px;

      &-button {
        height: 24px;
        width: 32px;
        outline: 3px solid $c-border-1;
        outline-offset: -2px;
        border: none;
        border-radius: $radius-s;
        transition: outline-color 100ms ease;
        cursor: pointer;
        margin-right: 0.4em;

        &:hover {
          outline-color: $c-secondary;
        }

        &-active {
          outline-color: $c-secondary;
        }        
      }
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;

    & h3 {
      font-size: 1rem;
      text-align: left;
    }

    &-size {
      display: flex;
      align-items: center;
      gap: 15px;
      font-weight: 500;
    }
  }

  &-options {
    display: flex;
    gap: 15px;

    & button {
      flex-grow: 1;
      background-color: $c-background-1;
      border: none;
      border-radius: $radius-s;
      padding: 5px;
      font-weight: 600;
      font-size: 0.9rem;
      color: $c-primary;
      transition: color 100ms ease, background-color 100ms ease;
      cursor: pointer;
    }

    &-active {
      background-color: $c-primary !important;
      // color: $c-secondary  !important;
      color: $c-secondary-bright  !important;
    }
  }
}

.button-wrapper {
  border: none;
  display: flex;
  // margin: auto;
  padding: 0px;
  background-color: transparent;
  min-height: 100%;
  // max-width: 16.5rem;
  height: 15.5rem;
}

.button-wrapper-mobile {
  border: none;
  display: flex;
  margin: auto;
  padding: 0px;
  background-color: transparent;
  min-height: 100%;
  width: 93vw;
  height: 14rem;
}

.button-wrapper:hover {
  cursor: context-menu;
}

button[disabled] {
  cursor: not-allowed;
  filter: grayscale(100%);
  border: dotted 2px #bdbdbd;
}

</style>