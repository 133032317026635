<template>
  <div class="WallDimension">
    <button class="lockButton left"  @click="changeLockedSide()"> <!--:class="!selectedWall.isLeftLocked ? 'colorChange': ''-->
      <!-- <img  class="buttonImage" size="24" :src="`${server}/textures/ui/arrow_right.svg`"> -->
      <!-- <svg style="width:24px;height:24px" viewBox="0 0 24 24">
        <path class="buttonImage" :class="!selectedWall.isLeftLocked ? 'colorChange': '' " fill="currentcolor" d="M5.5,4.14L4.5,5.86L15,12L4.5,18.14L5.5,19.86L19,12L5.5,4.14Z"  />
      </svg> -->
      <mdicon name="less-than" class="buttonImage" :class="(isLookingAtInsideOfSelectedWall ? selectedWall?.isLeftLocked : !selectedWall?.isLeftLocked) ? 'colorChange': '' "/>
    </button>
    <div class="inputDiv">
      <input ref="inputField" class="widthInput" type="text" :min="getWallMinSize" :max="getWallMaxSize" :value="(Math.round(selectedWallWidth*2)/2).toString().replaceAll('.', ',')" @change="changeWallSize($event)">
      <p class="inputSubText">mm</p>
    </div>
    <button class="lockButton right" @click="changeLockedSide()"> <!--:class="selectedWall.isLeftLocked ? 'colorChange': '' "-->
      <!-- <img class="buttonImage" size="24" style="-webkit-transform: scaleX(-1); transform: scaleX(-1);" :src="`${server}/textures/ui/arrow_right.svg`"> -->
      <!-- <svg style="width:24px; height:24px" viewBox="0 0 24 24">
        <path class="buttonImage" :class="selectedWall.isLeftLocked ? 'colorChange': '' " fill="currentcolor" d="M18.5,4.14L19.5,5.86L8.97,12L19.5,18.14L18.5,19.86L5,12L18.5,4.14Z" />
      </svg> -->
      <mdicon name="greater-than" class="buttonImage" :class="(isLookingAtInsideOfSelectedWall ? !selectedWall?.isLeftLocked : selectedWall?.isLeftLocked) ? 'colorChange': '' "/>
    </button>
    <!-- <button class="menu-button">Offerte</button> -->
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "wallDimensions-menu",

    data () {
      return {
        server: import.meta.env.VITE_VUE_APP_SERVER,
      }
    },

    methods: {
      changeLockedSide() {
        this.selectedWall.isLeftLocked = !this.selectedWall.isLeftLocked
      },

      changeWallSize(event) {
        const newWidth = Math.round((parseFloat(event.target.value.replaceAll(',', '.')))*2)/2
        this.$store.dispatch('setWallWidth', {wall: this.selectedWall, width: newWidth, side: this.selectedWall.isLeftLocked ? 1 : 0})
      },

    },

    computed: {
      ...mapGetters({
        selectedWall: 'getSelectedWallObject',
        getWallMinSize: 'getWallMinSize',
        getWallMaxSize: 'getWallMaxSize',
        selectedWallWidth: 'getSelectedWallWidth',
        cameraDirection: 'getCameraDirection'
      }),

      isLookingAtInsideOfSelectedWall() {
        if(this.selectedWall) {
          if (this.selectedWall.alignsXAxis) {
            if (this.selectedWall.alignedRotation) {
              // links
              return this.cameraDirection.z < 0
            } else {
              // rechts
              return this.cameraDirection.z > 0
            }
          } else {
            if (this.selectedWall.alignedRotation) {
              // voor
              return this.cameraDirection.x < 0
            } else {
              // achter
              return this.cameraDirection.x > 0
            }
          }
        }
      }
    },

    watch: {
      selectedWallWidth: {
        handler: function () {
          this.$refs.inputField.focus()
          const inputLength = String(this.selectedWallWidth).length
          this.$refs.inputField.setSelectionRange(inputLength, inputLength)
          // regel hieronder is voor chomium browsers (https://issues.chromium.org/issues/40345011)
          setTimeout(() => this.$refs.inputField.setSelectionRange(inputLength, inputLength), 0)
        },
        deep: true
      }
    },

    mounted() {
      document.getElementsByClassName("widthInput")[0].addEventListener("keypress", function (evt) {
        if(evt.which != 8 && evt.which != 44 && evt.which != 0 && evt.which !== 13 && evt.which < 48 || evt.which > 57)
        {
          evt.preventDefault();
        }
        // console.log(evt.target.value)
      });
      this.$nextTick(() => this.$refs.inputField.focus())
    }
  }
    
</script>

<style lang="scss" scoped>
.WallDimension {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80px;
  background-color: $c-primary;
  border-radius: 20px;
  width: 500px;
}

.lockButton {
  width: 50px;
  height: 50px;
  // border: 1px solid $c-border-1;
  border: none;
  background-color: $c-primary;
  color: #fff;
  cursor: pointer;
  -webkit-transition: background-color .2s linear;
  -ms-transition: background-color .2s linear;
  -moz-transition: background-color .2s linear;
    -o-transition: background-color .2s linear;
  transition: background-color .2s linear;
}

.buttonImage {
  color: white;
}

.left {
  border-radius: 6px 0px 0px 6px;
  border-right: 2px solid white;
}
.right {
  border-radius: 0px 6px 6px 0px;
  border-left: 2px solid white;
}

.colorChange {
  color: $c-secondary;
}
// .unlocked {
//   background-color: $c-secondary;
// }

.widthInput {
  height: 50px;
  // border: 1px solid $c-border-1;
  border: none;
  background-color: $c-primary;
  text-align: center;
  vertical-align: top;
  font-size: 20px;
  color: $c-secondary;
  width: 400px;
  // height: calc(100% - 5px);
}
.widthInput::-webkit-outer-spin-button,
.widthInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.widthInput {
  -moz-appearance: textfield;
}
.widthInput:focus {
    outline: none;
}

.inputDiv {
  vertical-align: top;
  display: inline-block;
  position: relative;
}

.inputSubText {
  display: inline-block;
  position: absolute;
  right: 6px;
  bottom: 0px;
  margin: 0px;
  color: white;
}

@media (max-width: 999px) {
  .WallDimension {
    position: absolute;
    left: 50vw;
  }

.widthInput {
  width: 200px;
} 

}

</style>