<template>
  <button :class="onMobile ? 'button-wrapper-mobile' : 'button-wrapper'" :style="setWrapperStyle()" @click="$emit('select')">
    <div class="card" :class="selected ? 'card-selected' : '' ">
      <div class="card-header">
        <img :src="`${previewsServer + models}previews/${data.code}.png`" alt="" @error="setBackupImage($event, data)">
      </div>

      <div class="card-info">
        <h3>{{ data.shortName }}</h3>
      </div>
    </div>
  </button>
</template>

<script>

  export default {
    name: "OptionCard",

    props: {
      data: Object,
      selected: Boolean,
      hasAutomargin: {
        type: Boolean,
        default: true
      },
      hasWidthlimit: {
        type: Boolean,
        default: true
      },
    },

    data () {
      return {
        models: import.meta.env.VITE_VUE_APP_MODELS_LOCATION,
        previewsServer: import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER,
        onMobile: false,
      }
    },

    created() {
      let regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      this.onMobile = regex.test(navigator.userAgent)
    },

    methods: {
      setBackupImage(e, data) {
        e.target.src = `https://fakeimg.pl/400x400/?text=${data.code +  (data.options.length > 0 ? data.options[this.selectedOption].subCode : '')}&font=bebas`
      },
      setWrapperStyle() {
        return `${this.hasAutomargin ? '' : 'margin: 0px;'}${this.hasWidthlimit ? '' : 'max-width: none; width: 100%;'}`
      }
    },
      
  }
</script>

<style lang="scss" scoped>

.card {
  display: flex;
  flex-grow: 1;
  height: calc(100% - 20px);
  flex-direction: column;
  outline: 3px solid $c-border-1;
  border-radius: $radius-m;
  padding: 10px;
  transition: outline-color 100ms ease;
  border: none;
  background-color: transparent;
  scroll-margin: 150px;

  &:hover {
    outline-color: $c-secondary;
    cursor: pointer;
  }

  &-selected {
    outline-color: $c-secondary;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    height: 150px;

    & img {
      min-height: 150px;
      max-height: 150px;
      width: 100%;
      object-fit: contain;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & h3 {
      font-size: 1rem;
      text-align: left;
      margin: 0px;
    }
  }
}

.button-wrapper {
  border: none;
  display: flex;
  margin: auto;
  padding: 0px;
  background-color: transparent;
  min-height: 100%;
  max-width: 16.5rem;
  height: 15.5rem;
}

.button-wrapper-mobile {
  border: none;
  display: flex;
  margin: auto;
  padding: 0px;
  background-color: transparent;
  min-height: 100%;
  width: 93vw;
  height: 14rem;
}

</style>