<template>
  <div class="InvalidLichtstraten">
    <h1>
      Er zijn {{ invalidLichtstraten.length }} lichtstraten onjuist geplaatst.
      <br>
      <span v-if="$route.path==='/cfg/roof/lichtstraten' && isMapOn">Klik op een maat om deze aan te passen.</span>
      <span v-else-if="$route.path==='/cfg/roof/lichtstraten'">Houd ingedrukt op een lichtstraat om deze te verplaatsen.</span>
      <span v-else>Ga naar menu <span class="redirect" @click="toLichtstraten()">lichtstraten</span> om deze aan te passen.</span>
    </h1>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import router from '../../router/index.js'

  export default {
    name: "invalidLichtstraten-menu",

    methods: {
      toLichtstraten() {
        router.push('/cfg/roof/lichtstraten')
      },
    },

    computed: {
      ...mapGetters({
        invalidLichtstraten: 'getInvalidLichtstraten',
        isMapOn: 'getIsMapActive',
      }),
    },
  }
    
</script>

<style lang="scss" scoped>
.InvalidLichtstraten {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80px;
  background-color: $c-primary;
  border-radius: 20px;
  width: 500px;

  h1 {
    color: $c-primary-text;
    font-size: 1em;
    font-weight: 600;
    padding: 10px 30px;
  }
}
.redirect {
  color: $c-secondary;
  cursor: pointer;
  text-decoration: underline;
}
</style>