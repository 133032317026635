<template>
  <div class="nav">

    <div class="nav-route">

      <div class="nav-route-part" v-for="(route, i) in routes" :key="i">

        <div class="nav-route-part-seperation" v-if="i > 0">
          <mdicon name="chevron-right" class="nav-route-part-seperation-icon" size="25" />
        </div>

        <router-link class="nav-route-link" :to="route.path">{{ route.name }}</router-link>
      </div>
    </div>

    <div class="nav-spacer" />

    <div class="nav-item" v-if="auth && superUser">
      <router-link class="nav-route-link" to="/account/invite">Uitnodigen</router-link>
    </div>

    <div class="nav-item">
      <router-link class="nav-route-link" to="/account/login">Uitloggen</router-link>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "CMSNavbar",

    data () {
      return {
        route: "",
        routes: []
      }
    },

    methods: {
      setRoute() {
        this.route = this.$router.currentRoute.value.fullPath
        
        let routes = this.route.split("/")
        let formatedRoutes = []

        let path = ""

        for (let i = 0; i < routes.length; i++) {
          if (routes[i].length > 0) {

            path = path + '/' + routes[i]

            const model = this.models.find(model => model.id === routes[i])
            const routeName = model ? model.name : routes[i]

            formatedRoutes.push({
              name: routeName,
              path: path,
            })
          }
        }
        console.log(this.models)
        this.routes = formatedRoutes
      },

      async get () {
        await this.$store.dispatch('getModelsInfo')
      },
    },
    
    computed: {
      ...mapGetters({
        auth: 'isAuth',
        superUser: 'isSuperUser',
        models: 'getModels'
      }),
    },

    async created () {
      if(!this.models) {
        await this.get()
      }
      this.setRoute()
    },

    watch: {
      $route () {
        this.setRoute()
      },
    }
  }
</script>

<style lang="scss" scoped>

.nav {
  display: flex;
  padding: 10px 30px;
  min-height: 20px;
  box-shadow: $shadow-1;
  align-items: center;
  position: fixed;
  top: 0;
  width: calc(100vw - 60px);
  gap: 15px;
  background-color: $c-primary;
  z-index: 10;

  &-spacer {
    content: "";
    flex-grow: 1;
  }

  &-item {

    &-link {
      color: white
    }
  }

  &-route {
    display: flex;

    &-link {
      color: white;
      cursor: pointer;
      font-weight: 600;
      font-size: 1.2rem;
      text-decoration: none;
      
      &:hover {
        color: $c-secondary;
      }
    }

    &-part {
      display: flex;
      align-items: center;

      &-seperation {

        &-icon {
          color: white;
        }
      }
    }
  }
}

</style>