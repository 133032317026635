<template>
  <div class="background" :style="isMapOn ? {'background-color': '#DCDCC8'} : { 'box-shadow' : 'rgb(200 200 200) 0px 0px 25em 2.5em inset' }">
    <div id="threeCanvas" ref="threeCanvas" :style="`cursor: ${cursorStyle};`" />
    <div id="fps" style="position: absolute; top: 0px; left: 0px"></div>
    <div id="frameTime" style="position: absolute; top: 0px; left: 50px"></div>
    <div id="lastUpdated" style="position: absolute; top: 0px; right: 10px"></div>

    <div class="devTools_container" v-if="devMode"><DevTools :muurInstances="getMuurInstances" @selectWall="selectWallGroup"></DevTools></div>
    <div class="scaling_container" v-if="scalingMode"><TextureScaling :texture="selectedDakafwerking" @updateTextureScaling="updateTextureScaling" @setTextureScaling="setTextureScaling"></TextureScaling></div>
    <div class="specImages_container" v-if="specImagesMode"><RegenerateSpecImages></RegenerateSpecImages></div>
  </div>
</template>

<script>
  var oldDate = new Date()
  import { mapGetters } from 'vuex'
  import { markRaw } from 'vue'
  import * as THREE from 'three'
  // import * as $ from 'jquery'
  // import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
  import { OrbitControls } from 'three-stdlib'
  //import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
  import * as boxBuffer from '@/three/buffer-geometries/boxBuffer.js'
  import router from '../router/index'
  import * as planeBuffer from '@/three/buffer-geometries/plane.js'
  import DevTools from '@/components/dev_tools/DevTools.vue'
  import TextureScaling from '@/components/dev_tools/TextureScaling.vue'
  import RegenerateSpecImages from '@/components/dev_tools/RegenerateSpecImages.vue'
  import { v4 as uuid } from 'uuid'
  import * as TWEEN from '@tweenjs/tween.js';
  //import {EffectPass, EffectComposer, NormalPass, SSAOEffect, BlendFunction, DepthDownsamplingPass, RenderPass} from 'postprocessing';
  /*import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
  import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js';
  import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
  import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
  import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
  import { GTAOPass } from './../three/scene/shaders/GTAOPass.js';*/
  // import { GUI } from 'dat.gui';
  //import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
  //import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
  //import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass.js';
	//import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
  //import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
  //import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
	//import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass.js';
  //import { CSM } from 'three/examples/jsm/csm/CSM.js';
  //import $ from 'jquery'
  // model files
  //import { VertexNormalsHelper } from 'three/examples/jsm/helpers/VertexNormalsHelper.js';
  import * as POSTPROCESSING from "postprocessing"
  import { SSAOEffect } from "realism-effects"
  import Stats from 'three/examples/jsm/libs/stats.module'
  import store from '../store/index'
  // import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js'

  export default {
    name: "ThreeView",
    
    data () {
      let regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      this.isMobile = regex.test(navigator.userAgent)
      this.stats = new Stats()
      if(import.meta.env.VITE_VUE_APP_SERVER === 'http://localhost:8080') {
        document.body.appendChild(this.stats.dom)
      }

      THREE.ColorManagement.enabled = false;
      const scene = markRaw(new THREE.Scene())
      const renderer = markRaw(new THREE.WebGLRenderer({ alpha: true, powerPreference: "high-performance", antialias: true, stencil: false, depth: true /*logarithmicDepthBuffer: true*/}))
      //renderer.gammaFactor = 2.2;
      renderer.physicallyCorrectLights = true //donker model bug
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap
      renderer.autoClear = false;
      renderer.setClearColor(0x000000, 0.0);
      renderer.toneMapping = THREE.ACESFilmicToneMapping;
      //renderer.toneMappingExposure = 2.3
      // scene.background = new THREE.Color(0x757575).convertSRGBToLinear();

      // const loader = new THREE.CubeTextureLoader();
      // let textureCube = loader.load([ 
      //   require('@/assets/test/posx.jpg'), 
      //   require('@/assets/test/negx.jpg'), 
      //   require('@/assets/test/posy.jpg'), 
      //   require('@/assets/test/negy.jpg'), 
      //   require('@/assets/test/posz.jpg'), 
      //   require('@/assets/test/negz.jpg') 
      // ]);
      // textureCube.encoding = THREE.sRGBEncoding;

      // scene.background = textureCube;

      // const ambientLight = new THREE.AmbientLight(0xffffff, 2.2) //0.3
      const ambientLight = markRaw(new THREE.AmbientLight('#8c9ca2', 5))
      if(this.isMobile) {
        ambientLight.color.set('#b9d5ff')
        ambientLight.intensity = 2.1 // test michiel task 1546// test michiel task 1546
      }
      /*const pointLight = new THREE.PointLight(0xffffff, 1)
      pointLight.shadow.mapSize.width = 1024
      pointLight.shadow.mapSize.height = 1024
      pointLight.shadow.camera.near = 100
      pointLight.shadow.camera.far = 100000
      pointLight.shadow.radius = 10
      pointLight.castShadow = true
      pointLight.position.set( -1000, 1000, -1000)
      scene.add(pointLight)*/

      //const hemiLight = new THREE.HemisphereLight( 0x0000ff, 0x00ff00, 0.2); 
      const hemiLight = markRaw(new THREE.HemisphereLight( 0x3D423C, 0xedd59e, 0)); //0.2
      scene.add(hemiLight)

      const light = markRaw(new THREE.DirectionalLight( 0xf2deae, 1.4 )); //1.5
      //const light = new THREE.PointLight(0xffee88, 1000)
      // light.position.set(3270/2-5000,5000,8020/2-5000);
      light.position.set(-4800,6000,3100); // test michiel task 1546
      
      /*new RGBELoader().load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}alps_field_2k.hdr`, (texture) => {
        console.log(texture)
        texture.mapping = THREE.EquirectangularReflectionMapping
        scene.environment = texture
        scene.background = texture
      })*/
      
      //light.position.set(130,20,130)
      //light.position.set(5000,5000,5000)
      light.castShadow = true;
      light.shadow.mapSize.width = 4096//1024; // default
      light.shadow.mapSize.height = 4096//1024; // default
      light.shadow.camera.near = 0.5; // default
      light.shadow.camera.far = 30000; // default
      light.shadow.camera.left = Math.sqrt(Math.pow(8020,2)+Math.pow(3270,2))/2*-1-500//-5000;
      light.shadow.camera.right = Math.sqrt(Math.pow(8020,2)+Math.pow(3270,2))/2+500//5000;
      light.shadow.camera.top = light.shadow.camera.right//5000;
      light.shadow.camera.bottom = light.shadow.camera.left//-5000;
      light.shadow.normalBias = 0.051
      light.shadow.bias = 0//0.0004 //0.00019
      scene.add( light );
      //setTimeout(() => {
        const targetObject = new THREE.Object3D();
        targetObject.position.set(0,0,0)
        scene.add(targetObject);
        light.target = targetObject;
      //}, 2000);
      // const helper = new THREE.CameraHelper( light.shadow.camera );
      // scene.add( helper );
      // this.helper = helper

      // shadow bias GUI
      // const gui = new GUI();
      // gui.add( light.shadow, 'bias', 0, 0.01 ).name('shadow bias');

      /*setTimeout(() => {
        scene.add( helper );
        const targetObject = new THREE.Object3D();
        targetObject.position.set(3000,0,3000)
        scene.add(targetObject);
        light.target = targetObject;

        const sides = {
          front: THREE.FrontSide,
          back: THREE.BackSide,
          double: THREE.DoubleSide
        }

        const params = {
          bias: 0,
          side: Object.keys( sides )[ 0 ],
          x: -2000,
          y: 5000,
          z: -2000,
          intensity: 1.4,
          ENVintensity: 2.2
        };

        const gui = new GUI();

				gui.add( params, 'bias' );
        gui.add( params, 'side', Object.keys( sides ) );
        gui.add( params, 'x');
        gui.add( params, 'y');
        gui.add( params, 'z');
        gui.add( params, 'intensity');
        gui.add( params, 'ENVintensity');

        /*gui.add( ssaoPass, 'output', {
					'Default': SSAOPass.OUTPUT.Default,
					'SSAO Only': SSAOPass.OUTPUT.SSAO,
					'SSAO Only + Blur': SSAOPass.OUTPUT.Blur,
					'Beauty': SSAOPass.OUTPUT.Beauty,
					'Depth': SSAOPass.OUTPUT.Depth,
					'Normal': SSAOPass.OUTPUT.Normal
				} ).onChange( function ( value ) {

					ssaoPass.output = parseInt( value );

				} );
				gui.add( ssaoPass, 'kernelRadius' ).min( 0 ).max( 32 );
				gui.add( ssaoPass, 'minDistance' ).min( 0.001 ).max( 0.02 );
				gui.add( ssaoPass, 'maxDistance' ).min( 0.01 ).max( 0.3 );*/

        /*gui.add( saoPass.params, 'output', {
					'Beauty': SAOPass.OUTPUT.Beauty,
					'Beauty+SAO': SAOPass.OUTPUT.Default,
					'SAO': SAOPass.OUTPUT.SAO,
					'Depth': SAOPass.OUTPUT.Depth,
					'Normal': SAOPass.OUTPUT.Normal
				} ).onChange( function ( value ) {

					saoPass.params.output = parseInt( value );

				} );
				gui.add( saoPass.params, 'saoBias', - 1, 1 );
				gui.add( saoPass.params, 'saoIntensity', 0, 1 );
				gui.add( saoPass.params, 'saoScale', 0, 10 );
				gui.add( saoPass.params, 'saoKernelRadius', 1, 100 );
				gui.add( saoPass.params, 'saoMinResolution', 0, 1 );
				gui.add( saoPass.params, 'saoBlur' );
				gui.add( saoPass.params, 'saoBlurRadius', 0, 200 );
				gui.add( saoPass.params, 'saoBlurStdDev', 0.5, 150 );
				gui.add( saoPass.params, 'saoBlurDepthCutoff', 0.0, 0.1 );*/
				/*gui.open();

        const geometry = new THREE.BoxGeometry( 1000, 1000, 1000 );
        const material = new THREE.MeshPhysicalMaterial( {color: 0x00ff00} );
        const cube = new THREE.Mesh( geometry, material );
        cube.position.set(1000,1000,1000)
        cube.castShadow = true
        cube.receiveShadow = true
        scene.add( cube );
        
        //material.wireframe = true
        //material.side = THREE.FrontSide
        const geometry1 = new THREE.BufferGeometry()
        geometry1.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(1000, 1000, 1000)), 3))
        geometry1.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
        geometry1.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
        geometry1.setIndex(boxBuffer.indexBuffer());
        //const geometry1 = new THREE.BoxBufferGeometry( 1000, 2000, 3000 );
        console.log(geometry1)
        const staander0 = new THREE.Mesh( geometry1, material)
        staander0.castShadow = true
        staander0.position.set(2500, 1000, 1000)
        const helpert = new VertexNormalsHelper( staander0, 200, 0x00ff00 );
        scene.add(staander0)
        scene.add(helpert)
        
        /*scene.traverse( function( object ) {
          if ( object.material ) {
            csm.setupMaterial(object.material)
            console.log(object.material)
          }
        } );*/

        /*setInterval(function () {
          light.shadow.bias = params.bias
          light.position.set(params.x, params.y, params.z)
          light.intensity = params.intensity
          ambientLight.intensity = params.ENVintensity
          //material.side = sides[params.side]
        }, 50)

      }, 2000);*/

      /*setTimeout(() => {
        scene.traverse( function ( obj ) {
          console.log(obj)
          if ( obj.isMesh ) {
            obj.castShadow=true
            obj.receiveShadow=true
          }
        })
      }, 5000);*/

      const camera = markRaw(new THREE.PerspectiveCamera(
        40, 
        (window.innerWidth >= 1000 ? window.innerWidth+530 : window.innerWidth+70) / (window.innerWidth >= 1000 ? window.innerHeight : (window.innerHeight / 2) + 15 ), // +15 for the border radius of the UI
        10, 
        100000
      ))

      const cameraGroup = markRaw(new THREE.Group())
      cameraGroup.add(camera)


      // setInterval(() => {
      //   const meshes = []
      //   function traverseGroup(group) {
      //     group.children.forEach((child) => {
      //       if (child.isMesh) {
      //         meshes.push(child)
      //       }
      //       if (child.children) {
      //         traverseGroup(child)
      //       }
      //     })
      //   }
      //   traverseGroup(scene)
      //   console.log("renderlist", meshes)
      // }, 10000)

      /*const renderPass = new RenderPass( scene, camera );

      const gammaPass = new ShaderPass( GammaCorrectionShader );

      const fxaaPass = new ShaderPass( FXAAShader );
      fxaaPass.uniforms[ 'resolution' ].value.set( 1 / window.innerWidth, 1 / window.innerHeight );

      const gtaoPass = new GTAOPass( scene, camera );
      window.gtaoPass = gtaoPass;


      var params = {
				enabled: true,
				rotationJitter: 1,
				radiusJitter: 0,

				directionOffset: 0.0,
				stepOffset: 0.0,
				numSteps: 8,
				numDirections: 1,
				intensity: 1,
				radius: 2.0,

				enableFalloff: true,
				falloffStart: 0.4,
				falloffEnd: 2.0,

				blurMode: GTAOPass.BOX_BLUR,
				blurIterations: 5,
				blurStride: 1,

				display: GTAOPass.DEFAULT,
				renderTargetScale: 0.5,
				scene: 'pyramid',

				ambientLightColor: [ 255, 255, 255 ],
				ambientLightIntensity: 0,

				enableLightBounce: false,
				lightBounceIntensity: 1.0,
			};
      gtaoPass.debug.display = parseInt( params.display );
      gtaoPass.enabled = params.enabled;
      gtaoPass.singlePass = params.singlePass;
      gtaoPass.rotationJitter = parseInt( params.rotationJitter );
      gtaoPass.radiusJitter = parseInt( params.radiusJitter );
      gtaoPass.blurMode = parseFloat( params.blurMode );
      gtaoPass.blurIterations = params.blurIterations;
      gtaoPass.blurStride = params.blurStride;
      gtaoPass.numSteps = params.numSteps;
      gtaoPass.numDirections = params.numDirections;
      gtaoPass.intensity = params.intensity;
      gtaoPass.radius = params.radius;
      gtaoPass.falloffStart = params.falloffStart;
      gtaoPass.falloffEnd = params.falloffEnd;
      gtaoPass.enableFalloff = params.enableFalloff;
      gtaoPass.directionOffset = params.directionOffset;
      gtaoPass.stepOffset = params.stepOffset;

      gtaoPass.lightBounceIntensity = params.enableLightBounce ? params.lightBounceIntensity : 0.0;

      // ambientLight.color.setRGB( ...params.ambientLightColor.map( c => c / 255 ) );
      // ambientLight.intensity = params.ambientLightIntensity;

      gtaoPass.ambientColor.setRGB( ...params.ambientLightColor.map( c => c / 255 ) );
      gtaoPass.ambientIntensity = params.ambientLightIntensity;

      const floatRT = new THREE.WebGLRenderTarget( 1, 1, {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.LinearFilter,
        format: THREE.RGBAFormat,
        type: THREE.HalfFloatType,
      } );
      const composer = new EffectComposer( renderer, floatRT );
      composer.setSize( window.innerWidth, window.innerHeight );
      composer.setPixelRatio( window.devicePixelRatio );
      composer.addPass( renderPass );
      composer.addPass( gtaoPass );
      console.log(gammaPass)
      //composer.addPass( gammaPass );
      //composer.addPass( fxaaPass );*/
      
      const composer = markRaw(new POSTPROCESSING.EffectComposer(renderer))
      
      const renderPass = markRaw(new POSTPROCESSING.RenderPass(scene, camera))//new RenderPass( this.scene, this.camera );
      composer.addPass( renderPass );

      const ssaoOptions = {
        resolutionScale: 1,
        spp: 16,
        distance: 65,
        distancePower: 0.15,
        power: 2,
        bias: 250,
        thickness: 0.075,
        color: 0,
        useNormalPass: false,
        velocityDepthNormalPass: null,
        normalTexture: null,
        iterations: 1,
        samples: 16
      }

      const ssaoEffect = markRaw(new SSAOEffect(composer, camera, scene, ssaoOptions))
      const ssaoPass = markRaw(new POSTPROCESSING.EffectPass(camera, ssaoEffect))
      // console.log("ssaoPass", ssaoPass, ssaoEffect)
      composer.addPass(ssaoPass)

      // const gui = new GUI()
      // const ssaoFolder = gui.addFolder('SSAO')
      // ssaoFolder.add(ssaoEffect, 'resolutionScale', 0, 1).name("resolution scale")
      // ssaoFolder.add(ssaoEffect, 'spp', 1, 64).name("samples per pixel")
      // ssaoFolder.add(ssaoEffect, 'distance', 0, 100).name("distance")
      // ssaoFolder.add(ssaoEffect, 'distancePower', 0, 1).name("distance power")
      // ssaoFolder.add(ssaoEffect, 'power', 0, 10).name("power")
      // ssaoFolder.add(ssaoEffect, 'bias', 0, 1000).name("bias")
      // ssaoFolder.add(ssaoEffect, 'thickness', 0, 1).name("thickness")
      // ssaoFolder.add(ssaoEffect, 'color', 0, 1).name("color")
      // ssaoFolder.add(ssaoEffect, 'useNormalPass').name("use normal pass")
      // ssaoFolder.add(ssaoEffect, 'iterations', 1, 16).name("iterations")
      // ssaoFolder.add(ssaoEffect, 'samples', 1, 64).name("samples")

      const fxaaEffect = new POSTPROCESSING.FXAAEffect()
      const fxaaPass = new POSTPROCESSING.EffectPass(camera, fxaaEffect)
      // composer.addPass(fxaaPass)

      const smaaEffect = new POSTPROCESSING.SMAAEffect()
      const smaaPass = new POSTPROCESSING.EffectPass(camera, smaaEffect)
      // composer.addPass(smaaPass)

      const gammaCorrectionEffect = new POSTPROCESSING.GammaCorrectionEffect()
      const gammaCorrectionPass = new POSTPROCESSING.EffectPass(gammaCorrectionEffect)
      // composer.addPass(gammaCorrectionPass)

      const ctx = renderer.getContext()
			composer.multisampling = Math.min(4, ctx.getParameter(ctx.MAX_SAMPLES))
      console.log(fxaaPass, smaaPass, gammaCorrectionPass, ctx.MAX_SAMPLES)

      
      //const target = new THREE.WebGLRenderTarget(window.innerWidth, window.innerHeight, {samples:1})
      /*const composer = new EffectComposer( renderer/*, target*/ /*);
			const renderPass = new RenderPass( scene, camera );
			composer.addPass( renderPass );

      const ssaoPass = new SSAOPass( scene, camera );
			ssaoPass.kernelRadius = 16;
			composer.addPass( ssaoPass );*/

      /*const fxaaPass = new ShaderPass( FXAAShader );
      const pixelRatio = renderer.getPixelRatio();
			fxaaPass.material.uniforms[ 'resolution' ].value.x = 1 / ( window.innerWidth * pixelRatio );
			fxaaPass.material.uniforms[ 'resolution' ].value.y = 1 / ( window.innerHeight * pixelRatio );
      composer.addPass(fxaaPass)*/


      //ssaoPass.renderToScreen = true;
      //composer.renderToScreen = true;
			//const saoPass = new SAOPass( scene, camera, false, true);
      /*saoPass.params.saoBias = .01
      saoPass.params.saoIntensity = .0012
      saoPass.params.saoScale = .3
      saoPass.params.saoKernelRadius = 40
      saoPass.params.saoBlurRadius = 4
      saoPass.params.saoMinResolution = 0
      console.log(saoPass)
			composer.addPass( saoPass );*/
      //composer.setSize(window.innerWidth,window.innerHeight)
      

      //console.log(composer)

      /*const csm = new CSM( {
					maxFar: 5000,
					cascades: 4,
					mode: 'practical',
					parent: scene,
					shadowMapSize: 1024,
					lightDirection: new THREE.Vector3( -1, -1, -1 ).normalize(),
					camera: camera
				} );
        console.log(csm)*/

      const mapCamera = markRaw(new THREE.OrthographicCamera(
        (window.innerWidth+530)/-2*7, (window.innerWidth+530)/2*7, window.innerHeight/2*7, window.innerHeight/-2*7,
        1, 
        10000000000000
      ))

      const floorGeometry = markRaw(new THREE.PlaneGeometry(10000, 10000, 100, 100))
      const floorMaterial = markRaw(new THREE.MeshStandardMaterial())
      floorMaterial.color.setHex(0x757575).convertSRGBToLinear()
      const floor = new THREE.Mesh(floorGeometry, floorMaterial)
      floor.position.x = 4000
      floor.position.z = 4000

      const loadingManager = markRaw(new THREE.LoadingManager(
        // Loaded
        () => {
           console.log('loaded')
        },
        // Progress
        () => {
          console.log('progress')
        }
      ))

      const raycaster = markRaw(new THREE.Raycaster())
      const moveRaycaster = markRaw(new THREE.Raycaster())

      /*const gltfLoader = new GLTFLoader()
      
      // load steellook
      gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}/Steellook-845-2.glb`, (glb) => {
        glb.scene.scale.multiplyScalar(1000);
        glb.scene.traverse( (child) => {
					if ( child instanceof THREE.Object3D  ) {

            if (child.material) {
              // child.receiveShadow = true
              // child.castShadow = true

              // if (child.material.map) {
              //   console.log(child.material)
              //   child.material.map.encoding = THREE.sRGBEncoding
              //   child.material.map.needsUpdate = true;
              //   child.material.needsUpdate = true;
              //   child.material.roughness = 0
              //   console.log(child.material)
              // }
            }						
					}
				})
        
        glb.scene.translateZ(120);
        glb.scene.translateX(72.5);
        glb.scene.scale.set(1000, 1000, 1000);
        scene.add(glb.scene)
        glb.scene.name = "demoSteellook";
        glb.scene.visible = this.shouldRenderSteellook;
        // console.log(glb.scene.uuid + ' ?=? ' + scene.children[scene.children.length-1].uuid);
      })*/

      const lichtstraatOutlineEffect = markRaw(new POSTPROCESSING.OutlineEffect(scene, camera, {
        blendFunction: POSTPROCESSING.BlendFunction.SCREEN,
        multisampling: Math.min(4, renderer.capabilities.maxSamples),
        edgeStrength: 5,
        pulseSpeed: 0.0,
        visibleEdgeColor: 0xbabc16, //0xff0000
        hiddenEdgeColor: 0xbabc16, //0xff0000
        height: 480,
        blur: true,
        blurriness: 2,
        xRay: true
      }));
      lichtstraatOutlineEffect.blendMode.setBlendFunction(Number(POSTPROCESSING.BlendFunction.LIGHTEN))
      lichtstraatOutlineEffect.blurPass.enabled = true;
      lichtstraatOutlineEffect.blurPass.blurMaterial.kernelSize = 2;
      const outlinePass = new POSTPROCESSING.EffectPass(camera, lichtstraatOutlineEffect);
      composer.addPass(outlinePass);
      
      this.$store.commit('setRenderData', {renderer: renderer, composer, ssaoEffect, camera: camera, mapCamera: mapCamera, scene: scene, THREE: THREE, lichtstraatOutlineEffect})

      // setTimeout(() => {
      //   this.$store.dispatch('renderImages', renderer)
      // }, 12345);
      
      //const color = new THREE.Color(0xD4F028)
      /*const c = new THREE.Color('#D4F028')
      const fragmentShader = `void main() {gl_FragColor = vec4(vec3(${c.r},${c.g},${c.b}),1.0);}`
      const material = new THREE.ShaderMaterial({fragmentShader})
      console.log("material", material)
      scene.add(new THREE.Mesh(new THREE.BoxGeometry(1000,1000,1000), material))*/

      /*const axesHelper = new THREE.AxesHelper( 10000 );
      axesHelper.setColors(0xff0000, 0x00ff00, 0x0000ff)
      scene.add( axesHelper );*/

      /*const gui = new GUI()
      const lighting = gui.addFolder('Belichting')
      lighting.add(light, 'intensity', 0, Math.PI * 2).name("directional light");
      lighting.add(ambientLight, 'intensity', 0, Math.PI * 2).name("ambient light");
      lighting.add(hemiLight, 'intensity', 0, Math.PI * 2).name("hemi light");
      lighting.add(renderer, 'toneMappingExposure', 0, Math.PI * 2)
      lighting.open()*/

      

      //console.log(EffectPass, NormalPass, SSAOEffect, BlendFunction, DepthDownsamplingPass)

      /*const capabilities = renderer.capabilities;

      const composer = new EffectComposer(renderer)
      composer.addPass(new RenderPass(scene, camera));
      const normalPass = new NormalPass(scene, camera);
      console.log(normalPass.texture)
      const depthDownsamplingPass = new DepthDownsamplingPass({
        normalBuffer: normalPass.texture,
        resolutionScale: 1
      });
      const normalDepthBuffer = capabilities.isWebGL2 ? depthDownsamplingPass.texture : null;
      const ssaoEffect = new SSAOEffect(camera, normalPass.texture, {
        blendFunction: BlendFunction.MULTIPLY,
        distanceScaling: true,
        depthAwareUpsampling: true,
        normalDepthBuffer,
        samples: 1,
        rings: 7,
        distanceThreshold: 1,	// Render up to a distance of ~20 world units
        distanceFalloff: 1.5,	// with an additional ~2.5 units of falloff.
        rangeThreshold: 0.3,		// Occlusion proximity of ~0.3 world units
        rangeFalloff: 0.1,			// with ~0.1 units of falloff.
        luminanceInfluence: 0.7,
        minRadiusScale: 0.33,
        radius: 0.25,
        intensity: 4,
        bias: 0.025,
        fade: 0.01,
        color: null,
        resolutionScale: 1
      });

      const effectPass = new EffectPass(camera, ssaoEffect);
      composer.addPass(normalPass);
      composer.addPass(effectPass);*/

      /*const composer = new EffectComposer( renderer );
      const renderPass = new RenderPass( scene, camera );
      composer.addPass( renderPass );
      const saoPass = new SAOPass( scene, camera, false, true );
      saoPass.params = {
        output: SAOPass.OUTPUT.Default,
        saoBias: 0.19,
        saoIntensity: 0.0001,
        saoScale: 10,
        saoKernelRadius: 5,
        saoMinResolution: 0,
        saoBlur: true,
        saoBlurRadius: 3.9,
        saoBlurStdDev: 150,
        saoBlurDepthCutoff: 0.1
      }
      saoPass.kernelRadius = 16;
      composer.addPass( saoPass );

				gui.add( saoPass.params, 'output', {
					'Beauty': SAOPass.OUTPUT.Beauty,
					'Beauty+SAO': SAOPass.OUTPUT.Default,
					'SAO': SAOPass.OUTPUT.SAO,
					'Depth': SAOPass.OUTPUT.Depth,
					'Normal': SAOPass.OUTPUT.Normal
				} ).onChange( function ( value ) {

					saoPass.params.output = parseInt( value );

				} );
				gui.add( saoPass.params, 'saoBias', - 1, 1 );
				gui.add( saoPass.params, 'saoIntensity', 0, 1 );
				gui.add( saoPass.params, 'saoScale', 0, 10 );
				gui.add( saoPass.params, 'saoKernelRadius', 1, 100 );
				gui.add( saoPass.params, 'saoMinResolution', 0, 1 );
				gui.add( saoPass.params, 'saoBlur' );
				gui.add( saoPass.params, 'saoBlurRadius', 0, 200 );
				gui.add( saoPass.params, 'saoBlurStdDev', 0.5, 1000 );
				gui.add( saoPass.params, 'saoBlurDepthCutoff', 0.0, 10 );*/

      /*const renderPass = new RenderPass( scene, camera );

      const gammaPass = new ShaderPass( GammaCorrectionShader );

      const fxaaPass = new ShaderPass( FXAAShader );
      fxaaPass.uniforms[ 'resolution' ].value.set( 1 / window.innerWidth, 1 / window.innerHeight);

      const gtaoPass = new GTAOPass( scene, camera );
      window.gtaoPass = gtaoPass;

      const floatRT = new THREE.WebGLRenderTarget( 1, 1, {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.LinearFilter,
        format: THREE.RGBAFormat,
        type: THREE.FloatType,
      } );
      const composer = new EffectComposer( renderer, floatRT );
      composer.setSize( window.innerWidth, window.innerHeight );
      composer.setPixelRatio( window.devicePixelRatio );
      composer.addPass( renderPass );
      composer.addPass( gtaoPass );
      composer.addPass( gammaPass );
      composer.addPass( fxaaPass );




      var params = {
				enabled: true,
				rotationJitter: 1,
				radiusJitter: 0,

				directionOffset: 0.0,
				stepOffset: 0.0,
				numSteps: 8,
				numDirections: 1,
				intensity: 1.0,
				radius: 2.0,

				enableFalloff: true,
				falloffStart: 0.4,
				falloffEnd: 2.0,

				blurMode: GTAOPass.BOX_BLUR,
				blurIterations: 5,
				blurStride: 1,

				display: GTAOPass.DEFAULT,
				renderTargetScale: 0.5,
				scene: 'pyramid',

				ambientLightColor: [ 255, 255, 255 ],
				ambientLightIntensity: 0,

				enableLightBounce: false,
				lightBounceIntensity: 1.0,
			};


      gui.add( params, 'enabled' );

				const settingsFolder = gui.addFolder( 'settings' );
				settingsFolder.add( params, 'directionOffset' ).min( 0.0 ).max( 1 ).step( 0.01 );
				settingsFolder.add( params, 'stepOffset' ).min( 0.0 ).max( 1 ).step( 0.01 );
				settingsFolder.add( params, 'rotationJitter', {
					NONE: 0,
					RANDOM: 1,
					BLUE_NOISE: 2,
				} );
				settingsFolder.add( params, 'radiusJitter', {
					NONE: 0,
					RANDOM: 1,
					BLUE_NOISE: 2,
				} );
				settingsFolder.add( params, 'numSteps' ).min( 1 ).max( 32 ).step( 1 );
				settingsFolder.add( params, 'numDirections' ).min( 1 ).max( 32 ).step( 1 );
				settingsFolder.add( params, 'intensity' ).min( 0 ).max( 2.0 ).step( 0.01 );
				settingsFolder.add( params, 'radius' ).min( 0 ).max( 10.0 ).step( 0.01 );

				settingsFolder.add( params, 'enableFalloff' );
				settingsFolder.add( params, 'falloffStart' ).min( 0 ).max( 10.0 ).step( 0.01 );
				settingsFolder.add( params, 'falloffEnd' ).min( 0 ).max( 10.0 ).step( 0.01 );

				settingsFolder.add( params, 'renderTargetScale' ).min( 0.1 ).max( 1.0 ).step( 0.01 ).onChange( v => {

					gtaoPass.renderTargetScale = v;

				} );
				settingsFolder.open();

        setInterval(() => {
          gtaoPass.debug.display = parseInt( params.display );
          gtaoPass.enabled = params.enabled;
          gtaoPass.singlePass = params.singlePass;
          gtaoPass.rotationJitter = parseInt( params.rotationJitter );
          gtaoPass.radiusJitter = parseInt( params.radiusJitter );
          gtaoPass.blurMode = parseFloat( params.blurMode );
          gtaoPass.blurIterations = params.blurIterations;
          gtaoPass.blurStride = params.blurStride;
          gtaoPass.numSteps = params.numSteps;
          gtaoPass.numDirections = params.numDirections;
          gtaoPass.intensity = params.intensity;
          gtaoPass.radius = params.radius;
          gtaoPass.falloffStart = params.falloffStart;
          gtaoPass.falloffEnd = params.falloffEnd;
          gtaoPass.enableFalloff = params.enableFalloff;
          gtaoPass.directionOffset = params.directionOffset;
          gtaoPass.stepOffset = params.stepOffset;

          gtaoPass.lightBounceIntensity = params.enableLightBounce ? params.lightBounceIntensity : 0.0;

          // ambientLight.color.setRGB( ...params.ambientLightColor.map( c => c / 255 ) );
          // ambientLight.intensity = params.ambientLightIntensity;

          gtaoPass.ambientColor.setRGB( ...params.ambientLightColor.map( c => c / 255 ) );
          gtaoPass.ambientIntensity = params.ambientLightIntensity;

          gammaPass.enabled = gtaoPass.debug.display === GTAOPass.DEFAULT;          
        }, 1000)

				const blurFolder = gui.addFolder( 'blur' );
				blurFolder.add( params, 'blurMode', {
					NO_BLUR: GTAOPass.NO_BLUR,
					BOX_BLUR: GTAOPass.BOX_BLUR,
					CROSS_BLUR: GTAOPass.CROSS_BLUR,
					DIAGONAL_BLUR: GTAOPass.DIAGONAL_BLUR,
				} );
				blurFolder.add( params, 'blurIterations' ).min( 1 ).max( 20 ).step( 1 );
				blurFolder.add( params, 'blurStride' ).min( 1 ).max( 5 ).step( 1 );
				blurFolder.open();
        const debugFolder = gui.addFolder( 'debug' );
				debugFolder.add( params, 'display', {
					DEFAULT: GTAOPass.DEFAULT,
					DEPTH: GTAOPass.DEPTH,
					NORMAL: GTAOPass.NORMAL,
					AO_SAMPLE: GTAOPass.AO_SAMPLE,
					COLOR_SAMPLE: GTAOPass.COLOR_SAMPLE,
				} );
				debugFolder.open();*/

      // setTimeout(() => {
      //   // export gltf
      //   const exporter = new GLTFExporter()
      //   exporter.parse(scene, (gltf) => {
      //     console.log(gltf)
      //     const json = JSON.stringify(gltf)
      //     const blob = new Blob([json], {type: 'application/json'})
      //     const url = URL.createObjectURL(blob)
      //     const a = document.createElement('a')
      //     a.href = url
      //     a.download = 'scene.gltf'
      //     a.click()
      //     URL.revokeObjectURL(url)
      //   })
      // }, 15000);

      return {
        scene: scene,
        renderer: renderer,
        composer: composer,
        camera: camera,
        mapCamera: mapCamera,
        raycaster: raycaster,
        moveRaycaster: moveRaycaster,
        mouse: null,
        currentIntersect: null,
        lights: {
          ambient: /*hemiLight,//*/ambientLight,
          directional: light,
        },
        mesh: {
          floor: floor,
        },
        loadingManager: loadingManager,
        sizes: {
          width: null,
          height: null,
        },
        delay: 750,
        isDragging: false,
        trackingPlane: null,
        devMode: false,
        scalingMode: false,
        specImagesMode: false,
        devObject: null,
        uuid: uuid,
        isHoveringOverLichtstraat: false,
        currentIntersectingLichtstraat: null,
        selectedLichtstraat: null,
        isMovingSelectedLichtstraat: false,
        lichtstraatTrackingPlane: null,
        lichtstraatOutlineEffect: lichtstraatOutlineEffect,
        tweenGroup: new TWEEN.Group(),
        disableControlsUpdate: false
      }
    },

    components: {
      DevTools,
      TextureScaling,
      RegenerateSpecImages
    },
    
    methods: {
      updateScenesVisible () {
        if (!this.controls) return
        const val = this.isMapOn
        this.controls.enabled = !val
        this.mapControls.enabled = val
        if (this.scene.getObjectByName("FrameGroup")) {
          this.scene.getObjectByName("FrameGroup").visible=!val;
        }
        if (this.scene.getObjectByName("ModelGroup")) {
          this.scene.getObjectByName("ModelGroup").visible=!val;
        }
        if (this.scene.getObjectByName("MapGroup")) {
          this.scene.getObjectByName("MapGroup").visible=val;
        }
      },

      tick () {
        this.stats.begin()
        //console.log(this.ssao, BlendFunction)
        //this.ssao.blendMode.set(this.effect ? BlendFunction.MULTIPLY : BlendFunction.SKIP)
        //if(this.devObject) {console.log(this.devObject.object, this.devControls, this.devControls.enabled, this.devControls.object)}
        if(document.location.hostname === 'tw-js-dev.mtest.nl' || document.location.hostname === 'localhost' || document.location.hostname === 'tw-v01-js-dev.mtest.nl') {
          document.getElementById('fps').innerHTML = Math.round(1000/(new Date() - oldDate)) + "fps"
          document.getElementById("frameTime").innerHTML = Date.now() - oldDate + "ms";
          oldDate = new Date()
        }
        //console.log(this.selectedElement)
        this.isMapOn ? this.mapControls.update() : this.controls.update()
        //this.isMapOn ? this.lights.ambient.intensity=0.9 : this.lights.ambient.intensity=0.2
        if(this.isMobile) {
          this.isMapOn ? this.renderer.render(this.scene, this.mapCamera) : this.renderer.render(this.scene, this.camera)
        } else {
          this.isMapOn ? this.renderer.render(this.scene, this.mapCamera) : this.composer.render(this.scene, this.camera)
        }
        // this.mapControls.enabled = this.isMapOn
        this.controls.enableRotate = !this.isMapOn && !this.isDragging && !this.isMovingSelectedLichtstraat
        // if (this.scene.getObjectByName("FrameGroup")) {
        //   this.scene.getObjectByName("FrameGroup").visible=!this.isMapOn;
        // }
        // if (this.scene.getObjectByName("ModelGroup")) {
        //   this.scene.getObjectByName("ModelGroup").visible=!this.isMapOn;
        // }
        // if (this.scene.getObjectByName("MapGroup")) {
        //   this.scene.getObjectByName("MapGroup").visible=this.isMapOn;
        // }

        this.tweenGroup.update()

        // const newCube = new THREE.Mesh(new THREE.BoxGeometry(100, 100, 100), new THREE.MeshBasicMaterial({color: 0x00ff00}))
        // newCube.position.set(
        //   Math.random() * 10000 - 5000,
        //   Math.random() * 10000 - 5000,
        //   Math.random() * 10000 - 5000
        // )
        // this.scene.add(newCube)
        // this.scene.children[3].visible=!this.isMapOn;
        // this.scene.children[5].visible=!this.isMapOn;
        // this.scene.children[4].visible=this.isMapOn;

        // save camera world direction to store
        const cameraDirection = new THREE.Vector3();
        this.camera.getWorldDirection(cameraDirection);
        this.$store.commit('setCameraDirection', cameraDirection)
        

        // const steellook = this.scene.getObjectByName('demoSteellook');
        // if(steellook) {
        //   //console.log(`Steellook by name: ${this.scene.getObjectByName('demoSteellook')}`)
        //   steellook.visible = this.shouldRenderSteellook;
        // }

        // raycaster
        this.raycaster.setFromCamera(this.mouse, this.isMapOn? this.mapCamera : this.camera)
        //console.log(this.intersectionOn)
        if (this.intersectionOn && !this.start && !this.getMovingBeam) {
          //var starttime = Date.now();
          //console.log(this.intersectingObjects)
          this.raycaster.firstHitOnly = true;

          //console.log(this.raycaster)
          let intersects = []
          try {
            intersects = this.raycaster.intersectObjects(this.intersectingObjects)
            // if(intersects[0])console.log(intersects[0].object.geometry.groups, intersects[0].object.material)
          } catch (error) {
            console.log(error)
          }
          //filter intersected objects
          intersects = intersects.filter(intersectedObject => {
            return !(intersectedObject.object.parent?.name === 'selectFrame' || (intersectedObject.object.name === '' && intersectedObject.object.parent?.name === ''));
          });

          if(this.currentIntersect && intersects.length) {
            if(this.currentIntersect.object.uuid !== intersects[0].object.uuid && (this.currentIntersect.object.name === 'hoverLineBuiten' || this.currentIntersect.object.name === 'hoverLineBinnen')) {
              this.$store.dispatch('setWallHoverShowInFuture', {wall: this.currentIntersect.object, value: '', side: this.currentIntersect.object.name === 'hoverLineBinnen' ? 0 : 1})
              this.$store.dispatch('wallHoverLeave', this.currentIntersect.object)
            }

            /*if(this.currentIntersect.object.uuid !== intersects[0].object.uuid && (this.currentIntersect.object.name === 'plusIconTopgevelBuiten' || this.currentIntersect.object.name === 'plusIconTopgevelBinnen')) {
              this.$store.dispatch('setWallHoverShowInFuture', {wall: this.currentIntersect.object, value: ''})
              this.$store.dispatch('topgevelHoverLeave', this.currentIntersect.object)
            }*/
          } else if(this.currentIntersect) {
            if(this.currentIntersect.object.name === 'hoverLineBuiten' || this.currentIntersect.object.name === 'hoverLineBinnen') {
              this.$store.dispatch('setWallHoverShowInFuture', {wall: this.currentIntersect.object, value: '', side: this.currentIntersect.object.name === 'hoverLineBinnen' ? 0 : 1})
              this.$store.dispatch('wallHoverLeave', this.currentIntersect.object)
              this.currentIntersect = null
            }

            /*if(this.currentIntersect.object.name === 'plusIconTopgevelBuiten' || this.currentIntersect.object.name === 'plusIconTopgevelBinnen') {
              this.$store.dispatch('setWallHoverShowInFuture', {wall: this.currentIntersect.object, value: ''})
              this.$store.dispatch('topgevelHoverLeave', this.currentIntersect.object)
              this.currentIntersect = null
            }*/
          }

          //console.log(this.intersectingObjects.length)
          //console.log(intersects)
          //var starttime3 = Date.now();
          if (intersects.length && this.isHoveringOverCanvas) {
            //var starttime2 = Date.now();
            //console.log(intersects[0].object)
            //console.log("object.name", intersects[0].object.parent.name, intersects[0].object, intersects)
            //if(!intersects[0].object.parent.name) {intersects[0].object.parent.position.y = 4000}
            if (intersects[0].object.name === 'hoverLineBuiten' || intersects[0].object.name === 'hoverLineBinnen') {
              if (!this.currentIntersect) {
                let uuid = this.uuid()
                //console.log("UUID", uuid)
                this.$store.dispatch('setWallHoverShowInFuture', {wall: intersects[0].object, value: uuid, side: intersects[0].object.name === 'hoverLineBinnen' ? 0 : 1 })
                this.hoverTimeout = setTimeout(() => {
                  this.$store.dispatch('wallHoverEnter', {object: intersects[0].object, uuid: uuid})
                }, this.delay)

              }

              if (this.currentIntersect) {
                //console.log(this.currentIntersect)
                if (intersects[0].object.uuid !== this.currentIntersect.object.uuid) {
                  this.$store.dispatch('setWallHoverShowInFuture', {wall: this.currentIntersect.object, value: '', side: this.currentIntersect.object.name === 'hoverLineBinnen' ? 0 : 1})
                  this.$store.dispatch('wallHoverLeave', this.currentIntersect.object)
                  //this.$store.dispatch('topgevelHoverLeave', this.currentIntersect.object)

                  let uuid = this.uuid()
                  this.$store.dispatch('setWallHoverShowInFuture', {wall: intersects[0].object, value: uuid, side: intersects[0].object.name === 'hoverLineBinnen' ? 0 : 1})
                  this.hoverTimeout = setTimeout(() => {
                    this.$store.dispatch('wallHoverEnter', {object: intersects[0].object, uuid: uuid})
                  }, this.delay)
                  
                }
                
              }

              this.currentIntersect = intersects[0]

            /*} else if(this.currentIntersect) {
              //if((this.currentIntersect.object.name === 'hoverLineBuiten' || this.currentIntersect.object.name === 'hoverLineBinnen') && (intersects[0].object.name !== 'hoverLineBuiten' || intersects[0].object.name !== 'hoverLineBinnen')) {
              /*if((this.currentIntersect.object.name === 'hoverLineBuiten' && intersects[0].object.name !== 'hoverLineBuiten') || (this.currentIntersect.object.name === 'hoverLineBinnen' && intersects[0].object.name !== 'hoverLineBinnen')) {  
                this.$store.dispatch('wallHoverLeave', this.currentIntersect.object)
                console.log("wallHoverLeave")
              }
            }*/
            }else if ((intersects[0].object.name === 'buitenmuurMap' || intersects[0].object.name === 'binnenmuurMap') && this.wallHover) {
              if (!this.currentIntersect) {
                //console.log(this.wallHover)
                let uuid = this.uuid()
                this.$store.dispatch('setWallHoverShowInFuture', {wall: this.scene.getObjectById(intersects[0].object.metaData.hoverID), value: uuid, side: intersects[0].object.name === 'binnenmuurMap' ? 0 : 1})
                this.$store.dispatch('wallHoverEnter', {object: this.scene.getObjectById(intersects[0].object.metaData.hoverID), uuid: uuid})
                // console.log("wallHoverEnter")
              }
              //console.log(this.currentIntersect)
              if (this.currentIntersect) {
                if (intersects[0].object.uuid !== this.currentIntersect.object.uuid && this.currentIntersect.object.metaData) {
                  this.$store.dispatch('setWallHoverShowInFuture', {wall: this.scene.getObjectById(intersects[0].object.metaData.hoverID), value: '', side: intersects[0].object.name === 'binnenmuurMap' ? 0 : 1})
                  this.$store.dispatch('wallHoverLeave', this.scene.getObjectById(this.currentIntersect.object.metaData.hoverID))
                  
                  let uuid = this.uuid()
                  this.$store.dispatch('setWallHoverShowInFuture', {wall: this.scene.getObjectById(intersects[0].object.metaData.hoverID), value: uuid, side: intersects[0].object.name === 'binnenmuurMap' ? 0 : 1})
                  this.$store.dispatch('wallHoverEnter', {object: this.scene.getObjectById(intersects[0].object.metaData.hoverID), uuid: uuid})
                }
                
              }

              this.currentIntersect = intersects[0]
              //console.log(this.currentIntersect)
            } 
            
            if((intersects[0].object.name === 'plusIconTopgevelBuiten' || intersects[0].object.name === 'plusIconTopgevelBinnen') && this.wallHover) {

              /*if (!this.currentIntersect) {
                let uuid = this.uuid()
                console.log("UUID", uuid)
                this.$store.dispatch('setTopgevelHoverShowInFuture', {topgevel: intersects[0].object, value: uuid})
                this.hoverTimeout = setTimeout(() => {
                  this.$store.dispatch('topgevelHoverEnter', {object: intersects[0].object, uuid: uuid})
                }, this.delay)

              }

              if (this.currentIntersect) {
                //console.log(this.currentIntersect)
                if (intersects[0].object.uuid !== this.currentIntersect.object.uuid) {
                  this.$store.dispatch('setWallHoverShowInFuture', {wall: this.currentIntersect.object, value: ''})
                  this.$store.dispatch('topgevelHoverLeave', this.currentIntersect.object)
                  
                  let uuid = this.uuid()
                  this.$store.dispatch('setTopgevelHoverShowInFuture', {wall: intersects[0].object, value: uuid})
                  this.hoverTimeout = setTimeout(() => {
                    this.$store.dispatch('topgevelHoverEnter', {object: intersects[0].object, uuid: uuid})
                  }, this.delay)
                }
                
              }*/

              this.currentIntersect = intersects[0]
            }

            if((intersects[0].object.name === 'mapTriangleBuiten' || intersects[0].object.name === 'mapTriangleBinnen') && this.wallHover) {
              this.currentIntersect = intersects[0]
            }

            if(intersects[0].object.name === 'topgevelSelectbox' && this.wallHover) {
              if(intersects[0].object.parent?.getObjectByName("topgevelBuiten").visible || intersects[0].object.parent?.getObjectByName("topgevelBinnen").visible) {
                this.currentIntersect = intersects[0]
              } else {
                this.currentIntersect = null
              }
            }

            if (intersects[0].object.parent?.name === 'wall' && intersects[0].object.parent?.visible && intersects[0].object.name !== 'hoverLineBuiten' && intersects[0].object.name !== 'hoverLineBinnen' && intersects[0].object.name !== 'buitenmuurMap' && intersects[0].object.name !== 'binnenmuurMap') {
              
              if (!this.currentIntersect) {
                
                // this.$store.dispatch('wallHoverEnter', intersects[0].object)

              }

              if (this.currentIntersect) {

                if (intersects[0].object.uuid !== this.currentIntersect.object.uuid) {

                  // this.$store.dispatch('wallHoverLeave', this.currentIntersect.object)
                  // this.$store.dispatch('wallHoverEnter', intersects[0].object)
                }
                
              }

              this.currentIntersect = intersects[0]

            }
            
            if(intersects[0].object.parent?.name === 'wallBox') {
              this.currentIntersect = intersects[0]
            }

            if (intersects[0].object.name === 'isolatieHoverButtonActive' || intersects[0].object.name === 'isolatieHoverButtonInactive') {
              this.currentIntersect = intersects[0]
            }
            if (intersects[0].object.name === 'isolatieMapButtonActive' || intersects[0].object.name === 'isolatieMapButtonInactive') {
              this.currentIntersect = intersects[0]
            }
            if (intersects[0].object.name === 'topIsolatieHoverButtonActive' || intersects[0].object.name === 'topIsolatieHoverButtonInactive') {
              this.currentIntersect = intersects[0]
            }
            if (intersects[0].object.name === 'topIsolatieMapButtonActive' || intersects[0].object.name === 'topIsolatieMapButtonInactive') {
              this.currentIntersect = intersects[0]
            }

            if (intersects[0].object.name === 'plusIcon') {
              this.currentIntersect = intersects[0]
            }

            if (intersects[0].object.name === 'arrowLeft' || intersects[0].object.name === 'arrowRight') {
              this.isDragging = true
              this.currentIntersect = intersects[0]
            } else {
              this.isDragging = false
            }


            if (intersects[0].object.parent?.name === 'ModelBox') {
              this.currentIntersect = intersects[0]
            }

            if (intersects[0].object.name === "lichtstraatHitbox" && router.currentRoute.value.fullPath.includes("/cfg/roof/")) {
              this.isHoveringOverLichtstraat = true
              this.currentIntersectingLichtstraat = intersects[0].object
              this.$store.commit('setCurrentIntersectingLichtstraat', intersects[0].object)
              this.currentIntersectingLichtstraatHitPosition = intersects[0].point
              // this.controls.enableRotate = false
            } else {
              this.isHoveringOverLichtstraat = false
              this.currentIntersectingLichtstraat = null
              this.$store.commit('setCurrentIntersectingLichtstraat', null)
              if (!this.selectedLichtstraat) {
                this.controls.enableRotate = true
              }
            }

            if (intersects[0].object.userData.type === "lichtstraatDimensionHitbox" && router.currentRoute.value.fullPath.includes("/cfg/roof/")) {
              this.$store.commit('setCurrentIntersectingLichtstraatDimension', intersects[0].object)
            } else {
              this.$store.commit('setCurrentIntersectingLichtstraatDimension', null)
            }

            //console.log(Date.now() - starttime2)
          } else {
            this.isDragging = false

            // lichtstraten
            this.isHoveringOverLichtstraat = false
            this.currentIntersectingLichtstraat = null
            this.$store.commit('setCurrentIntersectingLichtstraat', null)
            this.$store.commit('setCurrentIntersectingLichtstraatDimension', null)
            if (!this.selectedLichtstraat) {
              this.controls.enableRotate = true
            }

            if (this.currentIntersect) {
              if(this.currentIntersect.object.name !== "plusIcon") {
                // console.log(this.currentIntersect.object.name)
                if((this.currentIntersect.object.name === 'buitenmuurMap' || this.currentIntersect.object.name === 'binnenmuurMap') && this.wallHover) {
                  this.$store.dispatch('setWallHoverShowInFuture', {wall: this.scene.getObjectById(this.currentIntersect.object.metaData.hoverID), value: '', side: this.currentIntersect.object.name === 'binnenmuurMap' ? 0 : 1})
                  this.$store.dispatch('wallHoverLeave', this.scene.getObjectById(this.currentIntersect.object.metaData.hoverID))
                  // console.log("wallHoverLeave")
                } else if(this.currentIntersect.object.name === 'hoverLineBinnen' || this.currentIntersect.object.name === 'hoverLineBuiten') {
                  
                  clearTimeout(this.hoverTimeout)

                this.$store.dispatch('setWallHoverShowInFuture', {wall: this.currentIntersect.object, value: '' , side: this.currentIntersect.object.name === 'hoverLineBinnen' ? 0 : 1})
                  this.$store.dispatch('wallHoverLeave', this.currentIntersect.object)
                  // console.log("wallHoverLeave")
                }
              }

            }

            this.currentIntersect = null
          }
          //console.log(starttime3 - starttime)
          //console.log(Date.now() - starttime)
        }


        
        this.stats.end()

        window.requestAnimationFrame(this.tick)

        //document.getElementById("frameTime").innerHTML = Date.now() - oldDate + "ms";
      },

      handleResize() {
        // Update camera     
        this.camera.aspect = (window.innerWidth >= 1000 ? window.innerWidth+530 : window.innerWidth+70) / (window.innerWidth >= 1000 ? window.innerHeight : (window.innerHeight / 2) + 15 ) // +15 for the border radius of the UI
        this.camera.updateProjectionMatrix()

        this.mapCamera.left = (window.innerWidth+530)/-2*7
        this.mapCamera.right = (window.innerWidth+530)/2*7
        this.mapCamera.top = window.innerHeight/2*7
        this.mapCamera.bottom = window.innerHeight/-2*7
        this.mapCamera.updateProjectionMatrix()

        // Update renderer
        this.renderer.setSize(window.innerWidth >= 1000 ? window.innerWidth+530 : window.innerWidth+70, window.innerWidth >= 1000 ? window.innerHeight : (window.innerHeight / 2) + 15 ) // +15 for the border radius of the UI
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        this.composer.setSize(window.innerWidth >= 1000 ? window.innerWidth+530 : window.innerWidth+70, window.innerWidth >= 1000 ? window.innerHeight : (window.innerHeight / 2) + 15 ) // +15 for the border radius of the UI
        // this.composer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

        // update data
        this.sizes = {
          width: window.innerWidth >= 1000 ? window.innerWidth+530 : window.innerWidth+70,
          height: window.innerWidth >= 1000 ? window.innerHeight : (window.innerHeight / 2) + 15
        }
      },

      showWallRelations() {
        if(this.relationsArray == undefined) {
          this.relationsArray = []
        }
        for(var j=0; j<this.relationsArray.length; j++) {
          this.relationsArray[j].removeFromParent()
        }
        // console.log(this.getMuurInstances)
        for(var i=0; i<this.getMuurInstances.length; i++) {
          // console.log(this.getMuurInstances[i].childWall)
          if(this.getMuurInstances[i].childWall) {
            // console.log("relatie")
            const curve = new THREE.CatmullRomCurve3( [
              new THREE.Vector3(this.getMuurInstances[i].group.position.x, this.getMuurInstances[i].group.position.y, this.getMuurInstances[i].group.position.z),
              new THREE.Vector3(  (this.getMuurInstances[i].group.position.x+this.getMuurInstances[i].childWall.group.position.x)/2,
                                  ((this.getMuurInstances[i].group.position.y + this.getMuurInstances[i].childWall.group.position.y)/2) + 1500, 
                                  (this.getMuurInstances[i].group.position.z+this.getMuurInstances[i].childWall.group.position.z)/2,
                                ),
              new THREE.Vector3(this.getMuurInstances[i].childWall.group.position.x, this.getMuurInstances[i].childWall.group.position.y, this.getMuurInstances[i].childWall.group.position.z),
            ] );

            const points = curve.getPoints( 50 );
            const geometry = new THREE.BufferGeometry().setFromPoints( points );

            const material = new THREE.LineBasicMaterial( { color: 0xff0000, depthTest: false } );

            // Create the final object to add to the scene
            const curveObject = new THREE.Line( geometry, material );
            this.relationsArray.push(curveObject)
            this.scene.add(curveObject)
          }

          if(this.getMuurInstances[i].parentWall) {
            // console.log("relatie")
            const curve = new THREE.CatmullRomCurve3( [
              new THREE.Vector3(this.getMuurInstances[i].group.position.x, this.getMuurInstances[i].group.position.y, this.getMuurInstances[i].group.position.z),
              new THREE.Vector3(  (this.getMuurInstances[i].group.position.x+this.getMuurInstances[i].parentWall.group.position.x)/2,
                                  ((this.getMuurInstances[i].group.position.y + this.getMuurInstances[i].parentWall.group.position.y)/2) + 1000, 
                                  (this.getMuurInstances[i].group.position.z+this.getMuurInstances[i].parentWall.group.position.z)/2,
                                ),
              new THREE.Vector3(this.getMuurInstances[i].parentWall.group.position.x, this.getMuurInstances[i].parentWall.group.position.y, this.getMuurInstances[i].parentWall.group.position.z),
            ] );

            const points = curve.getPoints( 50 );
            const geometry = new THREE.BufferGeometry().setFromPoints( points );

            const material = new THREE.LineBasicMaterial( { color: 0x00ff00, depthTest: false } );

            // Create the final object to add to the scene
            const curveObject = new THREE.Line( geometry, material );
            this.relationsArray.push(curveObject)
            this.scene.add(curveObject)
          }

          if(this.getMuurInstances[i].tussenChildWall) {
            // console.log("relatie")
            const curve = new THREE.CatmullRomCurve3( [
              new THREE.Vector3(this.getMuurInstances[i].group.position.x, this.getMuurInstances[i].group.position.y, this.getMuurInstances[i].group.position.z),
              new THREE.Vector3(  (this.getMuurInstances[i].group.position.x+this.getMuurInstances[i].tussenChildWall.group.position.x)/2,
                                  ((this.getMuurInstances[i].group.position.y + this.getMuurInstances[i].tussenChildWall.group.position.y)/2) + 1500, 
                                  (this.getMuurInstances[i].group.position.z+this.getMuurInstances[i].tussenChildWall.group.position.z)/2,
                                ),
              new THREE.Vector3(this.getMuurInstances[i].tussenChildWall.group.position.x, this.getMuurInstances[i].tussenChildWall.group.position.y, this.getMuurInstances[i].tussenChildWall.group.position.z),
            ] );

            const points = curve.getPoints( 50 );
            const geometry = new THREE.BufferGeometry().setFromPoints( points );

            const material = new THREE.LineBasicMaterial( { color: 0x0000ff, depthTest: false } );

            // Create the final object to add to the scene
            const curveObject = new THREE.Line( geometry, material );
            this.relationsArray.push(curveObject)
            this.scene.add(curveObject)
          }

          if(this.getMuurInstances[i].tussenParentWall) {
            // console.log("relatie")
            const curve = new THREE.CatmullRomCurve3( [
              new THREE.Vector3(this.getMuurInstances[i].group.position.x, this.getMuurInstances[i].group.position.y, this.getMuurInstances[i].group.position.z),
              new THREE.Vector3(  (this.getMuurInstances[i].group.position.x+this.getMuurInstances[i].tussenParentWall.group.position.x)/2,
                                  ((this.getMuurInstances[i].group.position.y + this.getMuurInstances[i].tussenParentWall.group.position.y)/2) + 1000, 
                                  (this.getMuurInstances[i].group.position.z+this.getMuurInstances[i].tussenParentWall.group.position.z)/2,
                                ),
              new THREE.Vector3(this.getMuurInstances[i].tussenParentWall.group.position.x, this.getMuurInstances[i].tussenParentWall.group.position.y, this.getMuurInstances[i].tussenParentWall.group.position.z),
            ] );

            const points = curve.getPoints( 50 );
            const geometry = new THREE.BufferGeometry().setFromPoints( points );

            const material = new THREE.LineBasicMaterial( { color: 0xff6800, depthTest: false } );

            // Create the final object to add to the scene
            const curveObject = new THREE.Line( geometry, material );
            this.relationsArray.push(curveObject)
            this.scene.add(curveObject)
          }
        }
      },

      setWallRandomHeight() {
        var index = 0
        for(var i=0; i<this.getMuurInstances.length; i++) {
          if(this.getMuurInstances[i].childWall || this.getMuurInstances[i].parentWall) {
            this.getMuurInstances[i].group.position.y = index*200
            index++
          }
        }
      },

      setAllOnBeforeRender() {
        // alert("setAllOnBeforeRender")
        function handleChild(child) {
          child.onBeforeRender = (renderer, scene, camera, geometry, material, group) => {
            console.log("child", child)
          }
          if(child.children) {
            child.children.forEach(handleChild)
          }
        }
        this.scene.children.forEach(handleChild)
      },

      selectWallGroup(muur) {
        if(this.devObject) {
          this.devObject.deselect()
        }
        this.devObject = muur
        muur.select()
      },

      updateTextureScaling(texture) {
        // console.log(texture)
        this.$store.dispatch('updateDakafwerking', texture)
        //this.$store.dispatch('updateTextureScaling', texture)
      },

      setTextureScaling(texture) {
        this.$store.dispatch('setTextureScaling', texture)
      },

      setShadowAfmetingen(afmetingen) {
        this.lights.directional.position.set(-afmetingen.z, 5000, afmetingen.x * 0.75)
        this.lights.directional.target.position.set(afmetingen.z / 2,0,afmetingen.x / 2)
        this.lights.directional.shadow.camera.left = afmetingen.x*-1//Math.sqrt(Math.pow(afmetingen.x,2)+Math.pow(afmetingen.z,2))/2*-1-1500//-5000;
        this.lights.directional.shadow.camera.right = afmetingen.x //Math.sqrt(Math.pow(afmetingen.x,2)+Math.pow(afmetingen.z,2))/2+1000//5000;
        this.lights.directional.shadow.camera.top = afmetingen.z * 1.5//5000;
        this.lights.directional.shadow.camera.bottom = afmetingen.z*-1//-5000;
        this.lights.directional.shadow.camera.updateProjectionMatrix()
        // this.helper.updateMatrixWorld();
        // this.helper.update()
      },

      // get correct child route after /doorswindows/
      getTabByCategory(category) {
        let returnVal = "";
        switch (category) {
          case "Raam":
            returnVal = "bwindows"
            break;
          case "Steellook Raam":
            returnVal = "slwindows"
            break;
          case "Steellook Raam BW":
            returnVal = "slwindowsbw"
            break;
          case "Steellook Deur":
            returnVal = "sldoors"
            break;
          default:
            break;
        }
        return returnVal;
      },

      checkKeyWords(array){
        // betonband visibility should be set to false for the following key word:
        // "noBetonband"
        // where any value other than -1 means that the key word has been found
        if (array && array.indexOf("noBetonband") > -1) { return false; } 
        else return true;
      },

      findElementInArray(allElements, elementCode) {
        const properties = ['glasTypes', 'variants', 'colors', 'options']
        for (let i = 0; i < allElements.length; i++) {
          for(let j = 0; j < properties.length; j++) {
            const matchesElementCode = allElements[i][properties[j]]?.find((element) => element.code === elementCode)
            if(matchesElementCode) {
              return allElements[i]
            }
          }
        }
        for(let i = 0; i < allElements.length; i++) {
          for(let j = 0; j < properties.length; j++) {
            const matchesElementCode = allElements[i][properties[j]]?.find((element) => element.code === elementCode)
            if(matchesElementCode) {
              return allElements[i]
            }
          }
        }
        return null
      }

      // async getSlidingWalls () {
      //   if (this.slidingWalls.length === 0) {
      //     let categories = ['Schuifwand']
      //     let trueOnline = true

      //     this.$store.dispatch('getSlidingWallComponents', {categories: categories, trueOnline: trueOnline})
      //   }
      // },
    },

    computed: {
      ...mapGetters({
        frame: 'getFrame',
        intersectionOn: 'getIntersectionOn',
        intersectingObjects: 'getIntersectingObjects',
        selectedWall: 'getSelectedWall',
        selectedElement: 'getSelectedElement',
        selectedWallComponent: 'getSelectedWallComponent',
        selectedWallComponentSettings: 'getSelectedWallComponentSettings',
        isMapOn: 'getIsMapActive',
        shouldRenderSteellook: 'getShouldRenderSteellook',
        getMuurInstances: 'getMuurInstances',
        getMovingBeam: 'getMovingBeam',
        selectedWallObject: 'getSelectedWallObject',
        lastEditedWall: 'getLastEditedWall',
        afmetingen: 'getFormatedSizes',
        config: 'getConfig',
        wallHover: 'getWallHover',
        selectedDakafwerking: 'getSelectedDakafwerking',
        slidingWalls: "getSlidingWallComponents", // needed to fix routing issue
        steellooks: "getSteellookComponents",
        overstekConfig: 'getOverstekConfig',
        currentIntersectingLichtstraatDimension: 'getCurrentIntersectingLichtstraatDimension',
        selectedLichtstraatDimension: 'getSelectedLichtstraatDimension',
        isLoading: 'getLoading',
      }),

      diepte() {
        return this.afmetingen.diepte
      },

      cursorStyle() {
        if (this.selectedLichtstraat && this.isMovingSelectedLichtstraat) return 'grabbing'
        if (this.isHoveringOverLichtstraat) return 'grab'
        return this.currentIntersect || this.currentIntersectingLichtstraatDimension ? 'pointer' : 'default'
      }
    },

    async created () {
      //if(!this.pepernoot) {return}
      this.scene.add(this.lights.ambient)
      // console.log(this.afmetingen)
      await this.$store.dispatch('createFrame', {
        THREE: THREE, 
        scene: this.scene, 
        start: null,
        typeId: this.config.typeId,
        modelId: this.config.modelId,
        afmetingen: this.afmetingen,
        hover: this.$route.path == "/cfg/walls/"
      })
      this.scene.add(this.frame.frame)
      this.scene.add(this.frame.map)
      this.scene.add(this.frame.model)

      this.camera.position.set(this.afmetingen.z/2-10000, this.afmetingen.y/2+3500, this.afmetingen.x/2-4000)
      this.scene.add(this.camera.parent)

      this.mapCamera.position.x = 10000
      this.mapCamera.position.y = 100000
      this.mapCamera.position.z = 10000
      this.scene.add(this.mapCamera)

      this.renderer.sortObjects = false;
      this.renderer.setSize(window.innerWidth >= 1000 ? window.innerWidth+530 : window.innerWidth+70, window.innerWidth >= 1000 ? window.innerHeight : (window.innerHeight / 2) + 15 ) // +15 for the border radius of the UI
      this.composer.setSize(window.innerWidth >= 1000 ? window.innerWidth+530 : window.innerWidth+70, window.innerWidth >= 1000 ? window.innerHeight : (window.innerHeight / 2) + 15 ) // +15 for the border radius of the UI
      
      this.sizes = {
        width: window.innerWidth >= 1000 ? window.innerWidth+530 : window.innerWidth+70,
        height: window.innerWidth >= 1000 ? window.innerHeight : (window.innerHeight / 2) + 15
      }

      this.controls = new OrbitControls(this.camera, this.renderer.domElement)
      this.controls.minDistance = 0.1
      this.controls.maxDistance = 50000
      //this.controls.enableDamping = true
      //this.controls.dampingFactor = 0.05

      this.controls.target.set(this.afmetingen.z/2,this.afmetingen.y/2,this.afmetingen.x/2)
      if (!this.disableControlsUpdate) this.controls.update();

      // this.controls.saveState()
      // window.resetCamera = () => {
      //   this.controls.reset()
      // }

      this.mapControls = new OrbitControls(this.mapCamera, this.renderer.domElement)
      //this.mapControls.enableDamping = true
      //this.mapControls.dampingFactor = 0.05

      this.mapControls.target.set(2720 / 2, 0, (4320+4200)/2);
      this.mapControls.minPolarAngle = 0; // radians
      this.mapControls.maxPolarAngle = 0; // radians
      this.mapControls.minAzimuthAngle = 270*(Math.PI/180); // radians
      this.mapControls.maxAzimuthAngle = 270*(Math.PI/180); // radians
      this.mapControls.update();

      this.$store.commit('updateRenderData', {key: 'controls', value: this.controls})
      this.$store.commit('updateRenderData', {key: 'mapControls', value: this.mapControls})

      window.addEventListener('resize', this.handleResize);
      if(document.location.hostname === 'tw-js-dev.mtest.nl' || document.location.hostname === 'localhost' || document.location.hostname === 'tw-v01-js-dev.mtest.nl') {
        window.addEventListener("keydown", (e) => {
          // console.log(e)
          if(e.shiftKey && e.code == "NumpadMultiply") {
            this.showWallRelations()
          }
          if(e.shiftKey && e.code == "NumpadSubtract"){
            this.setWallRandomHeight()
          }
          if(e.shiftKey && e.code == "End"){
            this.setAllOnBeforeRender()
          }
          if(e.shiftKey && e.code == "AltLeft"){
            this.devMode = !this.devMode
          }
          if(e.shiftKey && e.code == "Home"){
            this.scalingMode = !this.scalingMode
          }
          if(e.shiftKey && e.code == "Enter"){
            this.effect = !this.effect
          }
        });
      }
      window.addEventListener("keydown", (e) => {
        if(e.shiftKey && e.code == "Backspace"){
          this.specImagesMode = !this.specImagesMode
        }
      });

      const paalgeometry = new THREE.BufferGeometry()
      paalgeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(boxBuffer.positionBuffer(100, 2300, 100)), 3))
      paalgeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(boxBuffer.uvBuffer()), 2))
      paalgeometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(boxBuffer.normalBuffer()), 3))
      paalgeometry.setIndex(boxBuffer.indexBuffer());
      paalgeometry.groups = boxBuffer.groupBuffer()
      const paalMaterial = new THREE.MeshBasicMaterial({
        color: 0x049ef4,
        //transparent: true,
        //opacity: 0,
        wireframe: false
      });
      const paal = new THREE.Mesh( paalgeometry, paalMaterial );
      paal.position.set(50,2300/2,-50)
      //this.scene.add(paal)



      const trackingPlaneMap = new THREE.TextureLoader().load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/testtexture.png`);
      trackingPlaneMap.anisotropy = 0;
      trackingPlaneMap.magFilter = THREE.NearestFilter;
      trackingPlaneMap.minFilter = THREE.NearestFilter;
      trackingPlaneMap.encoding = THREE.sRGBEncoding;
      trackingPlaneMap.wrapS = THREE.RepeatWrapping;
      trackingPlaneMap.wrapT = THREE.RepeatWrapping;
      trackingPlaneMap.repeat.set(100,100)
      const trackingPlaneMaterial = new THREE.MeshBasicMaterial( { map: trackingPlaneMap, side: THREE.DoubleSide, transparent: true, opacity: 0.5/*, depthWrite: false*/} );
      const trackingPlaneGeometry = new THREE.BufferGeometry()
      trackingPlaneGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(100000, 100000)), 3))
      trackingPlaneGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
      trackingPlaneGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))
      const trackingPlane = markRaw(new THREE.Mesh( trackingPlaneGeometry, trackingPlaneMaterial ));
      trackingPlane.position.set(4000,4000,4000)
      trackingPlane.rotation.x= 1.5*Math.PI
      trackingPlane.visible = false
      this.trackingPlane = trackingPlane
      this.scene.add(this.trackingPlane)

      // lichtstraten tracking plane
      const lichtstraatTrackingPlaneMap = new THREE.TextureLoader().load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}ui/testtexture.png`);
      lichtstraatTrackingPlaneMap.anisotropy = 0;
      lichtstraatTrackingPlaneMap.magFilter = THREE.NearestFilter;
      lichtstraatTrackingPlaneMap.minFilter = THREE.NearestFilter;
      lichtstraatTrackingPlaneMap.encoding = THREE.sRGBEncoding;
      lichtstraatTrackingPlaneMap.wrapS = THREE.RepeatWrapping;
      lichtstraatTrackingPlaneMap.wrapT = THREE.RepeatWrapping;
      lichtstraatTrackingPlaneMap.repeat.set(100,100)
      const lichtstraatTrackingPlaneMaterial = new THREE.MeshBasicMaterial( { map: lichtstraatTrackingPlaneMap, side: THREE.DoubleSide, transparent: true, opacity: 0.5/*, depthWrite: false*/} );
      const lichtstraatTrackingPlaneGeometry = new THREE.BufferGeometry()
      lichtstraatTrackingPlaneGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(100000, 100000)), 3))
      lichtstraatTrackingPlaneGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
      lichtstraatTrackingPlaneGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))
      lichtstraatTrackingPlaneGeometry.translate(-50000, 0, -50000)
      const lichtstraatTrackingPlane = markRaw(new THREE.Mesh( lichtstraatTrackingPlaneGeometry, lichtstraatTrackingPlaneMaterial ));
      lichtstraatTrackingPlane.position.set(0,4000,0)
      lichtstraatTrackingPlane.visible = false
      this.lichtstraatTrackingPlane = lichtstraatTrackingPlane
      this.scene.add(this.lichtstraatTrackingPlane)

      this.setShadowAfmetingen(this.afmetingen)

      this.hasFinishedCreating = true
    },

    mounted () {
      //this.$store.dispatch('setCurrentRoute', this.$router.currentRoute)

      // if(this.slidingWalls.length === 0) { this.getSlidingWalls() }

      if(document.location.hostname === 'tw-js-dev.mtest.nl' || document.location.hostname === 'localhost' || document.location.hostname === 'tw-v01-js-dev.mtest.nl') {
        document.getElementById("lastUpdated").innerHTML = document.lastModified
      }

      this.$refs.threeCanvas.appendChild(this.renderer.domElement)
      this.renderer.domElement.style.marginLeft = window.innerWidth >= 1000 ? "-530px" : "0px"
      

      this.mouse = new THREE.Vector2()

      this.$refs.threeCanvas.addEventListener('mousemove', (event) => {

        // if($("#myDiv:hover").length != 0) {
        //   this.isHoveringOverCanvas = true
        // } else {
        //   this.isHoveringOverCanvas = true
        // }
        this.mouse.x = ( (event.clientX + (window.innerWidth >= 1000 ? 530 : 0)) / (this.sizes.width) ) * 2 - 1
        this.mouse.y = - ( event.clientY / this.sizes.height ) * 2 + 1
        this.mouse.cx = event.clientX
        this.mouse.cy = event.clientY
        if(/*this.getMovingBeam*/store._state.data.frame.frame.movingBeam) {
          //console.log(this.mouse.cx)
          this.moveRaycaster.setFromCamera(this.mouse, this.isMapOn? this.mapCamera : this.camera)
          this.moveRaycaster.firstHitOnly = true;
          const intersects = this.moveRaycaster.intersectObjects([this.trackingPlane])
          // console.log("updateDrag2")
          // console.log(intersects)
          if(intersects.length > 0) {
            // console.log("updateDrag")
            this.$store.dispatch('updateMovingBeam', {mousePosition: this.mouse.cx, planePosition: intersects[0].point})
          }
        }

        // lichtstraten
        if (this.selectedLichtstraat && this.isMovingSelectedLichtstraat) {
          this.moveRaycaster.setFromCamera(this.mouse, this.isMapOn? this.mapCamera : this.camera)
          this.moveRaycaster.firstHitOnly = true;
          const intersects = this.moveRaycaster.intersectObjects([this.lichtstraatTrackingPlane])
          if(intersects.length > 0) {
            this.$store.dispatch('updateLichtstraatPosition', {planePosition: intersects[0].point})
          }
        }
      })

      document.onmousedown = this.mouseDown ? (event) => {
        if(event.which != 1) {return}
      } : null

      this.$refs.threeCanvas.onmouseout = () => {
        this.isHoveringOverCanvas = false
        if(this.currentIntersect) {
          if(this.currentIntersect.object.name === 'hoverLineBuiten' || this.currentIntersect.object.name === 'hoverLineBinnen') {
            this.$store.dispatch('setWallHoverShowInFuture', {wall: this.currentIntersect.object, value: '', side: this.currentIntersect.object.name === 'hoverLineBinnen' ? 0 : 1})
            this.$store.dispatch('wallHoverLeave', this.currentIntersect.object)
            this.currentIntersect = null
          }
        }
      }

      this.$refs.threeCanvas.onmouseover = () => {
        this.isHoveringOverCanvas = true
      }

      this.$refs.threeCanvas.onmousedown = (event) => {
        if(event.which != 1) {return}

        if(this.devObject) {
          this.devObject.deselect()
          this.devObject = null
        }

        this.mouseDown = true
        this.start = Date.now()
        if (this.currentIntersect) {
          if (this.currentIntersect.object.name === 'arrowLeft' || this.currentIntersect.object.name === 'arrowRight') {
            //if (!((this.selectedWallObject.isLeftLocked && this.currentIntersect.object.name === 'arrowRight') || (!this.selectedWallObject.isLeftLocked && this.currentIntersect.object.name === 'arrowLeft'))) { 
              // console.log(this.currentIntersect.object.parent.parent.rotation.y % Math.PI === 0)
              if(this.currentIntersect.object.parent.parent.rotation.y % Math.PI === 0) {
                const alignedRotation = this.selectedWallObject.alignedRotation
                // console.log(alignedRotation)
                this.trackingPlane.position.x = this.currentIntersect.point.x + (alignedRotation ? +50000 : -50000)//this.currentIntersect.object.position.x-50000
                this.trackingPlane.position.y = this.currentIntersect.point.y-50000//this.currentIntersect.object.position.y-50000
                this.trackingPlane.position.z = this.currentIntersect.point.z//this.currentIntersect.object.position.z
                this.trackingPlane.rotation.z = this.currentIntersect.object.parent.parent.rotation.y
                
              } else {
                // console.log(this.currentIntersect.object.parent.parent.rotation.y)
                const alignedRotation = this.selectedWallObject.alignedRotation
                this.trackingPlane.position.x = this.currentIntersect.point.x//this.currentIntersect.object.position.x-50000
                this.trackingPlane.position.y = this.currentIntersect.point.y-50000//this.currentIntersect.object.position.y-50000
                this.trackingPlane.position.z = this.currentIntersect.point.z + (alignedRotation ? -50000 : +50000)//this.currentIntersect.object.position.z
                this.trackingPlane.rotation.z = this.currentIntersect.object.parent.parent.rotation.y
              }
              this.$store.dispatch('enableMoveBeam', {beam: markRaw(this.currentIntersect.object), mousePosition: this.mouse.cx, planePosition: this.currentIntersect.point})
              // console.log(this.currentIntersect)
              // console.log("enableDragging")
            //}
          }
          // isolatie knoppen
          if (this.currentIntersect.object.name === 'isolatieHoverButtonActive' || this.currentIntersect.object.name === 'isolatieHoverButtonInactive') {
            const status = this.currentIntersect.object.name.slice(19);
            this.$store.dispatch('toggleWandIsolatie', {currentStatus:status, buttonGroup:this.currentIntersect.object.parent, viewType:'Hover'});
          }
          if (this.currentIntersect.object.name === 'isolatieMapButtonActive' || this.currentIntersect.object.name === 'isolatieMapButtonInactive') {
            const status = this.currentIntersect.object.name.slice(17);
            this.$store.dispatch('toggleWandIsolatie', {currentStatus:status, buttonGroup:this.currentIntersect.object.parent, viewType:'Map'});
          }
          // topgevel isolatie knoppen
          if (this.currentIntersect.object.name === 'topIsolatieHoverButtonActive' || 		this.currentIntersect.object.name === 'topIsolatieHoverButtonInactive') {
            const status = this.currentIntersect.object.name.slice(22);
            this.$store.dispatch('toggleHoverTopgevelIsolatie', {currentStatus:status, buttonGroup:this.currentIntersect.object.parent});
          }
          if (this.currentIntersect.object.name === 'topIsolatieMapButtonActive' || this.currentIntersect.object.name === 'topIsolatieMapButtonInactive') {
            const status = this.currentIntersect.object.name.slice(20);
            this.$store.dispatch('toggleMapTopgevelIsolatie', {currentStatus:status, buttonGroup:this.currentIntersect.object.parent});
          }
        }

        // lichtstraten
        if (this.currentIntersectingLichtstraat) {
          this.selectedLichtstraat = this.currentIntersectingLichtstraat
          this.controls.enableRotate = false
          this.lichtstraatTrackingPlane.position.y = this.currentIntersectingLichtstraatHitPosition.y
          this.$store.dispatch('setSelectedLichtstraat', {lichtstraat: this.selectedLichtstraat, planePosition: this.currentIntersectingLichtstraatHitPosition})
          router.push('/cfg/roof/lichtstraten')
          this.isMovingSelectedLichtstraat = true
          if (this.selectedLichtstraat) {
            // this will hide the gordingen and dakbeschot
            this.$store.dispatch('startLichtstraatMove')
          }
        } else {
          this.controls.enableRotate = true
        }
      }

      this.$refs.threeCanvas.onmouseup = (event) => {
        if(event.which != 1) {return}
        this.mouseDown = false
        const end = Date.now();
        const diff = (end - this.start) + 1;
        this.start = null

        /*for(var i=0; i<this.intersectingObjects.length; i++) {
          console.log(this.intersectingObjects[i].name, this.intersectingObjects[i])
        }*/
      // }
        if (this.currentIntersect) {
          if (this.currentIntersect.object.name === 'arrowLeft' || this.currentIntersect.object.name === 'arrowRight') {
            this.moveRaycaster.setFromCamera(this.mouse, this.isMapOn? this.mapCamera : this.camera)
            this.moveRaycaster.firstHitOnly = true;
            const intersects = this.moveRaycaster.intersectObjects([this.trackingPlane])
            if(intersects.length > 0) {
              // console.log("updateDrag")
              this.$store.dispatch('disableMoveBeam')
            }
            //this.$store.dispatch('disableMoveBeam')
          }
        }

        // lichtstraten
        if (this.selectedLichtstraat) {
          if (this.isMovingSelectedLichtstraat) {
            // unhide the gordingen and dakbeschot
            this.$store.dispatch('endLichtstraatMove')
          }
          this.isMovingSelectedLichtstraat = false
          this.controls.enableRotate = true
        }


      // this.$refs.threeCanvas.addEventListener('click', () => {
        if (diff < 250) {
          //console.log(this.currentIntersect)
          if (this.currentIntersect) {
            //console.log(this.currentIntersect.object)
            //console.log(this.currentIntersect)
            console.log("this.currentIntersect.object.parent.name", this.currentIntersect.object?.parent?.name)
            if (this.currentIntersect.object.name === 'hoverLineBuiten' || this.currentIntersect.object.name === 'hoverLineBinnen') {
              //console.log(this.currentIntersect.object)
              this.$store.dispatch('addWall', this.currentIntersect.object)

            } else if (this.currentIntersect.object.name === 'buitenmuurMap' || this.currentIntersect.object.name === 'binnenmuurMap') {
              //console.log(this.currentIntersect.object)
              this.$store.dispatch('addWall', this.scene.getObjectById(this.currentIntersect.object.metaData.hoverID))
              
            } else if (this.currentIntersect.object.name === 'plusIconTopgevelBuiten' || this.currentIntersect.object.name === 'plusIconTopgevelBinnen' || this.currentIntersect.object.name === 'mapTriangleBuiten' || this.currentIntersect.object.name === 'mapTriangleBinnen') {
              //topgevel
              this.$store.dispatch('addTopgevel', this.currentIntersect.object)
          
            } else if (this.currentIntersect.object.name === 'plusIcon') {
              //alert("plusicon")
              //console.log(this.selectedWallComponent)
              // console.log(this.selectedWallComponent)
              if(this.currentIntersect.object.isSteelLookButton) {
                console.log("plusIconObject", this.currentIntersect.object.parent.parent.uuid, this.currentIntersect.object.parent.name)
                console.log(this.selectedWallComponentSettings)
                if(this.$route.path == '/cfg/walls/sliding-walls') {-
                  this.$store.dispatch('addSchuifwand', {wall: this.currentIntersect.object, settings: this.selectedWallComponentSettings})
                } else {
                  this.$store.dispatch('addSteelLook', {wall: this.currentIntersect.object, component: this.selectedWallComponent, settings: this.selectedWallComponentSettings})
                  // redirect naar /walls/ als het een steellook met borstwering is
                  if (this.selectedWallComponent.category === 'Steellook Wand BW') {
                    router.push('/cfg/walls/')
                  }
                }
                console.log(this.currentIntersect.object.parent.parent.uuid)
              } else {
                this.$store.dispatch('updateWallHoles', {wall: this.currentIntersect.object, component: this.selectedWallComponent, settings: this.selectedWallComponentSettings})
              }

            } else if (this.currentIntersect.object.name === 'arrowLeft' || this.currentIntersect.object.name === 'arrowRight') {
              //

            
            } else if (this.currentIntersect.object.parent.name === 'ModelBox') {
              //check if already selected
              if(this.selectedWall !== null) {
                this.$store.dispatch('deselectWall')
              }
              if(this.selectedElement !== null) {
                this.$store.dispatch('deselectElement')
              }

              //select element
              this.$store.dispatch('selectElement', this.currentIntersect.object.parent)
              // router.push('/cfg/walls/doors-and-windows')
              // move down --> router.push('/cfg/doorswindows/')

              //get selected element from wall
              const muur = this.getMuurInstances.find((muur) => muur.group.uuid === this.currentIntersect.object.parent.parent.parent.uuid)
              const muurMetadata = muur.wallButtonIndex[this.currentIntersect.object.parent.metaData.ModelPosition]
              const compoCategory = muurMetadata.component.category

              // Stay in /walls/ on selecting classic Steellook items
              if(compoCategory === 'Steellook') {
                // console.log('TEST 1464',this.currentIntersect.object)
                router.push('/cfg/walls/steellook')
              } else { // 'Raam', 'Deur', 'Steellook Raam', 'Steellook Deur'
                const tabRoute = this.getTabByCategory(compoCategory);
                router.push('/cfg/doorswindows/' + tabRoute)
              }

              //set selected element
              // console.log(muurMetadata.settings)
              this.$store.dispatch('changeSelectedWallComponent', {component: muurMetadata.component, settings: muurMetadata.settings})
              setTimeout(() => {
                document.getElementsByClassName("card-selected")[0].scrollIntoView({ behavior: 'smooth'});
              }, 100); 

            } else if (this.currentIntersect.object.parent.name === 'wallBox') {
              // console.log('test schuifwanden',this.currentIntersect.object)
              if (this.$route.path.includes('/cfg/walls/')) {
                if(this.selectedElement !== null) {
                  this.$store.dispatch('deselectElement')
                }
                if(this.selectedWall !== null) {
                  this.$store.dispatch('deselectWall')
                }
                this.$store.dispatch('selectWall', {wall: this.currentIntersect.object})

                // choose between walls/steellook and walls/sliding-walls for routing
                const muur = this.getMuurInstances.find((muur) => muur.group.uuid === this.currentIntersect.object.parent.parent.uuid)
                const muurModelCode = muur.settings.modelCode;
                console.log("muurModelCode", muurModelCode, this.steellooks)
                // const slidingWall = this.slidingWalls.find((component) => component.code === muurModelCode)
                // const steellook = this.steellooks.find((component) => component.code === muurModelCode)
                const slidingWall = this.findElementInArray(this.slidingWalls, muurModelCode)
                const steellook = this.findElementInArray(this.steellooks, muurModelCode)
                console.log("findElementInArray", slidingWall, steellook)
                // if it's a sliding wall and we're not on the route, do the router.push

                if(slidingWall && this.$route.path != '/cfg/walls/sliding-walls') {
                  router.push('/cfg/walls/sliding-walls')
                // if it's not a sliding wall, it's walls/steellook
                } 
                if(slidingWall == null && this.$route.path != '/cfg/walls/steellook') {
                  router.push('/cfg/walls/steellook')
                }
                // old code:
                // if(this.$route.path != '/cfg/walls/steellook') {
                //   router.push('/cfg/walls/steellook')
                // }   
                console.log("slidingWall, steellook", slidingWall, steellook, muur)
                const component = slidingWall || steellook
                const settings = {
                  glasType: component.glasTypes ? component.glasTypes.find(g => g.code === muurModelCode) || component.glasTypes[0] || null : null,
                  variant: component.variants ? component.variants?.find(v => v.code === muurModelCode) || component.variants[0] || null : null,
                  color: component.colors ? component.colors?.find(c => c.code === muurModelCode) || component.colors[0] || null : null,
                  // option: component.options.find(o => o.code === muurModelCode) || component.options[0] || null,
                  lastCode: muurModelCode,
                  hasBetonBand: this.checkKeyWords(component.keyWords) // task 1455
                }
                this.$store.dispatch('changeSelectedWallComponent', {component, settings})
                setTimeout(() => {
                  document.getElementsByClassName("card-selected")[0].scrollIntoView({ behavior: 'smooth'});
                }, 100);
              }
            } else if (this.currentIntersect.object.parent.name === 'wall' && this.currentIntersect.object.parent.visible && this.currentIntersect.object.name !== 'hoverLineBuiten' || this.currentIntersect.object.name !== 'hoverLineBinnen' || this.currentIntersect.object.name === 'topgevelSelectbox') {
              //this.currentIntersect.object.position.y=2000
              // console.log('TEST 1494',this.currentIntersect.object)
              if (this.$route.path.includes('/cfg/walls/')) {
                if(this.selectedElement !== null) {
                  this.$store.dispatch('deselectElement')
                }
                if(this.selectedWall !== null) {
                  this.$store.dispatch('deselectWall')
                }
                const muur = this.getMuurInstances.find((muur) => muur.group.uuid === this.currentIntersect.object.parent.uuid)
                let clickedOnBorstweringAsset = false
                if(muur?.isSlidingWall) {
                  if(this.$route.path != '/cfg/walls/sliding-walls') {
                    router.push('/cfg/walls/sliding-walls')
                  }
                } else if (this.currentIntersect.object.name === "borstweringAssetHitbox") {
                  clickedOnBorstweringAsset = true
                  const component = muur.borstweringComponent.component
                  // const settings = muur.borstweringComponent.settings
                  const modelCode = muur.borstweringComponent.modelCode
                  const settings = {
                    glasType: component.glasTypes ? component.glasTypes.find(g => g.code === modelCode) || component.glasTypes[0] || null : null,
                    variant: component.variants ? component.variants?.find(v => v.code === modelCode) || component.variants[0] || null : null,
                    color: component.colors ? component.colors?.find(c => c.code === modelCode) || component.colors[0] || null : null,
                    // option: component.options.find(o => o.code === muurModelCode) || component.options[0] || null,
                    lastCode: modelCode,
                    hasBetonBand: this.checkKeyWords(component.keyWords) // task 1455
                  }
                  this.$store.dispatch('changeSelectedWallComponent', {component, settings})
                  // clicked on steellook part of steellook met borstwering
                  if (this.$route.path != '/cfg/walls/steellook-borstwering') {
                    router.push('/cfg/walls/steellook-borstwering')
                  }
                } else {
                  // clicked on wall or wall part of steellook met borstwering
                  if (this.$route.path != '/cfg/walls/') {
                    router.push('/cfg/walls/')
                  }
                }
                this.$store.dispatch('selectWall', {wall: this.currentIntersect.object, clickedOnBorstweringAsset})

              } /*else if (this.$route.path === '/cfg/walls/doors-and-windows') {
                console.log("leeg")
              }*/
            // console.log(this.currentIntersect.object)
            // console.log(this.intersectingObjects)
            } 
          } else {
            if (this.selectedWall) {
              this.$store.dispatch('deselectWall')
            }
            if (this.selectedElement) {
              this.$store.dispatch('deselectElement')
            }
            console.log("deselect lichtstraat", this.selectedLichtstraat, this.isHoveringOverLichtstraat)
            if (this.selectedLichtstraat && !this.isHoveringOverLichtstraat) {
              this.selectedLichtstraat = null
              this.$store.dispatch('setSelectedLichtstraat', {lichtstraat: null})
            }
            if (this.selectedLichtstraatDimension && !this.currentIntersectingLichtstraatDimension) {
              this.$store.commit('setSelectedLichtstraatDimension', null)
            }
          }

          if (this.currentIntersectingLichtstraatDimension) {
            this.$store.commit('setSelectedLichtstraatDimension', this.currentIntersectingLichtstraatDimension)
          }
        }
      }


      /*if (this.$route.path === '/cfg/walls/' || this.$route.path === '/cfg/walls/doors-and-windows' || this.$route.path === '/cfg/walls/steellook') {
        this.$store.dispatch('changeWallHover', true)
      } else {
        this.$store.dispatch('changeWallHover', false)
      }*/

      const hoofdthis = this
      
      function retryTick() {
        if(hoofdthis.hasFinishedCreating) {
          hoofdthis.tick()
        } else {
          setTimeout(() => {
            retryTick()
          }, 500);
        }
      }
      retryTick()
      
      // console.log(router.currentRoute.path)
      this.$store.dispatch('setCurrentRoute', router.currentRoute.value.path)
    },

    unmounted() {
      window.removeEventListener('resize', this.handleResize);
    },

    watch: {
      $route: {
        handler(to, from) {
          //alert("wijziging")
          this.$nextTick(() => {
            store.commit('updateIntersectingObjects')
          })
          if (to.path === '/cfg/walls/' /*|| to.path === '/cfg/walls/doors-and-windows'*/) {

            this.$store.dispatch('changeWallHover', true)

          } else if (from && from.path === '/cfg/walls/' /*|| from.path === '/cfg/walls/doors-and-windows'*/) {

            this.$store.dispatch('changeWallHover', false)

          }

          // feature 1394
          // if (to.path === '/cfg/walls/doors-and-windows' || to.path === '/cfg/walls/steellook' || to.path === '/cfg/walls/sliding-walls') {
          if (to.path === '/cfg/doorswindows' || to.path === '/cfg/walls/steellook' || to.path === '/cfg/walls/steellook-borstwering' || to.path === '/cfg/walls/sliding-walls' ||
              to.path === '/cfg/doorswindows/bwindows' || to.path === '/cfg/doorswindows/bdoors' ||
              to.path === '/cfg/doorswindows/slwindows' || to.path === '/cfg/doorswindows/sldoors' ||
              to.path === '/cfg/doorswindows/slwindowsbw') {

            this.$store.dispatch('setElementPlacing', true)

          // } else if ((from.path === '/cfg/walls/doors-and-windows' && to.path !== '/cfg/walls/steellook' && to.path !== '/cfg/walls/sliding-walls') 
          // || (from.path === '/cfg/walls/steellook' && to.path !== '/cfg/walls/doors-and-windows' && to.path !== '/cfg/walls/sliding-walls') 
          // || (from.path === '/cfg/walls/sliding-walls' && to.path !== '/cfg/walls/doors-and-windows' && to.path !== '/cfg/walls/steellook')) {
          // } else if
          //   // ((from.path === '/cfg/doorswindows' && to.path !== '/cfg/walls/steellook' && to.path !== '/cfg/walls/sliding-walls')
          //   // || (from.path === '/cfg/walls/steellook' && to.path !== '/cfg/doorswindows' && to.path !== '/cfg/walls/sliding-walls')
          //   // || (from.path === '/cfg/walls/sliding-walls' && to.path !== '/cfg/doorswindows' && to.path !== '/cfg/walls/steellook'))
          //
          //   // Remove Set Element Placing mode
          //   // Force every path out of the set of walls and doorswindows routes to meet condition.
          //   ((from.path.includes('cfg/walls')) && (!to.path.includes('cfg/walls')) ||
          //    (from.path.includes('cfg/walls')) && (!to.path.includes('cfg/doorswindows')) ||
          //    (from.path.includes('cfg/doorswindows')) && (!to.path.includes('cfg/doorswindows')) ||
          //    (from.path.includes('cfg/doorswindows')) && (!to.path.includes('cfg/walls'))
          //   )
          //   {
          //     this.$store.dispatch('setElementPlacing', false)
          //
          //     // task 1521 always deselect when routing away from /walls or /doorswindows
          //     if (this.selectedWall) {
          //       this.$store.dispatch('deselectWall')
          //     }
          //     if (this.selectedElement) {
          //       this.$store.dispatch('deselectElement')
          //     }
          //   }

          }
          if (!from) return
          if (from.path.includes('cfg/walls') && (!to.path.includes('cfg/walls'))) {
            if (this.selectedWall) {
              this.$store.dispatch('deselectWall')
            }
          }
          if (from.path.includes('cfg/walls/steellook') && (!to.path.includes('cfg/walls/steellook'))) {
            this.$store.dispatch('setElementPlacing', false)
            if (this.selectedElement) {
              this.$store.dispatch('deselectElement')
            }
          }
          if (from.path.includes('cfg/walls/sliding-walls') && !to.path.includes('cfg/walls/sliding-walls')) {
            this.$store.dispatch('setElementPlacing', false)
            if (this.selectedElement) {
              this.$store.dispatch('deselectElement')
            }
          }
          if (from.path.includes('cfg/doorswindows') && !to.path.includes('cfg/doorswindows')) {
            this.$store.dispatch('setElementPlacing', false)
            if (this.selectedElement) {
              this.$store.dispatch('deselectElement')
            }
          }
          // deselect current element between steellooks and steellook-borstwering
          if (
            (from.path === '/cfg/walls/steellook' && to.path === '/cfg/walls/steellook-borstwering') ||
            (from.path === '/cfg/walls/steellook-borstwering' && to.path === '/cfg/walls/steellook')
          ) {
            if (to.path === '/cfg/walls/steellook') {
              // als er geen normale steellook is geslecteerd
              if (this.selectedWallObject && (!this.selectedWallObject.isSteellook || (this.selectedWallObject.isSteellook && this.selectedWallObject.isBorstwering))) {
                this.$store.dispatch('deselectWall')
              }
            }
            if (to.path === '/cfg/walls/steellook-borstwering') {
              // als er geen steellook met borstwering is geselecteerd
              if (this.selectedWallObject && (!this.selectedWallObject.isSteellook || (this.selectedWallObject.isSteellook && !this.selectedWallObject.isBorstwering))) {
                this.$store.dispatch('deselectWall')
              }
              // voor elementen in muur geplaatst:
              if (this.selectedElement && this.selectedWallComponent && this.selectedWallComponent.category !== 'Steellook Wand BW') {
                this.$store.dispatch('deselectElement')
              }
            }

          }

          if (
            this.selectedWallObject && !this.selectedWallObject.isSteellook &&
            (
              (from.path === '/cfg/walls/' && to.path === '/cfg/walls/steellook-borstwering') ||
              (from.path === '/cfg/walls/' && to.path === '/cfg/walls/steellook')
            )
          ) {
            this.$store.dispatch('deselectWall')
          }
          //
          // if (
          //     this.selectedWallObject && !this.selectedWallObject.isSteellook &&
          //     (
          //         (from.path === '/cfg/walls/steellook-borstwering' && to.path === '/cfg/walls/') ||
          //         (from.path === '/cfg/walls/steellook' && to.path === '/cfg/walls/')
          //     )
          // ) {
          //   this.$store.dispatch('deselectWall')
          // }

          // feature 1394
          // if (to.path === '/cfg/walls/doors-and-windows' || to.path === '/cfg/walls/' || to.path === '/cfg/walls/steellook' || to.path === '/cfg/walls/sliding-walls') {
          if (to.path === '/cfg/doorswindows/' || to.path === '/cfg/walls/' || to.path === '/cfg/walls/steellook' || to.path === '/cfg/walls/steellook-borstwering' || to.path === '/cfg/walls/sliding-walls' ||
              to.path === '/cfg/doorswindows/bwindows' || to.path === '/cfg/doorswindows/bdoors' ||
              to.path === '/cfg/doorswindows/slwindows' || to.path === '/cfg/doorswindows/sldoors' ||
              to.path === '/cfg/doorswindows/slwindowsbw' || to.path.includes('/cfg/roof/')) {
            this.$store.dispatch('setIntersection', true)
          } else {
            this.$store.dispatch('setIntersection', false)
          }

          if (from.path.includes('/cfg/roof/') && !to.path.includes('/cfg/roof/') && this.selectedLichtstraat) {
            this.selectedLichtstraat = null
            this.$store.dispatch('setSelectedLichtstraat', {lichtstraat: null})
          }

          if (to.path.includes('/cfg/roof/lichtstraten')) {
            // move camera to roof
            const modelType = this.frame.modelType
            const afmetingen = this.config.afmetingen
            const overstekKlos = afmetingen.overstekKlos
            const overstekConfig = this.overstekConfig
            if (!overstekConfig.voEnabled) overstekKlos.klosVoEnabled = false
            if (!overstekConfig.acEnabled) overstekKlos.klosAcEnabled = false
            if (!overstekConfig.liEnabled) overstekKlos.klosLiEnabled = false
            if (!overstekConfig.reEnabled) overstekKlos.klosReEnabled = false
            let klosVoEnabled = overstekKlos.enabled? overstekKlos.klosVoEnabled : false
            let xBovVo =  klosVoEnabled ? overstekKlos.xBovVo : 0
            let klosAcEnabled = overstekKlos.enabled? overstekKlos.klosAcEnabled : false
            let xBovAc = klosAcEnabled ? overstekKlos.xBovAc : 0
            let klosLiEnabled = overstekKlos.enabled? overstekKlos.klosLiEnabled : false
            let xBovLi = klosLiEnabled ? overstekKlos.xBovLi : 0
            let klosReEnabled = overstekKlos.enabled? overstekKlos.klosReEnabled : false
            let xBovRe = klosReEnabled ? overstekKlos.xBovRe : 0
            const extraRuimteVoor = xBovVo + (afmetingen.boeideel.enabled?afmetingen.boeideel.z:0) + (afmetingen.boeideel.enabled&&afmetingen.boeideel.lijstEnabled?afmetingen.boeideel.lz:0) + (afmetingen.trim.enabled?afmetingen.trim.overhang:0)
            const extraRuimteAchter = modelType.isVeranda ? 0 : (xBovAc + (afmetingen.boeideel.enabled?afmetingen.boeideel.z:0) + (afmetingen.boeideel.enabled&&afmetingen.boeideel.lijstEnabled?afmetingen.boeideel.lz:0) + (afmetingen.trim.enabled?afmetingen.trim.overhang:0))
            const extraRuimteLinks = xBovLi + (afmetingen.boeideel.enabled?afmetingen.boeideel.z:0) + (afmetingen.boeideel.enabled&&afmetingen.boeideel.lijstEnabled?afmetingen.boeideel.lz:0) + (afmetingen.trim.enabled?afmetingen.trim.overhang:0)
            const extraRuimteRechts = xBovRe + (afmetingen.boeideel.enabled?afmetingen.boeideel.z:0) + (afmetingen.boeideel.enabled&&afmetingen.boeideel.lijstEnabled?afmetingen.boeideel.lz:0) + (afmetingen.trim.enabled?afmetingen.trim.overhang:0)
            const trimToTrimSizeWidth = this.config.afmetingen.breedte + extraRuimteLinks + extraRuimteRechts
            const trimToTrimSizeDepth = this.config.afmetingen.diepte + extraRuimteVoor + extraRuimteAchter
            const trimTopPosition = afmetingen.trim.position + afmetingen.trim.y
            const renderWidth = window.innerWidth >= 1000 ? window.innerWidth+530 : window.innerWidth+70
            const visibleRenderWidth = window.innerWidth >= 1000 ? window.innerWidth-670 : window.innerWidth-70
            const renderHeight = window.innerWidth >= 1000 ? window.innerHeight : (window.innerHeight / 2) + 15
            const visibleRenderHeight = window.innerWidth >= 1000 ? window.innerHeight : (window.innerHeight / 2)
            const cameraVerticalFOV = this.camera.fov
            const actualCameraVerticalFOV = this.camera.fov * (visibleRenderHeight/renderHeight)
            const cameraHorizontalFOV = (2 * Math.atan(Math.tan((cameraVerticalFOV * Math.PI) / 360) * (visibleRenderWidth/renderHeight)) * 180) / Math.PI;
            const trimToCameraDistanceHorizontal = (trimToTrimSizeWidth/2) / Math.tan(((cameraHorizontalFOV)/2) * Math.PI / 180)
            const trimToCameraDistanceVertical = (trimToTrimSizeDepth/2) / Math.tan(((actualCameraVerticalFOV)/2) * Math.PI / 180)
            const trimToCameraDistance = Math.max(trimToCameraDistanceHorizontal, trimToCameraDistanceVertical)

            // disable any other controls.update()'s
            this.disableControlsUpdate = true
            this.controls.enabled = false

            const previousAzimuthAngle = this.controls.getAzimuthalAngle()
            const previousPolarAngle = this.controls.getPolarAngle()
            let newAzimuthAngle = previousAzimuthAngle > Math.PI/2 ? Math.PI*1.5 : -Math.PI/2
            const targetBegin = this.controls.target.clone()
            const targetEnd = {
              x: this.afmetingen.z/2 + (extraRuimteAchter-extraRuimteVoor)/2,
              y: this.afmetingen.y/2,
              z: this.afmetingen.x/2 + (extraRuimteRechts-extraRuimteLinks)/2
            }
            const cameraBegin = this.controls.getDistance()
            const extraSpaceHeight = 2000
            const cameraEnd = -this.afmetingen.y/2 + trimTopPosition + trimToCameraDistance + extraSpaceHeight//this.afmetingen.y/2 + this.afmetingen.x*1.5

            this.realTweenProgress = {value: 0}
            this.previousRealTweenProgress = 0 // progress value, but with tween timing-function

            this.moveCameraBackValues = {
              targetBegin,
              previousAzimuthAngle,
              previousPolarAngle,
              cameraBegin,
              cameraPositionBegin: this.camera.position.clone()
            }

            const tweenControls = new TWEEN.Tween(this.realTweenProgress)
              .to({value: 1}, 1000)
              .easing(TWEEN.Easing.Quadratic.InOut)
              .onUpdate(() => {
                this.controls.target.set(
                  targetBegin.x + (targetEnd.x-targetBegin.x)*this.realTweenProgress.value,
                  targetBegin.y + (targetEnd.y-targetBegin.y)*this.realTweenProgress.value,
                  targetBegin.z + (targetEnd.z-targetBegin.z)*this.realTweenProgress.value
                )
                this.controls.setPolarAngle(previousPolarAngle-(this.realTweenProgress.value*previousPolarAngle))
                this.controls.setAzimuthalAngle(previousAzimuthAngle+(this.realTweenProgress.value*(newAzimuthAngle-previousAzimuthAngle)))
                this.controls.update()

                // change camera distance to target
                const amountOfDistanceToAdd = (cameraEnd-cameraBegin)*(this.realTweenProgress.value-this.previousRealTweenProgress); // Example distance to add
                const direction = new THREE.Vector3()
                    .subVectors(this.camera.position, this.controls.target)
                    .normalize(); // Normalize the vector to get only the direction

                // Scale the direction vector by the distance to add
                const offset = direction.multiplyScalar(amountOfDistanceToAdd);

                // Add the offset to positionB
                this.camera.position.add(offset);

                // set previousRealTweenProgress so that the next time we can calculate the delta which we need to calulate the distance to add to the camera
                this.previousRealTweenProgress = this.realTweenProgress.value
              })
              .onComplete(() => {
                this.controls.target.set(
                    targetBegin.x + (targetEnd.x-targetBegin.x),
                    targetBegin.y + (targetEnd.y-targetBegin.y),
                    targetBegin.z + (targetEnd.z-targetBegin.z)
                )
                this.controls.setPolarAngle(previousPolarAngle-(1*previousPolarAngle))
                this.controls.setAzimuthalAngle(previousAzimuthAngle+(newAzimuthAngle-previousAzimuthAngle))
                this.controls.update()

                // change camera distance to target
                const amountOfDistanceToAdd = (cameraEnd-cameraBegin)*(1-this.previousRealTweenProgress); // Example distance to add
                const direction = new THREE.Vector3()
                    .subVectors(this.camera.position, this.controls.target)
                    .normalize(); // Normalize the vector to get only the direction

                // Scale the direction vector by the distance to add
                const offset = direction.multiplyScalar(amountOfDistanceToAdd);

                // Add the offset to positionB
                this.camera.position.add(offset);

                this.disableControlsUpdate = false
                this.controls.enabled = true
              })
              .start()

            this.tweenGroup.add(tweenControls)
          } else if (from.path === '/cfg/roof/lichtstraten' && this.moveCameraBackValues) {
            this.disableControlsUpdate = true
            this.controls.enabled = false

            const targetBegin = this.controls.target.clone()
            const targetEnd = this.moveCameraBackValues.targetBegin
            let previousAzimuthAngle = this.moveCameraBackValues.previousAzimuthAngle
            // let newAzimuthAngle = previousAzimuthAngle > Math.PI/2 ? Math.PI*1.5 : -Math.PI/2
            const previousPolarAngle = this.moveCameraBackValues.previousPolarAngle
            let startAzimuthAngle = this.controls.getAzimuthalAngle()
            // if (startAzimuthAngle > Math.PI/2) {
            //   startAzimuthAngle -= Math.PI*2
            // }
            const startPolarAngle = this.controls.getPolarAngle()
            const cameraBegin = this.controls.getDistance()
            const cameraEnd = this.moveCameraBackValues.cameraBegin
            const cameraPositionBegin = this.moveCameraBackValues.cameraPositionBegin

            this.realTweenProgress = {value: 0}
            this.previousRealTweenProgress = 0 // progress value, but with tween timing-function

            const tweenControls = new TWEEN.Tween(this.realTweenProgress)
              .to({value: 1}, 1000)
              .easing(TWEEN.Easing.Quadratic.InOut)
              .onUpdate(() => {
                this.controls.target.set(
                  targetBegin.x + (targetEnd.x-targetBegin.x)*this.realTweenProgress.value,
                  targetBegin.y + (targetEnd.y-targetBegin.y)*this.realTweenProgress.value,
                  targetBegin.z + (targetEnd.z-targetBegin.z)*this.realTweenProgress.value
                )
                this.controls.setPolarAngle(startPolarAngle + (this.realTweenProgress.value * (previousPolarAngle - startPolarAngle)))
                // const azimuthDistance = Math.abs(previousAzimuthAngle - startAzimuthAngle)
                // if (azimuthDistance > Math.PI) {
                //   previousAzimuthAngle -= Math.PI*2
                // }
                const azimuthDistance = Math.abs(previousAzimuthAngle - startAzimuthAngle)
                const shorterAzimuthDistance = ((azimuthDistance + Math.PI) % (2 * Math.PI)) - Math.PI;
                if (previousAzimuthAngle > startAzimuthAngle) {
                  this.controls.setAzimuthalAngle(startAzimuthAngle + (this.realTweenProgress.value * shorterAzimuthDistance))
                } else {
                  this.controls.setAzimuthalAngle(startAzimuthAngle - (this.realTweenProgress.value * shorterAzimuthDistance))
                }
                // this.controls.setAzimuthalAngle(startAzimuthAngle + (this.realTweenProgress.value * (previousAzimuthAngle - startAzimuthAngle)))
                this.controls.update()

                // change camera distance to target
                const amountOfDistanceToAdd = (cameraEnd-cameraBegin)*(this.realTweenProgress.value-this.previousRealTweenProgress); // Example distance to add
                const direction = new THREE.Vector3()
                  .subVectors(this.camera.position, this.controls.target)
                  .normalize(); // Normalize the vector to get only the direction

                // Scale the direction vector by the distance to add
                const offset = direction.multiplyScalar(amountOfDistanceToAdd);

                // Add the offset to positionB
                this.camera.position.add(offset);

                // set previousRealTweenProgress so that the next time we can calculate the delta which we need to calulate the distance to add to the camera
                this.previousRealTweenProgress = this.realTweenProgress.value
              })
              .onComplete(() => {
                this.controls.target.set(
                    targetBegin.x + (targetEnd.x-targetBegin.x),
                    targetBegin.y + (targetEnd.y-targetBegin.y),
                    targetBegin.z + (targetEnd.z-targetBegin.z)
                )
                this.controls.setPolarAngle(previousPolarAngle)
                this.controls.setAzimuthalAngle(startAzimuthAngle + (previousAzimuthAngle - startAzimuthAngle))
                this.controls.update()

                // change camera distance to target
                const amountOfDistanceToAdd = (cameraEnd-cameraBegin)*(1-this.previousRealTweenProgress); // Example distance to add
                const direction = new THREE.Vector3()
                    .subVectors(this.camera.position, this.controls.target)
                    .normalize(); // Normalize the vector to get only the direction

                // Scale the direction vector by the distance to add
                const offset = direction.multiplyScalar(amountOfDistanceToAdd);

                // Add the offset to positionB
                this.camera.position.add(offset);

                this.disableControlsUpdate = false
                this.controls.enabled = true
              })
              .start()

            this.tweenGroup.add(tweenControls)
          }


          this.$store.dispatch('setCurrentRoute', to.path)
        },
        immediate: true
      },

      isMapOn: {
        handler: function (val) {
          this.renderer.sortObjects = val
          this.updateScenesVisible()
        },
        immediate: true
      },

      afmetingen (afmetingen) {
        this.setShadowAfmetingen(afmetingen)
      },

      isLoading () {
        this.updateScenesVisible()
      },

    }

  }
</script>

<style lang="scss" scoped>

.background {
  background-repeat: repeat space;

  // background: #363636; /* Old browsers */
  // background: -moz-radial-gradient(circle,  #363636 0%, #171717 100%); /* FF3.6-15 */
  // background: -webkit-radial-gradient(circle,  #363636 0%,#171717 100%); /* Chrome10-25,Safari5.1-6 */
  // background: radial-gradient(circle,  #363636 0%,#171717 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#363636', endColorstr='#171717',GradientType=0 ); /* IE6-9 */
}

.devTools_container {
  position: absolute;
  top: 25px;
  left: 80px;
  max-height: calc(100% - 50px);
  overflow-y: scroll;
  width: 500px;
}

.scaling_container {
  position: absolute;
  top: 25px;
  left: 80px;
  max-height: calc(100% - 50px);
  //overflow-y: scroll;
  width: 400px;
}

.specImages_container {
  position: absolute;
  top: 25px;
  left: 80px;
  max-height: calc(100% - 50px);
  //overflow-y: scroll;
  width: 400px;
}

</style>