<template>
  <div class="scalingMenu">
    <h1>{{texture.name}}</h1>
    <div><input type="number" v-model="texture.scalingX" @input="$emit('updateTextureScaling', texture)"><input type="range" v-model="texture.scalingX" step="0.01" min="0" max="10" @input="$emit('updateTextureScaling', texture)"></div>
    <div><input type="number" v-model="texture.scalingY" @input="$emit('updateTextureScaling', texture)"><input type="range" v-model="texture.scalingY" step="0.01" min="0" max="10" @input="$emit('updateTextureScaling', texture)"></div>
    <div><input type="number" v-model="texture.emissive" @input="$emit('updateTextureScaling', texture)"><input type="range" v-model="texture.emissive" step="0.01" min="0" max="1" @input="$emit('updateTextureScaling', texture)"></div>
    <div><input type="text" v-model="texture.emissiveColor" @input="$emit('updateTextureScaling', texture)"></div>
    <div><input type="number" v-model="texture.metalness" @input="$emit('updateTextureScaling', texture)"><input type="range" v-model="texture.metalness" step="0.01" min="0" max="1" @input="$emit('updateTextureScaling', texture)"><h3>metalness</h3></div>
    <button @click="$emit('setTextureScaling', texture)">UPDATE</button>
  </div>
</template>

<script>
  export default {
    name: "FromField",

    data() {
      return {
      }
    },

    created() {
      /*setInterval(() => {
        console.log("texture", this.texture)
      }, 1000);*/
    },

    props: {
      texture: Object
    },
  }
</script>

<style>
.scalingMenu {
  background-color: #272822cc;
  border: 2px solid #0075FF;
  border-radius: 6px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
h1 {
  margin: 0px;
}
input[type=number] {
  width: 50px;
  outline: none;
  border: 1px solid #0075FF;
  margin-top: 10px;
}
input[type=range] {
  margin-left: 10px;
  width: 250px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

h3 {
  margin: 0px
}
</style>