<template>
  <section
    class="button-grid"
  >
    <button 
      v-for="(tab, index) in tabs" 
      :key="index"
      class="switch-button" 
      :style="`width: ${buttonWidth(tab.name + extension)}px;`"
      :class="currentPath.substring(currentPath.lastIndexOf('/'), currentPath.length) === tab.route ? 'switch-button-active' : ''" 
      @click="$emit('change', index); currentIndex = index;"
    >
      <mdicon 
        class="switch-button-icon" 
        :name="tab.icon" 
        size="20" 
      />
      {{ tab.name + extension }} 
    </button>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: "TabSelectorNoScrolling",
  // otherwise referred to during development as "nested tabs". meant to replace TabSelectorIcons.
  // the point is that you no longer have a horizontally scrolling bar with arrow buttons.

  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    extension: {
      type: String,
      default: ''
    }
  },

  emits: ['change'],

  data () {
  
  },
  computed: {
    ...mapGetters({
      currentPath: 'getCurrentPath',
    }),
  },
  methods: {
    buttonWidth(text) {
      let textLength = text.length * 8
      let buttonPadding = 20
      let icon = 25
      
      return textLength + buttonPadding + icon
    },
  },
}
</script>

<style lang="scss" scoped>

.button-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}

.switch {
  display: flex;
  gap: 10px;
  position: relative;
  z-index: 10;
  transition: transform 200ms ease;

  &-button {
    background-color: transparent;
    // border: none;
    border: 2px solid $c-border-1;
    border-radius: $radius-m;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    color: $c-secondary-text;
    z-index: 12;
    margin: 0px;
    height: 45px;
    border-radius: $radius-m;
    // transition: color 200ms ease;
    text-decoration: none;
    font-weight: 600;
    // transition: background-color 100ms ease;

    &:hover {
      // background-color: $c-background-1-hover;
      background-color: $c-background-1;
      cursor: pointer;
    }

    &-active {
      color: $c-secondary-bright;//$c-secondary;
      background-color: $c-primary;
      border: 2px solid $c-primary;

      &:hover {
        background-color: $c-primary;//transparent;
        cursor: default;
      }
    }
  }
}

</style>