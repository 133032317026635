<template>
  <div class="top-page-solo">
    <ComponentCardV2 
      v-if="schuifwandTypes.length > 0"
      ref="component"
      :data="slidingWallElement"
      :selected="true"
      variantText="schuifrichting:"
      :variantText2 = "selectedWallData ? 'aantal glasplaten (breedte):' : null"
      :variants2="filteredSlidingWalls"
      :cselected="getComponentSelected()"
      :wSelected="selectedWallData"
      :scolor="schuifwandKleur"
      :svariant="schuifwandRichting"
      :svariant2="schuifwandPreference < filteredSlidingWalls.length ? schuifwandPreference : filteredSlidingWalls.length-1"
      @select="changeSelectedComponent($event)"
      @setSchuifwandRichting="setSchuifwandRichting($event)"
      @setSchuifwandKleur="setSchuifwandKleur($event)"
      @setSchuifwandPreference="setSchuifwandPreference($event)"
      :imgUrl="imgUrl"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ComponentCardV2 from "@/components/inputs/ComponentCardV2.vue";

export default {
  components: {
    ComponentCardV2,
  },
  name: "SlidingWalls",

  data () {
    return {
      slidingWallSchuifrichtingen: [],
      slidingWallColors: [],
      slidingWallElement: {
        colors: [],
        variants: [],
        size: 0
      },
      imgUrl: ""
    }
  },

  computed: {
    ...mapGetters({
      components: "getSlidingWallComponents",
      selected: 'getSelectedWallComponent',
      selectedSettings: 'getSelectedWallComponentSettings',
      // TODO change names above so they're the same as in SteellookDoors.vue etc
      selectedElement: 'getSelectedElement',
      selectedWall: 'getSelectedWall',
      selectedWallWidth: 'getSelectedWallWidth',
      getMuurInstances: 'getMuurInstances',

      schuifwandTypes: "getSchuifwandTypes",
      schuifwandWidthRanges: "getSchuifwandWidthRanges",

      schuifwandRichting: "getSchuifwandRichting",
      schuifwandKleur: "getSchuifwandKleur",
      schuifwandPreference: "getSchuifwandPreference",
    }),
    selectedWallData() {
      return this.getMuurInstances.find((muur) => muur.group.uuid === this.selectedWall?.wall?.parent?.parent?.uuid) || this.getMuurInstances.find((muur) => muur.group.uuid === this.selectedWall?.wall?.parent?.uuid)
    },

    filteredSlidingWalls() {
      const allSlidingWalls = JSON.parse(JSON.stringify(this.schuifwandTypes))
      allSlidingWalls.sort((a, b) => a.maximaleBreedte - b.maximaleBreedte)
      if(!this.selectedWallData) return allSlidingWalls
      let filterendSlidingWalls = []
      allSlidingWalls.forEach((type) => {
        if(type.minimaleBreedte <= this.selectedWallWidth && type.maximaleBreedte >= this.selectedWallWidth) {
          if(!filterendSlidingWalls.find(w => w.glasplaatAantal === type.glasplaatAantal && w.glasplaatBreedte === type.glasplaatBreedte)) {
            filterendSlidingWalls.push(type)
          }
        }
      })
      return filterendSlidingWalls
    }
  },

  methods: {

    // changeSelectedComponent (data) {
    // changeSelectedComponent (component) {     
    //   console.log("changeSelectedComponent", component) 
    //   this.imgCode = component.code
    //   // const component = data.component
    //   // const colorIndex = data.colorIndex
    //   // const optionIndex = data.optionIndex

    //   // const settings = {
    //   //   color: colorIndex ? component.colors[colorIndex] : null,
    //   //   option: optionIndex ? component.options[optionIndex] : null,
    //   // }

    //   const sglasType = this.$refs.component.selectedGlasType;
    //   const sVariant = this.$refs.component.selectedVariant;
    //   const sColor = this.$refs.component.selectedColor;
    //   const sOption = this.$refs.component.selectedOption 
    //   const sLastCode = this.$refs.component.lastCodeSelection;
    //   // console.log('component.keyWords',component.keyWords)
    //   const settings = {
    //       glasType: component.glasTypes && component.glasTypes.length>1 ? component.glasTypes[sglasType] : component.glasTypes ? component.glasTypes[0] : null,
    //       variant: component.variants && component.variants.length>1 ? component.variants[sVariant] : component.variants ? component.variants[0] : null,
    //       color: component.colors.length>1 ? component.colors[sColor] : component.colors[0],
    //       option: component.options.length>1 ? component.options[sOption] : component.options[0],
    //       lastCode: sLastCode,
    //       // hasBetonBand depends on keyword "noBetonband"
    //       hasBetonBand: this.checkKeyWords(component.keyWords) // task 1563
    //   }      
    //   // console.log('settings.hasBetonBand',settings.hasBetonBand)
    //   this.$store.dispatch('changeSelectedWallComponent', {component: component, settings: settings})
    // },

    setSchuifwandRichting (schuifrichting) {
      this.$store.dispatch('setSchuifwandRichting', schuifrichting)

      this.setImgUrl()

      if(this.selectedWallData) {
        this.selectedWallData.schuifwandRichting = schuifrichting
        this.selectedWallData.update()
      }
    },

    setSchuifwandKleur (color) {
      this.$store.dispatch('setSchuifwandKleur', color)

      if(this.selectedWallData) {
        this.selectedWallData.schuifwandKleur = color
        this.selectedWallData.update()
      }
    },

    setSchuifwandPreference (preference) {
      const preferenceIndex = this.filteredSlidingWalls.findIndex(w => w.id === preference.id)
      this.$store.dispatch('setSchuifwandPreference', preferenceIndex)

      this.setImgUrl()

      if(this.selectedWallData) {
        this.selectedWallData.schuifwandPreference = preferenceIndex
        this.selectedWallData.update()
      }
    },

    setImgUrl () {
      const chosenShuifwand = this.filteredSlidingWalls[this.schuifwandPreference > this.filteredSlidingWalls.length-1 ? this.filteredSlidingWalls.length-1 : this.schuifwandPreference]
      const matchingPreferences = this.schuifwandTypes.filter(w => 
        w.glasplaatAantal === chosenShuifwand.glasplaatAantal && 
        w.glasplaatBreedte === chosenShuifwand.glasplaatBreedte && 
        w.maximaleBreedte === chosenShuifwand.maximaleBreedte
      )
      this.imgUrl = matchingPreferences.find(p=>p.schuifrichting===this.schuifwandRichting).image
    },

    checkKeyWords(array){
      // betonband visibility should be set to false for the following key word:
      // "noBetonband"
      // where any value other than -1 means that the key word has been found
      if (array && array.indexOf("noBetonband") > -1) { return false; } 
      else return true;
    },

    async getComponents () {
      if (this.components.length === 0) {
        let categories = ['Schuifwand']
        let trueOnline = true

        await this.$store.dispatch('getSlidingWallComponents', {categories: categories, trueOnline: trueOnline})
        await this.$store.dispatch('getSchuifwandTypes')
      }
    },

    getComponentSelected (component) {
      if(this.selectedElement === null) {return false}
      const muur = this.getMuurInstances.find((muur) => muur.group.uuid === this.selectedElement.parent.parent.uuid)
      const muurMetadata = muur.wallButtonIndex[this.selectedElement.metaData.ModelPosition]
      return component === muurMetadata.component
    },

    processSlidingWalls() {
      this.schuifwandTypes.forEach((type) => {
        // schuifrichtingen
        // if(!this.slidingWallSchuifrichtingen.find(r=>r.name == type.schuifrichting)) {
        //   let foundComponent
        //   type.artikelnummers.forEach((artikelnummer) => {
        //     foundComponent = this.components.find((component) => component.id === artikelnummer)
        //     if(foundComponent) {
        //       this.slidingWallSchuifrichtingen.push({
        //         name: type.schuifrichting,
        //         code: foundComponent.code
        //       })
        //     }
        //   })
        // }
        if(!this.slidingWallSchuifrichtingen.includes(type.schuifrichting)) {
          this.slidingWallSchuifrichtingen.push(type.schuifrichting)
        }

        // colors
        type.artikelnummers.forEach((artikelnummer) => {
          const foundComponent = this.components.find((component) => component.id === artikelnummer)
          if(foundComponent) {
            foundComponent.colors.forEach((color) => {
              if(!this.slidingWallColors.find(c=>c.name===color.name)) {
                this.slidingWallColors.push(color)
              }
            })
          }
        })
      })

      this.slidingWallElement.colors = this.slidingWallColors
      if(this.schuifwandKleur == null) {
        // this.$store.dispatch('setSchuifwandKleur', this.slidingWallColors[0])
        // MatZwart kleur is default gezet
        const defaultColor = this.slidingWallColors.find(color => color.name === 'MatZwart')
        this.$store.dispatch('setSchuifwandKleur', defaultColor || this.slidingWallColors[0])

      }
      this.slidingWallElement.variants = this.slidingWallSchuifrichtingen
      if(this.schuifwandRichting == null) {
        this.$store.dispatch('setSchuifwandRichting', this.slidingWallSchuifrichtingen[0])
      }

      if(this.imgUrl == "") {
        this.imgUrl = this.schuifwandTypes[0].image
      }
    }
  },

  watch: {
    filteredSlidingWalls: function (newVal) {
      if(this.selectedWallData) {
        this.imgUrl = newVal[(this.schuifwandPreference >= newVal.length ? newVal.length-1 : this.schuifwandPreference)].image
      }
    },

    selectedWallData: {
      handler: function (newVal) {
        if(newVal) {
          this.$store.dispatch('setSchuifwandRichting', newVal.schuifwandRichting)
          this.$store.dispatch('setSchuifwandKleur', newVal.schuifwandKleur)
          this.$store.dispatch('setSchuifwandPreference', newVal.schuifwandPreference)
          this.imgUrl = this.filteredSlidingWalls[(this.schuifwandPreference >= this.filteredSlidingWalls.length ? this.filteredSlidingWalls.length-1 : this.schuifwandPreference)].image
        }
      },
      immediate: true
    },

    imgUrl: {
      handler: function () {
        const chosenShuifwand = this.filteredSlidingWalls[this.schuifwandPreference > this.filteredSlidingWalls.length-1 ? this.filteredSlidingWalls.length-1 : this.schuifwandPreference]
        const matchingPreferences = this.schuifwandTypes.filter(w => 
          w.glasplaatAantal === chosenShuifwand.glasplaatAantal && 
          w.glasplaatBreedte === chosenShuifwand.glasplaatBreedte && 
          w.maximaleBreedte === chosenShuifwand.maximaleBreedte
        )
        const slidingWall = matchingPreferences.find(p=>p.schuifrichting===this.schuifwandRichting)
        if (slidingWall) {
          slidingWall.artikelnummers.forEach((artikelnummer) => {
            const foundComponent = this.components.find((component) => component.id === artikelnummer)
            if(foundComponent) {
              // Kleuren volgorde omdraaien t.b.v. dat matzwart altijd bovenaan staat
              this.slidingWallColors = foundComponent.colors.slice().reverse()
              this.slidingWallElement.colors = this.slidingWallColors
            }
          })
        }
      },
      immediate: true
    }
  },

  async mounted() {
    if(this.components.length == 0 || this.schuifwandTypes.length == 0) await this.getComponents();
    
    // set slidingwalls colors and variants
    this.processSlidingWalls()

    if (this.selectedElement == null && !this.selectedWallData?.isSteellook) {
      this.$store.dispatch('changeSelectedWallComponent', {component: this.components[0], settings: {}})
    }

    // if(this.components.length == 0) {
    //   this.getComponents()
    // } else {
    //   // console.log(this.components)
    //   this.changeSelectedComponent({
    //     component: this.components[0], 
    //     colorIndex: 0, 
    //     optionIndex: 0,
    //     variantIndex: 0,
    //     glasTypeIndex: 0
    //   })
    // }
  },
};
</script>

<style lang="scss" scoped>

.steel-page {
  display: grid;
  padding: 25px 0px;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
}

.top-page-solo {
  display: flex;
  padding: 25px 0px;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
  min-width: 100%;
  
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center; //start; 
  }
}

</style>