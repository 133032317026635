<script setup>
function back() {
  window.location.href = "/cfg"
}
</script>

<template>
  <img class="left" src="https://dealerportal.toplawood.nl/img/login-topla.jpg">
  <div class="right">
    <div class="dialog">
      <p class="title">
        Bedankt voor uw aanvraag!
      </p>
      <div class="description">
        Hartelijk dank voor uw offerte aanvraag.<br>
        U ontvangt van ons een e-mail.
      </div>
      <button @click="back">Terug naar configurator</button>
    </div>
  </div>
</template>

<style lang="scss">
  .left {
    position: absolute;
    left: 0;
    object-fit: cover;
    width: 50vw;
    height: 100vh; 

    @media only screen and (max-width: 1000px) {
      width: 100vw;
    }
  }

  .right {
    position: absolute;
    right: 0;
    width: 50vw;
    height: 100vh; 
    background-color: #97aab9;

    @media only screen and (max-width: 1000px) {
      width: 100vw;
      background: none;
    }

    .dialog {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      background-color: #f7fafc;
      border-radius: 10px;

      .title {
        border-radius: 10px 10px 0px 0px;
        margin: 0;
        background-color: white;
        padding: 30px 60px;
        color: #5a5a5a;
        font-size: 1.5rem;
        font-weight: 600;
      }

      .description {
        margin-top: 30px;
        margin-bottom: 15px;
        color: #a0aecb;
      }

      button {
        margin-bottom: 30px;
        padding: 10px 20px;
        border-radius: 25px;
        color: white;
        background-color: #68d391;
        border: none;

        &:hover {
          background-color: #59b67d;
          cursor: pointer;
        }
      }
    }
  }
</style>