<template>
  <div class="edit">
    <h1>Materials</h1>

    <form class="default-form edit-form" @submit.prevent="">

      <div class="default-form-field">

        <div class="default-form-field-types row" style="flex-direction: row; text-align: left;">
          <span class="item row-header">Naam</span>
          <span class="item row-header">Breedte</span>
          <span class="item row-header">Hoogte</span>
          <span class="item row-header">kleur</span>
          <span class="item row-header">Diffuse texture</span>
          <span class="item row-header">Normal texture</span>
          <span class="item row-header">AO texture</span>
          <span class="item row-header">Metalness texture</span>
          <span class="item row-header">Roughness texture</span>
          <span class="item row-header"></span>

          <div v-for="(material, i) in materials" :key="i" class="default-form-field-types-edit-row row">
            <input type="text" class="default-form-field-input item" :id="'input' + i" v-model.trim="material.name" @keydown.enter="add(i + 1)">
            <input type="number" class="default-form-field-input item" :id="'input' + i" v-model="material.width" @keydown.enter="add(i + 1)">
            <input type="number" class="default-form-field-input item" :id="'input' + i" v-model="material.height" @keydown.enter="add(i + 1)">
            <input type="text" class="default-form-field-input item" :id="'input' + i" v-model.trim="material.color" @keydown.enter="add(i + 1)">
            <select v-model="material.diffusemap" class="default-form-field-input item">
              <option v-for="(texture, j) in textures" :key="'diff'+i+'-'+j" :value="texture.id">{{texture.name}}</option>
            </select>
            <select v-model="material.normalmap" class="default-form-field-input item">
              <option v-for="(texture, j) in texturesWithEmpty" :key="'normal'+i+'-'+j" :value="texture.id">{{texture.name}}</option>
            </select>
            <select v-model="material.aomap" class="default-form-field-input item">
              <option v-for="(texture, j) in textures" :key="'ao'+i+'-'+j" :value="texture.id">{{texture.name}}</option>
            </select>
            <select v-model="material.metalnessmap" class="default-form-field-input item">
              <option v-for="(texture, j) in textures" :key="'metal'+i+'-'+j" :value="texture.id">{{texture.name}}</option>
            </select>
            <select v-model="material.roughnessmap" class="default-form-field-input item">
              <option v-for="(texture, j) in textures" :key="'rough'+i+'-'+j" :value="texture.id">{{texture.name}}</option>
            </select>
            <button class="default-form-field-button item" @mousedown="remove(i)" >
              <mdicon name="minus" class="default-form-field-button-icon" size="25" />
            </button>
          </div>

          <button class="default-form-field-button" @click="add(materials.length)">
            <mdicon name="plus" class="default-form-field-button-icon" size="25" />
          </button>
        </div>
      </div>

      <div class="default-form-button">
        <LoadingButton @click="update()" :loading="loading" :disabled="!formValid">Update</LoadingButton>
      </div>

    </form>
  </div>
</template>

<script>
import isEqual from 'lodash.isequal'
import { mapGetters } from 'vuex'
import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'

export default {
  name: "Materials",

  components: {
    LoadingButton
  },

  data () {
    return {
      materials: [],
      formValid: false,
      loading: false,
    }
  },

  methods: {
    async get () {
      await this.$store.dispatch('getMaterials')
      this.materials = JSON.parse(JSON.stringify(this.data))
      await this.$store.dispatch('getTextures')
    },

    async update () {
      this.loading = true

      let remove = []
      let update = []
      let insert = []

      for (let i = 0; i < this.data.length; i++) {
        const keepId = this.materials.find((t) => t.id === this.data[i].id)
        if (!keepId) {
          // material is removed
          remove.push(this.data[i])
        } else {
          const materialChanged = this.materials.find((t) => t.id === this.data[i].id && JSON.stringify(t) !== JSON.stringify(this.data[i]))
          if (materialChanged) {
            // material is changed
            update.push(materialChanged)
          }
        }
      }

      for (let i = 0; i < this.materials.length; i++) {
        if (!this.materials[i].id) {
          insert.push(this.materials[i])
        }
      }

      try {
        await this.$store.dispatch('updateMaterials', {
          remove: remove,
          update: update,
          insert: insert
        })
      } catch (err) {
        console.log(err)
        this.loading = false
      }

      this.loading = false
      this.materials = JSON.parse(JSON.stringify(this.data))
    },

    add (i) {

      if (i === this.materials.length) {
        this.materials.push({
          name: "",
          id: null,
        })
      }

      this.$nextTick(() => {
        document.getElementById('input' + (i)).focus()
      })
    },

    remove (i) {
      this.materials.splice(i, 1)
    }
  },

  computed: {
    ...mapGetters({
      data: "getMaterials",
      textures: 'getTextures'
    }),

    texturesWithEmpty () {
      return [{ id: null, name: '(leeg)' }, ...this.textures]
    }
  },

  created () {
    this.get()
  },

  watch: {
    materials: {
      deep: true,
      immediate: false,
      handler () {
        if (isEqual(this.materials, this.data)) {
          this.formValid = false
        } else {
          this.formValid = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit {
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  min-width: min(100%, 800px);

  &-form {
    padding: 25px 0px;
    gap: 50px;
    width: 90%
  }
}

.row {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap if needed */
  width: 100%; /* Adjust as necessary */
  &-header {
    padding-left: 5px;
    justify-content: left !important;
  }
}

.item {
  flex: 1; /* Default flex property for other items */
  width: 0px; // dit werkt op een of andere manier
  box-sizing: border-box; /* Include padding and border in width */
  display: flex;
  align-items: center;
  justify-content: center;
  &:nth-child(2),
  &:nth-child(3) {
    flex: 0 0 70px; /* Fixed width of 70px */
  }
  &:nth-child(4) {
    flex: 0 0 100px; /* Fixed width of 100px */
  }
  &:nth-child(10) {
    flex: 0 0 30px; /* Fixed width of 30px */
  }
  //&:nth-child(n+4):nth-child(-n+8) {
  //  flex: 1; /* Allow remaining items to grow */
  //}
}

</style>