<template>
  <div>
    <CategoryLabel name="TOPLA EXCLUSIVE" />

    <div class="models-page">
      <ModelCard 
      v-for="(model, index) in models" 
      :key="index"
      ref="component"
      :data="model" 
      :selected="model.name === config.model"
      @select="updateModelType(model)"
      filter="Exclusive"
      />
    </div>

    <CategoryLabel name="TOPLA BASIC" 
    v-if="hasBasic"
    />

    <div class="models-page">
      <ModelCard 
      v-for="(model, index) in models" 
      :key="index"
      ref="component"
      :data="model" 
      :selected="model.name === config.model"
      @select="updateModelType(model)"
      filter="Basic"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ModelCard from '@/components/inputs/ModelCard.vue'
  import CategoryLabel from '@/components/form/CategoryLabel.vue'
  import { isEqual } from 'lodash';

  export default {
    name: "Models",

    components: {
      ModelCard,
      CategoryLabel
    },

    methods: {
      get () {
        if (!this.modelTypes) {
          this.$store.dispatch('getAvailableModels')
        }
      },

      updateModelType (model) {
        // this.$store.dispatch('updateModelType', model)
        console.log('updateModelType', model)
        console.log(this.config)
        console.log(JSON.parse(JSON.stringify(this.config)))

        if (model.type.name === 'Plat dak' || model.type.name === 'Aanbouwveranda' ) {
          this.$store.dispatch('toggleUitloopEnabled', true)
        }else {
          this.$store.dispatch('toggleUitloopEnabled', false)
        }
        
        const sameDepth = model.defaultSizes.find(size => size.diepte == this.config.afmetingen.diepte)
        const sameWidth = sameDepth ? sameDepth.breedtes.find(x => x.breedte == this.config.afmetingen.breedte) : null
        // check of model.type.id veranderd, als dit zo is moet ie altijd de wanden resetten
        const modelTypeIdChanged = model.type.id !== this.config.typeId
        const wallPositionChange = model.defaultSizes[0].wall.position === "buiten" || this.config.afmetingen.wall.position === "buiten"
        // use current dimensions if defaultsizes are disabled and maatwerk is enabled (veranda's)
        const keepDimensions = model.standaardmatenDisabled && model.maatwerk.enabled
        const sameDepthCapped = Math.max(Math.min(this.config.afmetingen.diepte, model.maatwerk.depth.max), model.maatwerk.depth.min)
        const sameWidthCapped = Math.max(Math.min(this.config.afmetingen.breedte, model.maatwerk.width.max), model.maatwerk.width.min)
        // new wall division for the same dimensions
        const sortedDepths = model.defaultSizes.map(size => size).sort((a, b) => b.diepte - a.diepte)
        const closestDepth = sortedDepths.find(size => size.diepte <= sameDepthCapped) || sortedDepths[sortedDepths.length-1]
        const amountOfWalls = Math.ceil((sameWidthCapped-closestDepth.staanderDikte) / (closestDepth.maximaleMuurBreedte+closestDepth.staanderDikte))
        const keepDimensionsWallDivision = []
        for(let i = 0; i < amountOfWalls; i++) {
          keepDimensionsWallDivision.push({
            bergingEnabled: false,
            width: (sameWidthCapped - ((amountOfWalls+1)*closestDepth.staanderDikte))/amountOfWalls
          })
        }

        if(sameDepth && sameWidth) {
          this.$store.dispatch('requestSizeChange', {size: {
            diepte: keepDimensions ? sameDepthCapped : sameDepth.diepte,
            hoogte: sameDepth.hoogtePaal,
            breedte: keepDimensions ? sameWidthCapped : sameWidth.breedte,
            bergingType: sameWidth.bergingType,
            artikelNummer: keepDimensions ? null : sameWidth.artikelNummer,
            wallDivision: keepDimensions ? keepDimensionsWallDivision : sameWidth.wallDivision,
            maximaleMuurBreedte: sameDepth.maximaleMuurBreedte,
            staanderDikte: sameDepth.staanderDikte, 
            poer: sameDepth.poer,
            schoor: sameDepth.schoor, 
            ringBalk: sameDepth.ringBalk,
            extraRingBalk: sameDepth.extraRingBalk,
            ringbalkTussenPaal: sameDepth.ringbalkTussenPaal,
            gordingBalk: sameDepth.gordingBalk,
            ringBalkGording: sameDepth.ringBalkGording,
            overstekKlos: sameDepth.overstekKlos,
            boeideel: sameDepth.boeideel,
            trim: sameDepth.trim,
            dak: sameDepth.dak,
            dakgoot: sameDepth.dakgoot,
            wall: sameDepth.wall,
            omlijstingPalen: sameDepth.omlijstingPalen,
            veranda: sameDepth.veranda
          }, model, mode: 'model', shouldResetWalls: !isEqual(sameWidth?.wallDivision, this.config.afmetingen.wallDivision) || modelTypeIdChanged || wallPositionChange})
        } else {
          const size = model.defaultSizes[0]
          this.$store.dispatch('requestSizeChange', {size: {
            diepte: keepDimensions ? sameDepthCapped : size.diepte,
            hoogte: size.hoogtePaal, 
            breedte: keepDimensions ? sameWidthCapped : size.breedtes[0].breedte,
            bergingType: size.breedtes[0].bergingType,
            artikelNummer: keepDimensions ? null : size.breedtes[0].artikelNummer,
            wallDivision: keepDimensions ? keepDimensionsWallDivision : size.breedtes[0].wallDivision,
            maximaleMuurBreedte: size.maximaleMuurBreedte,
            staanderDikte: size.staanderDikte, 
            poer: size.poer,
            schoor: size.schoor, 
            ringBalk: size.ringBalk,
            extraRingBalk: size.extraRingBalk,
            ringbalkTussenPaal: size.ringbalkTussenPaal,
            gordingBalk: size.gordingBalk,
            ringBalkGording: size.ringBalkGording,
            overstekKlos: size.overstekKlos,
            boeideel: size.boeideel,
            trim: size.trim,
            dak: size.dak,
            dakgoot: size.dakgoot,
            wall: size.wall,
            omlijstingPalen: size.omlijstingPalen,
            veranda: size.veranda
          }, model, mode: 'model', shouldResetWalls: true})
        }
        //this.$router.push('/cfg/sizes')
      }
    },

    computed: {
      ...mapGetters({
        models: 'getAvailableTypes',
        modelTypes: 'getModelTypes',
        config: 'getConfig',
      }),

      models () {
        if (this.modelTypes) {
          console.log(this.config.type, this.modelTypes)
          return this.modelTypes.filter((m) => m.type.name === this.config.type)
        } else {
          return []
        }
      },

      hasBasic () {
        if (this.modelTypes) {
          let basicModels = this.modelTypes.filter((m) => m.type.name === this.config.type).filter((m) => m.category.name === 'Basic')
          if (Object.keys(basicModels).length > 0) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      }
    },

    created () {
      this.get()
    }
  }
</script>

<style lang="scss" scoped>

.models-page {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
}

</style>