<template>
  <div class="sizes">

    <!-- standaarmaten / maatwerk selectie-->
    <TabSelectorIconFullWidth :tabs="[
      {
        id: '0',
        name: 'Standaardmaten',
        icon: 'vector-square'
      },
      {
        id: '1',
        name: 'Maatwerk',
        icon: 'tape-measure'
      }
    ]" :selectedBerging="selectedSizeMode" @change="changeSelectedSizeMode" v-if="config.maatwerk.enabled && !config.standaardmatenDisabled"/>

    <!--standaardmaten-->
    <div v-if="selectedSizeMode === '0' && !config.standaardmatenDisabled" class="standaardmaten">
      <p>Maak hier een keuze uit onze standaardafmetingen. Al onze standaardafmetingen zijn scherp geprijsd.<br><br>Wilt u bijvoorbeeld een hoekmodel of een specifieke afmeting die er niet tussen staat? Vermeld dit dan bij de aanvraag, wij werken dit graag verder uit met u.</p>
      
      <!-- <div class="sizes-section">
        <h3>Diepte</h3>

        <TabSelector extension=" mm" :tabs="depths" @change="changeDepth" />


      </div>

      <div class="sizes-section">
        <h3>Breedte</h3>

        <TabSelector extension=" mm" :tabs="widths" @change="changeWidth" />
      </div> -->

      <TabSelectorBergingen :tabs="bergingen" :selectedBerging="selectedBerging" @change="changeBerging" v-if="bergingen.length > 1"/>

      <div class="sizes-section" v-for="size in sizes" :key="size.diepte">
        <h3  v-if="size.breedtes.length" class="sizes-section-title">Afmetingen met een diepte van {{ size.diepte }} mm</h3>

        <div class="sizes-grid" >
          <div 
            class="sizes-grid-size" 
            :class="selectedSizes.breedte === breedte.breedte && selectedSizes.diepte === size.diepte && selectedSizes.bergingType === breedte.bergingType  ? 'sizes-grid-size-active' : ''" 
            v-for="breedte in sortBreedte(size.breedtes)" 
            :key="breedte.breedte"  
            @click="changeSize({
              diepte: size.diepte, 
              hoogte: size.hoogtePaal, 
              breedte: breedte.breedte, 
              bergingType: breedte.bergingType,
              artikelNummer: breedte.artikelNummer,
              wallDivision: breedte.wallDivision,
              maximaleMuurBreedte: size.maximaleMuurBreedte,
              staanderDikte: size.staanderDikte, 
              poer: size.poer,
              schoor: size.schoor, 
              ringBalk: size.ringBalk,
              extraRingBalk: size.extraRingBalk,
              ringbalkTussenPaal: size.ringbalkTussenPaal,
              gordingBalk: size.gordingBalk,
              ringBalkGording: size.ringBalkGording,
              overstekKlos: size.overstekKlos,
              boeideel: size.boeideel,
              trim: size.trim,
              dak: size.dak,
              dakgoot: size.dakgoot,
              wall: size.wall,
              omlijstingPalen: size.omlijstingPalen,
              veranda: size.veranda
            })"
          >
            <p>Breedte: {{ breedte.breedte }}</p>
            <p>Diepte: {{ size.diepte }}</p>
          </div>
        </div>
      </div>

    </div>

    <!--maatwerk-->
    <div class="expand-header" v-if="selectedSizeMode === '1' || config.standaardmatenDisabled">
      <h3>Frame</h3>
    </div>

    <div v-if="selectedSizeMode === '1' || config.standaardmatenDisabled">
      <p>Voer uw gewenste afmetingen in.</p>
      <h3 class="custom-size-title">Diepte:</h3>
      <!--  -->
      <div class="custom-size-input-container">
        <input 
          type="number"
          v-model.number="depth"
          class="custom-size-input"
          @change="handleCustomInputChange"
          @keydown="validateInputKeys"
        >
        <div class="custom-size-input-after">
          <p class="custom-size-title-range">{{ config.maatwerk.depth.min }}mm - {{ config.maatwerk.depth.max }}mm</p>
          <div class="custom-size-input-unit">mm</div>
        </div>
      </div>
      <h3 class="custom-size-title">Breedte:</h3>
      <div class="custom-size-input-container">
        <input 
          type="number"
          v-model.number="width"
          class="custom-size-input"
          @change="handleCustomInputChange"
          @keydown="validateInputKeys"
        >
        <div class="custom-size-input-after">
          <p class="custom-size-title-range">{{ config.maatwerk.width.min }}mm - {{ config.maatwerk.width.max }}mm</p>
          <div class="custom-size-input-unit">mm</div>
        </div>
      </div>

      <!-- overstekklos toggles -->
      <div
        v-if="selectedSizes.overstekKlos.enabled && (selectedSizes.overstekKlos.klosVoEditable || selectedSizes.overstekKlos.klosAcEditable || selectedSizes.overstekKlos.klosLiEditable || selectedSizes.overstekKlos.klosReEditable)"
        class="expand-header expand-header-margin"
      >
        <h3>Overstekken</h3>
      </div>

      <div v-if="selectedSizes.overstekKlos.enabled && (selectedSizes.overstekKlos.klosVoEditable || selectedSizes.overstekKlos.klosAcEditable || selectedSizes.overstekKlos.klosLiEditable || selectedSizes.overstekKlos.klosReEditable)" class="overstekklos-container">
        <p>Zet hier uw overstek aan of uit.</p>

        <!-- overstekklos voor -->
        <div v-if="selectedSizes.overstekKlos.klosVoEditable && selectedSizes.overstekKlos.klosVoEnabled" class="overstekklos-container-block">
          <input class="checkbox" type="checkbox" id="voEnabled" name="boeideel-checkbox" v-model="overstekConfig.voEnabled">
          <label for="voEnabled" class="checkbox-label sizes-form-checkbox overstekklos-container-checkbox">
            <mdicon
              name="check-bold"
              class="checkbox-label-icon"
              :class="overstekConfig.voEnabled ? 'checkbox-label-icon-active' : ''"
              size="20"
            />
          </label>
          <h3 class="custom-size-title overstekklos-container-label">Overstek voor</h3>
        </div>

        <!-- overstekklos achter -->
        <div v-if="selectedSizes.overstekKlos.klosAcEditable && selectedSizes.overstekKlos.klosAcEnabled" class="overstekklos-container-block">
          <input class="checkbox" type="checkbox" id="acEnabled" name="boeideel-checkbox" v-model="overstekConfig.acEnabled">
          <label for="acEnabled" class="checkbox-label sizes-form-checkbox overstekklos-container-checkbox">
            <mdicon
                name="check-bold"
                class="checkbox-label-icon"
                :class="overstekConfig.acEnabled ? 'checkbox-label-icon-active' : ''"
                size="20"
            />
          </label>
          <h3 class="custom-size-title overstekklos-container-label">Overstek achter</h3>
        </div>

        <!-- overstekklos links -->
        <div v-if="selectedSizes.overstekKlos.klosLiEditable && selectedSizes.overstekKlos.klosLiEnabled" class="overstekklos-container-block">
          <input class="checkbox" type="checkbox" id="liEnabled" name="boeideel-checkbox" v-model="overstekConfig.liEnabled">
          <label for="liEnabled" class="checkbox-label sizes-form-checkbox overstekklos-container-checkbox">
            <mdicon
                name="check-bold"
                class="checkbox-label-icon"
                :class="overstekConfig.liEnabled ? 'checkbox-label-icon-active' : ''"
                size="20"
            />
          </label>
          <h3 class="custom-size-title overstekklos-container-label">Overstek links</h3>
        </div>

        <!-- overstekklos rechts -->
        <div v-if="selectedSizes.overstekKlos.klosReEditable && selectedSizes.overstekKlos.klosReEnabled" class="overstekklos-container-block">
          <input class="checkbox" type="checkbox" id="reEnabled" name="boeideel-checkbox" v-model="overstekConfig.reEnabled">
          <label for="reEnabled" class="checkbox-label sizes-form-checkbox overstekklos-container-checkbox">
            <mdicon
                name="check-bold"
                class="checkbox-label-icon"
                :class="overstekConfig.reEnabled ? 'checkbox-label-icon-active' : ''"
                size="20"
            />
          </label>
          <h3 class="custom-size-title overstekklos-container-label">Overstek rechts</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import TabSelector from '@/components/inputs/TabSelector';
  import TabSelectorIconFullWidth from '@/components/inputs/TabSelectorIconsFullWidth.vue'
  import TabSelectorBergingen from '@/components/inputs/TabSelectorBergingen.vue'
  import { mapGetters } from 'vuex'

  export default {
    name: "sizes",

    components: {
      TabSelectorBergingen,
      TabSelectorIconFullWidth
      // TabSelector
    },

    data () {
      return {
        sizes: [],
        bergingen: [],
        selectedBerging: '',
        selectedSizeMode: '0',
        width: 7000,
        depth: 3500,
        overstekConfig: {
          // voEnabled: true,
          // acEnabled: true,
          // liEnabled: true,
          // reEnabled: true,
        }
      }
    },

    async created() {
      await this.get()
      this.$store.dispatch('enableMapMode', true)

      /*this.getBergingen.forEach(berging => {
        if() {
          this.bergingen.push(berging)
        }
      })*/
      this.getSizes.forEach(size => {
        size.breedtes.forEach(breedte => {
          const bergingType = this.getBergingen.find(berging => berging.id ===breedte.bergingType)
          if(!this.bergingen.includes(bergingType)) {
            this.bergingen.push(bergingType)
          }
        })
      })
      console.log(this.bergingen)

      this.selectedBerging = this.config.afmetingen.bergingType
      this.sizes = JSON.parse(JSON.stringify(this.getSizes))
      this.sizes.forEach(size => {
        for(let i = size.breedtes.length-1; i > -1; i--) {
          if (size.breedtes[i].bergingType !== this.selectedBerging) {
            size.breedtes.splice(i, 1)
          }
        }
      })

      console.log("this.config", this.config.afmetingen)

      // check if dimension is standaard
      let foundWidth = null
      for(let i = 0; i < this.sizes.length; i++) {
        if(this.sizes[i].diepte === this.config.afmetingen.diepte) {
          for(let j = 0; j < this.sizes[i].breedtes.length; j++) {
            if(this.sizes[i].breedtes[j].breedte === this.config.afmetingen.breedte) {
              foundWidth = this.sizes[i].breedtes[j]
              break
            }
          }
        }
        if(foundWidth) {
          break
        }
      }
      if (!foundWidth) {
        this.selectedSizeMode = '1'
      }
    },

    methods: {
      changeSize(size) {
        console.log(size)
        console.log(this.models, this.selectedSizes, this.modelTypes, this.config)
        this.$store.dispatch('requestSizeChange', {size, mode: 'size', shouldResetWalls: true})
      },

      async get () {
        if (!this.models) {
          await this.$store.dispatch('getAvailableModels')
        }
      },

      changeBerging (berging) {
        this.selectedBerging = berging
        this.sizes = JSON.parse(JSON.stringify(this.getSizes))
        console.log(this.selectedBerging, this.getSizes)
        this.sizes.forEach(size => {
          for(let i = size.breedtes.length-1; i > -1; i--) {
            if (size.breedtes[i].bergingType !== this.selectedBerging) {
              size.breedtes.splice(i, 1)
            }
          }
        })
      },

      sortBreedte (array) {
        let newArray = [...array]
        newArray.sort((a, b) => {
          return a.breedte - b.breedte;
        })
        return newArray
      },

      changeSelectedSizeMode (mode) {
        this.selectedSizeMode = mode
      },

      handleCustomInputChange () {
        // validate width
        if(this.width < this.config.maatwerk.width.min) {
          this.width = this.config.maatwerk.width.min
        } else if(this.width > this.config.maatwerk.width.max) {
          this.width = this.config.maatwerk.width.max
        }

        // validate depth
        if(this.depth < this.config.maatwerk.depth.min) {
          this.depth = this.config.maatwerk.depth.min
        } else if(this.depth > this.config.maatwerk.depth.max) {
          this.depth = this.config.maatwerk.depth.max
        }

        console.log(this.width, this.depth)
        // depth 
        const sortedDepths = this.sizes.map(size => size).sort((a, b) => b.diepte - a.diepte)
        const closestDepth = sortedDepths.find(size => size.diepte <= this.depth) || sortedDepths[sortedDepths.length-1]

        const sortedWidths = closestDepth.breedtes.map(breedte => breedte).sort((a, b) => b.breedte - a.breedte)
        const closestWidth = sortedWidths.find(breedte => breedte.breedte <= this.width) || sortedWidths[sortedWidths.length-1]

        const amountOfWalls = Math.ceil((this.width-closestDepth.staanderDikte) / (closestDepth.maximaleMuurBreedte+closestDepth.staanderDikte))
        const wallDivision = []
        for(let i = 0; i < amountOfWalls; i++) {
          wallDivision.push({
            bergingEnabled: false,
            width: (this.width - ((amountOfWalls+1)*closestDepth.staanderDikte))/amountOfWalls
          })
        }
        console.log("walldivision", closestWidth.wallDivision, closestDepth.staanderDikte, wallDivision, amountOfWalls, closestDepth.maximaleMuurBreedte)

        this.changeSize({
          diepte: this.depth, 
          hoogte: closestDepth.hoogtePaal, 
          breedte: this.width, 
          bergingType: closestWidth.bergingType,
          artikelNummer: null,
          wallDivision,
          maximaleMuurBreedte: closestDepth.maximaleMuurBreedte,
          staanderDikte: closestDepth.staanderDikte, 
          poer: closestDepth.poer,
          schoor: closestDepth.schoor, 
          ringBalk: closestDepth.ringBalk,
          extraRingBalk: closestDepth.extraRingBalk,
          ringbalkTussenPaal: closestDepth.ringbalkTussenPaal,
          gordingBalk: closestDepth.gordingBalk,
          ringBalkGording: closestDepth.ringBalkGording,
          overstekKlos: closestDepth.overstekKlos,
          boeideel: closestDepth.boeideel,
          trim: closestDepth.trim,
          dak: closestDepth.dak,
          dakgoot: closestDepth.dakgoot,
          wall: closestDepth.wall,
          omlijstingPalen: closestDepth.omlijstingPalen,
          veranda: closestDepth.veranda
        })
      },

      validateInputKeys (e) {
        const allowedKeys = [
          'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown',
          'Tab', 'Enter', 'Home', 'End'
        ];

        // Allow Ctrl/Command + A (Select All), C (Copy), V (Paste), X (Cut)
        const ctrlCmdKeys = ['a', 'c', 'v', 'x'];
        
        if (allowedKeys.includes(e.key) ||
            (e.ctrlKey || e.metaKey) && ctrlCmdKeys.includes(e.key.toLowerCase())) {
          return; // Allow the key event
        }
        
        // Prevent non-numeric keys
        if (!/^\d$/.test(e.key)) {
          e.preventDefault();
        }
      }
    },

    computed: {
      ...mapGetters({
        models: 'getAvailableTypes',
        modelTypes: 'getModelTypes',
        config: 'getConfig',
        selectedSizes: 'getSelectedSizes',
        getSizes: 'getSizes',
        getBergingen: 'getBergingTypes',
        getSelectedSizeMode: 'getSelectedSizeMode',
        getMaatwerkWidth: 'getMaatwerkWidth',
        getMaatwerkDepth: 'getMaatwerkDepth',
        getOverstekConfig: 'getOverstekConfig'
      }),
      maatwerkEnabled () {
        return this.config.maatwerk.enabled
      },
    },

    watch: {
      maatwerkEnabled (value) {
        if(!value) {
          this.selectedSizeMode = '0'
        }
      },
      getMaatwerkWidth (value) {
        this.width = value
      },
      getMaatwerkDepth (value) {
        this.depth = value
      },

      overstekConfig: {
        handler (value) {
          if (JSON.stringify(value) !== JSON.stringify(this.getOverstekConfig)) {
            this.$store.dispatch('overstekConfigChanged', JSON.parse(JSON.stringify(value)))
          }
        },
        deep: true
      }
    },

    mounted() {
      this.selectedSizeMode = this.getSelectedSizeMode
      this.width = this.getMaatwerkWidth
      this.depth = this.getMaatwerkDepth
      this.overstekConfig = JSON.parse(JSON.stringify(this.getOverstekConfig))
    },

    beforeDestroy() {
      this.$store.dispatch('setSelectedSizeMode', this.selectedSizeMode)
      this.$store.dispatch('setMaatwerkWidth', this.width)
      this.$store.dispatch('setMaatwerkDepth', this.depth)
    }
  }
</script>

<style lang="scss" scoped>

.sizes {
  display: flex;
  flex-direction: column;
  // gap: 25px;

  & p {
    text-align: left;
    margin-top: 4px;
  }

  &-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    
    // & h3 {
    //   text-align: left;
    //   font-size: 1.5rem;
    //   margin: 0px;
    // }

    &-title {
      font-size: 1.2rem;
      text-align: left;
    }
  }
}

.sizes-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  &-size {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border: 2px solid $c-border-1;
    border-radius: $radius-m;
    padding: 15px 20px;
    gap: 10px;
    min-width: 115px; //125px;
    align-items: left;
    transition: background-color 100ms ease, color 100ms ease, border 100ms ease;
    background-color: transparent;
    
    & p {
      margin: 0px;
      font-weight: 600;
    }

    &:hover {
      background-color: $c-background-1;
    }
    
    &-active {
      border: 2px solid $c-primary;
      background-color: $c-primary;
      color: $c-secondary-bright;//$c-secondary;

      &:hover {
        background-color: $c-primary;
      }
    }
  }
}

.custom-size-input {
  width: 96%;
  border: none;
  border-radius: $radius-m;
  font-size: 1rem;
  margin-top: 4px;
  margin-bottom: 14px;
  border-radius: $radius-s;
  font-weight: 500;
  outline: 2px solid $c-border-1;
  transition: outline 100ms ease;
  line-height: 28px;
  padding: 6px 12px;
}

.custom-size-input:hover {
  outline: 2px solid $c-secondary;
}

.custom-size-input-container {
  position: relative;
}

.custom-size-input-after {
  position: absolute;
  right: -2px;
  top: 4px;
}

.custom-size-input-unit {
  display: inline-block;
  border-radius: 0px $radius-s $radius-s 0px;
  background-color: $c-background-1;
  line-height: 28px;
  padding: 6px 12px;
}

.custom-size-input:focus {
  border: none;
  outline: 3px solid $c-secondary;
}

.custom-size-title {
  font-size: 1.2rem;
  text-align: left;
}

.custom-size-title-range {
  color: #53565a;
  margin: 0px;
  padding: 0px;
  margin-top: 5px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: bottom;
  font-size: 15px
}

.standaardmaten {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.overstekklos-container {
  margin-top: 16px;
  &-block {
    text-align: left;
    margin-bottom: 6px;
  }
  &-label {
    display: inline-block;
    margin-left: 10px;
  }
  &-checkbox {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
}

.expand-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 2px solid $c-border-1;

  & h3 {
    margin: 0px;
    font-size: 1.5rem;
  }

  &-margin {
    margin-top: 20px;
  }
}
</style>

<style lang="scss">
.overstekklos-container-checkbox > span > svg {
  margin-top: -6px;
  margin-left: -1px;
}
</style>