import * as boxBuffer from '@/three/buffer-geometries/boxBuffer.js'
import * as schuineGording from '@/three/buffer-geometries/schuineGording.js'
import * as plane from '@/three/buffer-geometries/plane.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import store from '../../../store';
import { getLichtstraatInfo } from '@/three/functions/functions.js'
import { SUBTRACTION, ADDITION, Brush, Evaluator } from 'three-bvh-csg';

export default class Dakbeschot {
  constructor (THREE, textureLoader, x, y, z, by, overstekKlos, offset, texture, dakAfwerking, kp, modelType, graden, overhang, dpo, dpoa, ox, oy, kapschuurConfig, lichtstraten, staanderDikte) {
    this.THREE = THREE
    this.textureLoader = textureLoader
    this.texture = texture
    this.dakAfwerking = dakAfwerking
    this.x = x
    this.y = y
    this.z = z
    this.by = by
    this.overstekKlos = overstekKlos
    this.offset = offset
    this.kp = kp
    this.modelType = modelType
    this.graden = graden
    this.overhang = overhang
    this.dpo = dpo
    this.dpoa = dpoa
    this.ox = ox
    this.oy = oy
    this.kapschuurConfig = kapschuurConfig
    this.lichtstraten = lichtstraten
    this.staanderDikte = staanderDikte
    this.dakpanMeshSizeVoor = 0
    this.dakpanMeshSizeAchter = 0
    this.evaluator = new Evaluator()
    this.gatMaterial = new THREE.MeshStandardMaterial()
    this.group = new THREE.Group()

    this.nokvorstVisible = true

    this.bouw(THREE, textureLoader, x, y, z, by, overstekKlos, offset, texture, kp, graden, overhang, dpo, dpoa, ox, oy)
  }

  bouw (THREE, textureLoader, x, y, z, by, overstekKlos, offset, texture, kp, graden, overhang, dpo, dpoa, ox, oy) {

    const breedte = this.modelType.isKapschuur ? this.kapschuurConfig.topX*2 : z

    let klosVoEnabled = this.klosVoEnabled = overstekKlos.enabled? overstekKlos.klosVoEnabled : false
    let xBovVo = this.xBovVo = klosVoEnabled ? overstekKlos.xBovVo : 0
    let klosAcEnabled = this.klosAcEnabled = overstekKlos.enabled? overstekKlos.klosAcEnabled : false
    let xBovAc = this.xBovAc = klosAcEnabled ? overstekKlos.xBovAc : 0
    let klosLiEnabled = this.klosLiEnabled = overstekKlos.enabled? overstekKlos.klosLiEnabled : false
    let xBovLi = this.xBovLi = klosLiEnabled ? overstekKlos.xBovLi : 0
    let klosReEnabled = this.klosReEnabled = overstekKlos.enabled? overstekKlos.klosReEnabled : false
    let xBovRe = this.xBovRe = klosReEnabled ? overstekKlos.xBovRe : 0

    //platdak
    const texture0 = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(texture0, (breedte + xBovVo + xBovAc + (offset*2)) * 0.00075, (x + xBovLi + xBovRe + (offset*2)) * 0.00075)
    const material = new THREE.MeshStandardMaterial({ map: texture0, metalness: 0, roughness: 1 })

    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(breedte + xBovVo + xBovAc + (offset*2), by, x + xBovLi + xBovRe + (offset*2))), 3))
    geometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
    geometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
    geometry.setIndex(boxBuffer.indexBuffer());
    geometry.groups = boxBuffer.groupBuffer()

    let dakbeschot = new Brush( geometry, material );
    dakbeschot.position.x = breedte / 2 + -xBovVo/2 + xBovAc/2
    dakbeschot.position.z = x / 2 + -xBovLi/2 + xBovRe/2

    // make holes in dakbeschot for lichtstraten
    if (this.lichtstraten.length > 0) {
      let lichtstraatInfo = getLichtstraatInfo(this.lichtstraten[0].code)
      const safeMargin = 10
      const lichtstraatGatGeometry = new THREE.BoxGeometry(
        lichtstraatInfo.dimensions.z-lichtstraatInfo.margins.zNeg2-lichtstraatInfo.margins.zPos2+safeMargin,
        1000,
        lichtstraatInfo.dimensions.x-lichtstraatInfo.margins.xNeg2-lichtstraatInfo.margins.xPos2+safeMargin
      )
      let lichtstraatGat = new Brush(lichtstraatGatGeometry, this.gatMaterial)
      lichtstraatGat.position.x = this.lichtstraten[0].positionFront + lichtstraatInfo.dimensions.z/2 +this.staanderDikte -lichtstraatInfo.margins.zNeg2 -lichtstraatInfo.margins.zNeg1/2+lichtstraatInfo.margins.zPos1/2
      lichtstraatGat.position.z = this.lichtstraten[0].positionLeft + lichtstraatInfo.dimensions.x/2 +this.staanderDikte -lichtstraatInfo.margins.xNeg2
      for (let i = 1; i < this.lichtstraten.length; i++) {
        let lichtstraatInfo = getLichtstraatInfo(this.lichtstraten[i].code)
        const newLichtstraatGatGeometry = new THREE.BoxGeometry(
          lichtstraatInfo.dimensions.z-lichtstraatInfo.margins.zNeg2-lichtstraatInfo.margins.zPos2+safeMargin,
          1000,
          lichtstraatInfo.dimensions.x-lichtstraatInfo.margins.xNeg2-lichtstraatInfo.margins.xPos2+safeMargin
        )
        const newLichtstraatGat = new Brush(newLichtstraatGatGeometry, this.gatMaterial)
        newLichtstraatGat.position.x = this.lichtstraten[i].positionFront + lichtstraatInfo.dimensions.z/2 +this.staanderDikte -lichtstraatInfo.margins.zNeg2 -lichtstraatInfo.margins.zNeg1/2+lichtstraatInfo.margins.zPos1/2
        newLichtstraatGat.position.z = this.lichtstraten[i].positionLeft + lichtstraatInfo.dimensions.x/2 +this.staanderDikte -lichtstraatInfo.margins.xNeg2
        lichtstraatGat.updateMatrixWorld()
        newLichtstraatGat.updateMatrixWorld()
        lichtstraatGat = this.evaluator.evaluate(lichtstraatGat, newLichtstraatGat, ADDITION)
      }
      dakbeschot.updateMatrixWorld()
      lichtstraatGat.updateMatrixWorld()
      dakbeschot = this.evaluator.evaluate(dakbeschot, lichtstraatGat, SUBTRACTION)
    }

    dakbeschot.castShadow = true;
    dakbeschot.receiveShadow = true;
    dakbeschot.frustumCulled = false


    //zadeldak
    const topPosition = ((breedte/2+ox)*Math.tan(graden * (Math.PI/180))) + oy
    const topPositionBeschot = topPosition + (by/Math.cos(graden * (Math.PI/180))) - by/2

    const radians = graden * (Math.PI/180);
    const hoogte = Math.cos(radians)*by
    const buitenOffset = Math.sqrt((by**2) - (hoogte**2))

    const texture1Voor = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`)
    this.textureSettings(texture1Voor, 1, 1, Math.PI)
    const material1Voor = new THREE.MeshStandardMaterial({ map: texture1Voor, metalness: 0, roughness: 1 })

    const texture1Achter = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`)
    this.textureSettings(texture1Achter, 1, 1, Math.PI)
    const material1Achter = new THREE.MeshStandardMaterial({ map: texture1Achter, metalness: 0, roughness: 1 })
    
    let dakbeschotVoorGeometry = new THREE.BufferGeometry()
    dakbeschotVoorGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(breedte/2+buitenOffset+ox, by, x+(overhang*2), graden)), 3))
    dakbeschotVoorGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(breedte/2+buitenOffset+ox, by, x+(overhang*2), graden, 1024, 1024)), 2))
    dakbeschotVoorGeometry.setIndex(boxBuffer.indexBuffer());
    dakbeschotVoorGeometry = dakbeschotVoorGeometry.toNonIndexed()
    dakbeschotVoorGeometry.computeVertexNormals()

    let dakbeschotAchterGeometry = new THREE.BufferGeometry()
    dakbeschotAchterGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer((this.modelType.isKapschuur ? z-this.kapschuurConfig.topX : breedte/2)+buitenOffset+ox, by, x+(overhang*2), graden)), 3))
    dakbeschotAchterGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer((this.modelType.isKapschuur ? z-this.kapschuurConfig.topX : breedte/2)+buitenOffset+ox, by, x+(overhang*2), graden, 1024, 1024)), 2))
    dakbeschotAchterGeometry.setIndex(boxBuffer.indexBuffer());
    dakbeschotAchterGeometry = dakbeschotAchterGeometry.toNonIndexed()
    dakbeschotAchterGeometry.computeVertexNormals()

    const dakbeschot1 = new THREE.Mesh( dakbeschotVoorGeometry, material1Voor );
    dakbeschot1.castShadow = true; 
    dakbeschot1.receiveShadow = true;
    dakbeschot1.frustumCulled = false
    dakbeschot1.position.x = breedte/2
    dakbeschot1.position.y = topPositionBeschot
    dakbeschot1.position.z = x / 2

    const dakbeschot2 = new THREE.Mesh( dakbeschotAchterGeometry, material1Achter );
    dakbeschot2.castShadow = true; 
    dakbeschot2.receiveShadow = true;
    dakbeschot2.frustumCulled = false
    dakbeschot2.position.x = breedte/2
    dakbeschot2.position.y = topPositionBeschot
    dakbeschot2.position.z = x / 2
    dakbeschot2.rotation.y = Math.PI
  
    const dakpanMeshPositionY = topPosition - by/2 + (by/Math.cos(radians)) + 5 
    const dakpanGeometryVoor = new THREE.BufferGeometry()
    const dakpanGeometryAchter = new THREE.BufferGeometry()

    const dakPlaatBreedteVoor = x+(overhang*2)
    const dakplaatLengteVoor = (breedte/2+buitenOffset+ox)/Math.cos(radians) + (Math.sin(radians)*5) + dpo

    const dakPlaatBreedteAchter = x+(overhang*2)
    const dakplaatLengteAchter = ((this.modelType.isKapschuur ? z-this.kapschuurConfig.topX : breedte/2)+buitenOffset+ox)/Math.cos(radians) + (Math.sin(radians)*5) + dpoa

    this.dakpanMeshSizeVoor = dakplaatLengteVoor
    this.dakpanMeshSizeAchter = dakplaatLengteAchter

    const texture2 = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.dakAfwerking.previewURL}`)
    this.textureSettings(texture2, x / 1000 / this.dakAfwerking.scalingX, this.dakpanMeshSizeVoor / 1000 / this.dakAfwerking.scalingY)
    const dakpanMaterialVoor = new this.THREE.MeshStandardMaterial({ map: texture2, metalness: 0, roughness: 1, side: THREE.DoubleSide })
    console.log(this.dakAfwerking)
    if(this.dakAfwerking.emissiveColor != null || this.dakAfwerking.emissiveColor != undefined) {
      dakpanMaterialVoor.emissive = new this.THREE.Color(this.dakAfwerking.emissiveColor)
    }
    if(this.dakAfwerking.emissive != null || this.dakAfwerking.emissive != undefined) {
      dakpanMaterialVoor.emissiveIntensity = this.dakAfwerking.emissive
    }
    if(this.dakAfwerking.metalness != null || this.dakAfwerking.metalness != undefined) {
      dakpanMaterialVoor.metalness = this.dakAfwerking.metalness
    }

    dakpanGeometryVoor.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(plane.positionBuffer(dakPlaatBreedteVoor, dakplaatLengteVoor)), 3))
    dakpanGeometryVoor.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(plane.uvBufferVoor(dakPlaatBreedteVoor, dakplaatLengteVoor)), 2))
    dakpanGeometryVoor.computeVertexNormals()
    const dakpanMeshVoor = new THREE.Mesh(dakpanGeometryVoor, dakpanMaterialVoor)
    dakpanMeshVoor.castShadow = true; 
    dakpanMeshVoor.receiveShadow = true;
    dakpanMeshVoor.frustumCulled = false
    dakpanMeshVoor.position.x = breedte/2
    dakpanMeshVoor.position.y = dakpanMeshPositionY
    dakpanMeshVoor.position.z = x+overhang
    dakpanMeshVoor.rotation.z = -radians
    dakpanMeshVoor.rotation.y = Math.PI

    const texture2Achter = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.dakAfwerking.previewURL}`)
    this.textureSettings(texture2Achter, x / 1000 / this.dakAfwerking.scalingX, this.dakpanMeshSizeAchter / 1000 / this.dakAfwerking.scalingY)
    const dakpanMaterialAchter = new this.THREE.MeshStandardMaterial({ map: texture2Achter, metalness: 0, roughness: 1, side: THREE.DoubleSide })
    if(this.dakAfwerking.emissiveColor != null || this.dakAfwerking.emissiveColor != undefined) {
      dakpanMaterialAchter.emissive = new this.THREE.Color(this.dakAfwerking.emissiveColor)
    }
    if(this.dakAfwerking.emissive != null || this.dakAfwerking.emissive != undefined) {
      dakpanMaterialAchter.emissiveIntensity = this.dakAfwerking.emissive
    }
    if(this.dakAfwerking.metalness != null || this.dakAfwerking.metalness != undefined) {
      dakpanMaterialAchter.metalness = this.dakAfwerking.metalness
    }

    dakpanGeometryAchter.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(plane.positionBuffer(dakPlaatBreedteAchter, dakplaatLengteAchter)), 3))
    dakpanGeometryAchter.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(plane.uvBufferAchter(dakPlaatBreedteAchter, dakplaatLengteAchter)), 2))
    dakpanGeometryAchter.computeVertexNormals()
    const dakpanMeshAchter = new THREE.Mesh(dakpanGeometryAchter, dakpanMaterialAchter)
    //dakpanMeshAchter.scale.z=-1
    dakpanMeshAchter.castShadow = true; 
    dakpanMeshAchter.receiveShadow = true;
    dakpanMeshAchter.frustumCulled = false
    dakpanMeshAchter.position.x = breedte/2
    dakpanMeshAchter.position.y = dakpanMeshPositionY
    dakpanMeshAchter.position.z = -overhang
    dakpanMeshAchter.rotation.z = -radians

    const gltfLoader = new GLTFLoader
    gltfLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}/models/nokvorst.gltf`, (model) => {
      console.log(model)
      const nokvorst = model.scene.children[0]
      nokvorst.scale.multiplyScalar(1000)
      nokvorst.castShadow = true; 
      nokvorst.receiveShadow = true;
      nokvorst.frustumCulled = false
      const nokvorstMaterial = new this.THREE.MeshStandardMaterial({ map: texture2.clone(), metalness: 0, roughness: 1 })
      if(this.dakAfwerking.emissiveColor != null || this.dakAfwerking.emissiveColor != undefined) {
        nokvorstMaterial.emissive = new this.THREE.Color(this.dakAfwerking.emissiveColor)
      }
      if(this.dakAfwerking.emissive != null || this.dakAfwerking.emissive != undefined) {
        nokvorstMaterial.emissiveIntensity = this.dakAfwerking.emissive
      }
      if(this.dakAfwerking.metalness != null || this.dakAfwerking.metalness != undefined) {
        nokvorstMaterial.metalness = this.dakAfwerking.metalness
      }
      nokvorstMaterial.map.rotation = 1*Math.PI
      nokvorstMaterial.map.repeat.x = this.x / 1000 / this.dakAfwerking.scalingX
      nokvorstMaterial.map.repeat.y = 0.01
      nokvorstMaterial.map.needsUpdate = true
      nokvorst.material = nokvorstMaterial
      nokvorst.scale.y = (x+(overhang*2))
      nokvorst.position.x = breedte/2
      nokvorst.position.y = dakpanMeshPositionY - 100 + 10
      nokvorst.position.z = x/2
      nokvorst.name = "nokvorst"
      nokvorst.visible = this.nokvorstVisible //dakbeschot1.visible
      this.group.add(nokvorst)
    })

    dakbeschot.name = "dakbeschot"
    dakbeschot.visible = this.modelType.isPlatdak
    dakbeschot1.name = "dakbeschot1"
    dakbeschot1.visible = this.modelType.isZadeldak || this.modelType.isKapschuur
    dakbeschot2.name = "dakbeschot2"
    dakbeschot2.visible = this.modelType.isZadeldak || this.modelType.isKapschuur
    dakpanMeshVoor.name = "dakpanMesh"
    dakpanMeshVoor.visible = this.modelType.isZadeldak || this.modelType.isKapschuur && this.nokvorstVisible
    dakpanMeshAchter.name = "dakpanMesh2"
    dakpanMeshAchter.visible = this.modelType.isZadeldak || this.modelType.isKapschuur && this.nokvorstVisible

    this.group.add(dakbeschot, dakbeschot1, dakbeschot2, dakpanMeshVoor, dakpanMeshAchter)

    store.dispatch('updateDakPlaatSize', {dakPlaatBreedteVoor, dakplaatLengteVoor, dakPlaatBreedteAchter, dakplaatLengteAchter})
  }

  update (x, y, z, by, overstekKlos, offset, kp, modelType, graden, overhang, dpo, dpoa, ox, oy, kapschuurConfig, lichtstraten, staanderDikte) {
    this.x = x
    this.y = y
    this.z = z
    this.by = by
    this.overstekKlos = overstekKlos
    let klosVoEnabled = this.klosVoEnabled = overstekKlos.enabled? overstekKlos.klosVoEnabled : false
    let xBovVo = this.xBovVo = klosVoEnabled ? overstekKlos.xBovVo : 0
    let klosAcEnabled = this.klosAcEnabled = overstekKlos.enabled? overstekKlos.klosAcEnabled : false
    let xBovAc = this.xBovAc = klosAcEnabled ? overstekKlos.xBovAc : 0
    let klosLiEnabled = this.klosLiEnabled = overstekKlos.enabled? overstekKlos.klosLiEnabled : false
    let xBovLi = this.xBovLi = klosLiEnabled ? overstekKlos.xBovLi : 0
    let klosReEnabled = this.klosReEnabled = overstekKlos.enabled? overstekKlos.klosReEnabled : false
    let xBovRe = this.xBovRe = klosReEnabled ? overstekKlos.xBovRe : 0
    this.offset = offset
    this.kp = kp
    this.modelType = modelType
    this.graden = graden
    this.overhang = overhang
    this.dpo = dpo
    this.dpoa = dpoa
    this.ox = ox
    this.oy = oy
    this.kapschuurConfig = kapschuurConfig
    this.lichtstraten = lichtstraten
    this.staanderDikte = staanderDikte

    while(this.group.children.length > 0){
      this.group.remove(this.group.children[0]);
    }
    this.bouw(this.THREE, this.textureLoader, x, y, z, by, overstekKlos, offset, this.texture, kp, graden, overhang, dpo, dpoa, ox, oy)
    return

    const breedte = this.modelType.isKapschuur ? this.kapschuurConfig.topX*2 : z

    const dakbeschot = this.group.getObjectByName("dakbeschot")
    const dakbeschot1 = this.group.getObjectByName("dakbeschot1")
    const dakbeschot2 = this.group.getObjectByName("dakbeschot2")
    const dakpanMesh = this.group.getObjectByName("dakpanMesh")
    const dakpanMesh2 = this.group.getObjectByName("dakpanMesh2")
    const nokvorst = this.group.getObjectByName("nokvorst")

    dakbeschot.visible = this.modelType.isPlatdak
    dakbeschot1.visible = this.modelType.isZadeldak || this.modelType.isKapschuur
    dakbeschot2.visible = this.modelType.isZadeldak || this.modelType.isKapschuur
    dakpanMesh.visible = this.modelType.isZadeldak || this.modelType.isKapschuur
    dakpanMesh2.visible = this.modelType.isZadeldak || this.modelType.isKapschuur
    nokvorst.visible = this.nokvorstVisible //this.modelType.isZadeldak || this.modelType.isKapschuur

    if(this.modelType.isPlatdak) {
      dakbeschot.geometry.attributes.position.array = new Float32Array(boxBuffer.positionBuffer(breedte + (xBovVo + xBovAc) + (offset*2), by, x + (xBovLi + xBovRe) + (offset*2)))
      dakbeschot.geometry.attributes.position.needsUpdate = true

      this.textureSettings(dakbeschot.material.map, (breedte + (xBovVo + xBovAc) + (offset*2)) * 0.00075, (x + (xBovLi + xBovRe) + (offset*2)) * 0.00075)
      dakbeschot.material.map.needsUpdate = true
      dakbeschot.material.needsUpdate = true

      dakbeschot.position.x = breedte / 2 - xBovVo / 2 + xBovAc / 2
      dakbeschot.position.z = x / 2 - xBovLi / 2 + xBovRe / 2


    } else if (this.modelType.isZadeldak || this.modelType.isKapschuur) {
      const topPosition = ((breedte/2+ox)*Math.tan(graden * (Math.PI/180))) + oy
      const topPositionBeschot = topPosition + (by/Math.cos(graden * (Math.PI/180))) - by/2

      const radians = graden * (Math.PI/180);
      const hoogte = Math.cos(radians)*by
      const buitenOffset = Math.sqrt((by**2) - (hoogte**2))

      const texture1Voor = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`)
      this.textureSettings(texture1Voor, 1, 1, Math.PI)
      const material1Voor = new this.THREE.MeshStandardMaterial({ map: texture1Voor, metalness: 0, roughness: 1 })

      const texture1Achter = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`)
      this.textureSettings(texture1Achter, 1, 1, Math.PI)
      const material1Achter = new this.THREE.MeshStandardMaterial({ map: texture1Achter, metalness: 0, roughness: 1 })
      
      let dakbeschotVoorGeometry = new this.THREE.BufferGeometry()
      dakbeschotVoorGeometry.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(breedte/2+buitenOffset+ox, by, x+(overhang*2), graden)), 3))
      dakbeschotVoorGeometry.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(breedte/2+buitenOffset+ox, by, x+(overhang*2), graden, 1024, 1024)), 2))
      dakbeschotVoorGeometry.setIndex(boxBuffer.indexBuffer());
      dakbeschotVoorGeometry = dakbeschotVoorGeometry.toNonIndexed()
      dakbeschotVoorGeometry.computeVertexNormals()

      let dakbeschotAchterGeometry = new this.THREE.BufferGeometry()
      dakbeschotAchterGeometry.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer((this.modelType.isKapschuur ? z-this.kapschuurConfig.topX : breedte/2)+buitenOffset+ox, by, x+(overhang*2), graden)), 3))
      dakbeschotAchterGeometry.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer((this.modelType.isKapschuur ? z-this.kapschuurConfig.topX : breedte/2)+buitenOffset+ox, by, x+(overhang*2), graden, 1024, 1024)), 2))
      dakbeschotAchterGeometry.setIndex(boxBuffer.indexBuffer());
      dakbeschotAchterGeometry = dakbeschotAchterGeometry.toNonIndexed()
      dakbeschotAchterGeometry.computeVertexNormals()

      dakbeschot1.geometry = dakbeschotVoorGeometry
      dakbeschot1.material = material1Voor
      dakbeschot1.position.x = breedte/2
      dakbeschot1.position.y = topPositionBeschot
      dakbeschot1.position.z = x / 2

      dakbeschot2.geometry = dakbeschotAchterGeometry
      dakbeschot2.material = material1Achter
      dakbeschot2.position.x = breedte/2
      dakbeschot2.position.y = topPositionBeschot
      dakbeschot2.position.z = x / 2

      const dakpanMeshPositionY = topPosition - by/2 + (by/Math.cos(radians)) + 5 + (1/Math.cos(radians))

      const dakPlaatBreedteVoor = x+(overhang*2)
      const dakplaatLengteVoor = (breedte/2+buitenOffset+ox)/Math.cos(radians) + (Math.sin(radians)*5) + dpo

      const dakPlaatBreedteAchter = x+(overhang*2)
      const dakplaatLengteAchter = ((this.modelType.isKapschuur ? z-this.kapschuurConfig.topX : breedte/2)+buitenOffset+ox)/Math.cos(radians) + (Math.sin(radians)*5) + dpoa

      this.dakpanMeshSizeVoor = dakplaatLengteVoor
      this.dakpanMeshSizeAchter = dakplaatLengteAchter

      console.log(this.dakAfwerking, this.dakAfwerking.scalingX, this.dakAfwerking.scalingY)

      this.textureSettings(dakpanMesh.material.map, x / 1000 / this.dakAfwerking.scalingX, this.dakpanMeshSizeVoor / 1000 / this.dakAfwerking.scalingY)
      if(this.dakAfwerking.emissiveColor != null || this.dakAfwerking.emissiveColor != undefined) {
        dakpanMesh.material.emissive = new this.THREE.Color(this.dakAfwerking.emissiveColor)
      }
      if(this.dakAfwerking.emissive != null || this.dakAfwerking.emissive != undefined) {
        dakpanMesh.material.emissiveIntensity = this.dakAfwerking.emissive
      }
      if(this.dakAfwerking.metalness != null || this.dakAfwerking.metalness != undefined) {
        dakpanMesh.material.metalness = this.dakAfwerking.metalness
      }
      this.textureSettings(dakpanMesh2.material.map, x / 1000 / this.dakAfwerking.scalingX, this.dakpanMeshSizeAchter / 1000 / this.dakAfwerking.scalingY)
      if(this.dakAfwerking.emissiveColor != null || this.dakAfwerking.emissiveColor != undefined) {
        dakpanMesh2.material.emissive = new this.THREE.Color(this.dakAfwerking.emissiveColor)
      }
      if(this.dakAfwerking.emissive != null || this.dakAfwerking.emissive != undefined) {
        dakpanMesh2.material.emissiveIntensity = this.dakAfwerking.emissive
      }
      if(this.dakAfwerking.metalness != null || this.dakAfwerking.metalness != undefined) {
        dakpanMesh2.material.metalness = this.dakAfwerking.metalness
      }

      const dakpanGeometryVoor = new this.THREE.BufferGeometry()
      dakpanGeometryVoor.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(plane.positionBuffer(dakPlaatBreedteVoor, dakplaatLengteVoor)), 3))
      dakpanGeometryVoor.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(plane.uvBufferVoor(dakPlaatBreedteVoor, dakplaatLengteVoor)), 2))
      dakpanGeometryVoor.computeVertexNormals()

      const dakpanGeometryAchter = new this.THREE.BufferGeometry()
      dakpanGeometryAchter.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(plane.positionBuffer(dakPlaatBreedteAchter, dakplaatLengteAchter)), 3))
      dakpanGeometryAchter.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(plane.uvBufferAchter(dakPlaatBreedteAchter, dakplaatLengteAchter)), 2))
      dakpanGeometryAchter.computeVertexNormals()

      //dakpanGeometry.computeVertexNormals()
      dakpanMesh.geometry = dakpanGeometryVoor
      dakpanMesh.position.x = breedte/2
      dakpanMesh.position.y = dakpanMeshPositionY
      dakpanMesh.position.z = x+overhang
      dakpanMesh.rotation.z = -radians

      dakpanMesh2.geometry = dakpanGeometryAchter
      dakpanMesh2.position.x = breedte/2
      dakpanMesh2.position.y = dakpanMeshPositionY
      dakpanMesh2.position.z = -overhang
      dakpanMesh2.rotation.z = -radians

      nokvorst.material.map.rotation = 1*Math.PI
      nokvorst.material.map.repeat.x = this.x / 1000 / this.dakAfwerking.scalingX
      nokvorst.material.map.repeat.y = 0.01
      nokvorst.scale.y = (x+(overhang*2))
      nokvorst.position.x = breedte/2
      nokvorst.position.y = dakpanMeshPositionY - 100 + 10
      nokvorst.position.z = x/2
      if(this.dakAfwerking.emissiveColor != null || this.dakAfwerking.emissiveColor != undefined) {
        nokvorst.material.emissive = new this.THREE.Color(this.dakAfwerking.emissiveColor)
      }
      if(this.dakAfwerking.emissive != null || this.dakAfwerking.emissive != undefined) {
        nokvorst.material.emissiveIntensity = this.dakAfwerking.emissive
      }
      if(this.dakAfwerking.metalness != null || this.dakAfwerking.metalness != undefined) {
        nokvorst.material.metalness = this.dakAfwerking.metalness
      }

      store.dispatch('updateDakPlaatSize', {dakPlaatBreedteVoor, dakplaatLengteVoor, dakPlaatBreedteAchter, dakplaatLengteAchter})
    }
  }

  updateDakafwerkingTexture (texture) {
    console.log(texture)
    this.dakAfwerking = texture
    this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.dakAfwerking.previewURL}`, (newTexture) => {
      const dakpanMesh = this.group.getObjectByName("dakpanMesh")
      const dakpanMesh2 = this.group.getObjectByName("dakpanMesh2")
      const nokvorst = this.group.getObjectByName("nokvorst")
      const newTextureVoor = newTexture.clone()
      const newTextureAchter = newTexture.clone()
      this.textureSettings(newTextureVoor, this.x / 1000 / texture.scalingX, this.dakpanMeshSizeVoor / 1000 / texture.scalingY)
      this.textureSettings(newTextureAchter, this.x / 1000 / texture.scalingX, this.dakpanMeshSizeAchter / 1000 / texture.scalingY)
      newTextureVoor.needsUpdate = true
      newTextureAchter.needsUpdate = true
      dakpanMesh.material.map = newTextureVoor
      if(this.dakAfwerking.emissiveColor != null || this.dakAfwerking.emissiveColor != undefined) {
        dakpanMesh.material.emissive = new this.THREE.Color(texture.emissiveColor)
      }
      if(this.dakAfwerking.emissive != null || this.dakAfwerking.emissive != undefined) {
        dakpanMesh.material.emissiveIntensity = texture.emissive
      }
      if(this.dakAfwerking.metalness != null || this.dakAfwerking.metalness != undefined) {
        dakpanMesh.material.metalness = texture.metalness
      }
      dakpanMesh.material.needsUpdate = true
      dakpanMesh2.material.map = newTextureAchter
      if(this.dakAfwerking.emissiveColor != null || this.dakAfwerking.emissiveColor != undefined) {
        dakpanMesh2.material.emissive = new this.THREE.Color(texture.emissiveColor)
      }
      if(this.dakAfwerking.emissive != null || this.dakAfwerking.emissive != undefined) {
        dakpanMesh2.material.emissiveIntensity = texture.emissive
      }
      if(this.dakAfwerking.metalness != null || this.dakAfwerking.metalness != undefined) {
        dakpanMesh2.material.metalness = texture.metalness
      }
      dakpanMesh2.material.needsUpdate = true
      nokvorst.material.map = newTextureVoor.clone()
      if(this.dakAfwerking.emissiveColor != null || this.dakAfwerking.emissiveColor != undefined) {
        nokvorst.material.emissive = new this.THREE.Color(texture.emissiveColor)
      }
      if(this.dakAfwerking.emissive != null || this.dakAfwerking.emissive != undefined) {
        nokvorst.material.emissiveIntensity = texture.emissive
      }
      if(this.dakAfwerking.metalness != null || this.dakAfwerking.metalness != undefined) {
        nokvorst.material.metalness = texture.metalness
      }
      nokvorst.material.needsUpdate = true
      nokvorst.material.map.rotation = 1*Math.PI
      nokvorst.material.map.repeat.x = this.x / 1000 / texture.scalingX
      nokvorst.material.map.repeat.y = 0.01
      nokvorst.material.map.needsUpdate = true
    })
  }

  updateTexture (texture) {
    this.texture = texture

    while(this.group.children.length > 0){
      this.group.remove(this.group.children[0]);
    }
    this.bouw(this.THREE, this.textureLoader, this.x, this.y, this.z, this.by, this.overstekKlos, this.offset, this.texture, this.kp, this.graden, this.overhang, this.dpo, this.dpoa, this.ox, this.oy)
    return

    let klosVoEnabled = this.klosVoEnabled = this.overstekKlos.enabled? this.overstekKlos.klosVoEnabled : false
    let xBovVo = this.xBovVo = klosVoEnabled ? this.overstekKlos.xBovVo : 0
    let klosAcEnabled = this.klosAcEnabled = this.overstekKlos.enabled? this.overstekKlos.klosAcEnabled : false
    let xBovAc = this.xBovAc = klosAcEnabled ? this.overstekKlos.xBovAc : 0
    let klosLiEnabled = this.klosLiEnabled = this.overstekKlos.enabled? this.overstekKlos.klosLiEnabled : false
    let xBovLi = this.xBovLi = klosLiEnabled ? this.overstekKlos.xBovLi : 0
    let klosReEnabled = this.klosReEnabled = this.overstekKlos.enabled? this.overstekKlos.klosReEnabled : false
    let xBovRe = this.xBovRe = klosReEnabled ? this.overstekKlos.xBovRe : 0

    console.log(texture)
    this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`, (newTexture) => {
      const dakbeschot = this.group.getObjectByName("dakbeschot")
      const dakbeschot1 = this.group.getObjectByName("dakbeschot1")
      const dakbeschot2 = this.group.getObjectByName("dakbeschot2")

      dakbeschot.material.map = newTexture.clone()
      this.textureSettings(dakbeschot.material.map, (this.z + xBovVo + xBovAc) * 0.00075, (this.x + xBovLi + xBovRe) * 0.00075)
      dakbeschot.material.map.needsUpdate = true;

      dakbeschot1.material.map = newTexture.clone()
      this.textureSettings(dakbeschot1.material.map, 1, 1, Math.PI)
      dakbeschot1.material.map.needsUpdate = true;

      dakbeschot2.material.map = newTexture.clone()
      this.textureSettings(dakbeschot2.material.map, 1, 1, Math.PI)
      dakbeschot2.material.map.needsUpdate = true;
    })
  }

  textureSettings(texture, repeatX, repeatY, rotation) {
    texture.rotation = rotation || texture.rotation
    texture.repeat.x = repeatX
    texture.repeat.y = repeatY
    texture.wrapS = this.THREE.RepeatWrapping
    texture.wrapT = this.THREE.RepeatWrapping
    texture.encoding = this.THREE.sRGBEncoding
    texture.anisotropy = 16
    // texture.offset.set(Math.random(), 0)
  }

  setDakpanMeshVisibility (visibility) {
    this.nokvorstVisible = visibility
    const dakpanMesh = this.group.getObjectByName("dakpanMesh")
    const dakpanMesh2 = this.group.getObjectByName("dakpanMesh2")
    const nokvorst = this.group.getObjectByName("nokvorst")
    if(dakpanMesh) dakpanMesh.visible = visibility
    if(dakpanMesh2) dakpanMesh2.visible = visibility
    if(nokvorst) nokvorst.visible = visibility
    console.log(dakpanMesh)
  }
}