<template>
  <div class="menu"> 
    <div class="menu-nav">
      <button class="menu-nav-button" @click="updateRoute('BACK')">
        <mdicon class="menu-button-icon" name="arrow-left-bold" size="30" />
      </button>

      <div class="menu-nav-links" :class="this.$route.path == '/cfg/invoice' ? 'offerte' : ''">
        <router-link class="menu-nav-links-link"  v-for="(item, index) in navItems" :key="index" 
        :to="$route.path.includes('doorswindows') && item.route.includes('doorswindows') ? '' : `/cfg${item.route}`">
        <!-- prevent Doors & Windows navitem from routing to childless self with line above -->

          <div
            @click="index === 4 ? scrollCardsToTop() : ''"
            class="menu-nav-item" 
            :class="
              $route.path.indexOf(`/cfg/doorswindows/`) >= 0 ? (item.route === '/doorswindows/' ? 'menu-nav-item-active' : '') :
              $route.path.indexOf(`/cfg/walls/`) >= 0 ? (item.route === '/walls/' ? 'menu-nav-item-active' : '') : 
              $route.path.indexOf(`/cfg/roof/`) >= 0 ? (item.route === '/roof/' ? 'menu-nav-item-active' : '') :
              $route.path.indexOf(`/cfg/options/`) >= 0 ? (item.route === '/options/' ? 'menu-nav-item-active' : '') :
              ($route.path === `/cfg${item.route}` ? 'menu-nav-item-active' : '')
            "
            >
            <mdicon class="menu-nav-item-icon" :name="item.icon" size="30" />
            <span class="menu-nav-item-title" :style="index === 4 ? 'font-size: 0.85rem' : ''">{{ item.title }}</span>
          </div>

          <!-- <div v-if="index === 4"
          @click="scrollCardsToTop()"
            class="menu-nav-item" 
            :class="
              $route.path.indexOf(`/cfg/doorswindows/`) >= 0 ? (item.route === '/doorswindows/' ? 'menu-nav-item-active' : '') :
              $route.path.indexOf(`/cfg/walls/`) >= 0 ? (item.route === '/walls/' ? 'menu-nav-item-active' : '') : 
              ($route.path === `/cfg${item.route}` ? 'menu-nav-item-active' : '')
            "
            >
            <mdicon class="menu-nav-item-icon" :name="item.icon" size="30" />
            <span class="menu-nav-item-title" style="font-size: 0.85rem">{{ item.title }}</span>
          </div> -->

          <div v-if="index === 0" class="menu-nav-slider" :style="`width: 100px; transform: translateX(${100 * selectedSection}px);`"></div>
        </router-link>
      </div>

      <button class="menu-nav-button" @click="updateRoute('FORWARD')"> 
        <mdicon class="menu-nav-button-icon" name="arrow-right-bold" size="30" />
      </button>

      <div class="menu-nav-links-right">
        <router-link class="menu-nav-links-link"  v-for="(item, index) in navItemsRight" :key="index" :to="`/cfg${item.route}`">
          <div 
            class="menu-nav-item" 
            :class="
              $route.path.indexOf(`/cfg/walls/`) >= 0 ? (item.route === '/walls/' ? 'menu-nav-item-active' : '') : 
              ($route.path === `/cfg${item.route}` ? 'menu-nav-item-active' : '')
            "
            >
            <mdicon class="menu-nav-item-icon" :name="item.icon" size="30" />
            <span class="menu-nav-item-title">{{ item.title }}</span>
          </div>

          <div v-if="$route.path.indexOf(`/cfg/walls/`) >= 0 ? (item.route === '/walls/' ? true : false) : 
              ($route.path === `/cfg${item.route}` ? true : false)" class="menu-nav-slider" :style="`width: 100px; transform: translateX(${0}px);`"></div>
        </router-link>
      </div>
    </div>

    <div class="menu-content">
      <!-- <KeepAlive> -->
        <RouterView />
      <!-- </KeepAlive> -->
    </div>

    <!-- <button class="menu-button">Offerte</button> -->
  </div>
</template>

<script>
  export default {
    name: "config-menu",

    data () {
      return {
        navItems: [
          {title: "Type", icon: "home", route: "/"},
          {title: "Model", icon: "folder-home", route: "/models"},
          {title: "Formaat", icon: "ruler-square", route: "/sizes"},
          {title: "Wanden", icon: "wall", route: "/walls/"},
          {title: "Ramen & Deuren", icon: "window-closed-variant", route: "/doorswindows/"},
          {title: "Kleuren", icon: "format-color-fill", route: "/colors"},
          {title: "Dak", icon: "home-roof", route: "/roof/"},
          {title: "Opties", icon: "coach-lamp", route: "/options/"},
        ],
        navItemsRight: [
          {title: "Offerte", icon: "clipboard-list", route: "/invoice"},
        ],
        selectedSection: 0,
        navScrollPosition: 0,
        navScrollEl: null,
        navScrollWidth: 0,
      }
    },

    methods: {
      setNavIndex() {
        if (this.$route.path.indexOf(`/cfg/walls/`) >= 0) {
          this.selectedSection = 3 }
        else if (this.$route.path.indexOf(`/cfg/doorswindows/`) >= 0) { //feature 1394
          this.selectedSection = 4
        } else if (this.$route.path.indexOf(`/cfg/roof/`) >= 0) { // TPLA-175 
          this.selectedSection = 6
        } else if (this.$route.path.indexOf(`/cfg/options/`) >= 0) { // TPLA-175 
          this.selectedSection = 7
        } else {
          this.selectedSection = this.navItems.findIndex((item) => this.$route.path === `/cfg${item.route}`)
        }

        this.navScrollWidth = document.querySelector('.menu-nav-links').clientWidth

        /*if ((this.selectedSection * 100) > this.navScrollWidth / 2) {
          setTimeout(() => {
            document.querySelector('.menu-nav-links').scrollLeft = (this.selectedSection * 100 + 200) - this.navScrollWidth / 2
          }, 100);
        } else if (this.selectedSection * 100 < this.navScrollWidth / 2) {
          setTimeout(() => {
            document.querySelector('.menu-nav-links').scrollLeft = 0
          }, 100);
        }*/
        setTimeout(() => {
          document.querySelector('.menu-nav-links').scrollLeft = (this.selectedSection * 100 + 100) - this.navScrollWidth / 2
        }, 100);
        /*let guiWidth = 0 
        if(window.innerWidth < 1000) {
          //mobile
          guiWidth = window.innerWidth
        } else {
          //desktop
          guiWidth = 600
        }
        console.log(window, guiWidth)
        document.querySelector('.menu-nav-links').scrollLeft = (this.selectedSection * 100) - (guiWidth-200)/2*/
      },

      scrollNav(movement) {
        const el = document.querySelector('.menu-nav-links')

        this.navScrollPosition = movement + el.scrollLeft

        el.scrollLeft = movement + el.scrollLeft
      },

      updateRoute(direction) {
        
        const paths = this.$router.getRoutes()
        const switchPaths = paths.filter((p) => p.meta.switchRoute)
        const currentPath = this.$route.path
        // const currentPathIndex = switchPaths.findIndex((p) => p.path === currentPath)

        // get nav items, used to set up menu buttons in the correct order
        const allNavItems = this.navItems.concat(this.navItemsRight);
        if (allNavItems.length !== switchPaths.length) {
          console.error('Nav items and switch paths are of different lengths!')
          return;
        }
        // add full route property, such as /cfg/walls/, to every list item
        for (let i = 0; i < allNavItems.length; i++) {
          const item = allNavItems[i];
          item.fullRoute = switchPaths.find(p => p.path.includes(item.route)).path;
        }
        // get nav item that matches current path
        let navItemIndex = 0;
        if (currentPath !== '/cfg/') {
          const navItem = allNavItems.find(i => i.fullRoute === currentPath);
          navItemIndex = allNavItems.indexOf(navItem);
        }
        
        if (direction === 'BACK') {
          // if (currentPathIndex > 0) {
          //   this.$router.push(switchPaths[currentPathIndex - 1])
          // } 
          if (navItemIndex > 0) {
            this.$router.push( 
              switchPaths.find(s => s.path === allNavItems[navItemIndex - 1].fullRoute) 
            )
          }
        } else if (direction === 'FORWARD') {
          // if (currentPathIndex < switchPaths.length) {
          //   this.$router.push(switchPaths[currentPathIndex + 1])
          // }
          if (navItemIndex < allNavItems.length - 1) {
            this.$router.push( 
              switchPaths.find(s => s.path === allNavItems[navItemIndex + 1].fullRoute) 
            )
          }
        }
      },

      scrollCardsToTop(){
        setTimeout(() => {
                document.getElementsByClassName("card")[0].scrollIntoView({ behavior: 'smooth'});
              }, 100);
      }
    },

    created () {
    },

    mounted () {
      this.setNavIndex()
    },

    watch: {
      $route () {
        this.setNavIndex()
      }
    }
  }
</script>

<style lang="scss" scoped>

.menu {
  // positioning and size
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  // styling
  cursor: default;

  &-nav {
    // positioning and size
    display: flex;
    min-height: 80px;
    width: 100%;
    position: relative;

    // styling
    background-color: $c-primary;

    &-links {
      display: flex;
      overflow: scroll;
      scroll-behavior: smooth;
      padding: 0px;
      margin: 0px;
      
      @media (min-width: 1000px) {
        width: 400px;
      }

      @media (max-width: 999px) {
        width: calc(100% - 200px);
      }

      @include scrolbar-hidden;

      &-link {
        display: flex;
        text-decoration: none;
        min-width: 100px;
        position: relative;
      }

      &-right {
        width: 100px;
        display: flex;
        overflow: scroll;
        scroll-behavior: smooth;
        padding: 0px;
        margin: 0px;
        @include scrolbar-hidden;
      }
    }

    &-button {
      min-width: 50px;
      max-width: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: $c-secondary;
      color: $c-primary;
      cursor: pointer;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0px;
      width: 100px;

      // stying
      color: $c-primary-text;
      cursor: pointer;

      // animation
      transition: color 100ms ease;

      &-icon {
        display: flex;
      }

      &-title {
        margin: 0px;
        font-size: 1rem;
        // an attempt to ensure readability - turned off for now
        //color: $c-primary-text;
      }

      &-active {
        color: $c-secondary;
      }
    }

    &-slider {
      content: "";
      position: absolute;
      height: 5px;
      bottom: 0;
      left: 0;
      width: 100px;

      // styling
      background-color: $c-secondary;

      // animation
      transition: transform 150ms ease;
    }

    @include scrolbar-hidden;
  }

  &-content {
    // positioning and size
    height: 100%;
    padding: 25px;
    @media (max-width: 999px) {
      padding: 10px;
    }
    // overflow: auto;
    overflow: scroll; // prevent wobbling sizes on submenu switch
    position: relative;

    @include scrolbar-1;

    // styling
    background-color: white;
  }

  &-button {
    // positioning and size
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 10px;
    padding: 10px 15px;
    z-index: 80;

    // styling
    border: none;
    border-radius: $radius-m;
    background-color: $c-secondary;
    box-shadow: $shadow-1;
    cursor: pointer;
    color: $c-secondary-text;

    // text
    font-size: 1.3rem;
    font-weight: 700;

    // animation
    transition: background-color 150ms ease;

    &:hover {
      background-color: $c-secondary-hover;
    }
  }
}

.offerte {
  width: auto;
  @media (max-width: 999px) {
    width: calc(100% - 100px);
  }
}

</style>