import * as boxBuffer from '@/three/buffer-geometries/boxBuffer.js'
import * as gordingBuffer from '@/three/buffer-geometries/gordingBuffer.js'
import * as schuineGording from '@/three/buffer-geometries/schuineGording.js'
import * as milaanGording from '@/three/buffer-geometries/milaanGording.js'
import * as dakPaal from '@/three/buffer-geometries/dakPaal.js'
import * as triangleBuffer from '@/three/buffer-geometries/triangle.js'
import * as nokGording from '@/three/buffer-geometries/nokGording.js'
import {CSG} from 'three-csg-ts';
import { SUBTRACTION, ADDITION, Brush, Evaluator } from 'three-bvh-csg';
import { getLichtstraatInfo } from '@/three/functions/functions.js'
// import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js'

export default class Gording {
  constructor (THREE, scene, textureLoader, texture, x, y, z, division, gy, gz, overstekKlos, kp, gs, rbgy, rbgz, rbgp, rby, rbz, verticalePositie, staanders, round, erOn, erOffset, modelType, graden, rbtp, rbtpy, rbtpz, ox, oy, oyl, gsh, gso, overhang, staanderGrootte, kapschuurConfig, dy, dz, lichtstraten) {
    this.THREE = THREE
    this.scene = scene
    this.textureLoader = textureLoader
    this.texture = texture
    this.group = null
    this.gordingBalk = null
    this.onderBalk = null
    this.klos = null
    this.ttOverspanning = gs // afstand tussen gordingen
    this.nTGroup = null
    this.x = x // frame
    this.y = y // frame
    this.z = z // frame
    this.division = division
    this.gy = gy // godring hoogte
    this.gz = gz // gording dikte
    this.overstekKlos = overstekKlos
    this.kp = kp
    this.rbgy = rbgy // ringBalk gording hoogte
    this.rbgz = rbgz // ringBalk gording breedte
    this.rbgp = rbgp
    this.rby = rby // ringBalk hoogte
    this.rbz = rbz // ringbalk dikte
    this.verticalePositie = verticalePositie //verticalePositie van gordingen
    this.staanders = staanders
    this.round = round
    this.erOn = erOn
    this.erOffset = erOffset
    this.modelType = modelType
    this.graden = graden
    this.rbtp = rbtp
    this.rbtpy = rbtpy
    this.rbtpz = rbtpz
    this.ox = ox
    this.oy = oy
    this.oyl = oyl
    this.gsh = gsh
    this.gso = gso
    this.overhang = overhang
    this.staanderGrootte = staanderGrootte
    this.kapschuurConfig = kapschuurConfig
    this.dy = dy
    this.dz = dz
    this.lichtstraten = lichtstraten

    this.group = new this.THREE.Group()
    this.evaluator = new Evaluator();
    this.gatMaterial = new this.THREE.MeshStandardMaterial({metalness: 0, roughness: 1 })

    this.bouw(THREE, scene, textureLoader, texture, x, y, z, division, gy, gz, overstekKlos, kp, gs, rbgy, rbgz, rbgp, rby, rbz, verticalePositie, staanders, round, erOn, erOffset, graden, rbtp, rbtpy, rbtpz, ox, oy, oyl, gsh, gso, overhang, staanderGrootte, dy, dz)
  }

  async bouw(THREE, scene, textureLoader, texture, x, y, z, division, gy, gz, overstekKlos, kp, gs, rbgy, rbgz, rbgp, rby, rbz, verticalePositie, staanders, round, erOn, erOffset, graden, rbtp, rbtpy, rbtpz, ox, oy, oyl, gsh, gso, overhang, staanderGrootte, dy, dz) {

    let zDikPaal = this.zDikPaal = overstekKlos.zDikPaal
    let klosVoEnabled = this.klosVoEnabled = overstekKlos.enabled? overstekKlos.klosVoEnabled : false
    let xBovVo = this.xBovVo = klosVoEnabled ? overstekKlos.xBovVo : 0
    let xOndVo = this.xOndVo = klosVoEnabled ? overstekKlos.xOndVo : 0
    let yHooVo = this.yHooVo = klosVoEnabled ? overstekKlos.yHooVo : 0
    let yKopVo = this.yKopVo = klosVoEnabled ? overstekKlos.yKopVo : 0
    let klosAcEnabled = this.klosAcEnabled = overstekKlos.enabled? overstekKlos.klosAcEnabled : false
    let xBovAc = this.xBovAc = klosAcEnabled ? overstekKlos.xBovAc : 0
    let xOndAc = this.xOndAc = klosAcEnabled ? overstekKlos.xOndAc : 0
    let yHooAc = this.yHooAc = klosAcEnabled ? overstekKlos.yHooAc : 0
    let yKopAc = this.yKopAc = klosAcEnabled ? overstekKlos.yKopAc : 0
    let klosLiEnabled = this.klosLiEnabled = overstekKlos.enabled? overstekKlos.klosLiEnabled : false
    let xBovLi = this.xBovLi = klosLiEnabled ? overstekKlos.xBovLi : 0
    let xOndLi = this.xOndLi = klosLiEnabled ? overstekKlos.xOndLi : 0
    let yHooLi = this.yHooLi = klosLiEnabled ? overstekKlos.yHooLi : 0
    let yKopLi = this.yKopLi = klosLiEnabled ? overstekKlos.yKopLi : 0
    let klosReEnabled = this.klosReEnabled = overstekKlos.enabled? overstekKlos.klosReEnabled : false
    let xBovRe = this.xBovRe = klosReEnabled ? overstekKlos.xBovRe : 0
    let xOndRe = this.xOndRe = klosReEnabled ? overstekKlos.xOndRe : 0
    let yHooRe = this.yHooRe = klosReEnabled ? overstekKlos.yHooRe : 0
    let yKopRe = this.yKopRe = klosReEnabled ? overstekKlos.yKopRe : 0

    let kzp = this.kzp = overstekKlos.zDikPaal // dikte paal
    let kx = this.kx = overstekKlos.xBovVo // lengte bovenkant
    let kxo = this.kxo = overstekKlos.xOndVo // lengte onderkant
    let kyo = this.kyo = overstekKlos.yKopVo // hoogte kopkant

    // setTimeout(() => {
    //   const exporter = new GLTFExporter();
    //   exporter.parse(
    //     this.group,
    //     ( gltf ) => {
    //         console.log( gltf );
    //         console.log(this.group.children)
    //         for(let i = 0; i < this.group.children.length; i++) {
    //           //this.group.children[i].position.y = 5000 + (i*500)
    //         }
    //         //this.group.children[3].position.y = 5000
    //     },
    //     ( error ) => {
    //         console.log( 'An error happened', error);
    //     },
    //     {onlyVisible: true}
    //   );
    // }, 10000)

    const group = this.group

    // console.log({
    //   gy: gy, // 120 a
    //   "z + (2 * kx)": z + (2 * kx), // 3160 b
    //   "gy - kyo": gy - kyo, // 50 c
    //   "z + (2 * kx) - (kx - kxo)": z + (2 * kx) - (kx - kxo), // 3000 d
    //   "kx - kxo": kx - kxo // 160 e
    // })

    // const coordinatesList = [
    //   new this.THREE.Vector3(0, gy, 0),
    //   new this.THREE.Vector3(z + (2 * kx), gy, 0),
    //   new this.THREE.Vector3(z + (2 * kx), gy - kyo, 0),
    //   new this.THREE.Vector3(z + (2 * kx) - (kx - kxo), 0, 0),
    //   new this.THREE.Vector3(kx - kxo, 0, 0),
    //   new this.THREE.Vector3(0, gy - kyo, 0),
    // ];

    // const extrudeSettings = {
    //   steps: 1,
    //   depth: gz, // 45 f
    //   bevelEnabled: false,
    // };

    // console.log(new this.THREE.Shape(coordinatesList))

    // const geometry = new this.THREE.ExtrudeGeometry( new this.THREE.Shape(coordinatesList), extrudeSettings );

    // console.log(geometry)
    const loadedTexture = await textureLoader.loadAsync(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.loadedTexture = loadedTexture

    const breedte = this.modelType.isKapschuur ? this.kapschuurConfig.topX*2 : z
    const ringBalkBreedteKapschuur = breedte+ox+((rbtpy+oy)/Math.tan(graden*(Math.PI/180)))

    // let gKLength = kp.a&&kp.v&&this.modelType.isPlatdak ? kx+0.5 : 0
    // let gKLengthO = kp.a&&kp.v&&this.modelType.isPlatdak ? kxo : 0


    //const red = new THREE.MeshStandardMaterial({color: "#ff0000"})
    /*const testTexture = loadedTexture.clone()//this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}testTexture.png`)
    this.textureSettings(testTexture, 1, 1)
    const material0 = new THREE.MeshStandardMaterial({ map: testTexture, metalness: 0, roughness: 1 })
    material0.map.needsUpdate = true
    material0.needsUpdate = true
    const driehoek = new THREE.BufferGeometry()
    driehoek.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(triangleBuffer.positionBuffer(145, 65, 40)), 3))
    driehoek.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(triangleBuffer.uvBuffer(145, 65, 40)), 2))
    driehoek.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(triangleBuffer.normalBuffer()), 3))
    const driehoekMesh = new THREE.Mesh(driehoek, material0)
    driehoekMesh.position.set(-200,1400,-200)
    const helper = new VertexNormalsHelper(driehoekMesh, 100)
    scene.add(driehoekMesh, helper)*/

    /*const texture0 = loadedTexture.clone()
    texture0.needsUpdate = true
    this.textureSettings(texture0, z / 5440000 , gy / 145 / gy)
    //const testTexture = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}testTexture.png`)
    this.textureSettings(texture0, 1, 1, 0, Math.random())
    const material0 = new THREE.MeshStandardMaterial({ map: texture0, metalness: 0, roughness: 1 })

    const schuineGordingGeometry = new THREE.BufferGeometry()
    schuineGordingGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer((z-145)/2, 145, 145, 40)), 3))
    schuineGordingGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer((z-145)/2, 145, 145, 40)), 2))
    schuineGordingGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(schuineGording.normalBuffer()), 3))
    schuineGordingGeometry.setIndex(schuineGording.indexBuffer());
    const schuineGordingMesh = new THREE.Mesh(schuineGordingGeometry, material0)
    schuineGordingMesh.position.set(0,0,145/2+1)
    const schuineGordingMesh2 = schuineGordingMesh.clone()
    schuineGordingMesh2.rotation.y=Math.PI
    schuineGordingMesh2.position.x=z

    const radians = 40 * (Math.PI/180);
    const h = ((z-145)/2)*Math.tan(radians)
    const dakPaalGeometry = new THREE.BufferGeometry()
    dakPaalGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(dakPaal.positionBuffer(145, h, 145, 40)), 3))
    dakPaalGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(dakPaal.uvBuffer(145, h, 145, 40)), 2))
    dakPaalGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(dakPaal.normalBuffer()), 3))
    const dakPaalMesh = new THREE.Mesh(dakPaalGeometry, material0)
    dakPaalMesh.position.set(z/2,0,145/2+1)
    this.group.add(schuineGordingMesh, schuineGordingMesh2, dakPaalMesh)*/


    // buitenste balken groep
    const bGroup = new this.THREE.Group()

    // balken aan de buitenkant (alleen bij milaan)
    const kGroup = new this.THREE.Group()
    //tochtplanken aan de zijkant
    const tpGroup = new this.THREE.Group()


    // ringbalkgording geometry
    const geometry1Paal = new THREE.BufferGeometry()
    if(this.modelType.isKapschuur) {
      geometry1Paal.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(ringBalkBreedteKapschuur*Math.sin(graden*(Math.PI/180)), dy, dz+2, 90-graden)), 3))
      geometry1Paal.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(ringBalkBreedteKapschuur*Math.sin(graden*(Math.PI/180)), dy, dz+2, 90-graden)), 2))
      geometry1Paal.setIndex(schuineGording.indexBuffer());
      geometry1Paal.computeVertexNormals()
      geometry1Paal.translate(0, 0, -dz/2)
      geometry1Paal.rotateX(Math.PI)
    } else if (this.modelType.isZadeldak) {
      geometry1Paal.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferGording(breedte-1, this.modelType.isPlatdak ? rbgy-0.5 : rbtpy-0.5, this.modelType.isPlatdak ? rbgz-0.5 : rbtpy-0.5, xBovLi, xOndLi, xBovRe, xOndRe, yHooLi, yKopLi, yHooRe, yKopRe)), 3))
      geometry1Paal.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferGording(breedte-1, this.modelType.isPlatdak ? rbgy-0.5 : rbtpy-0.5, this.modelType.isPlatdak ? rbgz-0.5 : rbtpy-0.5, xBovLi, xOndLi, xBovRe, xOndRe, yHooLi, yKopLi, yHooRe, yKopRe)), 2))
      geometry1Paal.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferGording()), 3))
    } else {
      geometry1Paal.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferGording(breedte-1, this.modelType.isPlatdak ? rbgy-0.5 : rbtpy-0.5, this.modelType.isPlatdak ? rbgz-0.5 : rbtpy-0.5, xBovVo, xOndVo, xBovAc, xOndAc, yHooVo, yKopVo, yHooAc, yKopAc)), 3))
      geometry1Paal.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferGording(breedte-1, this.modelType.isPlatdak ? rbgy-0.5 : rbtpy-0.5, this.modelType.isPlatdak ? rbgz-0.5 : rbtpy-0.5, xBovVo, xOndVo, xBovAc, xOndAc, yHooVo, yKopVo, yHooAc, yKopAc)), 2))
      geometry1Paal.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferGording()), 3))
    }

    // texture
    const texture1 = loadedTexture.clone()
    texture1.needsUpdate = true
    this.textureSettings(texture1, breedte / 2330 / breedte , gy / 145 / gy)
    const material1 = new THREE.MeshStandardMaterial({ map: texture1, metalness: 0, roughness: 1 })

    const textureKapschuur = loadedTexture.clone()
    this.textureSettings(textureKapschuur, 1, 1, Math.PI)
    const materialKapschuur = new THREE.MeshStandardMaterial({ map: textureKapschuur, metalness: 0, roughness: 1 })

    // ringbalkgording mesh
    let ringBalkGordingMesh
    if(this.modelType.isKapschuur) {
      ringBalkGordingMesh = new this.THREE.Mesh( geometry1Paal, materialKapschuur );
      ringBalkGordingMesh.rotation.z = (90-graden)*Math.PI/180
    } else {
      ringBalkGordingMesh = new Brush( geometry1Paal, material1 );
    }
    ringBalkGordingMesh.castShadow = true; 
    ringBalkGordingMesh.castShadow = true; 
    ringBalkGordingMesh.castShadow = true; 
    ringBalkGordingMesh.receiveShadow = true;
    ringBalkGordingMesh.position.y = rbgp- verticalePositie
    if(this.modelType.isKapschuur) {
      ringBalkGordingMesh.position.x = ringBalkBreedteKapschuur
    } else {
      ringBalkGordingMesh.position.x=this.modelType.isPlatdak ? (xBovAc+0.5)-xBovAc : 0.5
    }
    let ringBalkGording = ringBalkGordingMesh
    this.ringBalkGording = ringBalkGording
    

    let gordingBalk;
    let zadelDakPaal;
    let onderBalk;


    const tGroup = new this.THREE.Group()
    const oGroup = new this.THREE.Group()
    const ttGroup = new this.THREE.Group()
    const kbGroup = new this.THREE.Group()
    const kt0Group = new this.THREE.Group()
    const kt1Group = new this.THREE.Group()
    const ngGroup = new this.THREE.Group()


    if(this.modelType.isPlatdak) {
      const geometry1 = new THREE.BufferGeometry()
      geometry1.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferGording(breedte-1, gy-0.5, gz, xBovVo, xOndVo, xBovAc, xOndAc, yHooVo, yKopVo, yHooAc, yKopAc)), 3))
      geometry1.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferGording(breedte-1, gy-0.5, gz, xBovVo, xOndVo, xBovAc, xOndAc, yHooVo, yKopVo, yHooAc, yKopAc)), 2))
      geometry1.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferGording()), 3))

      const gordingBalkMesh = new Brush( geometry1, material1 );
      gordingBalkMesh.castShadow = true; 
      gordingBalkMesh.castShadow = true; 
      gordingBalkMesh.castShadow = true; 
      gordingBalkMesh.receiveShadow = true;
      gordingBalkMesh.position.y=0.5
      gordingBalkMesh.position.x= (xBovAc+0.5)-xBovAc

      gordingBalk = gordingBalkMesh
      if(round){
        const cylinderG = new THREE.CylinderGeometry( 1, 1, 1, 256 );
        const cylinderM = new THREE.MeshBasicMaterial( {color: 0xff00dc} );
        const cylinder = new THREE.Mesh( cylinderG, cylinderM );
        cylinder.scale.set(kx-kxo, 500, gy-kyo)
        cylinder.rotation.x=Math.PI/2
        cylinder.position.y=0.5
        cylinder.updateMatrix()
        gordingBalkMesh.updateMatrix()
        gordingBalk = CSG.subtract(gordingBalkMesh, cylinder)
        cylinder.position.x=breedte+kx*2
        cylinder.updateMatrix()
        gordingBalkMesh.updateMatrix()
        gordingBalk = CSG.subtract(gordingBalk, cylinder)
        cylinder.geometry.dispose();
        cylinder.material.dispose();
        gordingBalk = new Brush(gordingBalk.geometry, gordingBalk.material)
      }

      if(round){
        const cylinderG = new THREE.CylinderGeometry( 1, 1, 1, 256 );
        const cylinderM = new THREE.MeshBasicMaterial( {color: 0xff00dc} );
        const cylinder = new THREE.Mesh( cylinderG, cylinderM );
        cylinder.scale.set(kx-kxo, 500, gy-kyo)
        cylinder.rotation.x=Math.PI/2
        cylinder.position.y=0.5
        cylinder.updateMatrix()
        ringBalkGordingMesh.updateMatrix()
        ringBalkGording = CSG.subtract(ringBalkGordingMesh, cylinder)
        cylinder.position.x=breedte+kx*2
        cylinder.updateMatrix()
        ringBalkGordingMesh.updateMatrix()
        ringBalkGording = CSG.subtract(ringBalkGording, cylinder)
        cylinder.geometry.dispose();
        cylinder.material.dispose();
        ringBalkGording = new Brush(ringBalkGording.geometry, ringBalkGording.material)
      }

      this.gordingBalk = gordingBalk

      if(!erOn) {
        const balkB0 = ringBalkGording.clone()
        this.setMaterial(balkB0, breedte / 5440000 , gy / 145 / gy, null, Math.random())
        
        const balkB1 = ringBalkGording.clone()
        this.setMaterial(balkB1, breedte / 5440000 , gy / 145 / gy, null, Math.random())

        balkB0.position.z = 0.25
        balkB1.position.z = x - rbgz

        bGroup.add(balkB0)
        bGroup.add(balkB1)
      }

      // balken op tussen staanders

      // frame balk onder de tussen staanders
      const geometry2 = new THREE.BufferGeometry()
      geometry2.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(rbgz, rbgy, breedte - (2 * gz))), 3))
      geometry2.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
      geometry2.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
      geometry2.setIndex(boxBuffer.indexBuffer());
      geometry2.groups = boxBuffer.groupBuffer()

      // texture
      const textureY = loadedTexture.clone()
      textureY.needsUpdate = true
      this.textureSettings(textureY,  breedte / 2450,  gy * 0.0075)
      const materialY = new THREE.MeshStandardMaterial({ map: textureY, metalness: 0, roughness: 1 })
      //materialY.side = THREE.BackSide

      const textureX = loadedTexture.clone()
      textureX.needsUpdate = true
      this.textureSettings(textureX,  breedte / 2450,  gz * 0.0075,  0.5 * Math.PI)
      const materialX = new THREE.MeshStandardMaterial({ map: textureX, metalness: 0, roughness: 1 })
      //materialX.side = THREE.BackSide

      // mesh
      onderBalk = new Brush( geometry2, [
        materialY,
        materialY,
        materialX,
        materialX,
        materialX,
        materialX,
      ]);

      onderBalk.castShadow = true; 
      onderBalk.receiveShadow = true;

      if(verticalePositie == (y + rbgy)) {
        onderBalk.position.y = - gy / 2
      } else {
        onderBalk.position.y = gy / 2
      }
      onderBalk.rotation.y = 0.5 * Math.PI
      this.onderBalk = onderBalk



      // ringbalk tussen paal
      const geometryRBTP = new THREE.BufferGeometry()
      geometryRBTP.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(rbtpz, rbtpy, breedte - (2 * rbz))), 3))
      geometryRBTP.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
      geometryRBTP.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
      geometryRBTP.setIndex(boxBuffer.indexBuffer());
      geometryRBTP.groups = boxBuffer.groupBuffer()
      // texture
      const textureYRBTP = loadedTexture.clone()
      textureYRBTP.needsUpdate = true
      this.textureSettings(textureYRBTP,  breedte / 2450,  gy * 0.0075)
      const materialYRBTP = new THREE.MeshStandardMaterial({ map: textureYRBTP, metalness: 0, roughness: 1 })
      const textureXRBTP = loadedTexture.clone()
      textureXRBTP.needsUpdate = true
      this.textureSettings(textureXRBTP,  breedte / 2450,  gz * 0.0075,  0.5 * Math.PI)
      const materialXRBTP = new THREE.MeshStandardMaterial({ map: textureXRBTP, metalness: 0, roughness: 1 })
      // mesh
      const ringbalkTussenPaal = new Brush( geometryRBTP, [
        materialYRBTP,
        materialYRBTP,
        materialXRBTP,
        materialXRBTP,
        materialXRBTP,
        materialXRBTP,
      ]);
      ringbalkTussenPaal.castShadow = true;
      ringbalkTussenPaal.receiveShadow = true;
      ringbalkTussenPaal.position.y = y - verticalePositie + rbtpy/2
      ringbalkTussenPaal.position.x = breedte/2 + xBovVo
      ringbalkTussenPaal.position.z = rbgz/2
      ringbalkTussenPaal.rotation.y = 0.5 * Math.PI
      this.ringbalkTussenPaal = ringbalkTussenPaal



      // console.log({
      //   gy: gy, // 120 a
      //   kx: kx, // 220 b
      //   "kx - kxo": kx - kxo, // 160 c
      //   "gy - kyo": gy - kyo, // 50 d
      // })

      // const coordinatesList = [
      //   new this.THREE.Vector3(0, gy, 0),
      //   new this.THREE.Vector3(kx, gy, 0),
      //   new this.THREE.Vector3(kx, 0, 0),
      //   new this.THREE.Vector3(kx - kxo, 0, 0),
      //   new this.THREE.Vector3(0, gy - kyo, 0),
      // ];

      // const extrudeSettings = {
      //   steps: 1,
      //   depth: gz, // 45 e
      //   bevelEnabled: false,
      // };

      // console.log(new this.THREE.Shape(coordinatesList))

      // const geometry = new this.THREE.ExtrudeGeometry( new this.THREE.Shape(coordinatesList), extrudeSettings );
      // gy = position y
      // kx = schuine gedeelte klos horizontal afstand
      // kxo = position flauwe hoek beneden
      // kyo = 
      // kyo = 
      // kyo = 

      console.log(gy, kx, kxo, kyo, gz)

      const linksGeometryKlos = new this.THREE.BufferGeometry()
      linksGeometryKlos.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferKlos(yHooLi, xBovLi+erOffset, xOndLi, yKopLi, gz)), 3))
      linksGeometryKlos.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferKlos(yHooLi, xBovLi+erOffset, xOndLi, yKopLi, gz)), 2))
      linksGeometryKlos.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferKlos()), 3))

      const rechtsGeometryKlos = new this.THREE.BufferGeometry()
      rechtsGeometryKlos.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferKlos(yHooRe, xBovRe+erOffset, xOndRe, yKopRe, gz)), 3))
      rechtsGeometryKlos.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferKlos(yHooRe, xBovRe+erOffset, xOndRe, yKopRe, gz)), 2))
      rechtsGeometryKlos.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferKlos()), 3))

      const textureK = loadedTexture.clone()
      textureK.needsUpdate = true
      this.textureSettings(textureK, breedte / 2330 / breedte , gy / 145 / gy)
      const materialK = new THREE.MeshStandardMaterial({ map: textureK, metalness: 0, roughness: 1 })

      const linksKlos = new this.THREE.Mesh( linksGeometryKlos, materialK );
      linksKlos.rotation.y = 1.5 * Math.PI
      linksKlos.position.y = gy-yHooLi
      linksKlos.position.x = gz + xBovVo 
      linksKlos.position.z = - xBovLi
      linksKlos.castShadow = true; 
      linksKlos.receiveShadow = true;
      this.linksKlos = linksKlos

      const rechtsKlos = new this.THREE.Mesh( rechtsGeometryKlos, materialK );
      rechtsKlos.rotation.y = 1.5 * Math.PI
      rechtsKlos.position.y = gy-yHooRe
      rechtsKlos.position.x = gz + xBovVo
      rechtsKlos.position.z = - xBovRe
      rechtsKlos.castShadow = true; 
      rechtsKlos.receiveShadow = true;
      this.rechtsKlos = rechtsKlos

      if(round){
        const cylinderG = new this.THREE.CylinderGeometry( 1, 1, 1, 256 );
        const cylinderM = new this.THREE.MeshBasicMaterial( {color: 0xff00dc} );
        const cylinder = new this.THREE.Mesh( cylinderG, cylinderM );
        console.log(kx-kxo, gy-kyo, 500)

        cylinder.scale.set(gy-yKopLi, 500, xBovLi-xOndLi)
        cylinder.rotation.z=Math.PI/2
        cylinder.position.y=0.5
        cylinder.position.x=200
        cylinder.position.z=-xBovLi
        cylinder.updateMatrix()
        this.linksKlos.updateMatrix()
        this.linksKlos.geometry.attributes = CSG.subtract(this.linksKlos, cylinder).geometry.attributes

        cylinder.scale.set(gy-yKopRe, 500, xBovRe-xOndRe)
        cylinder.rotation.z=Math.PI/2
        cylinder.position.y=0.5
        cylinder.position.x=200
        cylinder.position.z=-xBovRe
        cylinder.updateMatrix()
        this.rechtsKlos.updateMatrix()
        this.rechtsKlos.geometry.attributes = CSG.subtract(this.rechtsKlos, cylinder).geometry.attributes

        cylinder.geometry.dispose();
        cylinder.material.dispose();
      }
      
    } else if (this.modelType.isZadeldak || this.modelType.isKapschuur) {
      const texture0 = loadedTexture.clone()
      texture0.needsUpdate = true
      this.textureSettings(texture0, 1, 1, 0, Math.random())
      const material0 = new THREE.MeshStandardMaterial({ map: texture0, metalness: 0, roughness: 1 })

      const topPosition = ((breedte/2+ox)*Math.tan(graden * (Math.PI/180))) + oy
      const topPositionPaal = topPosition - (Math.tan(graden * (Math.PI/180))*(rbgz/2))

      let schuineGordingGeometry = new THREE.BufferGeometry()
      let schuineGordingGeometry2 = new THREE.BufferGeometry()
      if(this.modelType.isMonaco || this.modelType.isLissabon) {
        schuineGordingGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer((breedte-rbgz)/2, rbgy, rbgz, graden)), 3))
        schuineGordingGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer((breedte-rbgz)/2, rbgy, rbgz, graden)), 2))
        schuineGordingGeometry.setIndex(schuineGording.indexBuffer());
        schuineGordingGeometry = schuineGordingGeometry.toNonIndexed()

        if(this.modelType.isLissabon) {
          schuineGordingGeometry2.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(z-this.kapschuurConfig.topX-rbgz/2, rbgy, rbgz, graden)), 3))
          schuineGordingGeometry2.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(z-this.kapschuurConfig.topX-rbgz/2, rbgy, rbgz, graden)), 2))
          schuineGordingGeometry2.setIndex(schuineGording.indexBuffer());
          schuineGordingGeometry2 = schuineGordingGeometry2.toNonIndexed()
        } else {
          schuineGordingGeometry2.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer((breedte-rbgz)/2, rbgy, rbgz, graden)), 3))
          schuineGordingGeometry2.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer((breedte-rbgz)/2, rbgy, rbgz, graden)), 2))
          schuineGordingGeometry2.setIndex(schuineGording.indexBuffer());
          schuineGordingGeometry2 = schuineGordingGeometry2.toNonIndexed()
        }
      } else if(this.modelType.isMilaan || this.modelType.isStockholm) {
        schuineGordingGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(breedte/2, rbgy, rbgz, graden, ox, oy, oyl)), 3))
        schuineGordingGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(breedte/2, rbgy, rbgz, graden, ox, oy, oyl)), 2))

        if(this.modelType.isStockholm) {
          schuineGordingGeometry2.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(z-this.kapschuurConfig.topX, rbgy, rbgz, graden, ox, oy, oyl)), 3))
          schuineGordingGeometry2.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(z-this.kapschuurConfig.topX, rbgy, rbgz, graden, ox, oy, oyl)), 2))
        } else {
          schuineGordingGeometry2.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(breedte/2, rbgy, rbgz, graden, ox, oy, oyl)), 3))
          schuineGordingGeometry2.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(breedte/2, rbgy, rbgz, graden, ox, oy, oyl)), 2))
        }    
      }
      schuineGordingGeometry.computeVertexNormals()
      schuineGordingGeometry2.computeVertexNormals()
      const schuineGordingMesh = new THREE.Mesh(schuineGordingGeometry, material0)
      if(this.modelType.isMonaco || this.modelType.isLissabon) {
        schuineGordingMesh.position.set(breedte/2-rbgz/2,topPositionPaal,rbgz/2+1)
      } else if(this.modelType.isMilaan || this.modelType.isStockholm) {
        schuineGordingMesh.position.set(breedte/2,topPosition,rbgz/2+1)
      }
      schuineGordingMesh.castShadow = true; 
      schuineGordingMesh.receiveShadow = true;
      schuineGordingMesh.name = "schuineGordingMesh"
      const schuineGordingMesh2 = new THREE.Mesh(schuineGordingGeometry2, material0)
      if(this.modelType.isMonaco || this.modelType.isLissabon) {
        schuineGordingMesh2.position.set(breedte/2-rbgz/2,topPositionPaal,rbgz/2+1)
      } else if(this.modelType.isMilaan || this.modelType.isStockholm) {
        schuineGordingMesh2.position.set(breedte/2,topPosition,rbgz/2+1)
      }
      schuineGordingMesh2.castShadow = true; 
      schuineGordingMesh2.receiveShadow = true;
      schuineGordingMesh2.name = "schuineGordingMesh2"
      schuineGordingMesh2.rotation.y=Math.PI
      if(this.modelType.isMonaco || this.modelType.isLissabon) {
        schuineGordingMesh2.position.x=breedte/2+rbgz/2
      } else if(this.modelType.isMilaan || this.modelType.isStockholm) {
        schuineGordingMesh2.position.x=breedte/2
      }

      const radians = graden * (Math.PI/180);
      const r1 = (90-graden) * (Math.PI/180);
      let h;
      if(this.modelType.isMonaco || this.modelType.isLissabon) {
        h = ((breedte-rbgz)/2)*Math.tan(radians)
      } else if(this.modelType.isMilaan || this.modelType.isStockholm) {
        h = topPosition - (rbgy/(Math.sin(r1))) - ((rbtpy/2)*Math.tan(radians))
      }
      const dakPaalGeometry = new THREE.BufferGeometry()
      dakPaalGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(dakPaal.positionBuffer(rbtpz, h, rbgz, graden)), 3))
      dakPaalGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(dakPaal.uvBuffer(rbtpz, h, rbgz, graden)), 2))
      //dakPaalGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(dakPaal.normalBuffer()), 3))
      dakPaalGeometry.computeVertexNormals()
      const dakPaalMesh = new THREE.Mesh(dakPaalGeometry, material0)
      dakPaalMesh.position.set(breedte/2,0,rbgz/2+1)
      dakPaalMesh.castShadow = true; 
      dakPaalMesh.receiveShadow = true;
      dakPaalMesh.name = "dakPaalMesh"

      zadelDakPaal = new THREE.Group()
      zadelDakPaal.position.y = rbtpy
      
      zadelDakPaal.add(schuineGordingMesh, schuineGordingMesh2, dakPaalMesh)

      if(this.modelType.isMilaan || this.modelType.isStockholm) {


        const schuineLijn = Math.sin(gsh * (Math.PI/180)) * gso
        const yCoordinate = Math.cos(gsh * (Math.PI/180)) * schuineLijn
        const xCoordinate = Math.sin(gsh * (Math.PI/180)) * schuineLijn

        const gordingSchoorZijdeC = breedte/2 - rbtpz/2
        const gordingSchoorHoekGamma = 180 - gsh - graden

        const gordingSchoorHoekGammaRad = gordingSchoorHoekGamma * (Math.PI/180)
        const gradenRad = graden * (Math.PI/180)
 
        const gordingSchoorZijdeA = (gordingSchoorZijdeC/Math.sin(gordingSchoorHoekGammaRad))   *   Math.sin(gradenRad)

        const g_gordingSchoor = new THREE.BufferGeometry()
        g_gordingSchoor.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBufferScaled(gordingSchoorZijdeA, rbgy, rbgz-2)), 3))
        g_gordingSchoor.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBufferScaled(gordingSchoorZijdeA, rbgy, rbgz-2)), 2))
        g_gordingSchoor.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
        g_gordingSchoor.setIndex(boxBuffer.indexBuffer());
        g_gordingSchoor.toNonIndexed()
        console.log(g_gordingSchoor.attributes.position.array)
        g_gordingSchoor.groups = boxBuffer.groupBuffer()
        const gordingSchoor = new THREE.Mesh(g_gordingSchoor, material0)
        gordingSchoor.castShadow = true;
        gordingSchoor.receiveShadow = true;
        gordingSchoor.name = "gordingSchoorLeft"
        gordingSchoor.position.x = breedte/2 + rbtpz/2 + xCoordinate
        gordingSchoor.position.y = -yCoordinate
        gordingSchoor.position.z = rbgz/2+1
        gordingSchoor.rotation.z = gsh * (Math.PI/180)

        const gordingSchoor2 = gordingSchoor.clone()
        gordingSchoor2.name = "gordingSchoorRight"
        gordingSchoor2.rotation.y = Math.PI
        gordingSchoor2.position.x = breedte/2 - rbtpz/2 - xCoordinate

        zadelDakPaal.add(gordingSchoor, gordingSchoor2)

        if(this.modelType.isStockholm) {
          const extraBalkjeGeometry = new THREE.BufferGeometry()
          extraBalkjeGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBufferScaled((y-this.kapschuurConfig.achtergevelHoogte-dy)/Math.sin(graden * (Math.PI/180))+(this.kapschuurConfig.extraBalkjeDikte/Math.tan(graden * (Math.PI/180))), this.kapschuurConfig.extraBalkjeDikte, rbgz)), 3))
          extraBalkjeGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBufferScaled((y-this.kapschuurConfig.achtergevelHoogte-dy)/Math.sin(graden * (Math.PI/180))+(this.kapschuurConfig.extraBalkjeDikte/Math.tan(graden * (Math.PI/180))), this.kapschuurConfig.extraBalkjeDikte, rbgz)), 2))
          extraBalkjeGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
          extraBalkjeGeometry.setIndex(boxBuffer.indexBuffer());
          extraBalkjeGeometry.toNonIndexed()
          // extraBalkjeGeometry.translate(0, 0, rbgz/2+1)

          const extraBalkje = new THREE.Mesh(extraBalkjeGeometry, material0)
          extraBalkje.castShadow = true;
          extraBalkje.receiveShadow = true;
          extraBalkje.name = "extraBalkje"

          extraBalkje.position.y = -dy
          extraBalkje.position.x = ringBalkBreedteKapschuur-(rbgy/Math.sin(graden * (Math.PI/180))) - (this.kapschuurConfig.extraBalkjeDikte/Math.sin(graden * (Math.PI/180)))
          extraBalkje.position.z = rbgz/2+1
          extraBalkje.rotation.z = -graden * (Math.PI/180)

          zadelDakPaal.add(extraBalkje)
        }



      }

      gordingBalk = zadelDakPaal


      const balkB0 = zadelDakPaal.clone()
      for(let i=0; i<balkB0.children.length; i++) {
        balkB0.children[i].material = balkB0.children[i].material.clone()
        let zadelDakTexture0 = balkB0.children[i].material.map.clone()
        this.textureSettings(zadelDakTexture0, 1, 1, 0, Math.random())
        balkB0.children[i].material.map = zadelDakTexture0
        balkB0.children[i].material.map.needsUpdate = true
        balkB0.children[i].material.needsUpdate = true
      }

      const balkB1 = zadelDakPaal.clone()
      for(let i=0; i<balkB1.children.length; i++) {
        balkB1.children[i].material = balkB1.children[i].material.clone()
        let zadelDakTexture0 = balkB1.children[i].material.map.clone()
        this.textureSettings(zadelDakTexture0, 1, 1, 0, Math.random())
        balkB1.children[i].material.map = zadelDakTexture0
        balkB1.children[i].material.map.needsUpdate = true
        balkB1.children[i].material.needsUpdate = true
      }

      balkB0.position.z = 0.25
      balkB1.position.z = x - rbgz - 1.25 

      bGroup.add(balkB0)
      bGroup.add(balkB1)


      zadelDakPaal.getObjectByName('schuineGordingMesh').position.z = rbtpz/2
      zadelDakPaal.getObjectByName('schuineGordingMesh2').position.z = rbtpz/2
      zadelDakPaal.getObjectByName('dakPaalMesh').position.z = rbtpz/2
      if(zadelDakPaal.getObjectByName('gordingSchoorLeft')) {zadelDakPaal.getObjectByName('gordingSchoorLeft').position.z = rbtpz/2}
      if(zadelDakPaal.getObjectByName('gordingSchoorRight')) {zadelDakPaal.getObjectByName('gordingSchoorRight').position.z = rbtpz/2}
      if(zadelDakPaal.getObjectByName('extraBalkje')) {zadelDakPaal.getObjectByName('extraBalkje').position.z = rbtpz/2}




      gordingBalk = new THREE.Group()
      gordingBalk.position.y = gy
      let schuineGordingGeometryTussen = new THREE.BufferGeometry()
      let schuineGordingGeometryTussen2 = new THREE.BufferGeometry()
      if(this.modelType.isMonaco || this.modelType.isLissabon) {
        schuineGordingGeometryTussen.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(breedte/2, gy, gz, graden)), 3))
        schuineGordingGeometryTussen.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(breedte/2, gy, gz, graden)), 2))
        schuineGordingGeometryTussen.setIndex(schuineGording.indexBuffer());
        schuineGordingGeometryTussen = schuineGordingGeometryTussen.toNonIndexed()

        if(this.modelType.isLissabon) {
          schuineGordingGeometryTussen2.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(z-this.kapschuurConfig.topX, rbgy, gz, graden)), 3))
          schuineGordingGeometryTussen2.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(z-this.kapschuurConfig.topX, rbgy, gz, graden)), 2))
          schuineGordingGeometryTussen2.setIndex(schuineGording.indexBuffer());
          schuineGordingGeometryTussen2 = schuineGordingGeometryTussen2.toNonIndexed()
        } else {
          schuineGordingGeometryTussen2.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(breedte/2, rbgy, rbgz, graden)), 3))
          schuineGordingGeometryTussen2.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(breedte/2, rbgy, rbgz, graden)), 2))
          schuineGordingGeometryTussen2.setIndex(schuineGording.indexBuffer());
          schuineGordingGeometryTussen2 = schuineGordingGeometryTussen2.toNonIndexed()
        }
      } else if(this.modelType.isMilaan || this.modelType.isStockholm) {
        schuineGordingGeometryTussen.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(breedte/2, gy, rbgz, graden, ox, oy, oyl)), 3))
        schuineGordingGeometryTussen.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(breedte/2, gy, rbgz, graden, ox, oy, oyl)), 2))

        if(this.modelType.isStockholm) {
          schuineGordingGeometryTussen2.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(z-this.kapschuurConfig.topX, gy, rbgz, graden, ox, oy, oyl)), 3))
          schuineGordingGeometryTussen2.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(z-this.kapschuurConfig.topX, gy, rbgz, graden, ox, oy, oyl)), 2))
        } else {
          schuineGordingGeometryTussen2.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(breedte/2, gy, rbgz, graden, ox, oy, oyl)), 3))
          schuineGordingGeometryTussen2.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(breedte/2, gy, rbgz, graden, ox, oy, oyl)), 2))
        }
      }
      schuineGordingGeometryTussen.computeVertexNormals()
      schuineGordingGeometryTussen2.computeVertexNormals()

      const schuineGordingMeshTussen = new THREE.Mesh(schuineGordingGeometryTussen, material0)
      schuineGordingMeshTussen.position.y=topPosition
      schuineGordingMeshTussen.position.x=breedte/2
      schuineGordingMeshTussen.castShadow = true; 
      schuineGordingMeshTussen.receiveShadow = true;
      const schuineGordingMeshTussen2 = new THREE.Mesh(schuineGordingGeometryTussen2, material0)
      schuineGordingMeshTussen2.position.y=topPosition
      schuineGordingMeshTussen2.position.x=breedte/2
      schuineGordingMeshTussen2.castShadow = true; 
      schuineGordingMeshTussen2.receiveShadow = true;
      schuineGordingMeshTussen2.rotation.y=Math.PI
      const nokhoek = new THREE.BufferGeometry()
      nokhoek.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(triangleBuffer.positionBuffer(gy, gz, graden)), 3))
      nokhoek.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(triangleBuffer.uvBuffer(gy, gz, graden, 2330, 145)), 2))
      nokhoek.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(triangleBuffer.normalBuffer()), 3))
      const nokHoekMesh = new THREE.Mesh(nokhoek, material0)
      nokHoekMesh.castShadow = true; 
      nokHoekMesh.receiveShadow = true;
      nokHoekMesh.position.set(breedte/2, topPosition - (gy/(Math.sin((90 - graden) * (Math.PI/180)))) - gy, 0)
      gordingBalk.add(schuineGordingMeshTussen, schuineGordingMeshTussen2)
      if(this.modelType.isMonaco) {
        gordingBalk.add(nokHoekMesh)
      }
      this.gordingBalk = gordingBalk

      if(this.modelType.isMilaan || this.modelType.isStockholm) {
        const k11 = schuineGordingMeshTussen.clone()
        k11.material = k11.material.clone()
        let k11Texture = k11.material.map.clone()
        this.textureSettings(k11Texture, 1, 1, 0, Math.random())
        k11.material.map = k11Texture
        k11.material.map.needsUpdate = true
        k11.material.needsUpdate = true
        const k12 = schuineGordingMeshTussen2.clone()
        let k12Texture = k12.material.map.clone()
        this.textureSettings(k12Texture, 1, 1, 0, Math.random())
        k12.material.map = k12Texture
        k12.material.map.needsUpdate = true
        k12.material.needsUpdate = true

        const k1 = new THREE.Group()
        k1.add(k11, k12)
        k1.position.z = -overhang +rbgz/2 +1
        const k2 = new THREE.Group()
        k2.position.z = x+overhang -rbgz/2 -1
        k2.add(schuineGordingMeshTussen.clone(), schuineGordingMeshTussen2.clone())
        kGroup.add(k1, k2)
        kGroup.position.y = rbtpy



        // zijplanken op ringbalk
        const zijplankDikte = 25
        const lengteVanPlankInRingBalk = Math.tan(radians)*zijplankDikte
        const offsetY = Math.cos(radians) * lengteVanPlankInRingBalk
        const offsetX = Math.sin(radians) * lengteVanPlankInRingBalk
        const posY = rbgy - offsetY
        const posX = offsetX

        //find distance between point and line
        const distance = (x1, y1, x2, y2, x3, y3) => {
          const num = Math.abs((y2 - y1) * x3 - (x2 - x1) * y3 + x2 * y1 - y2 * x1)
          const den = Math.sqrt((y2 - y1) * (y2 - y1) + (x2 - x1) * (x2 - x1))
          return num / den
        }
        const startPoint = {x: -ox, y: rby+oy}
        const endPoint = {x: breedte/2, y: topPosition+rby}

        console.log(radians)
        const zijplankDistance = distance(startPoint.x, startPoint.y, endPoint.x, endPoint.y, posX, posY)
        
        const g_zijplank = new THREE.BufferGeometry()
        g_zijplank.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBufferScaled(x+2*overhang-2*gz, zijplankDistance, zijplankDikte)), 3))
        g_zijplank.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBufferScaled(x, zijplankDistance, zijplankDikte)), 2))
        g_zijplank.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
        g_zijplank.setIndex(boxBuffer.indexBuffer());
        g_zijplank.toNonIndexed()
        g_zijplank.groups = boxBuffer.groupBuffer()
        g_zijplank.translate(0, 0, -zijplankDikte/2)
        const zijplank = new THREE.Mesh(g_zijplank, material0)
        zijplank.castShadow = true;
        zijplank.receiveShadow = true;
        zijplank.name = "zijplank"
        const zijplank2 = zijplank.clone()
        zijplank2.name = "zijplank2"
        //zijplank.rotation.y = 1.5*Math.PI
        //zijplank.rotation.x = 0.25*Math.PI
        //zijplank.rotation.y = 0.5*Math.PI
        zijplank.rotateY(1.5*Math.PI);
        zijplank.rotateX(radians);
        zijplank.position.z = -overhang +gz
        zijplank.position.y = posY
        zijplank.position.x = posX
        
        zijplank2.rotateY(0.5*Math.PI);
        zijplank2.rotateX(radians);
        zijplank2.position.x = breedte-posX + (this.modelType.isStockholm ? (z-(2*this.kapschuurConfig.topX)) : 0)
        zijplank2.position.y = this.modelType.isStockholm ? posY - (y-this.kapschuurConfig.achtergevelHoogte) : posY  
        zijplank2.position.z = x+overhang -gz

        tpGroup.add(zijplank, zijplank2)
      }


      const nokGordingTexture = loadedTexture.clone()
      nokGordingTexture.needsUpdate = true
      this.textureSettings(nokGordingTexture, 1, 1, Math.PI*0.5, Math.random())
      const nokGordingMaterial = new THREE.MeshStandardMaterial({ map: nokGordingTexture, metalness: 0, roughness: 1 })
      const nokGordingGeometry = new THREE.BufferGeometry()
      const p = 135-((gz/2)*Math.tan(radians))
      const q = p+(gz/2)*Math.tan(radians)
      const nokGordingWidth = (this.modelType.isMonaco || this.modelType.isLissabon) ? x-(rbgz*2) : x+(overhang*2) + 2
      nokGordingGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(nokGording.positionBuffer(gz, p, nokGordingWidth, graden)), 3))
      nokGordingGeometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(nokGording.uvBuffer(gz, p, nokGordingWidth, graden)), 2))
      nokGordingGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(nokGording.normalBuffer()), 3))
      const nokGordingMesh = new THREE.Mesh(nokGordingGeometry, nokGordingMaterial)
      nokGordingMesh.position.set(breedte/2, topPosition + gy - q + 1, x/2)
      nokGordingMesh.castShadow = true; 
      nokGordingMesh.receiveShadow = true;
      ngGroup.add(nokGordingMesh)
    }

    console.log(staanders, division, staanderGrootte)
    
    let positionOffset = staanderGrootte.dx/2
    for (let i = division.length-1; i > 0; i--) {
      positionOffset -= (division[i].width + staanderGrootte.dx)
      // console.log(verticalePositie, (y + rbgy))
      let balk = ringBalkGording.clone()
      if(this.modelType.isKapschuur) {
        this.setMaterial(balk, 1 , 1, null, Math.random())
      } else {
        this.setMaterial(balk, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
      }

      // make holes in gordingen for lichtstraten
      this.gatMaterial.map = loadedTexture.clone()
      this.textureSettings(this.gatMaterial.map, 1, 1000/145, 0, 0, 0.5)
      if(this.modelType.isPlatdak) {
        if (this.lichtstraten.length > 0) {
          let lichtstraatInfo = getLichtstraatInfo(this.lichtstraten[0].code)
          const safeMargin = 10
          const lichtstraatGatGeometry = new THREE.BoxGeometry(
            lichtstraatInfo.dimensions.z - lichtstraatInfo.margins.zNeg2 - lichtstraatInfo.margins.zPos2 + safeMargin,
            1000,
            lichtstraatInfo.dimensions.x - lichtstraatInfo.margins.xNeg2 - lichtstraatInfo.margins.xPos2 + safeMargin
          )
          let lichtstraatGat = new Brush(lichtstraatGatGeometry, this.gatMaterial)
          lichtstraatGat.position.x = this.lichtstraten[0].positionFront + lichtstraatInfo.dimensions.z / 2 + this.staanderGrootte.dx - lichtstraatInfo.margins.zNeg2 +xBovVo -lichtstraatInfo.margins.zNeg1/2+lichtstraatInfo.margins.zPos1/2
          lichtstraatGat.position.z = this.lichtstraten[0].positionLeft + lichtstraatInfo.dimensions.x / 2 + this.staanderGrootte.dx - lichtstraatInfo.margins.xNeg2
          for (let i = 1; i < this.lichtstraten.length; i++) {
            let lichtstraatInfo = getLichtstraatInfo(this.lichtstraten[i].code)
            const newLichtstraatGatGeometry = new THREE.BoxGeometry(
              lichtstraatInfo.dimensions.z - lichtstraatInfo.margins.zNeg2 - lichtstraatInfo.margins.zPos2 + safeMargin,
              1000,
              lichtstraatInfo.dimensions.x - lichtstraatInfo.margins.xNeg2 - lichtstraatInfo.margins.xPos2 + safeMargin
            )
            const newLichtstraatGat = new Brush(newLichtstraatGatGeometry, this.gatMaterial)
            newLichtstraatGat.position.x = this.lichtstraten[i].positionFront + lichtstraatInfo.dimensions.z / 2 + this.staanderGrootte.dx - lichtstraatInfo.margins.zNeg2 +xBovVo -lichtstraatInfo.margins.zNeg1/2+lichtstraatInfo.margins.zPos1/2
            newLichtstraatGat.position.z = this.lichtstraten[i].positionLeft + lichtstraatInfo.dimensions.x / 2 + this.staanderGrootte.dx - lichtstraatInfo.margins.xNeg2
            lichtstraatGat.updateMatrixWorld()
            newLichtstraatGat.updateMatrixWorld()
            lichtstraatGat = this.evaluator.evaluate(lichtstraatGat, newLichtstraatGat, ADDITION)
          }
          lichtstraatGat.position.z -= (positionOffset + staanders.position.z - (rbgz / 2))
          balk.updateMatrixWorld()
          lichtstraatGat.updateMatrixWorld()
          balk = this.evaluator.evaluate(balk, lichtstraatGat, SUBTRACTION)
        }
      }

      const toAddToGroup = new THREE.Group()
      toAddToGroup.position.z = positionOffset + staanders.position.z - (rbgz / 2)
      toAddToGroup.add(balk)
      if(this.modelType.isZadeldak || this.modelType.isKapschuur) {
        toAddToGroup.position.z = positionOffset + staanders.position.z - (rbtpz / 2)
        const zadelDak = zadelDakPaal.clone()
        for(let i=0; i<zadelDak.children.length; i++) {
          zadelDak.children[i].material = zadelDak.children[i].material.clone()
          let zadelDakTexture0 = zadelDak.children[i].material.map.clone()
          this.textureSettings(zadelDakTexture0, 1, 1, 0, Math.random())
          zadelDak.children[i].material.map = zadelDakTexture0
          zadelDak.children[i].material.map.needsUpdate = true
          zadelDak.children[i].material.needsUpdate = true
        }
        toAddToGroup.add(zadelDak)
      }
      if (this.modelType.isPlatdak && rbtp.enabled) {
        let ringbalkTussenPaal = this.ringbalkTussenPaal.clone()

        // make holes in ringbalk tussen paal for lichtstraten (alleen rome & bergamo)
        if (this.lichtstraten.length > 0) {
          let lichtstraatInfo = getLichtstraatInfo(this.lichtstraten[0].code)
          const safeMargin = 1
          const lichtstraatGatGeometry = new THREE.BoxGeometry(
            lichtstraatInfo.dimensions.z - lichtstraatInfo.margins.zNeg2 - lichtstraatInfo.margins.zPos2 + safeMargin,
            1000,
            lichtstraatInfo.dimensions.x - lichtstraatInfo.margins.xNeg2 - lichtstraatInfo.margins.xPos2 + safeMargin
          )
          let lichtstraatGat = new Brush(lichtstraatGatGeometry, this.gatMaterial)
          lichtstraatGat.position.x = this.lichtstraten[0].positionFront + lichtstraatInfo.dimensions.z / 2 + this.staanderGrootte.dx - lichtstraatInfo.margins.zNeg2 +xBovVo -lichtstraatInfo.margins.zNeg1/2+lichtstraatInfo.margins.zPos1/2
          lichtstraatGat.position.z = this.lichtstraten[0].positionLeft + lichtstraatInfo.dimensions.x / 2 + this.staanderGrootte.dx - lichtstraatInfo.margins.xNeg2 +rbgz/2
          for (let i = 1; i < this.lichtstraten.length; i++) {
            let lichtstraatInfo = getLichtstraatInfo(this.lichtstraten[i].code)
            const newLichtstraatGatGeometry = new THREE.BoxGeometry(
              lichtstraatInfo.dimensions.z - lichtstraatInfo.margins.zNeg2 - lichtstraatInfo.margins.zPos2 + safeMargin,
              1000,
              lichtstraatInfo.dimensions.x - lichtstraatInfo.margins.xNeg2 - lichtstraatInfo.margins.xPos2 + safeMargin
            )
            const newLichtstraatGat = new Brush(newLichtstraatGatGeometry, this.gatMaterial)
            newLichtstraatGat.position.x = this.lichtstraten[i].positionFront + lichtstraatInfo.dimensions.z / 2 + this.staanderGrootte.dx - lichtstraatInfo.margins.zNeg2 +xBovVo -lichtstraatInfo.margins.zNeg1/2+lichtstraatInfo.margins.zPos1/2
            newLichtstraatGat.position.z = this.lichtstraten[i].positionLeft + lichtstraatInfo.dimensions.x / 2 + this.staanderGrootte.dx - lichtstraatInfo.margins.xNeg2 +rbgz/2
            lichtstraatGat.updateMatrixWorld()
            newLichtstraatGat.updateMatrixWorld()
            lichtstraatGat = this.evaluator.evaluate(lichtstraatGat, newLichtstraatGat, ADDITION)
          }
          lichtstraatGat.position.z -= (positionOffset + staanders.position.z)
          ringbalkTussenPaal.updateMatrixWorld()
          lichtstraatGat.updateMatrixWorld()
          ringbalkTussenPaal = this.evaluator.evaluate(ringbalkTussenPaal, lichtstraatGat, SUBTRACTION)
        }

        toAddToGroup.add(ringbalkTussenPaal)
      }

      tGroup.add(toAddToGroup)
      
      // gording bij paal
      if(verticalePositie == (y + rbgy) && this.modelType.isPlatdak) {
        let obalk = onderBalk.clone()
        obalk.position.x = breedte / 2 + kx

        // make holes in onderbalk for lichtstraten (alleen parijs momenteel)
        if (this.lichtstraten.length > 0) {
          let lichtstraatInfo = getLichtstraatInfo(this.lichtstraten[0].code)
          const safeMargin = 0
          const lichtstraatGatGeometry = new THREE.BoxGeometry(
            lichtstraatInfo.dimensions.z - lichtstraatInfo.margins.zNeg2 - lichtstraatInfo.margins.zPos2 + safeMargin,
            1000,
            lichtstraatInfo.dimensions.x - lichtstraatInfo.margins.xNeg2 - lichtstraatInfo.margins.xPos2 + safeMargin
          )
          let lichtstraatGat = new Brush(lichtstraatGatGeometry, this.gatMaterial)
          lichtstraatGat.position.x = this.lichtstraten[0].positionFront + lichtstraatInfo.dimensions.z / 2 + this.staanderGrootte.dx - lichtstraatInfo.margins.zNeg2 +xBovVo -lichtstraatInfo.margins.zNeg1/2+lichtstraatInfo.margins.zPos1/2
          lichtstraatGat.position.z = this.lichtstraten[0].positionLeft + lichtstraatInfo.dimensions.x / 2 + this.staanderGrootte.dx - lichtstraatInfo.margins.xNeg2
          for (let i = 1; i < this.lichtstraten.length; i++) {
            let lichtstraatInfo = getLichtstraatInfo(this.lichtstraten[i].code)
            const newLichtstraatGatGeometry = new THREE.BoxGeometry(
              lichtstraatInfo.dimensions.z - lichtstraatInfo.margins.zNeg2 - lichtstraatInfo.margins.zPos2 + safeMargin,
              1000,
              lichtstraatInfo.dimensions.x - lichtstraatInfo.margins.xNeg2 - lichtstraatInfo.margins.xPos2 + safeMargin
            )
            const newLichtstraatGat = new Brush(newLichtstraatGatGeometry, this.gatMaterial)
            newLichtstraatGat.position.x = this.lichtstraten[i].positionFront + lichtstraatInfo.dimensions.z / 2 + this.staanderGrootte.dx - lichtstraatInfo.margins.zNeg2 +xBovVo -lichtstraatInfo.margins.zNeg1/2+lichtstraatInfo.margins.zPos1/2
            newLichtstraatGat.position.z = this.lichtstraten[i].positionLeft + lichtstraatInfo.dimensions.x / 2 + this.staanderGrootte.dx - lichtstraatInfo.margins.xNeg2
            lichtstraatGat.updateMatrixWorld()
            newLichtstraatGat.updateMatrixWorld()
            lichtstraatGat = this.evaluator.evaluate(lichtstraatGat, newLichtstraatGat, ADDITION)
          }
          lichtstraatGat.position.z -= (positionOffset + staanders.position.z)
          obalk.updateMatrixWorld()
          lichtstraatGat.updateMatrixWorld()
          obalk = this.evaluator.evaluate(obalk, lichtstraatGat, SUBTRACTION)
        }

        this.setMaterial(balk, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
        obalk.position.z = positionOffset + staanders.position.z
        oGroup.add(obalk)
      }
      // ringBalkGording bij paal
    }

    // tt, grodingen tussen de vaste staander gordingen
    let offset = staanderGrootte.dx
    // console.log(tGroup.children.length + 1, nBlak)
    for (let n = 0; n < tGroup.children.length + 1; n++) {

      const space = division[n].width
      const nBlak = Math.ceil((space+gz) / (this.ttOverspanning+gz)) - 1

      //alert(nBlak + ' ' + space + ' ' + n)

      for(let i = 0; i < nBlak; i++) {
        console.log(nBlak)
        let balk = gordingBalk.clone()
        if(this.modelType.isPlatdak) {
          this.setMaterial(balk, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
        } else {
          for(let i=0; i<balk.children.length; i++) {
            balk.children[i].material = balk.children[i].material.clone()
            let balkTexture = balk.children[i].material.map.clone()
            this.textureSettings(balkTexture, 1, 1, 0, Math.random())
            balk.children[i].material.map = balkTexture
            balk.children[i].material.map.needsUpdate = true
            balk.children[i].material.needsUpdate = true
          }
        }
        // console.log(space , (nBlak + 1) , i , n , nBlak , offset , gz/2)
        balk.position.z = offset + ((space-(nBlak*gz))/(nBlak+1))*(i+1) + (gz*i)

        // make holes in gordingen for lichtstraten
        if(this.modelType.isPlatdak) {
          if (this.lichtstraten.length > 0) {
            let lichtstraatInfo = getLichtstraatInfo(this.lichtstraten[0].code)
            const safeMargin = 10
            const lichtstraatGatGeometry = new THREE.BoxGeometry(
              lichtstraatInfo.dimensions.z - lichtstraatInfo.margins.zNeg2 - lichtstraatInfo.margins.zPos2 + safeMargin,
              1000,
              lichtstraatInfo.dimensions.x - lichtstraatInfo.margins.xNeg2 - lichtstraatInfo.margins.xPos2 + safeMargin
            )
            let lichtstraatGat = new Brush(lichtstraatGatGeometry, this.gatMaterial)
            lichtstraatGat.position.x = this.lichtstraten[0].positionFront + lichtstraatInfo.dimensions.z / 2 + this.staanderGrootte.dx - lichtstraatInfo.margins.zNeg2 +xBovVo -lichtstraatInfo.margins.zNeg1/2+lichtstraatInfo.margins.zPos1/2
            lichtstraatGat.position.z = this.lichtstraten[0].positionLeft + lichtstraatInfo.dimensions.x / 2 + this.staanderGrootte.dx - lichtstraatInfo.margins.xNeg2
            for (let i = 1; i < this.lichtstraten.length; i++) {
              let lichtstraatInfo = getLichtstraatInfo(this.lichtstraten[i].code)
              const newLichtstraatGatGeometry = new THREE.BoxGeometry(
                lichtstraatInfo.dimensions.z - lichtstraatInfo.margins.zNeg2 - lichtstraatInfo.margins.zPos2 + safeMargin,
                1000,
                lichtstraatInfo.dimensions.x - lichtstraatInfo.margins.xNeg2 - lichtstraatInfo.margins.xPos2 + safeMargin
              )
              const newLichtstraatGat = new Brush(newLichtstraatGatGeometry, this.gatMaterial)
              newLichtstraatGat.position.x = this.lichtstraten[i].positionFront + lichtstraatInfo.dimensions.z / 2 + this.staanderGrootte.dx - lichtstraatInfo.margins.zNeg2 +xBovVo -lichtstraatInfo.margins.zNeg1/2+lichtstraatInfo.margins.zPos1/2
              newLichtstraatGat.position.z = this.lichtstraten[i].positionLeft + lichtstraatInfo.dimensions.x / 2 + this.staanderGrootte.dx - lichtstraatInfo.margins.xNeg2
              lichtstraatGat.updateMatrixWorld()
              newLichtstraatGat.updateMatrixWorld()
              lichtstraatGat = this.evaluator.evaluate(lichtstraatGat, newLichtstraatGat, ADDITION)
            }
            balk.updateMatrixWorld()
            lichtstraatGat.updateMatrixWorld()
            balk = this.evaluator.evaluate(balk, lichtstraatGat, SUBTRACTION)
          }
        }

        //alert(space/(nBlak+1)*i)
        ttGroup.add(balk)

      }

      offset += space + staanderGrootte.dx
    }


    // klosssen
    if(this.modelType.isPlatdak) {
      const linksGeometryHoekKlos = new this.THREE.BufferGeometry()
      linksGeometryHoekKlos.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferKlos(yHooLi, xBovLi, xOndLi, yKopLi, zDikPaal)), 3))
      linksGeometryHoekKlos.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferKlos(yHooLi, xBovLi, xOndLi, yKopLi, zDikPaal)), 2))
      linksGeometryHoekKlos.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferKlos()), 3))

      const rechtsGeometryHoekKlos = new this.THREE.BufferGeometry()
      rechtsGeometryHoekKlos.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferKlos(yHooRe, xBovRe, xOndRe, yKopRe, zDikPaal)), 3))
      rechtsGeometryHoekKlos.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferKlos(yHooRe, xBovRe, xOndRe, yKopRe, zDikPaal)), 2))
      rechtsGeometryHoekKlos.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferKlos()), 3))
      
      const textureHK = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`)
      this.textureSettings(textureHK, breedte / 2330 / breedte , gy / 145 / gy)
      const materialHK = new this.THREE.MeshStandardMaterial({ map: textureHK, metalness: 0, roughness: 1 })

      const linksHoekKlos = new this.THREE.Mesh( linksGeometryHoekKlos, materialHK );
      linksHoekKlos.rotation.y = 1.5 * Math.PI
      linksHoekKlos.position.y = gy-yHooLi
      linksHoekKlos.position.x = gz + xBovLi
      linksHoekKlos.position.z = - xBovLi 
      linksHoekKlos.castShadow = true; 
      linksHoekKlos.receiveShadow = true;
      this.linksHoekKlos = linksHoekKlos
      
      const rechtsHoekKlos = new this.THREE.Mesh( rechtsGeometryHoekKlos, materialHK );
      rechtsHoekKlos.rotation.y = 1.5 * Math.PI
      rechtsHoekKlos.position.y = gy-yHooRe
      rechtsHoekKlos.position.x = gz + xBovRe 
      rechtsHoekKlos.position.z = - xBovRe 
      rechtsHoekKlos.castShadow = true; 
      rechtsHoekKlos.receiveShadow = true;
      this.rechtsHoekKlos = rechtsHoekKlos

       if(round){
        const cylinderG = new this.THREE.CylinderGeometry( 1, 1, 1, 256 );
        const cylinderM = new this.THREE.MeshBasicMaterial( {color: 0xff00dc} );
        const cylinder = new this.THREE.Mesh( cylinderG, cylinderM );
        console.log(kx-kxo, gy-kyo, 500)
        cylinder.scale.set(gy-yKopLi, 500, xBovLi-xOndLi)
        cylinder.rotation.z=Math.PI/2
        cylinder.position.y=0.5
        cylinder.position.x=200
        cylinder.position.z=-xBovLi
        cylinder.updateMatrix()
        linksHoekKlos.updateMatrix()
        this.hoekKlosLinks = CSG.subtract(linksHoekKlos, cylinder)

        cylinder.scale.set(gy-yKopLi, 500, xBovRe-xOndRe)
        cylinder.rotation.z=Math.PI/2
        cylinder.position.y=0.5
        cylinder.position.x=200
        cylinder.position.z=-xBovRe
        cylinder.updateMatrix()
        rechtsHoekKlos.updateMatrix()
        this.hoekKlosRechts = CSG.subtract(rechtsHoekKlos, cylinder)
        
        cylinder.geometry.dispose();
        cylinder.material.dispose();
      }

      if(!erOn) {
        const klosB0 = round ? this.hoekKlosLinks.clone() : this.linksHoekKlos.clone()
        this.setMaterial(klosB0, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
        klosB0.rotation.y = 1.5 * Math.PI
        klosB0.position.x = xBovVo + zDikPaal
        klosB0.position.z = - xBovLi 
        klosB0.visible = klosLiEnabled

        const klosB1 =  round ? this.hoekKlosLinks.clone() : this.linksHoekKlos.clone()
        this.setMaterial(klosB1, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
        klosB1.position.x = xBovVo + breedte
        klosB1.visible = klosLiEnabled

        const klosB2 = round ? this.hoekKlosRechts.clone() : this.rechtsHoekKlos.clone()
        this.setMaterial(klosB2, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
        klosB2.rotation.y = 0.5 * Math.PI
        klosB2.position.x = xBovVo
        klosB2.position.z = x + xBovRe
        klosB2.visible = klosReEnabled

        const klosB3 = round ? this.hoekKlosRechts.clone() : this.rechtsHoekKlos.clone()
        this.setMaterial(klosB3, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
        klosB3.rotation.y = 0.5 * Math.PI
        klosB3.position.x = breedte + xBovVo - zDikPaal
        klosB3.position.z = x + xBovRe
        klosB3.visible = klosReEnabled
        kbGroup.add(klosB0, klosB1, klosB2, klosB3)
      }

      // tussen klossen
      const klosSpace = breedte - (kzp * 2)
      const nklos = Math.ceil(klosSpace / this.ttOverspanning) - 1

      for(let i=0; i<nklos; i++) {
        const clonedKlosT0 = this.linksKlos.clone()
        this.setMaterial(clonedKlosT0, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
        clonedKlosT0.visible = klosLiEnabled
        kt0Group.add(clonedKlosT0)

        const clonedKlosT1 = this.rechtsKlos.clone()
        this.setMaterial(clonedKlosT1, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
        clonedKlosT1.rotation.y = 0.5 * Math.PI
        clonedKlosT1.visible = klosReEnabled
        kt1Group.add(clonedKlosT1)
      }

      this.updateTKlosPosition(nklos, klosSpace, kt0Group, kt1Group, xBovVo, xBovLi, xBovRe, gz, x, zDikPaal)
    } //klossen
    
    // group
    group.add(bGroup)
    group.add(kGroup)
    group.add(tGroup)
    group.add(oGroup)
    group.add(ttGroup)
    group.add(kbGroup)
    group.add(kt0Group)
    group.add(kt1Group)
    group.add(ngGroup)
    group.add(tpGroup)

    this.nTGroup = tGroup.children.length

    // setTimeout(() => {
    //   console.log("frameGroup", group)
    //   group.children[0].position.y = 4000
    // }, 5000);
  }

  update(x, y, z, division, gy, gz, overstekKlos, kp, gs, rbgy, rbgz, rbgp, rby, rbz, verticalePositie, staanders, round, erOn, erOffset, modelType, graden, rbtp, rbtpy, rbtpz, ox, oy, oyl, gsh, gso, overhang, staanderGrootte, kapschuurConfig, dy, dz, lichtstraten) {
    this.overstekKlos = overstekKlos
    
    let zDikPaal = this.zDikPaal = overstekKlos.zDikPaal
    let klosVoEnabled = this.klosVoEnabled = overstekKlos.enabled? overstekKlos.klosVoEnabled : false
    let xBovVo = this.xBovVo = klosVoEnabled ? overstekKlos.xBovVo : 0
    let xOndVo = this.xOndVo = klosVoEnabled ? overstekKlos.xOndVo : 0
    let yHooVo = this.yHooVo = klosVoEnabled ? overstekKlos.yHooVo : 0
    let yKopVo = this.yKopVo = klosVoEnabled ? overstekKlos.yKopVo : 0
    let klosAcEnabled = this.klosAcEnabled = overstekKlos.enabled? overstekKlos.klosAcEnabled : false
    let xBovAc = this.xBovAc = klosAcEnabled ? overstekKlos.xBovAc : 0
    let xOndAc = this.xOndAc = klosAcEnabled ? overstekKlos.xOndAc : 0
    let yHooAc = this.yHooAc = klosAcEnabled ? overstekKlos.yHooAc : 0
    let yKopAc = this.yKopAc = klosAcEnabled ? overstekKlos.yKopAc : 0
    let klosLiEnabled = this.klosLiEnabled = overstekKlos.enabled? overstekKlos.klosLiEnabled : false
    let xBovLi = this.xBovLi = klosLiEnabled ? overstekKlos.xBovLi : 0
    let xOndLi = this.xOndLi = klosLiEnabled ? overstekKlos.xOndLi : 0
    let yHooLi = this.yHooLi = klosLiEnabled ? overstekKlos.yHooLi : 0
    let yKopLi = this.yKopLi = klosLiEnabled ? overstekKlos.yKopLi : 0
    let klosReEnabled = this.klosReEnabled = overstekKlos.enabled? overstekKlos.klosReEnabled : false
    let xBovRe = this.xBovRe = klosReEnabled ? overstekKlos.xBovRe : 0
    let xOndRe = this.xOndRe = klosReEnabled ? overstekKlos.xOndRe : 0
    let yHooRe = this.yHooRe = klosReEnabled ? overstekKlos.yHooRe : 0
    let yKopRe = this.yKopRe = klosReEnabled ? overstekKlos.yKopRe : 0
    
    this.ttOverspanning = gs // afstand tussen gordingen
    this.x = x // frame
    this.y = y // frame
    this.z = z // frame
    this.division = division
    this.gy = gy // godring hoogte
    this.gz = gz // gording dikte
    let kzp = this.kzp = overstekKlos.zDikPaal // dikte paal
    let kx = this.kx = overstekKlos.xBovVo // lengte bovenkant
    let kxo = this.kxo = overstekKlos.xOndVo // lengte onderkant
    let kyo = this.kyo = overstekKlos.yKopVo // hoogte kopkant
    // let ky = this.ky = overstekKlos.yHooVo // hoogte
    this.kp = kp
    this.rbgy = rbgy // ringBalk gording hoogte
    this.rbgz = rbgz // ringBalk gording breedte
    this.rbgp = rbgp
    this.rby = rby // ringBalk hoogte
    this.rbz = rbz // ringbalk dikte
    this.verticalePositie = verticalePositie //verticalePositie van gordingen
    this.round = round
    this.staanders = staanders
    this.erOn = erOn
    this.erOffset = erOffset
    this.modelType = modelType
    this.graden = graden
    this.rbtp = rbtp
    this.rbtpy = rbtpy
    this.rbtpz = rbtpz
    this.ox = ox
    this.oy = oy
    this.oyl = oyl
    this.gsh = gsh
    this.gso = gso
    this.overhang = overhang
    this.staanderGrootte = staanderGrootte
    this.kapschuurConfig = kapschuurConfig
    this.dy = dy
    this.dz = dz
    this.lichtstraten = lichtstraten

    while(this.group.children.length > 0){
      this.group.remove(this.group.children[0]);
    }
    this.bouw(this.THREE, this.scene, this.textureLoader, this.texture, this.x, this.y, this.z, this.division, this.gy, this.gz, this.overstekKlos, this.kp, this.gs, this.rbgy, this.rbgz, this.rbgp, this.rby, this.rbz, this.verticalePositie, this.staanders, this.round, this.erOn, this.erOffset, this.graden, this.rbtp, this.rbtpy, this.rbtpz, this.ox, this.oy, this.oyl, this.gsh, this.gso, this.overhang, this.staanderGrootte, this.dy, this.dz)
    if (!this.pepernoot) return

    let gordingBalk;
    let zadelDakPaal;

    const bGroup = this.group.children[0]
    const kGroup = this.group.children[1]
    const tGroup = this.group.children[2]
    const oGroup = this.group.children[3]
    const ttGroup = this.group.children[4]
    const kbGroup = this.group.children[5]
    const kt0Group = this.group.children[6]
    const kt1Group = this.group.children[7]
    const ngGroup = this.group.children[8]
    const tpGroup = this.group.children[9]

    const breedte = this.modelType.isKapschuur ? this.kapschuurConfig.topX*2 : z
    const ringBalkBreedteKapschuur = breedte+ox+((rbtpy+oy)/Math.tan(graden*(Math.PI/180)))



    if(this.onderBalk) {
      this.onderBalk.geometry.attributes.position.array = new Float32Array(boxBuffer.positionBuffer(rbgz, rbgy, breedte - (2 * gz)))
      this.onderBalk.geometry.attributes.uv.array = new Float32Array(boxBuffer.uvBuffer(rbgz, rbgy, breedte - (2 * gz)))
      this.onderBalk.geometry.attributes.position.needsUpdate = true

      this.textureSettings(this.onderBalk.material[0].map, breedte / 2450, gy * 0.0075)
      this.onderBalk.material[0].map.needsUpdate = true
      this.onderBalk.material[0].needsUpdate = true

      this.textureSettings(this.onderBalk.material[2].map, breedte / 2450,  gz * 0.0075,  0.5 * Math.PI)
      this.onderBalk.material[2].map.needsUpdate = true
      this.onderBalk.material[2].needsUpdate = true
      if(verticalePositie == (y + rbgy)) {
        this.onderBalk.position.y = - gy / 2
      } else {
        this.onderBalk.position.y = gy / 2
      }
    }
    while(oGroup.children.length) {
      oGroup.children[0].removeFromParent()
    }

    // crraete new gordingen
    const geometry1 = new this.THREE.BufferGeometry()
    geometry1.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferGording(breedte-1, gy-0.5, gz, xBovVo, xOndVo, xBovAc, xOndAc, yHooVo, yKopVo, yHooAc, yKopAc)), 3))
    geometry1.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferGording(breedte-1, gy-0.5, gz, xBovVo, xOndVo, xBovAc, xOndAc, yHooVo, yKopVo, yHooAc, yKopAc)), 2))
    geometry1.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferGording()), 3))

    const geometry1Paal = new this.THREE.BufferGeometry()
    if(this.modelType.isKapschuur) {
      geometry1Paal.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(ringBalkBreedteKapschuur*Math.sin(graden*(Math.PI/180)), dy, dz+2, 90-graden)), 3))
      geometry1Paal.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(ringBalkBreedteKapschuur*Math.sin(graden*(Math.PI/180)), dy, dz+2, 90-graden)), 2))
      geometry1Paal.setIndex(schuineGording.indexBuffer());
      geometry1Paal.computeVertexNormals()
      geometry1Paal.translate(0, 0, -dz/2)
      geometry1Paal.rotateX(Math.PI)
    } else if (this.modelType.isZadeldak) {
      geometry1Paal.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferGording(breedte-1, this.modelType.isPlatdak ? rbgy-0.5 : rbtpy-0.5, this.modelType.isPlatdak ? rbgz-0.5 : rbtpy-0.5, xBovLi, xOndLi, xBovRe, xOndRe, yHooLi, yKopLi, yHooRe, yKopRe)), 3))
      geometry1Paal.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferGording(breedte-1, this.modelType.isPlatdak ? rbgy-0.5 : rbtpy-0.5, this.modelType.isPlatdak ? rbgz-0.5 : rbtpy-0.5, xBovLi, xOndLi, xBovRe, xOndRe, yHooLi, yKopLi, yHooRe, yKopRe)), 2))
      geometry1Paal.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferGording()), 3))
    } else {
      geometry1Paal.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferGording(breedte-1, this.modelType.isPlatdak ? rbgy-0.5 : rbtpy-0.5, this.modelType.isPlatdak ? rbgz-0.5 : rbtpy-0.5, xBovVo, xOndVo, xBovAc, xOndAc, yHooVo, yKopVo, yHooAc, yKopAc)), 3))
      // geometry1Paal.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferGording(breedte-1, this.modelType.isPlatdak ? gy-0.5 : rbtpy-0.5, this.modelType.isPlatdak ? rbgz-0.5 : rbtpy-0.5, gKLength, gKLengthO, ky, kyo)), 3))
      geometry1Paal.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferGording(breedte-1, this.modelType.isPlatdak ? rbgy-0.5 : rbtpy-0.5, this.modelType.isPlatdak ? rbgz-0.5 : rbtpy-0.5, xBovVo, xOndVo, xBovAc, xOndAc, yHooVo, yKopVo, yHooAc, yKopAc)), 2))
      geometry1Paal.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferGording()), 3))
    }

    // texture
    const texture1 = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`)
    this.textureSettings(texture1, breedte / 2330 / breedte , gy / 145 / gy)
    const material1 = new this.THREE.MeshStandardMaterial({ map: texture1, metalness: 0, roughness: 1 })

    const textureKapschuur = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`)
    this.textureSettings(textureKapschuur, 1, 1, Math.PI)
    const materialKapschuur = new this.THREE.MeshStandardMaterial({ map: textureKapschuur, metalness: 0, roughness: 1 })

    let ringBalkGordingMesh
    if(this.modelType.isKapschuur) {
      ringBalkGordingMesh = new this.THREE.Mesh( geometry1Paal, materialKapschuur );
      ringBalkGordingMesh.rotation.z = (90-graden)*Math.PI/180
    } else {
      ringBalkGordingMesh = new this.THREE.Mesh( geometry1Paal, material1 );
    }
    ringBalkGordingMesh.castShadow = true; 
    ringBalkGordingMesh.receiveShadow = true;
    ringBalkGordingMesh.position.y = rbgp- verticalePositie
    if(this.modelType.isKapschuur) {
      ringBalkGordingMesh.position.x = ringBalkBreedteKapschuur
    } else {
      ringBalkGordingMesh.position.x=this.modelType.isPlatdak ? (xBovAc+0.5)-xBovAc : 0.5
    }
    let ringBalkGording = ringBalkGordingMesh
    if(round){
      const cylinderG = new this.THREE.CylinderGeometry( 1, 1, 1, 256 );
      const cylinderM = new this.THREE.MeshBasicMaterial( {color: 0xff00dc} );
      const cylinder = new this.THREE.Mesh( cylinderG, cylinderM );
      cylinder.scale.set(kx-kxo, 500, gy-kyo)
      cylinder.rotation.x=Math.PI/2
      cylinder.position.y=0.5
      cylinder.updateMatrix()
      ringBalkGordingMesh.updateMatrix()
      ringBalkGording = CSG.subtract(ringBalkGordingMesh, cylinder)
      cylinder.position.x=breedte+kx*2
      cylinder.updateMatrix()
      ringBalkGordingMesh.updateMatrix()
      ringBalkGording = CSG.subtract(ringBalkGording, cylinder)
      cylinder.geometry.dispose();
      cylinder.material.dispose();
    }
    this.ringBalkGording = ringBalkGording


    // empty kop group (alleen milaan)
    console.log(kGroup)
    kGroup.children.forEach(kg => {
      while(kg.children.length) {
        kg.children[0].geometry.dispose();
        kg.children[0].material.dispose();
        kg.children[0].removeFromParent();
      }
    })
    //empty zijplank group
    while(tpGroup.children.length) {
      tpGroup.children[0].geometry.dispose();
      tpGroup.children[0].material.dispose();
      tpGroup.children[0].removeFromParent();
    }

    //update buitenste balken
    const bGroupLength = bGroup.children.length
    console.log(bGroup)
    if(bGroup.children.length) {
      if(bGroup.children[0].type === "Mesh") {
        for(let i=0; i<bGroupLength; i++) {
          bGroup.children[0].geometry.dispose();
          bGroup.children[0].material.dispose();
          bGroup.children[0].removeFromParent();
        }
      } else if (bGroup.children[0].type === "Group") {
        for(let i=0; i<bGroupLength; i++) {
          for(let j=0; j<bGroup.children[0].length; j++){
            bGroup.children[0].children[0].geometry.dispose();
            bGroup.children[0].children[0].material.dispose();
            bGroup.children[0].children[0].removeFromParent();
          }
          bGroup.children[0].removeFromParent();
        }
      }
    }
    
    const tGroupLength = tGroup.children.length
    if(tGroup.children[0] && tGroup.children[0].type === "Mesh") {
      for(let i=0; i<tGroupLength; i++) {
        tGroup.children[0].geometry.dispose();
        tGroup.children[0].material.dispose();
        tGroup.children[0].removeFromParent();
      }
    } else if (tGroup.children[0] && tGroup.children[0].type === "Group") {
      for(let i=0; i<tGroupLength; i++) {
        for(let j=0; j<tGroup.children[0].length; j++){
          tGroup.children[0].children[0].geometry.dispose();
          tGroup.children[0].children[0].material.dispose();
          tGroup.children[0].children[0].removeFromParent();
        }
        tGroup.children[0].removeFromParent();
      }
    }

    const ngGroupLength = ngGroup.children.length
    for(let i=0; i<ngGroupLength; i++) {
      ngGroup.children[0].geometry.dispose();
      ngGroup.children[0].material.dispose();
      ngGroup.children[0].removeFromParent();
    }


    if(this.modelType.isPlatdak) {
      const gordingBalkMesh = new this.THREE.Mesh( geometry1, material1 );
      gordingBalkMesh.castShadow = true; 
      gordingBalkMesh.receiveShadow = true;
      gordingBalkMesh.position.y=0.5
      gordingBalkMesh.position.x= (xBovAc+0.5)-xBovAc
      gordingBalk = gordingBalkMesh
      if(round){
        const cylinderG = new this.THREE.CylinderGeometry( 1, 1, 1, 256 );
        const cylinderM = new this.THREE.MeshBasicMaterial( {color: 0xff00dc} );
        const cylinder = new this.THREE.Mesh( cylinderG, cylinderM );
        cylinder.scale.set(kx-kxo, 500, gy-kyo)
        cylinder.rotation.x=Math.PI/2
        cylinder.position.y=0.5
        cylinder.updateMatrix()
        gordingBalkMesh.updateMatrix()
        gordingBalk = CSG.subtract(gordingBalkMesh, cylinder)
        cylinder.position.x=breedte+kx*2
        cylinder.updateMatrix()
        gordingBalkMesh.updateMatrix()
        gordingBalk = CSG.subtract(gordingBalk, cylinder)
        cylinder.geometry.dispose();
        cylinder.material.dispose();
      }
      this.gordingBalk = gordingBalk

      if(!this.onderBalk) {
        const geometry2 = new this.THREE.BufferGeometry()
        geometry2.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(rbgz, rbgy, breedte - (2 * gz))), 3))
        geometry2.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
        geometry2.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
        geometry2.setIndex(boxBuffer.indexBuffer());
        geometry2.groups = boxBuffer.groupBuffer()
        this.onderBalk = new this.THREE.Mesh(geometry2)
      }

      this.onderBalk.geometry.attributes.position.array = new Float32Array(boxBuffer.positionBuffer(rbgz, rbgy, breedte - (2 * gz)))
      this.onderBalk.geometry.attributes.uv.array = new Float32Array(boxBuffer.uvBuffer())
      this.onderBalk.geometry.attributes.position.needsUpdate = true
      this.onderBalk.geometry.attributes.uv.needsUpdate = true
      const textureY = this.loadedTexture.clone()
      textureY.needsUpdate = true
      this.textureSettings(textureY,  breedte / 2450,  gy * 0.0075)
      const materialY = new this.THREE.MeshStandardMaterial({ map: textureY, metalness: 0, roughness: 1 })
      //materialY.side = THREE.BackSide

      const textureX = this.loadedTexture.clone()
      textureX.needsUpdate = true
      this.textureSettings(textureX,  breedte / 2450,  gz * 0.0075,  0.5 * Math.PI)
      const materialX = new this.THREE.MeshStandardMaterial({ map: textureX, metalness: 0, roughness: 1 })
      //materialX.side = THREE.BackSide

      // mesh
      this.onderBalk.material = [
        materialY,
        materialY,
        materialX,
        materialX,
        materialX,
        materialX,
      ];


      //update buitenste balken
      if(!erOn) {
        for(let i=0; i<2; i++) {
          const rbg = this.ringBalkGording.clone()
          this.setMaterial(rbg, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
          bGroup.add(rbg)
        }
        bGroup.children[1].position.z = x - rbgz
        bGroup.children[0].position.z = 0.25
      }


      for(let i=0; i<division.length-1; i++) {
        const rbg1 = this.ringBalkGording.clone()
        this.setMaterial(rbg1, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
        tGroup.add(rbg1)
      }



    } else if (this.modelType.isZadeldak || this.modelType.isKapschuur) {
      const texture0 = this.loadedTexture.clone()
      texture0.needsUpdate = true
      this.textureSettings(texture0, 1, 1, 0, Math.random())
      const material0 = new this.THREE.MeshStandardMaterial({ map: texture0, metalness: 0, roughness: 1 })

      const topPosition = ((breedte/2+ox)*Math.tan(graden * (Math.PI/180))) + oy
      const topPositionPaal = topPosition - (Math.tan(graden * (Math.PI/180))*(rbgz/2))

      let schuineGordingGeometry = new this.THREE.BufferGeometry()
      let schuineGordingGeometry2 = new this.THREE.BufferGeometry()
      if(this.modelType.isMonaco || this.modelType.isLissabon) {
        schuineGordingGeometry.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer((breedte-rbgz)/2, rbgy, rbgz, graden)), 3))
        schuineGordingGeometry.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer((breedte-rbgz)/2, rbgy, rbgz, graden)), 2))
        schuineGordingGeometry.setIndex(schuineGording.indexBuffer());
        schuineGordingGeometry = schuineGordingGeometry.toNonIndexed()

        if(this.modelType.isLissabon) {
          schuineGordingGeometry2.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(z-this.kapschuurConfig.topX-rbgz/2, rbgy, rbgz, graden)), 3))
          schuineGordingGeometry2.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(z-this.kapschuurConfig.topX-rbgz/2, rbgy, rbgz, graden)), 2))
          schuineGordingGeometry2.setIndex(schuineGording.indexBuffer());
          schuineGordingGeometry2 = schuineGordingGeometry2.toNonIndexed()
        } else {
          schuineGordingGeometry2.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer((breedte-rbgz)/2, rbgy, rbgz, graden)), 3))
          schuineGordingGeometry2.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer((breedte-rbgz)/2, rbgy, rbgz, graden)), 2))
          schuineGordingGeometry2.setIndex(schuineGording.indexBuffer());
          schuineGordingGeometry2 = schuineGordingGeometry2.toNonIndexed()
        }
      } else if(this.modelType.isMilaan || this.modelType.isStockholm) {
        schuineGordingGeometry.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(breedte/2, rbgy, rbgz, graden, ox, oy, oyl)), 3))
        schuineGordingGeometry.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(breedte/2, rbgy, rbgz, graden, ox, oy, oyl)), 2))
        if(this.modelType.isStockholm) {
          schuineGordingGeometry2.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(z-this.kapschuurConfig.topX, rbgy, rbgz, graden, ox, oy, oyl)), 3))
          schuineGordingGeometry2.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(z-this.kapschuurConfig.topX, rbgy, rbgz, graden, ox, oy, oyl)), 2))
        } else {
          schuineGordingGeometry2.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(breedte/2, rbgy, rbgz, graden, ox, oy, oyl)), 3))
          schuineGordingGeometry2.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(breedte/2, rbgy, rbgz, graden, ox, oy, oyl)), 2))
        }
      }
      schuineGordingGeometry.computeVertexNormals()
      schuineGordingGeometry2.computeVertexNormals()
      const schuineGordingMesh = new this.THREE.Mesh(schuineGordingGeometry, material0)
      if(this.modelType.isMonaco || this.modelType.isLissabon) {
        schuineGordingMesh.position.set(breedte/2-rbgz/2,topPositionPaal,rbgz/2+1)
      } else if(this.modelType.isMilaan || this.modelType.isStockholm) {
        schuineGordingMesh.position.set(breedte/2,topPosition,rbgz/2+1)
      }
      schuineGordingMesh.castShadow = true; 
      schuineGordingMesh.receiveShadow = true;
      schuineGordingMesh.name = "schuineGordingMesh"
      const schuineGordingMesh2 = new this.THREE.Mesh(schuineGordingGeometry2, material0)
      if(this.modelType.isMonaco || this.modelType.isLissabon) {
        schuineGordingMesh2.position.set(breedte/2-rbgz/2,topPositionPaal,rbgz/2+1)
      } else if(this.modelType.isMilaan || this.modelType.isStockholm) {
        schuineGordingMesh2.position.set(breedte/2,topPosition,rbgz/2+1)
      }
      schuineGordingMesh2.castShadow = true; 
      schuineGordingMesh2.receiveShadow = true;
      schuineGordingMesh2.name = "schuineGordingMesh2"
      schuineGordingMesh2.rotation.y=Math.PI
      if(this.modelType.isMonaco || this.modelType.isLissabon) {
        schuineGordingMesh2.position.x=breedte/2+rbgz/2
      } else if(this.modelType.isMilaan || this.modelType.isStockholm) {
        schuineGordingMesh2.position.x=breedte/2
      }

      const radians = graden * (Math.PI/180);
      const r1 = (90-graden) * (Math.PI/180);
      let h;
      if(this.modelType.isMonaco || this.modelType.isLissabon || this.modelType.isKapschuur) {
        h = ((breedte-rbgz)/2)*Math.tan(radians)
      } else if(this.modelType.isMilaan || this.modelType.isStockholm) {
        h = topPosition - (rbgy/(Math.sin(r1))) - ((rbtpy/2)*Math.tan(radians))
      }
      const dakPaalGeometry = new this.THREE.BufferGeometry()
      dakPaalGeometry.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(dakPaal.positionBuffer(rbtpz, h, rbgz, graden)), 3))
      dakPaalGeometry.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(dakPaal.uvBuffer(rbtpz, h, rbgz, graden)), 2))
      //dakPaalGeometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(dakPaal.normalBuffer()), 3))
      dakPaalGeometry.computeVertexNormals()
      const dakPaalMesh = new this.THREE.Mesh(dakPaalGeometry, material0)
      dakPaalMesh.position.set(breedte/2,0,rbgz/2+1)
      dakPaalMesh.castShadow = true; 
      dakPaalMesh.receiveShadow = true;
      dakPaalMesh.name = "dakPaalMesh"

      zadelDakPaal = new this.THREE.Group()
      zadelDakPaal.position.y = rbtpy
      zadelDakPaal.add(schuineGordingMesh, schuineGordingMesh2, dakPaalMesh)

      if(this.modelType.isMilaan || this.modelType.isStockholm) {
        console.log(gsh, gso)


        const schuineLijn = Math.sin(gsh * (Math.PI/180)) * gso
        const yCoordinate = Math.cos(gsh * (Math.PI/180)) * schuineLijn
        const xCoordinate = Math.sin(gsh * (Math.PI/180)) * schuineLijn

        const gordingSchoorZijdeC = breedte/2 - rbtpz/2
        const gordingSchoorHoekGamma = 180 - gsh - graden

        const gordingSchoorHoekGammaRad = gordingSchoorHoekGamma * (Math.PI/180)
        const gradenRad = graden * (Math.PI/180)
 
        const gordingSchoorZijdeA = (gordingSchoorZijdeC/Math.sin(gordingSchoorHoekGammaRad))   *   Math.sin(gradenRad)

        const g_gordingSchoor = new this.THREE.BufferGeometry()
        g_gordingSchoor.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.positionBufferScaled(gordingSchoorZijdeA, rbgy, rbgz-2)), 3))
        g_gordingSchoor.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.uvBufferScaled(gordingSchoorZijdeA, rbgy, rbgz-2)), 2))
        g_gordingSchoor.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
        g_gordingSchoor.setIndex(boxBuffer.indexBuffer());
        g_gordingSchoor.toNonIndexed()
        console.log(g_gordingSchoor.attributes.position.array)
        g_gordingSchoor.groups = boxBuffer.groupBuffer()
        const gordingSchoor = new this.THREE.Mesh(g_gordingSchoor, material0)
        gordingSchoor.castShadow = true;
        gordingSchoor.receiveShadow = true;
        gordingSchoor.name = "gordingSchoorLeft"
        gordingSchoor.position.x = breedte/2 + rbtpz/2 + xCoordinate
        gordingSchoor.position.y = -yCoordinate
        gordingSchoor.position.z = rbgz/2+1
        gordingSchoor.rotation.z = gsh * (Math.PI/180)

        const gordingSchoor2 = gordingSchoor.clone()
        gordingSchoor2.name = "gordingSchoorRight"
        gordingSchoor2.rotation.y = Math.PI
        gordingSchoor2.position.x = breedte/2 - rbtpz/2 - xCoordinate

        zadelDakPaal.add(gordingSchoor, gordingSchoor2)


        if(this.modelType.isStockholm) {
          const extraBalkjeGeometry = new this.THREE.BufferGeometry()
          extraBalkjeGeometry.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.positionBufferScaled((y-this.kapschuurConfig.achtergevelHoogte-dy)/Math.sin(graden * (Math.PI/180))+(this.kapschuurConfig.extraBalkjeDikte/Math.tan(graden * (Math.PI/180))), this.kapschuurConfig.extraBalkjeDikte, rbgz)), 3))
          extraBalkjeGeometry.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.uvBufferScaled((y-this.kapschuurConfig.achtergevelHoogte-dy)/Math.sin(graden * (Math.PI/180))+(this.kapschuurConfig.extraBalkjeDikte/Math.tan(graden * (Math.PI/180))), this.kapschuurConfig.extraBalkjeDikte, rbgz)), 2))
          extraBalkjeGeometry.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
          extraBalkjeGeometry.setIndex(boxBuffer.indexBuffer());
          extraBalkjeGeometry.toNonIndexed()
          // extraBalkjeGeometry.translate(0, 0, rbgz/2+1)

          const extraBalkje = new this.THREE.Mesh(extraBalkjeGeometry, material0)
          extraBalkje.castShadow = true;
          extraBalkje.receiveShadow = true;
          extraBalkje.name = "extraBalkje"

          extraBalkje.position.y = -dy
          extraBalkje.position.x = ringBalkBreedteKapschuur-(rbgy/Math.sin(graden * (Math.PI/180))) - (this.kapschuurConfig.extraBalkjeDikte/Math.sin(graden * (Math.PI/180)))
          extraBalkje.position.z = rbgz/2+1
          extraBalkje.rotation.z = -graden * (Math.PI/180)

          zadelDakPaal.add(extraBalkje)
        }



        // zijplanken op ringbalk
        const zijplankDikte = 25
        const lengteVanPlankInRingBalk = Math.tan(radians)*zijplankDikte
        const offsetY = Math.cos(radians) * lengteVanPlankInRingBalk
        const offsetX = Math.sin(radians) * lengteVanPlankInRingBalk
        const posY = rbgy - offsetY
        const posX = offsetX

        //find distance between point and line
        const distance = (x1, y1, x2, y2, x3, y3) => {
          const num = Math.abs((y2 - y1) * x3 - (x2 - x1) * y3 + x2 * y1 - y2 * x1)
          const den = Math.sqrt((y2 - y1) * (y2 - y1) + (x2 - x1) * (x2 - x1))
          return num / den
        }
        const startPoint = {x: -ox, y: rby+oy}
        const endPoint = {x: breedte/2, y: topPosition+rby}

        console.log(radians)
        const zijplankDistance = distance(startPoint.x, startPoint.y, endPoint.x, endPoint.y, posX, posY)
        
        const g_zijplank = new this.THREE.BufferGeometry()
        g_zijplank.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.positionBufferScaled(x+2*overhang-2*gz, zijplankDistance, zijplankDikte)), 3))
        g_zijplank.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.uvBufferScaled(x, zijplankDistance, zijplankDikte)), 2))
        g_zijplank.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
        g_zijplank.setIndex(boxBuffer.indexBuffer());
        g_zijplank.toNonIndexed()
        g_zijplank.groups = boxBuffer.groupBuffer()
        g_zijplank.translate(0, 0, -zijplankDikte/2)
        const zijplank = new this.THREE.Mesh(g_zijplank, material0)
        zijplank.castShadow = true;
        zijplank.receiveShadow = true;
        zijplank.name = "zijplank"
        const zijplank2 = zijplank.clone()
        zijplank2.name = "zijplank2"
        //zijplank.rotation.y = 1.5*Math.PI
        //zijplank.rotation.x = 0.25*Math.PI
        //zijplank.rotation.y = 0.5*Math.PI
        zijplank.rotateY(1.5*Math.PI);
        zijplank.rotateX(radians);
        zijplank.position.z = -overhang +gz
        zijplank.position.y = posY
        zijplank.position.x = posX
        
        zijplank2.rotateY(0.5*Math.PI);
        zijplank2.rotateX(radians);
        zijplank2.position.x = breedte-posX + (this.modelType.isStockholm ? (z-(2*this.kapschuurConfig.topX)) : 0)
        zijplank2.position.y = this.modelType.isStockholm ? posY - (y-this.kapschuurConfig.achtergevelHoogte) : posY
        zijplank2.position.z = x+overhang -gz

        tpGroup.add(zijplank, zijplank2)
      }

      gordingBalk = zadelDakPaal


      const balkB0 = zadelDakPaal.clone()
      console.log("PM",balkB0)
      for(let i=0; i<balkB0.children.length; i++) {
        balkB0.children[i].material = balkB0.children[i].material.clone()
        let zadelDakTexture0 = balkB0.children[i].material.map.clone()
        this.textureSettings(zadelDakTexture0, 1, 1, 0, Math.random())
        balkB0.children[i].material.map = zadelDakTexture0
        balkB0.children[i].material.map.needsUpdate = true
        balkB0.children[i].material.needsUpdate = true
      }

      const balkB1 = zadelDakPaal.clone()
      for(let i=0; i<balkB1.children.length; i++) {
        balkB1.children[i].material = balkB1.children[i].material.clone()
        let zadelDakTexture0 = balkB1.children[i].material.map.clone()
        this.textureSettings(zadelDakTexture0, 1, 1, 0, Math.random())
        balkB1.children[i].material.map = zadelDakTexture0
        balkB1.children[i].material.map.needsUpdate = true
        balkB1.children[i].material.needsUpdate = true
      }

      balkB0.position.z = 0.25
      balkB1.position.z = x - rbgz - 1.25 

      bGroup.add(balkB0)
      bGroup.add(balkB1)


      zadelDakPaal.getObjectByName('schuineGordingMesh').position.z = rbtpz/2
      zadelDakPaal.getObjectByName('schuineGordingMesh2').position.z = rbtpz/2
      zadelDakPaal.getObjectByName('dakPaalMesh').position.z = rbtpz/2
      if(zadelDakPaal.getObjectByName('gordingSchoorLeft')) {zadelDakPaal.getObjectByName('gordingSchoorLeft').position.z = rbtpz/2}
      if(zadelDakPaal.getObjectByName('gordingSchoorLeft')) {zadelDakPaal.getObjectByName('gordingSchoorRight').position.z = rbtpz/2}
      if(zadelDakPaal.getObjectByName('extraBalkje')) {zadelDakPaal.getObjectByName('extraBalkje').position.z = rbtpz/2}


      let positionOffset = staanderGrootte.dx/2
      for (let i = 0; i < division.length-1 ; i++) {
        positionOffset -= (division[i].width + staanderGrootte.dx)
        // console.log(staanders)
        // console.log(verticalePositie, (y + rbgy))
        const balk = ringBalkGording.clone()
        if(this.modelType.isKapschuur) {
          this.setMaterial(balk, 1 , 1, null, Math.random())
        } else {
          this.setMaterial(balk, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
        }
        const toAddToGroup = new this.THREE.Group()
        toAddToGroup.position.z = positionOffset + staanders.position.z - (rbgz / 2)
        toAddToGroup.add(balk)
        if(this.modelType.isZadeldak || this.modelType.isKapschuur) {
          toAddToGroup.position.z = positionOffset + staanders.position.z - (rbtpz / 2)
          const zadelDak = zadelDakPaal.clone()
          for(let i=0; i<zadelDak.children.length; i++) {
            zadelDak.children[i].material = zadelDak.children[i].material.clone()
            let zadelDakTexture0 = zadelDak.children[i].material.map.clone()
            this.textureSettings(zadelDakTexture0, 1, 1, 0, Math.random())
            zadelDak.children[i].material.map = zadelDakTexture0
            zadelDak.children[i].material.map.needsUpdate = true
            zadelDak.children[i].material.needsUpdate = true
          }
          toAddToGroup.add(zadelDak)
        }
        tGroup.add(toAddToGroup)

        if(verticalePositie == (y + rbgy) && this.modelType.isPlatdak) {
          const obalk = this.onderBalk.clone()
          this.setMaterial(balk, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
          obalk.position.z = positionOffset + staanders.position.z
          obalk.position.x = breedte / 2 + kx
          oGroup.add(obalk)
        }
      }



      gordingBalk = new this.THREE.Group()
      gordingBalk.position.y = rbtpy
      let schuineGordingGeometryTussen = new this.THREE.BufferGeometry()
      let schuineGordingGeometryTussen2 = new this.THREE.BufferGeometry()
      if(this.modelType.isMonaco || this.modelType.isLissabon) {
        schuineGordingGeometryTussen.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(breedte/2, gy, gz, graden)), 3))
        schuineGordingGeometryTussen.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(breedte/2, gy, gz, graden)), 2))
        schuineGordingGeometryTussen.setIndex(schuineGording.indexBuffer());
        schuineGordingGeometryTussen = schuineGordingGeometryTussen.toNonIndexed()

        if(this.modelType.isLissabon) {
          schuineGordingGeometryTussen2.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(z-this.kapschuurConfig.topX, rbgy, gz, graden)), 3))
          schuineGordingGeometryTussen2.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(z-this.kapschuurConfig.topX, rbgy, gz, graden)), 2))
          schuineGordingGeometryTussen2.setIndex(schuineGording.indexBuffer());
          schuineGordingGeometryTussen2 = schuineGordingGeometryTussen2.toNonIndexed()
        } else {
          schuineGordingGeometryTussen2.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(breedte/2, rbgy, rbgz, graden)), 3))
          schuineGordingGeometryTussen2.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(breedte/2, rbgy, rbgz, graden)), 2))
          schuineGordingGeometryTussen2.setIndex(schuineGording.indexBuffer());
          schuineGordingGeometryTussen2 = schuineGordingGeometryTussen2.toNonIndexed()
        }
      } else if(this.modelType.isMilaan || this.modelType.isStockholm) {
        schuineGordingGeometryTussen.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(breedte/2, rbgy, rbgz, graden, ox, oy, oyl)), 3))
        schuineGordingGeometryTussen.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(breedte/2, rbgy, rbgz, graden, ox, oy, oyl)), 2))

        if(this.modelType.isStockholm) {
          schuineGordingGeometryTussen2.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(z-this.kapschuurConfig.topX, rbgy, rbgz, graden, ox, oy, oyl)), 3))
          schuineGordingGeometryTussen2.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(z-this.kapschuurConfig.topX, rbgy, rbgz, graden, ox, oy, oyl)), 2))
        } else {
          schuineGordingGeometryTussen2.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(breedte/2, rbgy, rbgz, graden, ox, oy, oyl)), 3))
          schuineGordingGeometryTussen2.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(breedte/2, rbgy, rbgz, graden, ox, oy, oyl)), 2))
        }
      }
      schuineGordingGeometryTussen.computeVertexNormals()
      schuineGordingGeometryTussen2.computeVertexNormals()
      const schuineGordingMeshTussen = new this.THREE.Mesh(schuineGordingGeometryTussen, material0)
      schuineGordingMeshTussen.position.y=topPosition
      schuineGordingMeshTussen.position.x=breedte/2
      schuineGordingMeshTussen.castShadow = true; 
      schuineGordingMeshTussen.receiveShadow = true;
      const schuineGordingMeshTussen2 = new this.THREE.Mesh(schuineGordingGeometryTussen2, material0)
      schuineGordingMeshTussen2.position.y=topPosition
      schuineGordingMeshTussen2.position.x=breedte/2
      schuineGordingMeshTussen2.castShadow = true; 
      schuineGordingMeshTussen2.receiveShadow = true;
      schuineGordingMeshTussen2.rotation.y=Math.PI
      const nokhoek = new this.THREE.BufferGeometry()
      nokhoek.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(triangleBuffer.positionBuffer(gy, gz, graden)), 3))
      nokhoek.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(triangleBuffer.uvBuffer(gy, gz, graden, 2330, 145)), 2))
      nokhoek.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(triangleBuffer.normalBuffer()), 3))
      const nokHoekMesh = new this.THREE.Mesh(nokhoek, material0)
      nokHoekMesh.castShadow = true; 
      nokHoekMesh.receiveShadow = true;
      nokHoekMesh.position.set(breedte/2, topPosition - (gy/(Math.sin((90 - graden) * (Math.PI/180)))) - gy, 0)
      gordingBalk.add(schuineGordingMeshTussen, schuineGordingMeshTussen2)
      if(this.modelType.isMonaco) {
        gordingBalk.add(nokHoekMesh)
      }
      this.gordingBalk = gordingBalk


      if(this.modelType.isMilaan || this.modelType.isStockholm) {
        const k11 = schuineGordingMeshTussen.clone()
        k11.material = k11.material.clone()
        let k11Texture = k11.material.map.clone()
        this.textureSettings(k11Texture, 1, 1, 0, Math.random())
        k11.material.map = k11Texture
        k11.material.map.needsUpdate = true
        k11.material.needsUpdate = true
        const k12 = schuineGordingMeshTussen2.clone()
        let k12Texture = k12.material.map.clone()
        this.textureSettings(k12Texture, 1, 1, 0, Math.random())
        k12.material.map = k12Texture
        k12.material.map.needsUpdate = true
        k12.material.needsUpdate = true

        const k1 = new this.THREE.Group()
        k1.add(k11, k12)
        k1.position.z = -overhang +rbgz/2 +1
        const k2 = new this.THREE.Group()
        k2.position.z = x+overhang -rbgz/2 -1
        k2.add(schuineGordingMeshTussen.clone(), schuineGordingMeshTussen2.clone())
        kGroup.add(k1, k2)
        kGroup.position.y = rbtpy
      }


      const nokGordingTexture = this.loadedTexture.clone()
      nokGordingTexture.needsUpdate = true
      this.textureSettings(nokGordingTexture, 1, 1, Math.PI*0.5, Math.random())
      const nokGordingMaterial = new this.THREE.MeshStandardMaterial({ map: nokGordingTexture, metalness: 0, roughness: 1 })
      const nokGordingGeometry = new this.THREE.BufferGeometry()
      const p = 135-((gz/2)*Math.tan(radians))
      const q = p+(gz/2)*Math.tan(radians)
      const nokGordingWidth = (this.modelType.isMonaco || this.modelType.isLissabon) ? x-(rbgz*2) : x+(overhang*2) + 2
      nokGordingGeometry.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(nokGording.positionBuffer(gz, p, nokGordingWidth, graden)), 3))
      nokGordingGeometry.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(nokGording.uvBuffer(rbz, p, nokGordingWidth, graden)), 2))
      nokGordingGeometry.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(nokGording.normalBuffer()), 3))
      const nokGordingMesh = new this.THREE.Mesh(nokGordingGeometry, nokGordingMaterial)
      nokGordingMesh.position.set(breedte/2, topPosition + gy - q + 1, x/2)
      nokGordingMesh.castShadow = true; 
      nokGordingMesh.receiveShadow = true;
      ngGroup.add(nokGordingMesh)
      //let ringBalkGording = ringBalkGordingMesh



      



      
    }



    
    



    /*if (tGroup.children.length < staanders.children.length) {
      // er zijn staanders bijgekomen en dus moeten er ook gordignen bijkomen

      for (let i = tGroup.children.length; i <  staanders.children.length; i++) {

        const balk = this.ringBalkGording.clone()
        balk.position.z = staanders.children[i].position.z + staanders.position.z - (kzp / 2)
        tGroup.add(balk)

        if(verticalePositie == (y + rbgy)) {
          const obalk = this.onderBalk.clone()
          obalk.position.z = staanders.children[i].position.z + staanders.position.z
          obalk.position.x = z / 2 + kx
          oGroup.add(obalk)
        }

      }

    } else if (tGroup.children.length > staanders.children.length) {
      // er zijn staanders verwijderde en dus moeten er ook gordingen verwijderd worden
      console.log("was: " + tGroup.children.length + "naar: " + staanders.children.length)
      
      while(tGroup.children.length > staanders.children.length) {
        tGroup.children[0].geometry.dispose()
        tGroup.children[0].removeFromParent()

        if(oGroup.children[0]) {
          oGroup.children[0].geometry.dispose()
          oGroup.children[0].removeFromParent()
        }
      }*/


    if(verticalePositie == (y + rbgy)) {
      const oGroupLength = oGroup.children.length
      for(let i=0; i<oGroupLength; i++) {
        oGroup.children[0].geometry.dispose();
        oGroup.children[0].material.forEach(material => {
          material.dispose();
        });
        oGroup.children[0].removeFromParent()
      }
      for(let i=0; i<division.length-1; i++) {
        oGroup.add(this.onderBalk.clone())
      }
    } else {
      while(oGroup.children.length) {
        oGroup.children[0].geometry.dispose()
        oGroup.children[0].removeFromParent()
      }
    }
    //oGroup.visible=false
    /* if(verticalePositie == (y + rbgy)) {
      for (let i = oGroup.children.length; i <  staanders.children.length; i++) {
        const obalk = this.onderBalk.clone()
        obalk.position.z = staanders.children[i].position.z + staanders.position.z
        obalk.position.x = z / 2 + kx
        oGroup.add(obalk)
      }
    } else {
      if(verticalePositie != (y + rbgy)) {
        //als er geen onderbalken getoont moeten worden
        while(oGroup.children.length) {
          oGroup.children[0].geometry.dispose()
          oGroup.children[0].removeFromParent()
        }
      }
    } */
    this.updateTPosition (tGroup, oGroup, staanders, division, breedte, rbgz, rbtpz, kx)



    this.updateTTGroup(tGroup, ttGroup, gz, rbz, rbgz)

    this.nTGroup = tGroup.children.length


    const kt0GroupLength = kt0Group.children.length
    for(let i=0; i<kt0GroupLength; i++) {
      kt0Group.children[0].geometry.dispose();
      kt0Group.children[0].material.dispose();
      kt0Group.children[0].removeFromParent();
      
      kt1Group.children[0].geometry.dispose();
      kt1Group.children[0].material.dispose();
      kt1Group.children[0].removeFromParent();
    }

    while(kbGroup.children.length) {
      kbGroup.children[0].geometry.dispose();
      kbGroup.children[0].material.dispose();
      kbGroup.children[0].removeFromParent();
    }

    if(this.modelType.isPlatdak) {
      // update klossen
      console.log(gy, kx, kxo, kyo, kzp)
      /*this.hoekKlos.geometry.attributes.position.array = new Float32Array(gordingBuffer.positionBufferKlos(gy, kx, kxo, kyo, kzp))
      this.hoekKlos.geometry.attributes.position.array = new Float32Array(gordingBuffer.uvBufferKlos(gy, kx, kxo, kyo, kzp))
      this.hoekKlos.geometry.attributes.position.needsUpdate = true
      this.hoekKlos.geometry.attributes.uv.needsUpdate = true*/

      const linksGeometryHoekKlos = new this.THREE.BufferGeometry()
      linksGeometryHoekKlos.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferKlos(yHooLi, xBovLi, xOndLi, yKopLi, zDikPaal)), 3))
      linksGeometryHoekKlos.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferKlos(yHooLi, xBovLi, xOndLi, yKopLi, zDikPaal)), 2))
      linksGeometryHoekKlos.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferKlos()), 3))

      const rechtsGeometryHoekKlos = new this.THREE.BufferGeometry()
      rechtsGeometryHoekKlos.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferKlos(yHooRe, xBovRe, xOndRe, yKopRe, zDikPaal)), 3))
      rechtsGeometryHoekKlos.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferKlos(yHooRe, xBovRe, xOndRe, yKopRe, zDikPaal)), 2))
      rechtsGeometryHoekKlos.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferKlos()), 3))

      const textureHK = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`)
      this.textureSettings(textureHK, breedte / 2330 / breedte , gy / 145 / gy)
      const materialHK = new this.THREE.MeshStandardMaterial({ map: textureHK, metalness: 0, roughness: 1 })

      const linksHoekKlos = new this.THREE.Mesh( linksGeometryHoekKlos, materialHK );
      linksHoekKlos.rotation.y = 1.5 * Math.PI
      linksHoekKlos.position.y = gy-yHooLi
      linksHoekKlos.position.x = gz + xBovLi
      linksHoekKlos.position.z = - xBovLi 
      linksHoekKlos.castShadow = true; 
      linksHoekKlos.receiveShadow = true;
      this.linksHoekKlos = linksHoekKlos
      
      const rechtsHoekKlos = new this.THREE.Mesh( rechtsGeometryHoekKlos, materialHK );
      rechtsHoekKlos.rotation.y = 1.5 * Math.PI
      rechtsHoekKlos.position.y = gy-yHooRe
      rechtsHoekKlos.position.x = gz + xBovRe 
      rechtsHoekKlos.position.z = - xBovRe 
      rechtsHoekKlos.castShadow = true; 
      rechtsHoekKlos.receiveShadow = true;
      this.rechtsHoekKlos = rechtsHoekKlos
      
      if(round){
        const cylinderG = new this.THREE.CylinderGeometry( 1, 1, 1, 256 );
        const cylinderM = new this.THREE.MeshBasicMaterial( {color: 0xff00dc} );
        const cylinder = new this.THREE.Mesh( cylinderG, cylinderM );
        console.log(kx-kxo, gy-kyo, 500)
        cylinder.scale.set(gy-yKopLi, 500, xBovLi-xOndLi)
        cylinder.rotation.z=Math.PI/2
        cylinder.position.y=0.5
        cylinder.position.x=200
        cylinder.position.z=-xBovLi
        cylinder.updateMatrix()
        linksHoekKlos.updateMatrix()
        this.hoekKlosLinks = CSG.subtract(linksHoekKlos, cylinder)

        cylinder.scale.set(gy-yKopLi, 500, xBovRe-xOndRe)
        cylinder.rotation.z=Math.PI/2
        cylinder.position.y=0.5
        cylinder.position.x=200
        cylinder.position.z=-xBovRe
        cylinder.updateMatrix()
        rechtsHoekKlos.updateMatrix()
        this.hoekKlosRechts = CSG.subtract(rechtsHoekKlos, cylinder)
        
        cylinder.geometry.dispose();
        cylinder.material.dispose();
      }

      if(!erOn) {
        const klosB0 = round ? this.hoekKlosLinks.clone() : this.linksHoekKlos.clone()
        this.setMaterial(klosB0, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
        klosB0.rotation.y = 1.5 * Math.PI
        klosB0.position.x = xBovVo + zDikPaal
        klosB0.position.z = - xBovLi 
        klosB0.visible = klosLiEnabled

        const klosB1 =  round ? this.hoekKlosLinks.clone() : this.linksHoekKlos.clone()
        this.setMaterial(klosB1, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
        klosB1.position.x = xBovVo + breedte
        klosB1.visible = klosLiEnabled

        const klosB2 = round ? this.hoekKlosRechts.clone() : this.rechtsHoekKlos.clone()
        this.setMaterial(klosB2, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
        klosB2.rotation.y = 0.5 * Math.PI
        klosB2.position.x = xBovVo
        klosB2.position.z = x + xBovRe
        klosB2.visible = klosReEnabled

        const klosB3 = round ? this.hoekKlosRechts.clone() : this.rechtsHoekKlos.clone()
        this.setMaterial(klosB3, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
        klosB3.rotation.y = 0.5 * Math.PI
        klosB3.position.x = breedte + xBovVo - zDikPaal
        klosB3.position.z = x + xBovRe
        klosB3.visible = klosReEnabled
        kbGroup.add(klosB0, klosB1, klosB2, klosB3)
      }


      // tussen klossen
      const klosSpace = breedte - (zDikPaal * 2)
      const nklos = Math.ceil(klosSpace / this.ttOverspanning) - 1

      const linksGeometryKlos = new this.THREE.BufferGeometry()
      linksGeometryKlos.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferKlos(yHooLi, xBovLi+erOffset, xOndLi, yKopLi, gz)), 3))
      linksGeometryKlos.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferKlos(yHooLi, xBovLi+erOffset, xOndLi, yKopLi, gz)), 2))
      linksGeometryKlos.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferKlos()), 3))

      const rechtsGeometryKlos = new this.THREE.BufferGeometry()
      rechtsGeometryKlos.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.positionBufferKlos(yHooRe, xBovRe+erOffset, xOndRe, yKopRe, gz)), 3))
      rechtsGeometryKlos.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.uvBufferKlos(yHooRe, xBovRe+erOffset, xOndRe, yKopRe, gz)), 2))
      rechtsGeometryKlos.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(gordingBuffer.normalBufferKlos()), 3))

      const textureK = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`)
      this.textureSettings(textureK, breedte / 2330 / breedte , gy / 145 / gy)
      const materialK = new this.THREE.MeshStandardMaterial({ map: textureK, metalness: 0, roughness: 1 })

      const linksKlos = new this.THREE.Mesh( linksGeometryKlos, materialK );
      linksKlos.rotation.y = 1.5 * Math.PI
      linksKlos.position.y = gy-yHooLi
      linksKlos.position.x = gz + xBovVo 
      linksKlos.position.z = - xBovLi
      linksKlos.castShadow = true; 
      linksKlos.receiveShadow = true;
      this.linksKlos = linksKlos

      const rechtsKlos = new this.THREE.Mesh( rechtsGeometryKlos, materialK );
      rechtsKlos.rotation.y = 1.5 * Math.PI
      rechtsKlos.position.y = gy-yHooRe
      rechtsKlos.position.x = gz + xBovVo
      rechtsKlos.position.z = - xBovRe
      rechtsKlos.castShadow = true; 
      rechtsKlos.receiveShadow = true;
      this.rechtsKlos = rechtsKlos


      /*this.klos.geometry.attributes.position.array =  new Float32Array(gordingBuffer.positionBufferKlos(gy, kx, kxo, kyo, gz))
      this.klos.geometry.attributes.uv.array = new Float32Array(gordingBuffer.uvBufferKlos(gy, kx, kxo, kyo, gz))
      this.klos.geometry.attributes.position.needsUpdate = true
      this.klos.geometry.attributes.uv.needsUpdate = true*/
      if(round){
        const cylinderG = new this.THREE.CylinderGeometry( 1, 1, 1, 256 );
        const cylinderM = new this.THREE.MeshBasicMaterial( {color: 0xff00dc} );
        const cylinder = new this.THREE.Mesh( cylinderG, cylinderM );
        console.log(kx-kxo, gy-kyo, 500)

        cylinder.scale.set(gy-yKopLi, 500, xBovLi-xOndLi)
        cylinder.rotation.z=Math.PI/2
        cylinder.position.y=0.5
        cylinder.position.x=200
        cylinder.position.z=-xBovLi
        cylinder.updateMatrix()
        this.linksKlos.updateMatrix()
        this.linksKlos.geometry.attributes = CSG.subtract(this.linksKlos, cylinder).geometry.attributes

        cylinder.scale.set(gy-yKopRe, 500, xBovRe-xOndRe)
        cylinder.rotation.z=Math.PI/2
        cylinder.position.y=0.5
        cylinder.position.x=200
        cylinder.position.z=-xBovRe
        cylinder.updateMatrix()
        this.rechtsKlos.updateMatrix()
        this.rechtsKlos.geometry.attributes = CSG.subtract(this.rechtsKlos, cylinder).geometry.attributes

        cylinder.geometry.dispose();
        cylinder.material.dispose();
      }

      /*if (nklos > kt0Group.children.length) {
        // clone

        for (let i = kt0Group.children.length; i < nklos; i++) {

          const klosT0 = this.klos.clone()
          kt0Group.add(klosT0)
    
          const klosT1 = this.klos.clone()
          klosT1.rotation.y = 0.5 * Math.PI
          kt1Group.add(klosT1)

        }

        this.updateTKlosPosition(nklos, klosSpace, kt0Group, kt1Group, kx, gz, x, kzp)

      } else if (nklos < kt0Group.children.length) {
        // dispose
        
        const lastkt0Length = kt0Group.children.length

        for (let i = nklos; i <lastkt0Length; i++) {

          kt0Group.children[kt0Group.children.length - 1].geometry.dispose()
          kt0Group.children[kt0Group.children.length - 1].removeFromParent()

          kt1Group.children[kt1Group.children.length - 1].geometry.dispose()
          kt1Group.children[kt1Group.children.length - 1].removeFromParent()

        }

        this.updateTKlosPosition(nklos, klosSpace, kt0Group, kt1Group, kx, gz, x, kzp)

      } else {
        // position

        this.updateTKlosPosition(nklos, klosSpace, kt0Group, kt1Group, kx, gz, x, kzp)
      }*/

      for(let i=0; i<nklos; i++) {
        const clonedKlosT0 = this.linksKlos.clone()
        this.setMaterial(clonedKlosT0, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
        clonedKlosT0.visible = klosLiEnabled
        kt0Group.add(clonedKlosT0)

        const clonedKlosT1 = this.rechtsKlos.clone()
        this.setMaterial(clonedKlosT1, breedte / 2330 / breedte , gy / 145 / gy, null, Math.random())
        clonedKlosT1.rotation.y = 0.5 * Math.PI
        clonedKlosT1.visible = klosReEnabled
        kt1Group.add(clonedKlosT1)
      }

      this.updateTKlosPosition(nklos, klosSpace, kt0Group, kt1Group, xBovVo, xBovLi, xBovRe, gz, x, zDikPaal)
    }
  }

  async updateTexture (texture) {
    const loadedTexture = await this.textureLoader.loadAsync(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.loadedTexture = loadedTexture
    this.texture = texture
    this.update(
      this.x, 
      this.y, 
      this.z, 
      this.division,
      this.gy, 
      this.gz, 
      this.overstekKlos,
      this.kp, 
      this.ttOverspanning, 
      this.rbgy, 
      this.rbgz,
      this.rbgp,
      this.rby,
      this.rbz, 
      this.verticalePositie, 
      this.staanders, 
      this.round, 
      this.erOn, 
      this.erOffset,
      this.modelType,
      this.graden,
      this.rbtp, 
      this.rbtpy, 
      this.rbtpz,
      this.ox, 
      this.oy, 
      this.oyl, 
      this.gsh, 
      this.gso, 
      this.overhang,
      this.staanderGrootte,
      this.kapschuurConfig, 
      this.dy, 
      this.dz,
      this.lichtstraten
    )
  }

  textureSettings(texture, repeatX, repeatY, rotation, offsetX, offsetY) {
    texture.rotation = rotation || texture.rotation
    texture.repeat.x = repeatX
    texture.repeat.y = repeatY
    texture.wrapS = this.THREE.RepeatWrapping
    texture.wrapT = this.THREE.RepeatWrapping
    texture.encoding = this.THREE.sRGBEncoding
    texture.anisotropy = 16
    texture.offset.set(offsetX !== undefined ? offsetX : 0, offsetY !== undefined ? offsetY : 0)
  }

  setMaterial(mesh, repeatX, repeatY, rotation, offsetX, offsetY) {
    if(mesh.type === "Mesh") {
      if (Array.isArray(mesh.material)) {
        for (let i=0; i<mesh.material.length; i++) {
          mesh.material[i] = mesh.material[i].clone()
          if (mesh.material[i].map) {
            mesh.material[i].map.dispose()
            mesh.material[i].map = this.loadedTexture.clone()
            mesh.material[i].map.needsUpdate = true
            this.textureSettings(mesh.material[i].map, repeatX, repeatY, rotation, offsetX, offsetY)
          }
        }
      } else {
        mesh.material = mesh.material.clone()
        mesh.material.map.dispose()
        mesh.material.map = this.loadedTexture.clone()
        mesh.material.map.needsUpdate = true
        this.textureSettings(mesh.material.map, repeatX, repeatY, rotation, offsetX, offsetY)
      }
    }
  }

  updateTTGroup (tGroup, ttGroup, gz, rbz, rbgz) {

    //const space = tGroup.children[0].position.z - gz
    //const nBalk = Math.ceil(space / this.ttOverspanning) - 1
    let amountOfBalken = 0
    this.division.forEach((division) => {
      const space = division.width
      amountOfBalken += Math.ceil((space+gz) / (this.ttOverspanning+gz)) - 1
    })

    const ttGroupLength = ttGroup.children.length
    if(ttGroup.children[0].type === "Mesh") {
      for(let i=0; i<ttGroupLength; i++) {
        ttGroup.children[0].geometry.dispose();
        ttGroup.children[0].material.dispose();
        ttGroup.children[0].removeFromParent();
      }
    } else if (ttGroup.children[0].type === "Group") {
      for(let i=0; i<ttGroupLength; i++) {
        for(let j=0; j<ttGroup.children[0].length; j++){
          ttGroup.children[0].children[0].geometry.dispose();
          ttGroup.children[0].children[0].material.dispose();
          ttGroup.children[0].children[0].removeFromParent();
        }
        ttGroup.children[0].removeFromParent();
      }
    }
    for(let i=0; i<amountOfBalken; i++) {
      const gb = this.gordingBalk.clone()
      if(this.modelType.isPlatdak) {
        this.setMaterial(gb, this.z / 2330 / this.z , this.gy / 145 / this.gy, null, Math.random())
      } else {
        for(let i=0; i<gb.children.length; i++) {
          gb.children[i].material = gb.children[i].material.clone()
          let balkTexture = gb.children[i].material.map.clone()
          this.textureSettings(balkTexture, 1, 1, 0, Math.random())
          gb.children[i].material.map = balkTexture
          gb.children[i].material.map.needsUpdate = true
          gb.children[i].material.needsUpdate = true
        }
      }
      ttGroup.add(gb)
    }

    this.updateTTPosition (tGroup, ttGroup, rbgz, gz)
  }

  updateTTPosition (tGroup, ttGroup, rbgz, gz) {
    let offset = this.staanderGrootte.dx
    let balkIndex = 0
    for (let n = 0; n < tGroup.children.length + 1; n++) {

      const space = this.division[n].width
      const nBalk = Math.ceil((space+gz) / (this.ttOverspanning+gz)) - 1

      for(let i = 0; i < nBalk; i++) {
        ttGroup.children[balkIndex].position.z = offset + ((space-(nBalk*gz))/(nBalk+1))*(i+1) + (gz*i)
        balkIndex++
      }

      offset += space + this.staanderGrootte.dx
    }
  }

  updateTPosition (tGroup, oGroup, staanders, division, z, rbgz, rbtpz, kx) {
    console.log(tGroup.children.length)
    let positionOffset = this.staanderGrootte.dx/2
    for (let i = 0; i < tGroup.children.length; i++) {
      positionOffset -= (division[division.length-1-i].width + this.staanderGrootte.dx)
      tGroup.children[i].position.z = positionOffset + staanders.position.z - (((this.modelType.isZadeldak||this.modelType.isKapschuur)? rbtpz : rbgz) / 2)
    }
    positionOffset = this.staanderGrootte.dx/2
    for (let i = 0; i < oGroup.children.length; i++) {
      positionOffset -= (division[division.length-1-i].width + this.staanderGrootte.dx)
      oGroup.children[i].position.z = positionOffset + staanders.position.z
      oGroup.children[i].position.x = z / 2 + kx
    }
  }

  updateTKlosPosition (nklos, klosSpace, kt0Group, kt1Group, xBovVo, xBovLi, xBovRe, gz, x, zDikPaal) {
    for (let i = 0; i < nklos; i++) {

      kt0Group.children[i].position.x = (klosSpace / (nklos + 1)) * (i + 1) + zDikPaal + xBovVo + gz/2
      kt0Group.children[i].position.z = - xBovLi

      kt1Group.children[i].position.x = (klosSpace / (nklos + 1)) * (i + 1) + zDikPaal + xBovVo - gz/2
      kt1Group.children[i].position.z = x + xBovRe

    }
  }
}

  