<template>
  <div class="edit-textures">
    <h1>Textures</h1>

    <form class="default-form edit-textures-form" @submit.prevent="">

          <input class="default-form-field-input" v-model="filter">

          <div class="textureblock-container">
            <div v-for="(texture, i) in filteredTextures" :key="i" class="textureblock">
              <div class="textureblock-card">
                <img :src="texture.tempImageStorage ? texture.tempImageStorage : `${previewsServer}/textures/${texture.url}`" :title="`${texture.url}`" class="">
                <div class="changeImageContainer">
                  <input type="file" class="fileInput" @input="replaceTextureFile($event, i)">
                  <mdicon class="changeImageContainer-icon" name="upload" size="75"></mdicon>
                </div>
                <input type="text" class="default-form-field-input" :id="'input' + i" v-model.trim="texture.name">
                <select name="textureType" id="textureType" v-model="texture.textureType" ref="textureType">
                  <option v-for="(type, i) in textureTypes" :key="i" :value="type.id">{{ type.name }}</option>
                </select>
                <select name="plankType" id="plankType" v-model="texture.plankType" v-if="texture.textureType==='62cd857a7c20bc17b85af2cd'">
                  <option v-for="(type, i) in plankTypes" :key="i" :value="type.id">{{ type.name }}</option>
                </select>
                <select name="color" id="color" v-model="texture.color">
                  <option v-for="(color, i) in colors" :key="i" :value="color.id">{{ color.name }}</option>
                </select>

                <div v-if="texture.textureType==='62cd857a7c20bc17b85af2cd'">
                  <div v-for="(location, j) in plankLocations" :key="j" class="checkboxContainer">
                    <input class="checkbox" type="checkbox" :id="`admin-checkbox-${i}-${j}`" name="admin-checkbox" :checked="texture.locations.includes(location.id)" @change="toggleLocation(i,location.id)">
                    <label :for="`admin-checkbox-${i}-${j}`" class="checkbox-label">
                      <mdicon name="check-bold" class="checkbox-label-icon" :class="texture.locations.includes(location.id) ? 'checkbox-label-icon-active' : ''" size="15" />
                    </label><p class="locationText">{{location.name}}</p>
                  </div>
                </div>

                <button @click="remove(i)" class="delete">verwijder</button>
              </div>
            </div>


            <div class="textureblock">
              <div class="textureblock-card addButton">
                <input type="file" class="fileInput" ref="fileInput" @input="addTextureFile()">
                <mdicon name="plus" class="default-form-field-button-icon addButton-icon" size="225"/>
              </div>
            </div>
          </div>

          <!--<button class="default-form-field-button" @click="addColor()">
            <mdicon name="plus" class="default-form-field-button-icon" size="25" />
          </button>-->
          

      <div class="default-form-button">
        <LoadingButton @click="updateTextures()" :loading="loading" :disabled="!formValid">Update</LoadingButton>
      </div>

    </form>
  </div>
</template>

<script>
  import isEqual from 'lodash.isequal'
  import { mapGetters } from 'vuex'
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'

  export default {
    name: "MenuColors",

    components: {
      LoadingButton
    },

    data () {
      return {
        filter: '',
        textures: null,
        textureTypes: [],
        plankTypes: [],
        colors: [],
        plankLocations: [],
        formValid: false,
        loading: false,
        previewsServer: import.meta.env.VITE_VUE_APP_SERVER,
      }
    },

    methods: {
      async getTextures () {
        await this.$store.dispatch('getTextures')
        this.textures = JSON.parse(JSON.stringify(this.texturesM))
      },

      async getTextureTypes () {
        await this.$store.dispatch('getTextureTypes')
        this.textureTypes = JSON.parse(JSON.stringify(this.textureTypesM))
      },

      async getPlankTypes () {
        await this.$store.dispatch('getPlankTypes')
        this.plankTypes = JSON.parse(JSON.stringify(this.plankTypesM))
      },

      async getColors () {
        await this.$store.dispatch('getMenuColors')
        this.colors = JSON.parse(JSON.stringify(this.colorsM))
      },

      async getPlankLocations () {
        await this.$store.dispatch('getPlankLocations')
        this.plankLocations = JSON.parse(JSON.stringify(this.plankLocationsM))
      },

      async updateTextures () {
        this.loading = true

        let remove = []
        let update = []
        let insert = []

        for (let i = 0; i < this.texturesM.length; i++) {
          const keepId = this.textures.find((t) => t.id === this.texturesM[i].id)

          if (!keepId) {
            remove.push(this.texturesM[i])
            console.log(this.texturesM[i])
          } else {
            const nameChanged = this.textures.find((t) => t.id === this.texturesM[i].id && t.name !== this.texturesM[i].name)
            const textureTypeChanged = this.textures.find((t) => t.id === this.texturesM[i].id && t.textureType !== this.texturesM[i].textureType)
            const plankTypeChanged = this.textures.find((t) => t.id === this.texturesM[i].id && t.plankType !== this.texturesM[i].plankType)
            const colorChanged = this.textures.find((t) => t.id === this.texturesM[i].id && t.color !== this.texturesM[i].color)
            //console.log(this.textures.find((t) => t.id === this.texturesM[i].id).tempImageStorage)
            const urlChanged = this.textures[i].tempImageStorage ? this.textures[i] : null
            const locationsChanged = this.textures.find((t) => t.id === this.texturesM[i].id && JSON.stringify(t.locations) != JSON.stringify(this.texturesM[i].locations))
            //console.log(i, locationsChanged, JSON.stringify(locationsChanged.locations), JSON.stringify(this.texturesM[i].locations))
            if (nameChanged) {
              update.push(nameChanged)
            } else if (textureTypeChanged) {
              update.push(textureTypeChanged)
            } else if (plankTypeChanged) {
              update.push(plankTypeChanged)
            } else if (colorChanged) {
              update.push(colorChanged)
            } else if (urlChanged) {
              update.push(urlChanged)
            } else if (locationsChanged) {
              update.push(locationsChanged)
            }
          }
        }

        for (let i = 0; i < this.textures.length; i++) {
          if (!this.textures[i].id) {
            insert.push(this.textures[i])
          }
        }

        try {
          await this.$store.dispatch('updateTextures', {
            remove: remove,
            update: update,
            insert: insert
          })
          this.loading = false
        } catch (err) {
          console.log(err)
          this.loading = false
        }
        this.loading = false
        this.textures = JSON.parse(JSON.stringify(this.texturesM))
      },

      async addTextureFile() {
        if(this.$refs.fileInput.files.length < 1) {return}
        this.textures.push({
          id: null,
          name: "",
          url: "",
          tempImageStorage: null,
          locations: []
        })

        const reader = new FileReader();
        reader.readAsDataURL(this.$refs.fileInput.files[0]);
        reader.onload = () => {
          this.textures[this.textures.length-1].tempImageStorage = reader.result
          this.$refs.fileInput.value = null
        };

        this.$nextTick(() => {
          document.getElementById('input' + (this.textures.length-1)).focus()
        })
      },

      async replaceTextureFile(input, i) {
        if(input.target.files.length < 1) {return}
        this.textures[i].tempImageStorage = input.target.files[0]

        const reader = new FileReader();
        reader.readAsDataURL(input.target.files[0]);
        reader.onload = () => {
          this.textures[i].tempImageStorage = reader.result
          const texturesBackup = this.textures
          this.textures = null
          this.textures = texturesBackup
          input.target.value = null
        };
      },

      remove (i) {
        this.textures.splice(i, 1)
      },

      toggleLocation(textureIndex, locationId) {
        const texture = this.textures[textureIndex]
        if(texture.locations.includes(locationId)) {
          texture.locations.splice(texture.locations.findIndex(Id => Id === locationId),1)
        } else {
          texture.locations.push(locationId)
        }
      }
    
    },

    computed: {
      ...mapGetters({
        texturesM: "getTextures",
        textureTypesM: "getTextureTypes",
        plankTypesM: "getPlankTypes",
        colorsM: "getMenuColors",
        plankLocationsM: "getPlankLocations"        
      }),

      filteredTextures: function () {
        if(this.textures != null) {
          return this.textures.filter(i => i.name.toLowerCase().includes(this.filter.toLowerCase()))
        } else {
          return []
        }
      },
    },

    created () {
      this.getTextures()
      this.getTextureTypes()
      this.getPlankTypes()
      this.getColors()
      this.getPlankLocations()      
    },

    watch: {
      textures: {
        deep: true,
        immediate: false,
        handler () {
          if (isEqual(this.textures, this.texturesM)) {
            this.formValid = false
          } else {
            this.formValid = true
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

.edit-textures {
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  min-width: min(100%, 800px);

  &-form {
    padding: 25px 0px;
    gap: 50px;
    min-width: min(100%, 800px);
    width: min(100%, 1200px);

    &-display {
      border-radius: $radius-s;
      outline: 3px solid $c-background-1;
      outline-offset: -1px;
      height: 29px;
      align-self: center;
    }
  }
}

.textureblock-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.textureblock {
  flex: 0 0 33.33333%;
  height: 550px;
  margin-bottom: 0px;
  
  &-card {
    position: relative;
    margin: 0px auto;
    border: 3px solid #E3E6E9;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: $radius-s;

    img {
      width:95%;
      display: block;
      margin: 0px auto;
      margin-top: 10px;
      max-height: 45%;
      height: 45%;
      object-fit: contain;
    }

    input, select {
      width: 95%;
      margin-top:10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}

.addButton {
  position: relative;
  background-color: #E3E6E9;
  outline: 3px solid #00000000;
  -webkit-transition: outline-color .15s ease-out;
     -moz-transition: outline-color .15s ease-out;
       -o-transition: outline-color .15s ease-out;
          transition: outline-color .15s ease-out;

  &-icon {
    margin-top: 152.5px;
    display: block;
  }

  &:hover {
    outline: 3px solid $c-secondary;
    cursor: pointer;
  }
}

.fileInput {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  margin-top: 0px !important;
  left: 0px;
  opacity: 0%;
  z-index: 10;
  cursor: pointer;
}

.delete {
  background-color: $c-error;
  color: $c-primary-text;
  font-size: 0.95rem;
  margin-top: 15px;
  border-radius: $radius-s;
  padding: 4px 20px;
  border: none;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.changeImageContainer {
  position: absolute;
  background-color: rgba(70, 70, 70, 0.5);
  width: 100px;
  height: 100px;
  border-radius: 50px;
  top: calc((50% - 100px) / 2);
  left: calc(50% - 50px);
  cursor: pointer !important;

  &-icon {
    position: relative;
    display: block;
    color: #ffffff;
    margin-top: 12.5px;
  }
}

input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
}

.checkboxContainer {
  width: 95%;
  margin: 10px auto;
  text-align: left;

  label {
    display: inline-block;
    margin-right: 10px;

    span {
      svg {
        margin-top: -7px;
      }
    }
  }
}

.locationText {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  display: inline;
}

</style>