import * as trapezoidL from '@/three/buffer-geometries/trapezoidL.js'
import * as planeBuffer from '@/three/buffer-geometries/plane.js'
import { SUBTRACTION, ADDITION, Brush, Evaluator } from 'three-bvh-csg';
import { getLichtstraatInfo } from '@/three/functions/functions.js'

export default class Dakbedekking {
  constructor (THREE, textureLoader, x, y, z, dz, dby, dbz, dsz, tz, offset, overstekKlos, lijstPosition, lijstEnabled, kp, lichtstraten, staanderDikte) {
    this.THREE = THREE
    this.textureLoader = textureLoader
    this.x = x // breedte van overkapping
    this.y = y // hoogte van de paal van de overkapping
    this.z = z // diepte van de overkapping
    this.dz = dz // boeideel dikte
    this.dby = dby // hoogte van verticale stuk van de dakbedekking 
    this.dbz = dbz // diepte/breedte van horizontale stuk van de dakbedekking
    this.dsz = dsz // boeilijst dikte
    this.tz = tz // trim dikte
    this.offset = offset // trim overhang
    this.overstekKlos = overstekKlos //overstekklos lengte, meestal ongeveer 220mm
    this.group = null
    this.lijstPosition = lijstPosition // of de lijst aan de binnen- of buitenkant zit
    this.lijstEnabled = lijstEnabled
    this.kp = kp
    this.lichtstraten = lichtstraten
    this.staanderDikte = staanderDikte
    this.evaluator = new Evaluator()
    this.gatMaterial = new THREE.MeshStandardMaterial()
    this.group = new THREE.Group()

    this.textureZ = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}dakbedekking/rubber.jpg`)
    this.texture1 = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}dakbedekking/rubber.jpg`)
    this.texture2 = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}dakbedekking/rubber.jpg`)

    this.bouw(THREE, textureLoader, x, y, z, dz, dby, dbz, dsz, tz, offset, overstekKlos, lijstPosition, lijstEnabled, kp)
  }

  bouw (THREE, textureLoader, x, y, z, dz, dby, dbz, dsz, tz, offset, overstekKlos, lijstPosition, lijstEnabled, kp) {
    let klosVoEnabled = this.klosVoEnabled = overstekKlos.enabled? overstekKlos.klosVoEnabled : false
    let xBovVo = this.xBovVo = klosVoEnabled ? overstekKlos.xBovVo : 0
    let klosAcEnabled = this.klosAcEnabled = overstekKlos.enabled? overstekKlos.klosAcEnabled : false
    let xBovAc = this.xBovAc = klosAcEnabled ? overstekKlos.xBovAc : 0
    let klosLiEnabled = this.klosLiEnabled = overstekKlos.enabled? overstekKlos.klosLiEnabled : false
    let xBovLi = this.xBovLi = klosLiEnabled ? overstekKlos.xBovLi : 0
    let klosReEnabled = this.klosReEnabled = overstekKlos.enabled? overstekKlos.klosReEnabled : false
    let xBovRe = this.xBovRe = klosReEnabled ? overstekKlos.xBovRe : 0
    let kx = this.kx = xBovVo

    console.log(x, y, z, dz, dby, dsz, tz, offset, kx, lijstEnabled, kp)
    // L hoeken op de x as
    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(this.positionBuffer(z + (xBovAc + xBovVo) + (2*dz) + (lijstPosition||!lijstEnabled?0:(2*dsz)) - (2*tz) + (2*offset) - 2*dbz, dbz, dby, 0)), 3))
    geometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(this.uvBuffer(z, dz, kx)), 2))
    geometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(this.normalBuffer()), 3))
    geometry.groups = this.groupBuffer()

    this.textureSettings(this.textureZ, z * 0.0025, dby * 0.0025)
    const material = new THREE.MeshBasicMaterial({ map: this.textureZ, side: THREE.DoubleSide })

    const L0 = new this.THREE.Mesh( geometry, material );

    L0.castShadow = true; 
    L0.receiveShadow = true;
    L0.frustumCulled = false

    L0.position.z = (- xBovLi + (lijstPosition||!lijstEnabled?dsz:0)) - (dz+dsz-tz+offset)
    L0.position.x = - xBovVo -dz +tz -offset - (lijstPosition||!lijstEnabled?0:dsz)

    const L1 = L0.clone()
    L1.rotation.y = Math.PI
    L1.position.x = z + xBovAc + dz +(lijstPosition||!lijstEnabled?0:dsz)-tz+offset//z + kx + dz 
    L1.position.z = x + xBovRe + dz -tz +offset + (lijstPosition||!lijstEnabled?0:dsz)

    // L hoeken op de y as
    const geometry1 = new THREE.BufferGeometry()
    geometry1.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(this.positionBuffer(x + (xBovLi + xBovRe) + (2*dz) + (lijstPosition||!lijstEnabled?0:(2*dsz)) - (2*tz) + (2*offset) - 2*dbz, dbz, dby, 0)), 3))
    geometry1.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(this.uvBuffer(x, dz, kx)), 2))
    geometry1.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(this.normalBuffer()), 3))
    geometry1.groups = this.groupBuffer()

    this.textureSettings(this.texture1, x * 0.0025, dby * 0.0025)
    const material1 = new THREE.MeshBasicMaterial({ map: this.texture1, side: THREE.DoubleSide })

    const L2 = new this.THREE.Mesh( geometry1, material1 );

    L2.castShadow = true; 
    L2.receiveShadow = true;
    L2.frustumCulled = false

    L2.rotation.y = 0.5 * Math.PI
    L2.position.z = x + xBovRe + dz +(lijstPosition||!lijstEnabled?0:dsz) -tz+offset
    L2.position.x = - xBovVo - dz-(lijstPosition||!lijstEnabled?0:dsz)+tz-offset

    const L3 = L2.clone()
    L3.rotation.y = 1.5 * Math.PI
    L3.position.z = (- xBovLi + (lijstPosition||!lijstEnabled?dsz:0)) - (dz+dsz-tz+offset)
    L3.position.x = z + xBovAc + dz +(lijstPosition||!lijstEnabled?0:dsz)-tz+offset

    // vlak
    const geometry2 = new THREE.BufferGeometry()
    geometry2.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(planeBuffer.positionBuffer(x + (xBovLi + xBovRe) + (2*dz) + (lijstPosition||!lijstEnabled?0:(2*dsz)) - (2*tz) + (2*offset) - 2*dbz, z + (xBovVo + xBovAc) + (2*dz) + (lijstPosition||!lijstEnabled?0:(2*dsz)) - (2*tz) + (2*offset) - 2*dbz)), 3))
    geometry2.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(planeBuffer.uvBuffer()), 2))
    geometry2.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(planeBuffer.normalBuffer()), 3))

    this.textureSettings(this.texture2, z * 0.0025, x * 0.0025)
    const material2 = new THREE.MeshStandardMaterial({ map: this.texture2, side: THREE.DoubleSide })

    let vlak = new Brush( geometry2, material2 );
    vlak.position.x = - xBovVo -dz +tz -offset - (lijstPosition||!lijstEnabled?0:dsz) + dbz
    vlak.position.z = (- xBovLi + (lijstPosition||!lijstEnabled?dsz:0)) - (dz+dsz-tz+offset) + dbz

    // make holes in dakbedekking vlak for lichtstraten
    if (this.lichtstraten.length > 0) {
      let lichtstraatInfo = getLichtstraatInfo(this.lichtstraten[0].code)
      const safeMargin = 10
      const lichtstraatGatGeometry = new THREE.BoxGeometry(
        lichtstraatInfo.dimensions.z-lichtstraatInfo.margins.zNeg2-lichtstraatInfo.margins.zPos2+safeMargin,
        1000,
        lichtstraatInfo.dimensions.x-lichtstraatInfo.margins.xNeg2-lichtstraatInfo.margins.xPos2+safeMargin
      )
      let lichtstraatGat = new Brush(lichtstraatGatGeometry, this.gatMaterial)
      lichtstraatGat.position.x = this.lichtstraten[0].positionFront + lichtstraatInfo.dimensions.z/2 +this.staanderDikte -lichtstraatInfo.margins.zNeg2 -lichtstraatInfo.margins.zNeg1/2+lichtstraatInfo.margins.zPos1/2
      lichtstraatGat.position.z = this.lichtstraten[0].positionLeft + lichtstraatInfo.dimensions.x/2 +this.staanderDikte -lichtstraatInfo.margins.xNeg2
      for (let i = 1; i < this.lichtstraten.length; i++) {
        let lichtstraatInfo = getLichtstraatInfo(this.lichtstraten[i].code)
        const newLichtstraatGatGeometry = new THREE.BoxGeometry(
          lichtstraatInfo.dimensions.z-lichtstraatInfo.margins.zNeg2-lichtstraatInfo.margins.zPos2+safeMargin,
          1000,
          lichtstraatInfo.dimensions.x-lichtstraatInfo.margins.xNeg2-lichtstraatInfo.margins.xPos2+safeMargin
        )
        const newLichtstraatGat = new Brush(newLichtstraatGatGeometry, this.gatMaterial)
        newLichtstraatGat.position.x = this.lichtstraten[i].positionFront + lichtstraatInfo.dimensions.z/2 +this.staanderDikte -lichtstraatInfo.margins.zNeg2 -lichtstraatInfo.margins.zNeg1/2+lichtstraatInfo.margins.zPos1/2
        newLichtstraatGat.position.z = this.lichtstraten[i].positionLeft + lichtstraatInfo.dimensions.x/2 +this.staanderDikte -lichtstraatInfo.margins.xNeg2
        lichtstraatGat.updateMatrixWorld()
        newLichtstraatGat.updateMatrixWorld()
        lichtstraatGat = this.evaluator.evaluate(lichtstraatGat, newLichtstraatGat, ADDITION)
      }
      vlak.updateMatrixWorld()
      lichtstraatGat.updateMatrixWorld()
      vlak = this.evaluator.evaluate(vlak, lichtstraatGat, SUBTRACTION)
    }

    vlak.castShadow = true;
    vlak.receiveShadow = true;
    vlak.frustumCulled = false
    

    // add to group
    this.group.add(L0)
    this.group.add(L1)
    this.group.add(L2)
    this.group.add(L3)
    this.group.add(vlak)
  }

  update (x, y, z, dz, dby, dbz, dsz, tz, offset, overstekKlos, lijstPosition, lijstEnabled, kp, lichtstraten, staanderDikte) {
    this.x = x
    this.y = y
    this.z = z
    this.dz = dz
    this.dby = dby
    this.dbz = dbz
    this.dsz = dsz
    this.tz = tz
    this.offset = offset
    this.overstekKlos = overstekKlos
    let klosVoEnabled = this.klosVoEnabled = overstekKlos.enabled? overstekKlos.klosVoEnabled : false
    let xBovVo = this.xBovVo = klosVoEnabled ? overstekKlos.xBovVo : 0
    let klosAcEnabled = this.klosAcEnabled = overstekKlos.enabled? overstekKlos.klosAcEnabled : false
    let xBovAc = this.xBovAc = klosAcEnabled ? overstekKlos.xBovAc : 0
    let klosLiEnabled = this.klosLiEnabled = overstekKlos.enabled? overstekKlos.klosLiEnabled : false
    let xBovLi = this.xBovLi = klosLiEnabled ? overstekKlos.xBovLi : 0
    let klosReEnabled = this.klosReEnabled = overstekKlos.enabled? overstekKlos.klosReEnabled : false
    let xBovRe = this.xBovRe = klosReEnabled ? overstekKlos.xBovRe : 0
    let kx = this.kx = xBovVo
    this.lijstPosition = lijstPosition
    this.lijstEnabled = lijstEnabled
    this.kp = kp
    this.lichtstraten = lichtstraten
    this.staanderDikte = staanderDikte

    while(this.group.children.length > 0){
      this.group.remove(this.group.children[0]);
    }
    this.bouw(this.THREE, this.textureLoader, x, y, z, dz, dby, dbz, dsz, tz, offset, overstekKlos, lijstPosition, lijstEnabled, kp)
    return


    const L0 = this.group.children[0]
    const L1 = this.group.children[1]
    const L2 = this.group.children[2]
    const L3 = this.group.children[3]
    const vlak = this.group.children[4]

    L0.geometry.attributes.position.array = new Float32Array(this.positionBuffer(z + (xBovAc + xBovVo) + (2*dz) + (lijstPosition||!lijstEnabled?0:(2*dsz)) - (2*tz) + (2*offset) - 2*dbz, dbz, dby, 0))
    L0.geometry.attributes.uv.array = new Float32Array(this.uvBuffer(z, dz, kx))
    L0.geometry.attributes.position.needsUpdate = true
    L0.geometry.attributes.uv.needsUpdate = true

    this.textureSettings(L0.material.map, z * 0.0025, dby * 0.0025)
    L0.material.map.needsUpdate = true
    L0.material.needsUpdate = true

    L0.position.z = (- (xBovLi) + (lijstPosition||!lijstEnabled?dsz:0)) - (dz+dsz-tz+offset)
    L0.position.x = - (xBovVo) -dz +tz -offset - (lijstPosition||!lijstEnabled?0:dsz)

    L1.position.x = z + (xBovAc) + dz +(lijstPosition||!lijstEnabled?0:dsz)-tz+offset//z + kx + dz 
    L1.position.z = x + (xBovRe) + dz -tz +offset + (lijstPosition||!lijstEnabled?0:dsz)

    L2.geometry.attributes.position.array = new Float32Array(this.positionBuffer(x + (xBovLi + xBovRe) + (2*dz) + (lijstPosition||!lijstEnabled?0:(2*dsz)) - (2*tz) + (2*offset) - 2*dbz, dbz, dby, 0))
    L2.geometry.attributes.uv.array = new Float32Array(this.uvBuffer(x, dz, kx))
    L2.geometry.attributes.position.needsUpdate = true
    L2.geometry.attributes.uv.needsUpdate = true

    L2.position.z = x + (xBovRe) + dz +(lijstPosition||!lijstEnabled?0:dsz) -tz+offset
    L2.position.x = - (xBovVo) - dz-(lijstPosition||!lijstEnabled?0:dsz)+tz-offset

    L3.position.z = (- (xBovLi) + (lijstPosition||!lijstEnabled?dsz:0)) - (dz+dsz-tz+offset)
    L3.position.x = z + (xBovAc) + dz +(lijstPosition||!lijstEnabled?0:dsz)-tz+offset

    this.textureSettings(L2.material.map, x * 0.0025, dby * 0.0025)
    L2.material.map.needsUpdate = true
    L2.material.needsUpdate = true

    vlak.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(x + (xBovLi + xBovRe) + (2*dz) + (lijstPosition||!lijstEnabled?0:(2*dsz)) - (2*tz) + (2*offset) - 2*dbz, z + (xBovVo + xBovAc) + (2*dz) + (lijstPosition||!lijstEnabled?0:(2*dsz)) - (2*tz) + (2*offset) - 2*dbz))
    vlak.geometry.attributes.position.needsUpdate = true

    this.textureSettings(vlak.material.map, z * 0.0025, x * 0.0025)
    vlak.material.map.needsUpdate = true
    vlak.material.needsUpdate = true

    vlak.position.x = - (xBovVo) -dz +tz -offset - (lijstPosition||!lijstEnabled?0:dsz) + dbz
    vlak.position.z = (- (xBovLi) + (lijstPosition||!lijstEnabled?dsz:0)) - (dz+dsz-tz+offset) + dbz
  }

  positionBuffer (y, dz, dby, kx) {
    const a = dby // L height
    const b = y + (2 * kx) + (2 * dz)  // outer width
    const c = y + (2 * kx) + dz // inner width
    const d = dz // L depth

    return trapezoidL.positionBuffer(a, b, c, d)
  }

  uvBuffer (y, dz, kx) {
    const b = y + (2 * kx) + (2 * dz)  // outer width
    const c = y + (2 * kx) + dz // inner width
    const d = dz // L depth

    return trapezoidL.uvBuffer(b, c, d)
  }

  normalBuffer () {
    return trapezoidL.normalBuffer()
  }

  groupBuffer () {
    return trapezoidL.groupBuffer()
  }

  textureSettings(texture, repeatX, repeatY, rotation) {
    texture.rotation = rotation || texture.rotation
    texture.repeat.x = repeatX
    texture.repeat.y = repeatY
    texture.wrapS = this.THREE.RepeatWrapping
    texture.wrapT = this.THREE.RepeatWrapping
    texture.encoding = this.THREE.sRGBEncoding
    texture.anisotropy = 16
    // texture.offset.set(Math.random(), 0)
  }
}