<template>
  <div class="doors-windows-page">
    <div class="wall-page-header">
      <!-- v-if="!selectedWall && !selectedElement" -->
      <!-- <TabSelectorIcons :tabs="filteredTabs" :position="getCurrentPosition" extension="" @change="changeTab"/>  -->
      <!--@change="changeTab"-->
      <!-- <TabSelectorIcons :tabs="tabs" extension="" @change="changeTab" /> -->
      <TabSelectorNoScrolling :tabs="filteredTabs" extension="" @change="changeTab"/>
    </div>
    <!--<keep-alive>-->
      <RouterView />
    <!--</keep-alive>-->
  </div>
</template>

<script>
// import TabSelectorIcons from '@/components/inputs/TabSelectorIcons.vue'
  import TabSelectorNoScrolling from '@/components/inputs/TabSelectorNoScrolling.vue'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      // TabSelectorIcons,
      TabSelectorNoScrolling
    },

    data () {
      return {
        tabs: [
          {name: "Basic Deuren", icon: "door", route: "/"},
          {name: "Basic Ramen", icon: "window-closed-variant", route: "/bwindows"},
          {name: "Steellook Deuren", icon: "door", route: "/sldoors"},
          {name: "Steellook Ramen", icon: "window-open-variant", route: "/slwindows"},
          {name: "Steellook Ramen met BW", icon: "window-open-variant", route: "/slwindowsbw"},
        ],
      }
    },

    methods: {
      changeTab (tab) {
        this.$router.push('/cfg/doorswindows' + this.filteredTabs[tab].route)
      }
    },

    computed: {
      ...mapGetters({
        selectedElement: 'getSelectedElement',
        config: 'getConfig',
      }),

      filteredTabs () {
        if (this.config.afmetingen.wall.position === 'buiten') {
          return this.tabs.filter((tab) => tab.route !== '/slwindowsbw')
        }
        return this.tabs
      },
    },
    
  }
</script>

<style lang="scss" scoped>

.wall-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
  min-width: 100%;

  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

</style>