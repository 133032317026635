<template>
  <div class="models-page">
    <TypeCard 
      v-for="(model, index) in models" 
      :key="index" 
      ref="component"
      :data="model"
      :selected="model.type.name === config.type"
      @select="updateType(model)"
    />
    
    <div style="margin-top: 50px" v-if="filterTypes(externalTypes).length > 0">
      <h3>Kijk hieronder om een maatwerk overkapping te configureren.</h3>
      <TypeCardExternal
        v-for="(type, index) in filterTypes(externalTypes)" 
        :key="index" 
        ref="component"
        :data="type"
        @select="toExternalType(type)"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import TypeCard from '@/components/inputs/TypeCard.vue'
  import TypeCardExternal from '@/components/inputs/TypeCardExternal.vue'

  export default {
    name: "Types",

    components: {
      TypeCard,
      TypeCardExternal
    },

    methods: {
      get () {
        if (!this.models) {
          this.$store.dispatch('getAvailableModels')
          setTimeout(() => {
            console.log(this.externalTypes)
          }, 1000);
        }
      },
      // TASK 1543 turn off links to maatwerk CFG, except for one button
      filterTypes(array) {
        if(!array) return []
        return array.filter((item) => item.name === 'Maatwerk Kapschuur')
      },

      updateType (model) {
        this.$store.dispatch('updateType', model)
        this.$router.push('/cfg/models')
      },

      async toExternalType (type) {
        const isPublic = await this.$store.dispatch('getIsPublicUser')
        let url = ''
        switch(location.hostname) {
          case 'localhost':
          case 'tw-js-dev.mtest.nl':
          case 'tw-v01-js-dev.mtest.nl':
            url = type.urls.dev 
            break;
          case 'tw-js-tes.mtest.nl':
          case 'tw-v02-js-tes.mtest.nl':
            url = type.urls.tes 
            break;
          case 'tw-js-pro.mtest.nl':
          case 'standaardmaten.toplawood.nl':
          case 'toplawood.moogue.com':
          case 'toplawood.3dconfigurators.nl':
            url = type.urls.pro
            break;
        }
        if(isPublic) {
          url = url.replace('configurator', 'public')
          url = url + '/' + this.userToken
        }
        window.parent.location.href = url
      }
    },

    computed: {
      ...mapGetters({
        models: 'getAvailableTypes',
        externalTypes: 'getExternalTypes',
        config: 'getConfig',
        userToken: 'getUserToken',
      }),
    },

    created () {
      this.get()
    }
  }
</script>

<style lang="scss" scoped>

.models-page {
  display: grid;
  gap: 15px;
  //grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
}

h3 { 
  margin-bottom: 25px;
}

</style>