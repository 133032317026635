<template>

<div class="backgroundFilter" ref="filter">
  <div class="card">
    <p>Wilt u verdergaan waar u was gebleven?</p>
    <button @click="hideFilter(false)">Ja, ik wil verder gaan waar ik ben gebleven.</button>
    <button @click="hideFilter(true)">Nee, ik wil opnieuw beginnen.</button>
  </div>
</div>

</template>

<script>
  export default {
    name: "ConfirmHistory",

    methods: {
      hideFilter(reset) {
        this.$refs.filter.style.opacity=0; 
        this.$refs.filter.style.pointerEvents='none';

        if(!reset) {
          this.$store.dispatch('initializeHistory')
          this.$store.dispatch('setConfigToCurrent')
        }

        setTimeout(() => {
          this.$emit('continue')
        }, 0);
      }
    }
  }
</script>

<style lang="scss" scoped>
// styling for the whole sidebar
.backgroundFilter {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.card {
  background-color: #ffffff;
  border-radius: $radius-l;
  width: 600px;
  height: 250px;
  //margin-left: calc(50vw - 300px - 300px + 35px);;
  //margin-top: calc(50vh - 125px);
  margin-left: calc(50vw - 300px);
  margin-top: calc(50vh - 125px);
  @media (max-width: 999px) {
    width: 80vw;
    height: auto;
    margin-left: 10vw;
    margin-top: calc(50vh - 150px);
    padding-bottom: 30px;
  }
}

p {
  margin: 0px auto;
  margin-top: 25px;
  max-width: 90%;
  font-size: 1.6rem;
  font-weight: 500;
  display: inline-block;
  word-wrap: break-word;
  @media (max-width: 999px) {
    font-size: 1.2rem;
  }
}

button {
  background-color: $c-primary;
  font-size: 1.2rem;
  color: $c-secondary;
  border: none;
  border-radius: $radius-s;
  font-weight: 500;
  margin-top: 20px;
  width: 80%;
  word-wrap: break-word;
  padding: 10px 30px;
  cursor: pointer;
  @media (max-width: 999px) {
    font-size: 1rem;
  }
}
</style>