<template>
  <div class="edit-uisettings">
    <h1>Menu ui settings</h1>

    <form v-if="uisettings[0]"
      class="default-form edit-uisettings-form"
      @submit.prevent=""
    >
      <div class="default-form-field">
        <input class="checkbox" type="checkbox" :id="`productDetails-checkbox`" name="trueOnline-checkbox" v-model="uisettings[0].showProductDetails">
        <label :for="`productDetails-checkbox`" class="checkbox-label">
          <mdicon name="check-bold" class="checkbox-label-icon" :class="uisettings[0].showProductDetails ? 'checkbox-label-icon-active' : ''" size="15" />
        </label>
        <label class="default-form-field-label" for="productDetails">Product Details</label>
      </div>

      <div class="default-form-field">
        <input class="checkbox" type="checkbox" :id="`price-checkbox`" name="trueOnline-checkbox" v-model="uisettings[0].showPrice">
        <label :for="`price-checkbox`" class="checkbox-label">
          <mdicon name="check-bold" class="checkbox-label-icon" :class="uisettings[0].showPrice ? 'checkbox-label-icon-active' : ''" size="15" />
        </label>
        <label class="default-form-field-label" for="price">Totaalprijs</label>
      </div>

      <div class="default-form-field">
        <div class="default-form-field-uisettings">
          <div class="default-form-field-uisettings-header default-form-field-uisettings-edit-row">
            <span>Extra Details</span>
          </div>

          <div 
            v-for="(extraDetail, i) in uisettings[0].extraDetails" 
            :key="i" 
            class="default-form-field-uisettings-edit-row"
          >
            <input 
              v-model.trim="extraDetail.text"
              type="text"
              class="default-form-field-input" 
            >

            <button class="default-form-field-button" @click="removeExtraDetail(i)">
              <mdicon name="minus" class="default-form-field-button-icon" size="25" />
            </button>
          </div>

          <button class="default-form-field-button" @click="addExtraDetail()">
            <mdicon name="plus" class="default-form-field-button-icon" size="25" />
          </button>

          <div class="default-form-field-uisettings-header default-form-field-uisettings-edit-row">
            <span>Extra Vragen</span>
          </div>

          <div v-for="(extraQuestion, i) in uisettings[0].extraQuestions" :key="i" >
            <div class="default-form-field-uisettings-edit-row">
              <input 
                v-model.trim="extraQuestion.text"
                type="text"
                class="default-form-field-input" 
              >

              <button class="default-form-field-button" @click="removeExtraQuestion(i)">
                <mdicon name="minus" class="default-form-field-button-icon" size="25" />
              </button>
            </div>

            <div class="default-form-field-uisettings-edit-row sub" v-for="(option, i2) in extraQuestion.options" :key="i">
              <input 
                v-model.trim="option.text"
                type="text"
                class="default-form-field-input" 
              >

              <button class="default-form-field-button" @click="removeOption(i, i2)">
                <mdicon name="minus" class="default-form-field-button-icon" size="25" />
              </button>
            </div>
            
            <button class="default-form-field-button sub" @click="addOption(i)">
              <mdicon name="plus" class="default-form-field-button-icon" size="25" />
            </button>
          </div>

          <button class="default-form-field-button" @click="addExtraQuestion()">
            <mdicon name="plus" class="default-form-field-button-icon" size="25" />
          </button>
        </div>
      </div>

      <div class="default-form-button">
        <LoadingButton 
          :loading="loading" 
          :disabled="!formValid"
          @click="updateMenuUISettings()" 
        >
          Update
        </LoadingButton>
      </div>
    </form>
  </div>
</template>

<script>
  import isEqual from 'lodash.isequal'
  import { mapGetters } from 'vuex'
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'

  export default {
    name: "UISettings",

    components: {
      LoadingButton
    },

    data () {
      return {
        uisettings: {},
        formValid: false,
        loading: false,
        showmodal: false,
        selectedrowID: '',
        selectedrowName: '',
        selectedrowUrlEnding: '',
        tempImageStorage: null,
        allowConfirm: false,
        previewsServer: import.meta.env.VITE_VUE_APP_SERVER
      }
    },

    computed: {
      ...mapGetters({
        menuUISettings: "getMenuUISettings"
      }),
    },

    watch: {
      uisettings: {
        deep: true,
        immediate: false,
        handler () {
          if (isEqual(this.uisettings, this.menuUISettings)) {
            this.formValid = false
          } else {
            this.formValid = true
          }
        }
      }
    },

    created () {
      this.getMenuUISettings()
    },

    methods: {
      async getMenuUISettings () {
        await this.$store.dispatch('getMenuUISettings')
        this.uisettings = JSON.parse(JSON.stringify(this.menuUISettings))
      },

      async updateMenuUISettings () {
        this.loading = true

        try {
          await this.$store.dispatch('updateMenuUISettings', this.uisettings)
        } catch (err) {
          console.log(err)
          this.loading = false
        }

        this.loading = false
        this.uisettings = JSON.parse(JSON.stringify(this.menuUISettings))
      },
      
      addUISetting () {
        console.log(this.uisettings)
        this.uisettings.push({
          showProductDetails: true,
          showPrice: true,
        })
      },
      
      removeUISetting (i) {
        this.uisettings.splice(i, 1)
      },
      
      addExtraDetail() {
        console.log(this.uisettings)
        this.uisettings[0].extraDetails.push({
          text: ''
        })
      },

      removeExtraDetail (i) {
        this.uisettings[0].extraDetails.splice(i, 1)
      },

      addExtraQuestion() {
        console.log(this.uisettings)
        this.uisettings[0].extraQuestions.push({
          text: '',
          options: []
        })
      },

      removeExtraQuestion (i) {
        this.uisettings[0].extraQuestions.splice(i, 1)
      },

      addOption(i) {
        console.log(this.uisettings)
        this.uisettings[0].extraQuestions[i].options.push({
          text: ''
        })
      },

      removeOption (i, i2) {
        this.uisettings[0].extraQuestions[i].options.splice(i2, 1)
      },
      

      // handleModal(infoboxsetting) {
      //   // console.log('infoboxsetting',infoboxsetting);
      //   this.selectedrowID = infoboxsetting.id;
      //   this.selectedrowName = infoboxsetting.name;
      //   console.log('infoboxsetting.urlEnding',infoboxsetting.urlEnding)
      //   this.selectedrowUrlEnding = infoboxsetting.urlEnding;
      //   this.showmodal = true;
        
      //   this.$nextTick(() => {
      //     const buttonRef = this.$refs.focusafteropen;
      //     buttonRef.focus();
      //   });
      // },

      // clearModal() {
      //   this.$refs[`fileInput${this.selectedrowID}`].value = null;
      //   this.selectedrowID = '';
      //   this.selectedrowName = '';
      //   this.showmodal = false;
      //   this.tempImageStorage = null;
      // },

      // addImageFile() {
      //   console.log('addImageFile');
      //   const reader = new FileReader();
      //   reader.readAsDataURL(this.$refs[`fileInput${this.selectedrowID}`].files[0]);
      //   reader.onload = () => {
      //     this.tempImageStorage = reader.result;
      //     this.allowConfirm = true;

      //     this.$nextTick(() => {
      //       const buttonRef = this.$refs.focusafterfilepick;
      //       buttonRef.focus();
      //     });
      //   };

      // },

      // async confirmUpload() {
      //   // console.log('confirmUpload')
      //   const uploadObject = {
      //     id: this.selectedrowID,
      //     image: this.tempImageStorage,
      //     urlEnding: this.selectedrowUrlEnding
      //   }

      //   try {
      //     if (!uploadObject.image || uploadObject.image === 'undefined') {
      //       console.error('No image provided for upload.')
      //       return;
      //     } 
      //     await this.$store.dispatch('updateInfoboxImage', uploadObject)
      //   } catch (err) {
      //     console.log(err)
      //     this.loading = false
      //   } finally {
      //     this.clearModal();
      //   }
      // }
    },
  }
</script>

<style lang="scss" scoped>

input {
  height: 1.5rem;
}

.uploadmodal {
  position: fixed;
  z-index: 999;
  top: 20%;
  left: 50%;
  width: 600px;
  margin-left: -300px;
  height: 60vh;
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 16px grey;

  .modalsection {
    display: flex;
    flex-direction: row;
  }
}

.fullcover {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.close-modal-button {
  margin-left: 3rem;
  height: 2.4rem;
}

.preview-image {
  width:75%;
  display: block;
  margin: 0px auto;
  margin-top: 10px;
  max-height: 50%;
  height: 50%;
  object-fit: contain;
}

.hints {
  font-size: small;
  margin: 10px 0px 0px 10px;
}

.add-image-button {
  border-radius: $radius-s;
  background-color: $c-secondary;
  color: $c-primary;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.cancel-add-image {
  border-radius: $radius-s;
  background-color: #E3E6E9;
  color: $c-primary;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;  
}

.blocked-add-image {
  border-radius: $radius-s;
  background-color: #f2f5f7;
  color: $c-primary;
  border: none;
  font-size: 1rem;
  font-weight: 600;
}

.edit-uisettings {
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  min-width: min(100%, 800px);

  &-form {
    padding: 25px 0px;
    gap: 25px;
    min-width: min(100%, 800px);

    &-display {
      border-radius: $radius-s;
      outline: 3px solid $c-background-1;
      outline-offset: -1px;
      height: 29px;
      align-self: center;
    }
  }
}

.default-form-field-uisettings-edit-row {
  grid-template-columns: 500px repeat(3, 1fr) 100px 100px;
}

.sub {
  margin: 10px 10px 10px 100px;
  grid-template-columns: 400px repeat(3, 1fr) 100px 100px;
}
</style>