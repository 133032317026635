<template>



  <div class="edit-component" :style="`height: calc((100vh - 60px) * ${height} - 10px); width: calc(100vw * ${width} - 10px);`">

    <div class="compact-form-button-sticky">
        <LoadingButton @click="newDocument ? createComponent() : updateComponent()" :loading="loading" :disabled="!formValid">{{newDocument ? 'Create' : 'Update'}}</LoadingButton>              
        <label class="header-label-code" for="code">{{ c.code }}</label>        
        <label class="header-label-combinedCode" for="combinedCode">{{ c.combinedCode }}</label>        
        <LoadingButton v-if="!newDocument && superUser" @click="showConfirm = true" class="delete-button">Delete</LoadingButton>
      </div>

      
    <!-- <h3>{{ c.combinedCode }}</h3> -->

    <form class="compact-form edit-component-form" @submit.prevent="">

      <div v-if="newDocument" class="compact-form-field">
        <label class="compact-form-field-label" for="name">Code</label>
        <input class="compact-form-field-input" type="text" id="Code" name="Code" v-model="c.code">
      </div>

       <div v-if="newDocument" class="compact-form-field">
        <label class="compact-form-field-label" for="name">Combined Code</label>
        <input class="compact-form-field-input" type="text" id="CombinedCode" name="CombinedCode" v-model="c.combinedCode">
      </div>

      <div class="compact-form-field">
        <button @click="$emit('copy',{ p: 'name', v: c.name, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <label class="compact-form-field-label" for="name">Name</label>
        <input class="compact-form-field-input" type="text" id="name" name="name" v-model="c.name">
        <button @click="$emit('copy',{ p: 'name', v: c.name, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>

      <div class="compact-form-field">
        <button @click="$emit('copy',{ p: 'shortName', v: c.shortName, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <label class="compact-form-field-label" for="shortName">Short name</label>
        <input class="compact-form-field-input" type="text" id="shortName" name="shortName" v-model="c.shortName">
        <button @click="$emit('copy',{ p: 'shortName', v: c.shortName, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>

      <div class="compact-form-field">
        <button @click="$emit('copy',{ p: 'variantShortName', v: c.variantShortName, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <label class="compact-form-field-label" for="variantShortName">Variant short name</label>
        <input class="compact-form-field-input" type="text" id="variantShortName" name="variantShortName" v-model="c.variantShortName">
        <button @click="$emit('copy',{ p: 'variantShortName', v: c.variantShortName, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>

      <div class="compact-form-field">
        <button @click="$emit('copy',{ p: 'size', v: c.size, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <label class="compact-form-field-label" for="size">Size</label>
        <input class="compact-form-field-input" type="text" id="size" name="size" v-model="c.size">
        <button @click="$emit('copy',{ p: 'size', v: c.size, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>

      <div class="compact-form-field">
        <button @click="$emit('copy',{ p: 'category', v: c.category, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <label class="compact-form-field-label" for="category">Categories</label>
        <select name="category" id="category" v-model="c.category">
          <option v-for="(category, i) in categories" :key="i" :value="category">{{ category }}</option>
        </select>
        <div class="spacer" />
        <button @click="$emit('copy',{ p: 'category', v: c.category, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>

 
        <div class="compact-form-field">
          <button @click="$emit('copy', { p: 'glasTypes', v: c.glasTypes, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
            <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
          </button>
         
        <label class="compact-form-field-label" >GlasTypes</label>

        <div class="compact-form-field-colors">
          <div class="compact-form-field-colors-header compact-form-field-colors-edit-row">
            <span>Name</span>
            <span>Acronym</span>
            <span>code</span>
            <span></span>
          </div>

          <div v-for="(glasType, i) in c.glasTypes" :key="i" class="compact-form-field-colors-edit-row" >
            <!-- <input type="text" class="compact-form-field-input" v-model.trim="color.name"> -->
            <select name="category" id="category" v-model="glasType.name" @change="updateGlasType($event.target.value, i)">
              <option v-for="(menuGlasTypes, i) in menuGlasTypes" :key="i" :value="menuGlasTypes.name">{{ `${menuGlasTypes.name} ${menuGlasTypes.acronym}` }}</option>
            </select>
            <input type="text" class="compact-form-field-input" readonly v-model.trim="glasType.acronym">
            <input type="text" class="compact-form-field-input" v-model.trim="glasType.code">
            <button @click="removeGlasType(i)" class="compact-form-field-button">
              <mdicon name="close" class="compact-form-field-button-icon" size="16" />
            </button>
          </div>

      

          <button class="compact-form-field-button" @click="addGlasType()">
            <mdicon name="plus" class="compact-form-field-button-icon" size="18" />
          </button>
        </div>

        <button @click="$emit('copy',{ p: 'glasTypes', v: c.glasTypes, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>




      <!-- /* -------------------------------------------------------------------------- */
      /*                                glastype block                               */
      /* -------------------------------------------------------------------------- */ -->
        <!-- <div class="compact-form-field">
        <button @click="$emit('copy',{ p: 'glasType', v: c.glasType, s: side })" class="compact-form-field-copy" 
          title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <label class="compact-form-field-label" for="glasType">Glass Types</label>
        <select name="glasType" id="glasType" v-model="c.glasType">
          <option v-for="(glasType, i) in menuGlasTypes" :key="i" :value="glasType">{{ glasType.name }}</option>
        </select>
        <div class="spacer" />
        <button @click="$emit('copy',{ p: 'glasType', v: c.glasType, s: side })" class="compact-form-field-copy" 
          title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div> -->


      <!--  -->
        
      <!-- <div class="compact-form-field">
          <button @click="$emit('copy', { p: 'glasTypes', v: c.glasTypes, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
            <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
          </button>

          <label class="compact-form-field-label" >Glass Types</label>

          <div class="compact-form-field-options">
            <div class="compact-form-field-options-header compact-form-field-options-edit-row">
              <span>Name</span>
              <span>Acronym</span>
              <span>Code</span>
            </div>

            <div v-for="(glasType, i) in c.glasTypes" :key="i" class="compact-form-field-options-edit-row">
              <input type="text" class="compact-form-field-input" v-model.trim="glasType.name">
              <input type="text" class="compact-form-field-input" v-model.trim="glasType.acronym">
              <input type="text" class="compact-form-field-input" v-model.trim="glasType.code">
              <button class="compact-form-field-button" @click="removeGlasType(i)">
                <mdicon name="minus" class="compact-form-field-button-icon" size="18" />
              </button>
            </div>

            <button class="compact-form-field-button" @click="addGlasType()">
              <mdicon name="plus" class="compact-form-field-button-icon" size="18" />
            </button>
          </div>

          <button @click="$emit('copy', { p: 'glasTypes', v: c.glasTypes, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
            <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
          </button>
        </div>       -->

        <!--  -->
  <!--      /* -------------------------------------------------------------------------- */
/*                               end glastype block                               */
/* -------------------------------------------------------------------------- */ -->

      <div class="compact-form-field">
        <button @click="$emit('copy',{ p: 'trueOnline', v: c.trueOnline, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <label class="compact-form-field-label" for="trueOnline">True Online</label>
        <!-- <input cclass="compact-form-field-input" type="checkbox" id="trueOnline" name="trueOnline" v-model="c.trueOnline"> -->

        <input class="checkbox" type="checkbox" :id="`admin-checkbox-${side}`" name="admin-checkbox" v-model="c.trueOnline">
        <label :for="`admin-checkbox-${side}`" class="checkbox-label">
          <mdicon name="check-bold" class="checkbox-label-icon" :class="c.trueOnline ? 'checkbox-label-icon-active' : ''" size="15" />
        </label>
        <div class="spacer" />
        <button @click="$emit('copy',{ p: 'trueOnline', v: c.trueOnline, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>

      <div class="compact-form-field">
        <button style="visibility: hidden" @click="$emit('copy',{ p: 'trueOnline', v: c.trueOnline, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <label class="compact-form-field-label" for="dimensionx">Dimension x</label>
        <input class="compact-form-field-input" type="number" id="dimensionx" name="dimensionx" v-model="c.dimensions.x">
        <button style="visibility: hidden" @click="$emit('copy',{ p: 'dimensions', v: c.dimensions, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>

      <div class="compact-form-field">
        <button @click="$emit('copy',{ p: 'dimensions', v: c.dimensions, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <label class="compact-form-field-label" for="dimensiony">Dimension y</label>
        <input class="compact-form-field-input" type="number" id="dimensiony" name="dimensiony" v-model="c.dimensions.y">
        <button @click="$emit('copy',{ p: 'dimensions', v: c.dimensions, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>

      <div class="compact-form-field">
        <button @click="$emit('copy',{ p: 'dimensions', v: c.dimensions, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <label class="compact-form-field-label" for="dimensiony">Dimension z</label>
        <input class="compact-form-field-input" type="number" id="dimensiony" name="dimensiony" v-model="c.dimensions.z">
        <button @click="$emit('copy',{ p: 'dimensions', v: c.dimensions, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>

      <div class="compact-form-field">
        <button style="visibility: hidden" @click="$emit('copy',{ p: 'trueOnline', v: c.trueOnline, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <label class="compact-form-field-label" for="dimensionxo">Dimension x offset</label>
        <input class="compact-form-field-input" type="number" id="dimensionxo" name="dimensionxo" v-model="c.dimensions.xo">
        <button style="visibility: hidden" @click="$emit('copy',{ p: 'dimensions', v: c.dimensions, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>

      <div class="compact-form-field">
        <button style="visibility: hidden" @click="$emit('copy',{ p: 'trueOnline', v: c.trueOnline, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <label class="compact-form-field-label" for="dimensionyo">Dimension y offset</label>
        <input class="compact-form-field-input" type="number" id="dimensionyo" name="dimensionyo" v-model="c.dimensions.yo">
        <button style="visibility: hidden" @click="$emit('copy',{ p: 'dimensions', v: c.dimensions, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>

      <div class="compact-form-field">
        <button style="visibility: hidden" @click="$emit('copy',{ p: 'trueOnline', v: c.trueOnline, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <label class="compact-form-field-label" for="dimensionzo">Dimension z offset</label>
        <input class="compact-form-field-input" type="number" id="dimensionzo" name="dimensionzo" v-model="c.dimensions.zo">
        <button style="visibility: hidden" @click="$emit('copy',{ p: 'dimensions', v: c.dimensions, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>

      <!-- word momenteel alleen gebruikt voor lichtstraten -->
      <div class="compact-form-field">
        <label class="compact-form-field-label">Margins (lichtstraten):</label>
        <div class="margin-container">
          <div class="margin-flex-container">
            <label class="compact-form-field-label">margin overhang</label>
            <label class="compact-form-field-label">margin paneel dikte</label>
          </div>
        </div>
        <label class="compact-form-field-label" for="dimensionxo2">Margin X-pos</label>
        <div class="margin-container"><div class="margin-flex-container">
          <input class="compact-form-field-input" type="number" id="dimensionzo2" name="dimensionzo2" v-model="c.margins.xPos1">
          <input class="compact-form-field-input" type="number" id="dimensionzo2" name="dimensionzo2" v-model="c.margins.xPos2">
        </div></div>
        <label class="compact-form-field-label" for="dimensionxo2">Margin X-neg</label>
        <div class="margin-container"><div class="margin-flex-container">
          <input class="compact-form-field-input" type="number" id="dimensionzo2" name="dimensionzo2" v-model="c.margins.xNeg1">
          <input class="compact-form-field-input" type="number" id="dimensionzo2" name="dimensionzo2" v-model="c.margins.xNeg2">
        </div></div>
        <label class="compact-form-field-label" for="dimensionxo2">Margin Y-pos</label>
        <div class="margin-container"><div class="margin-flex-container">
          <input class="compact-form-field-input" type="number" id="dimensionzo2" name="dimensionzo2" v-model="c.margins.yPos1">
          <input class="compact-form-field-input" type="number" id="dimensionzo2" name="dimensionzo2" v-model="c.margins.yPos2">
        </div></div>
        <label class="compact-form-field-label" for="dimensionxo2">Margin Y-neg</label>
        <div class="margin-container"><div class="margin-flex-container">
          <input class="compact-form-field-input" type="number" id="dimensionzo2" name="dimensionzo2" v-model="c.margins.yNeg1">
          <input class="compact-form-field-input" type="number" id="dimensionzo2" name="dimensionzo2" v-model="c.margins.yNeg2">
        </div></div>
        <label class="compact-form-field-label" for="dimensionxo2">Margin Z-pos</label>
        <div class="margin-container"><div class="margin-flex-container">
          <input class="compact-form-field-input" type="number" id="dimensionzo2" name="dimensionzo2" v-model="c.margins.zPos1">
          <input class="compact-form-field-input" type="number" id="dimensionzo2" name="dimensionzo2" v-model="c.margins.zPos2">
        </div></div>
        <label class="compact-form-field-label" for="dimensionxo2">Margin Z-neg</label>
        <div class="margin-container"><div class="margin-flex-container">
          <input class="compact-form-field-input" type="number" id="dimensionzo2" name="dimensionzo2" v-model="c.margins.zNeg1">
          <input class="compact-form-field-input" type="number" id="dimensionzo2" name="dimensionzo2" v-model="c.margins.zNeg2">
        </div></div>
      </div>


      <div class="compact-form-field">
        <button @click="$emit('copy',{ p: 'options', v: c.options, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>

        <label class="compact-form-field-label" >Options</label>

        <div class="compact-form-field-options">
          <div class="compact-form-field-options-header compact-form-field-options-edit-row">
            <span>Name</span>
            <span>subCode</span>
            <span></span>
          </div>

          <div v-for="(option, i) in c.options" :key="i" class="compact-form-field-options-edit-row">
            <input type="text" class="compact-form-field-input" v-model.trim="option.name">
            <input type="text" class="compact-form-field-input" v-model.trim="option.subCode">
            <button class="compact-form-field-button" @click="removeSubCode(i)">
              <mdicon name="minus" class="compact-form-field-button-icon" size="18" />
            </button>
          </div>

          <button class="compact-form-field-button" @click="addSubCode()">
            <mdicon name="plus" class="compact-form-field-button-icon" size="18" />
          </button>
        </div>

        <button @click="$emit('copy',{ p: 'options', v: c.options, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>

        <!-- /* -------------------------------------------------------------------------- */
        /*                                 color block                                */
        /* -------------------------------------------------------------------------- */ -->
      <div class="compact-form-field">
        <button @click="$emit('copy',{ p: 'colors', v: c.colors, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <!-- /* ------------------------------- colorBlock ------------------------------- */ -->        
        <label class="compact-form-field-label" >Colors</label>

        <div class="compact-form-field-colors">
          <div class="compact-form-field-colors-header compact-form-field-colors-edit-row">
            <span>Name</span>
            <span>hexCode</span>
            <span>code</span>
            <span></span>
          </div>

          <div v-for="(color, i) in c.colors" :key="i" class="compact-form-field-colors-edit-row" >
            <!-- <input type="text" class="compact-form-field-input" v-model.trim="color.name"> -->
            <select name="category" id="category" v-model="color.name" @change="updateColor($event.target.value, i)">
              <option v-for="(menuColor, i) in menuColors" :key="i" :value="menuColor.name">{{ `${menuColor.name} (${menuColor.hexCode})` }}</option>                         
            </select>
            <input type="text" class="compact-form-field-input" readonly v-model.trim="color.hexCode">
            <input type="text" class="compact-form-field-input" v-model.trim="color.code">
            <button class="compact-form-field-button" @click="removeColor(i)">
              <mdicon name="minus" class="compact-form-field-button-icon" size="18" />
            </button>
          </div>

          <button class="compact-form-field-button" @click="addColor()">
            <mdicon name="plus" class="compact-form-field-button-icon" size="18" />
          </button>
        </div>

        <button @click="$emit('copy',{ p: 'colors', v: c.colors, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>
<!-- /* -------------------------------------------------------------------------- */
/*                               end color block                              */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                variant block                               */
/* -------------------------------------------------------------------------- */ -->
      <div class="compact-form-field">
        <button @click="$emit('copy',{ p: 'variants', v: c.variants, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>

        <label class="compact-form-field-label" >Variants</label>

        <div class="compact-form-field-options">
          <div class="compact-form-field-options-header compact-form-field-options-edit-row">
            <span>Name</span>
            <span>code</span>
            <span></span>
          </div>

          <div v-for="(variant, i) in c.variants" :key="i" class="compact-form-field-options-edit-row">
            <input type="text" class="compact-form-field-input" v-model.trim="variant.name">
            <input type="text" class="compact-form-field-input" v-model.trim="variant.code">
            <button class="compact-form-field-button" @click="removeVariant(i)">
              <mdicon name="minus" class="compact-form-field-button-icon" size="18" />
            </button>
          </div>

          <button class="compact-form-field-button" @click="addVariant()">
            <mdicon name="plus" class="compact-form-field-button-icon" size="18" />
          </button>
        </div>

        <button @click="$emit('copy',{ p: 'variants', v: c.variants, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>
<!-- /* -------------------------------------------------------------------------- */
/*                              end variant block                             */
/* -------------------------------------------------------------------------- */ -->
      <div class="compact-form-field">
        <button @click="$emit('copy',{  p: 'keyWords', v: keyWords, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <label class="compact-form-field-label" for="keyWords">keyWords</label>
        <input class="compact-form-field-input" type="text" id="keyWords" name="keyWords" v-model="keyWords" @change="parseKeyString()">
        <button @click="$emit('copy',{ p: 'keyWords', v: keyWords, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>

      <div class="compact-form-field">
        <button @click="$emit('copy',{  p: 'wallOptions', v: c.wallOptions, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <label class="compact-form-field-label" >Wand opties</label>

        <div class="compact-form-field-boolean">
          <div class="compact-form-field-boolean-row">
            <div class="compact-form-field-boolean-row-item">
              <span>links</span>
              <input class="checkbox" type="checkbox" :id="`wall-option-l-${side}`" name="admin-checkbox" v-model="c.wallOptions.left">
              <label :for="`wall-option-l-${side}`" class="checkbox-label">
                <mdicon name="check-bold" class="checkbox-label-icon" :class="c.wallOptions.left ? 'checkbox-label-icon-active' : ''" size="15" />
              </label>
            </div>

            <div class="compact-form-field-boolean-row-item">
              <span>midden</span>
              <input class="checkbox" type="checkbox" :id="`wall-option-m-${side}`" name="admin-checkbox" v-model="c.wallOptions.center">
              <label :for="`wall-option-m-${side}`" class="checkbox-label">
                <mdicon name="check-bold" class="checkbox-label-icon" :class="c.wallOptions.center ? 'checkbox-label-icon-active' : ''" size="15" />
              </label>
            </div>
            
            <div class="compact-form-field-boolean-row-item">
              <span>rechts</span>
              <input class="checkbox" type="checkbox" :id="`wall-option-r-${side}`" name="admin-checkbox" v-model="c.wallOptions.right">
              <label :for="`wall-option-r-${side}`" class="checkbox-label">
                <mdicon name="check-bold" class="checkbox-label-icon" :class="c.wallOptions.right ? 'checkbox-label-icon-active' : ''" size="15" />
              </label>
            </div>
          </div>
        </div>

        <button @click="$emit('copy',{ p: 'wallOptions', v: c.wallOptions, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>

       <div class="compact-form-field">
        <button @click="$emit('copy',{  p: 'facadeOptions', v: c.facadeOptions, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'R'">
          <mdicon name="arrow-left" class="compact-form-field-copy-icon" size="16" />
        </button>
        <label class="compact-form-field-label" >Gevel opties</label>

        <div class="compact-form-field-boolean">
          <div class="compact-form-field-boolean-row">
            <div class="compact-form-field-boolean-row-item">
              <span>links</span>
              <input class="checkbox" type="checkbox" :id="`facade-option-l-${side}`" name="admin-checkbox" v-model="c.facadeOptions.left">
              <label :for="`facade-option-l-${side}`" class="checkbox-label">
                <mdicon name="check-bold" class="checkbox-label-icon" :class="c.facadeOptions.left ? 'checkbox-label-icon-active' : ''" size="15" />
              </label>
            </div>

            <div class="compact-form-field-boolean-row-item">
              <span>midden</span>
              <input class="checkbox" type="checkbox" :id="`facade-option-m-${side}`" name="admin-checkbox" v-model="c.facadeOptions.center">
              <label :for="`facade-option-m-${side}`" class="checkbox-label">
                <mdicon name="check-bold" class="checkbox-label-icon" :class="c.facadeOptions.center ? 'checkbox-label-icon-active' : ''" size="15" />
              </label>
            </div>
            
            <div class="compact-form-field-boolean-row-item">
              <span>rechts</span>
              <input class="checkbox" type="checkbox" :id="`facade-option-r-${side}`" name="admin-checkbox" v-model="c.facadeOptions.right">
              <label :for="`facade-option-r-${side}`" class="checkbox-label">
                <mdicon name="check-bold" class="checkbox-label-icon" :class="c.facadeOptions.right ? 'checkbox-label-icon-active' : ''" size="15" />
              </label>
            </div>
          </div>
        </div>

        <button @click="$emit('copy',{ p: 'facadeOptions', v: c.facadeOptions, s: side })" class="compact-form-field-copy" title="kopiëer naar rechts" v-if="isSplit && side === 'L'">
          <mdicon name="arrow-right" class="compact-form-field-copy-icon" size="16" />
        </button>
      </div>

      <!-- <div class="compact-form-button">
        <LoadingButton @click="newDocument ? createComponent() : updateComponent()" :loading="loading" :disabled="!formValid">{{newDocument ? 'Create' : 'Update'}}</LoadingButton>
      
        <LoadingButton v-if="!newDocument && superUser" @click="showConfirm = true" class="delete-button">Delete</LoadingButton>
      </div> -->

     

    </form>

    <div class="edit-component-actions" v-if="actions">
      <button class="edit-component-actions-button" title="New document" @click="insertNewDocument()">
        <mdicon name="plus" class="edit-component-new-button-icon" size="25" />
      </button>

      <button class="edit-component-actions-button" title="Split view" @click="$emit('splitview')">
        <mdicon :name="isSplit ? `arrow-collapse-horizontal` : `arrow-split-vertical`" class="edit-component-new-button-icon" size="25" />
      </button>
    </div>

    <div class="modal" v-show="showConfirm" > 
      <ConfirmModal 
        message="Weet u zeker dat u dit component wilt verwijderen?" 
        @confirm="deleteComponent()"
        @cancel="showConfirm = false"
      />
    </div>
  </div>
</template>

<script>
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'
  import ConfirmModal from '@/components/modals/ConfirmModal.vue'
  import { mapGetters } from 'vuex'
  import isEqual from 'lodash.isequal'

  export default {
    name: "CMSComponent",

    components: {
      LoadingButton,
      ConfirmModal
    },

    props: {
      id: {
        typeof: String
      },
      component: {
        typeof: Object
      },
      height: {
        typeof: Number,
        default: 0.5,
      },
      width: {
        typeof: Number,
        default: 1,
      },
      isSplit: {
        typeof: Boolean,
        default: false,
      },
      actions: {
        typeof: Boolean,
        default: true
      },
      side: {
        typeof: String,
      },
      setCopyData: {
        typeof: Object,
      }
    },

    data () {
      return {
        // id: null,
        loading: false,
        showConfirm: false,
        fields: [
          {
            label: "trueOnline",
            value: "trueOnline",
            type: "boolean",
          },
          {
            label: "dimensions",
            value: "dimensions",
            type: "object",
            properties: [
              {
                label: "x",
                value: "x",
                type: "number",
              },
              {
                label: "y",
                value: "y",
                type: "number",
              },
              {
                label: "z",
                value: "z",
                type: "number",
              },
              {
                label: "xo",
                value: "xo",
                type: "number",
              },
              {
                label: "yo",
                value: "yo",
                type: "number",
              },
              {
                label: "zo",
                value: "zo",
                type: "number",
              },
            ]
          }
        ],
        // categories: ['Deur', 'Raam', 'Steellook'],
        // TASK-1394 : more categories needed for filtering in the configurator
        categories: ['Deur', 'Raam', 'Steellook', 'Steellook Wand BW', 'Steellook Raam', 'Steellook Deur', 'Steellook Raam BW'],
        // c: {},
        formValid: false,
        keyWords: "",
        c: {},
        error: false,
        newDocument: false,
      }
    },

    methods: {
      // setId () {
      //   this.id = this.$route.params.id
      // },

      parseKeyWords (words) {
        let string = ""

        for (let i = 0; i < words.length; i++) {
          string += `${words[i]}, `
        }

        return string
      },

      parseKeyString () {
        let valuesArray = this.keyWords.split(",")
        let words = valuesArray.map((x) => x.trim())
        this.c.keyWords = words.filter((x) => x)
      },

      // async getComponent () {
      //   await this.$store.dispatch('getComponent', this.id)
      //   this.c = JSON.parse(JSON.stringify(this.component))
      //   this.keyWords =  this.parseKeyWords(this.component.keyWords)
      // },

      setComponent () {
        this.c = JSON.parse(JSON.stringify(this.component))
        this.keyWords =  this.component.keyWords
        this.newDocument = false
        console.log('this.c')
        console.log(this.c)
      },  

      async updateComponent () {
        this.loading = true
        this.error = false
        // console.log('test update',this.c)
        try {
          await this.$store.dispatch('updateComponent', this.c)
          this.error = false
        } catch (err) {
          this.loading = false
          this.error = true
        }

        if (!this.error) {

          this.$emit('refresh', {c: JSON.parse(JSON.stringify(this.c)), side: this.side})

          this.c = JSON.parse(JSON.stringify(this.component))
          this.loading = false

        }
      },

      async createComponent () {

        this.loading = true
        this.error = false
        
        try {
          console.log("c", this.c)
          await this.$store.dispatch('createComponent', this.c)
          this.error = false
        } catch (err) {
          this.loading = false
          this.error = true
        }

        if (!this.error) {

          this.$emit('newData', JSON.parse(JSON.stringify(this.newComponent)))

          this.c = JSON.parse(JSON.stringify(this.newComponent))
          this.loading = false
          this.newDocument = false
        }
      }, 

      async deleteComponent () {
        try {
          await this.$store.dispatch('deleteComponent', this.c.id)
          this.error = false
        } catch (err) {
          this.error = true
          this.showConfirm = false
        }

        if (!this.error) {

          this.$emit('newData')

          this.insertNewDocument()

          this.showConfirm = false
        }
      },

      async getMenuColors () {
        if (this.menuColors.length === 0) {
          await this.$store.dispatch('getMenuColors')
        }
      },
      updateColor (value, i) {
        this.c.colors[i].hexCode = this.menuColors.find((color) => color.name === value).hexCode
      },
      addColor () {
        this.c.colors.push({
          name: "",
          hexCode: "",
          code: "",
        })
      },
      removeColor (i) {
        this.c.colors.splice(i, 1)
      },

      addVariant () {
        this.c.variants.push({
          name: "",
          code: "",
        })        
      },
      removeVariant (i) {
        this.c.variants.splice(i, 1)
      },

      async getMenuGlasTypes () {
        if (this.menuGlasTypes.length === 0) {
          await this.$store.dispatch('getMenuGlasTypes')
        }
      },      
      
      updateGlasType (value, i) {
        this.c.glasTypes[i].acronym = this.menuGlasTypes.find((glasTypes) => glasTypes.name === value).acronym
      },

      addGlasType () {
        this.c.glasTypes.push({
          name: "",
          acronym: "",
          code: "",
        })        
      },
      removeGlasType (i) {
        this.c.glasTypes.splice(i, 1)
      },      

      addSubCode () {
        this.c.options.push({
          name: "",
          subCode: "",
        })
      },
      removeSubCode (i) {
        this.c.options.splice(i, 1)
      },

      insertNewDocument () {
        this.c = {
          code: "",
          combinedCode: "",
          category: "",
          glasTypes: [],
          name: "",
          shortName: "",
          variantShortName: "", //
          variants: [], //
          trueOnline: false,
          colors: [],
          dimensions: {
            x: 0,
            y: 0,
            z: 0,
            xo: 0,
            yo: 0,
            zo: 0,
          },
          margins: {
            xPos1: 0,
            xPos2: 0,
            xNeg1: 0,
            xNeg2: 0,
            yPos1: 0,
            yPos2: 0,
            yNeg1: 0,
            yNeg2: 0,
            zPos1: 0,
            zPos2: 0,
            zNeg1: 0,
            zNeg2: 0,
          },
          keyWords: "",
          options: [],
          size: "",
          wallOptions: {
            left: false,
            center: false,
            right: false,
          },
           facadeOptions: {
            left: false,
            center: false,
            right: false,
          }
        }

        this.newDocument = true
        this.keyWords =  ""
      },

      setCopy () {
        if (this.setCopyData) {
          if (this.setCopyData.p === 'keyWords') {
            this.keyWords = this.setCopyData.v
          } else {
            this.c[this.setCopyData.p] = this.setCopyData.v
          }
        }
      }
    },

    computed: {
      ...mapGetters({
        // component: 'getCurrentComponent',
        menuColors: "getMenuColors",
        newComponent: 'getNewComponent',
        superUser: 'isSuperUser',
        menuGlasTypes: "getMenuGlasTypes", //
      }),
    },

    created () {
      this.getMenuColors()
      this.getMenuGlasTypes()
    },

    watch: {
      // $route () {
      //   this.setId()
      //   this.getComponent()
      // },

      component: {
        deep: true,
        immediate: true,
        handler () {
          this.setComponent()
        }
      },

      c: {
        deep: true,
        immediate: false,
        handler () {
          if (this.newDocument) {
            this.formValid = true
          } else if (isEqual(this.component, this.c) ) {
            this.formValid = false
          } else {
            this.formValid = true
          }
        }
      },

      setCopyData: {
        deep: true,
        immediate: true,
        handler () {
          this.setCopy()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

.edit-component {
  
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px;
  align-items: center;
  overflow-y: scroll;
  min-height: 50px;

  

  & h1 {
    margin: 0px;
  }

  &-form {
    max-width: 1000px;
    width: min(1000px, 100%);
  }

  &-actions {
    position: fixed;
    left: 25px;
    top: 85px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-button {
      border: none;
      background-color: $c-secondary;
      border-radius: $radius-m-1;
      height: 35px;
      width: 35px;
      box-shadow: $shadow-m-0;
      transition: color 100ms ease;
      cursor: pointer;

      &:hover {
        background-color: $c-secondary-hover;
      }

      &-icon {
        color: $c-primary;
      }
    }
  }
}

.default-form-field-label {
  min-width: 120px;
}

.delete-button {
  background-color: $c-error;
  color: white;

  &:hover {
    background-color: $c-error-hover;
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  z-index: 99;
  display: flex;
}

.compact-form-button-sticky {  
  display: flex;
  width: calc(100% - 1%);
  height: 35px;
  align-content: center;
  justify-content: space-evenly;
  border-radius: $radius-s;
  background-color: #edf2f7;
  color: $c-transparent-1;
  border: none;
  padding: 5px 5px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 100ms ease, color 100ms ease;
  
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  // background-color: #666;
  // padding: 40px;
  // font-size: 25px;

  &:disabled {
    background-color: $c-background-1;
    cursor: default;
  }

  &-loading {
    background-color: $c-primary;
  } 

}

.header-label-code{
  font-size: 1.5rem;
  font-weight: bolder;
  font-family: "lato";  
  }

  .header-label-code::before{
    content: "code  ";
    font-size: 1.0rem;
    // font-weight: bolder;
    font-family: "lato";
    font-style: italic;      
  }

  .header-label-combinedCode{
  font-size: 1.5rem;
  font-weight: bolder;
  font-family: "lato";
  font-style: italic;
  color: lightgray;  
  }

  .header-label-combinedCode::before{
    content: "combinedCode  ";
    font-size: 1.0rem;
    // font-weight: bolder;
    font-family: "lato";
    font-style: italic;
    color: lightgray;  
  }

.margin-container {
  display: inline-block;
  width: calc(100% - 165px);
}
.margin-flex-container {
  display: flex;
  gap: 5px;
  width: 100%;
  height: 22px;
}
.margin-flex-container > * {
  flex: 1;
  margin: 0;
  padding: 0;
}

</style>