import SelectElement from './selectElement.js'
import * as boxBuffer from '@/three/buffer-geometries/boxBuffer.js'
import * as planeBuffer from '@/three/buffer-geometries/plane.js'
import * as planeBorderBuffer from '@/three/buffer-geometries/planeBorder.js'
import * as triangleBuffer from '@/three/buffer-geometries/triangleBuffer.js'
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry.js'
import Betonband from './betonBand.js';
import Betonpoer from '../betonPoer.js';
import { v4 as uuid } from 'uuid';

export default class SteellookComponent {
  constructor(THREE, textureLoader, y, set, dimensions, model, map, staanderObject, font, totalOverhang, modelCode, settings, topgevelSpace, poer, wallConfig, dy, textureHoekprofielen, hasBetonBand) {
    // WARNING: make sure that using the settings param above does not result in uuid errors (see below)
    console.log("MODELSET", THREE, textureLoader, set, dimensions, model, map, staanderObject, modelCode)
    this.THREE = THREE
    this.textureLoader = textureLoader
    this.dimensions = dimensions
    this.model = model
    this.map = map
    this.group = null
    this.mapGroup = null
    this.modelGroup = null
    this.y = y
    this.set = set
    this.font = font
    this.totalOverhang = totalOverhang
    this.settings = settings || {}
    this.settings.width = this.set.mx
    this.settings.position = {x: this.set.z, y: this.set.y, z: this.set.x}
    if(!this.settings.uuid){this.settings.uuid = uuid()}
    this.settings.modelArray = []
    this.settings.isSteellook = true
    this.settings.rotation = set.rotation
    this.settings.tussenMuur = set.tussenMuur
    this.settings.modelCode = modelCode
    this.settings.dimensions = dimensions
    // hasBetonBand depends on keyword "noBetonband"
    this.settings.hasBetonBand = hasBetonBand //
    this.parentWall = null
    this.childWall = null
    this.staanderObject = staanderObject
    this.wallStaander = null
    this.wallStaanderInfo = null
    this.mapWallStaander = null
    this.wallOn = true
    this.isLocked = true
    this.isSteellook = true
    this.betonBand = null 
    this.betonPoer = null
    this.fundering = true // previously used for setting betonBand visibility
    this.modelCode = modelCode
    this.alignsXAxis = (set.rotation % Math.PI === 0)  //if wall is rotated vertical or horizontal       | or _    as seen from above
    this.alignedRotation = this.alignsXAxis ? (this.set.rotation % (Math.PI*2)/Math.PI) : ((this.set.rotation-Math.PI/2) % (Math.PI*2)/Math.PI)//if wall is rotated 180 degrees on its y-axis
    this.topgevelSpace = (set.rotation % Math.PI === 0)?topgevelSpace:0
    this.poer = poer
    this.wallConfig = wallConfig
    this.dy = dy
    this.textureHoekprofielen = textureHoekprofielen

    this.groupOffset = {
      x: 0,
      z: 0
    }

    this.hoekProfiel = {
      left: null,
      right: null
    }

    this.hoekProfielConfig = {
      shouldRight: false, 
      shouldLeft: false, 
      shouldRightBoeideel: false, 
      shouldLeftBoeideel: false
    }

    this.build(THREE, dimensions, staanderObject)
  }

  build(THREE, dimensions, staanderObject) {
    this.group = new THREE.Group()
    this.group.name = "wall"
    this.mapGroup = new THREE.Group()
    this.modelGroup = new THREE.Group()

    /*const set = {
      mx: dimensions.x+2,
      mz: staanderObject.dx+10,
      my: dimensions.y-dimensions.yo+2,
    }*/
    console.log(dimensions,staanderObject)
    const modelBox = new SelectElement(THREE, dimensions.x+20, dimensions.y-dimensions.yo+20, staanderObject.dx+10, this.set.rotation)
    modelBox.group.position.x = dimensions.x/2
    modelBox.group.position.y = (dimensions.yo + dimensions.y) / 2
    modelBox.group.rotation.y = this.set.rotation //0.5 * Math.PI
    modelBox.group.visible = false
    modelBox.group.name = "wallBox"
    this.selectFrame = modelBox

    this.model.rotation.y = 0.5 * Math.PI
    this.model.position.x = dimensions.xo
    this.model.position.y = dimensions.yo
    this.model.position.z = dimensions.zo

    this.map.rotation.y = 0.5 * Math.PI
    this.map.position.x = dimensions.xo
    this.map.position.z = dimensions.zo

    this.model.traverse( function ( obj ) {
      if ( obj.isMesh ) {
        obj.castShadow = true; 
        obj.receiveShadow = true;
      }
    })
    

    this.betonBand = new Betonband(this.THREE, this.set.mx);
    // hasBetonBand depends on keyword "noBetonband"
    this.betonBand.betonBand.visible = this.settings.hasBetonBand // this.fundering

    this.group.add(modelBox.group, this.betonBand.betonBand);
    this.mapGroup.add(this.map);
    this.modelGroup.add(this.model);




    //maatlijnen
    const betweenLines = 200
    const betweenTextAndLine = 20
    const lineWidth = 20
    const alignLineWidth = 10
    const arrowLength = 200
    const arrowWidth = 120
    const overhang = this.settings.tussenMuur ? 0 : (this.alignsXAxis ? (this.alignedRotation ? this.totalOverhang.l : this.totalOverhang.r) : (this.alignedRotation ? this.totalOverhang.v : this.totalOverhang.a))

    const triangleMaterial = this.shaderMaterial('#505051')

    const arrowGeometry = new THREE.BufferGeometry()
    arrowGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(triangleBuffer.positionBuffer(arrowLength, arrowWidth)), 3))
    arrowGeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(triangleBuffer.uvBuffer()), 2))
    arrowGeometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(triangleBuffer.normalBuffer()), 3))

    const lineGeometry = new THREE.BufferGeometry()
    lineGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(planeBuffer.positionBuffer(lineWidth, this.set.mx - (2 * arrowLength))), 3))
    lineGeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(planeBuffer.uvBuffer()), 2))
    lineGeometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(planeBuffer.normalBuffer()), 3))

    const arrow0 = new THREE.Mesh(arrowGeometry, triangleMaterial);
    const arrow1 = arrow0.clone()
    const line0 = new THREE.Mesh(lineGeometry, triangleMaterial);
    arrow0.rotation.y = 270 * Math.PI / 180
    arrow0.position.set(arrowLength, 0, this.staanderObject.dx/2+overhang+betweenLines-arrowWidth/2+lineWidth/2 +this.topgevelSpace)
    arrow1.rotation.y = 90 * Math.PI / 180
    arrow1.position.set(this.set.mx - arrowLength, 0, this.staanderObject.dx/2+overhang+betweenLines+arrowWidth/2+lineWidth/2 +this.topgevelSpace) 
    line0.position.set(arrowLength, 0, this.staanderObject.dx/2+overhang+betweenLines +this.topgevelSpace)
    line0.name = 'line0'

    const alignLineGeometry = new THREE.BufferGeometry()
    alignLineGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(planeBuffer.positionBuffer(overhang+betweenLines+arrowWidth/2+lineWidth/2 +this.topgevelSpace, alignLineWidth)), 3))
    alignLineGeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(planeBuffer.uvBuffer()), 2))
    alignLineGeometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(planeBuffer.normalBuffer()), 3))

    const alignLine0 = new THREE.Mesh(alignLineGeometry, triangleMaterial);
    const alignLine1 = alignLine0.clone()

    alignLine0.position.set(-alignLineWidth, 0, this.set.mz / 2)
    alignLine0.rotation.y = 0 * Math.PI / 180
    alignLine1.position.set(this.set.mx, 0, this.set.mz / 2)
    alignLine1.rotation.y = 0 * Math.PI / 180


    const totalWidth = (Math.round(this.set.mx*2)/2).toString().replaceAll('.', ',')
    
    // console.log(font)
    const textGeometry = new TextGeometry(totalWidth, {
      font: this.font,
      size: 125,
      height: 1,
    })

    const text0 = new THREE.Mesh(textGeometry, triangleMaterial)

    const box0 = new THREE.Box3().setFromObject(text0);
    const vector0 = new THREE.Vector3()
    box0.getSize(vector0)
    this.box = box0
    this.vector = vector0

    if (this.set.rotation * 180 / Math.PI === 270) {
      console.log(vector0)
      text0.position.set((this.set.mx - vector0.x) / 2, 0, this.staanderObject.dx/2+overhang+betweenLines+lineWidth+betweenTextAndLine+vector0.y)
      text0.rotation.x = 270 * Math.PI / 180
      text0.rotation.z = 0 * Math.PI / 180
      //alert('gedraaid')
    } else {
      text0.rotation.x = 270 * Math.PI / 180
      text0.rotation.z = 180 * Math.PI / 180
      text0.position.set((this.set.mx + vector0.x) / 2, 0, this.staanderObject.dx/2+overhang+betweenLines+lineWidth+betweenTextAndLine +this.topgevelSpace)
    }

    this.mapGroup.add(line0, arrow0, arrow1, alignLine0, alignLine1, text0)

  }

  select() {
    this.selected = true
    this.selectFrame.group.visible = true
  }

  deselect() {
    this.selected = false
    this.selectFrame.group.visible = false
  }

  showWallStaander(texture) {
    if(this.wallStaander) {return}
    // geometry
    const geometry1 = new this.THREE.BufferGeometry()
    geometry1.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(this.staanderObject.dx, this.staanderObject.y, this.staanderObject.dz)), 3))
    geometry1.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
    geometry1.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
    geometry1.setIndex(boxBuffer.indexBuffer());
    geometry1.groups = boxBuffer.groupBuffer()

    // console.log(this.staanderObject.frameTexture)
    // texture

    this.wallStaander = new this.THREE.Group(); // this.wallStaander is a THREE Group now
    this.wallStaanderInfo = {x:this.staanderObject.dx, y:this.staanderObject.y, z:this.staanderObject.dz}

    this.mapWallStaander = new this.THREE.Group();

    this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`, (map) => {
      const textureX = map.clone()
      this.textureSettings(textureX,  this.staanderObject.y / 2320,  this.staanderObject.dz / 145,  0.5 * Math.PI)
      const materialX = new this.THREE.MeshStandardMaterial({ map: textureX, metalness: 0, roughness: 1})
      materialX.needsUpdate = true
      materialX.map.needsUpdate = true

      const textureY = map.clone()
      this.textureSettings(textureY, this.staanderObject.dx / 2320,  this.staanderObject.dz / 145, 0.5 * Math.PI)
      const materialY = new this.THREE.MeshStandardMaterial({ map: textureY, metalness: 0, roughness: 1})
      materialY.needsUpdate = true
      materialY.map.needsUpdate = true

      const textureZ = map.clone()
      this.textureSettings(textureZ,  this.staanderObject.y / 2320,  this.staanderObject.dz / 145,  1.5 * Math.PI)
      const materialZ = new this.THREE.MeshStandardMaterial({ map: textureZ, metalness: 0, roughness: 1})
      materialZ.needsUpdate = true
      materialZ.map.needsUpdate = true

      // mesh
      const staander0 = new this.THREE.Mesh( geometry1, [
        materialX,
        materialX,
        materialY,
        materialY,
        materialZ,
        materialZ,
      ]);
      staander0.castShadow = true; 
      staander0.receiveShadow = true;

      staander0.position.y = this.staanderObject.y/2

      // Making Betonpoer class available
      this.betonPoer = new Betonpoer(this.THREE, this.poer, this.fundering);

      this.betonPoer.betonPoer.visible = this.fundering
    
      this.wallStaander.add(staander0, this.betonPoer.betonPoer) // adding staander0 and betonPoer to the wallStaander group 
      this.group.add(this.wallStaander) // adding the wallStaander to the muur group

      // positioning of the betonPoeren 
      this.betonPoer.betonPoer.rotation.x = Math.PI / 2; // 180graden draaien van de betonpoeren op de x as
      this.betonPoer.betonPoer.position.y = -this.poer.y/2; // betonpoeren met de helft van de eigen hoogte naar beneden
      

      // console.log(texture)
      const geometry2 = new this.THREE.BufferGeometry()
      // console.log(this.staanderObject)
      geometry2.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(planeBorderBuffer.positionBuffer(this.staanderObject.dx, this.staanderObject.dz, 7)), 3))
      geometry2.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(planeBorderBuffer.uvBuffer()), 2))
      geometry2.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(planeBorderBuffer.normalBuffer()), 3))
      geometry2.groups = planeBorderBuffer.groupBuffer()
      geometry2.translate( -this.staanderObject.dx / 2, 0, -this.staanderObject.dz / 2 );

      const mapTexture = map.clone()
      this.textureSettings(mapTexture,  this.staanderObject.dx/2450,  this.staanderObject.dz/145,  this.set.rotation+Math.PI*0.5)
      const mapMaterial = new this.THREE.MeshStandardMaterial({ map: mapTexture })
      mapMaterial.needsUpdate = true
      mapMaterial.map.needsUpdate = true
      const mapMaterialOutline = this.shaderMaterial('#000000')

      const mapStaander0 = new this.THREE.Mesh( geometry2, [mapMaterialOutline, mapMaterial] );
      mapStaander0.name = "mapStaander"

      mapStaander0.renderOrder = -10
      if(this.mapWallStaander) {
        this.mapWallStaander.add(mapStaander0)
        this.mapGroup.add(this.mapWallStaander)
      }

    })
    
    
  }

  updateWallStaanderTexture(texture) {
    this.staanderObject.frameTexture = texture
    // console.log(texture)
    this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`, (map) => {
      const textureX = map.clone()
      this.textureSettings(textureX,  this.staanderObject.y / 2450,  this.staanderObject.dx * 0.0075,  0.5 * Math.PI)

      const textureZ = map.clone()
      this.textureSettings(textureZ,  this.staanderObject.y / 2450,  this.staanderObject.dz * 0.0075,  0.5 * Math.PI)

      console.log(this.wallStaander)
      if(this.wallStaander) {
        
        this.wallStaander.children[0].material[0].map = textureZ
        this.wallStaander.children[0].material[0].map.needsUpdate = true
        this.wallStaander.children[0].material[0].needsUpdate = true
        this.wallStaander.children[0].material[1].map = textureZ
        this.wallStaander.children[0].material[1].map.needsUpdate = true
        this.wallStaander.children[0].material[1].needsUpdate = true
        this.wallStaander.children[0].material[2].map = textureX
        this.wallStaander.children[0].material[2].map.needsUpdate = true
        this.wallStaander.children[0].material[2].needsUpdate = true
        this.wallStaander.children[0].material[3].map = textureX
        this.wallStaander.children[0].material[3].map.needsUpdate = true
        this.wallStaander.children[0].material[3].needsUpdate = true
        this.wallStaander.children[0].material[4].map = textureX
        this.wallStaander.children[0].material[4].map.needsUpdate = true
        this.wallStaander.children[0].material[4].needsUpdate = true
        this.wallStaander.children[0].material[5].map = textureX
        this.wallStaander.children[0].material[5].map.needsUpdate = true
        this.wallStaander.children[0].material[5].needsUpdate = true
      }

      if(this.mapWallStaander) {
        const mapTexture = map.clone()
        this.textureSettings(mapTexture,  this.staanderObject.dx/2450,  this.staanderObject.dz/145,  this.set.rotation+Math.PI*0.5)
        this.mapWallStaander.children[0].material[1].map = mapTexture
        this.mapWallStaander.children[0].material[1].map.needsUpdate = true
        this.mapWallStaander.children[0].material[1].needsUpdate = true
      }
    })
  }

  updateMuurAfkapping() {
    // updateMuurAfkapping
  }

  setHover() {
    // setHover
  }

  updateWall() {
    // updateWall
  }

  setWallPlusIcons() {
    // setWallPlusIcons
  }

  setElementPlacing() {
    // setElementPlacing
  }

  setSchorenVisibility() {
    // setSchorenVisibility
  }

  update(null1, null2, null3, null4, null5, topgevelSpace, poer) {
    //update
    if(topgevelSpace !== null && topgevelSpace !== undefined) {this.topgevelSpace = (this.set.rotation % Math.PI === 0)?topgevelSpace:0}
    if(poer !== null && poer !== undefined) {this.poer = poer}

    const betweenLines = 200
    const betweenTextAndLine = 20
    const lineWidth = 20
    const alignLineWidth = 10
    const arrowLength = 200
    const arrowWidth = 120
    const overhang = this.settings.tussenMuur ? 0 : (this.alignsXAxis ? (this.alignedRotation ? this.totalOverhang.l : this.totalOverhang.r) : (this.alignedRotation ? this.totalOverhang.v : this.totalOverhang.a))
    
    const line0 = this.mapGroup.children[1]
    const arrow0 = this.mapGroup.children[2]
    const arrow1 = this.mapGroup.children[3]
    const alignLine0 = this.mapGroup.children[4]
    const alignLine1 = this.mapGroup.children[5]
    const text0 = this.mapGroup.children[6]

    const box0 = this.box
    const textSizes = this.vector
    
    line0.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(lineWidth, this.set.mx-2*arrowLength))
    line0.geometry.attributes.position.needsUpdate = true
    alignLine0.geometry.attributes.position.array = new Float32Array(planeBuffer.positionBuffer(overhang+betweenLines+arrowWidth/2+lineWidth/2 +this.topgevelSpace, alignLineWidth))
    alignLine0.geometry.attributes.position.needsUpdate = true
    arrow0.rotation.y = 270 * Math.PI / 180
    arrow0.position.set(arrowLength, 0, this.staanderObject.dx/2+overhang+betweenLines-arrowWidth/2+lineWidth/2 +this.topgevelSpace)
    arrow1.rotation.y = 90 * Math.PI / 180
    arrow1.position.set(this.set.mx - arrowLength, 0, this.staanderObject.dx/2+overhang+betweenLines+arrowWidth/2+lineWidth/2 +this.topgevelSpace)
    line0.position.set(arrowLength, 0, this.staanderObject.dx/2+overhang+betweenLines +this.topgevelSpace)
    alignLine0.position.set(-alignLineWidth, 0, this.set.mz / 2)
    alignLine0.rotation.y = 0 * Math.PI / 180
    alignLine1.position.set(this.set.mx, 0, this.set.mz / 2)
    alignLine1.rotation.y = 0 * Math.PI / 180
    const totalWidth = (Math.round(this.set.mx*2)/2).toString().replaceAll('.', ',')
    text0.geometry = new TextGeometry(totalWidth, {
      font: this.font,
      size: 125,
      height: 1,
    })
    box0.setFromObject(text0)
    box0.getSize(textSizes)
    console.log(textSizes)
    if (this.set.rotation * 180 / Math.PI === 270) {
      text0.position.set((this.set.mx - this.vectorMHighest(textSizes, 1)) / 2, 0, this.staanderObject.dx/2+overhang+betweenLines+lineWidth+betweenTextAndLine+this.vectorMHighest(textSizes, 2))
      text0.rotation.x = 270 * Math.PI / 180
      text0.rotation.z = 0 * Math.PI / 180
      //alert('gedraaid')
    } else {
      text0.rotation.x = 270 * Math.PI / 180
      text0.rotation.z = 180 * Math.PI / 180
      text0.position.set((this.set.mx + this.vectorMHighest(textSizes, 1)) / 2, 0, this.staanderObject.dx/2+overhang+betweenLines+lineWidth+betweenTextAndLine +this.topgevelSpace)
    }

    if(poer) {
      this.betonPoer.updateBetonPoer(poer)
      this.betonPoer.betonPoer.position.y = -poer.y/2;
    }
  }

  updatePosition(pos) {
    this.group.position = pos
    this.mapGroup.position = pos
    this.modelGroup.position = pos
  }

  textureSettings(texture, repeatX, repeatY, rotation) {
    texture.rotation = rotation || texture.rotation
    texture.repeat.x = repeatX
    texture.repeat.y = repeatY
    texture.wrapS = this.THREE.RepeatWrapping
    texture.wrapT = this.THREE.RepeatWrapping
    texture.encoding = this.THREE.sRGBEncoding
    texture.anisotropy = 16
  }

  shaderMaterial(hex) {
    const c = new this.THREE.Color(hex)
    const fragmentShader = `void main() {gl_FragColor = vec4(vec3(${c.r},${c.g},${c.b}),1.0);}`
    const material = new this.THREE.ShaderMaterial({fragmentShader})
    return material
  }

  vectorMHighest(vector, index) {
    let numArray = []
    Object.keys(vector).forEach(key => {
      numArray.push(vector[key])
    });
    numArray.sort(function(a, b){return a - b})
    return numArray[numArray.length-index]
  }

  async updateHoekProfiel({shouldRight, shouldLeft, shouldRightBoeideel, shouldLeftBoeideel}) {
    
    this.hoekProfielConfig = {shouldRight, shouldLeft, shouldRightBoeideel, shouldLeftBoeideel}

    const generateHoekProfiel = (height, posY) => {
      console.log(height, posY)
      const hoekProfielWidth = 63
      const hokeProfielThickness = this.wallConfig.wz+1 //23

      const shape = new this.THREE.Shape();
      shape.moveTo(0, 0);
      shape.lineTo(0, -(hoekProfielWidth - hokeProfielThickness));
      shape.lineTo(hokeProfielThickness, -(hoekProfielWidth - hokeProfielThickness));
      shape.lineTo(hokeProfielThickness, hokeProfielThickness);
      shape.lineTo(-(hoekProfielWidth - hokeProfielThickness), hokeProfielThickness);
      shape.lineTo(-(hoekProfielWidth - hokeProfielThickness), 0);
      shape.lineTo(0, 0);

      const extrudeSettings = {
        steps: 1,
        depth: height,
        bevelEnabled: false,
      };
      const geometry = new this.THREE.ExtrudeGeometry(shape, extrudeSettings);
      geometry.groups = [
        {start: 0, count: 30, materialIndex: 0},
        {start: 30, count: 6, materialIndex: 1},
        {start: 36, count: 6, materialIndex: 2},
        {start: 42, count: 138, materialIndex: 0},
      ]
      // console.log(geometry)

      return new Promise((resolve) => {

        const tex = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.textureHoekprofielen}`, () => {
          this.textureSettings(tex,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI/2)
          const texFrontLeft = tex.clone()
          this.textureSettings(texFrontLeft,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI*1.5)
          texFrontLeft.needsUpdate = true
          texFrontLeft.offset.set(posY/2330, 0)
          const texFrontRight = tex.clone()
          this.textureSettings(texFrontRight,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI/2)
          texFrontRight.needsUpdate = true
          texFrontRight.offset.set(posY/2330, 0)
          console.log("offset", posY/2330)
          const material = new this.THREE.MeshStandardMaterial({map: tex});
          const front_left = new this.THREE.MeshStandardMaterial({map: texFrontLeft});
          const front_right = new this.THREE.MeshStandardMaterial({map: texFrontRight});
          const mesh = new this.THREE.Mesh(geometry, [material, front_left, front_right]);
        
          mesh.castShadow = true;
          mesh.receiveShadow = true;

          resolve(mesh)
        })

      })
    }
    

    const removeRight = () => {
      if(this.hoekProfiel.right) {
        console.log("remove right")
        this.hoekProfiel.right.removeFromParent()
        this.hoekProfiel.right.geometry.dispose()
        this.hoekProfiel.right.material.forEach((material) => {
          material.map.dispose()
          material.dispose()
        })
        this.hoekProfiel.right = null
      }
    }
    removeRight()

    if(shouldRight && !this.hoekProfiel.right) {
      const hoekProfielRechts = await generateHoekProfiel(shouldRightBoeideel ? this.y + this.dy + 16 : this.y + 16, 0)
      hoekProfielRechts.position.x = this.set.mx + this.staanderObject.dx
      hoekProfielRechts.position.z = this.staanderObject.dx/2
      hoekProfielRechts.rotation.x = -Math.PI/2
      hoekProfielRechts.rotation.z = Math.PI*1.5
      removeRight()
      if(this.hoekProfielConfig.shouldRight && !this.hoekProfiel.right) {
        this.hoekProfiel.right = hoekProfielRechts
        this.group.add(hoekProfielRechts);
      }
    } else if(shouldRightBoeideel && !shouldRight && !this.hoekProfiel.right) {
      const hoekProfielRechts = await generateHoekProfiel(this.dy + 16 +1, this.y -1)
      hoekProfielRechts.position.x = this.set.mx + this.staanderObject.dx
      hoekProfielRechts.position.z = this.staanderObject.dx/2
      hoekProfielRechts.position.y = this.y -1
      hoekProfielRechts.rotation.x = -Math.PI/2
      hoekProfielRechts.rotation.z = Math.PI*1.5
      removeRight()
      if(this.hoekProfielConfig.shouldRightBoeideel && !this.hoekProfielConfig.shouldRight && !this.hoekProfiel.right) {
        this.hoekProfiel.right = hoekProfielRechts
        this.group.add(hoekProfielRechts);
      }
    } 

    const removeLeft = () => {
      if(this.hoekProfiel.left) {
        this.hoekProfiel.left.removeFromParent()
        this.hoekProfiel.left.geometry.dispose()
        this.hoekProfiel.left.material.forEach((material) => {
          material.map.dispose()
          material.dispose()
        })
        this.hoekProfiel.left = null
      }
    }
    removeLeft()

    if(shouldLeft && !this.hoekProfiel.left) {
      const hoekProfielLinks = await generateHoekProfiel(shouldLeftBoeideel ? this.y + this.dy + 16 : this.y + 16, 0)
      hoekProfielLinks.position.x = -this.staanderObject.dx
      hoekProfielLinks.position.z = this.staanderObject.dx/2
      hoekProfielLinks.rotation.x = -Math.PI/2
      hoekProfielLinks.rotation.z = Math.PI*1
      removeLeft()
      if(this.hoekProfielConfig.shouldLeft && !this.hoekProfiel.left) {
        this.hoekProfiel.left = hoekProfielLinks
        this.group.add(hoekProfielLinks);
      }
    } else if(shouldLeftBoeideel && !shouldLeft && !this.hoekProfiel.left) {
      const hoekProfielLinks = await generateHoekProfiel(this.dy+16 +1, this.y -1)
      hoekProfielLinks.position.x = -this.staanderObject.dx
      hoekProfielLinks.position.z = this.staanderObject.dx/2
      hoekProfielLinks.position.y = this.y -1
      hoekProfielLinks.rotation.x = -Math.PI/2
      hoekProfielLinks.rotation.z = Math.PI*1
      removeLeft()
      if(this.hoekProfielConfig.shouldLeftBoeideel && !this.hoekProfielConfig.shouldLeft && !this.hoekProfiel.left) {
        this.hoekProfiel.left = hoekProfielLinks
        this.group.add(hoekProfielLinks);
      }
    }
    
  }

  updateHoekprofielTexture(texture) {
    this.textureHoekprofielen = texture

    const shouldLeft = this.hoekProfielConfig.shouldLeft
    const shouldLeftBoeideel = this.hoekProfielConfig.shouldLeftBoeideel
    const shouldRight = this.hoekProfielConfig.shouldRight
    const shouldRightBoeideel = this.hoekProfielConfig.shouldRightBoeideel

    const hoekProfielWidth = 63

    const tex = this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.textureHoekprofielen}`, () => {
      if(this.hoekProfiel.left) {

        const posY = shouldLeft ? 0 : (shouldLeftBoeideel ? (this.y -1) : 0)

        const tex0 = tex.clone()
        this.textureSettings(tex0,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI/2)
        tex0.needsUpdate = true
        
        const texFrontLeft = tex.clone()
        this.textureSettings(texFrontLeft,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI*1.5)
        texFrontLeft.needsUpdate = true
        texFrontLeft.offset.set(posY/2330, 0)

        const texFrontRight = tex.clone()
        this.textureSettings(texFrontRight,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI/2)
        texFrontRight.needsUpdate = true
        texFrontRight.offset.set(posY/2330, 0)

        this.hoekProfiel.left.material[0].map = tex
        this.hoekProfiel.left.material[1].map = texFrontLeft
        this.hoekProfiel.left.material[2].map = texFrontRight

        this.hoekProfiel.left.material[0].map.needsUpdate = true
        this.hoekProfiel.left.material[1].map.needsUpdate = true
        this.hoekProfiel.left.material[2].map.needsUpdate = true

        this.hoekProfiel.left.material[0].needsUpdate = true
        this.hoekProfiel.left.material[1].needsUpdate = true
        this.hoekProfiel.left.material[2].needsUpdate = true
      }

      if(this.hoekProfiel.right) {

        const posY = shouldRight ? 0 : (shouldRightBoeideel ? (this.y -1) : 0)

        const tex1 = tex.clone()
        this.textureSettings(tex1,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI/2)
        tex1.needsUpdate = true
        
        const texFrontLeft1 = tex.clone()
        this.textureSettings(texFrontLeft1,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI*1.5)
        texFrontLeft1.needsUpdate = true
        texFrontLeft1.offset.set(posY/2330, 0)

        const texFrontRight1 = tex.clone()
        this.textureSettings(texFrontRight1,  this.y/2330/this.y, hoekProfielWidth/145/hoekProfielWidth, Math.PI/2)
        texFrontRight1.needsUpdate = true
        texFrontRight1.offset.set(posY/2330, 0)

        this.hoekProfiel.right.material[0].map = tex1
        this.hoekProfiel.right.material[1].map = texFrontLeft1
        this.hoekProfiel.right.material[2].map = texFrontRight1

        this.hoekProfiel.right.material[0].map.needsUpdate = true
        this.hoekProfiel.right.material[1].map.needsUpdate = true
        this.hoekProfiel.right.material[2].map.needsUpdate = true

        this.hoekProfiel.left.material[0].needsUpdate = true
        this.hoekProfiel.left.material[1].needsUpdate = true
        this.hoekProfiel.left.material[2].needsUpdate = true
      }


    })
  }

  // Added to support changing variant/glass type etc. on steellook placed as a wall
  setModel(data,model,map,modelCode){
    console.log('test data,component,glb_map.scene,modelCode',data,model,map,modelCode)
    this.dimensions = data.component.dimensions
    this.settings.dimensions = data.component.dimensions
    this.modelCode = modelCode
    this.settings.modelCode = modelCode
    model.rotation.y = this.model.rotation.y
    model.position.x = this.model.position.x
    model.position.y = this.model.position.y
    model.position.z = this.model.position.z
    map.rotation.y = this.map.rotation.y
    map.position.x = this.map.position.x
    map.position.z = this.map.position.z
    this.model.traverse( function ( obj ) {
      if ( obj.isMesh ) {
        obj.castShadow = true;
        obj.receiveShadow = true;
      }
    })
    this.modelGroup.children[0].removeFromParent()
    this.mapGroup.children[0].removeFromParent()
    this.modelGroup.add(model)
    this.mapGroup.add(map)
  }
}