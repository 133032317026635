<template>
  <div class="invite">
    <div class="invite-card">
      <form @submit.prevent="sendInvite()" class="default-form">
        
        <h1 class="invite-card-title">Nieuwe gebruiker</h1>

        <p class="invite-card-info">Nodig een nieuwe gebruiken uit. Deze gebruiker ontvangt een email met daarin een link en een token waarmee het account gecreëerd kan worden.</p>

        <div class="default-form-field">
          <label for="email" class="default-form-field-label">Email</label>
          <input type="email" id="email" name="email" class="default-form-field-input" v-model.trim="invite.email">
        </div>

        <div class="default-form-field">
          <label for="admin" class="default-form-field-label">Admin</label>
          <input class="checkbox" type="checkbox" id="admin-checkbox" name="admin-checkbox" v-model="invite.admin">
          <label for="admin-checkbox" class="checkbox-label">
            <mdicon name="check-bold" class="checkbox-label-icon" :class="invite.admin ? 'checkbox-label-icon-active' : ''" size="18" />
          </label>
        </div>

        <div class="default-form-button">
          <LoadingButton type="submit" :loading="invitePending" :disabled="!formValid">Uitnodigen</LoadingButton>
        </div>

        <div v-if="lastInvite.status" class="default-form-field">
          <!-- {{ lastInvite }} -->
        </div>

      </form>
    </div>
  </div>
</template>

<script>
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'
  import { mapGetters } from 'vuex'

  export default {
    name: "Invite",

    components: {
      LoadingButton
    },

    data () {
      return {
        invite: {
          email: "",
          admin: false,
        },
        invitePending: false,
        formValid: false,
      }
    },

    methods: {
      validate () {
        if (this.invite.email) {
          this.formValid = true
        } else {
          this.formValid = false
        }
      },

      async sendInvite () {
        this.invitePending = true

        try {

          await this.$store.dispatch('sendInvite', this.invite)

        } catch (err) {
          this.invitePending = false
        }

        this.invitePending = false
      }
    },

    computed: {
      ...mapGetters({
        lastInvite: 'getLastInvite'
      }),
    },

    watch: {
      invite: {
        deep: true,
        immediate: false,
        handler () {
          this.validate()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

.invite {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &-card {
    display: flex;
    padding: 25px;
    margin: 15px;
    border-radius: $radius-m;
    box-shadow: $shadow-1;

    &-title {
      font-weight: 600;
      margin: 0px;
    }

    &-info {
      text-align: start;
      font-weight: 500;
    }
  }
}

</style>