<template>
  <div class="models">
    <div class="models-list">
      <router-link :to="`/cms/models/${model.id}`" v-for="(model, i) in models" :key="i" class="link">
        <div class="card">
          <div class="card-image">
            <img :src="`${previewsServer + modelsLocation}previews/models/${model.name}.png`" alt="">
          </div>

          <div class="card-info">
            <h3>{{ model.name }}</h3>
            <span>type: {{ model.type.name }}</span>
            <span>categorie: {{ model.category.name }}</span>
          </div>
        </div>
      </router-link>

      <div class="models-list-new">
        <LoadingButton @click="$router.push('/cms/models/new')" >Nieuw model</LoadingButton>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'

  export default {
    name: "ModelsOverview",

    components: {
      LoadingButton
    },

    data () {
      return {
        modelsLocation: import.meta.env.VITE_VUE_APP_MODELS_LOCATION,
        previewsServer: import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER,
      }
    },

    methods: {
      async get () {
        await this.$store.dispatch('getModelsInfo')
      },
    },

    computed: {
      ...mapGetters({
        models: "getModels"
      }),
    },

    created () {
      this.get()
    },
  }
</script>

<style lang="scss" scoped>

.models {
  display: flex;
  padding: 25px;
  justify-content: center;

  &-list {
    display: flex;
    flex-direction: column;
    width: min(100%, 800px);
    gap: 15px;

    &-new {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      padding: 25px;
    }
  }
}

.card {
  display: flex;
  border-radius: $radius-m-1;
  box-shadow: $shadow-m-0;
  background-color: white;
  overflow: hidden;
  gap: 15px;
  cursor: pointer;

  &-image {
    background-color: $c-background-1;
    height: 96px;
    width: 96px;
    border-radius: $radius-m-1;

    & img {
      object-fit: contain;
      max-height: 96px;
      max-width: 96px;
      min-height: 96px;
      min-width: 96px;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;

    & h3 {
      margin: 0px;
    }
  }

  &:hover {
    background-color: hsl(0, 0%, 0%, 0.08);
  }
}

.link {
  text-decoration: none;
  color: black;
}

</style>