<template>
  <div class="home">
    <CmsNavBar />
    <RouterView />
  </div>
</template>

<script>
  import CmsNavBar from '@/components/nav/CmsNavBar.vue'

  export default {
    name: "CMSHome",

    components: {
      CmsNavBar
    }
  }
</script>

<style lang="scss" scoped>

.home {
  margin-top: 45px;
  position: relative;
}

</style>