<template>
  <div class="modal">
    <span class="modal-message">{{ message }}</span>

    <div class="modal-actions">
      <button class="modal-actions-confirm" @click="$emit('confirm')">Ga door</button>
      <button class="modal-actions-cancel" @click="$emit('cancel')">Cancel</button>
    </div>  
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'Weet u zeker dat u deze actie wilt uitvoeren?',
    },
  },
  components: {
  }
}
</script>

<style scoped lang="scss">
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: $radius-m-1;
  padding: 20px;
  gap: 25px;
  box-shadow: 
    0px 7px 8px -4px adjust-color($color: black, $alpha: -0.8),
    0px 12px 17px 2px adjust-color($color: black, $alpha: -0.86),
    0px 5px 22px 4px adjust-color($color: black, $alpha: -0.88);
  background-color: white;

  &-message {
    width: 400px;
    font-size: 1.3rem;
    padding: 10px 10px;
    
    @media screen and (max-width: 850px) {
      width: 250px;
    }
  }

  &-actions {
    display: flex;
    gap: 25px;

    &-confirm {
      background-color: $c-secondary;
      color: $c-primary !important;
      @include standard-button;

      &:hover {
        background-color: $c-secondary-hover;
      }
    }

    &-cancel {
      background-color: $c-error;
      @include standard-button;

      &:hover {
        background-color: $c-error-hover;
      }
    }
  }
}
</style>