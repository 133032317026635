<template>

<div class="backgroundFilter" ref="filter">
  <div class="card">
    <p>Als u van {{mode === 'size' ? 'maat' : 'model'}} veranderd, worden alle wanden, steellooks, ramen, en deuren, verwijderd. Wilt u door gaan?</p>
    <div>
      <input class="checkbox" type="checkbox" id="doNotshowMessage" v-model="ShouldNotShowMessage[mode]" @change="saveShowMessage()">
      <label for="doNotshowMessage" class="checkbox-label sizes-form-checkbox" style="margin-right: 0px; display: inline-block; margin-right: 10px; height: 24px; width: 24px; vertical-align: bottom; margin-bottom:3px">
        <mdicon name="check-bold" class="checkbox-label-icon" :class="ShouldNotShowMessage[mode] ? 'checkbox-label-icon-active' : ''" size="15" />
      </label>
      <p style="display: inline-block; vertical-align: bottom;">Deze melding niet meer weergeven.</p>
    </div>
    <button @click="$emit('continue')">Ja, ik wil {{mode === 'size' ? 'de maat' : 'het model'}} veranderen.</button>
    <button @click="$emit('cancel')">Nee, ik wil mijn configuratie behouden.</button>
  </div>
</div>

</template>

<script>
  export default {
    name: "SizeChangeConfirmation",

    data () {
      return {
        ShouldNotShowMessage: {
          size: false,
          model: false
        }
      }
    },

    props: {
      mode: String
    },

    methods: {
      saveShowMessage () {
        this.$store.dispatch('saveShowMessage', this.ShouldNotShowMessage)
      }
    }
  }
</script>

<style lang="scss" scoped>
// styling for the whole sidebar
.backgroundFilter {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.card {
  background-color: #ffffff;
  border-radius: $radius-l;
  width: 600px;
  height: 350px;
  //margin-left: calc(50vw - 300px - 300px + 35px);;
  //margin-top: calc(50vh - 125px);
  margin-left: calc(50vw - 300px);
  margin-top: calc(50vh - 175px);
  @media (max-width: 999px) {
    width: 80vw;
    height: auto;
    margin-left: 10vw;
    margin-top: calc(50vh - 150px);
    padding-bottom: 30px;
  }
}

p {
  margin: 0px auto;
  margin-top: 25px;
  max-width: 90%;
  font-size: 1.6rem;
  font-weight: 500;
  display: inline-block;
  word-wrap: break-word;
  @media (max-width: 999px) {
    font-size: 1.2rem;
  }
}

button {
  background-color: $c-primary;
  font-size: 1.2rem;
  color: $c-secondary;
  border: none;
  border-radius: $radius-s;
  font-weight: 500;
  margin-top: 20px;
  width: 80%;
  word-wrap: break-word;
  padding: 10px 30px;
  cursor: pointer;
  @media (max-width: 999px) {
    font-size: 1rem;
  }
}
</style>