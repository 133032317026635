<template>
  <div class="scalingMenu">
    <input type="number" v-model.number="specID" style="width:200px">
    <button @click="$store.dispatch('renderImages', specID)">REGENERATE</button>
  </div>
</template>

<script>
  export default {
    name: "RegenerateSpecImages",

    data() {
      return {
        specID: 0,
      }
    },

    created() {
      /*setInterval(() => {
        console.log("texture", this.texture)
      }, 1000);*/
    },

    props: {
      texture: Object
    },
  }
</script>

<style>
.scalingMenu {
  background-color: #272822cc;
  border: 2px solid #0075FF;
  border-radius: 6px;
  color: #fff;
  text-align: left;
  padding: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>