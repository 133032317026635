<template>
  <div>
    <transition name="fade" appear v-if="loading">
      <div class="overlay">
        <div class="card">
          <h2>De configurator wordt geladen, een moment geduld A.U.B.</h2>

          <div class="progress-bar">
            <div class="progress-bar-progress" :style="`width: ${progress * 100}%;`"></div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        loading: 'getLoading',
        progress: 'getProgress'
      })
    },
  }
</script>

<style lang="scss" scoped>

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 25px;
}

.progress-bar {
  border: 2px solid $c-border-1;
  border-radius: $radius-s;
  min-width: 100%;
  height: 15px;
  background-color: $c-background-1;
  display: flex;

  &-progress {
    transition: width 200ms ease;
    background-color: $c-secondary;
    height: 15px;
    border-radius: $radius-s;
  }
}

// overlay vue animation
.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>