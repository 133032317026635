<template>
  <div class="WallDimension">
    <button class="lockButton left"></button>
    <div class="inputDiv">
      <input ref="inputField" class="widthInput" type="text" :min="250" :max="selectedLichtstraatDimensionValue.max-250" :value="Math.round(selectedLichtstraatDimensionValue.value).toString().replaceAll('.', ',')" @change="changeLichtstraatDimensionValue($event)">
      <p class="inputSubText">mm</p>
    </div>
    <button class="lockButton right"></button>
    <!-- <button class="menu-button">Offerte</button> -->
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "lichtstraatDimensions-menu",


    methods: {
      changeLichtstraatDimensionValue(event) {
        let newWidth = Math.round((parseFloat(event.target.value.replaceAll(',', '.'))))
        newWidth = Math.max(Math.min(this.selectedLichtstraatDimensionValue.max-250, newWidth), 250)
        this.$store.commit('setSelectedLichtstraatNewDimensionValue', newWidth)
      },
    },

    computed: {
      ...mapGetters({
        selectedLichtstraatDimensionValue: "getSelectedLichtstraatDimensionValue",
        selectedLichtstraatDimension: "getSelectedLichtstraatDimension",
      }),
    },

    watch: {
      selectedLichtstraatDimension: {
        handler: function () {
          console.log("selectedLichtstraatDimension", this.selectedLichtstraatDimension)
          this.$refs.inputField.focus()
          const inputLength = String(this.selectedLichtstraatDimensionValue.value).length
          setTimeout(() =>this.$refs.inputField.setSelectionRange(0, inputLength), 0)
        },
        deep: false
      }
    },

    mounted() {
      document.getElementsByClassName("widthInput")[0].addEventListener("keypress", function (evt) {
        if(evt.which != 8 && evt.which != 44 && evt.which != 0 && evt.which !== 13 && evt.which < 48 || evt.which > 57) {
          evt.preventDefault();
        }
      });
      this.$nextTick(() => {
        this.$refs.inputField.focus()
        const inputLength = String(this.selectedLichtstraatDimensionValue.value).length
        this.$refs.inputField.setSelectionRange(0, inputLength)
      })
    }
  }
    
</script>

<style lang="scss" scoped>
.WallDimension {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80px;
  background-color: $c-primary;
  border-radius: 20px;
  width: 500px;
}

.lockButton {
  width: 50px;
  height: 50px;
  // border: 1px solid $c-border-1;
  border: none;
  background-color: $c-primary;
  color: #fff;
  cursor: pointer;
  -webkit-transition: background-color .2s linear;
  -ms-transition: background-color .2s linear;
  -moz-transition: background-color .2s linear;
    -o-transition: background-color .2s linear;
  transition: background-color .2s linear;
  pointer-events: none;
}

.buttonImage {
  color: white;
}

.left {
  border-radius: 6px 0px 0px 6px;
  border-right: 2px solid white;
}
.right {
  border-radius: 0px 6px 6px 0px;
  border-left: 2px solid white;
}

.colorChange {
  color: $c-secondary;
}
// .unlocked {
//   background-color: $c-secondary;
// }

.widthInput {
  height: 50px;
  // border: 1px solid $c-border-1;
  border: none;
  background-color: $c-primary;
  text-align: center;
  vertical-align: top;
  font-size: 20px;
  color: $c-secondary;
  width: 400px;
  // height: calc(100% - 5px);
}
.widthInput::-webkit-outer-spin-button,
.widthInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.widthInput {
  -moz-appearance: textfield;
}
.widthInput:focus {
    outline: none;
}

.inputDiv {
  vertical-align: top;
  display: inline-block;
  position: relative;
}

.inputSubText {
  display: inline-block;
  position: absolute;
  right: 6px;
  bottom: 0px;
  margin: 0px;
  color: white;
}

@media (max-width: 999px) {
  .WallDimension {
    position: absolute;
    left: 50vw;
  }

.widthInput {
  width: 200px;
} 

}

</style>