import * as douglasBuffer from '@/three/buffer-geometries/douglasBuffer.js'
import * as boxBuffer from '@/three/buffer-geometries/boxBuffer.js'
import * as schuineGording from '@/three/buffer-geometries/schuineGording.js'
import * as schuineGording2 from '@/three/buffer-geometries/schuineGording2.js'
import * as milaanGording from '@/three/buffer-geometries/milaanGording.js'

export default class Boeideel {
  constructor (THREE, textureLoader, modelType, x, y, z, dy, dz, dsz, dsy, ry, overstekKlos, texture, textureHoekprofielen, textureFrame, lijstPosition, enabled, lijstEnabled, kp, op, kPos, graden, overhang, pos, dpy, dpz, dpok, dpog, np, byl, douglasPlanken, wallConfig, kapschuurConfig, staanderGrootte, boeideelHorizontaleOffset) {
    this.THREE = THREE
    this.textureLoader = textureLoader
    this.modelType = modelType
    this.texture = texture
    this.textureHoekprofielen = textureHoekprofielen
    this.textureFrame = textureFrame
    this.x = x
    this.y = y
    this.z = z
    this.dy = dy 
    this.dz = dz
    this.dsy = dsy
    this.dsz = dsz
    this.ry = ry
    this.lijstPosition = lijstPosition
    this.enabled = enabled
    this.lijstEnabled = lijstEnabled
    this.kp = kp //klosposition     if klos is on a specified side
    this.op = op //omlijstingPalen
    this.kPos = kPos
    this.graden = graden
    this.overhang = overhang
    this.pos = pos
    this.dpy = dpy
    this.dpz = dpz
    this.dpok = dpok
    this.dpog = dpog
    this.np = np
    this.byl = byl
    this.douglasPlanken = douglasPlanken
    this.wallConfig = wallConfig
    this.kapschuurConfig = kapschuurConfig
    this.staanderGrootte = staanderGrootte
    this.boeideelHorizontaleOffset = boeideelHorizontaleOffset

    this.group = null

    this.deel0 = null
    this.deel1 = null
    this.deel2 = null
    this.deel3 = null

    this.koof0 = null
    this.koof1 = null

    this.zadeldak1 = null
    this.zadeldak2 = null
    this.zadeldak3 = null
    this.zadeldak4 = null
    this.zadeldak5 = null
    this.zadeldak6 = null
    this.zadeldak7 = null
    this.zadeldak8 = null

    this.bouw(THREE, textureLoader, x, y, z, dy, dz, dsz, dsy, ry, overstekKlos, texture, textureHoekprofielen, textureFrame, lijstPosition, enabled, lijstEnabled, kp, op, kPos, graden, overhang, pos, dpy, dpz, dpok, dpog, np, byl, douglasPlanken, staanderGrootte, boeideelHorizontaleOffset)
  }

  bouw (THREE, textureLoader, x, y, z, dy, dz, dsz, dsy, ry, overstekKlos, texture, textureHoekprofielen, textureFrame, lijstPosition, enabled, lijstEnabled, kp, op, kPos, graden, overhang, pos, dpy, dpz, dpok, dpog, np, byl, douglasPlanken, staanderGrootte, boeideelHorizontaleOffset) {
    let klosVoEnabled = this.klosVoEnabled = overstekKlos.enabled? overstekKlos.klosVoEnabled : false
    let xBovVo = this.xBovVo = klosVoEnabled ? overstekKlos.xBovVo : 0
    let klosAcEnabled = this.klosAcEnabled = overstekKlos.enabled? overstekKlos.klosAcEnabled : false
    let xBovAc = this.xBovAc = klosAcEnabled ? overstekKlos.xBovAc : 0
    let klosLiEnabled = this.klosLiEnabled = overstekKlos.enabled? overstekKlos.klosLiEnabled : false
    let xBovLi = this.xBovLi = klosLiEnabled ? overstekKlos.xBovLi : 0
    let klosReEnabled = this.klosReEnabled = overstekKlos.enabled? overstekKlos.klosReEnabled : false
    let xBovRe = this.xBovRe = klosReEnabled ? overstekKlos.xBovRe : 0
    let kx = this.kx = xBovVo
    this.trash = [xBovVo, xBovAc, xBovLi, xBovRe]

    const breedte = this.modelType.isKapschuur ? this.kapschuurConfig.topX*2 : z

    this.group = new THREE.Group()
    console.log(textureFrame)
    // x as (deel 0 en deel 1)
    const hoofdplank = this.meshCreation (THREE, douglasPlanken, textureLoader, breedte + xBovVo + xBovAc, dy, dz, texture, "x")

    // position
    hoofdplank.position.x = 0//breedte / 2 - xBovVo/2 + xBovAc/2
    hoofdplank.position.z = - dz / 2
    hoofdplank.position.y = dy/2

    // sub plank
    const subPlank = this.meshCreation (THREE, douglasPlanken, textureLoader, breedte + xBovVo + xBovAc - (lijstPosition ? (2 * dsz) : 0), dsy, dsz, this.wallConfig.position === "buiten" ? textureFrame : texture, null, true)

    // position
    subPlank.position.x = 0//breedte / 2 + xBovAc / 2 - xBovVo / 2
    subPlank.position.z = - (0.5 * dsz) + (lijstPosition ? dsz : -dz)
    subPlank.position.y = dy - dsy / 2
    subPlank.visible = lijstEnabled
      

    // group hoof en sub
    this.deel0 = new this.THREE.Group()
    this.deel0.add(hoofdplank)
    this.deel0.add(subPlank)
    this.deel0.position.x = breedte/2 - xBovVo/2 + xBovAc/2
    this.deel0.position.z = -xBovLi

    this.deel1 = this.deel0.clone()
    this.deel1.rotation.y = Math.PI
    this.deel1.position.z = x + xBovRe

    //koof
    if(op) {
      this.koof0 = this.meshCreation (THREE, douglasPlanken, textureLoader, breedte + ((kp.l&&kp.r?2:0) * kx), dz, kx, texture)
      this.koof0.position.y = kPos+dz/2
      this.koof0.name = "koof"
      if(kp.l) {
        let koof = this.koof0.clone()
        koof.position.x = kx/2
        koof.position.z = kx/2
        this.deel0.add(koof.clone())
      }
      if(kp.r) {
        let koof = this.koof0.clone()
        koof.position.x = -kx/2
        koof.position.z = kx/2
        this.deel1.add(koof)
      }
    }


    // z as (deel 2 en deel 3)
    // hoofdplank
    const hoofdPlank1 = this.meshCreation (THREE, douglasPlanken, textureLoader, x + xBovLi + xBovRe + (2 * dz) + ((lijstPosition || !lijstEnabled) ? 0 : (2*dsz)), dy, dz, texture, "z")

    // position
    // hoofPlank1.position.x = hoofPlank.position.x
    hoofdPlank1.position.z = -dz/2
    hoofdPlank1.position.y = dy/2

    // sub plank
    const subPlank1 = this.meshCreation (THREE, douglasPlanken, textureLoader, x + xBovLi + xBovRe + ((lijstPosition || !lijstEnabled) ? 0 : (2*dsz)) + ((lijstPosition || !lijstEnabled) ? 0 : (2*dz)), dsy, dsz, this.wallConfig.position === "buiten" ? textureFrame : texture, null, true)
    console.log(lijstPosition, lijstEnabled, x, kx, dz, dsz)
    // position
    // subPlank1.position.x = subPlank.position.x
    subPlank1.position.z = subPlank.position.z 
    subPlank1.position.y = subPlank.position.y
    subPlank1.visible = lijstEnabled

    // group hoof en sub
    this.deel2 = new this.THREE.Group()
    this.deel2.add(hoofdPlank1)
    this.deel2.add(subPlank1)

    this.deel2.rotation.y = 0.5 * Math.PI
    this.deel2.position.x = - xBovVo
    this.deel2.position.z = x/2 - xBovLi/2 + xBovRe/2

    this.deel3 = this.deel2.clone()
    this.deel3.rotation.y = 1.5 * Math.PI 
    this.deel3.position.x = breedte + xBovAc

    if(op) {
      this.koof1 = this.meshCreation (THREE, douglasPlanken, textureLoader, x + ((kp.l&&kp.r?2:1) * kx), dz, kx, texture)
      this.koof1
      this.koof1.position.y = kPos+dz/2
      this.koof1.name = "koof"
      if(kp.v) {
        let koof = this.koof1.clone()
        koof.position.x = 0
        koof.position.z = kx/2
        this.deel2.add(koof.clone())
      }
    }

    // group
    if(enabled && this.modelType.isPlatdak) {
      this.group.add(this.deel0)
      this.group.add(this.deel1)
      this.group.add(this.deel2)
      this.group.add(this.deel3)
    }






    //zadeldak
    //if byl > 0 use milaangording, otherwise use schuinegording2
    const radians = graden*(Math.PI/180)
    const HalfDepth = this.modelType.isKapschuur ? (z-(y-this.kapschuurConfig.achtergevelHoogte)/Math.tan(radians))/2 : z/2
    const yMax = Math.tan(radians) * (HalfDepth + (dy/Math.sin(radians)) - (byl/Math.tan(radians)) + boeideelHorizontaleOffset) + pos + byl
    const yMin = pos

    const totalWidthVoor = (yMax-yMin)/Math.tan(radians) -(byl/Math.tan(radians))
    const totalWidthAchter = this.modelType.isKapschuur ? (yMax-yMin+(y-this.kapschuurConfig.achtergevelHoogte))/Math.tan(radians) -(byl/Math.tan(radians))-2 : totalWidthVoor
    const totalWidthHVoor = (yMax-yMin+(dpy/(Math.cos(radians))) - (dpy*Math.cos(radians)))/Math.tan(radians) + (Math.cos(radians)*dpog) -(byl/Math.tan(radians))
    const totalWidthHVoorLissabon = totalWidthHVoor - (dy/Math.tan(radians)) - (dz/Math.sin(radians))*1.8
    const totalWidthHAchter = this.modelType.isKapschuur ? (yMax-yMin+(y-this.kapschuurConfig.achtergevelHoogte)+(dpy/(Math.cos(radians))) - (dpy*Math.cos(radians)))/Math.tan(radians) + (Math.cos(radians)*dpog) -(byl/Math.tan(radians)) - (this.modelType.isLissabon ? dy/Math.tan(radians) : 0) +2: totalWidthHVoor
    const totalWidthHAchterLissabon = totalWidthHAchter - (dz/Math.sin(radians))*3
    //lissabon
    const oy = dy/(Math.sin((90-graden)*(Math.PI/180)))
    const breedteVanOy = oy/(Math.tan(graden*(Math.PI/180)))

    textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`, (loadedTexture) => {
      //verticale delen
      let g_verticaleBoeideelVoor = new THREE.BufferGeometry()
      let g_verticaleBoeideelAchter = new THREE.BufferGeometry()
      if(byl > 0) {
        g_verticaleBoeideelVoor.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(totalWidthVoor, dy, dz, graden, 0, byl, byl)), 3))
        g_verticaleBoeideelVoor.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(totalWidthVoor, dy, dz, graden, 0, byl, byl)), 2))
        g_verticaleBoeideelVoor = g_verticaleBoeideelVoor.toNonIndexed()
        g_verticaleBoeideelVoor.computeVertexNormals()

        g_verticaleBoeideelAchter.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(totalWidthAchter, dy, dz, graden, 0, byl, byl)), 3))
        g_verticaleBoeideelAchter.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(totalWidthAchter, dy, dz, graden, 0, byl, byl)), 2))
        g_verticaleBoeideelAchter = g_verticaleBoeideelAchter.toNonIndexed()
        g_verticaleBoeideelAchter.computeVertexNormals()
      } else {
        g_verticaleBoeideelVoor.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording2.positionBuffer(totalWidthVoor, dy, dz, graden)), 3))
        g_verticaleBoeideelVoor.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording2.uvBuffer(totalWidthVoor, dy, dz, graden)), 2))
        g_verticaleBoeideelVoor.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(schuineGording2.normalBuffer()), 3))
        g_verticaleBoeideelVoor.setIndex(schuineGording2.indexBuffer());
        g_verticaleBoeideelVoor.groups = schuineGording2.groupBuffer()

        if(this.modelType.isLissabon) {
          g_verticaleBoeideelAchter.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(totalWidthAchter-breedteVanOy-staanderGrootte+dy, dy, dz, graden, 0, oy, oy)), 3))
          g_verticaleBoeideelAchter.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(totalWidthAchter-breedteVanOy-staanderGrootte+dy, dy, dz, graden, 0, oy, oy)), 2))
          g_verticaleBoeideelAchter = g_verticaleBoeideelAchter.toNonIndexed()
          g_verticaleBoeideelAchter.computeVertexNormals()
        } else {
          g_verticaleBoeideelAchter.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording2.positionBuffer(totalWidthAchter, dy, dz, graden)), 3))
          g_verticaleBoeideelAchter.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording2.uvBuffer(totalWidthAchter, dy, dz, graden)), 2))
          g_verticaleBoeideelAchter.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(schuineGording2.normalBuffer()), 3))
          g_verticaleBoeideelAchter.setIndex(schuineGording2.indexBuffer());
          g_verticaleBoeideelAchter.groups = schuineGording2.groupBuffer()
        }
      }

      const t_verticaleBoeideel1 = loadedTexture.clone()
      t_verticaleBoeideel1.needsUpdate = true;
      this.textureSettings(t_verticaleBoeideel1, 1, 1, 0, Math.random())
      const m_verticaleBoeideel1 = new THREE.MeshStandardMaterial({ map: t_verticaleBoeideel1, metalness: 0, roughness: 1 })
      this.zadeldak1 = new THREE.Mesh(g_verticaleBoeideelVoor, m_verticaleBoeideel1)
      this.zadeldak1.frustumCulled = false;
      this.zadeldak1.castShadow = true
      this.zadeldak1.receiveShadow = true
      this.zadeldak1.frustumCulled = false
      this.zadeldak1.position.set(breedte/2, yMax-yMin, -overhang-dz/2)

      const t_verticaleBoeideel2 = loadedTexture.clone()
      t_verticaleBoeideel2.needsUpdate = true;
      this.textureSettings(t_verticaleBoeideel2, 1, 1, 0, Math.random())
      const m_verticaleBoeideel2 = new THREE.MeshStandardMaterial({ map: t_verticaleBoeideel2, metalness: 0, roughness: 1 })
      this.zadeldak2 = new THREE.Mesh(g_verticaleBoeideelAchter, m_verticaleBoeideel2)
      this.zadeldak2.frustumCulled = false;
      this.zadeldak2.castShadow = true
      this.zadeldak2.receiveShadow = true
      this.zadeldak2.frustumCulled = false
      this.zadeldak2.position.set(breedte/2, yMax-yMin, -overhang-dz/2)
      this.zadeldak2.rotation.y=Math.PI

      const t_verticaleBoeideel3 = loadedTexture.clone()
      t_verticaleBoeideel3.needsUpdate = true;
      this.textureSettings(t_verticaleBoeideel3, 1, 1, 0, Math.random())
      const m_verticaleBoeideel3 = new THREE.MeshStandardMaterial({ map: t_verticaleBoeideel3, metalness: 0, roughness: 1 })
      this.zadeldak3 = new THREE.Mesh(g_verticaleBoeideelVoor, m_verticaleBoeideel3)
      this.zadeldak3.frustumCulled = false;
      this.zadeldak3.castShadow = true
      this.zadeldak3.receiveShadow = true
      this.zadeldak3.frustumCulled = false
      this.zadeldak3.position.set(breedte/2, yMax-yMin, x+overhang+dz/2)

      const t_verticaleBoeideel4 = loadedTexture.clone()
      t_verticaleBoeideel4.needsUpdate = true;
      this.textureSettings(t_verticaleBoeideel4, 1, 1, 0, Math.random())
      const m_verticaleBoeideel4 = new THREE.MeshStandardMaterial({ map: t_verticaleBoeideel4, metalness: 0, roughness: 1 })
      this.zadeldak4 = new THREE.Mesh(g_verticaleBoeideelAchter, m_verticaleBoeideel4)
      this.zadeldak4.frustumCulled = false;
      this.zadeldak4.castShadow = true
      this.zadeldak4.receiveShadow = true
      this.zadeldak4.frustumCulled = false
      this.zadeldak4.position.set(breedte/2, yMax-yMin, x+overhang+dz/2)
      this.zadeldak4.rotation.y=Math.PI


      //horizontale delen
      const horizontalPlankHeight = dz/Math.cos(graden*(Math.PI/180))

      const g_horizontaleBoeideelVoor = new THREE.BufferGeometry()
      g_horizontaleBoeideelVoor.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(totalWidthHVoor, dpy, dpz, graden)), 3))
      g_horizontaleBoeideelVoor.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(totalWidthHVoor, dpy, dpz, graden)), 2))
      g_horizontaleBoeideelVoor.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(schuineGording.normalBuffer()), 3))
      g_horizontaleBoeideelVoor.setIndex(schuineGording.indexBuffer());
      g_horizontaleBoeideelVoor.groups = schuineGording.groupBuffer()

      const g_horizontaleBoeideelAchter = new THREE.BufferGeometry()
      g_horizontaleBoeideelAchter.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(totalWidthHAchter, dpy, dpz, graden)), 3))
      g_horizontaleBoeideelAchter.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(totalWidthHAchter, dpy, dpz, graden)), 2))
      g_horizontaleBoeideelAchter.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(schuineGording.normalBuffer()), 3))
      g_horizontaleBoeideelAchter.setIndex(schuineGording.indexBuffer());
      g_horizontaleBoeideelAchter.groups = schuineGording.groupBuffer()

      const t_horizontaleBoeideel1 = loadedTexture.clone()
      t_horizontaleBoeideel1.needsUpdate = true;
      this.textureSettings(t_horizontaleBoeideel1, 1, 1, 0, Math.random())
      const m_horizontaleBoeideel1 = new THREE.MeshStandardMaterial({ map: t_horizontaleBoeideel1, metalness: 0, roughness: 1 })
      this.zadeldak5 = new THREE.Mesh(g_horizontaleBoeideelVoor, m_horizontaleBoeideel1)
      this.zadeldak5.frustumCulled = false;
      this.zadeldak5.castShadow = true
      this.zadeldak5.receiveShadow = true
      this.zadeldak5.frustumCulled = false
      this.zadeldak5.position.set(breedte/2, yMax-yMin+horizontalPlankHeight, -overhang+dpz/2-dz-dpok)

      const t_horizontaleBoeideel2 = loadedTexture.clone()
      t_horizontaleBoeideel2.needsUpdate = true;
      this.textureSettings(t_horizontaleBoeideel2, 1, 1, 0, Math.random())
      const m_horizontaleBoeideel2 = new THREE.MeshStandardMaterial({ map: t_horizontaleBoeideel2, metalness: 0, roughness: 1 })
      this.zadeldak6 = new THREE.Mesh(g_horizontaleBoeideelAchter, m_horizontaleBoeideel2)
      this.zadeldak6.frustumCulled = false;
      this.zadeldak6.castShadow = true
      this.zadeldak6.receiveShadow = true
      this.zadeldak6.frustumCulled = false
      this.zadeldak6.position.set(breedte/2, yMax-yMin+horizontalPlankHeight, -overhang+dpz/2-dz-dpok)
      this.zadeldak6.rotation.y=Math.PI

      const t_horizontaleBoeideel3 = loadedTexture.clone()
      t_horizontaleBoeideel3.needsUpdate = true;
      this.textureSettings(t_horizontaleBoeideel3, 1, 1, 0, Math.random())
      const m_horizontaleBoeideel3 = new THREE.MeshStandardMaterial({ map: t_horizontaleBoeideel3, metalness: 0, roughness: 1 })
      this.zadeldak7 = new THREE.Mesh(g_horizontaleBoeideelVoor, m_horizontaleBoeideel3)
      this.zadeldak7.frustumCulled = false;
      this.zadeldak7.castShadow = true
      this.zadeldak7.receiveShadow = true
      this.zadeldak7.frustumCulled = false
      this.zadeldak7.position.set(breedte/2, yMax-yMin+horizontalPlankHeight, x+overhang-dpz/2+dz+dpok)

      const t_horizontaleBoeideel4 = loadedTexture.clone()
      t_horizontaleBoeideel4.needsUpdate = true;
      this.textureSettings(t_horizontaleBoeideel4, 1, 1, 0, Math.random())
      const m_horizontaleBoeideel4 = new THREE.MeshStandardMaterial({ map: t_horizontaleBoeideel4, metalness: 0, roughness: 1 })
      this.zadeldak8 = new THREE.Mesh(g_horizontaleBoeideelAchter, m_horizontaleBoeideel4)
      this.zadeldak8.frustumCulled = false;
      this.zadeldak8.castShadow = true
      this.zadeldak8.receiveShadow = true
      this.zadeldak8.frustumCulled = false
      this.zadeldak8.position.set(breedte/2, yMax-yMin+horizontalPlankHeight, x+overhang-dpz/2+dz+dpok)
      this.zadeldak8.rotation.y=Math.PI

      if(enabled && (this.modelType.isZadeldak || this.modelType.isKapschuur)) {
        this.group.add(this.zadeldak1, this.zadeldak2, this.zadeldak3, this.zadeldak4, this.zadeldak5, this.zadeldak6, this.zadeldak7, this.zadeldak8)
      }

      // Lissabon
      const g_horizontaleBoeideelVoorOnder = new THREE.BufferGeometry()
      g_horizontaleBoeideelVoorOnder.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(totalWidthHVoorLissabon*Math.tan(radians), dz, overhang, 90-graden)), 3))
      g_horizontaleBoeideelVoorOnder.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(totalWidthHVoorLissabon*Math.tan(radians), dz, overhang, 90-graden)), 2))
      g_horizontaleBoeideelVoorOnder.setIndex(schuineGording.indexBuffer());
      g_horizontaleBoeideelVoorOnder.rotateX(Math.PI)
      g_horizontaleBoeideelVoorOnder.computeVertexNormals()

      const g_horizontaleBoeideelAchterOnder = new THREE.BufferGeometry()
      g_horizontaleBoeideelAchterOnder.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(totalWidthHAchterLissabon*Math.tan(radians), dz, overhang, 90-graden)), 3))
      g_horizontaleBoeideelAchterOnder.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(totalWidthHAchterLissabon*Math.tan(radians), dz, overhang, 90-graden)), 2))
      g_horizontaleBoeideelAchterOnder.setIndex(schuineGording.indexBuffer());
      g_horizontaleBoeideelAchterOnder.rotateX(Math.PI)
      g_horizontaleBoeideelAchterOnder.computeVertexNormals()

      const t_horizontaleBoeideelOnder1 = loadedTexture.clone()
      t_horizontaleBoeideelOnder1.needsUpdate = true;
      this.textureSettings(t_horizontaleBoeideelOnder1, 1, 1, 0, Math.random())
      const m_horizontaleBoeideelOnder1 = new THREE.MeshStandardMaterial({ map: t_horizontaleBoeideelOnder1, metalness: 0, roughness: 1 })
      this.zadeldak9 = new THREE.Mesh(g_horizontaleBoeideelVoorOnder, m_horizontaleBoeideelOnder1)
      this.zadeldak9.frustumCulled = false;
      this.zadeldak9.castShadow = true
      this.zadeldak9.receiveShadow = true
      this.zadeldak9.frustumCulled = false
      this.zadeldak9.rotation.z = -Math.PI/2
      this.zadeldak9.position.set(breedte/2-totalWidthHVoorLissabon, 0, -overhang/2)

      const bovenPositie = totalWidthHVoorLissabon * Math.tan(radians)
      const achterPositie = bovenPositie - (totalWidthHAchterLissabon * Math.tan(radians))

      const t_horizontaleBoeideelOnder2 = loadedTexture.clone()
      t_horizontaleBoeideelOnder2.needsUpdate = true;
      this.textureSettings(t_horizontaleBoeideelOnder2, 1, 1, 0, Math.random())
      const m_horizontaleBoeideelOnder2 = new THREE.MeshStandardMaterial({ map: t_horizontaleBoeideelOnder2, metalness: 0, roughness: 1 })
      this.zadeldak10 = new THREE.Mesh(g_horizontaleBoeideelAchterOnder, m_horizontaleBoeideelOnder2)
      this.zadeldak10.frustumCulled = false;
      this.zadeldak10.castShadow = true
      this.zadeldak10.receiveShadow = true
      this.zadeldak10.frustumCulled = false
      this.zadeldak10.position.set(breedte/2+totalWidthHAchterLissabon, achterPositie, -overhang/2)
      this.zadeldak10.rotation.z = -Math.PI/2
      this.zadeldak10.rotation.y = Math.PI

      const t_horizontaleBoeideelOnder3 = loadedTexture.clone()
      t_horizontaleBoeideelOnder3.needsUpdate = true;
      this.textureSettings(t_horizontaleBoeideelOnder3, 1, 1, 0, Math.random())
      const m_horizontaleBoeideelOnder3 = new THREE.MeshStandardMaterial({ map: t_horizontaleBoeideelOnder3, metalness: 0, roughness: 1 })
      this.zadeldak11 = new THREE.Mesh(g_horizontaleBoeideelVoorOnder, m_horizontaleBoeideelOnder3)
      this.zadeldak11.frustumCulled = false;
      this.zadeldak11.castShadow = true
      this.zadeldak11.receiveShadow = true
      this.zadeldak11.frustumCulled = false
      this.zadeldak11.rotation.z = -Math.PI/2
      this.zadeldak11.position.set(breedte/2-totalWidthHVoorLissabon, 0, x+overhang/2)

      const t_horizontaleBoeideelOnder4 = loadedTexture.clone()
      t_horizontaleBoeideelOnder4.needsUpdate = true;
      this.textureSettings(t_horizontaleBoeideelOnder4, 1, 1, 0, Math.random())
      const m_horizontaleBoeideelOnder4 = new THREE.MeshStandardMaterial({ map: t_horizontaleBoeideelOnder4, metalness: 0, roughness: 1 })
      this.zadeldak12 = new THREE.Mesh(g_horizontaleBoeideelAchterOnder, m_horizontaleBoeideelOnder4)
      this.zadeldak12.frustumCulled = false;
      this.zadeldak12.castShadow = true
      this.zadeldak12.receiveShadow = true
      this.zadeldak12.frustumCulled = false
      this.zadeldak12.position.set(breedte/2+totalWidthHAchterLissabon, achterPositie, x+overhang/2)
      this.zadeldak12.rotation.z = -Math.PI/2
      this.zadeldak12.rotation.y=Math.PI

      const puntBoeideelLinksOnderin = (yMax-yMin) - ((totalWidthAchter-breedteVanOy-staanderGrootte+dy)*Math.tan(graden*(Math.PI/180))) - (dy/(Math.sin((90-graden)*(Math.PI/180))))
      const lijstPuntPositie = puntBoeideelLinksOnderin + dy*(Math.tan((graden)*(Math.PI/180)))

      const g_verticaalBuiten = new THREE.BufferGeometry()
      g_verticaalBuiten.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer((yMin+lijstPuntPositie)*Math.cos(graden*(Math.PI/180)), dy, dz, graden)), 3))
      g_verticaalBuiten.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer((yMin+lijstPuntPositie)*Math.cos(graden*(Math.PI/180)), dy, dz, graden)), 2))
      g_verticaalBuiten.setIndex(schuineGording.indexBuffer());
      g_verticaalBuiten.computeVertexNormals()
      g_verticaalBuiten.rotateZ((90-graden)*(Math.PI/180))

      const lissabonTexture = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
      this.textureSettings(lissabonTexture, 1, 1, 0, Math.random())
      const lissabonMaterial = new THREE.MeshStandardMaterial({ map: lissabonTexture, metalness: 0, roughness: 1 })
      
      this.zadeldak13 = new THREE.Mesh(g_verticaalBuiten, lissabonMaterial)
      this.zadeldak13.castShadow = true
      this.zadeldak13.receiveShadow = true
      this.zadeldak13.position.y = lijstPuntPositie
      this.zadeldak13.position.x = z-staanderGrootte
      this.zadeldak13.position.z = -overhang-dz/2

      this.zadeldak14 = this.zadeldak13.clone()
      this.zadeldak14.position.z = x+overhang+dz/2

      const g_horizontaleBoeideelVoorkant = new THREE.BufferGeometry()
      g_horizontaleBoeideelVoorkant.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(dz, this.kapschuurConfig.achtergevelHoogte+this.ry, overhang)), 3))
      g_horizontaleBoeideelVoorkant.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
      g_horizontaleBoeideelVoorkant.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
      g_horizontaleBoeideelVoorkant.setIndex(boxBuffer.indexBuffer());
      g_horizontaleBoeideelVoorkant.groups = boxBuffer.groupBuffer()

      const g_horizontaleBoeideelAchterkant = new THREE.BufferGeometry()
      g_horizontaleBoeideelAchterkant.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer((this.kapschuurConfig.achtergevelHoogte+this.ry)*Math.cos((90-graden) * (Math.PI/180)), dz, overhang+staanderGrootte, 90-graden)), 3))
      g_horizontaleBoeideelAchterkant.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer((this.kapschuurConfig.achtergevelHoogte+this.ry)*Math.tan(radians), dz, overhang+staanderGrootte, 90-graden)), 2))
      g_horizontaleBoeideelAchterkant.setIndex(schuineGording.indexBuffer());
      g_horizontaleBoeideelAchterkant.computeVertexNormals()

      const t_horizontaleBoeideelVoorkant = loadedTexture.clone()
      t_horizontaleBoeideelVoorkant.needsUpdate = true;
      this.textureSettings(t_horizontaleBoeideelVoorkant, x / 2320,  overhang / 145, Math.PI/2, 0, Math.random())
      const m_horizontaleBoeideelVoorkant = new THREE.MeshStandardMaterial({ map: t_horizontaleBoeideelVoorkant, metalness: 0, roughness: 1, side: douglasPlanken ? this.THREE.DoubleSide : this.THREE.FrontSide })
      this.zadeldak15 = new THREE.Mesh(g_horizontaleBoeideelVoorkant, m_horizontaleBoeideelVoorkant)
      this.zadeldak15.frustumCulled = false;
      this.zadeldak15.castShadow = true
      this.zadeldak15.receiveShadow = true
      this.zadeldak15.frustumCulled = false
      this.zadeldak15.position.set(z-staanderGrootte+dz/2, this.kapschuurConfig.achtergevelHoogte-y - (this.kapschuurConfig.achtergevelHoogte-this.ry)/2, -overhang/2)

      this.zadeldak16 = this.zadeldak15.clone()
      this.zadeldak16.position.z = x+overhang/2

      const t_horizontaleBoeideelAchterkant = loadedTexture.clone()
      t_horizontaleBoeideelAchterkant.needsUpdate = true;
      this.textureSettings(t_horizontaleBoeideelAchterkant, 1,  1, 0, Math.random())
      const m_horizontaleBoeideelAchterkant = new THREE.MeshStandardMaterial({ map: t_horizontaleBoeideelAchterkant, metalness: 0, roughness: 1, side: douglasPlanken ? this.THREE.DoubleSide : this.THREE.FrontSide  })
      this.zadeldak17 = new THREE.Mesh(g_horizontaleBoeideelAchterkant, m_horizontaleBoeideelAchterkant)
      this.zadeldak17.frustumCulled = false;
      this.zadeldak17.castShadow = true
      this.zadeldak17.receiveShadow = true
      this.zadeldak17.frustumCulled = false
      this.zadeldak17.position.set(z, this.kapschuurConfig.achtergevelHoogte-y+this.ry, staanderGrootte/2-overhang/2)
      this.zadeldak17.rotation.z = radians

      this.zadeldak18 = this.zadeldak17.clone()
      this.zadeldak18.position.z = x-staanderGrootte/2+overhang/2

      if(this.modelType.isLissabon) {
        this.group.add(this.zadeldak9, this.zadeldak10, this.zadeldak11, this.zadeldak12, this.zadeldak13, this.zadeldak14, this.zadeldak15, this.zadeldak16, this.zadeldak17, this.zadeldak18)
      }

    })


    //nice sluitlatten
    if(this.wallConfig.position === "buiten") {
      const z_Lat_Links = this.meshCreation (THREE, douglasPlanken, textureLoader, breedte + (2*this.wallConfig.wz)-1, 16, 62-1, textureHoekprofielen)
      z_Lat_Links.position.x = breedte/2
      z_Lat_Links.position.y = -8 
      z_Lat_Links.position.z = this.wallConfig.wz/2-1.5
      z_Lat_Links.name = "z_Lat_Links"

      const z_Lat_Rechts = z_Lat_Links.clone()
      z_Lat_Rechts.position.z = x-this.wallConfig.wz/2+1.5
      z_Lat_Rechts.name = "z_Lat_Rechts"

      const x_Lat_Voor = this.meshCreation (THREE, douglasPlanken, textureLoader, x, 16, 62-1, textureHoekprofielen)
      x_Lat_Voor.position.x = this.wallConfig.wz/2-1.5
      x_Lat_Voor.position.y = -8
      x_Lat_Voor.position.z = x/2
      x_Lat_Voor.rotation.y = Math.PI/2
      x_Lat_Voor.name = "x_Lat_Voor"

      const x_Lat_Achter = x_Lat_Voor.clone()
      x_Lat_Achter.position.x = breedte-this.wallConfig.wz/2+1.5
      x_Lat_Achter.name = "x_Lat_Achter"

      this.group.add(z_Lat_Links, z_Lat_Rechts, x_Lat_Voor, x_Lat_Achter)
    }
  }

  update (x, y, z, dy, dz, dsz, dsy, ry, overstekKlos, lijstPosition, enabled, lijstEnabled, kp, op, kPos, modelType, graden, overhang, pos, dpy, dpz, dpok, dpog, np, byl, douglasPlanken, wallConfig, kapschuurConfig, staanderGrootte, boeideelHorizontaleOffset) {
    this.x = x
    this.y = y
    this.z = z
    this.dy = dy
    this.dz = dz
    this.dsz = dsz
    this.dsy = dsy
    this.ry = ry
    let klosVoEnabled = this.klosVoEnabled = overstekKlos.enabled? overstekKlos.klosVoEnabled : false
    let xBovVo = this.xBovVo = klosVoEnabled ? overstekKlos.xBovVo : 0
    let klosAcEnabled = this.klosAcEnabled = overstekKlos.enabled? overstekKlos.klosAcEnabled : false
    let xBovAc = this.xBovAc = klosAcEnabled ? overstekKlos.xBovAc : 0
    let klosLiEnabled = this.klosLiEnabled = overstekKlos.enabled? overstekKlos.klosLiEnabled : false
    let xBovLi = this.xBovLi = klosLiEnabled ? overstekKlos.xBovLi : 0
    let klosReEnabled = this.klosReEnabled = overstekKlos.enabled? overstekKlos.klosReEnabled : false
    let xBovRe = this.xBovRe = klosReEnabled ? overstekKlos.xBovRe : 0
    let kx = this.kx = xBovVo
    this.trash = [xBovVo, xBovAc, xBovLi, xBovRe]
    this.lijstPosition = lijstPosition
    this.lijstEnabled = lijstEnabled
    this.kp = kp
    this.op = op
    this.kPos = kPos
    this.graden = graden
    this.overhang = overhang
    this.pos = pos
    this.dpy = dpy
    this.dpz = dpz
    this.dpok = dpok
    this.dpog = dpog
    this.np = np
    this.byl = byl
    this.douglasPlanken = douglasPlanken
    this.wallConfig = wallConfig
    this.kapschuurConfig = kapschuurConfig
    this.staanderGrootte = staanderGrootte
    this.boeideelHorizontaleOffset = boeideelHorizontaleOffset
    
    if(enabled && modelType.isPlatdak) {
      this.group.add(this.deel0)
      this.group.add(this.deel1)
      this.group.add(this.deel2)
      this.group.add(this.deel3)
    } 

    if(enabled && ((!this.modelType.isZadeldak && modelType.isZadeldak) || (!this.modelType.isKapschuur && modelType.isKapschuur))) {
      this.group.add(this.zadeldak1)
      this.group.add(this.zadeldak2)
      this.group.add(this.zadeldak3)
      this.group.add(this.zadeldak4)
      this.group.add(this.zadeldak5)
      this.group.add(this.zadeldak6)
      this.group.add(this.zadeldak7)
      this.group.add(this.zadeldak8)
    }
    this.enabled = enabled
    this.modelType = modelType

    const breedte = this.modelType.isKapschuur ? this.kapschuurConfig.topX*2 : z
    
    if(enabled && this.modelType.isPlatdak) {
      const deel0 = this.deel0
      const deel1 = this.deel1
      const deel2 = this.deel2
      const deel3 = this.deel3

      // x as (deel 0 en deel 1)
      this.meshUpdate(deel0.children[0], douglasPlanken, breedte + (xBovVo + xBovAc), dy, dz, "x")
      this.meshUpdate(deel1.children[0], douglasPlanken, breedte + (xBovVo + xBovAc), dy, dz, "x")
      this.materialUpdate(deel0.children[1], breedte + (xBovVo + xBovAc) - (lijstPosition ? (2 * dsz) : 0), dsy, dsz, this.wallConfig.position === "buiten" ? this.textureFrame : this.texture)
      this.materialUpdate(deel1.children[1], breedte + (xBovVo + xBovAc) - (lijstPosition ? (2 * dsz) : 0), dsy, dsz, this.wallConfig.position === "buiten" ? this.textureFrame : this.texture)
      this.meshUpdate(deel0.children[1], douglasPlanken, breedte + (xBovVo + xBovAc) - (lijstPosition ? (2 * dsz) : 0), dsy, dsz)
      this.meshUpdate(deel1.children[1], douglasPlanken, breedte + (xBovVo + xBovAc) - (lijstPosition ? (2 * dsz) : 0), dsy, dsz)

      deel0.position.x = breedte/2 - xBovVo/2 + xBovAc/2
      deel0.position.z = -xBovLi

      deel0.children[0].position.x = 0//breedte/2 - xBovVo/2 + xBovAc/2
      deel0.children[0].position.z = - dz / 2
      deel0.children[0].position.y = dy/2

      // deel0.children[1].position.x = breedte / 2 + xBovAc / 2 - xBovVo / 2
      deel0.children[1].position.z = - (0.5 * dsz) + (lijstPosition ? dsz : -dz)
      deel0.children[1].position.y = dy - dsy / 2
      deel0.children[1].visible = lijstEnabled

      // deel1.children[0].position.x = breedte / 2 - (!kp.a?kx/2:0)
      deel1.children[0].position.z = -dz/2
      deel1.children[0].position.y = dy/2

      // deel1.children[1].position.x = breedte / 2 + xBovAc / 2 - xBovVo / 2
      deel1.children[1].position.z = - (0.5 * dsz) + (lijstPosition ? dsz : -dz)
      deel1.children[1].position.y = dy - dsy / 2
      deel1.children[1].visible = lijstEnabled

      // deel1.position.z = x + (!kp.r?-kx:!kp.l?kx:0) 
      // deel1.position.x = breedte - (!kp.a?kx:0)
      deel1.position.x = breedte/2 - xBovVo/2 + xBovAc/2
      deel1.rotation.y = Math.PI
      deel1.position.z = x + xBovRe

      // z as (deel 2 en deel 3)
      this.meshUpdate(deel2.children[0], douglasPlanken, x + (xBovLi + xBovRe) + (2 * dz) + ((lijstPosition || !lijstEnabled) ? 0 : (2*dsz)), dy, dz, "z")
      this.meshUpdate(deel3.children[0], douglasPlanken, x + (xBovLi + xBovRe) + (2 * dz) + ((lijstPosition || !lijstEnabled) ? 0 : (2*dsz)), dy, dz, "z")
      this.materialUpdate(deel2.children[1], x + (xBovLi + xBovRe) + ((lijstPosition || !lijstEnabled) ? 0 : (2*dsz)) + ((lijstPosition || !lijstEnabled) ? 0 : (2*dz)), dsy, dsz, this.wallConfig.position === "buiten" ? this.textureFrame : this.texture)
      this.materialUpdate(deel3.children[1], x + (xBovLi + xBovRe) + ((lijstPosition || !lijstEnabled) ? 0 : (2*dsz)) + ((lijstPosition || !lijstEnabled) ? 0 : (2*dz)), dsy, dsz, this.wallConfig.position === "buiten" ? this.textureFrame : this.texture)
      this.meshUpdate(deel2.children[1], douglasPlanken, x + (xBovLi + xBovRe) + ((lijstPosition || !lijstEnabled) ? 0 : (2*dsz)) + ((lijstPosition || !lijstEnabled) ? 0 : (2*dz)), dsy, dsz)
      this.meshUpdate(deel3.children[1], douglasPlanken, x + (xBovLi + xBovRe) + ((lijstPosition || !lijstEnabled) ? 0 : (2*dsz)) + ((lijstPosition || !lijstEnabled) ? 0 : (2*dz)), dsy, dsz)

      deel2.children[0].position.z = -dz/2
      deel2.children[0].position.y = deel0.children[0].position.y

      deel2.children[1].position.z = deel0.children[1].position.z 
      deel2.children[1].position.y = deel0.children[1].position.y
      deel2.children[1].visible = lijstEnabled

      deel2.position.x = - xBovVo
      deel2.position.z = x/2 - xBovLi/2 + xBovRe/2

      deel3.children[0].position.z = - dz / 2
      deel3.children[0].position.y = deel0.children[0].position.y

      deel3.children[1].position.z = deel0.children[1].position.z 
      deel3.children[1].position.y = deel0.children[1].position.y
      deel3.children[1].visible = lijstEnabled

      deel3.position.z = x/2 - xBovLi/2 + xBovRe/2
      deel3.position.x = breedte + xBovAc

      console.log(deel3)

      //koof
      if(op) {
        this.koof0 = this.meshCreation (this.THREE, this.douglasPlanken, this.textureLoader, breedte + ((kp.l&&kp.r?2:0) * kx), dz, kx, this.texture)
        this.koof0.position.y = kPos+dz/2
        this.koof0.name = "koof"

        this.deel0.remove(this.deel0.getObjectByName("koof"))
        if(kp.l) {
          let koof = this.koof0.clone()
          koof.position.x = kx/2
          koof.position.z = kx/2
          this.deel0.add(koof)
        } 

        this.deel1.remove(this.deel1.getObjectByName("koof"))
        if(kp.r) {
          let koof = this.koof0.clone()
          koof.position.x = -kx/2
          koof.position.z = kx/2
          this.deel1.add(koof)
        }

        this.koof1 = this.meshCreation (this.THREE, this.douglasPlanken, this.textureLoader, x + ((kp.l&&kp.r?2:1) * kx), dz, kx, this.texture)
        this.koof1
        this.koof1.position.y = kPos+dz/2
        this.koof1.name = "koof"

        this.deel2.remove(this.deel2.getObjectByName("koof"))
        if(kp.v) {
          let koof = this.koof1.clone()
          koof.position.x = 0
          koof.position.z = kx/2
          this.deel2.add(koof.clone())
        } 
      } else {
        this.deel0.remove(this.deel0.getObjectByName("koof"))
        this.deel1.remove(this.deel1.getObjectByName("koof"))
        this.deel2.remove(this.deel2.getObjectByName("koof"))
      }

    }else {
      this.deel0.removeFromParent()
      this.deel1.removeFromParent()
      this.deel2.removeFromParent()
      this.deel3.removeFromParent()
    }

    if(enabled && (this.modelType.isZadeldak || this.modelType.isKapschuur)) {
      const radians = graden*(Math.PI/180)
      const HalfDepth = this.modelType.isKapschuur ? (z-(y-this.kapschuurConfig.achtergevelHoogte)/Math.tan(radians))/2 : z/2
      const yMax = Math.tan(radians) * (HalfDepth + (dy/Math.sin(radians)) - (byl/Math.tan(radians)) + boeideelHorizontaleOffset) + pos + byl
      const yMin = pos

      const totalWidthVoor = (yMax-yMin)/Math.tan(radians) -(byl/Math.tan(radians))
      const totalWidthAchter = this.modelType.isKapschuur ? (yMax-yMin+(y-this.kapschuurConfig.achtergevelHoogte))/Math.tan(radians) -(byl/Math.tan(radians))-2 : totalWidthVoor
      const totalWidthHVoor = (yMax-yMin+(dpy/(Math.cos(radians))) - (dpy*Math.cos(radians)))/Math.tan(radians) + (Math.cos(radians)*dpog) -(byl/Math.tan(radians))
      const totalWidthHVoorLissabon = totalWidthHVoor - (dy/Math.tan(radians)) - (dz/Math.sin(radians))*1.8
      const totalWidthHAchter = this.modelType.isKapschuur ? (yMax-yMin+(y-this.kapschuurConfig.achtergevelHoogte)+(dpy/(Math.cos(radians))) - (dpy*Math.cos(radians)))/Math.tan(radians) + (Math.cos(radians)*dpog) -(byl/Math.tan(radians)) - (this.modelType.isLissabon ? dy/Math.tan(radians) : 0) +2: totalWidthHVoor
      const totalWidthHAchterLissabon = totalWidthHAchter - (dz/Math.sin(radians))*3
      //lissabon
      const oy = dy/(Math.sin((90-graden)*(Math.PI/180)))
      const breedteVanOy = oy/(Math.tan(graden*(Math.PI/180)))

      //this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`, (loadedTexture) => {
        let g_verticaleBoeideelVoor = new this.THREE.BufferGeometry()
        let g_verticaleBoeideelAchter = new this.THREE.BufferGeometry()
        if(byl > 0) {
          g_verticaleBoeideelVoor.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(totalWidthVoor, dy, dz, graden, 0, byl, byl)), 3))
          g_verticaleBoeideelVoor.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(totalWidthVoor, dy, dz, graden, 0, byl, byl)), 2))
          g_verticaleBoeideelVoor = g_verticaleBoeideelVoor.toNonIndexed()
          g_verticaleBoeideelVoor.computeVertexNormals()

          g_verticaleBoeideelAchter.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(totalWidthAchter, dy, dz, graden, 0, byl, byl)), 3))
          g_verticaleBoeideelAchter.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(totalWidthAchter, dy, dz, graden, 0, byl, byl)), 2))
          g_verticaleBoeideelAchter = g_verticaleBoeideelAchter.toNonIndexed()
          g_verticaleBoeideelAchter.computeVertexNormals()
        } else {
          g_verticaleBoeideelVoor.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(schuineGording2.positionBuffer(totalWidthVoor, dy, dz, graden)), 3))
          g_verticaleBoeideelVoor.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(schuineGording2.uvBuffer(totalWidthVoor, dy, dz, graden)), 2))
          g_verticaleBoeideelVoor.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(schuineGording2.normalBuffer()), 3))
          g_verticaleBoeideelVoor.setIndex(schuineGording2.indexBuffer());
          g_verticaleBoeideelVoor.groups = schuineGording2.groupBuffer()

          if(this.modelType.isLissabon) {
            g_verticaleBoeideelAchter.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(milaanGording.positionBuffer(totalWidthAchter-breedteVanOy-staanderGrootte+dy, dy, dz, graden, 0, oy, oy)), 3))
            g_verticaleBoeideelAchter.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(milaanGording.uvBuffer(totalWidthAchter-breedteVanOy-staanderGrootte+dy, dy, dz, graden, 0, oy, oy)), 2))
            g_verticaleBoeideelAchter = g_verticaleBoeideelAchter.toNonIndexed()
            g_verticaleBoeideelAchter.computeVertexNormals()
          } else {
            g_verticaleBoeideelAchter.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(schuineGording2.positionBuffer(totalWidthAchter, dy, dz, graden)), 3))
            g_verticaleBoeideelAchter.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(schuineGording2.uvBuffer(totalWidthAchter, dy, dz, graden)), 2))
            g_verticaleBoeideelAchter.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(schuineGording2.normalBuffer()), 3))
            g_verticaleBoeideelAchter.setIndex(schuineGording2.indexBuffer());
            g_verticaleBoeideelAchter.groups = schuineGording2.groupBuffer()
          }
        }

      const vertical = [this.zadeldak1, this.zadeldak2, this.zadeldak3, this.zadeldak4]
      vertical.forEach((zadeldak, i) => {
        if(i%2) {
          zadeldak.geometry = g_verticaleBoeideelAchter
        } else {
          zadeldak.geometry = g_verticaleBoeideelVoor
        }
        this.textureSettings(zadeldak.material.map, 1, 1, 0, Math.random())
      })
      this.zadeldak1.position.set(breedte/2, yMax-yMin, -overhang-dz/2)
      this.zadeldak2.position.set(breedte/2, yMax-yMin, -overhang-dz/2)
      this.zadeldak3.position.set(breedte/2, yMax-yMin, x+overhang+dz/2)
      this.zadeldak4.position.set(breedte/2, yMax-yMin, x+overhang+dz/2)

      const horizontalPlankHeight = dz/Math.cos(graden*(Math.PI/180))
      const horizontal = [this.zadeldak5, this.zadeldak6, this.zadeldak7, this.zadeldak8]
      horizontal.forEach((zadeldak, i) => {
        if(i%2) {
          zadeldak.geometry.attributes.position.array = new Float32Array(schuineGording.positionBuffer(totalWidthHAchter, dpy, dpz, graden))
          zadeldak.geometry.attributes.uv.array = new Float32Array(schuineGording.uvBuffer(totalWidthHAchter, dpy, dpz, graden))
        } else {
          zadeldak.geometry.attributes.position.array = new Float32Array(schuineGording.positionBuffer(totalWidthHVoor, dpy, dpz, graden))
          zadeldak.geometry.attributes.uv.array = new Float32Array(schuineGording.uvBuffer(totalWidthHVoor, dpy, dpz, graden))
        }
        zadeldak.geometry.attributes.position.needsUpdate = true
        zadeldak.geometry.attributes.uv.needsUpdate = true
        //zadeldak.material.map = loadedTexture.clone()
        this.textureSettings(zadeldak.material.map, 1, 1, 0, Math.random())
      })
      this.zadeldak5.position.set(breedte/2, yMax-yMin+horizontalPlankHeight, -overhang+dpz/2-dz-dpok)
      this.zadeldak6.position.set(breedte/2, yMax-yMin+horizontalPlankHeight, -overhang+dpz/2-dz-dpok)
      this.zadeldak7.position.set(breedte/2, yMax-yMin+horizontalPlankHeight, x+overhang-dpz/2+dz+dpok)
      this.zadeldak8.position.set(breedte/2, yMax-yMin+horizontalPlankHeight, x+overhang-dpz/2+dz+dpok)
      


      // Lissabon
    if(this.modelType.isLissabon) {
      const g_horizontaleBoeideelVoorOnder = new this.THREE.BufferGeometry()
      g_horizontaleBoeideelVoorOnder.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(totalWidthHVoorLissabon*Math.tan(radians), dz, overhang, 90-graden)), 3))
      g_horizontaleBoeideelVoorOnder.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(totalWidthHVoorLissabon*Math.tan(radians), dz, overhang, 90-graden)), 2))
      g_horizontaleBoeideelVoorOnder.setIndex(schuineGording.indexBuffer());
      g_horizontaleBoeideelVoorOnder.rotateX(Math.PI)
      g_horizontaleBoeideelVoorOnder.computeVertexNormals()

      const g_horizontaleBoeideelAchterOnder = new this.THREE.BufferGeometry()
      g_horizontaleBoeideelAchterOnder.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer(totalWidthHAchterLissabon*Math.tan(radians), dz, overhang, 90-graden)), 3))
      g_horizontaleBoeideelAchterOnder.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer(totalWidthHAchterLissabon*Math.tan(radians), dz, overhang, 90-graden)), 2))
      g_horizontaleBoeideelAchterOnder.setIndex(schuineGording.indexBuffer());
      g_horizontaleBoeideelAchterOnder.rotateX(Math.PI)
      g_horizontaleBoeideelAchterOnder.computeVertexNormals()

      this.zadeldak9.geometry = g_horizontaleBoeideelVoorOnder
      this.zadeldak9.rotation.z = -Math.PI/2
      this.zadeldak9.position.set(breedte/2-totalWidthHVoorLissabon, 0, -overhang/2)

      const bovenPositie = totalWidthHVoorLissabon * Math.tan(radians)
      const achterPositie = bovenPositie - (totalWidthHAchterLissabon * Math.tan(radians))

      this.zadeldak10.geometry = g_horizontaleBoeideelAchterOnder
      this.zadeldak10.position.set(breedte/2+totalWidthHAchterLissabon, achterPositie, -overhang/2)
      this.zadeldak10.rotation.z = -Math.PI/2
      this.zadeldak10.rotation.y = Math.PI

      this.zadeldak11.geometry = g_horizontaleBoeideelVoorOnder
      this.zadeldak11.rotation.z = -Math.PI/2
      this.zadeldak11.position.set(breedte/2-totalWidthHVoorLissabon, 0, x+overhang/2)

      this.zadeldak12.geometry = g_horizontaleBoeideelAchterOnder
      this.zadeldak12.position.set(breedte/2+totalWidthHAchterLissabon, achterPositie, x+overhang/2)
      this.zadeldak12.rotation.z = -Math.PI/2
      this.zadeldak12.rotation.y=Math.PI

      const puntBoeideelLinksOnderin = (yMax-yMin) - ((totalWidthAchter-breedteVanOy-staanderGrootte+dy)*Math.tan(graden*(Math.PI/180))) - (dy/(Math.sin((90-graden)*(Math.PI/180))))
      const lijstPuntPositie = puntBoeideelLinksOnderin + dy*(Math.tan((graden)*(Math.PI/180)))

      const g_verticaalBuiten = new this.THREE.BufferGeometry()
      g_verticaalBuiten.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer((yMin+lijstPuntPositie)*Math.cos(graden*(Math.PI/180)), dy, dz, graden)), 3))
      g_verticaalBuiten.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer((yMin+lijstPuntPositie)*Math.cos(graden*(Math.PI/180)), dy, dz, graden)), 2))
      g_verticaalBuiten.setIndex(schuineGording.indexBuffer());
      g_verticaalBuiten.computeVertexNormals()
      g_verticaalBuiten.rotateZ((90-graden)*(Math.PI/180))
      
      this.zadeldak13.geometry = g_verticaalBuiten
      this.zadeldak13.position.y = lijstPuntPositie
      this.zadeldak13.position.x = z-staanderGrootte
      this.zadeldak13.position.z = -overhang-dz/2

      this.zadeldak14.geometry = g_verticaalBuiten
      this.zadeldak14.position.y = lijstPuntPositie
      this.zadeldak14.position.x = z-staanderGrootte
      this.zadeldak14.position.z = x+overhang+dz/2

      const g_horizontaleBoeideelVoorkant = new this.THREE.BufferGeometry()
      g_horizontaleBoeideelVoorkant.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(dz, this.kapschuurConfig.achtergevelHoogte+this.ry, overhang)), 3))
      g_horizontaleBoeideelVoorkant.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
      g_horizontaleBoeideelVoorkant.setAttribute( 'normal', new this.THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
      g_horizontaleBoeideelVoorkant.setIndex(boxBuffer.indexBuffer());
      g_horizontaleBoeideelVoorkant.groups = boxBuffer.groupBuffer()

      const g_horizontaleBoeideelAchterkant = new this.THREE.BufferGeometry()
      g_horizontaleBoeideelAchterkant.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(schuineGording.positionBuffer((this.kapschuurConfig.achtergevelHoogte+this.ry)*Math.cos((90-graden) * (Math.PI/180)), dz, overhang+staanderGrootte, 90-graden)), 3))
      g_horizontaleBoeideelAchterkant.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(schuineGording.uvBuffer((this.kapschuurConfig.achtergevelHoogte+this.ry)*Math.tan(radians), dz, overhang+staanderGrootte, 90-graden)), 2))
      g_horizontaleBoeideelAchterkant.setIndex(schuineGording.indexBuffer());
      g_horizontaleBoeideelAchterkant.computeVertexNormals()

      this.zadeldak15.geometry = g_horizontaleBoeideelVoorkant
      this.textureSettings(this.zadeldak15.material.map, x / 2320,  overhang / 145, Math.PI/2, 0, Math.random())
      this.zadeldak15.material.map.needsUpdate = true
      this.zadeldak15.material.needsUpdate = true
      this.zadeldak15.position.set(z-staanderGrootte+dz/2, this.kapschuurConfig.achtergevelHoogte-y - (this.kapschuurConfig.achtergevelHoogte-this.ry)/2, -overhang/2)

      this.zadeldak16.geometry = g_horizontaleBoeideelVoorkant
      this.textureSettings(this.zadeldak16.material.map, x / 2320,  overhang / 145, Math.PI/2, 0, Math.random())
      this.zadeldak16.material.map.needsUpdate = true
      this.zadeldak16.material.needsUpdate = true
      this.zadeldak16.position.set(z-staanderGrootte+dz/2, this.kapschuurConfig.achtergevelHoogte-y - (this.kapschuurConfig.achtergevelHoogte-this.ry)/2, x+overhang/2)

      this.zadeldak17.geometry = g_horizontaleBoeideelAchterkant
      this.zadeldak17.position.set(z, this.kapschuurConfig.achtergevelHoogte-y+this.ry, staanderGrootte/2-overhang/2)
      this.zadeldak17.rotation.z = radians

      this.zadeldak18.geometry = g_horizontaleBoeideelAchterkant
      this.zadeldak18.position.set(z, this.kapschuurConfig.achtergevelHoogte-y+this.ry, x-staanderGrootte/2+overhang/2)
      this.zadeldak18.rotation.z = radians

      if(this.modelType.isLissabon) {
        this.group.add(this.zadeldak9, this.zadeldak10, this.zadeldak11, this.zadeldak12, this.zadeldak13, this.zadeldak14, this.zadeldak15, this.zadeldak16, this.zadeldak17, this.zadeldak18)
      }
    } else {
      this.zadeldak9.removeFromParent()
      this.zadeldak10.removeFromParent()
      this.zadeldak11.removeFromParent()
      this.zadeldak12.removeFromParent()
      this.zadeldak13.removeFromParent()
      this.zadeldak14.removeFromParent()
      this.zadeldak15.removeFromParent()
      this.zadeldak16.removeFromParent()
      this.zadeldak17.removeFromParent()
      this.zadeldak18.removeFromParent()
    }


      
    }else {
      this.zadeldak1.removeFromParent()
      this.zadeldak2.removeFromParent()
      this.zadeldak3.removeFromParent()
      this.zadeldak4.removeFromParent()
      this.zadeldak5.removeFromParent()
      this.zadeldak6.removeFromParent()
      this.zadeldak7.removeFromParent()
      this.zadeldak8.removeFromParent()
      this.zadeldak9.removeFromParent()
      this.zadeldak10.removeFromParent()
      this.zadeldak11.removeFromParent()
      this.zadeldak12.removeFromParent()
      this.zadeldak13.removeFromParent()
      this.zadeldak14.removeFromParent()
      this.zadeldak15.removeFromParent()
      this.zadeldak16.removeFromParent()
      this.zadeldak17.removeFromParent()
      this.zadeldak18.removeFromParent()
    }


    //nice bottom latjes
    ["z_Lat_Links", "z_Lat_Rechts", "x_Lat_Voor", "x_Lat_Achter"].forEach((lat) => {
      const currentLat = this.group.getObjectByName(lat)
      if(currentLat) {
        currentLat.geometry.dispose()
        currentLat.material.forEach((material) => {
          material.map.dispose()
          material.dispose()
        })
        currentLat.removeFromParent()
      }
    })
    if(this.wallConfig.position === "buiten") {
      const z_Lat_Links = this.meshCreation (this.THREE, this.douglasPlanken, this.textureLoader, breedte, 16, 62-1, this.textureHoekprofielen)
      z_Lat_Links.position.x = breedte/2
      z_Lat_Links.position.y = -8
      z_Lat_Links.position.z = this.wallConfig.wz/2-1.5
      z_Lat_Links.name = "z_Lat_Links"

      const z_Lat_Rechts = z_Lat_Links.clone()
      z_Lat_Rechts.position.z = x-this.wallConfig.wz/2+1.5
      z_Lat_Rechts.name = "z_Lat_Rechts"

      const x_Lat_Voor = this.meshCreation (this.THREE, this.douglasPlanken, this.textureLoader, x, 16, 62-1, this.textureHoekprofielen)
      x_Lat_Voor.position.x = this.wallConfig.wz/2-1.5
      x_Lat_Voor.position.y = -8
      x_Lat_Voor.position.z = x/2
      x_Lat_Voor.rotation.y = Math.PI/2
      x_Lat_Voor.name = "x_Lat_Voor"

      const x_Lat_Achter = x_Lat_Voor.clone()
      x_Lat_Achter.position.x = breedte-this.wallConfig.wz/2+1.5
      x_Lat_Achter.name = "x_Lat_Achter"

      this.group.add(z_Lat_Links, z_Lat_Rechts, x_Lat_Voor, x_Lat_Achter)
    }
  }

  updateTexture (texture) {
    this.texture = texture
    console.log(this.deel0, this.deel2)
    if(this.deel0.children[0]) {
      this.materialUpdate(this.deel0.children[0], this.z + ((this.kp.l&&this.kp.r?2:1) * this.kx), this.dy, this.dz, texture, "x")
    }
    if(this.deel0.children[1]) {
      this.materialUpdate(this.deel0.children[1], this.z + ((this.kp.l&&this.kp.r?2:1) * this.kx), this.dsy, this.dz, this.wallConfig.position === "buiten" ? this.textureFrame : this.texture)
    }
    if(this.deel2.children[0]) {
      this.materialUpdate(this.deel2.children[0], this.x + ((this.kp.l&&this.kp.r?2:1) * this.kx) + (2 * this.dz) + ((this.lijstPosition || !this.lijstEnabled) ? 0 : (2*this.dsz)), this.dy, this.dz, texture, "z")
    }
    if(this.deel2.children[1]) {
      this.materialUpdate(this.deel2.children[1], this.x + ((this.kp.l&&this.kp.r?2:1) * this.kx) + (2 * this.dz) + ((this.lijstPosition || !this.lijstEnabled) ? 0 : (2*this.dsz)), this.dsy, this.dz, this.wallConfig.position === "buiten" ? this.textureFrame : this.texture)
    }

    this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${this.texture}`, (loadedTexture) => {
      const meshes = [this.zadeldak1, this.zadeldak2, this.zadeldak3, this.zadeldak4, this.zadeldak5, this.zadeldak6, this.zadeldak7, this.zadeldak8]
      meshes.forEach((mesh) => {
        mesh.material.map = loadedTexture.clone()
        mesh.material.map.needsUpdate = true
        this.textureSettings(mesh.material.map, this.z / 2320, 1, 0, Math.random())
      })

      this.zadeldak15.material.map = loadedTexture.clone()
      this.zadeldak15.material.map.needsUpdate = true
      this.textureSettings(this.zadeldak15.material.map, this.z / 2320,  this.overhang / 145, Math.PI/2, 0, Math.random())

      this.zadeldak16.material.map = loadedTexture.clone()
      this.zadeldak16.material.map.needsUpdate = true
      this.textureSettings(this.zadeldak16.material.map, this.z / 2320,  this.overhang / 145, Math.PI/2, 0, Math.random())

      const meshes2 = [this.zadeldak9, this.zadeldak10, this.zadeldak11, this.zadeldak12, this.zadeldak13, this.zadeldak14, this.zadeldak17, this.zadeldak18]
      meshes2.forEach((mesh) => {
        mesh.material.map = loadedTexture.clone()
        mesh.material.map.needsUpdate = true
        this.textureSettings(mesh.material.map, this.z / 2320, 1, 0, Math.random())
      })
    })
  }

  textureSettings(texture, repeatX, repeatY, rotation, offsetX, offsetY) {
    texture.rotation = rotation || texture.rotation
    texture.repeat.x = repeatX ? repeatX : texture.repeat.x
    texture.repeat.y = repeatY ? repeatY : texture.repeat.y
    texture.wrapS = this.THREE.RepeatWrapping
    texture.wrapT = this.THREE.RepeatWrapping
    texture.encoding = this.THREE.sRGBEncoding
    texture.anisotropy = 16
    texture.offset.set(offsetX !== undefined ? offsetX : 0, offsetY !== undefined ? offsetY : 0)
  }

  meshCreation (THREE, douglasPlanken, textureLoader, x, y, z, texture, axis, isLijst, geoOnly) { // axis is x or y for model nice
    const geometry = new THREE.BufferGeometry()

    if(isLijst) {
      geometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(x, y, z)), 3))
      geometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
      geometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
      geometry.setIndex(boxBuffer.indexBuffer());
      geometry.groups = boxBuffer.groupBuffer()
    } else {      
      if(douglasPlanken) {
        geometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(douglasBuffer.positionBuffer(x, y, z)), 3))
        geometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(douglasBuffer.uvBuffer(x,y,z)), 3))
        // geometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(douglasBuffer.normalBuffer()), 3))
        geometry.computeVertexNormals()
        geometry.groups = douglasBuffer.groupBuffer()
      } else {
        geometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(boxBuffer.positionBuffer(x, y, z)), 3))
        geometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array(boxBuffer.uvBuffer()), 2))
        geometry.setAttribute( 'normal', new THREE.BufferAttribute( new Float32Array(boxBuffer.normalBuffer()), 3))
        geometry.setIndex(boxBuffer.indexBuffer());
        geometry.groups = boxBuffer.groupBuffer()
      }
    }

    if(geoOnly) {
      return geometry
    }


    // texture
    let textureX = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    if(this.wallConfig.position === "buiten" && axis !== undefined) {

      this.textureSettings(textureX,  x * 0.000351,  y * 0.0004331, null, axis === "z" ? 1-(this.dz*0.000351) : 0, (Math.abs(this.kPos)+this.y) * 0.0004331)
    } else {
      this.textureSettings(textureX,  x / 2320,  y * 0.0075, null, Math.random())
    }
    const materialX = new THREE.MeshStandardMaterial({ map: textureX, /*normalMap: textureXN, specularMap: null, specular: new THREE.Color(0xffffff),*/ metalness: 0, roughness: 1, side: douglasPlanken ? this.THREE.DoubleSide : this.THREE.FrontSide })
    //materialX.side = THREE.BackSide


    const textureY = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureY,  x / 2320,  z * 0.0075,  0 * Math.PI)
    const materialY = new THREE.MeshStandardMaterial({ map: textureY, metalness: 0, roughness: 1, side: douglasPlanken ? this.THREE.DoubleSide : this.THREE.FrontSide })
    //materialY.side = THREE.BackSide

    const textureZ = textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`)
    this.textureSettings(textureZ,  z / 2320,  y * 0.0075,  0 * Math.PI)
    const materialZ = new THREE.MeshStandardMaterial({ map: textureZ, metalness: 0, roughness: 1, side: douglasPlanken ? this.THREE.DoubleSide : this.THREE.FrontSide })
    //materialZ.side = THREE.BackSide

    console.log(materialY, materialZ)

    // mesh
    const boeideel = new THREE.Mesh( geometry, [
      materialX,
      materialX,
      materialX,
      materialX,
      materialX,
      materialX,
    ]);
    boeideel.castShadow = true; 
    boeideel.receiveShadow = true;
    boeideel.frustumCulled = false
    return boeideel
  }

  meshUpdate(deel, douglasPlanken, x, y, z, axis) {
    // console.log('test')
    if(douglasPlanken) {
      // deel.geometry.setAttribute( 'position', new this.THREE.BufferAttribute( new Float32Array(douglasBuffer.positionBuffer(x, y, z)), 3))
      // deel.geometry.setAttribute( 'uv', new this.THREE.BufferAttribute( new Float32Array(douglasBuffer.uvBuffer(x,y,z)), 3))
      // deel.geometry.computeVertexNormals()
      // deel.geometry.groups = douglasBuffer.groupBuffer()
      deel.geometry.copy( this.meshCreation(this.THREE, true, false, x, y, z, false, false, false, true) )
      deel.geometry.attributes.position.array = new Float32Array(douglasBuffer.positionBuffer(x, y, z))
    } else {
      deel.geometry.copy( this.meshCreation(this.THREE, false, false, x, y, z, false, false, false, true) )
      deel.geometry.attributes.position.array = new Float32Array(boxBuffer.positionBuffer(x, y, z))
    }
    deel.geometry.attributes.position.needsUpdate = true

    deel.material[0].side = douglasPlanken ? this.THREE.DoubleSide : this.THREE.FrontSide
    if(this.wallConfig.position === "buiten" && axis !== undefined) {
      this.textureSettings(deel.material[0].map, x * 0.000351,  y * 0.0004331, null, axis === "z" ? 1-(this.dz*0.000351) : 0, (Math.abs(this.kPos)+this.y) * 0.0004331)
    } else {
      this.textureSettings(deel.material[0].map, z / 2320,  y * 0.0075, null, Math.random())
    }
    deel.material[0].map.needsUpdate = true
    deel.material[0].needsUpdate = true

    deel.material[2].side = douglasPlanken ? this.THREE.DoubleSide : this.THREE.FrontSide
    if(this.wallConfig.position === "buiten" && axis !== undefined) {
      this.textureSettings(deel.material[2].map, x * 0.000351,  y * 0.0004331, null, axis === "z" ? 1-(this.dz*0.000351) : 0, (Math.abs(this.kPos)+this.y) * 0.0004331)
    } else {
      this.textureSettings(deel.material[2].map, x / 2320,  z * 0.0075)
    }
    deel.material[2].map.needsUpdate = true
    deel.material[2].needsUpdate = true

    
    deel.material[4].side = douglasPlanken ? this.THREE.DoubleSide : this.THREE.FrontSide
    if(this.wallConfig.position === "buiten" && axis !== undefined) {
      this.textureSettings(deel.material[4].map, x * 0.000351,  y * 0.0004331, null, axis === "z" ? 1-(this.dz*0.000351) : 0, (Math.abs(this.kPos)+this.y) * 0.0004331)
    } else {
      this.textureSettings(deel.material[4].map, x / 2320,  y * 0.0075)
    }
    deel.material[4].map.needsUpdate = true
    deel.material[4].needsUpdate = true
  }

  materialUpdate (deel, x, y, z, texture, axis) {

    this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`, (newTexture) => {
      const textureX = deel.material[4]
      textureX.map.dispose()
      textureX.map = newTexture
      if(this.wallConfig.position === "buiten" && axis !== undefined) {
        this.textureSettings(textureX.map, x * 0.000351,  y * 0.0004331, null, axis === "z" ? 1-(this.dz*0.000351) : 0, (Math.abs(this.kPos)+this.y) * 0.0004331)
      } else {
        this.textureSettings(textureX.map, x / 2320,  y * 0.0075, null, Math.random())
      }
      textureX.map.needsUpdate = true;
      textureX.needsUpdate = true;

      if(this.op) {
        let kp = this.kp
        let kx = this.kx
        let dz = this.dz
        let z = this.z
        let x = this.x
      
        if(kp.l) {
          if(this.deel0.getObjectByName("koof")) {
            this.meshTextureUpdate (this.deel0.getObjectByName("koof"), this.THREE, z + ((kp.l&&kp.r?2:0) * kx), dz, kx, newTexture)
          }
        }
        if(kp.r) {
          if(this.deel1.getObjectByName("koof")) {
            this.meshTextureUpdate (this.deel1.getObjectByName("koof"), this.THREE, z + ((kp.l&&kp.r?2:0) * kx), dz, kx, newTexture)
          }
        }
        if(this.deel2.getObjectByName("koof")) {
          this.meshTextureUpdate (this.deel2.getObjectByName("koof"), this.THREE, x + ((kp.l&&kp.r?2:1) * kx), dz, kx, newTexture)
        }
      }
    })
  }

  meshTextureUpdate(object, THREE, x, y, z, map) {
    console.log(object, THREE, x, y, z, map)
    const mapX = map.clone()
    this.textureSettings(mapX, x / 2320, y * 0.0075, 2*Math.PI, Math.random())
    const materialX = new THREE.MeshStandardMaterial({ map: mapX, metalness: 0, roughness: 1 })
    materialX.map.needsUpdate = true;
    materialX.needsUpdate = true;

    const mapY = map.clone()
    this.textureSettings(mapY, x / 2320, z * 0.0075, 2*Math.PI)
    const materialY = new THREE.MeshStandardMaterial({ map: mapY, metalness: 0, roughness: 1 })
    materialY.map.needsUpdate = true;
    materialY.needsUpdate = true;

    const mapZ = map.clone()
    this.textureSettings(mapZ, z / 2320, y * 0.0075, 2*Math.PI)
    const materialZ = new THREE.MeshStandardMaterial({ map: mapZ, metalness: 0, roughness: 1 })
    materialZ.map.needsUpdate = true;
    materialZ.needsUpdate = true;

    console.log(materialZ, materialY, materialX)
    console.log(x,z,y)

    // mesh
    object.material = [
      materialZ,
      materialZ,
      materialY,
      materialY,
      materialX,
      materialX
    ];
  }

  updateSluitlatTexture(texture) {
    this.textureHoekprofielen = texture

    this.textureLoader.load(`${import.meta.env.VITE_VUE_APP_SERVER}${import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION}${texture}`, (newTexture) => {
      const z_Lat_Links = this.group.getObjectByName("z_Lat_Links")
      const z_Lat_Rechts = this.group.getObjectByName("z_Lat_Rechts")
      const x_Lat_Voor = this.group.getObjectByName("x_Lat_Voor")
      const x_Lat_Achter = this.group.getObjectByName("x_Lat_Achter")
      if(z_Lat_Links) {
        this.meshTextureUpdate(z_Lat_Links, this.THREE, this.z + (2*this.wallConfig.wz)-1, 16, 62-1, newTexture)
      }
      if(z_Lat_Rechts) {
        this.meshTextureUpdate(z_Lat_Rechts, this.THREE, this.z + (2*this.wallConfig.wz)-1, 16, 62-1, newTexture)
      }
      if(x_Lat_Voor) {
        this.meshTextureUpdate(x_Lat_Voor, this.THREE, this.x, 16, 62-1, newTexture)
      }
      if(x_Lat_Achter) {
        this.meshTextureUpdate(x_Lat_Achter, this.THREE, this.x, 16, 62-1, newTexture)
      }
    })
  }

  updateFrameTexture(texture) {
    this.textureFrame = texture

    this.materialUpdate(this.deel2.children[1], this.x + ((this.kp.l&&this.kp.r?2:1) * this.kx) + ((this.lijstPosition || !this.lijstEnabled) ? 0 : (2*this.dsz)) + ((this.lijstPosition || !this.lijstEnabled) ? 0 : (2*this.dz)), this.dsy, this.dsz, this.wallConfig.position === "buiten" ? this.textureFrame : this.texture)
    this.materialUpdate(this.deel0.children[1], this.z + ((this.kp.l&&this.kp.r?2:1) * this.kx) - (this.lijstPosition ? (2 * this.dsz) : 0), this.dsy, this.dsz, this.wallConfig.position === "buiten" ? this.textureFrame : this.texture)
  }
}