<template>
  <div class="conf">
    <LoadingScreen v-if="!hasToConfirmHistory"/>

    <div class="conf-gui" :class="this.$route.path == '/cfg/invoice' ? 'offerte' : ''" v-if="!loading && !hasToConfirmHistory">
      <MainUI  />
    </div>

    <Transition name="slide-fade">
      <div class="conf-walldimensions" v-if="shouldShowWallDimensions">
        <WallDimensions/> <!--v-if="selectedWall && selectedWall.wall.parent.name != 'wallBox'"-->
      </div>
    </Transition>

    <Transition name="slide-fade">
      <div class="conf-walldimensions" v-if="selectedLichtstraatDimension && this.$route.path.includes('/cfg/roof/')">
        <LichtstraatDimensions/> <!--v-if="selectedWall && selectedWall.wall.parent.name != 'wallBox'"-->
      </div>
    </Transition>

    <!-- <div class="conf-actie" v-if="!loading && !hasToConfirmHistory"> // uitgezet omdat deze aktie voorbij is. Wellicht later weer aanzetten
      <ActieText v-show="!hasToConfirmHistory"/>
    </div> -->

    <div class="conf-sidebar" v-if="!loading && !hasToConfirmHistory">
      <SideBar v-show="!hasToConfirmHistory"/>
    </div>
    
    <!-- <div class="conf-gui-confirmDelete" v-show="!loading" :style="{left: (wallSelected !== null || elementSelected !== null) ? '0px' : '-200px', width: wallSelected ? '300px' : '200px'}">
      <ConfirmDelete  />
    </div>
      
    <div class="conf-gui-viewswitch" v-show="!loading">
      <Map3DSwitch  />
    </div> -->
    <ConfirmHistory v-if="hasToConfirmHistory" @continue="hasToConfirmHistory=false"/>

    <SizeChangeConfirmation v-if="wantsToChangeSize.size || wantsToChangeSize.model" :mode="wantsToChangeSize.size ? 'size' : 'model'" @continue="$store.dispatch('confirmSizeChange', wantsToChangeSize.size ? 'size' : 'model')" @cancel="$store.dispatch('cancelSizeChange', wantsToChangeSize.size ? 'size' : 'model')"/>

    <div v-if="getIsFinishingInvoice" style="position: relative;">
      <InvoiceFinishing :image-array="imageNameArray"/>
    </div>

    <Transition name="slide-fade">
      <div class="conf-lichtstraat-invalid" v-if="invalidLichtstraten.length > 0 && !selectedLichtstraatDimension">
        <LichtstratenInvalid />
      </div>
    </Transition>

    <Transition name="slide-fade">
      <div class="conf-lichtstraat-invalid" v-if="this.$route.path.includes('/cfg/roof/lichtstraten') && invalidLichtstraten.length < 1 && !selectedLichtstraatDimension && !loading">
        <LichtstratenHelper />
      </div>
    </Transition>

    <ThreeView class="conf-three" v-show="!loading" v-if="!hasToConfirmHistory" />
  </div>
</template>

<script>
  import ThreeView from '@/components/ThreeView.vue'
  import WallDimensions from '@/views/configurator-ui/WallDimensions.vue'
  import LichtstraatDimensions from '@/views/configurator-ui/LichtstraatDimensions.vue'
  import MainUI from '@/views/configurator-ui/Main.vue'
  import SideBar from '@/views/configurator-ui/switch.vue'
  import ConfirmHistory from '@/views/configurator-ui/ConfirmHistory.vue'
  import SizeChangeConfirmation from '@/views/configurator-ui/SizeChangeConfirmation.vue'
  import InvoiceFinishing from '@/views/configurator-ui/InvoiceFinishing.vue'
  import LichtstratenInvalid from "@/views/configurator-ui/LichtstratenInvalid.vue";
  import LichtstratenHelper from "@/views/configurator-ui/LichtstratenHelper.vue";
  // import ActieText from '@/views/configurator-ui/ActieText.vue' // uitgezet omdat deze aktie voorbij is. Wellicht later weer aanzetten
  import LoadingScreen from '@/components/LoadingScreen.vue'
  import { mapGetters } from 'vuex'

  export default {
    name: "Configurator",

    data() {
      return {
        hasToConfirmHistory: true,
      }
    },

    components: {
      ThreeView,
      WallDimensions,
      LichtstraatDimensions,
      MainUI,
      // ConfirmDelete,
      // Map3DSwitch,
      SideBar,
      ConfirmHistory,
      SizeChangeConfirmation,
      InvoiceFinishing,
      LichtstratenInvalid,
      LichtstratenHelper,
      // ActieText, // uitgezet omdat deze actie voorbij is. Wellicht later weer aanzetten
      LoadingScreen
    },

    computed: {
      ...mapGetters({
        loading: 'getLoading',
        wallSelected: 'getSelectedWall',
        elementSelected: 'getSelectedElement',
        selectedWall: 'getSelectedWall',
        getIsFinishingInvoice: 'getIsFinishingInvoice',
        imageNameArray: 'getImageNameArray',
        isRetourLink: 'getIsRetourLink',
        wantsToChangeSize: 'getWantsToChangeSize',
        selectedWallObject: 'getSelectedWallObject',
        invalidLichtstraten: 'getInvalidLichtstraten',
        selectedLichtstraatDimension: 'getSelectedLichtstraatDimension'
      }),

      shouldShowWallDimensions() {
        return this.wallSelected && this.wallSelected.wall.parent.name != 'wallBox' && this.wallSelected.wall.name != 'topgevelSelectbox' && !this.selectedWallObject?.isBorstwering
      }
    },

    methods: {
      switchView(mode) {
        this.currentView = mode;
      }
    },

    created() {
      console.log("localStorage", localStorage.getItem('currentConfig'))
      if(!localStorage.getItem('currentConfig') || this.isRetourLink) {
        this.hasToConfirmHistory = false
      }
    }
  }
</script>

<style lang="scss" scoped>

.conf {
  overflow: hidden;
  max-width: 100vw;
  max-height: 100vh;

  &-gui {
    // positioning and size
    position: fixed;
    overflow: hidden;

    @media (min-width: 1000px) {
      top: 25px;
      right: 0;
      width: 600px;
      transition: width 500ms ease;
      height: calc(100vh - 50px);

      // styling
      border-radius: $radius-l 0px 0px $radius-l;
    }

    @media (max-width: 999px) {
      bottom:  0;
      left: 0;
      height: 50vh;
      width: 100vw;

      // styling
      border-radius: $radius-l $radius-l 0px 0px;
    }

    // alingment of the content
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;

    // styling
    box-shadow: $shadow-1;
  }
  // styling walldimensionsbox
  &-walldimensions {
    position: absolute;
    top: 25px;
    left: calc((100vw - 530px) / 2 - (500px / 2));
  }

  &-lichtstraat {
    &-invalid {
      position: absolute;
      top: 25px;
      left: calc((100vw - 530px) / 2 - (500px / 2));
    }
  }
}

.offerte {
  width: 50vw;

  @media (max-width: 999px) {
    width: 100%;
  }
}

.slide-fade-enter-active {
  transition: transform 0.2s ease-out;
}
.slide-fade-leave-active {
  transition: transform 0.2s ease-in;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-105px);
}
</style>