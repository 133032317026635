// import Vue from 'vue'
import * as VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index.js'

// Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/cfg/'
  },
  {
    path: '/cfg/',
    component: () => import(/* webpackChunkName: "configurator" */ '../views/Configurator.vue'),

    children: [
      {
        path: '',
        name: 'Configurator types',
        beforeEnter(to, from, next) {
          /*store.dispatch('getModelConfig', to.params.id).then(() => {
            router.push('/cfg/')
          })*/
          console.log(to)
          var url_string = 'http://a.nl'+to.fullPath; 
          var url = new URL(url_string);
          var c = url.searchParams.get("configId");
          console.log(c);
          if(c){
            store.dispatch('getAvailableModels').then(() => {
              store.dispatch('getSlidingWallComponents', {categories: ['Schuifwand'], trueOnline: true}).then(() => {
                store.dispatch('getSchuifwandTypes').then(() => {
                  store.dispatch('getOptions').then(() => {
                    store.dispatch('getUitloopOptions').then(() => {
                      store.dispatch('getModelConfig', c).then(() => {
                        next()
                      })
                    })
                  })
                })
              })
            })
          } else {
            next()
          }
        },
        component: () => import(/* webpackChunkName: "types" */ '../views/configurator-ui/sections/Types.vue'),
        meta: { switchRoute: true }
      },
      {
        path: 'models',
        name: 'Configurator models',
        component: () => import(/* webpackChunkName: "models" */ '../views/configurator-ui/sections/Models.vue'),
        meta: { switchRoute: true }
      },
      {
        path: 'sizes',
        name: 'Configurator sizes',
        component: () => import(/* webpackChunkName: "sizes" */ '../views/configurator-ui/sections/Sizes.vue'),
        meta: { switchRoute: true }
      },
      {
        path: 'walls/',
        // name: 'Configurator walls',
        component: () => import(/* webpackChunkName: "walls" */ '../views/configurator-ui/sections/Walls.vue'),

        children: [
          {
            path: '',
            name: 'Add walls',
            component: () => import(/* webpackChunkName: "AddWalls" */ '../views/configurator-ui/sections/wall-sections/AddWalls.vue'),
            meta: { switchRoute: true },
          },
          // {
          //   path: 'doors-and-windows',
          //   name: 'Doors and windows',
          //   component: () => import(/* webpackChunkName: "DoorsAndWindows" */ '../views/configurator-ui/sections/wall-sections/DoorsAndWindows.vue'),
          // },
          {
            path: 'steellook',
            name: 'steellook',
            component: () => import(/* webpackChunkName: "steellook" */ '../views/configurator-ui/sections/wall-sections/Steellook.vue'),
          },
          {
            path: 'steellook-borstwering',
            name: 'steellook-borstwering',
            component: () => import(/* webpackChunkName: "steellook" */ '../views/configurator-ui/sections/wall-sections/SteellookBorstwering.vue'),
          },
          {
            path: 'sliding-walls',
            name: 'sliding walls',
            component: () => import(/* webpackChunkName: "SlidingWalls" */ '../views/configurator-ui/sections/wall-sections/SlidingWalls.vue'),
          },
        ]
      },
      {
        path: 'doorswindows/',
        // name: 'Configurator walls',
        // name: 'Doors Windows Main',
        component: () => import(/* webpackChunkName: "walls" */ '../views/configurator-ui/sections/DoorsWindowsMain.vue'),
 
        children: [
          {
            //path: 'doors-and-windows', // old
            path: '',
            name: 'Basic doors',
            component: () => import(/* webpackChunkName: "DoorsAndWindows" */ '../views/configurator-ui/sections/doorswindows-sections/DoorsAndWindows.vue'),
            meta: { switchRoute: true },
          },
          {
            path: 'bwindows',
            name: 'Basic windows',
            component: () => import(/* webpackChunkName: "DoorsAndWindows" */ '../views/configurator-ui/sections/doorswindows-sections/BasicWindows.vue'),
            // meta: { switchRoute: true },
          },
          {
            path: 'sldoors',
            name: 'Steellook doors',
            component: () => import(/* webpackChunkName: "DoorsAndWindows" */ '../views/configurator-ui/sections/doorswindows-sections/SteellookDoors.vue'),
            // meta: { switchRoute: true },
          },
          {
            path: 'slwindows',
            name: 'Steellook windows',
            component: () => import(/* webpackChunkName: "DoorsAndWindows" */ '../views/configurator-ui/sections/doorswindows-sections/SteellookWindows.vue'),
            // meta: { switchRoute: true },
          },
          {
            path: 'slwindowsbw',
            name: 'Steellook doors borstwering',
            component: () => import(/* webpackChunkName: "DoorsAndWindows" */ '../views/configurator-ui/sections/doorswindows-sections/SteellookBorstwering.vue'),
            // meta: { switchRoute: true },
          },
        ]
      },
      {
        path: 'colors',
        name: 'Configurator colors',
        component: () => import(/* webpackChunkName: "colors" */ '../views/configurator-ui/sections/Colors.vue'),
        meta: { switchRoute: true }
      },
      {
        path: 'roof/',
        // name: 'Configurator roof',
        component: () => import(/* webpackChunkName: "roof" */ '../views/configurator-ui/sections/Roof.vue'),

        children: [
          {
            path: '',
            name: 'Dakafwerking',
            component: () => import(/* webpackChunkName: "roof" */ '../views/configurator-ui/sections/roof-sections/Dakafwerking.vue'),
            meta: { switchRoute: true },
          },
          {
            path: 'uitloop',
            name: 'Uitloop',
            component: () => import(/* webpackChunkName: "roof" */ '../views/configurator-ui/sections/roof-sections/Uitloop.vue'),
          },
          {
            path: 'dakgoot',
            name: 'Dakgoot',
            component: () => import(/* webpackChunkName: "roof" */ '../views/configurator-ui/sections/roof-sections/Dakgoot.vue'),
          },
          {
            path: 'dakisolatie',
            name: 'Isolatie',
            component: () => import(/* webpackChunkName: "roof" */ '../views/configurator-ui/sections/roof-sections/DakIsolatie.vue'),
          },          
          {
            path: 'lichtstraten',
            name: 'Lichtstraten',
            component: () => import(/* webpackChunkName: "roof" */ '../views/configurator-ui/sections/roof-sections/Lichtstraten.vue'),
          },  
        ]
      },
      // {
      //   path: 'options',
      //   name: 'Configurator options',
      //   component: () => import(/* webpackChunkName: "options" */ '../views/configurator-ui/sections/Options.vue'),
      //   meta: { switchRoute: true }
      // },
      {
        path: 'options/',
        // name: 'Configurator options',
        component: () => import(/* webpackChunkName: "options" */ '../views/configurator-ui/sections/Options.vue'),

        children: [
          {
            path: '',
            name: 'Fundering',
            component: () => import(/* webpackChunkName: "options" */ '../views/configurator-ui/sections/options-sections/Fundering.vue'),
            meta: { switchRoute: true },
          },
          {
            path: 'spotjes',
            name: 'Spotjes',
            component: () => import(/* webpackChunkName: "options" */ '../views/configurator-ui/sections/options-sections/Spotjes.vue'),
          },
        ]
      },
      {
        path: 'invoice',
        name: 'Configurator invoice',
        component: () => import(/* webpackChunkName: "invoice" */ '../views/configurator-ui/sections/Invoice.vue'),
        meta: { switchRoute: true }
      },
    ]
  },
  /*{
    path: '/cfg/:id',
    beforeEnter(to) {
      store.dispatch('getModelConfig', to.params.id).then(() => {
        router.push('/cfg/')
      })
    }
  },*/
  {
    path: '/cms/',
    component: () => import(/* webpackChunkName: "cms overview" */ '../views/cms/Home.vue'),
    children: [
      {
        path: '',
        name: 'CMSOverview',
        component: () => import(/* webpackChunkName: "cms components" */ '../views/cms/CMSOverview.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'components',
        name: 'componentsOverview',
        component: () => import(/* webpackChunkName: "cms components" */ '../views/cms/ComponentsOverview.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'components/:id',
        name: 'cmsComponent',
        component: () => import(/* webpackChunkName: "cms component" */ '../views/cms/Component.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'menu-colors',
        name: 'MenuColors',
        component: () => import(/* webpackChunkName: "Menu Colors" */ '../views/cms/sections/MenuColors.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'types',
        name: 'ModelTypes',
        component: () => import(/* webpackChunkName: "Model Types" */ '../views/cms/sections/Types.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'model-categories',
        name: 'ModelCategories',
        component: () => import(/* webpackChunkName: "Model categories" */ '../views/cms/sections/ModelCategories.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'models',
        name: 'Models',
        component: () => import(/* webpackChunkName: "Models" */ '../views/cms/sections/Models.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'models/:id',
        name: 'Edit model',
        component: () => import(/* webpackChunkName: "Models" */ '../views/cms/sections/Model.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'textures',
        name: 'Textures',
        component: () => import(/* webpackChunkName: "Models" */ '../views/cms/sections/Textures.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'materials',
        name: 'Materials',
        component: () => import(/* webpackChunkName: "Models" */ '../views/cms/sections/Materials.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'globalsettings',
        name: 'Globalsettings',
        component: () => import(/* webpackChunkName: "Models" */ '../views/cms/sections/GlobalSettings.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'plank-types',
        name: 'PlankTypes',
        component: () => import(/* webpackChunkName: "Models" */ '../views/cms/sections/PlankTypes.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'berging-types',
        name: 'BergingTypes',
        component: () => import(/* webpackChunkName: "Models" */ '../views/cms/sections/BergingTypes.vue'),
        meta: { requiresAuth: true }
      },
      // {
      //   path: 'infoboxsettings',
      //   name: 'InfoboxSettings',
      //   component: () => import(/* webpackChunkName: "Menu Colors" */ '../views/cms/sections/InfoboxSettings.vue'),
      //   meta: { requiresAuth: true }
      // },
      {
        path: 'manage',
        name: 'Manage',
        component: () => import(/* webpackChunkName: "Models" */ '../views/cms/sections/Manage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'glas-types',
        name: 'GlasTypes',
        component: () => import(/* webpackChunkName: "Menu Colors" */ '../views/cms/sections/GlasTypes.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'isolatie-types',
        name: 'IsolatieTypes',
        component: () => import(/* webpackChunkName: "Menu Colors" */ '../views/cms/sections/IsolatieTypes.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'infoboxsettings',
        name: 'InfoboxSettings',
        component: () => import(/* webpackChunkName: "Menu Colors" */ '../views/cms/sections/InfoboxSettings.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'uisettings',
        name: 'UISettings',
        component: () => import(/* webpackChunkName: "Menu Colors" */ '../views/cms/sections/UISettings.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'schuifwand-types',
        name: 'schuifwand types',
        component: () => import(/* webpackChunkName: "Models" */ '../views/cms/sections/SchuifwandTypes.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'schuifwand-types/:id',
        name: 'Edit schuifwand type',
        component: () => import(/* webpackChunkName: "Models" */ '../views/cms/sections/SchuifwandType.vue'),
        meta: { requiresAuth: true }
      },
    ]
  },
  {
    path: '/invoice-requested/',
    component: () => import('../views/InvoiceRequested.vue')
  },
  {
    path: '/account/invite',
    name: 'Invite',
    component: () => import(/* webpackChunkName: "invite" */ '../views/account/Invite.vue'),
    meta: { requiresAuth: true, requiresSuperUser: true }
  },
  {
    path: '/account/create/:token',
    name: 'CreateAccount',
    component: () => import(/* webpackChunkName: "create account" */ '../views/account/CreateAccount.vue'),
  },
  {
    path: '/account/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/account/Login.vue'),
  },
]

const router = new VueRouter.createRouter({
  mode: 'history',
  history: VueRouter.createWebHistory(),
  base: import.meta.env.VITE_BASE_URL,
  routes
})

router.beforeEach(async  (to, from, next) => {
  console.log("router before each", to)
  if(to.query.Token) {
    await store.dispatch('setUserToken', to.query.Token)
    console.error("SET TOKEN", `${to.query.User}/public/${to.query.Run}/`, to)
    await store.dispatch('setConfiguratorURL', `${to.query.User}/public/${to.query.Run}/`)
    console.log(from, to)
  }

  if (to.meta.requiresAuth && !store.getters.isAuth) {
    await store.dispatch('autoLogin')
  }

  if (to.meta.requiresAuth && !store.getters.isAuth) {
    next('/account/login')
  } else if (to.meta.requiresSuperUser && !store.getters.isSuperUser) {
    next('/cms')
  } else {
    next ()
  }

  if (from.path === '/cfg/sizes') {
    store.dispatch('enableMapMode', false)
  }

})

export default router
