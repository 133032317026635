<template>
  <button v-if="filter == data.category.name" class="button-wrapper" @click="$emit('select')">
    <div class="card" :class="selected ? 'card-selected' : '' ">

      <div class="card-header">
        <div class="card-info">
          {{namePt1}} <span>{{namePt2}}</span>
        </div>
        <!-- <div v-show="specsVisible" class="card-specs" style="white-space: pre;"> -->
      </div>

      <!-- <div v-show="!specsVisible" class="card-info"><h3>{{ data.name }}</h3></div> -->

      <!-- <button class="card-button-specs" @click.stop="enableSpecs()"> -->
        <!-- @mouseover="specsVisible = true" @mouseleave="specsVisible = false" -->
      <!-- <button 
        v-if="data.infoboxIsOnline"
        class="card-button-specs" 
        @mouseover="specsMouseOver" @mouseleave="specsMouseLeave"
        @click.stop="enableAndHoldSpecs">
        <div class="card-question-mark" style="pointer-events: none;">?</div>
        <h3 class="card-button-specs-text">
          Meer info
        </h3>
      </button> -->
      <button 
        v-if="data.infoboxIsOnline"
        class="card-button-specs" 
        @mouseover="specsBtnMouseOver" 
        @mouseleave="specsBtnMouseLeave"
        @click.stop="enableAndHoldSpecs()"
      >
        <Transition name="glow">
          <div 
            v-show="hasGlowingInfoBtn"
            class="absolutepos glowinginfo"
          />
        </Transition>
        <div class="card-question-mark" style="pointer-events: none;">?</div>
        <h3 class="card-button-specs-text">
          Meer info
        </h3>
      </button>

      <div class="card-label" :class="data.category.name === 'Basic' ? 'card-label-basic' : 'card-label-exclusive'">
        {{ data.category.name }}
      </div>

      <img :src="`${previewsServer + models}previews/models/${getImgName}.png`" alt="">

    </div>
  </button>
  <Teleport to="body">
    <Transition name="fade">
      <div
        v-show="specsVisible"
        id="fullcover"
        @click="specsVisible = false"
      >              
        <div 
          :class="onMobile ? 'modalspecs modalspecs-size-mobile' : 'modalspecs modalspecs-size'"
          @click.stop=""
        >
          <div class="modaltopcontainer">
            <h3>
              {{ data.infobox.title }}
            </h3>
            <button 
              class="modalclosebutton" 
              @mouseover="specsCloseBtnMouseOver" 
              @mouseleave="specsCloseBtnMouseLeave"
              @click="specsVisible = false"
            >
              <Transition name="closebtn">
                <div 
                  v-show="hasGlowingCloseBtn"
                  class="absolutepos glowingclose"
                />
              </Transition>
              <div>
                ×
              </div>
            </button>
          </div>
          <!-- <div class="greyheader"> 
            {{ data.infobox.header }}
          </div> -->
          <div class="imagescroller">
            <img class="modalimage" :src="`/infoboximages/${data.infobox.urlEnding}`" alt="">
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
  export default {
    name: "ComponentCard",

    props: {
      data: Object,
      selected: Boolean,
      filter: String
    },

    data () {
      return {
        models: import.meta.env.VITE_VUE_APP_MODELS_LOCATION,
        previewsServer: import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER,
        onMobile: false,
        specsVisible: false,
        hasGlowingInfoBtn: false,
        hasGlowingCloseBtn: false
      }
    },
    computed: {
      // first part is regular font
      namePt1() {
        return this.data.name.split(' ',1)[0]
          .toUpperCase();
      },
      // second part must be bold text
      namePt2() {
        if (this.data.name.indexOf(' ') < 0) return '';
        return this.data.name.substring(this.data.name.indexOf(' ') + 1)
          .toUpperCase();
      },
      getImgName() {
        if (this.data.name.includes('Athene')) return 'Athene_detail';
        return this.data.name + '_detail';
      },
      // temp
      getModelInfo() {
        let result = '';
        // result = `Douglashout\nHoogte: 2600/2650 mm\nKolommen: 145x145 mm\nBalken: 145x145/190 mm\n\nStijlvol, eigentijds en verfijnd. De Rome heeft een gestroomlijnde overstek rondom voor een strakke afwerking. Dankzij de unieke dakconstructie met zwaluwstaartverbindingen kunnen grote overspanningen worden gerealiseerd zonder extra kolommen.`
        const name = this.data.name;
        switch (name) {
          case 'Athene Links':
          case 'Athene Rechts':
            result = `Douglashout<br />
              Hoogte: 2600/2650 mm<br />
              Kolommen: 145x145 mm<br />
              Balken: 145x145/190 mm<br /><br />`
            result += "Met de Athene zijn strakke ontwerp en zorgvuldig afgewerkte overstekken aan de voor- en zijkant, straalt dit buitenverblijf elegantie en moderniteit uit."
            break;
          case 'Barcelona':
            result = `Douglashout<br />
              Hoogte: 2600/2650 mm<br />
              Kolommen: 145x145 mm<br />
              Balken: 145x145/190 mm<br />
              Schuine overstekklossen<br /><br />`
            result += "De Barcelona heeft een perfecte harmonie tussen authenticiteit en landelijke schoonheid. Met zijn kenmerkende schuine overstekklossen brengt dit buitenverblijf een vleugje landelijk leven naar elke omgeving."
            break;            
          case 'Brussel':
            result = `Douglashout<br />
              Hoogte: 2600/2650 mm<br />
              Kolommen: 145x145 mm<br />
              Balken: 145x145/190 mm<br />
              Halfronde holle overstekklossen<br /><br />`
            result += "Met zijn landelijke uitstraling en karakteristieke halfronde holle overstekklossen brengt de Brussel een vleugje rustieke schoonheid naar iedere tuin."
            break;
          case 'Nice':
          result = `Douglashout<br />
              Hoogte: 2700 mm<br />
              Kolommen: 145x145 mm<br />
              Balken: 105x145/190 mm<br /><br />`
            result += "Met zijn gestroomlijnde uitstraling en afwezigheid van overstekken, is de Nice een ware blikvanger. De Deense rabatwanden lopen elegant door tot aan de dakrand, wat zorgt voor een naadloze en verfijnde afwerking."
            break;            
          case 'Rome':
            result = `Douglashout<br />
              Hoogte: 2700/2800 mm<br />
              Kolommen: 145x145 mm<br />
              Balken: 145x145/190 mm<br />
              Rechts overstekklossen<br /><br />`
            result += "Stijlvol, eigentijds en verfijnd. De Rome heeft een gestroomlijnde overstek rondom voor een strakke afwerking. Dankzij de unieke dakconstructie met zwaluwstaartenverbindingen kunnen grote overspanningen worden gerealiseerd zonder extra kolommen."
            break;
          case 'Valencia':
            result = `Douglashout<br />
              Hoogte: 2585 mm<br />
              Kolommen: 145x145 mm<br />
              Balken: 145x190 mm<br />
              Hoge zwarte daktrim<br /><br />`
            result += "De Valencia heeft een naadloos ontwerp dat een moderne uitstraling geeft zonder overstekken. Perfect voor eigentijdse tuinen met strakke contouren. De toevoeging van een hoge zwarte dakrand geeft de finishing touch aan dit stoere plaatje."
            break;      
          case 'Valencia XXL':
            result = `Douglashout<br />
              Hoogte: 2585mm<br />
              Kolommen: 190x190mm<br />
              Balken: 190x190<br />
              Hoge zwarte daktrim<br /><br />`
            result += "De Valencia XXL heeft een naadloos ontwerp. Door de afwezigheid van overstekken en de grote houtmaten, Onstaat er een moderne en robuuste uitstraling. De toevoeging van een hoge zwarte daktrim geeft de finishing touch aan dit stoere plaatje."
            break;  
          case 'Lagos':
            result = `Douglashout<br />
              Hoogte: 2560 mm<br />
              Kolommen: 145x145 mm<br />
              Balken: 64x145 mm<br /><br />`
            result += "Onze Lagos combineert eenvoud en kracht in een tijdloos design en met zijn strakke boeidelen past hij daardoor naadloos in elke tuin."
            break;
          case 'Lyon':
            result = `Douglashout<br />
              Hoogte: 2700 mm<br />
              Kolommen: 145x145 mm<br />
              Balken: 64x145 mm<br /><br />`
            result += "Buitenverblijf Lyon brengt moderniteit naar uw tuin met een strak ontwerp, opvallende overstek en een hoge dakrand. Dit functionele model biedt de ideale basis voor een eigentijdse tuin."
            break;
          case 'Nantes':
            result = `Douglashout<br />
              Hoogte: 2700 mm<br />
              Kolommen: 120x120 mm<br />
              Balken: 120x120 mm<br /><br />`
            result += "Met het buitenverblijf 'Nantes' voegt u eenvoud en moderniteit toe aan uw tuin. Het opvallende overstek aan de voorzijde en het boeideel met blokhutprofiel geven een eigentijdse uitstraling. Dankzij de gordingen op het frame heeft de Nantes een mooi hoog design."
            break;
          case 'Parijs':
            result = `Douglashout<br />
              Hoogte: 2600/2650 mm<br />
              Kolommen: 145x145 mm<br />
              Balken: 145x145/190 mm<br />
              Halfronde holle overstekklossen<br /><br />`
            result += "Geniet van de eenvoudige schoonheid en functionaliteit van dit basismodel. Met zijn karakteristieke rondom overstekken en schuine klossen, straalt dit buitenverblijf een tijdloze charme uit."
            break;
          case 'Lissabon':
            result = `Douglashout<br />
              Hoogte: 3622/4068 mm<br />
              Kolommen: 145x145 mm<br />
              Balken: 145x145/190 mm<br /><br />`
            result += "In combinatie met het Deens rabat, heeft deze kapschuur een strakke en moderne uitstraling. Hierdoor past dit buitenverblijf helemaal in de hedendaagse architectuur."
            break;
          case 'Lissabon XXL':
            result = `Douglashout<br />
              Hoogte: 4545 mm<br />
              Kolommen: 190x190 mm<br />
              Balken: 190x190 mm<br /><br />`
            result += "In combinatie met het Deens rabat, heeft deze kapschuur een strakke en moderne uitstraling. Hierdoor past dit buitenverblijf helemaal in de hedendaagse architectuur."
            break;
          case 'Stockholm':
            result = `Douglashout<br />
              Hoogte: 3755/4201 mm<br />
              Kolommen: 145x145 mm<br />
              Balken: 145x145/190 mm<br /><br />`
            result += "Kapschuur Stockholm heeft door de afwerking van schuine klossen en pen-en-gat verbindingen een landelijke uitstraling. "
            break;
          case 'Stockholm XXL':
            result = `Douglashout<br />
              Hoogte: 4525/5076 mm<br />
              Kolommen: 190x190 mm<br />
              Balken: 190x190 mm<br /><br />`
            result += "Kapschuur Stockholm heeft door de afwerking van schuine klossen en pen-en-gat verbindingen een landelijke uitstraling. "
            break;
          case 'Milaan':
            result = `Douglashout<br />
              Hoogte: 3678/4392 mm<br />
              Kolommen: 145x145 mm<br />
              Balken: 145x145/190 mm<br /><br />`
            result += "Buitenverblijf zadeldak Milaan heeft veel authentieke details. Deels uitgevoerd met pen-en-gat en zwaluwstaart verbindingen in de constructie. Door keramisch gebakken dakpannen toe te passen op het dak, krijgt deze zadeldak een karakteristieke en landelijke uitstraling."
            break;     
          case 'Milaan XXL':
            result = `Douglashout<br />
              Hoogte: 4392 mm<br />
              Kolommen: 190x190 mm<br />
              Balken: 190x190 mm<br /><br />`
            result += "Doordat de constructie deels wordt uitgevoerd met pen-en-gat- en zwaluwstaart verbindingen, krijgt dit zadeldak een landelijke uitstraling."
            break;                 
          case 'Monaco':
            result = `Douglashout<br />
              Hoogte: 4084/4987 mm<br />
              Kolommen: 145x145 mm<br />
              Balken: 145x145/190 mm<br /><br />`
            result += "Deels uitgevoerd met pen-en-gat en zwaluwstaart verbindingen in de constructie. De steile dakhelling geeft dit model veel ruimte waar in veel gevallen een zolder een perfecte toevoeging kan zijn. Het dak bedekt met vlakke leipannen maakt dit buitenverblijf compleet."
            break;    
          case 'Monaco XXL':
            result = `Douglashout<br />
              Hoogte: 4987 mm<br />
              Kolommen: 190x190 mm<br />
              Balken: 190x190 mm<br /><br />`
            result += "De steile dakhelling geeft dit model veel ruimte, waardoor het perfect is voor het toevoegen van een eventuele zolder."
            break;
          default:
            break;
        }

        return result;
      }
    },
    created() {
      let regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      this.onMobile = regex.test(navigator.userAgent)
      // add click event on modal backdrop
      this.$nextTick(() => {
          document.getElementById('fullcover')
            .addEventListener('click', function (/*event*/) {
                // console.log('TEST',event)
            });
        })
    },
    methods: {
      specsBtnMouseOver() {
        this.hasGlowingInfoBtn = true;
      },
      specsBtnMouseLeave(){
        this.hasGlowingInfoBtn = false;
      },
      specsCloseBtnMouseOver() {
        this.hasGlowingCloseBtn = true;
      },
      specsCloseBtnMouseLeave(){
        this.hasGlowingCloseBtn = false;
      },
      enableAndHoldSpecs() {
        this.specsVisible = !this.specsVisible;
      }
    }
  }
</script>

<style lang="scss" scoped>

.card {
  display: flex;
  flex-grow: 1;
  // height: calc(100% - 20px);
  flex-direction: column;
  outline: 3px solid $c-border-1;
  border-radius: $radius-m;
  // padding: 10px;
  transition: outline-color 100ms ease;
  border: none;
  background-color: transparent;
  // scroll-margin: 150px;
  position: relative;

  &:hover {
    outline-color: $c-secondary;
    cursor: pointer;
  }

  &-selected {
    outline-color: $c-secondary;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    // height: 150px;

    // & img {
    //   min-height: 150px;
    //   max-height: 150px;
    //   width: 100%;
    //   object-fit: cover; //contain;
    // }
  }

  &-info {
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    position: absolute;
    left: 5px;
    margin-top: 25px;

    font-family: 'Oswald', sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: bold;

    background-color: white;
    border-radius: $radius-m;
    padding: 0px 3px;

    // & h3 {
    //   font-size: 1rem;
    //   text-align: left;
    //   margin: 0px;
    // }
    & span {
      font-family: 'Oswald', sans-serif;
      font-weight: normal;
    }    
  }

  &-label {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0px 3px; //5px 10px;
    margin: 5px;
    font-weight: bold;
    font-size: 0.7rem;
    border-radius: $radius-s;
    font-family: 'Source Sans 3', sans-serif;
    
    &-basic {
      background-color: $c-primary;
      color: white;
    }

    &-exclusive {
      background-color: $c-secondary;
      color: $c-primary; //$c-secondary-text;
    }
  }

  &-button-specs {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0px;
    padding-right: 10px;
    margin: 5px;
    border-radius: $radius-l;
    border-style: none;
    background-color: #d8d970; // babc16
    color: $c-primary; //$c-secondary-text;

    &-text {
      font-size: 0.7rem;
      padding-left: 7px;
      font-family: 'Source Sans 3', sans-serif;
    }

  }

  &-question-mark {
    // padding: 5px 10px;
    padding: 0px 5px;
    border-radius: $radius-l;
    font-weight: bold;
    font-size: 0.7rem;    
    background-color: $c-secondary;
  }

  // &-specs {
  //   position: absolute;
  //   top: 25px;
  //   bottom: 5px;
  //   right: 5px;
  //   left: 5px;
  //   padding: 2px 5px 2px 5px;
  //   border-radius: $radius-m;
  //   background-color: $c-secondary;    
  //   font-size: 11px;
  //   text-align: start;
  //   line-height: 98%; // added to fit text into the card space
  //   font-family: 'Source Sans 3', sans-serif;
  // }

  // &-specs {
  //   position: fixed;
  //   z-index: 999;
  //   top: 0%;
  //   left: 50%;
  //   width: 400px;
  //   margin-left: -200px;
  //   height: 90vh;
  //   background-color: white;
  //   border-radius: 8px;
  //   box-shadow: 0 4px 16px rgb(212, 212, 212);
  // }

  & img {
    min-height: 180px;//150px;
    max-height: 180px;//150px;
    width: 100%;
    object-fit: cover; //contain;
    
    border-radius: $radius-m;
  }  
}

// .modalgrid {
//   position: fixed;
//   top: 0%;
//   z-index: 995;
//   display: grid;
//   grid-template-columns: minmax(auto, 45vw) auto minmax(auto, 45vw);
//   max-height: calc(100% - 1px); // a trick to force a scroll area to appear despite fixed position
//   overflow-y: auto;
// }
#fullcover {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
.modalspecs {
  position: fixed;
  z-index: 999;
  // top: 10%;
  left: 50%;
  // width: 30rem;
  // margin-left: -15rem;
  // height: 80vh;
  background-color: white;
  border-radius: 8px;
}
.modalspecs-size {
    top: 3%;
    width: 36rem;
    margin-left: -18rem;
    height: 94vh;
 }
.modalspecs-size-mobile {
    top: 10%;
    width: 98%;
    margin-left: -49%;
    height: 80vh;
 }

.modaltopcontainer {
  display: flex; text-align: start; height: 8vh
}
.modaltopcontainer h3 {
  margin: 1.5vh 1.5vh 1.5vh 3vh;
}
// .greyheader {
//   color: $c-secondary;
//   background-color: $c-primary; 
//   border-radius: 5px; 
//   margin: 0px 10px 10px 10px; 
//   font-size: x-large;
//   text-align: center; 
// }

.imagescroller {
  // max-height: calc(85% - 1px); // a trick to force a scroll area to appear despite fixed position
  max-height: calc(98% - 8vh); // minus size of modaltopcontainer
  overflow-y: auto;
}
.modalimage {
  width: 100%;
  height: auto;
}

.modalclosebutton {
  position: relative;
  // margin-left: auto;
  margin: 1.5vh 1.5vh 1.5vh auto;
  height: 4vh;
  width: 4vh;
  background: lightgrey;
  border: none;
  border-radius: 3vh;
  // padding: 0px 10px 0px 10px;
  font-size: 3vh;
  pointer-events: all;
  cursor: pointer;
  // force the × to stay centered despite resizing
  display: flex;
  align-items: center;
  justify-content: center;
}

.absolutepos {
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%;
}
.glowinginfo {
  background-color: rgba(255, 255, 255, 0.3);
}
.glowingclose {
  background-color: rgba(255, 255, 15, 0.75);
  border-radius: 20px;
}

.button-wrapper {
  border: none;
  display: flex;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  min-height: 100%;
}

// vue fade-in with <Transition>
// .fade-anim-enter-active,
// .fade-anim-leave-active {
//   transition: opacity 0.5s;
// }
// .fade-anim-enter, .fade-anim-leave-to {
//   opacity: 0;
// }
.fade-enter-active,
.fade-enter {
    transition: opacity 250ms ease;
}
.fade-leave-active {
    transition: opacity 0.5s ease;
}
.fade-leave-to, .fade-enter-from {
    opacity: 0;
}

.glow-enter-active,
.glow-enter {
    transition: opacity 0.5s ease;
}
.glow-leave-active {
    transition: opacity 0.5s ease;
}
.glow-leave-to, .fade-enter-from {
    opacity: 0;
}

.closebtn-enter-active,
.closebtn-enter {
    transition: opacity 0.5s ease;
}
.closebtn-leave-active {
    transition: opacity 0.5s ease;
}
.closebtn-leave-to, .fade-enter-from {
    opacity: 0;
}

</style>