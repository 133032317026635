<template>
  <div class="login">
    <div class="login-card">
      <form @submit.prevent="createAccount()" class="default-form">
        
        <h1 class="login-card-title">Login</h1>

        <div class="default-form-field">
          <label for="email" class="default-form-field-label">Email</label>
          <input type="email" id="email" name="email" class="default-form-field-input" v-model.trim="account.email">
        </div>

        <div class="default-form-field">
          <label for="password" class="default-form-field-label">Wachtwoord</label>
          <input type="password" id="password" name="password" class="default-form-field-input" v-model.trim="account.password">
        </div>

        <div class="default-form-button">
          <LoadingButton type="submit" :loading="loading" :disabled="!formValid">login</LoadingButton>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'

  export default {
    name: "Login",

    components: {
      LoadingButton
    },

    data () {
      return {
        account: {
          email: "",
          password: "",
        },
        loading: false,
        formValid: false,
      }
    },

    methods: {
      validate () {
        if (!this.account.email) {
          this.formValid = false
        } else if (!this.account.password) {
          this.formValid = false
        } else {
          this.formValid = true
        }
      },

      async createAccount () {
        this.loading = true

        try {

          await this.$store.dispatch('login', this.account)

        } catch (err) {
          this.loading = false
        }

        this.loading = false
      },
    },

    watch: {
      account: {
        deep: true,
        immediate: false,
        handler () {
          this.validate()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

.default-form-field-label {
  min-width: 120px;
}

.default-form-field-input {
  min-width: 250px;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &-card {
    display: flex;
    padding: 25px;
    margin: 15px;
    border-radius: $radius-m;
    box-shadow: $shadow-1;

    &-title {
      font-weight: 600;
      margin: 0px;
      padding-bottom: 15px;
    }
  }
}
</style>