<template>
  <div class="InvalidLichtstraten">
    <h1 v-if="!isMapOn">
      Ga naar de <span class="redirect" @click="toggleMapMode()">plattegrond</span> om de exacte locatie van een lichtstraat in te stellen.
    </h1>
    <h1 v-else>
      Klik op een maat om deze aan te passen.
    </h1>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "lichtstratenHelper-menu",

    methods: {
      toggleMapMode() {
        this.$store.dispatch('enableMapMode', !this.isMapOn)
      },
    },

    computed: {
      ...mapGetters({
        isMapOn: 'getIsMapActive',
      }),
    },
  }
    
</script>

<style lang="scss" scoped>
.InvalidLichtstraten {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  background-color: $c-primary;
  border-radius: 20px;
  width: 500px;

  h1 {
    color: $c-primary-text;
    font-size: 1em;
    font-weight: 600;
    padding: 10px 30px;
  }
}
.redirect {
  color: $c-secondary;
  cursor: pointer;
  text-decoration: underline;
}
</style>