<template>
    <div class="categoryLabel">
      {{ name }}
    </div>
</template>

<script>
export default {
  name: "CategoryLabel",

  props: {
    name: String
  },
}
</script>

<style lang="scss" scoped>
.categoryLabel {
  padding: 20px;
  border-radius: $radius-m;
  font-size: 40px;
  margin: 20px 0px;
  background-color: $c-background-1;
  text-align: left;
  color: $c-primary;
  // font-weight: bold;
  text-align: center
}

.categoryLabel:first-child {
  margin-top: 0px;
}
</style>