<script>
import { mapGetters } from "vuex";
import DakGootButton from '@/components/inputs/buttons/DakGootButton.vue'
export default {
    components: {
      DakGootButton,
    },

    computed: {
      ...mapGetters({
        config: 'getConfig', 
        dakgoot: 'getDakgoot',
      }),
    },

    methods: {
      changeDakgoot (data) {
        this.$store.dispatch('updateDakgoot', data)
      }
    }
  }
</script>
<template>
  <DakGootButton
    v-if="config.availableOptions.dakgoot"
    :data="{name: 'Voorzijde', enabled: dakgoot.front.enabled, left: dakgoot.front.left, right: dakgoot.front.right}"
    @setDakgoot="changeDakgoot"
  />

  <DakGootButton
    v-if="config.availableOptions.dakgoot"
    :data="{name: 'Achterzijde', enabled: dakgoot.back.enabled, left: dakgoot.back.left, right: dakgoot.back.right}"
    @setDakgoot="changeDakgoot"
  />
</template>