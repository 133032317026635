<template>
  <div class="selector">
    <div class="selector-section">
      <h3>{{ categoryTitle }}</h3>
      
      <div class="selector-section-category">
        <button 
          v-for="(category, index) in categorys" 
          :key="index" 
          class="selector-section-category-item" 
          :class="!status ? '' : selectedCategory.type === category.type ? 'selector-section-category-item-active' : ''"
          :disabled="!status"
          @click="$emit('changeCategory', category)"
        >
          <img :src="host + category.typeImage" alt="">

          <h4>{{ category.type }}</h4>
        </button>
      </div>
    </div>

    <div class="flexRow">
      <div 
        v-if="!(selectedCategory.id == '6622508ad0774fabfa45b6c6')" 
        class="selector-section"
        :style="showThirdSection ? 'width: 45%;' : ''"
      >
        <h3>{{ optionTitle }}</h3>
        
        <div class="selector-section-color">
          <button 
            v-for="(option, index) in selectedCategory.colors" 
            :key="index"
            class="selector-section-color-item"
            :class="!status ? '' : option.name === selectedColor.name ? 'selector-section-color-item-active' : ''"
            :disabled="!status"
            @click="$emit('changeColor', option)"
          >
            <img :src="host + option.previewURL" alt="">
  
            <div class="selector-section-color-item-hover">
              <span class="selector-section-color-item-hover-name">{{ option.name }}</span>
            </div>
          </button>
        </div>
      </div>
      <div 
        v-if="!(selectedCategory.id == '6622508ad0774fabfa45b6c6') && showThirdSection" 
        class="selector-section"
      >
        <h3>{{ thirdSectionTitle }}</h3>
        
        <div class="selector-section-color">
          <!-- {{ extraOptionIsTurnedOn }} -->
          <button 
            class="extra-option-toggle"
            @click="$emit('changeExtraOption', !extraOptionIsTurnedOn)"
          >
            <img 
              :src="`${previewsServer}/textures/ui/${extraOptionIsTurnedOn ? extraOptionOnGraphic : extraOptionOffGraphic}`" 
              class="togglegraphic"
              height="50"
              width="240" 
            >
          </button>
        </div>
        <!-- <div class="selector-section-color">
          <button 
            v-show="extraOptionIsTurnedOn"
            class="extra-option-toggle"
            @click="$emit('changeExtraOption', false)"
          >
            <img 
              :src="`${previewsServer}/textures/ui/${extraOptionOnGraphic}`" 
              height="50"
              width="150" 
            >
          </button>
          <button 
            v-show="!extraOptionIsTurnedOn"
            class="extra-option-toggle"
            @click="$emit('changeExtraOption', true)"
          >
            <img 
              :src="`${previewsServer}/textures/ui/${extraOptionOffGraphic}`" 
              height="50"
              width="150" 
            >
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      categoryTitle: String,
      optionTitle: String,
      categorys: Array,
      status: Boolean,
      selectedCategory: Object,
      selectedColor: Object,
      // de bedoeling is dat "extraOption" hergebruikt kan worden voor verschillende onderwerpen.
      // momenteel is het in gebruik voor wand isolatie aan/uit knoppen in de UI.
      showThirdSection: Boolean,
      thirdSectionTitle: String,
      extraOptionIsTurnedOn: Boolean,
      extraOptionOffGraphic: String,
      extraOptionOnGraphic: String,
    },
    emits: ['changeCategory', 'changeColor', 'changeExtraOption'],
    data () {
      return {
        host: import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION,
        previewsServer: import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER,
      }
    },
    // created () {
    //   console.log('this.showThirdSection',this.showThirdSection)
    // }
  }
</script>

<style lang="scss" scoped>

.flexRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.extra-option-toggle {
  // border-style: none; 
  border: 3px solid white;
  border-radius: 2rem;
  background-color: transparent;//black;
  position: relative;
}

.togglegraphic {
  position: absolute;
  inset: 0px;
  margin-top: -0.4rem;
  border-style: solid;
  border: 3px solid white;
  border-radius:2rem;
}
.togglegraphic:hover {
  cursor: pointer;
  border-style: solid;
  border-color: $c-secondary; 
}

.selector {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;

  &-section {
    display: flex;
    flex-direction: column;
    max-width: 100%;

    & h3 {
      text-align: left;
      margin: 0px;
      padding: 10px 0px;
    }

    &-category {
      display: flex;
      gap: 10px;
      max-width: 100%;

      &-item {
        width: 22%;
        display: inline-block;
        flex-direction: column;
        align-items: center;
        background-color: transparent;
        border: 3px solid $c-border-1;
        border-radius: $radius-m;
        margin: 0px;
        padding: 5px;
        transition: background-color 100ms ease, border-color 100ms ease;

        &-active {
          border: 3px solid $c-secondary;
          //background-color: $c-background-1;
        }

        &:hover:enabled {
          border: 3px solid $c-secondary;
          //background-color: $c-background-1;
          cursor: pointer;
        }

        &:disabled {
          cursor: not-allowed;
        }

        & h4 {
          font-size: 0.8rem;
          margin: 0px;
        }

        & img {
          width: 100%;
          object-fit: contain;
        }
      }
    }

    &-color {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-content: flex-start;

      &-item {
        width: 50px;
        max-height: 50px;
        border: none;
        background-color: transparent;
        display: inline-block;
        padding: 0px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        border-radius: $radius-s;
        outline: 4px solid transparent;
        outline-offset: -4px;
        transition: outline-color 100ms ease;

        &-active {
          outline: 4px solid $c-secondary;
        }

        & img {
          min-width: 100px;
          min-height: 100px;
          max-width: 100px;
          max-height: 100px;
          object-fit: cover;
          border-radius: $radius-s;
          background-color: $c-background-1;
        }

        &:hover:enabled > .selector-section-color-item-hover {
          transform: translateY(-30px);
        }

        &:disabled {
          cursor: not-allowed;
        }

        &-hover {
          position: absolute;
          bottom: -30px;
          left: 0px;
          background-color: $c-transparent-1;
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: $radius-s;
          transition: transform 175ms ease;

          &-name {
            font-weight: 500;
            font-size: 0.5rem;
            padding: 5px;
            color: white;
            text-align: center;
            max-width: 100%;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

</style>