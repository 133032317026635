<template>
  <button class="button" :class="loading ? 'button-loading' : ''" :disabled="disabled">
    <div class="button-loading" v-if="loading">
      <Ellipsis color="#D4F028" />
    </div>
    <slot v-else></slot>
  </button>
</template>

<script>
  import Ellipsis from '@/components/loading/Ellipsis.vue'

  export default {
    name: "LoadingButton",

    components: {
      Ellipsis
    },  

    props: {
      loading: {
        typeof: Boolean,
        default: false,
      },
      disabled: {
        typeof: Boolean,
        default: false,
      },
    }
  }
</script>

<style lang="scss" scoped>

.button {
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: $radius-s;
  background-color: $c-secondary;
  color: $c-primary;
  border: none;
  padding: 8px 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 100ms ease, color 100ms ease;

  &:disabled {
    background-color: $c-background-1;
    cursor: default;
  }

  &-loading {
    background-color: $c-primary;
  }

}

</style>