<script>
import { mapGetters } from "vuex";
// import ComponentCardSimple from '@/components/inputs/ComponentCardSimple.vue'
import OptionCard from '@/components/inputs/OptionCard.vue'
export default {
    components: {
      // ComponentCardSimple
      OptionCard
    },

    data () {
      return {
        filteredUitlopen: null,
        selectedUitloop: null,
      }
    },

    computed: {
      ...mapGetters({
        config: 'getConfig', 
        uitloopOptions: 'getUitloopOptions',
        uitloopDefault: 'getUitloopDefault',
        // storedUitloop: 'getSelectedUitloop',
      }),
    },

    mounted () {
      this.getUitloopOptions();
    },

    methods: {
      async getUitloopOptions() {
        if (this.uitloopOptions.length === 0) {
          await this.$store.dispatch('getUitloopOptions')
        }
        this.filteredUitlopen = this.filterUitlopen(this.uitloopOptions);

        // set selected uitloop
        // if (this.storedUitloop && this.filteredUitlopen.includes(this.storedUitloop)) {
        //   this.selectedUitloop = this.storedUitloop;
        if (this.config.uitloop) {
          // console.log('config.uitloop',this.config.uitloop);
          this.selectedUitloop = this.filteredUitlopen.find((o) => o.code === this.config.uitloop.code);
        } else {
          this.selectedUitloop = this.uitloopDefault;
        }

        if (!this.selectedUitloop) console.error('No uitloop selected in getUitloopOptions.');
      },
      filterUitlopen(options) {
        const currentModel = this.config.model;
        // console.log('currentModel',currentModel); // bijvoorbeeld 'Barcelona'
        const filteredConfigOptions = options.filter((o) => o.keyWords.some((k) => k === currentModel));
        // console.log('filteredConfigOptions',filteredConfigOptions);
        return filteredConfigOptions;
      },
      changeUitloop(component, cardIndex) {
        // console.log('component',component);
        this.$store.dispatch('updateUitloop', {input: component, mustCheckForCorrectDefault: false});

        // set to data
        this.selectedUitloop = this.filteredUitlopen[cardIndex];
      },
    }
  }
</script>
<template>
  <!-- {{ config.uitloop }}
  {{ selectedUitloop }} -->
  <div class="uitloop-page">
    <!-- <ComponentCardSimple
      v-for="(component, index) in filteredUitlopen" 
      :key="index" 
      ref="component"
      :data="component" 
      :datatype="'uitlopen'"
      :isselected="selectedUitloop"
      @select="changeUitloop(component, index)"
    /> -->
    <OptionCard 
      v-for="(component, index) in filteredUitlopen" 
      :key="index"
      :data="component" 
      :selected="selectedUitloop?.code === component.code"
      :has-automargin="false"
      @select="changeUitloop(component, index)"
    />
  </div>
</template>
<style lang="scss" scoped>
#mainsection {
  margin: 1rem;
}
.uitloop-page {
  display: grid;
  padding: 25px 0px;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
}
</style>