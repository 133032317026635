<template>
  <!-- { component: data, colorIndex: selectedColor, optionIndex: selectedOption, variantIndex: selectedVariant })"> -->
  <button class="button-wrapper" @click="$emit('select')">
    <div class="card" :class="selected ? 'card-selected' : '' ">
      <div class="card-header">
        <!-- <img :src="`${previewsServer + models}previews/${data.code +  (data.options.length > 0 ? data.options[selectedOption].subCode : '')}.png`" alt="" @error="setBackupImage($event, data)"> -->
        <img :src="`${previewsServer + models}previews/${getCurrentImage}.png`" 
          alt="" @error="setBackupImage($event, data)">

        <!-- GLASS TYPES -->
        <!-- title = hover text over button -->
        <div class="card-header-glastypes" v-if="data.glasTypes && data.glasTypes.length > 0">
          <button 
            class="card-header-glastypes-button" 
            v-for="(glasType, index) in data.glasTypes" 
            :key="index" 
            :title="`${glasType.name} (${glasType.code})`"
            :class="cselected ? sglasType.code === glasType.code ? 'card-header-glastypes-button-active' : '' : index === selectedGlasType ? 'card-header-glastypes-button-active' : ''"
            @click="selectedGlasType = index; 
              selectedVariant = 0; 
              selectedColor = 0; 
              selectedOption = 0; 
              lastCodeSelection = glasType.code;
              $emit('select')"
            >
            <!-- {{ JSON.stringify(lastCodeSelection) }} -->
            <!-- ",{ 
              component: data, 
              glasTypeIndex: index, 
              variantIndex: 0, 
              colorIndex: 0, 
              optionIndex: 0 
              })" -->
          {{ glasType.acronym }}
          </button>
        </div>

        <!-- COLORS -->
        <!-- <div class="card-header-colors" v-if="data.colors.length > 0"> -->
          <!-- v-for="(color, index) in data.colors" -->
        <div class="card-header-colors" v-if="computeCurrentColors">
          <button 
            class="card-header-colors-button" 
            v-for="(color, index) in computeCurrentColors" 
            :key="index" 
            :title="`${color.name} (${color.code})`"
            :style="`background-color: ${color.hexCode};`"
            :class="cselected ? scolor.code === color.code ? 'card-header-colors-button-active' : '' : index === selectedColor ? 'card-header-colors-button-active' : ''"
            @click="selectedColor = index; 
            lastCodeSelection = color.code;
            $emit('select')"
          >
          <!-- { 
              component: data, 
              glasTypeIndex: selectedGlasType, 
              variantIndex: selectedVariant, 
              colorIndex: index, 
              optionIndex: selectedOption 
              })" -->
          </button>
        </div>
      </div>

      <!-- CARD INFO -->
      <!-- title = hover text over button -->
      <div class="card-info">
        <!-- <h3>{{ selectedColor > 0 ? data.colors[selectedColor].componentName : data.shortName }}</h3> -->
        <h3>{{ selectedColor > 0 ? 
          data.colors[selectedColor].componentName : selectedVariant > 0 ? 
          data.variants[selectedVariant].componentName : selectedGlasType > 0 ?
          data.glasTypes[selectedGlasType].componentName : data.shortName }}</h3>
        <div class="card-info-size">
          <mdicon class="card-info-size-icon" name="ruler-square" size="25" />
          <span class="card-info-size-text" >{{ data.size }} mm</span>
        </div>
      </div>

      <!-- VARIANTS -->
      <!-- <div class="card-options" v-if="data.variants && data.variants.length > 0"> -->
      <div class="card-options" v-if="computeCurrentVariants">        
        <button 
          v-for="(variant, index) in computeCurrentVariants" 
          :key="index"
          :class="cselected ? svariant.code === variant.code ? 'card-options-active' : '' : index === selectedVariant ? 'card-options-active' : ''"
          @click="selectedVariant = index; 
          selectedColor = 0; 
          selectedOption = 0; 
          lastCodeSelection = variant.code;
          $emit('select')"
          >
          <!-- ",{ 
            component: data, 
            glasTypeIndex: selectedGlasType,
            variantIndex: index, 
            colorIndex: 0, 
            optionIndex: 0 });"
        > -->
          {{ variant.name }}
        </button>
      </div>

      <!-- OPTIONS -->
      <!-- title = hover text over button -->
      <div class="card-options" v-if="data.options.length > 0">
        <button 
          v-for="(option, index) in data.options" 
          :key="index"
          :class="cselected ? soption.subCode === option.subCode ? 'card-options-active' : '' : index === selectedOption ? 'card-options-active' : ''"    
          @click="selectedOption = index; $emit('select')"
          >
          <!-- ,{ component: data, colorIndex: selectedColor, optionIndex: index })"
        > -->
          {{ option.name }}
        </button>
      </div>
    </div>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

  export default {
    name: "ComponentCard",

    props: {
      data: Object,
      selected: Boolean,
      cselected: Boolean,
      wSelected: Object, // selected wall data
      scolor: Object,
      soption: null,
      svariant: Object, // feature 1394
      sglasType: Object,
    },

    data () {
      // console.log('component card data',this.data)
      return {
        models: import.meta.env.VITE_VUE_APP_MODELS_LOCATION,
        previewsServer: import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER,
        selectedColor: 0,
        selectedOption: 0,
        selectedVariant: 0, // feature 1394
        selectedGlasType: 0, //
        lastCodeSelection: '' // for choosing .glb file
      }
    },

    computed: {
      ...mapGetters({
        glassAndVariantsData: 'getGlassAndVariantsData'
      }),
      computeDataForThisGlassType() {
        let targetCode = '';
        if (this.selectedGlasType) {
          targetCode = this.data.glasTypes[this.selectedGlasType].code
          console.log('test glassAndVariantsData',this.glassAndVariantsData)
          return this.glassAndVariantsData[this.curCategory()].glassVariants
            .find(item => (item.code === targetCode && item.variants));
        } else return null;
      },
      computeCurrentVariants() {
        // Find "related" data for glass types and their variants when selecting glass.
        if (this.selectedGlasType) {
          const curData = this.computeDataForThisGlassType;
          if (curData) { 
            return curData.variants; 
          } else return [];
        // Otherwise, show data.variants (if any)
        } else if (this.data.variants && this.data.variants.length > 0) {
          return this.data.variants;
        } else return [];
      },
      computeDataForThisVariant() {
        let targetCode = '';
        if (this.selectedVariant) {
          targetCode = this.data.variants[this.selectedVariant].code
          return this.glassAndVariantsData[this.curCategory()].variantColors
            .find(item => (item.code === targetCode && item.colors));
        } else return null;
      },
      computeCurrentColors() {
        // Find "related" data for variants and their colors when selecting variant.
        if (this.selectedVariant) {
          const curData = this.computeDataForThisVariant;
          if (curData) { return curData.colors; } else return [];
        // Otherwise, show data.colors (if any)
        } else if (this.data.colors && this.data.colors.length > 0) {
          return this.data.colors;
        } else return [];
      },      
      getCurrentImage() {
        let imgCode = this.data.code;
        // if(this.data.size === "845x2300") {
        //   console.log("getCurrentImage", this.selectedVariant, this.computeCurrentVariants.findIndex(v => v.code === this.svariant?.code))
        // }
        // React to glasstype
        if (this.selectedGlasType) {
          imgCode = this.data.glasTypes[this.selectedGlasType].code
        }
        // React to variant
        if (this.selectedVariant) {
        // if (this.selectedVariant !== null && this.selectedVariant !== undefined && this.data.variants && this.data.variants[this.selectedVariant]) {
          imgCode = this.data.variants[this.selectedVariant].code
        }
        // if (this.computeCurrentVariants.findIndex(v => v.code === this.svariant?.code) > -1) {
        //   imgCode = this.data.variants[this.computeCurrentVariants.findIndex(v => v.code === this.svariant?.code)].code
        // }
        // if(this.selected) {
        //   if (this.computeCurrentVariants.findIndex(v => v.code === this.svariant?.code) > -1) {
        //     imgCode = this.data.variants[this.computeCurrentVariants.findIndex(v => v.code === this.svariant?.code)].code
        //   }
        // } else {
        //   if (this.selectedVariant) {
        //     imgCode = this.data.variants[this.selectedVariant].code
        //   }
        // }
        // React to subcode
        if (this.data.options.length > 0) imgCode += this.data.options[this.selectedOption].subCode
        // console.log('imgCode',imgCode)
        return imgCode;
      },      
    },

    watch: {
      wSelected: "setCurrentComponentSettings",
    },

    mounted() {
      this.setCurrentComponentSettings()
    },

    methods: {
      setBackupImage(e, data) {
        e.target.src = `https://fakeimg.pl/400x400/?text=${data.code +  (data.options.length > 0 ? data.options[this.selectedOption].subCode : '')}&font=bebas`
      },
      curCategory() {
        // Find current category (for use in this.glassAndVariantsData.???.glassVariants etc)
        let result;
        switch(this.data.category) {
          case 'Deur': result = 'basicDoors'; break;
          case 'Raam': result = 'basicWindows'; break;          
          case 'Steellook Deur': result = 'steellookDoors'; break;
          case 'Steellook Raam': result = 'steellookWindows'; break;
          case 'Steellook Raam BW': result = 'steellookWindowsBW'; break;
          case 'Steellook': result = 'steellook'; break;
          case 'Steellook Wand BW': result = 'steellookWandBW'; break;
          case 'Schuifwand': result = 'slidingwall'; break;
        }
        return result;
      },

      setCurrentComponentSettings() {
        const colorIndex = this.computeCurrentColors.findIndex(item => item.code === this.scolor?.code);
        if(colorIndex > -1) this.selectedColor = colorIndex;
        const optionIndex = this.data.options ? this.data.options.findIndex(item => item.subCode === this.soption?.subCode) : -1;
        if(optionIndex > -1) this.selectedOption = optionIndex;
        const variantIndex = this.computeCurrentVariants.findIndex(item => item.code === this.svariant?.code);
        if(variantIndex > -1) this.selectedVariant = variantIndex;
        const glasTypeIndex = this.data.glasTypes ? this.data.glasTypes.findIndex(item => item.code === this.sglasType?.code) : -1;
        if(glasTypeIndex > -1) this.selectedGlasType = glasTypeIndex;
      }
    },
  }
</script>

<style lang="scss" scoped>

.card {
  display: flex;
  flex-grow: 1;
  height: calc(100% - 20px);
  flex-direction: column;
  outline: 3px solid $c-border-1;
  border-radius: $radius-m;
  padding: 10px;
  transition: outline-color 100ms ease;
  border: none;
  background-color: transparent;
  scroll-margin: 150px;

  &:hover {
    outline-color: $c-secondary;
    cursor: pointer;
  }

  &-selected {
    outline-color: $c-secondary;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    height: 150px;
    max-width: 100%;

    & img {
      min-height: 150px;
      max-height: 150px;
      width: calc(100% - 24px);
      object-fit: contain;
    }

    &-colors {
      display: flex;
      flex-direction: column;
      gap: 6px;

      &-button {
        height: 24px;
        width: 24px;
        outline: 3px solid $c-border-1;
        outline-offset: -2px;
        border: none;
        border-radius: $radius-s;
        transition: outline-color 100ms ease;
        cursor: pointer;

        &:hover {
          outline-color: $c-secondary;
        }

        &-active {
          outline-color: $c-secondary;
        }
      }
    }

    &-glastypes {
      display: flex;
      flex-direction: column;
      gap: 6px;

      &-button {
        height: 24px;
        width: 32px;
        outline: 3px solid $c-border-1;
        outline-offset: -2px;
        border: none;
        border-radius: $radius-s;
        transition: outline-color 100ms ease;
        cursor: pointer;
        margin-right: 0.4em;

        &:hover {
          outline-color: $c-secondary;
        }

        &-active {
          outline-color: $c-secondary;
        }        
      }
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;

    & h3 {
      font-size: 1rem;
      text-align: left;
    }

    &-size {
      display: flex;
      align-items: center;
      gap: 15px;
      font-weight: 500;
    }
  }

  &-options {
    display: flex;
    gap: 15px;

    & button {
      flex-grow: 1;
      background-color: $c-background-1;
      border: none;
      border-radius: $radius-s;
      padding: 5px;
      font-weight: 600;
      font-size: 0.9rem;
      color: $c-primary;
      transition: color 100ms ease, background-color 100ms ease;
      cursor: pointer;
    }

    &-active {
      background-color: $c-primary !important;
      // color: $c-secondary  !important;
      color: $c-secondary-bright  !important;
    }
  }
}

.button-wrapper {
  border: none;
  display: flex;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  min-height: 100%;
}

</style>