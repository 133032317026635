<template>

<div class="background" ref="background">
  <h1 class="title">Even geduld A.U.B.</h1>
  <h2>Uw offerte wordt gegenereerd.</h2>

  <div class="loadingBarContainer">
    <div class="loadingBarInfill" :style="{width: `${(100)*(imageArray.length/10)}%`, transition: `width ${Math.ceil(averageTime)+2}s linear`}"></div>
  </div>
</div>

</template>

<script>
  export default {
    name: "InvoiceFinishing",

    data() {
      return {
        timeCreated: null,
        averageTime: 1
      }
    },

    props: {
      imageArray: Array
    },

    watch: {
      imageArray(newData) {
        this.averageTime = ((Date.now() - this.timeCreated)/newData.length)/1000*2
        console.log("this.averageTime", this.averageTime)
      }
    },

    created() {
      this.timeCreated = Date.now()
    },

    mounted() {
      setTimeout(() => {
        this.$refs.background.style.left = "0px"
      }, 10);
    }
  }
</script>

<style lang="scss" scoped>
.background {
  left: 100vw;
  transition: left 1s ease-in-out;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  background-color: #ffffff;
}

.title {
  margin-top: 30vh;
  @media (max-width: 999px) {
    margin-top: 35vh;
  }
}

.loadingBarContainer {
  position: relative;
  background-color: $c-primary;
  width: 60vw;
  height: 30px;
  margin: 0 auto;
}

.loadingBarInfill {
  position: relative;
  background-color: $c-secondary;
  height: 100%;
}
</style>