<template>
<div class="edit">
    <h1>Model categorieën</h1>

    <form class="default-form edit-form" @submit.prevent="">

      <div class="default-form-field">

        <div class="default-form-field-types">
          <div class="default-form-field-types-header default-form-field-types-edit-row">
            <span>Naam</span>
            <span></span>
          </div>

          <div v-for="(type, i) in types" :key="i" class="default-form-field-types-edit-row">
            <input type="text" class="default-form-field-input" :id="'input' + i" v-model.trim="type.name" @keydown.enter="add(i + 1)">
            <button class="default-form-field-button" @mousedown="remove(i)" >
              <mdicon name="minus" class="default-form-field-button-icon" size="25" />
            </button>
          </div>

          <button class="default-form-field-button" @click="add(types.length)">
            <mdicon name="plus" class="default-form-field-button-icon" size="25" />
          </button>
        </div>
      </div>

      <div class="default-form-button">
        <LoadingButton @click="update()" :loading="loading" :disabled="!formValid">Update</LoadingButton>
      </div>

    </form>
  </div>
</template>

<script>
  import isEqual from 'lodash.isequal'
  import { mapGetters } from 'vuex'
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'

  export default {
    name: "ModelTypes",

    components: {
      LoadingButton
    },

    data () {
      return {
        types: [],
        formValid: false,
        loading: false,
      }
    },

    methods: {
      async get () {
        await this.$store.dispatch('getModelsInfo')
        this.types = JSON.parse(JSON.stringify(this.data))
      },

      async update () {
        this.loading = true

        let remove = []
        let update = []
        let insert = []

        for (let i = 0; i < this.data.length; i++) {

          const keepId = this.types.find((t) => t.id === this.data[i].id)

          if (!keepId) {
            remove.push(this.data[i])
          } else {

            const nameChanged = this.types.find((t) => t.id === this.data[i].id && t.name !== this.data[i].name)

            if (nameChanged) {
              update.push(nameChanged)
            }
          }
        }

        for (let i = 0; i < this.types.length; i++) {
          if (!this.types[i].id) {
            insert.push(this.types[i])
          }
        }

        try {
          await this.$store.dispatch('updateModelCategories', {
            remove: remove,
            update: update,
            insert: insert
          })
        } catch (err) {
          console.log(err)
          this.loading = false
        }

        this.loading = false
        this.types = JSON.parse(JSON.stringify(this.data))
      },
      
      add (i) {

        if (i === this.types.length) {
          this.types.push({
            name: "",
            id: null,
          })
        }

        this.$nextTick(() => {
          document.getElementById('input' + (i)).focus()
        })
      },

      remove (i) {
        this.types.splice(i, 1)
      }
    },

    computed: {
      ...mapGetters({
        data: "getModelCategories"
      }),
    },

    created () {
      this.get()
    },

    watch: {
      types: {
        deep: true,
        immediate: false,
        handler () {
          if (isEqual(this.types, this.data)) {
            this.formValid = false
          } else {
            this.formValid = true
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.edit {
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  min-width: min(100%, 800px);

  &-form {
    padding: 25px 0px;
    gap: 50px;
    min-width: min(100%, 800px);
  }
}

</style>