<template>
  <div class="color">
    <div class="color-preview">
      <span>Geselecteerd:</span>
      
      <h3>{{ selected.name }}</h3>

      <img v-if="selected.url && !selected.previewURL" :src="host + selected.url" alt="">
      <img v-if="selected.previewURL" :src="host + selected.previewURL" alt="">
      <div v-if="selected.hexCode" class="colorBlock" :style="{backgroundColor: selected.hexCode}"></div>
    </div>

    <div class="color-options">
      <button class="color-options-item" v-for="(option, index) in options" :key="index" @click="changeColor(option)">
        <img v-if="option.url && !option.previewURL" :src="host + option.url" alt="">
        <img v-if="option.previewURL" :src="host + option.previewURL" alt="">
        <div v-if="option.hexCode" class="colorBlock" :style="{backgroundColor: option.hexCode}"></div>
        <div class="color-options-item-hover">
          <span class="color-options-item-hover-name">{{ option.name }}</span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "color-selector-preview",

    props: {
      options: Array,
      selected: Object
    },

    data () {
      return {
        host: import.meta.env.VITE_VUE_APP_TEXTURE_LOCATION,
      }
    },

    methods: {
      changeColor (color) {
        this.$emit('change', color)
      }
    }
  }
</script>

<style lang="scss" scoped>

.color {
  display: flex;
  gap: 25px;

  @media (max-width: 550px) {
    flex-wrap: wrap;
  }

  &-preview {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    max-width: 150px;

    & img {
      min-height: 150px;
      min-width: 150px;
      max-height: 150px;
      max-width: 150px;
      object-fit: cover;
      border-radius: $radius-m;
      background-color: $c-background-1;
    }

    .colorBlock {
      min-height: 150px;
      min-width: 150px;
      max-height: 150px;
      max-width: 150px;
      object-fit: cover;
      border-radius: $radius-m;
    }

    & span {
      padding-bottom: 5px;
      font-weight: 500;
      color: $c-sub-text;
      text-align: left;
      width: 100%;
    }

    & h3 {
      margin: 0px;
      text-align: left;
      width: 100%;
      padding-bottom: 5px;
    }
  }

  &-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-content: flex-start;

    &-item {
      max-height: 100px;
      border: none;
      background-color: transparent;
      display: flex;
      padding: 0px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      & img {
        min-width: 100px;
        min-height: 100px;
        max-width: 100px;
        max-height: 100px;
        object-fit: cover;
        border-radius: $radius-s;
        background-color: $c-background-1;
      }

      .colorBlock {
        min-width: 100px;
        min-height: 100px;
        max-width: 100px;
        max-height: 100px;
        object-fit: cover;
        border-radius: $radius-s;
      }

      &:hover > .color-options-item-hover {
        transform: translateY(-30px);
      }

      &-hover {
        position: absolute;
        bottom: -30px;
        left: 0px;
        background-color: $c-transparent-1;
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $radius-s;
        transition: transform 175ms ease;

        &-name {
          font-weight: 500;
          padding: 5px;
          color: white;
          text-align: center;
          max-width: 100%;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

</style>