<template>
  <div>
    <h3>Vraag uw offerte aan</h3>

    <div class="divider divider-left">
      <div class="divider-block">
        <p class="divider-block-title">UW GEGEVENS</p>

        <label>aanhef</label>

        <label class="checkboxlabel" for="male">Dhr.</label>
        <input class="checkbox" type="radio" id="male" name="pronoun" value="male" v-model="inputData.pronoun">
        <label for="male" class="radioButton" :class="inputData.pronoun==='male' ? 'radioButton-active' : ''">
          <label for="male" class="radioButtonInner" :class="inputData.pronoun==='male' ? 'radioButtonInner-active' : ''" size="15" />
        </label>

        <div class="radioButtonContainer">
          <label class="checkboxlabel" for="female">Mevr.</label>
          <input class="checkbox" type="radio" id="female" name="pronoun" value="female" v-model="inputData.pronoun">
          <label for="female" class="radioButton" :class="inputData.pronoun==='female' ? 'radioButton-active' : ''">
            <label for="female" class="radioButtonInner" :class="inputData.pronoun==='female' ? 'radioButtonInner-active' : ''" size="15" />
          </label>
          <mdicon v-if="inputData.pronoun" class="validRadio" name="check" size="25"/>
          <span v-else class="invalidRadio">*</span>
        </div>
  <br>

  <div class="input-icon-container">
          <label for="name">Naam:</label>
          <div class="input-with-icon">
            <input type="text" id="name" name="name" class="default-form-field-input" v-model="inputData.name">
            <mdicon v-if="inputData.name && inputDataValidation.name" class="valid" name="check" size="25"/>
            <span v-else class="invalid">*</span>
          </div>
  </div>

  <div class="input-icon-container">
          <label for="surname">Achternaam:</label>
          <div class="input-with-icon">
            <input type="text" id="surname" name="surname" class="default-form-field-input" v-model="inputData.surname">
            <mdicon v-if="inputData.surname && inputDataValidation.surname" class="valid" name="check" size="25"/>
            <span v-else class="invalid">*</span>
          </div>
  </div>

  <div class="input-icon-container">
          <label for="company">Bedrijf:</label>
          <div class="input-with-icon">
            <input type="text" id="company" name="company" class="default-form-field-input" v-model="inputData.company">
            <mdicon v-if="inputData.company && inputDataValidation.company" class="valid" name="check" size="25"/>
            <!-- Removed the span for the star as it's not a required field -->
          </div>
  </div>

  <div class="input-icon-container">
          <label for="street">Straat + nr.:</label>
          <div class="input-with-icon">
            <input type="text" id="street" name="street" class="default-form-field-input" v-model="inputData.street">
            <mdicon v-if="inputData.street && inputDataValidation.street" class="valid" name="check" size="25"/>
            <span v-else class="invalid">*</span>
          </div>
  </div>

  <div class="input-icon-container">
          <label for="zip">Postcode:</label>
          <div class="input-with-icon">
            <input type="text" id="zip" name="zip" class="default-form-field-input" v-model="inputData.zip">
            <mdicon v-if="inputData.zip && inputDataValidation.zip" class="valid" name="check" size="25"/>
            <span v-else class="invalid">*</span>
          </div>
  </div>

  <div class="input-icon-container">
          <label for="city">Plaats:</label>
          <div class="input-with-icon">
            <input type="text" id="city" name="city" class="default-form-field-input" v-model="inputData.city">
            <mdicon v-if="inputData.city && inputDataValidation.city" class="valid" name="check" size="25"/>
            <span v-else class="invalid">*</span>
          </div>
  </div>

  <div class="input-icon-container">
          <label for="phone">Telefoon:</label>
          <div class="input-with-icon">
            <input type="tel" id="phone" name="phone" class="default-form-field-input" v-model="inputData.phone">
            <mdicon v-if="inputData.phone && inputDataValidation.phone" class="valid" name="check" size="25"/>
            <span v-else class="invalid">*</span>
          </div>
  </div>

  <div class="input-icon-container">
          <label for="email">E-mail:</label>
          <div class="input-with-icon">
            <input type="email" id="email" name="email" class="default-form-field-input" v-model="inputData.email">
            <mdicon v-if="inputData.email && inputDataValidation.email" class="valid" name="check" size="25"/>
            <span v-else class="invalid">*</span>
          </div>
  </div>
  </div>

  <div class="divider-block">
        <p class="divider-block-title">EXTRA INFORMATIE</p>

        <div class="question" v-if="uiSettings[0]" v-for="(question, i) in uiSettings[0].extraQuestions" :key="i">
          <label class="title" for="question">{{ question.text }}</label>
          <div class="input-with-icon">
            <select v-model="inputData.extraQuestions[question.id]" id="question" name="question" class="default-form-field-input">
              <option value="" disabled selected hidden>Kies een optie</option>
              <option :value="option.id" v-for="(option, i2) in question.options" :key="i2">{{ option.text }}</option>
            </select>
            <mdicon v-if="inputData.extraQuestions[question.id] && inputDataValidation.extraQuestions[question.id]" class="valid" name="check" size="25"/>
            <span v-else class="invalid">*</span>
          </div>
  </div>
  </div>

  <div class="divider-block">
        <p class="divider-block-title">OFFERTE AANVRAGEN</p>

        <label for="privacy" class="longInput">Akkoord <a href="https://www.toplawood.nl/privacybeleid/" target="_blank">Privacy Statement</a>:<span v-if="!inputData.privacy" class="invalid">*</span></label>
        <input class="checkbox default-form-field-input" type="checkbox" id="privacy" name="privacy" v-model="inputData.privacy" :checked="inputData.privacy">
        <label for="privacy" class="checkbox-label">
          <mdicon name="check-bold" class="checkbox-label-icon" :class="inputData.privacy ? 'checkbox-label-icon-active' : ''" size="15" />
        </label>

        <label for="conditions" class="longInput">Akkoord <a href="https://www.toplawood.nl/algemene-voorwaarden/" target="_blank">Algemene Voorwaarden</a>:</label>
        <span v-if="!inputData.conditions" class="invalid">*</span>
        <input class="checkbox default-form-field-input" type="checkbox" id="conditions" name="conditions" v-model="inputData.conditions" :checked="inputData.conditions">
        <label for="conditions" class="checkbox-label">
          <mdicon name="check-bold" class="checkbox-label-icon" :class="inputData.conditions ? 'checkbox-label-icon-active' : ''" size="15" />
        </label>

        <button class="getPriceButton" :disabled="!inputDataValid" v-if="loggedIn || (uiSettings[0] ? uiSettings[0].showPrice : false)" @click="getPrice()" :class="{ getPriceButtonClicked: buttonClicked }">
          <span v-if="!shouldShowPrice">PRIJS TONEN</span>
          <span v-else>UW PRIJS </span>
          <span v-if="loggedIn && shouldShowPrice">&#10004;</span></button>
        <button class="invoiceButton" :disabled="!(inputDataValid && (shouldShowPrice || (loggedIn!=null && !loggedIn)))" :class="loggedIn || (uiSettings[0] ? uiSettings[0].showPrice : false) ? '' : 'invoiceButton-fullWidth'" @click="getQuotation()">MAIL OFFERTE</button>
      
      </div>

      <div class="divider-block">
        <p class="divider-block-title">VRAGEN</p>
        <textarea class="default-form-field-input" v-model="inputData.questions"></textarea>

      </div>

    </div>


    <div class="divider">
      <div class="divider-block">
        <p class="divider-block-title">UW PRIJS</p>
        <label class="longInput">Vul uw gegevens in en ontvang uw offerte.</label>

        <table class="productTable">
        <tbody v-if="loggedIn || (uiSettings[0] ? uiSettings[0].showProductDetails : 0)">
          <tr v-for="(product, i) in productList" :key="i" :title="loggedIn && shouldShowPrice ? product.Code : ''">
            <td class="quantity">{{product.Quantity}}x</td>
            <td class="description">{{product.DescriptionShort}}</td>
            <td class="currency" v-if="loggedIn && shouldShowPrice">€</td>
            <td class="price productTable-price" v-if="loggedIn && shouldShowPrice">{{formatPrice(product.Price)}}</td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-if="productList[0]" :key="i" :title="loggedIn && shouldShowPrice ? product.Code : ''">
            <td class="description">{{productList[0].DescriptionShort}}</td>
          </tr>
          <tr v-if="uiSettings?.[0]?.extraDetails">
            <td colspan="4">
              <ul class="shortProductList">
                <li v-for="(extraDetail, i) in uiSettings[0].extraDetails || []" :key="i">
                  {{ extraDetail.text }}
                </li>
              </ul>
            </td>
          </tr>
        </tbody>

        <tbody>
          <tr><td colspan="4">&nbsp;</td></tr>
          <!-- <tr><td colspan="4">&nbsp;</td></tr> -->
          <tr class="total-excl-row" v-if="(loggedIn || (uiSettings[0] ? uiSettings[0].showPrice : false)) && shouldShowPrice">
            <td colspan="2" class="priceTitle priceBlock">TOTAAL EXCL. BTW</td>
            <td class="currency priceBlock">€</td>
            <td class="price priceBlock">{{ formatPrice(productListPriceEXCL) }}</td>
          </tr>
            <tr class="btw-row" v-if="(loggedIn || (uiSettings[0] ? uiSettings[0].showPrice : false)) && shouldShowPrice">
            <td colspan="2" class="priceTitle priceBlock">BTW</td>
            <td class="currency priceBlock">€</td>
            <td class="price priceBlock">{{ formatPrice(productListPriceBTW) }}</td>
          </tr>

          <tr class="total-incl-row" v-if="(loggedIn || (uiSettings[0] ? uiSettings[0].showPrice : false)) && shouldShowPrice">
            <td colspan="2" class="priceTitle priceBlock">TOTAAL INCL. BTW</td>
            <td class="currency priceBlock">€</td>
            <td class="price priceBlock">{{ formatPrice(productListPriceINCL) }}</td>
          </tr>
        </tbody>
      </table>


      </div>
  </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  //import $ from "jquery";
  export default {
    data () {
      return {
        inputData: {
          pronoun: '',
          name: '',
          surname: '',
          company: '',
          street: '',
          zip: '',
          city: '',
          phone: '',
          email: '',
          extraQuestions: {},
          privacy: false,
          conditions: false,
          questions: '',
        },
        // uiSettings: [], // Initialize as empty array
        inputDataValidation: {
          pronoun: false,
          name: false,
          surname: false,
          company: false,
          street: false,
          zip: false,
          city: false,
          phone: false,
          email: false,
          extraQuestions: {},
          privacy: false,
          conditions: false,
        },
        inputDataValid: false,
        wallArray: [],
        shouldShowPrice: false,
        hasRequestedQuotation: false,
        buttonClicked: false // add this line
      }
    },

    methods: {
      async getPrice() {
  console.log('Store state:', this.$store.state);
  console.log('Config state:', this.$store.state.config);
  
  const inputDataWithSettings = {
    ...this.inputData,
    uisettings: this.$store.state.cms.menuUISettings
  }
  
  console.log('Input data with settings:', inputDataWithSettings);
  
  await this.$store.dispatch('getProductListPrice', {
    token: this.userToken, 
    inputData: inputDataWithSettings
  })

  this.shouldShowPrice = true
}
,

      async getQuotation() {
        if(this.hasRequestedQuotation) return
        this.hasRequestedQuotation = true
        await this.$store.dispatch('setIsFinishingInvoice', true)
        this.$store.dispatch('updateSessionData', {collectionName: "configuratorSession", data: {quoteDataId: true}})
        setTimeout(async() => {
          await this.$store.dispatch('renderImages')
          await this.$store.dispatch('getQuotation', {token: this.userToken, inputData: this.inputData})
        }, 1100);
      },

      async getUISettings() {
        await this.$store.dispatch('getMenuUISettings')

        console.log(this.inputData)

        for(const question of this.uiSettings[0].extraQuestions) {
          this.inputData.extraQuestions[question.id] = ''
        }
      },

      formatPrice(price) {
        let x = Math.round(price * 100) / 100
        x = x.toString().replace('.', ',')
        x = x.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        if(!x.includes(',')) {
          x = x + ",00"
        // in case of a single decimal place, add a zero
        } else if(x.substring(x.indexOf(',')+1).length === 1) {
          x = x + "0"
        }
        return x
      },
      toggleButtonClicked() {
      this.buttonClicked = !this.buttonClicked;
      }
    },

    created() {
      this.$store.dispatch('getProductList', {token: this.userToken})
      this.inputData = this.getInputData


      // if((location.hostname == "localhost" || location.hostname == "tw-js-dev.mtest.nl" || location.hostname === 'tw-v01-js-dev.mtest.nl') && this.inputData.street == "") {

      //   this.inputData = {
      //     pronoun: "male",
      //     name: "Job",
      //     surname: "Griffioen",
      //     company: "Moogue",
      //     street: "Trasmolenlaan 5",
      //     zip: "3447GZ",
      //     city: "Woerden",
      //     phone: "0348 743 485",
      //     email: "productie@moogue.com",
      //     privacy: true,
      //     conditions: true,
      //     questions: ""
      //   }
      // }

      if((location.hostname == "localhostX" || 
          location.hostname == "tw-js-dev.mtest.nlX" || 
          location.hostname === 'tw-v01-js-dev.mtest.nlX') 
          && this.inputData.street == "") {
        this.inputData.pronoun = "male";
        this.inputData.name = "Job";
        this.inputData.surname = "Griffioen";
        this.inputData.company = "Moogue";
        this.inputData.street = "Trasmolenlaan 5";
        this.inputData.zip = "3447GZ";
        this.inputData.city = "Woerden";
        this.inputData.phone = "0348 743 485";
        this.inputData.email = "productie@moogue.com";
        this.inputData.privacy = true;
        this.inputData.conditions = true;
        // this.inputData.questions: ""
      }

      this.getUISettings()
      console.log(this)
    },

    unmounted() {
      // alert("unmounted")
      this.$store.commit('setInputData', this.inputData)
      this.$store.dispatch('dwLogout')
    },

    computed: {
      ...mapGetters({
        muren: 'getMuren',
        muurInstances: 'getMuurInstances',
        productList: 'getProductList',
        productListPriceEXCL: 'getProductListPriceEXCL',
        productListPriceBTW: 'getProductListPriceBTW',
        productListPriceINCL: 'getProductListPriceINCL',
        loggedIn: 'getTokenLoggedIn',
        dwSessionData: 'getDWSessionData',
        getInputData: 'getInputData',
        userToken: 'getUserToken',
        uiSettings: 'getMenuUISettings'
      })
    },

    watch: {
      inputData: {
        deep: true,
        immediate: true,
        handler () {
          this.inputDataValid = false
          const d = this.inputData
          const v = this.inputDataValidation

          v.pronoun = d.pronoun !== ''
          v.name = d.name.length > 0
          v.surname = d.surname.length > 0
          v.company = d.company.length > 0
          v.street = d.street.length > 1
          v.zip = d.zip.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i)
          v.city = d.city.length > 1
          v.phone = d.phone.toString().length > 9 && d.phone.toString().length < 20
          v.email = d.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)
          v.extraQuestions = {}
          let extraQuestionsValid = true
          for(const questionId in d.extraQuestions) {
            v.extraQuestions[questionId] = d.extraQuestions[questionId].length > 0 ? d.extraQuestions[questionId] : false
            if (!v.extraQuestions[questionId]) {
              extraQuestionsValid = false
            }
          }
          v.privacy = d.privacy
          v.conditions = d.conditions

          this.inputDataValid = v.pronoun && v.name && v.surname && v.street && v.zip && v.city && v.phone && v.email && extraQuestionsValid && v.privacy && v.conditions
        }
      }
    }

  }
</script>

<style lang="scss" scoped>
.divider-block {
.question {  
    .title {
      margin-bottom: 10px;  // Increase this value for more space
    }
  
  .invalid {
      right: 10px !important;
      top: 50% !important;
      transform: translateY(-60%) !important;
    }
    
    .valid {
      right: 5px !important;
      top: 50% !important;
      transform: translateY(-50%) !important;
    }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: none;

      &:hover {
        cursor: pointer;
        border-color: $c-secondary;
      }
      
      &:focus {
        border-color: $c-secondary;
        box-shadow: 0 0 5px rgba(var(--c-secondary-rgb), 0.3);
      }
    }
  }
}


.divider {
  width: 47.5%;
  @media (max-width: 999px) {
    width: 100%;
  }
  display: inline-block;
  vertical-align: top;

  &-left {
    margin-right: 3%;
    @media (max-width: 999px) {
      margin-right: 0px;
    }
  }

  &-block {
    border: 2px solid $c-border-1;
    border-radius: $radius-l;
    text-align: left;
    margin-bottom: 20px;
    position: relative;

    &-title {
      text-align: left;
      margin-left: 20px;
      font-weight: 500;
      font-size: 18px;
    }

    label {
      display: inline-block;
      width: calc(35% - 50px);
      margin-left: 20px;
      font-weight: 500;
      vertical-align: top;
      @media (max-width: 999px) {
        font-size: 14px;
        width: 30%;
      }
    }

    .checkboxlabel {
      cursor: pointer;
      width: auto;
      margin-left: 0px;
      vertical-align: top;
    }

    input {
      width: calc(65% - 30px);
      margin-bottom: 15px;
    }

    .valid {
      color: $c-secondary;
      position: absolute;
      right: 25px;
    }

    .invalid {
       color: red;
    }

    .input-icon-container {
  display: flex;
  // align-items: center; /* This ensures vertical center alignment of children */
  margin-bottom: 0px;
}

label {
  margin-right: 10px; /* Ensure there's space between the label and the input */
  // align-self: center; /* Center the label vertically within the flex container */
}

.input-with-icon {
  display: flex;
  align-items: center;
  position: relative;
  width: calc(65% + 00px);
  margin-bottom: 0px;
}

.input-with-icon input {
  width: 100%;
  padding-right: 30px; /* Room for the icon/star */
}

.valid {
  position: absolute;
  right: 5px; /* Position inside the input, to the right */
  top: 15px;
  transform: translateY(-50%); /* Center vertically */
}

.invalid {
  position: absolute;
  right: 10px; /* Position inside the input, to the right */
  // top: 50%;
  transform: translateY(-50%); /* Center vertically */
}

/* Adjust the vertical alignment of the input to match the label */
input, .valid, .invalid {
  align-self: center; /* This ensures that the input and icons are vertically centered */
}

/* Additional styles for .valid if needed */
.valid {
  color: $c-secondary; /* Assuming $c-secondary is defined elsewhere */
}

.default-form-field-input {
  .question {
  select.default-form-field-input {
    &:hover {
      cursor: pointer;
      border-color: $c-secondary;
    }
    
    &:focus {
      border-color: $c-secondary;
      box-shadow: 0 0 5px rgba(var(--c-secondary-rgb), 0.3);
    }
  }
}


  flex-grow: 1; /* Allows the input field to take up remaining space */
}

    input[type=radio] {
      width: auto;
      margin-right: 10%;
      margin-left: 3%;
    }

    input[type=radio]:checked {
      background-color: $c-primary;
    }

    .longInput {
      width: 75%;
    }

    input[type=checkbox] {
      width: auto;
      position: relative;
      margin-left: calc(25% - 24px - 24px);
    }

    .checkbox-label {
      display: auto;
      width: 18px;
      margin-left: calc(25% - 70px);
      font-weight: auto;
      margin-bottom: 15px;
    }

    label {
      span {
        svg {
          margin-top: -7px;
        }
      }
    }

    textarea {
      resize: none;
      width: calc(100% - 50px);
      margin-left: 20px;
      height: 300px;
      @media (max-width: 999px) {
        height: 200px;
      }
      margin-bottom: 15px;
    }

    table {
      width: calc(100% - 50px);
      @media (max-width: 999px) {
        width: calc(100% - 20px);
        margin-left: 10px;
      }
      margin-left: 20px;
      margin-bottom: 15px;
    }

    td {
      font-size: 14px;
      @media (max-width: 999px) {
        font-size: 12px;
      }
      padding: 4px 10px 4px 0px;

    }

  .productTable {

    // TOTAAL EXCL. BTW row
    .total-excl-row {
      .priceBlock {
        font-weight: 500;
        font-size: 16px;
        // color: blue;
      }
    }

    // BTW row
    .btw-row {
      .priceBlock {
        font-weight: 400;
        color: grey;
        font-style: oblique;
        border-bottom: 1px solid grey;
      }
    }

    // TOTAAL INCL. BTW row
    .total-incl-row {
      .priceBlock {
        font-weight: 600;
        font-size: 18px;
        // color: red;
      }
    }
          
      td {
        vertical-align: top;
      }
      .quantity {
        width: 10%;
      }
      .description {
        width: 70%;
      }
      .currency {
        width: 2%;
        text-align: right;
      }
      .price {
        width: 15%;
      }
      .priceTitle {
        width: 80%;
      }
      .priceBlock {
        font-weight: 500;
        font-size: 16px;
        @media (max-width: 999px) {
          font-size: 14px;
        }
        text-align: right;
      }

      .priceTitle.priceBlock {
        text-align: left;
      }

      .price.priceBlock {
        text-align: right;
      }

      &-price {
        text-align: right;
      }
    }

    .required-field {
  color: red;
  font-weight: bold;
  
}

    .invoiceButton {
  width: calc(49% - 25px);
  margin-left: 20px;
  margin-bottom: 20px;
  background-color: $c-secondary;
  padding: 10px;
  color: $c-secondary-text;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 0 0 rgba(var(--c-secondary-rgb), 0.4);
}

.invoiceButton:hover {
  background-color: darken($c-secondary, 10%);
  box-shadow: 0 0 10px rgba(var(--c-secondary-rgb), 0.6);
}

.invoiceButtonClicked {
  width: calc(49% - 25px);
  margin-left: 20px;
  margin-bottom: 20px;
  background-color: #D4F028;
  padding: 10px;
  color: #333;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 0 0 rgba(212, 240, 40, 0.4);
}

.invoiceButtonClicked:hover {
  background-color: #bfd726;
  box-shadow: 0 0 10px rgba(212, 240, 40, 0.6);
}

.invoiceButton:disabled {
  background-color: $c-background-1;
  color: $c-primary;
  cursor: not-allowed;
}

.invoiceButton-fullWidth {
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-bottom: 15px;
}

    .getPriceButton {
      width: calc(49% - 25px);
      margin-left: 20px;
      margin-bottom: 20px;
      background-color: #4CAF50;
      /* Green */
      padding: 10px 20px;
      /* Increased padding for better spacing */
      color: white;
      /* Changed text color to white for better contrast */
      border: none;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      transition: background-color 0.3s ease;
      /* Added transition for smooth hover effect */
    }

    /* Style for hover effect */
.getPriceButton:hover {
  background-color: #3e8e41;
  /* Removed box-shadow and added outline for a traditional focus-like effect */
  outline: 2px solid $c-secondary; /* Assuming $c-secondary is defined elsewhere */
}


/* Remove hover effect for disabled buttons */
.getPriceButton:disabled {
  background-color: initial; /* Reset background color */
  outline: none; /* Remove outline */
  cursor: not-allowed; /* Show not-allowed cursor to indicate the button is disabled */
}

/* Ensure no hover styles are applied when disabled */
.getPriceButton:disabled:hover{
  // background-color: initial; /* Keep the background color unchanged */
  outline: none; /* Ensure no outline */
}

    .getPriceButtonClicked {
      width: calc(49% - 25px);
      margin-left: 20px;
      margin-bottom: 20px;
      background-color: #D4F028;
      /* Yellow */
      padding: 10px 20px;
      /* Increased padding for better spacing */
      color: #333;
      /* Changed text color to dark gray for better contrast */
      border: none;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      transition: background-color 0.3s ease;
      /* Added transition for smooth hover effect */
    }

    .getPriceButtonClicked:hover {
      background-color: #bfd726;
      box-shadow: 0 0 10px rgba(212, 240, 40, 0.6);
      /* Glowing outline on hover */
    }

    .getPriceButton:disabled {
      background-color: $c-background-1;
      color: $c-primary;
      cursor: not-allowed;
    }

    .getPriceButton-fullWidth {
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-bottom: 15px;
    }
  
  }
}

h3 {
  margin-bottom: 30px;
  margin-left: 10px;
  font-size: 1.5rem;
  text-align: left;
}

.radioButton {
  background-color: white;
  border: 2px solid #505051;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  border-radius: 50px;
  position: relative;
  margin: 0px;
  height: 16px;
  width: 16px !important;
  transition: background-color 100ms ease;
  cursor: pointer;
  margin-bottom: 15px;
  margin-right: 30px;
  margin-left: 10px !important;
}

.radioButton-active {
  background-color: $c-primary;
}

.radioButtonInner {
  width: 10px !important;
  height: 10px;
  border-radius: 100px;
  background-color: #ffffff00;
  position: absolute;
  margin-left: 3px !important;
  margin-top: 3px;
  cursor: pointer;
  transition: background-color 100ms ease;
}

.radioButtonInner-active {
  background-color: #D4F028;
}
</style>

<style lang="scss">
.checkbox-label-icon {
  svg {
    margin-top: -7px;
  }
}

.radioButtonContainer {
  position: relative;
  display: inline-block;
}

.validRadio {
  position: absolute;
  right: -15px; /* Position inside the input, to the right */
  // top: 50%;
  transform: translateY(-50%); /* Center vertically */
  color: $c-secondary;
}

.invalidRadio {
  position: absolute;
  right: -5px; /* Position inside the input, to the right */
  // top: 50%;
  transform: translateY(-50%); /* Center vertically */
  color: red;
}

.shortProductList {
  font-size: 0.8rem;
  padding-left: 20px;
  margin-top: 0;

  li {
    margin: 0;
  }
}

.question {
  margin-bottom: 15px;

  .title {
    max-width: 100% !important;
    width: auto !important;
    margin-bottom: 5px;
    padding-right: 20px;
  }

  .input-with-icon {
    margin-left: 20px;
    width: calc(100% - 42px) !important;
  }

  select {
    width: 100%; /* Make select take full width of its container */
  }

  .invalid, .valid {
    right: 20px !important;
    top: 15px;
  }
}

</style>