<template> 
  <div class="options">
    <!-- <div class="top-page-header">
      <TabSelectorIcons :tabs="tabs" extension="" @change="changeTab"/>
    </div> -->
    <div class="top-page-header">
      <TabSelectorNoScrolling :tabs="tabs" extension="" @change="changeTab"/>
    </div>

    <RouterView />

    <!-- <ExpansionCard title="Basis opties" v-if="config.availableOptions.fundering || config.availableOptions.dakgoot"> 
      <div v-if="config.availableOptions.fundering">
        <OptionCard 
          v-for="(optie, index) in basis" 
          :key="index"
          :data="optie" 
          :selected="config.fundering"
          @select="updateBasisOption(optie)"
        />
      </div>

      <br>

      <DakGootButton
        v-if="config.availableOptions.dakgoot"
        :data="{name: 'Voorzijde', enabled: dakgoot.front.enabled, left: dakgoot.front.left, right: dakgoot.front.right}"
        @setDakgoot="changeDakgoot"
      />

      <DakGootButton
        v-if="config.availableOptions.dakgoot"
        :data="{name: 'Achterzijde', enabled: dakgoot.back.enabled, left: dakgoot.back.left, right: dakgoot.back.right}"
        @setDakgoot="changeDakgoot"
      />
      <br>
    </ExpansionCard> -->


    <!-- <ExpansionCard title="Lichtstraten" class="options-cards" v-if="config.availableOptions.lichtstraten">
      <OptionCardColor 
        v-for="(option, i) in lichtstraten" 
        :key="i" 
        :option="option" 
        type="lichtstraten"
        :index="i"
        @select="selectColor"
        @updateCounter="updateCounter"
      />
    </ExpansionCard> -->

    <!-- <ExpansionCard title="LED verlichting" v-if="config.availableOptions.ledverlichting">
      <OptionCardColor 
        v-for="(option, i) in led" 
        :key="i" 
        :option="option"
        type="led"
        :index="i"
        @select="selectColor"
        @updateCounter="updateCounter"
      />
    </ExpansionCard> -->
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  // import ExpansionCard from '@/components/layout/ExpansionCard.vue'
  // import OptionCard from '@/components/inputs/OptionCard.vue'
  // import OptionCardColor from '@/components/inputs/OptionCardColor.vue'
  // import DakGootButton from '@/components/inputs/buttons/DakGootButton.vue'
  // import TabSelectorIcons from '@/components/inputs/TabSelectorIcons.vue'
  import TabSelectorNoScrolling from '@/components/inputs/TabSelectorNoScrolling.vue'
  
  export default {
    name: "Options",

    components: {
      // ExpansionCard,
      // OptionCard,
      // OptionCardColor,
      // DakGootButton,
      // TabSelectorIcons
      TabSelectorNoScrolling
    },

    data () {
      return {
        parsedOptions: null,
        basis: null,
        // lichtstraten: null,
        // led: null,
        tabs: [
          {name: "Fundering", icon: "home-roof", route: "/"},
          {name: "", icon: "home-roof", route: "/", placeholder: true},
        ],
        dynamicTabs: [
          {name: "Spotjes", icon: "home-roof", route: "/spotjes"},
        ]
      }
    },

    computed: {
      ...mapGetters({
        options: 'getOptions',
        config: 'getConfig', 
        dakgoot: 'getDakgoot',
      }),
    },

    mounted() {
      this.filterTabs()
    },

    // created () {
    //   this.getOptions()
    //   setTimeout(() => {
    //     console.log(this.config)
    //   }, 5000);
    // },

    methods: {
      changeTab (tab) {
        this.$router.push('/cfg/options' + this.tabs[tab].route)
      },
      filterTabs() {
        // console.log('config.type',this.config.type)
        this.tabs = this.tabs.filter(tab => !tab.placeholder);
        if (this.config.type === 'Plat dak' || this.config.type === 'Aanbouwveranda') {
          this.tabs.push(this.dynamicTabs.find(tab => tab.name === "Spotjes"))
        }
      },
      
      // async getOptions () {
      //   if (this.options.length === 0) {
      //     await this.$store.dispatch('getOptions')
      //   }

        // this.parseOptions()
        // this.basis = this.filterCategory(this.parsedOptions, 'Fundering')
        // this.lichtstraten = this.filterCategory(this.parsedOptions, 'Lichtstraat')
        // this.led = this.filterCategory(this.parsedOptions, 'LED')
      // },

      // parseOptions () {
      //   let options = []

      //   for (let i = 0; i < this.options.length; i++) {

      //     let match

      //     if (this.options[i].colors.length > 0) {
      //       for (let x = 0; x < this.options[i].colors.length; x++) {
      //         if (!match) {
      //           match = options.find((o) => o.code === this.options[i].colors[x].code)
      //         }
      //       }

      //       const firstColor = this.options.find((o) => o.code === this.options[i].colors[0].code)

      //       if (!match) {
      //         options.push({
      //           ...this.options[i],
      //           colorPicked: 0,
      //           counter: 0,
      //           shortName: firstColor ? firstColor.shortName : this.options[i].shortName,
      //         })
      //       }
      //     } else {
      //       options.push({
      //         ...this.options[i],
      //         counter: 0,
      //       })
      //     }
      //   }

      //   for (let i = 0; i < this.config.opties.length; i++) {
      //     const option = this.config.opties[i]

      //     let matchIndex
      //     let colorIndex
      //     let match

      //     for (let x = 0; x < options.length; x++) {
      //       match = options[x].colors.findIndex((o) => o.code === option.code)

      //       if (match >= 0) {
      //         colorIndex = match
      //         matchIndex = x
      //       }
      //     }

      //     const firstColor = this.options.find((o) => o.code === options[matchIndex].colors[colorIndex].code)

      //     options[matchIndex].colorPicked = colorIndex
      //     options[matchIndex].counter = option.aantal
      //     options[matchIndex].shortName = firstColor.shortName
      //   }

      //   this.parsedOptions = options
      // },

      // filterCategory (options, category) {
      //   return options.filter((o) => o.keyWords.some((k) => k === category))
      // },

      // selectColor ({ code, colorIndex, cardIndex, type }) {
      //   const counter = this[type][cardIndex].counter

      //   const foundItem = this.options.find((o) => o.code === code)

      //   this[type][cardIndex].colorPicked = colorIndex
      //   this[type][cardIndex].counter = counter
      //   this[type][cardIndex].shortName = foundItem.shortName

      //   this.updateOptions(cardIndex, type)
      // },

      // updateCounter ({ counter, index, type }) {

      //   this[type][index].counter = counter

      //   this.updateOptions(index, type)
      // },

      // updateOptions (index, type) {

      //   const option = this[type][index]

      //   let code
        
      //   if (type === 'basis') {
      //     code = option.code
      //   } else {
      //     code = option.colors[option.colorPicked].code
      //   }

      //   const configOptions = JSON.parse(JSON.stringify(this.config.opties))

      //   const indexExistingOption = configOptions.findIndex((o) => o.code === code)

      //   if (indexExistingOption >= 0) {

      //     configOptions.splice(indexExistingOption, 1)
      //   }

      //   if (option.counter > 0) {

      //     configOptions.push({
      //       code: code,
      //       aantal: option.counter,
      //     })
      //   }

      //   this.$store.dispatch('updateConfigOptions', configOptions)
      // },

      // updateBasisOption () {
      //   this.$store.dispatch('updateFoundation', !this.config.fundering)
      // },

      // changeDakgoot (data) {
      //   this.$store.dispatch('updateDakgoot', data)
      // }
    },
  }
</script>

<style lang="scss" scoped>

// .options {
//   display: flex;
//   flex-direction: column;
//   align-items: start//;stretch;
// }

.top-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
  min-width: 100%;
  
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center; //start; 
  }
}

</style>