<template>
  <div class="wrapper">
    <button class="button-wrapper" @click="$emit('setDakgoot', {...data, enabled: !data.enabled})" :class="data.enabled ? 'card-selected' : ''">
      <img :src="`${previewsServer}/textures/dak/dakgoot.png`" alt="" @error="setBackupImage($event, data)">
    </button>
    <div class="rightPart">
      <h3>Dakgoot {{data.name}}</h3>
      <div class="sideSelector">
        <div class="sideContainer" v-on:click="$emit('setDakgoot', {...data, left: !data.left, right: data.left})">
          <mdicon :name="data.left ? 'toggle-switch' : 'toggle-switch-off'" class="labelA-icon toggle-icon" :class="data.left ? 'labelA-icon-active' : 'labelA-icon'" size="45"/>
          <h3 class="side">Uitvoer Links</h3>
        </div>
        <div class="sideContainer" v-on:click="$emit('setDakgoot', {...data, right: !data.right, left: data.right})">
          <mdicon :name="data.right ? 'toggle-switch' : 'toggle-switch-off'" class="labelA-icon toggle-icon" :class="data.right ? 'labelA-icon-active' : 'labelA-icon'" size="45" v-on:click="$emit('setDakgoot', {...data, right: !data.right})"/>
          <h3 class="side">Uitvoer Rechts</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "DakGootButton",

    props: {
      data: Object,
    },

    data () {
      return {
        previewsServer: import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER,
        
      }
    },

    methods: {
      
      },
      
    }
</script>

<style lang="scss" scoped>

img {
  min-height: 100px;
  max-height: 100px;
  max-width: 150px;
  min-width: 150px;
  object-fit: contain;
}

svg {
  width: 45px;
}

path {
  transform: translateX(-5px) translateY(-5px) scale(1.5); // 75% of original size
  ;
}

.card-selected {
  outline-color: $c-secondary;
}

.wrapper {
  margin-top: 20px;
  vertical-align: top;
  text-align: left !important;
}

button {
  margin-left: 10px !important;
  display: inline-block !important;
  outline: 3px solid $c-border-1;
  border-radius: $radius-s;
  cursor: pointer;
}

.rightPart {
  display: inline-block;
  margin-top: 0px;
  vertical-align: top;
}

h3 {
  margin-top: 10px;
  vertical-align: top;
  margin-left: 20px;
  padding-top: 0px;
  margin-bottom: 0px;
}

.side {
  display: inline-block;
  margin: 0px !important;
  padding-top: 5px !important;
  padding-left: 5px;
  font-size: 1rem;
}

.sideSelector {
  vertical-align: top;
  margin-left: 20px;
}

.button-wrapper {
  border: none;
  display: flex;
  margin: auto;
  padding: 0px;
  background-color: transparent;
  min-height: 100%;
}

.sideContainer {
  cursor: pointer;
  user-select: none;
}

.labelA {
  background-color: white;
  border: 2px solid $c-primary;;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  border-radius: $radius-s;
  position: relative;
  margin: 7px;
  height: 20px;
  width: 20px;
  transition: background-color 100ms ease;
  cursor: pointer !important;
  pointer-events: none;

  &-icon {
    height: 35px;
    color: $c-background-1;
    margin-left: 1px;
    margin-bottom: 2px;
    transition: color 200ms ease;
    cursor: pointer !important;

    &-active {
      color: $c-secondary;
    }
  }
}

</style>

<style lang="scss">
.toggle-icon {
  svg {
    margin-top: -7.5px;
    margin-bottom: -6.5px;
    margin-left: 1px;
  }
}
</style>