<template>
  <div class="edit">
    <h1>{{ newSchuifwand ? 'Nieuw schuifwand type' : `schuifwand, ${data.glasplaatAantal} panelen` }}</h1>

    <form class="default-form edit-form" @submit.prevent="">

      <div class="data-block">
        
        <div class="imageContainer">
          <img :src="tempImageStorage ? tempImageStorage : data.image ? `${previewsServer}/textures/${data.image}` : ''" class="imageContainer-image">
          <input type="file" class="imageContainer-button" @input="changeImage($event)">
          <mdicon class="imageContainer-icon" name="upload" size="75"></mdicon>
          <div class="imageContainer-delete" v-if="tempImageStorage || data.image" @click="deleteImage()">
            <mdicon name="delete" size="25" style="color: red; pointer-events: none;" />
          </div>
        </div>

        <h2>gehele schuifwand:</h2>
        <div class="data-block-inset">
          <div class="default-form-field">
            <label class="default-form-field-label" for="minimaleBreedte">minimale breedte:</label>
            <input type="number" class="default-form-field-input" id="minimaleBreedte" v-model.number="data.minimaleBreedte" >
          </div>
          <div class="default-form-field">
            <label class="default-form-field-label" for="maximaleBreedte">maximale breedte:</label>
            <input type="number" class="default-form-field-input" id="maximaleBreedte" v-model.number="data.maximaleBreedte" >
          </div>
        </div>

        <h2>glasplaat:</h2>
        <div class="data-block-inset">
          <div class="default-form-field">
            <label class="default-form-field-label" for="glasplaatAantal">aantal:</label>
            <input type="number" class="default-form-field-input" id="glasplaatAantal" v-model.number="data.glasplaatAantal" >
          </div>
          <div class="default-form-field">
            <label class="default-form-field-label" for="glasplaatBreedte">breedte:</label>
            <input type="number" class="default-form-field-input" id="glasplaatBreedte" v-model.number="data.glasplaatBreedte" >
          </div>
          <div class="default-form-field">
            <label class="default-form-field-label" for="glasplaatDikte">dikte:</label>
            <input type="number" class="default-form-field-input" id="glasplaatDikte" v-model.number="data.glasplaatDikte" >
          </div>
          <div class="default-form-field">
            <label class="default-form-field-label" for="glasplaatMinimaleOverlap">minimale overlap:</label>
            <input type="number" class="default-form-field-input" id="glasplaatMinimaleOverlap" disabled v-model.number="minimaleOverlap" >
          </div>
          <div class="default-form-field">
            <label class="default-form-field-label" for="glasplaatMaximaleOverlap">maximale overlap:</label>
            <input type="number" class="default-form-field-input" id="glasplaatMaximaleOverlap" disabled v-model.number="maximaleOverlap" >
          </div>
        </div>

        <h2>schuifwand rails:</h2>
        <div class="data-block-inset">
          <div class="default-form-field">
            <label class="default-form-field-label" for="bovenrailHoogte">bovenrail hoogte:</label>
            <input type="number" class="default-form-field-input" id="bovenrailHoogte" v-model.number="data.bovenrailHoogte" >
          </div>
          <div class="default-form-field">
            <label class="default-form-field-label" for="onderrailHoogte">onderrail hoogte:</label>
            <input type="number" class="default-form-field-input" id="onderrailHoogte" v-model.number="data.onderrailHoogte" >
          </div>
          <div class="default-form-field">
            <label class="default-form-field-label" for="railDikte">rail dikte:</label>
            <input type="number" class="default-form-field-input" id="railDikte" v-model.number="data.railDikte" >
          </div>
        </div>


        <h2>glasprofiel:</h2>
        <div class="data-block-inset">
          <div class="default-form-field">
            <label class="default-form-field-label" for="glasprofielStaanderBreedte">glasprofiel staander breedte:</label>
            <input type="number" class="default-form-field-input" id="glasprofielStaanderBreedte" v-model.number="data.glasprofielStaanderBreedte" >
          </div>
          <div class="default-form-field">
            <label class="default-form-field-label" for="glasprofielStaanderDikte">glasprofiel staander dikte:</label>
            <input type="number" class="default-form-field-input" id="glasprofielStaanderDikte" v-model.number="data.glasprofielStaanderDikte" >
          </div>
          <div class="default-form-field">
            <label class="default-form-field-label" for="glasprofielLiggerBreedte">glasprofiel ligger breedte:</label>
            <input type="number" class="default-form-field-input" id="glasprofielLiggerBreedte" v-model.number="data.glasprofielLiggerBreedte" >
          </div>
          <div class="default-form-field">
            <label class="default-form-field-label" for="glasprofielLiggerDikte">glasprofiel ligger dikte:</label>
            <input type="number" class="default-form-field-input" id="glasprofielLiggerDikte" v-model.number="data.glasprofielLiggerDikte" >
          </div>
        </div>

        <h2>handvat:</h2>
        <div class="data-block-inset">
          <div class="default-form-field">
            <label class="default-form-field-label" for="handvatVerticalePositionering">handvat verticale positionering:</label>
            <input type="number" class="default-form-field-input" id="handvatVerticalePositionering" v-model.number="data.handvatVerticalePositionering" >
          </div>
          <div class="default-form-field">
            <label class="default-form-field-label" for="handvatHorizontalePositionering">handvat horizontale positionering:</label>
            <input type="number" class="default-form-field-input" id="handvatHorizontalePositionering" v-model.number="data.handvatHorizontalePositionering" >
          </div>
        </div>

        <div class="default-form-field spacer">
          <label class="default-form-field-label" for="glaspaneelHartAfstand">glaspaneel hart-afstand:</label>
          <input type="number" class="default-form-field-input" id="glaspaneelHartAfstand" v-model.number="data.glaspaneelHartAfstand" >
        </div>

        <div class="default-form-field spacer">
          <label class="default-form-field-label" for="name">schuifrichting:</label>
          <!-- <input type="text" class="default-form-field-input" id="name" v-model.trim="data.schuifrichting" > -->
          <select v-model="data.schuifrichting" class="default-form-field-input">
            <option value="links">links</option>
            <option value="rechts">rechts</option>
          </select>
        </div>

        <div class="default-form-field spacer">
          <h2>artikelnummers:</h2>
          <!-- <input type="text" class="default-form-field-input" id="name" v-model.trim="data.artikelnummers" > -->
          <div style="width: 100%">
            <div class="artikelnummer-row" v-for="artikelnummer, i in data.artikelnummers" :key="`artikelnummer-${i}`">
              <div class="default-form-field-input artikelnummer-item" 
                :title="JSON.stringify(cmsComponents.find(c=>c.id===artikelnummer))"
              >
                {{ cmsComponents.find(c=>c.id===artikelnummer) ? cmsComponents.find(c=>c.id===artikelnummer).code : artikelnummer }} -
                {{ cmsComponents.find(c=>c.id===artikelnummer) ? cmsComponents.find(c=>c.id===artikelnummer).shortName : '' }}
              </div>
              <button class="default-form-field-button artikelnummer-add" disabled style="pointer-events: none;">
                <mdicon name="eye-outline" class="default-form-field-button-icon" size="18" v-if="cmsComponents.find(c=>c.id===artikelnummer) ? cmsComponents.find(c=>c.id===artikelnummer).trueOnline : false"/>
                <mdicon name="eye-off-outline" class="default-form-field-button-icon" size="18" v-else/>
              </button>
              <button class="default-form-field-button artikelnummer-add" @mousedown="moveUp(i)">
                <mdicon name="chevron-up" class="default-form-field-button-icon" size="18" />
              </button>
              <button class="default-form-field-button artikelnummer-add" @mousedown="moveDown(i)">
                <mdicon name="chevron-down" class="default-form-field-button-icon" size="18" />
              </button>
              <button class="default-form-field-button artikelnummer-add" @mousedown="removeArtikelnummer(i)">
                <mdicon name="minus" class="default-form-field-button-icon" size="18" />
              </button>
            </div>

            <select v-model="selectedSlidingWallComponent" class="default-form-field-input artikelnummer-select">
              <option v-for="slidingWall, i in filteredCMSComponents" :key="`slidingWall${i}`" :value="slidingWall.id">{{slidingWall.code}} - {{slidingWall.shortName}}</option>
            </select>
            <button class="default-form-field-button artikelnummer-add" @mousedown="addArtikelnummer()">
              <mdicon name="plus" class="default-form-field-button-icon" size="18" />
            </button>
          </div>
        </div>
      </div>

      <div class="default-form-button">
        <LoadingButton 
          @click="newSchuifwand ? create() : update()" 
          :loading="loading" 
          :disabled="!formValid"
        >
          {{ newSchuifwand ? 'Creëren' : 'Update' }}
        </LoadingButton>
      </div>

    </form>
  </div>
</template>

<script>
  import isEqual from 'lodash.isequal'
  import { mapGetters } from 'vuex'
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'

  export default {
    name: "ModelDetails",

    components: {
      LoadingButton
    },

    data () {
      return {
        id: null,
        loading: false,
        newSchuifwand: false,
        formValid: false,
        selectedSlidingWallComponent: null,
        tempImageStorage: null,
        previewsServer: import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER,
        data: {
          minimaleBreedte: 0,
          maximaleBreedte: 0,
          glasplaatAantal: 0,
          glasplaatBreedte: 0,
          glasplaatDikte: 0,
          bovenrailHoogte: 0,
          onderrailHoogte: 0,
          railDikte: 0,
          glasprofielStaanderBreedte: 0,
          glasprofielStaanderDikte: 0,
          glasprofielLiggerBreedte: 0,
          glasprofielLiggerDikte: 0,
          handvatVerticalePositionering: 0,
          handvatHorizontalePositionering: 0,
          glaspaneelHartAfstand: 0,
          schuifrichting: "",
          artikelnummers: [],
          order: 0,
        },
      }
    },

    methods: {
      setId () {
        this.id = this.$route.params.id

        if (this.id === 'new') {
          this.newSchuifwand = true
        } else {
          this.data.id = this.id
        }
      },

      async get () {
        await this.$store.dispatch('getSchuifwandTypes')
        await this.$store.dispatch('getCMSComponents', {categories: ['Deur', 'Raam', 'Steellook']})

        if (!this.newSchuifwand) {
          this.schuifwandType = this.schuifwandTypes.find((m) => m.id === this.id)
          this.data = JSON.parse(JSON.stringify(this.schuifwandType))
        }
      },

      async create () {
        this.loading = true
        try {
          await this.$store.dispatch('createSchuifwandType', this.data)
        } catch (err) {
          this.loading = false
        }
        this.loading = false
      },

      async update () {
        this.loading = true
        try {
          await this.$store.dispatch('updateSchuifwandType', this.data)
          await this.get()
        } catch (err) {
          console.log(err)
          this.loading = false
        }
        this.loading = false
      },

      addArtikelnummer () {
        if (this.selectedSlidingWallComponent) {
          this.data.artikelnummers.push(this.selectedSlidingWallComponent)
        }
        this.selectedSlidingWallComponent = null
      },

      removeArtikelnummer (index) {
        this.data.artikelnummers.splice(index, 1)
      },

      moveUp(i) {
        if (i === 0) return
        this.data.artikelnummers.splice(i - 1, 0, this.data.artikelnummers.splice(i, 1)[0])
      },

      moveDown(i) {
        if (i === this.data.artikelnummers.length - 1) return
        this.data.artikelnummers.splice(i + 1, 0, this.data.artikelnummers.splice(i, 1)[0])
      },

      changeImage (e) {
        if (e.target.files.length < 1) return
        this.tempImageStorage = URL.createObjectURL(e.target.files[0])
        // save image as base64
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result;
          this.data.image = base64String
          console.log("this.data.image", this.data.image)
        };
        reader.readAsDataURL(e.target.files[0]);
      },

      deleteImage () {
        this.tempImageStorage = null
        this.data.image = null
      }
    },


    computed: {
      ...mapGetters({
        schuifwandTypes: "getSchuifwandTypes",
        cmsComponents: "getCMSComponents"
      }),

      minimaleOverlap () {
        return (-this.data.maximaleBreedte + (this.data.glasplaatAantal * this.data.glasplaatBreedte))/(this.data.glasplaatAantal - 1)
      },

      maximaleOverlap () {
        return (-this.data.minimaleBreedte + (this.data.glasplaatAantal * this.data.glasplaatBreedte))/(this.data.glasplaatAantal - 1)
      },

      filteredCMSComponents () {
        return this.cmsComponents.filter((c) => c.category === 'Schuifwand').filter((c) => !this.data.artikelnummers.includes(c.id))
      }
    },

    async created () {
      console.log(this.$refs)
      this.setId()
      await this.get()

      document.addEventListener('keydown', e => {
        if (e.ctrlKey && e.key === 's') {
          e.preventDefault();
          this.update()
        }
      });
    },

    watch: {
      data: {
        deep: true,
        immediate: false,
        handler () {
          if (this.newSchuifwand) {
            this.formValid = true
          } else {
            if (isEqual(this.model, this.data)) {
              this.formValid = false
            } else {
              this.formValid = true
            }
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.edit {
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  min-width: min(100%, 800px);

  &-form {
    padding: 25px 0px;
    min-width: min(100%, 800px);
    max-width: min(100%, 800px);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.data-block {
  h2 {
    text-align: left;
    margin: 8px 0px;
    font-weight: 600;
    font-size: 1.4rem;
  }

  .default-form-field {
    margin-bottom: 12px;

    &-label {
      min-width: 320px
    }
  }

  &-inset {
    margin-left: 20px;
    margin-bottom: 24px;
    .default-form-field {
      &-label {
        min-width: 300px
      }
    }
  }
}

.spacer {
  margin-bottom: 24px !important;
}

.artikelnummer {
  &-select {
    width: calc(100% - 42px);
    display: inline-block;
    margin: 0px;
    vertical-align: top;
  }

  &-add {
    display: inline-block;
    padding: 0px;
    margin: 0px;
    vertical-align: top;
    margin-left: 12px
  }

  &-item {
    width: calc(100% - 178px);
    display: inline-block;
    margin: 0px;
    vertical-align: top;
    text-align: left;
  }

  &-button {
    display: inline-block;
    padding: 0px;
    margin: 0px;
    vertical-align: top;
    margin-left: 12px
  }

  &-row {
    margin-bottom: 12px;
  }
}

.imageContainer {
  width: 300px;
  height: 300px;
  position: relative;
  border: 3px solid #E3E6E9;

  &-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    outline: none;
  }

  &-button {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
  }

  &-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-delete {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #E3E6E9;
    padding: 5px;
    cursor: pointer;
    z-index: 100;
  }
}
</style>