<template>
  <div class="edit-infoboxsettings">
    <h1>Menu infobox settings</h1>

    <form 
      class="default-form edit-infoboxsettings-form"
      style="width: 90vw;" 
      @submit.prevent=""
    >
      <div class="default-form-field">
        <div class="default-form-field-infoboxsettings">
          <div class="default-form-field-infoboxsettings-header default-form-field-infoboxsettings-edit-row">
            <span>Nr.</span>
            <span>Name</span>
            <span>Title</span>
            <span>Header</span>
          </div>

          <div 
            v-for="(infoboxsetting, i) in infoboxsettings" 
            :key="i" 
            class="default-form-field-infoboxsettings-edit-row"
          >
            <input 
              v-model="infoboxsetting.uiIndex"
              type="text" 
              class="default-form-field-input"
              style="border: none; background-color: transparent;"
              readonly
            >
            <input 
              v-model.trim="infoboxsetting.name"
              type="text" 
              class="default-form-field-input" 
            >
            <input 
              v-model.trim="infoboxsetting.title"
              type="text" 
              class="default-form-field-input" 
            >
            <input 
              v-model.trim="infoboxsetting.header"
              type="text" 
              class="default-form-field-input" 
            >

            <button 
              v-if="infoboxsetting.id"
              class="add-image-button"
              @click="handleModal(infoboxsetting)" 
            >
              Add Image
            </button>

            <button class="default-form-field-button" @click="removeInfoboxSetting(i)">
              <mdicon name="minus" class="default-form-field-button-icon" size="25" />
            </button>
          </div>

          <button class="default-form-field-button" @click="addInfoboxSetting()">
            <mdicon name="plus" class="default-form-field-button-icon" size="25" />
          </button>
        </div>
      </div>

      <div class="default-form-button">
        <LoadingButton 
          :loading="loading" 
          :disabled="!formValid"
          @click="updateMenuInfoboxSettings()" 
        >
          Update
        </LoadingButton>
      </div>
    </form>
  </div>
  <Teleport to="body">
    <div 
      v-if="showmodal"
      class="fullcover"
    >
      <div 
        class="uploadmodal"
      >
        <section class="modalsection">
          <div>
            <div>Voeg afbeelding toe aan: </div>
            <div><span style="font-weight:bold;">{{ selectedrowName }}</span></div>
            <div>ID: {{ selectedrowID }}</div>
            <br>
            <input 
              :ref="`fileInput${selectedrowID}`"
              type="file" 
              accept="image/png"
              @input="addImageFile(selectedrowID)"
            >
          </div>
          <div style="margin-left: auto;">
            <button 
              v-if="allowConfirm"
              ref="focusafterfilepick"
              tabindex="0"
              class="close-modal-button add-image-button" 
              @click="confirmUpload()"
              @keydown.esc="clearModal()"
            >
              Bevestigen
            </button>
            <button 
              v-else
              class="close-modal-button blocked-add-image"
            >
              Bevestigen
            </button>
            <button 
              ref="focusafteropen"
              tabindex="0"
              class="close-modal-button cancel-add-image" 
              @click="clearModal()"
              @keydown.esc="clearModal()"
            >
              Annuleren
            </button>
          </div>
        </section>
        <section class="hints">
          <div>Breedte: &lt;= 1500px</div>
          <div>Lengte: onbeperkt</div>
          <div>File type: PNG</div>
          <div>Maximum file size: 3mb</div>
        </section>
        <img 
          :src="tempImageStorage ? tempImageStorage : `${previewsServer}/infoboximages/${selectedrowUrlEnding}`" 
          :title="`${selectedrowUrlEnding}`" 
          class="preview-image"
        >
      </div>
    </div>
  </Teleport>
</template>

<script>
  import isEqual from 'lodash.isequal'
  import { mapGetters } from 'vuex'
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'

  export default {
    name: "MenuInfoboxSettings",

    components: {
      LoadingButton
    },

    data () {
      return {
        infoboxsettings: [],
        formValid: false,
        loading: false,
        showmodal: false,
        selectedrowID: '',
        selectedrowName: '',
        selectedrowUrlEnding: '',
        tempImageStorage: null,
        allowConfirm: false,
        previewsServer: import.meta.env.VITE_VUE_APP_SERVER
      }
    },

    computed: {
      ...mapGetters({
        menuInfoboxSettings: "getMenuInfoboxSettings"
      }),
    },

    watch: {
      infoboxsettings: {
        deep: true,
        immediate: false,
        handler () {
          if (isEqual(this.infoboxsettings, this.menuInfoboxSettings)) {
            this.formValid = false
          } else {
            this.formValid = true
          }
        }
      }
    },

    created () {
      this.getMenuInfoboxSettings()
    },

    methods: {
      async getMenuInfoboxSettings () {
        await this.$store.dispatch('getMenuInfoboxSettings')
        this.infoboxsettings = JSON.parse(JSON.stringify(this.menuInfoboxSettings))
      },

      async updateMenuInfoboxSettings () {
        this.loading = true

        try {
          await this.$store.dispatch('updateMenuInfoboxSettings', this.infoboxsettings)
        } catch (err) {
          console.log(err)
          this.loading = false
        }

        this.loading = false
        this.infoboxsettings = JSON.parse(JSON.stringify(this.menuInfoboxSettings))
      },
      
      addInfoboxSetting () {
        this.infoboxsettings.push({
          name: "",
        })
      },

      removeInfoboxSetting (i) {
        this.infoboxsettings.splice(i, 1)
      },

      handleModal(infoboxsetting) {
        // console.log('infoboxsetting',infoboxsetting);
        this.selectedrowID = infoboxsetting.id;
        this.selectedrowName = infoboxsetting.name;
        console.log('infoboxsetting.urlEnding',infoboxsetting.urlEnding)
        this.selectedrowUrlEnding = infoboxsetting.urlEnding;
        this.showmodal = true;
        
        this.$nextTick(() => {
          const buttonRef = this.$refs.focusafteropen;
          buttonRef.focus();
        });
      },

      clearModal() {
        this.$refs[`fileInput${this.selectedrowID}`].value = null;
        this.selectedrowID = '';
        this.selectedrowName = '';
        this.showmodal = false;
        this.tempImageStorage = null;
      },

      addImageFile() {
        console.log('addImageFile');
        const reader = new FileReader();
        reader.readAsDataURL(this.$refs[`fileInput${this.selectedrowID}`].files[0]);
        reader.onload = () => {
          this.tempImageStorage = reader.result;
          this.allowConfirm = true;

          this.$nextTick(() => {
            const buttonRef = this.$refs.focusafterfilepick;
            buttonRef.focus();
          });
        };

      },

      async confirmUpload() {
        // console.log('confirmUpload')
        const uploadObject = {
          id: this.selectedrowID,
          image: this.tempImageStorage,
          urlEnding: this.selectedrowUrlEnding
        }

        try {
          if (!uploadObject.image || uploadObject.image === 'undefined') {
            console.error('No image provided for upload.')
            return;
          } 
          await this.$store.dispatch('updateInfoboxImage', uploadObject)
        } catch (err) {
          console.log(err)
          this.loading = false
        } finally {
          this.clearModal();
        }
      }
    },
  }
</script>

<style lang="scss" scoped>

input {
  height: 1.5rem;
}

.uploadmodal {
  position: fixed;
  z-index: 999;
  top: 20%;
  left: 50%;
  width: 600px;
  margin-left: -300px;
  height: 60vh;
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 16px grey;

  .modalsection {
    display: flex;
    flex-direction: row;
  }
}

.fullcover {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.close-modal-button {
  margin-left: 3rem;
  height: 2.4rem;
}

.preview-image {
  width:75%;
  display: block;
  margin: 0px auto;
  margin-top: 10px;
  max-height: 50%;
  height: 50%;
  object-fit: contain;
}

.hints {
  font-size: small;
  margin: 10px 0px 0px 10px;
}

.add-image-button {
  border-radius: $radius-s;
  background-color: $c-secondary;
  color: $c-primary;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.cancel-add-image {
  border-radius: $radius-s;
  background-color: #E3E6E9;
  color: $c-primary;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;  
}

.blocked-add-image {
  border-radius: $radius-s;
  background-color: #f2f5f7;
  color: $c-primary;
  border: none;
  font-size: 1rem;
  font-weight: 600;
}

.edit-infoboxsettings {
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  min-width: min(100%, 800px);

  &-form {
    padding: 25px 0px;
    gap: 50px;
    min-width: min(100%, 800px);

    &-display {
      border-radius: $radius-s;
      outline: 3px solid $c-background-1;
      outline-offset: -1px;
      height: 29px;
      align-self: center;
    }
  }
}

</style>