<template>
  <div class="wall-page-v2">
    <!-- <div class="wall-page-header"> -->
      <!-- v-if="!selectedWall && !selectedElement" -->
      <!-- <TabSelectorIcons :tabs="filteredTabs" :position="getCurrentPosition" extension="" @change="changeTab"/>  -->
      <!--@change="changeTab"-->
      <!-- <TabSelectorIcons :tabs="tabs" extension="" @change="changeTab" /> -->
    <!-- </div> -->
    <div class="wall-page-header">
      <TabSelectorNoScrolling :tabs="filteredTabs" extension="" @change="changeTab"/>
    </div>

    <!--<h3 v-else-if="selectedWall">Pas de geselecteerde wand aan</h3>-->

    <!-- <WallDimensions v-if="selectedWall && selectedWall.wall.parent.name != 'wallBox'"/>  -->

    <!-- <h3 v-else-if="selectedElement">Pas het geselecteerde item aan</h3> -->

    <!-- <h3 >Pas het geselecteerde item aan</h3>  -->
    <!-- v-else-if="selectedElement" -->
    <!--<keep-alive>-->
      <RouterView />
    <!--</keep-alive>-->
  </div>
</template>

<script>
  // import TabSelectorIcons from '@/components/inputs/TabSelectorIcons.vue'
  import TabSelectorNoScrolling from '@/components/inputs/TabSelectorNoScrolling.vue'
  // import WallDimensions from '@/views/configurator-ui/WallDimensions'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      // TabSelectorIcons,
      TabSelectorNoScrolling
      // WallDimensions
    },

    data () {
      return {
        tabs: [
          {name: "Wanden toevoegen", icon: "shape-rectangle-plus", route: "/"},
          // {name: "ramen & deuren", icon: "door", route: "/doors-and-windows"},
          {name: "Steellook", icon: "window-closed-variant", route: "/steellook"},
          {name: "Steellook met BW", icon: "window-closed-variant", route: "/steellook-borstwering"},
          {name: "Schuifwanden", icon: "door-sliding", route: "/sliding-walls"},
        ],
      }
    },

    methods: {
      changeTab (tab) {
        this.$router.push('/cfg/walls' + this.filteredTabs[tab].route)
      }
    },

    computed: {
      ...mapGetters({
        // selectedWall: 'getSelectedWall',
        selectedElement: 'getSelectedElement',
        config: 'getConfig',
      }),

      filteredTabs () {
        if (this.config.afmetingen.wall.position === 'buiten') {
          return this.tabs.filter((tab) => tab.route !== '/steellook-borstwering')
        }
        return this.tabs
      },

      /*getCurrentPosition () {
        const tab = this.tabs.findIndex((tab) => '/cfg/walls'+tab.route === this.$route.path)
        this.tabs.findIndex((tab) => console.log('/cfg/walls'+tab.route, this.$route.path))
        console.log(tab)
        return tab
      }*/
    },
    
  }
</script>

<style lang="scss" scoped>

.wall-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
  min-width: 100%;

  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.wall-page-v2 {
  display: flex;
  flex-direction: column;
  align-items: start;//stretch;
  gap: 15px;
  min-width: 100%;

  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

</style>