<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <RouterView />

    <ErrorBar v-if="error.showError" />

    <SuccesBar v-if="succes.showSucces" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ErrorBar from './components/modals/ErrorBar.vue'
  import SuccesBar from './components/modals/SuccesBar.vue'

  export default {
    components: {
      ErrorBar,
      SuccesBar
    },

    computed: {
      ...mapGetters({
        error: 'getError',
        succes: 'getSucces',
      }),
    },

    async created () {
      await this.$store.dispatch('autoLogin')
      if (import.meta.env.VITE_VUE_APP_CONNECTWEBSOCKET === true) await this.$store.dispatch('connectToWebSocket');

      window.addEventListener('message', (event) => {
        if (event.data.name === 'TW_CONFIGURATOR') {
          switch(event.data.action) {
            case 'FULLSCREEN':
              this.$store.commit('setFullScreen', event.data.value)
              break;
            case 'FULLSCREEN_POSSIBLE':
              this.$store.commit('setFullScreenPossible', event.data.value)
              break;
          }
        }
      });
      // ask parent frame for response if fullscreen should be possible
      window.parent.postMessage({
        name: 'TW_CONFIGURATOR',
        action: 'FULLSCREEN_POSSIBLE'
      }, '*');
    }
  } 
</script>

<style lang="scss">
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
// task 1511 - Oswald and Source Sans Pro
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&family=Source+Sans+3:wght@400;700&display=swap');

#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

body {
  padding: 0px;
  margin: 0px;
}

* {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

</style>
