<template>
  <div ref="boudingBox" class="bouding-box" :style="`width: ${totalWidth}px;`">
    <div name="switch" class="switch" :style="`transform: translateX(${- center}px);`">
      <div class="switch-tracker" :style="`width: ${trackerWidth}px; transform: translateX(${trackerPostition}px);`"></div>

      <button v-for="(tab, index) in tabs" :key="index"
        class="switch-button" 
        :style="`width: ${buttonWidth(tab)}px;`"
        :class="selectedBerging === tab.id ? 'switch-button-active' : ''" 
        @click="$emit('change', tab.id)"
      >
        {{ tab.name }} 
      </button>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: "tab-selector",

    props: {
      tabs: Array,
      selectedBerging: String,
    },

    data () {
      return {
        //position: 0,
        maxWidth: 0,
      }
    },

    methods: {
      buttonWidth(text) {
        let textLength = text.name.length * 8
        let buttonPadding = 15
        let icon = 0
        
        return textLength + buttonPadding + icon
      },

      handleResize () {
        this.maxWidth = this.$refs.boudingBox.clientWidth
      }
    },

    computed: {

      totalWidth () {
        let padding = ((this.tabs.length - 1) * 10) 
        let buttons = 0 
        for (let i = 0; i < this.tabs.length; i++) {
          let text = this.tabs[i].name
          let textLength = text.length * 8
          let buttonPadding = 15
          let icon = 0
          buttons = buttons + (textLength + buttonPadding + icon) 
        }
        return padding + buttons
      },

      trackerWidth () {
        // console.log(this.tabs)
        // console.log(this.accuratePosition)
        let text = this.tabs[this.accuratePosition] ? this.tabs[this.accuratePosition].name : 'placeholder'
        let textLength = text.length * 8 
        let padding = 15
        let icon = 0
        return textLength + padding + icon
      },

      trackerPostition () {
        if (this.accuratePosition > 0) {
          let padding = (this.accuratePosition * 10)
          let buttons = 0 
          for (let i = 0; i < this.accuratePosition; i++) {
            let text = this.tabs[i].name
            let textLength = text.length * 8
            let buttonPadding = 15
            let icon = 0
            buttons = buttons + (textLength + buttonPadding + icon) 
          }
          return padding + buttons
        } else {
          return 0
        }
      },

      center () {
          
        if (this.totalWidth > this.maxWidth) {
          let maxRight = this.totalWidth - (this.maxWidth / 2)
          let minLeft = this.maxWidth / 2

          
          if (this.trackerPostition + (this.trackerWidth / 2) < minLeft) { // minimum requirement for moving form the left side
            return 0 
          } else if (this.trackerPostition + (this.trackerWidth / 2) > maxRight) { // maximum movent to rigth, there is no more tabbar to show (+ 10 to compensate padding)
            return this.totalWidth - this.maxWidth + 10
          } else  { // movement between the min and max restrictions
          // console.log(this.trackerPostition, this.trackerWidth, this.maxWidth)
            return (this.trackerPostition + this.trackerWidth / 2) - this.maxWidth / 2
          }
          // } else { // if for any reason the calculation fails the tabbar is returend to the default position
          //   return 0
          // }
        } else {
          return 0
        }
      },

      accuratePosition () {
        let currentPosition
        for(let i=0; i<this.tabs.length; i++) {
          if(this.selectedBerging === this.tabs[i].id) {
            currentPosition = i
          }
        }
        // console.log(currentPosition)
        return currentPosition !== undefined ? currentPosition : 0
        //currentPath.substring(currentPath.lastIndexOf('/'), currentPath.length) === tab.route

      },


      ...mapGetters({
        currentPath: 'getCurrentPath',
      })

      // center () {
      //   console.log(this.$refs["bouding-box"])

      //   if (this.boudingBox) {
      //     let maxWidth = this.$refs["bouding-box"].clientWidth

      //     console.log(maxWidth)

      //     if (this.totalWidth > maxWidth) {
      //       let maxRight = this.totalWidth - maxWidth
            
      //       console.log(maxRight)

      //       if (this.trackerPostition > maxRight) {
      //         return maxRight
      //       } else {

      //         return this.trackerPostition
      //       }
      //     } else {
      //       return 0
      //     }
      //   } else {
      //     return 0
      //   }
      // }
    },

    created () {
      window.addEventListener('resize', this.handleResize);
    },

    mounted () {
      this.handleResize()
    },

    unmounted () {
      window.removeEventListener('resize', this.handleResize);
    },

    /*watch: {
      currentPath(to) {
        for(let i=0; i<this.tabs.length; i++) {
          if(to.substring(to.lastIndexOf('/'), to.length) === this.tabs[i].route) {
            this.position = i
          }
          console.log(this.tabs)
          console.log(to)
        }
      }
    }*/

    // watch: {
    //   $router () {
    //     this.handleResize()
    //   }
    // }
  }
</script>

<style lang="scss" scoped>

.bouding-box {
  max-width: 100%;
  display: flex;
  background: $c-background-1;
  border-radius: $radius-m;
  overflow: hidden;
  padding: 5px;
}

.switch {
  display: flex;
  gap: 10px;
  position: relative;
  z-index: 10;
  transition: transform 200ms ease;

  &-button {
    background-color: transparent;
    border: none;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    color: $c-secondary-text;
    z-index: 12;
    margin: 0px;
    height: 45px;
    border-radius: $radius-m;
    transition: color 200ms ease;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 100ms ease;

    &:hover {
      background-color: $c-background-1-hover;
      cursor: pointer;
    }

    &-active {
      color: $c-secondary-bright;//$c-secondary;
      background-color: $c-primary;

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }

  &-tracker {
    content: "";
    position: absolute;
    background-color: $c-primary;
    height: 45px;
    z-index: 11;
    border-radius: $radius-m;
    transition: transform 200ms ease, width 100ms ease;
  }
}


</style>