<template>
  <div class="edit">
    <h1>Model typen</h1>

    <form class="default-form edit-form" @submit.prevent="">

      <div class="default-form-field">

        <div class="default-form-field-types">
          <div class="default-form-field-types-header default-form-field-types-edit-row">
            <span>Naam</span>
            <span></span>
          </div>

          <div v-for="(type, i) in types" :key="i" class="default-form-field-types-edit-row container">
            <input type="text" class="default-form-field-input" :id="'input' + i" v-model.trim="type.name" @keydown.enter="add(i + 1)">
            <button class="default-form-field-button" @mousedown="moveUp(i)" >
              <mdicon name="chevron-up" class="default-form-field-button-icon" size="25" />
            </button>
            <button class="default-form-field-button" @mousedown="moveDown(i)" >
              <mdicon name="chevron-down" class="default-form-field-button-icon" size="25" />
            </button>
            <button class="default-form-field-button" @mousedown="remove(i)" >
              <mdicon name="minus" class="default-form-field-button-icon" size="25" />
            </button>
            <div class="item"><label class="linkTypeText">DEV link</label><input class="linkTypeTextInput default-form-field-input" :id="'input' + i + 'urlD'" v-model.trim="type.urls.dev"></div>
            <div class="item"><label class="linkTypeText">TES link</label><input class="linkTypeTextInput default-form-field-input" :id="'input' + i + 'urlT'" v-model.trim="type.urls.tes"></div>
            <div class="item"><label class="linkTypeText">PRO link</label><input class="linkTypeTextInput default-form-field-input" :id="'input' + i + 'urlP'" v-model.trim="type.urls.pro"></div>
          </div>

          <button class="default-form-field-button" @click="add(types.length)">
            <mdicon name="plus" class="default-form-field-button-icon" size="25" />
          </button>
        </div>
      </div>

      <div class="default-form-button">
        <LoadingButton @click="update()" :loading="loading" :disabled="!formValid">Update</LoadingButton>
      </div>

    </form>
  </div>
</template>

<script>
  import isEqual from 'lodash.isequal'
  import { mapGetters } from 'vuex'
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'

  export default {
    name: "ModelTypes",

    components: {
      LoadingButton
    },

    data () {
      return {
        types: [],
        formValid: false,
        loading: false,
      }
    },

    methods: {
      async get () {
        await this.$store.dispatch('getModelsInfo')
        this.types = JSON.parse(JSON.stringify(this.data))
      },

      async update () {
        this.loading = true

        for (let i = 0; i < this.types.length; i++) {
          this.types[i].index = i
        }

        let remove = []
        let update = []
        let insert = []

        for (let i = 0; i < this.data.length; i++) {

          const keepId = this.types.find((t) => t.id === this.data[i].id)

          if (!keepId) {
            remove.push(this.data[i])
          } else {

            const nameChanged = this.types.find((t) => t.id === this.data[i].id && t !== this.data[i])

            if (nameChanged) {
              update.push(nameChanged)
            }
          }
        }

        for (let i = 0; i < this.types.length; i++) {
          if (!this.types[i].id) {
            insert.push(this.types[i])
          }
        }

        try {
          await this.$store.dispatch('updateTypes', {
            remove: remove,
            update: update,
            insert: insert
          })
        } catch (err) {
          console.log(err)
          this.loading = false
        }

        this.loading = false
        this.types = JSON.parse(JSON.stringify(this.data))
      },
      
      add (i) {

        if (i === this.types.length) {
          this.types.push({
            name: "",
            id: null,
            urls: {
              dev: "",
              tes: "",
              pro: "",
            }
          })
        }

        this.$nextTick(() => {
          document.getElementById('input' + (i)).focus()
        })
      },

      remove (i) {
        this.types.splice(i, 1)
      },

      moveUp (i) {
        if (i > 0) {
          const temp = this.types[i]
          this.types.splice(i, 1)
          this.types.splice(i - 1, 0, temp)
        }
      },

      moveDown (i) {
        if (i < this.types.length - 1) {
          const temp = this.types[i]
          this.types.splice(i, 1)
          this.types.splice(i + 1, 0, temp)
        }
      }
    },

    computed: {
      ...mapGetters({
        data: "getTypes"
      }),
    },

    created () {
      this.get()
    },

    watch: {
      types: {
        deep: true,
        immediate: false,
        handler () {
          if (isEqual(this.types, this.data)) {
            this.formValid = false
          } else {
            this.formValid = true
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.edit {
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  min-width: min(100%, 800px);

  &-form {
    padding: 25px 0px;
    gap: 50px;
    min-width: min(100%, 800px);
  }
}

.linkTypeText {
  text-align: left;
  display: inline-block;
  width: 80px;
  padding-left: 30px;
  font-size: 1rem;
  font-weight: 500;
}

.linkTypeTextVeranda {
  width: 190px;
}

.linkTypeTextInput {
  display: inline-block;
  width: calc(100% - 80px - 40px);
}

.linkTypeTextInputVeranda {
  width: calc(100% - 190px - 40px);
}

.container {
  grid-template-columns: repeat(1, 1fr) 30px 30px 30px;
  grid-template-areas:
    "one two three four"
    "five"
    "six"
    "seven";
}

.item {
  grid-column: 1 / -1; /* Span all columns for items after the first four */
}
</style>