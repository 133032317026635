<template>
  <button class="button-wrapper" @click="$emit('select')">
    <div class="card" :class="selected ? 'card-selected' : '' ">
      <div class="card-info">
        {{namePt1}} <span>{{namePt2}}</span>
      </div>
      <img :src="`${previewsServer + models}previews/models/${data.name}.jpg`" alt="">      
    </div>
  </button>
</template>
<!-- old -->
<!-- <template>
  <button class="button-wrapper" @click="$emit('select')">
    <div class="card" :class="selected ? 'card-selected' : '' ">
      <div class="card-header">
        <img :src="`${previewsServer + models}previews/models/${data.name}.png`" alt="">
      </div>

      <div class="card-info">
        <h3>{{ data.name }}</h3>
      </div>
    </div>
  </button>
</template> -->

<script>
  export default {
    name: "TypeCard",

    props: {
      data: Object,
      selected: Boolean,
    },

    computed: {
      namePt1() {
        return this.data.name.split(' ',1)[0]
        .toUpperCase();
      },
      namePt2() {
        return this.data.name.substring(this.data.name.indexOf(' ') + 1)
        .toUpperCase();
      }
    },    

    data () {
      return {
        models: import.meta.env.VITE_VUE_APP_MODELS_LOCATION,
        previewsServer: import.meta.env.VITE_VUE_APP_PREVIEWS_SERVER,
      }
    },
  }
</script>

<style lang="scss" scoped>

.card {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  outline: 3px solid $c-border-1;
  border-radius: $radius-m;
  transition: outline-color 100ms ease;
  border: none;
  background-color: transparent;
// scroll-margin: 160px;//150px;

  &:hover {
    outline-color: $c-secondary;
    cursor: pointer;
  }

  &-selected {
    outline-color: $c-secondary;
  }

  &-info {
    position: absolute;
    left: 55px;    
    
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1px;
    font-size: 18px;

    background-color: $c-secondary;
    border-radius: $radius-s;
    padding: 0px 3px;  
    margin-top: 8px;    

    & span {
      font-family: 'Oswald', sans-serif;
      font-weight: bold;
    }
  }

  & img {
    min-height: 180px;//150px;
    max-height: 180px;//150px;
    width: 100%;
    object-fit: cover;

    border-radius: $radius-m;
  }  
}

.button-wrapper {
  border: none;
  display: flex;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  margin-bottom: 20px;
  width: 100%;
}

</style>
<!-- old -->
<!-- <style lang="scss" scoped>

.card {
  display: flex;
  flex-grow: 1;
  height: calc(100% - 20px);
  flex-direction: column;
  outline: 3px solid $c-border-1;
  border-radius: $radius-m;
  padding: 10px;
  transition: outline-color 100ms ease;
  border: none;
  background-color: transparent;
  scroll-margin: 150px;

  &:hover {
    outline-color: $c-secondary;
    cursor: pointer;
  }

  &-selected {
    outline-color: $c-secondary;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    height: 150px;

    & img {
      min-height: 150px;
      max-height: 150px;
      width: 100%;
      object-fit: contain;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & h3 {
      font-size: 1rem;
      text-align: left;
      margin: 0px;
    }
  }
}

.button-wrapper {
  border: none;
  display: flex;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  margin-bottom: 20px;
  width: 100%;
}

</style> -->