<template>
  <div class="wall-page">
    <OptionCard
      title="Buitenwand"
      v-if="buitenwandEnabled"
      @updateStatus="updateWallOutsideOn" 
      :status="buitenWandAan"
    >
      <CategorySelector  
        categoryTitle="Type wand"
        optionTitle="Kleur"
        :categorys="categoriesOutside"
        :selectedCategory="selectedCategoryOutside"
        :selectedColor="selectedWallColorOutside.color"
        :status="selectedWall == null ? true : wallOutsideOn"
        show-third-section
        third-section-title="Isolatie"
        :extra-option-is-turned-on="wallIsolatieStatus"
        extra-option-off-graphic="Wandisolatie_lang_UIT.svg"
        extra-option-on-graphic="Wandisolatie_lang_AAN.svg"
        @changeCategory="changeCategoryOutside"
        @changeColor="updateWallColorOutside"
        @changeExtraOption="updateWallIsolatie"
      />
    </OptionCard>

    <OptionCard
      title="Binnenwand" 
      @updateStatus="updateWallInsideOn" 
      :status="binnenWandAan"
    >
      <CategorySelector  
        categoryTitle="Type wand"
        optionTitle="Kleur"
        :categorys="categoriesInside"
        :selectedCategory="selectedCategoryInside"
        :selectedColor="selectedWallColorInside.color"
        :status="selectedWall == null ? true : wallInsideOn"
        show-third-section
        third-section-title="Isolatie"
        :extra-option-is-turned-on="wallIsolatieStatus"
        extra-option-off-graphic="Wandisolatie_lang_UIT.svg"
        extra-option-on-graphic="Wandisolatie_lang_AAN.svg"
        @changeCategory="changeCategoryInside"
        @changeColor="updateWallColorInside"
        @changeExtraOption="updateWallIsolatie"
      />
      <!-- selectedWall == null ? wallIsolatieStatus : selectedWall.settings.heeftIsolatie -->
    </OptionCard>

    <RouterView />
  </div>
</template>

<script>
  import CategorySelector from '@/components/inputs/CategorySelector.vue'
  import OptionCard from '@/components/layout/OptionCard.vue'
  import { mapGetters } from 'vuex'

  export default {
    name: "AddWalls",

    components: {
      CategorySelector,
      OptionCard
    },

    data () {
      return {
        wallTextures: [],
        wallColors: {buiten: [], binnen: []},
        buitenWandAan: false,
        binnenWandAan: false
      }
    },

    methods: {
      updateWallColorInside(color) {
        this.$store.dispatch('updateWallTextureInside', color)
      },

      updateWallColorOutside(color) {
        this.$store.dispatch('updateWallTextureOutside', color)
      },

      updateWallIsolatie(isActive/*object*/) {
        // console.log('triggered method updateWallIsolatie with value',isActive)
        this.$store.dispatch('updateWallIsolatieStatus', isActive)
        if (this.selectedWall !== null) {
          this.selectIsolatieType();
        }
      },
      selectIsolatieType() {
        const isolatieType = this.getIsolatieTypeDefault; //this.getIsolatieTypes[0];
        // console.log('selectIsolatieType',isolatieType);
        this.$store.dispatch('updateWallIsolatieType', isolatieType)
      },

      changeCategoryInside (category) {
        this.$store.dispatch('updateWallCategoryInside', category)
      },

      changeCategoryOutside (category) {
        this.$store.dispatch('updateWallCategoryOutside', category)
      },

      updateWallOutsideOn (status) {
        this.$store.dispatch('updateWallOutsideOn', status)
      },

      updateWallInsideOn (status) {
        this.$store.dispatch('updateWallInsideOn', status)
      },

      updateAvaibleWallColors (newData) {

        // if wall hover leave, check if there is a wall taht is still selected
        if(newData == undefined || newData == null) {
          if(this.selectedWall !== null) {
            this.updateAvaibleWallColors(this.selectedWall)
            return
          }
        }

        if(newData !== undefined && newData !== null) {
          const isTopgevel = newData.isTopgevel
          const isTussenMuur = newData.settings.tussenMuur

          const buitenSelector = isTopgevel ? (isTussenMuur ? "topgevelLinks" : "topgevelBuiten") : (isTussenMuur ? "tussenWandLinks" : "wandBuiten")
          const binnenSelector = isTopgevel ? (isTussenMuur ? "topgevelRechts" : "topgevelBinnen") : (isTussenMuur ? "tussenWandRechts" : "wandBinnen")
          
          console.log(this.availableWallFinishes, buitenSelector, binnenSelector)

          this.wallColors.buiten = this.availableWallFinishes[buitenSelector]
          this.wallColors.binnen = this.availableWallFinishes[binnenSelector]
          
        } else {
          //show all available 

          this.wallColors.buiten = [] 
          this.wallColors.binnen = []
          this.wallTextures.forEach((texture) => {

            const plankType = this.getPlankTypes.find((plankType) => plankType.id === texture.plankType) 

            const isInBuiten = this.getWallFinishes.wandBuiten.includes(texture.id) ||
            this.getWallFinishes.tussenWandLinks.includes(texture.id) ||
            this.getWallFinishes.topgevelBuiten.includes(texture.id) ||
            this.getWallFinishes.topgevelLinks.includes(texture.id)

            const isInBinnen = this.getWallFinishes.wandBinnen.includes(texture.id) ||
            this.getWallFinishes.tussenWandRechts.includes(texture.id) ||
            this.getWallFinishes.topgevelBinnen.includes(texture.id) ||
            this.getWallFinishes.topgevelRechts.includes(texture.id)

            //buiten of links
            if(this.wallColors.buiten.find((plankTypeBuiten) => plankTypeBuiten.id === plankType.id)) {
              if(isInBuiten) {
                const newPlankType = this.wallColors.buiten.find((plankTypeBuiten) => plankTypeBuiten.id === plankType.id)
                newPlankType.colors.push({
                  name: this.getMenuColors.filter(color => color.id === texture.color)[0].name,
                  url: texture.url,
                  previewURL: texture.url,
                  hex: this.getMenuColors.filter(color => color.id === texture.color)[0].hexCode,
                  id: this.getMenuColors.filter(color => color.id === texture.color)[0].id,
                  plankType: plankType.id,
                  open: this.getMenuColors.filter(color => color.id === texture.color)[0].open
                })
              }
            } else {
              if(isInBuiten) {
                const newPlankType = {
                  id: plankType.id,
                  type: plankType.name,
                  typeImage: plankType.image,
                  connection: plankType.connection,
                  colors: []
                }
                newPlankType.colors.push({
                  name: this.getMenuColors.filter(color => color.id === texture.color)[0].name,
                  url: texture.url,
                  previewURL: texture.url,
                  hex: this.getMenuColors.filter(color => color.id === texture.color)[0].hexCode,
                  id: this.getMenuColors.filter(color => color.id === texture.color)[0].id,
                  plankType: plankType.id,
                  open: this.getMenuColors.filter(color => color.id === texture.color)[0].open
                })
                this.wallColors.buiten.push(newPlankType)
              }
            }

            //binnen of rechts
            if(this.wallColors.binnen.find((plankTypeBinnen) => plankTypeBinnen.id === plankType.id)) {
              if(isInBinnen) {
                const newPlankType = this.wallColors.binnen.find((plankTypeBinnen) => plankTypeBinnen.id === plankType.id)
                newPlankType.colors.push({
                  name: this.getMenuColors.filter(color => color.id === texture.color)[0].name,
                  url: texture.url,
                  previewURL: texture.url,
                  hex: this.getMenuColors.filter(color => color.id === texture.color)[0].hexCode,
                  id: this.getMenuColors.filter(color => color.id === texture.color)[0].id,
                  plankType: plankType.id,
                  open: this.getMenuColors.filter(color => color.id === texture.color)[0].open
                })
              }
            } else {
              if(isInBinnen) {
                const newPlankType = {
                  id: plankType.id,
                  type: plankType.name,
                  typeImage: plankType.image,
                  connection: plankType.connection,
                  colors: []
                }
                newPlankType.colors.push({
                  name: this.getMenuColors.filter(color => color.id === texture.color)[0].name,
                  url: texture.url,
                  previewURL: texture.url,
                  hex: this.getMenuColors.filter(color => color.id === texture.color)[0].hexCode,
                  id: this.getMenuColors.filter(color => color.id === texture.color)[0].id,
                  plankType: plankType.id,
                  open: this.getMenuColors.filter(color => color.id === texture.color)[0].open
                })
                this.wallColors.binnen.push(newPlankType)
              }
            }

          })
          
        }

        if(!this.wallColors.buiten.find((category) => category.type === this.selectedWallColorOutside.type)) {
          this.changeCategoryOutside(this.wallColors.buiten[0])
          this.updateWallColorOutside(this.wallColors.buiten[0].colors[0])
        }

        if(!this.wallColors.binnen.find((category) => category.type === this.selectedWallColorInside.type)) {
          this.changeCategoryInside(this.wallColors.binnen[0])
          this.updateWallColorInside(this.wallColors.binnen[0].colors[0])
        }
      }
    },

    computed: {
      ...mapGetters({
        selectedWallColorInside: 'getSelectedWallColorInside',
        selectedWallColorOutside: 'getSelectedWallColorOutside',
        wallInsideOn: 'getWallInsideOn',
        wallOutsideOn: 'getWallOutsideOn',
        wallIsolatieStatus: 'getWallIsolatieStatus',
        getTextures: 'getTextures',
        getWallFinishes: 'getWallFinishes',
        selectedWall: 'getSelectedWall',
        selectedWallObject: 'getSelectedWallObject',
        hoveringWall: 'getHoveringWall',
        getPlankTypes: 'getPlankTypes',
        getIsolatieTypes: 'getIsolatieTypes',
        getIsolatieTypeDefault: 'getIsolatieTypeDefault',
        isolatieTypeFromSelectedWall: 'getIsolatieTypeFromSelectedWall', // usage not yet implemented
        getMenuColors: 'getMenuColors',
        availableWallFinishes: 'getAvailableWallFinishes',
        config: 'getConfig'
      }),

      buitenwandEnabled () {
        const verandaEnabled = this.config.afmetingen.veranda?.enabled
        const shouldBeHidden =
          this.selectedWall != null &&
          this.selectedWallObject &&
          !this.selectedWallObject.alignsXAxis &&
          !this.selectedWallObject.alignedRotation &&
          verandaEnabled
        return !shouldBeHidden
      },

      selectedCategoryInside () {
        return this.wallColors.binnen.find((category) => category.type === this.selectedWallColorInside.type)
      },

      selectedCategoryOutside () {
        return this.wallColors.buiten.find((category) => category.type === this.selectedWallColorOutside.type)
      },

      categoriesOutside () {
        if (this.selectedWall && this.selectedWallObject?.isBorstwering) {
          return this.wallColors.buiten.filter((category) => !category.colors.some((color) => color.open))
        } else {
          return this.wallColors.buiten
        }
      },

      categoriesInside () {
        if (this.selectedWall && this.selectedWallObject?.isBorstwering) {
          return this.wallColors.binnen.filter((category) => !category.colors.some((color) => color.open))
        } else {
          return this.wallColors.binnen
        }
      },
    },

    created () {
      this.wallTextures = this.getTextures.filter((texture) => texture.textureType === "62cd857a7c20bc17b85af2cd")
      this.$store.dispatch('getWallColors')
      this.updateAvaibleWallColors()
    },

    mounted() {
      this.wallSettingsFetcher = setInterval(() => {
        const wallSettings = this.selectedWall?.settings
        if (wallSettings !== null && wallSettings !== undefined) {
          this.binnenWandAan = wallSettings.binnenMuur
          this.buitenWandAan = wallSettings.buitenMuur
        } else {
          this.binnenWandAan = true
          this.buitenWandAan = true
        }
      }, 100)
    },

    unmounted() {
      clearInterval(this.wallSettingsFetcher)
    },

    watch: {
      selectedWall: {
        immediate: false,
        handler (newData) {
          console.log('newData',newData)
          this.updateAvaibleWallColors(newData)
        }
      },

      hoveringWall: {
        immediate: false,
        handler (newData) {
          this.updateAvaibleWallColors(newData)
          console.log(newData)
        }
      },
    }


  }
</script>

<style lang="scss" scoped>

.wall-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
  min-width: 100%;

  &-preview {
    padding-bottom: 25px;
  }
}

</style>