<script>
import ColorSelectorPreview from '@/components/inputs/ColorSelectorPreview.vue'
// import ExpansionCard from '@/components/layout/ExpansionCard.vue'
// import OptionCard from '@/components/inputs/OptionCard.vue'
// import ComponentCardSimple from '@/components/inputs/ComponentCardSimple.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
      // ComponentCardSimple,
      ColorSelectorPreview,
      // ExpansionCard,
      // OptionCard
    },
    data() {
      return {
        roofFinishes: [],
        knownSetNames: [
          'Beton pan',
          'Felsplaten-set',
          'profielplaat',
          'Keramische pan',
          'Vlakke pan',
        ]
      } 
    },

    computed: {
      ...mapGetters({
        selectedDakafwerking: 'getSelectedDakafwerking',
        dakafwerking: 'getRoofFinishes',
        getTextures: 'getTextures',
        selectedFrameColor: 'getSelectedFrameColor',
        // getInsulation: 'getInsulation',
        // selectedInsulation: 'getSelectedInsulation',
        typeId: 'getTypeId',
      }),
    },

    async created () {
      this.refreshRoofFinishes()
    },

    mounted() {
      this.refreshRoofFinishes()
    },

    methods: {
      addGroupNames(data) {
        const dataReadyForGrouping = []
        const chooseSetName = (inputString) => {
          let chosenSetName = '';
          let searchResult = this.knownSetNames.find(i => inputString.includes(i));
          if (searchResult) chosenSetName = searchResult;
          else chosenSetName = inputString;
          return chosenSetName;
        }

        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          const chosenSetName = chooseSetName(item.name);
          if (dataReadyForGrouping.indexOf(item => item.group === chosenSetName) === -1) {
            dataReadyForGrouping.push({
              group: chosenSetName,
              ...item
            });
          } else {
            dataReadyForGrouping.find(item => item.group === chosenSetName).push(item);
          }
        }
        return dataReadyForGrouping;
      },
      createGroupedData(preparedData) {
        let endResult = []
        for (let i = 0; i < this.knownSetNames.length; i++) {
          const setName = this.knownSetNames[i];
          const searchResults = preparedData.filter(item => item.group === setName);
          let preparedObject = {...searchResults[0]}
          // console.log('preparedObject',preparedObject)
          preparedObject.colors = searchResults.map(item => item);
          endResult.push(preparedObject);
        }
        return endResult;
      },

      changeSelectedComponent(color) {
        if (color.name !== this.selectedDakafwerking.name) {
          this.$store.dispatch('updateDakafwerking', color)
        }
      },
      async refreshRoofFinishes() {
        // if (!this.insulation?.length) {
        //   await this.$store.dispatch('getInsulation')
        //   this.insulation = this.getInsulation
        // }
        await this.$store.dispatch('getTextures')
        const filteredTextures1 = this.getTextures.filter(texture => texture.textureType === "62e3e09a990280c3716422dd")
        console.log(this.dakafwerking)
        const filteredTextures = filteredTextures1.filter(texture => this.dakafwerking.includes(texture.id))
        this.roofFinishes = []
        for(let i=0; i<filteredTextures.length; i++) {
          // added line to filter on color aand loop trough all colors to pick geen and the selected color from the frame color
          // be sure the data like scaling is in the texture in the database, other wise it will not work and you will get no texture
          if(filteredTextures[i].name.toLowerCase() === "geen" ? (this.selectedFrameColor.colorId === filteredTextures[i].color)  : true) {
            this.roofFinishes.push({
              name: filteredTextures[i].name,
              previewURL: filteredTextures[i].url,
              id: filteredTextures[i].id,
              scalingX: filteredTextures[i].scalingX,
              scalingY: filteredTextures[i].scalingY,
              emissive: filteredTextures[i].emissive,
              emissiveColor: filteredTextures[i].emissiveColor,
              metalness: filteredTextures[i].metalness,
            })
          }
        }
        // console.log(this.roofFinishes)
        // added line to sort, so that "Geen" is always on top
        this.roofFinishes.sort((a, b) => {
          if (a.name === "Geen") return -1;
          if (b.name === "Geen") return 1;
          return 0;
        });
      },

      updateDakafwerking(color) {
        if (color.name !== this.selectedDakafwerking.name) {
          this.$store.dispatch('updateDakafwerking', color)
        }
      },
    },    
}
</script>
<template>
  <!-- <div class="roofassets-page">
    <ComponentCardSimple
      v-for="(component, index) in createGroupedData(addGroupNames(roofFinishes))" 
      :key="index" 
      ref="component"
      :data="component" 
      :isselected="selectedDakafwerking.id === component.id"
      @select="changeSelectedComponent(component, index)"
      />
  </div> -->
  <!-- old -->
  <!-- <ExpansionCard title="Dakafwerking"> -->
  <section id="mainsection">
    <ColorSelectorPreview 
      :options="roofFinishes" 
      :selected="selectedDakafwerking" 
      class="roof-page-preview" 
      @change="updateDakafwerking" 
    />
  </section>
  <!-- </ExpansionCard> -->

  <!-- <div v-if="selectedDakafwerking.id === '62e7c729de63cb0cfe032453'">
    <OptionCard 
      v-for="(ins, index) in insulation" 
      :key="index"
      :data="ins" 
      :selected="selectedInsulation === ins.code"
      @select="setSelectedInsulation(ins)"
    />
  </div> -->
</template>
<style lang="scss" scoped>
#mainsection {
  margin: 1rem;
}
.roofassets-page {
  display: grid;
  padding: 25px 0px;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
}
</style>