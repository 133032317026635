<script>
import { mapGetters } from "vuex";
// import ExpansionCard from '@/components/layout/ExpansionCard.vue'
// import OptionCard from '@/components/inputs/OptionCard.vue'
// import OptionCardColor from '@/components/inputs/OptionCardColor.vue'
import ComponentCardSimple from '@/components/inputs/ComponentCardSimple.vue'

export default {
    name: "Lichtstraten",

    components: {
      // ExpansionCard,
      // OptionCard,
      // OptionCardColor,
      ComponentCardSimple
    },

    data () {
      return {
        lichtstraten: null,
        selectedLichtstraat: null,
        // TEMP - GET VIA COMPUTED INSTEAD
        selectedComponentSettings: {},
        // TEMP
      }
    },

    computed: {
      ...mapGetters({
        options: 'getOptions',
        config: 'getConfig',
        selectedLichtstraat3D: 'getSelectedLichtstraat',
        selectedlichtstraat3DCode: 'getSelectedLichtstraatCode'
      }),
    },

    created () {
      this.getOptions()
      setTimeout(() => {
        console.log(this.config)
      }, 5000);
    },

    methods: {
      async getOptions () {
        if (this.options.length === 0) {
          await this.$store.dispatch('getOptions')
        }

        this.parseOptions()
        // this.basis = this.filterCategory(this.parsedOptions, 'Fundering')
        // this.led = this.filterCategory(this.parsedOptions, 'LED')
        this.lichtstraten = this.filterCategory(this.parsedOptions, 'Lichtstraat')

        this.lichtstraten.sort(function(a, b) {
          if (a.name.slice(0,13) === b.name.slice(0,13)) {
            // get sizes in correct order even if article codes are technically in wrong order
            return a.size.slice(0,1) - b.size.slice(0,1)
          }
        })

        this.selectedLichtstraat = this.lichtstraten[0];
        this.selectedComponentSettings = {
          ...this.selectedComponentSettings,
          color: this.selectedLichtstraat.colors[0]
        }
      },

      parseOptions () {
        let options = []

        for (let i = 0; i < this.options.length; i++) {

          let match

          if (this.options[i].colors.length > 0) {
            for (let x = 0; x < this.options[i].colors.length; x++) {
              if (!match) {
                match = options.find((o) => o.code === this.options[i].colors[x].code)
              }
            }

            const firstColor = this.options.find((o) => o.code === this.options[i].colors[0].code)

            if (!match) {
              options.push({
                ...this.options[i],
                colorPicked: 0,
                counter: 0,
                shortName: firstColor ? firstColor.shortName : this.options[i].shortName,
              })
            }
          } else {
            options.push({
              ...this.options[i],
              counter: 0,
            })
          }
        }

        const filteredConfigOptions = this.config.opties.filter((o) => o.category === 'Lichtstraat')
        // for (let i = 0; i < this.config.opties.length; i++) {
        for (let i = 0; i < filteredConfigOptions.length; i++) {
          const option = filteredConfigOptions[i]

          let matchIndex
          let colorIndex
          let match

          for (let x = 0; x < options.length; x++) {
            match = options[x].colors.findIndex((o) => o.code === option.code)

            if (match >= 0) {
              colorIndex = match
              matchIndex = x
            }
          }

          const firstColor = this.options.find((o) => o.code === options[matchIndex].colors[colorIndex].code)

          options[matchIndex].colorPicked = colorIndex
          options[matchIndex].counter = option.aantal
          options[matchIndex].shortName = firstColor.shortName
        }

        this.parsedOptions = options
      },

      filterCategory (options, category) {
        return options.filter((o) => o.keyWords.some((k) => k === category))
      },

      changeSelectedComponent(component, index) {
        const comp = this.$refs.component[index];
        const colorIndex = comp.selectedColorIndex;
        
        this.selectColor({ code: component.code, colorIndex: colorIndex, cardIndex: index });
      },

      // selectColor ({ code, colorIndex, cardIndex, type }) {
      selectColor ({ code, colorIndex, cardIndex }) {
        // const counter = this['lichtstraten'][cardIndex].counter

        const foundItem = this.options.find((o) => o.code === code)

        this['lichtstraten'][cardIndex].colorPicked = colorIndex
        // this['lichtstraten'][cardIndex].counter = counter
        this['lichtstraten'][cardIndex].shortName = foundItem.shortName
        // console.log("this['lichtstraten'][cardIndex]",this['lichtstraten'][cardIndex])

        // set to data
        this.selectedLichtstraat = this.options.find(o => o.code === this['lichtstraten'][cardIndex].code)//this['lichtstraten'][cardIndex]
        const LichtstraatColor = this.selectedLichtstraat.colors[colorIndex]
        this.selectedComponentSettings = {
          ...this.selectedComponentSettings,
          color: LichtstraatColor
        }
        this.$store.dispatch('addLichtstraat', {lichtstraat: this.selectedLichtstraat, color: LichtstraatColor})
      },

      updateCounter ({ counter, index }) {

        this['lichtstraten'][index].counter = counter

        this.updateOptions(index)
      },

      updateOptions (index) {

        const option = this['lichtstraten'][index]
        // console.log('updateOptions option',option)

        let code
        
        // if (type === 'basis') {
        //   code = option.code
        // } else {
        //   code = option.colors[option.colorPicked].code
        // }
        code = option.colors[option.colorPicked].code

        const configOptions = JSON.parse(JSON.stringify(this.config.opties))

        const indexExistingOption = configOptions.findIndex((o) => o.code === code)

        if (indexExistingOption >= 0) {

          configOptions.splice(indexExistingOption, 1)
        }

        // if (option.counter > 0) {

        //   configOptions.push({
        //     code: code,
        //     aantal: option.counter,
        //   })
        // }

        configOptions.push({code: code, category: 'Lichtstraat'})

        this.$store.dispatch('updateConfigOptions', configOptions)
      },

      // updateBasisOption () {
      //   this.$store.dispatch('updateFoundation', !this.config.fundering)
      // },

      // changeDakgoot (data) {
      //   this.$store.dispatch('updateDakgoot', data)
      // }
    }
}
</script>
<template>
  <!-- <ExpansionCard title="Lichtstraten" class="options-cards" v-if="config.availableOptions.lichtstraten"> -->
  <div class="lichtstraten-page">
    <ComponentCardSimple
      v-for="(component, index) in lichtstraten" 
      :key="index" 
      ref="component"
      :data="component" 
      :datatype="'lichtstraten'"
      :isselected="selectedLichtstraat3D && component.colors.find((c) => c.code === selectedlichtstraat3DCode)"
      :scolor="{code: selectedlichtstraat3DCode}"
      @select="changeSelectedComponent(component, index)"
    />
  </div>
  <!-- <section id="mainsection">
    old
    <OptionCardColor 
      v-for="(option, i) in lichtstraten" 
      :key="i" 
      :option="option" 
      type="lichtstraten"
      :index="i"
      @select="selectColor"
      @updateCounter="updateCounter"
    />
  </section> -->
  <!-- </ExpansionCard> -->
</template>
<style lang="scss" scoped>
#mainsection {
  margin: 1rem;
}
.lichtstraten-page {
  display: grid;
  padding: 25px 0px;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
}
</style>