<template>
  <div class="edit-plankTypes">
    <h1>Plank Types</h1>

    <form class="default-form edit-plankTypes-form" @submit.prevent="">

          <input class="default-form-field-input" v-model="filter">

          <div class="plankTypeblock-container">
            <div v-for="(plankType, i) in filteredPlankTypes" :key="i" class="plankTypeblock">
              <div class="plankTypeblock-card">
                <img :src="plankType.tempImageStorage ? plankType.tempImageStorage : `${previewsServer}/textures/${plankType.image}`" :title="`${plankType.image}`" class="">
                <div class="changeImageContainer">
                  <input type="file" class="fileInput" @input="replaceTextureFile($event, i)">
                  <mdicon class="changeImageContainer-icon" name="upload" size="75"></mdicon>
                </div>
                <input type="text" class="default-form-field-input" :id="'input' + i" v-model.trim="plankType.name">
                <select name="plankType" id="plankType" v-model="plankType.connection" ref="plankType">
                  <option value="horizontal">horizontal</option>
                  <option value="vertical">vertical</option>
                </select>
                <button @click="remove(i)" class="delete">verwijder</button>
              </div>
            </div>


            <div class="plankTypeblock">
              <div class="plankTypeblock-card addButton">
                <input type="file" class="fileInput" ref="fileInput" @input="addPlankTypeFile()">
                <mdicon name="plus" class="default-form-field-button-icon addButton-icon" size="225"/>
              </div>
            </div>
          </div>

          <!--<button class="default-form-field-button" @click="addColor()">
            <mdicon name="plus" class="default-form-field-button-icon" size="25" />
          </button>-->
          

      <div class="default-form-button">
        <LoadingButton @click="updatePlankTypes()" :loading="loading" :disabled="!formValid">Update</LoadingButton>
      </div>

    </form>
  </div>
</template>

<script>
  import isEqual from 'lodash.isequal'
  import { mapGetters } from 'vuex'
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'

  export default {
    name: "PlankTypes",

    components: {
      LoadingButton
    },

    data () {
      return {
        filter: '',
        plankTypes: null,
        formValid: false,
        loading: false,
        previewsServer: import.meta.env.VITE_VUE_APP_SERVER,
      }
    },

    methods: {
      async getPlankTypes () {
        await this.$store.dispatch('getPlankTypes')
        this.plankTypes = JSON.parse(JSON.stringify(this.plankTypesM))
      },

      async updatePlankTypes () {
        this.loading = true

        let remove = []
        let update = []
        let insert = []

        for (let i = 0; i < this.plankTypesM.length; i++) {
          const keepId = this.plankTypes.find((t) => t.id === this.plankTypesM[i].id)

          if (!keepId) {
            remove.push(this.plankTypesM[i])
          } else {
            const nameChanged = this.plankTypes.find((t) => t.id === this.plankTypesM[i].id && t.name !== this.plankTypesM[i].name)
            const connectionChanged = this.plankTypes.find((t) => t.id === this.plankTypesM[i].id && t.connection !== this.plankTypesM[i].connection)
            const urlChanged = this.plankTypes[i].tempImageStorage ? this.plankTypes[i] : null
            if (nameChanged) {
              update.push(nameChanged)
            } else if (connectionChanged) {
              update.push(connectionChanged)
            } else if (urlChanged) {
              update.push(urlChanged)
            }
          }
        }

        for (let i = 0; i < this.plankTypes.length; i++) {
          if (!this.plankTypes[i].id) {
            insert.push(this.plankTypes[i])
          }
        }

        try {
          await this.$store.dispatch('updatePlankTypes', {
            remove: remove,
            update: update,
            insert: insert
          })

        } catch (err) {
          console.log(err)
          this.loading = false
        }

        this.loading = false
        
        this.plankTypes = JSON.parse(JSON.stringify(this.plankTypesM))
        
        
        
      },

      async addPlankTypeFile() {
        if(this.$refs.fileInput.files.length < 1) {return}
        this.plankTypes.push({
          id: null,
          name: "",
          url: "",
          tempImageStorage: null
        })

        const reader = new FileReader();
        reader.readAsDataURL(this.$refs.fileInput.files[0]);
        reader.onload = () => {
          this.plankTypes[this.plankTypes.length-1].tempImageStorage = reader.result
          this.$refs.fileInput.value = null
        };

        this.$nextTick(() => {
          document.getElementById('input' + (this.plankTypes.length-1)).focus()
        })
      },

      async replaceTextureFile(input, i) {
        if(input.target.files.length < 1) {return}
        this.plankTypes[i].tempImageStorage = input.target.files[0]

        const reader = new FileReader();
        reader.readAsDataURL(input.target.files[0]);
        reader.onload = () => {
          this.plankTypes[i].tempImageStorage = reader.result
          const plankTypesBackup = this.plankTypes
          this.plankTypes = null
          this.plankTypes = plankTypesBackup
          input.target.value = null
        };
      },

      remove (i) {
        this.plankTypes.splice(i, 1)
      }
    
    },

    computed: {
      ...mapGetters({
        plankTypesM: "getPlankTypes",
      }),

      filteredPlankTypes: function () {
        if(this.plankTypes != null) {
          return this.plankTypes.filter(i => i.name.toLowerCase().includes(this.filter.toLowerCase()))
        } else {
          return []
        }
      },
    },

    created () {
      this.getPlankTypes()
    },

    watch: {
      plankTypes: {
        deep: true,
        immediate: false,
        handler () {
          if (isEqual(this.plankTypes, this.plankTypesM)) {
            this.formValid = false
          } else {
            this.formValid = true
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

.edit-plankTypes {
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  min-width: min(100%, 800px);

  &-form {
    padding: 25px 0px;
    gap: 50px;
    min-width: min(100%, 800px);
    width: min(100%, 1200px);

    &-display {
      border-radius: $radius-s;
      outline: 3px solid $c-background-1;
      outline-offset: -1px;
      height: 29px;
      align-self: center;
    }
  }
}

.plankTypeblock-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.plankTypeblock {
  flex: 0 0 33.33333%;
  height: 400px;
  margin-bottom: 0px;
  
  &-card {
    position: relative;
    margin: 0px auto;
    border: 3px solid #E3E6E9;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: $radius-s;

    img {
      width:95%;
      display: block;
      margin: 0px auto;
      margin-top: 10px;
      max-height: 65%;
      min-height: 65%;
      object-fit: contain;
    }

    input, select {
      width: 95%;
      margin-top:10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}

.addButton {
  position: relative;
  background-color: #E3E6E9;
  outline: 3px solid #00000000;
  -webkit-transition: outline-color .15s ease-out;
     -moz-transition: outline-color .15s ease-out;
       -o-transition: outline-color .15s ease-out;
          transition: outline-color .15s ease-out;

  &-icon {
    margin-top: 77.5px;
    display: block;
  }

  &:hover {
    outline: 3px solid $c-secondary;
    cursor: pointer;
  }
}

.fileInput {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  margin-top: 0px !important;
  left: 0px;
  opacity: 0%;
  z-index: 10;
  cursor: pointer;
}

.delete {
  background-color: $c-error;
  color: $c-primary-text;
  font-size: 0.95rem;
  margin-top: 10px;
  border-radius: $radius-s;
  padding: 4px 20px;
  border: none;
  cursor: pointer;
}

.changeImageContainer {
  position: absolute;
  background-color: rgba(70, 70, 70, 0.5);
  width: 100px;
  height: 100px;
  border-radius: 50px;
  top: calc((65% - 100px) / 2);
  left: calc(50% - 50px);
  cursor: pointer !important;

  &-icon {
    position: relative;
    display: block;
    color: #ffffff;
    margin-top: 12.5px;
  }
}

input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
}

</style>