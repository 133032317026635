<template>
  <div class="edit">
    <h1>Materials</h1>

    <form class="default-form edit-form" @submit.prevent="">

      <div class="default-form-field">
        <div class="default-form-field-types row">
          <div class="form-group">
            <label class="form-label">Lichtstraat Border Margin</label>
            <input type="number" class="default-form-field-input" v-model.number="globalSettings.lichtstraatBorderMargin">
          </div>
          <div class="form-group">
            <label class="form-label">Lichtstraat Between Margin</label>
            <input type="number" class="default-form-field-input" v-model.number="globalSettings.lichtstraatBetweenMargin">
          </div>
          <div class="form-group">
            <label class="form-label">Lichtstraat Map Color</label>
            <input type="text" class="default-form-field-input" v-model.trim="globalSettings.lichtstraatMapColor">
          </div>
          <div class="form-group">
            <label class="form-label">Lichtstraat Map Line Color</label>
            <input type="text" class="default-form-field-input" v-model.trim="globalSettings.lichtstraatMapLineColor">
          </div>
          <div class="form-group">
            <label class="form-label">Lichtstraat Map Line Color Selected</label>
            <input type="text" class="default-form-field-input" v-model.trim="globalSettings.lichtstraatMapLineColorSelected">
          </div>
        </div>
      </div>

      <div class="default-form-button">
        <LoadingButton @click="update()" :loading="loading" :disabled="!formValid">Update</LoadingButton>
      </div>

    </form>
  </div>
</template>

<script>
import isEqual from 'lodash.isequal'
import { mapGetters } from 'vuex'
import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'

export default {
  name: "Materials",

  components: {
    LoadingButton
  },

  data () {
    return {
      globalSettings: {},
      formValid: false,
      loading: false,
    }
  },

  methods: {
    async get () {
      await this.$store.dispatch('getGlobalSettings')
      this.globalSettings = JSON.parse(JSON.stringify(this.data))
    },

    async update () {
      this.loading = true

      try {
        await this.$store.dispatch('updateGlobalSettings', this.globalSettings)
      } catch (err) {
        console.log(err)
        this.loading = false
      }

      this.loading = false
      this.globalSettings = JSON.parse(JSON.stringify(this.data))
    },
  },

  computed: {
    ...mapGetters({
      data: "getGlobalSettings",
    })
  },

  created () {
    this.get()
  },

  watch: {
    globalSettings: {
      deep: true,
      immediate: false,
      handler () {
        if (isEqual(this.globalSettings, this.data)) {
          this.formValid = false
        } else {
          this.formValid = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit {
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  min-width: min(100%, 800px);

  &-form {
    padding: 25px 0px;
    gap: 50px;
    max-width: 800px; /* Set max-width to 800px */
    width: 100%; /* Ensure it takes full width up to 800px */
  }
}

.default-form-field-types {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto; /* Ensure it takes the height of its content */
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-label {
  width: 300px; /* Set width to 300px */
  text-align: left;
  padding-right: 10px;
}

.default-form-field-input {
  flex: 1;
  padding: 5px;
  box-sizing: border-box;
}
</style>