<script>
import { mapGetters } from "vuex";
// import OptionCardColor from '@/components/inputs/OptionCardColor.vue'
import SpotjesCard from '@/components/inputs/SpotjesCard.vue'
export default {
    components: {
      // OptionCardColor,
      SpotjesCard
    },

    data () {
      return {
        parsedOptions: null,
        spotjesArray: null,
      }
    },

    computed: {
      ...mapGetters({
        options: 'getOptions',
        config: 'getConfig', 
      }),
      filteredOptions () {
        return this.options.filter((o) => o.keyWords.some((k) => k === 'LED'))
      }
    },

    created () {
      this.getOptions()
      setTimeout(() => {
        // console.log(this.config)
      }, 5000);
    },

    methods: {
      async getOptions () {
        // if (this.filteredOptions.length === 0) {
          await this.$store.dispatch('getOptions')
        // }

        this.parseOptions()

        // this.basis = this.filterCategory(this.parsedOptions, 'Fundering')
        // this.lichtstraten = this.filterCategory(this.parsedOptions, 'Lichtstraat')
        // this.spotjesArray = this.filterCategory(this.parsedOptions, 'LED')

        // filter moved to a computed and used within parseOptions
        this.spotjesArray = this.parsedOptions;

        // const ledCopy = JSON.parse(JSON.stringify(this.spotjesArray))
        // for (let i = 0; i < ledCopy.length; i++) {
        //   const ledArticle = ledCopy[i];
        //   for (let j = 0; j < ledArticle.colors.length; j++) {

        //     if (!ledArticle.colors[j].counter) {
        //       console.log('set fresh counter')
        //       this.spotjesArray[i].colors[j].counter = 0;
        //     } else {
        //       console.log('use existing')
        //       this.spotjesArray[i].colors[j].counter = ledArticle.colors[j].counter;
        //     }
        //   }
        // }

        // console.log('this.spotjesArray',this.spotjesArray)
      },

      parseOptions () {
        let newOptions = []

        for (let i = 0; i < this.filteredOptions.length; i++) {

          let match

          if (this.filteredOptions[i].colors.length > 0) {
            for (let x = 0; x < this.filteredOptions[i].colors.length; x++) {
              if (!match) {
                match = newOptions.find((o) => o.code === this.filteredOptions[i].colors[x].code)
              }
            }

            // turned off because shortName is not in use
            // const firstColor = this.filteredOptions.find((o) => o.code === this.filteredOptions[i]?.colors[0].code)

            // console.log('parseOptions this.filteredOptions[i]',this.filteredOptions[i]);
            if (!match) {
              newOptions.push({
                ...this.filteredOptions[i],
                // colorPicked: 0, // not used; needs cleanup
                // counter: 0, // not used; needs cleanup
                // shortName: firstColor ? firstColor.shortName : this.filteredOptions[i].shortName,
              })
            }
          } else {
            newOptions.push({
              ...this.filteredOptions[i],
              counter: 0,
            })
          }
        }

        const filteredConfigOptions = this.config.opties.filter((o) => o.category === 'LED')
        // console.log('filteredConfigOptions',filteredConfigOptions)
        // for (let i = 0; i < this.config.opties.length; i++) {
        for (let i = 0; i < filteredConfigOptions.length; i++) {
          const option = filteredConfigOptions[i]
          // console.log('option',option);

          let matchIndex
          // let colorIndex
          let match

          for (let x = 0; x < newOptions.length; x++) {
            match = newOptions[x].colors.findIndex((o) => o.code === option.code)

            if (match >= 0) {
              // colorIndex = match
              matchIndex = x
            }
          }

          // turned off because shortName is not in use
          // const firstColor = this.filteredOptions.find((o) => o.code === newOptions[matchIndex].colors[colorIndex].code)

          // newOptions[matchIndex].colorPicked = colorIndex
          // newOptions[matchIndex].counter = option.aantal
          // newOptions[matchIndex].shortName = firstColor?.shortName
          // set counters in colors array
          if (option.editedColors) {
            // console.log('option.editedColors',option.editedColors)
            newOptions[matchIndex].colors = JSON.parse(JSON.stringify(option.editedColors))
            // for (let i = 0; i < option.editedColors.length; i++) {
            //   const cnt = option.editedColors[i].counter;
            //   console.log(newOptions[matchIndex].code, cnt)
            //   if (cnt) {
            //     newOptions[matchIndex].colors[i].counter = cnt;
            //   } else {
            //     // set fresh counter
            //     newOptions[matchIndex].colors[i].counter = 0;
            //   }
            // }
          }
        }
        // console.log('newOptions',newOptions)
        this.parsedOptions = newOptions
      },

      // no longer in use
      // filterCategory (options, category) {
      //   return options.filter((o) => o.keyWords.some((k) => k === category))
      // },

      updateArticle({ counter, cardIndex, colorIndex, code }) {
        // console.log('Spotjes.vue updateArticle',counter, cardIndex, colorIndex, code)

        // update the right counter in the known colors array
        this.spotjesArray[cardIndex].colors[colorIndex].counter = counter;
        // also do this for options

        // get configOptions
        const configOptions = JSON.parse(JSON.stringify(this.config.opties))

        // replace existing entry
        const indexExistingOption = configOptions.findIndex((o) => o.code === code);
        if (indexExistingOption >= 0) configOptions.splice(indexExistingOption, 1);

        // if (counter > 0) {
          configOptions.push({
            code: code,
            aantal: counter,
            editedColors: JSON.parse(JSON.stringify(this.spotjesArray[cardIndex].colors)),
            category: 'LED',
          })
        // }
        
        // console.log('update store with configOptions:',configOptions)
        this.$store.dispatch('updateConfigOptions', configOptions)
      }
    }
  }
</script>
<template>
  <div class="spotjes-page">
    <SpotjesCard
      v-for="(component, i) in spotjesArray" 
      :key="i" 
      ref="component"
      :card-index="i"
      :spotje="component" 
      @update-article="updateArticle"
    />
  </div>
</template>
<style lang="scss" scoped>
.spotjes-page {
  display: grid;
  padding: 25px 0px;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
  width: 100%;
}
</style>