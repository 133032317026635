<template>
  <div class="sldoors-page">
    <ComponentCard 
      v-for="(component, index) in categoryFilter(wallComponents)" 
      :key="index" 
      ref="component"
      :data="component" 
      :selected="component.code === selectedComponent.code"
      :cselected="getComponentSelected(component)"
      :scolor="selectedComponentSettings.color"
      :soption="selectedComponentSettings.option"
      :svariant="selectedComponentSettings.variant"
      :sglasType="selectedComponentSettings.glasType"      
      @select="changeSelectedComponent(component, index)"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ComponentCard from '@/components/inputs/ComponentCard.vue'

  export default {
    name: "SteellookDoors",

    components: {
      ComponentCard
    },

    data () {
      return {
        // selectedComponent: 0,
      }
    },

    methods: {
      categoryFilter(array){
        return array.filter(
          item => item.category === 'Steellook Deur');
      },      
      changeSelectedComponent (component, index, shouldNotReplaceModel) {
        const comp = this.$refs.component[index];
        const sglasType = comp.selectedGlasType;
        const sVariant = comp.selectedVariant;
        const sColor = comp.selectedColor;
        const sOption = comp.selectedOption;
        const sLastCode = comp.lastCodeSelection;

        const settings = {
          glasType: component.glasTypes && component.glasTypes.length>1 ? component.glasTypes[sglasType] : component.glasTypes ? component.glasTypes[0] : null,
          variant: component.variants && component.variants.length>1 ? component.variants[sVariant] : component.variants ? component.variants[0] : null,
          color: component.colors.length>1 ? component.colors[sColor] : component.colors[0],
          option: component.options.length>1 ? component.options[sOption] : component.options[0],
          lastCode: sLastCode
        }        
        // console.log('test sldoors settings',settings)
        this.$store.dispatch('changeSelectedWallComponent', {component: component, settings: settings})

        if(this.selectedElement !== null && !shouldNotReplaceModel) {

          const muur = this.getMuurInstances.find((muur) => muur.group.uuid === this.selectedElement.parent.parent.uuid)

          this.$store.dispatch('changeElement', {
            obj: this.selectedElement, 
            wall: null, 
            component: this.selectedComponent, 
            settings: this.selectedComponentSettings,
            Ewall: muur,
            Elocation: this.selectedElement.metaData.ModelPosition,
            Eside: this.selectedElement.metaData.Side
          })
        }
      },

      async getComponents () {
        if (this.wallComponents.length === 0) {
          let categories = ['Deur', 'Raam', 'Steellook Deur', 'Steellook Raam', 'Schuifwand']
          let trueOnline = true

          // await this.$store.dispatch('getComponents', {categories: categories, trueOnline: trueOnline})
          await Promise.all([
            this.$store.dispatch('getComponents', {categories: categories, trueOnline: trueOnline}),
            this.$store.dispatch('getGlassVariantsMain', {categories: categories, trueOnline: trueOnline})
          ])          
        }
      },

      getComponentSelected (component) {
        if(this.selectedElement === null) {return false}

        const muur = this.getMuurInstances.find((muur) => muur.group.uuid === this.selectedElement.parent.parent.uuid)
        const muurMetadata = muur.wallButtonIndex[this.selectedElement.metaData.ModelPosition]

        return component === muurMetadata.component
      }
    },

    computed: {
      ...mapGetters({
        wallComponents: 'getInWallComponents',
        selectedComponent: 'getSelectedWallComponent',
        selectedComponentSettings: 'getSelectedWallComponentSettings',
        selectedElement: 'getSelectedElement',
        getMuurInstances: 'getMuurInstances',
      }),
    },

    async mounted () {
      // if(this.wallComponents.length == 0) {
      //   await this.getComponents()
      //   this.changeSelectedComponent(this.categoryFilter(this.wallComponents)[0], 0, true);
      // } else {
      //   this.changeSelectedComponent(this.categoryFilter(this.wallComponents)[0], 0, true);
      // }
      // Fix issue where selected element would jump to top of list instead of correct component
      if(this.wallComponents.length == 0) await this.getComponents();
      if (this.selectedElement == null) {
        this.changeSelectedComponent(this.categoryFilter(this.wallComponents)[0], 0, true);
      }      
    }
  }
</script>

<style lang="scss" scoped>

.sldoors-page {
  display: grid;
  padding: 25px 0px;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
}

</style>