<template>
  <div class="steel-page">
    <ComponentCard 
      v-for="(component, index) in filteredComponents"
      :key="index"
      ref="component"
      :data="component"
      :selected="selected ? component.code === selected.code : false"
      :cselected="getComponentSelected(component)"
      :wSelected="selectedWallData"
      :scolor="selectedSettings.color"
      :soption="selectedSettings.option"
      :svariant="selectedSettings.variant"
      :sglasType="selectedSettings.glasType"
      @select="changeSelectedComponent(component, index)"
      />
      <!-- @select="changeSelectedComponent"
    /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ComponentCard from "@/components/inputs/ComponentCard.vue";

export default {
  components: {
    ComponentCard,
  },
  name: "Steellook",

  computed: {
    ...mapGetters({
      components: "getSteellookComponents",
      selected: 'getSelectedWallComponent',
      selectedSettings: 'getSelectedWallComponentSettings',
      // TODO change names above so they're the same as in SteellookDoors.vue etc
      selectedElement: 'getSelectedElement',
      selectedWall: 'getSelectedWall',
      getMuurInstances: 'getMuurInstances',      
    }),
    selectedWallData() {
      return this.getMuurInstances.find((muur) => muur.group.uuid === this.selectedWall?.wall?.parent?.parent?.uuid)
    },
    filteredComponents() {
      return this.components.filter(c => c.category === "Steellook")
    }
  },

  methods: {

    // changeSelectedComponent (data) {
    // changeSelectedComponent (component, index) {
    changeSelectedComponent (component, index, shouldNotReplaceModel) {        
      // const component = data.component
      // const colorIndex = data.colorIndex
      // const optionIndex = data.optionIndex
      // const variantIndex = data.variantIndex; // feature 1394

      const sglasType = this.$refs.component[index].selectedGlasType;
      const sVariant = this.$refs.component[index].selectedVariant;
      const sColor = this.$refs.component[index].selectedColor;
      const sOption = this.$refs.component[index].selectedOption      
      const sLastCode = this.$refs.component[index].lastCodeSelection;
      // console.log('component.keyWords',component.keyWords)
      // console.log('changeSelectedComponent steellook log', component)

      // const settings = {
      //   color: colorIndex ? component.colors[colorIndex] : null,
      //   option: optionIndex ? component.options[optionIndex] : null,
      //   variant: variantIndex ? component.variants[variantIndex] : null,
      // }
      const settings = {
          glasType: component.glasTypes && component.glasTypes.length>1 ? component.glasTypes[sglasType] : component.glasTypes ? component.glasTypes[0] : null,
          variant: component.variants && component.variants.length>1 ? component.variants[sVariant] : component.variants ? component.variants[0] : null,
          color: component.colors.length>1 ? component.colors[sColor] : component.colors[0],
          option: component.options.length>1 ? component.options[sOption] : component.options[0],
          lastCode: sLastCode,
          // old behavior was to always set BetonBand visibility to true in steellookComponent.js
          // hasBetonBand: component.shortName?.includes('schuifdeur') ? false : true // task 1455
          // hasBetonBand depends on keyword "noBetonband"
          hasBetonBand: this.checkKeyWords(component.keyWords) // task 1455
      }      

      this.$store.dispatch('changeSelectedWallComponent', {component: component, settings: settings})

      // console.log('sl muur test',this.getMuurInstances[0],this.selectedWall,this.getMuurInstances
      // .find((muurInstance) => muurInstance.group.uuid === this.selectedWall.wall.parent.parent.uuid))
      // console.log('sl this.selectedWall',this.selectedWall);
      // console.log('sl this.selectedElement',this.selectedElement,shouldNotReplaceModel)
      if(this.selectedElement !== null && !shouldNotReplaceModel) {
        const muur = this.getMuurInstances.find((muur) => muur.group.uuid === this.selectedElement.parent.parent.uuid)

        this.$store.dispatch('changeElement', {
          obj: this.selectedElement, 
          wall: null, 
          component: this.selected, 
          settings: this.selectedSettings,
          Ewall: muur,
          Elocation: this.selectedElement.metaData.ModelPosition,
          Eside: this.selectedElement.metaData.Side
        })
      }

      // Select steellook that was placed as a wall
      const selectedSteellook = this.getMuurInstances.find((muurInstance) => muurInstance.group.uuid === this.selectedWall?.wall?.parent.parent.uuid)

      // isSteellook and code checks
      if(selectedSteellook?.isSteellook && !shouldNotReplaceModel){// && this.isSameSet()){

        this.$store.dispatch('changeSelectedSteellookModel', {
          // obj: this.selectedElement, 
          // wall: this.selectedWall, 
          wall: selectedSteellook,
          component: this.selected, 
          settings: this.selectedSettings,
          // Ewall: muur,
          // Elocation: this.selectedElement.metaData.ModelPosition,
          // Eside: this.selectedElement.metaData.Side
        })        
      }
    },
    checkKeyWords(array){
      // betonband visibility should be set to false for the following key word:
      // "noBetonband"
      // where any value other than -1 means that the key word has been found
      if (array && array.indexOf("noBetonband") > -1) { return false; } 
      else return true;
    },

    async getComponents () {
      if (this.components.length === 0) {
        let categories = ['Steellook']
        let trueOnline = true

        // this.$store.dispatch('getSteellookComponents', {categories: categories, trueOnline: trueOnline})
        await Promise.all([
          this.$store.dispatch('getSteellookComponents', {categories: categories, trueOnline: trueOnline}),
          this.$store.dispatch('getGlassVariantsMain', {categories: categories, trueOnline: trueOnline})
        ])
      }
    },

    getComponentSelected (component) {
      if(this.selectedElement === null) {return false}
      const muur = this.getMuurInstances.find((muur) => muur.group.uuid === this.selectedElement.parent.parent.uuid)
      const muurMetadata = muur.wallButtonIndex[this.selectedElement.metaData.ModelPosition]
      return component === muurMetadata.component
    },
    
    // isSameSet(){
    //   return true;
    //   // TODO implement logic that prevents switching to a much larger steellook model
    // }
  },

  // mounted() {
  //   if(this.components.length == 0) {
  //     this.getComponents()
  //   }
  async mounted () {
    if(this.components.length == 0) await this.getComponents();
    // } else {
    //   // console.log(this.components)
    //   // this.changeSelectedComponent({component: this.components[0], colorIndex: 0, optionIndex: 0})
    //   this.changeSelectedComponent({
    //     component: this.components[0], 
    //     colorIndex: 0, 
    //     optionIndex: 0, 
    //     variantIndex: 0,
    //     glasTypeIndex: 0
    //   })
    // }
    if (this.selectedElement == null && !this.selectedWallData?.isSteellook) {
      this.changeSelectedComponent(this.filteredComponents[0], 0, true);
    }
  },
};
</script>

<style lang="scss" scoped>

.steel-page {
  display: grid;
  padding: 25px 0px;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
}

</style>