<template>
<div class="edit">

    <div class="confirmation" v-if="needsToConfirmDatabaseMigration || needsToConfirmBackupRestore">
      <div class="modal">
        <span class="modal-message" v-if="needsToConfirmDatabaseMigration">Weet u zeker dat u de {{source}} database over wilt zetten naar de {{destination}}?</span>
        <span class="modal-message" v-if="needsToConfirmBackupRestore">Weet u zeker dat u de backup terug wilt zetten naar de {{selectedDatabase}}?</span>

        <div class="modal-actions">
          <button class="modal-actions-confirm" v-if="needsToConfirmDatabaseMigration" @click="migrateDatabase(); needsToConfirmDatabaseMigration=false">Ja, kopieer!</button>
          <button class="modal-actions-confirm" v-if="needsToConfirmBackupRestore" @click="restoreBackup(); needsToConfirmBackupRestore=false">Ja, restore!</button>
          <button class="modal-actions-cancel" @click="needsToConfirmDatabaseMigration = false">Nee, annuleer!</button>
        </div>  
      </div>
    </div>

    <h1>Beheer gebruikers</h1>

    <form class="default-form edit-form" @submit.prevent="">

      <div class="default-form-field">

        <div class="default-form-field-types">

          <div v-for="user, i in users" :key="'user'+i" class="overview-list-section-card">
            <h3 class="overview-list-section-card-main-title">{{ user.email }}</h3>
            <div class="adminbox">
              <input class="checkbox" type="checkbox" :id="`admin_${i}`" name="trim-checkbox" v-model="user.superUser">
              <label :for="`admin_${i}`" class="checkbox-label checkbox-label-manage" style="margin-right: 0px">
                <mdicon name="check-bold" class="checkbox-label-icon" :class="user.superUser ? 'checkbox-label-icon-active' : ''" size="15" />
              </label>
              <span class="overview-list-section-card-main-admin">Admin</span>
            </div>
            <span class="overview-list-section-card-main-description">Machtigingen:</span>
            <br>
            <div style="display: grid; grid-template-columns: 1fr 1fr 1fr">
              <div v-for="page, j in pages" :key="'machtiging'+i+'_'+j" class="machtiging">
                <input class="checkbox" type="checkbox" :id="'machtiging'+i+'_'+j" name="trim-checkbox" :checked="user.collectionsAllowedToEdit.includes(page.route)" @change="updateCATE(user, page)">
                <label :for="'machtiging'+i+'_'+j" class="checkbox-label checkbox-label-manage" style="margin-right: 0px">
                  <mdicon name="check-bold" class="checkbox-label-icon" :class="user.collectionsAllowedToEdit.includes(page.route) ? 'checkbox-label-icon-active' : ''" size="15" />
                </label>
                <span class="overview-list-section-card-main-admin">{{page.title}}</span>
              </div>
            </div>
          </div>
          
        </div>
      </div>

      <div class="default-form-button">
        <LoadingButton @click="update()" :loading="loading" :disabled="!formValid">Update users</LoadingButton>
      </div>

    </form>

    <h1>Beheer database</h1>

    <form class="default-form edit-form" @submit.prevent="">

      <div class="default-form-field">

        <div class="default-form-field-types" style="text-align: left;">

          <div>
            
            <p class="database-migration-text">kopieer de database van</p>
            <select v-model="source" class="default-form-field-input" style="margin-right: 10px;">
              <option value="DEV">DEV</option>
              <option value="TEST">TEST</option>
            </select>
            <p class="database-migration-text">naar</p>
            <select v-model="destination" class="default-form-field-input" style="margin-right: 10px;">
              <option value="TEST">TEST</option>
              <option value="PRO">PRO</option>
            </select>
            <button class="button" @click="needsToConfirmDatabaseMigration = true">MIGRATE DATABASE!</button>
          
          </div>

          <div>
            
            <p class="database-migration-text">Maak handmatige backup van</p>
            <select v-model="backup" class="default-form-field-input" style="margin-right: 10px;">
              <option value="dev">DEV</option>
              <option value="test">TEST</option>
              <option value="pro">PRO</option>
            </select>
            <button class="button" @click="$store.dispatch('createBackup', backup)">CREATE BACKUP!</button>
          
          </div>


          <div class="backup-list-header">
            <p class="database-migration-text">Selecteer database:</p>
            <select v-model="selectedDatabase" class="default-form-field-input" style="margin-right: 10px;" @change="$store.dispatch('getBackups', selectedDatabase); selectedBackup = ''">
              <option value="dev">DEV</option>
              <option value="test">TEST</option>
              <option value="pro">PRO</option>
            </select>
          </div>
          <div class="backup-list">

            <div v-for="backup, i in getBackups" :key="'backup'+i" class="overview-list-section-card-backup" :class="selectedBackup === backup.filename ? 'selected-backup' : ''" style="margin-top: 5px; margin-bottom: 10px" @click="selectedBackup = backup.filename">
              <p class="overview-list-section-card-backup-text">{{backup.dateString}}</p>
            </div>

          </div>

          <div class="backup-list-footer">
            <input class="checkbox" type="checkbox" id="shouldBackupDatabaseBeforeRestore" name="trim-checkbox" v-model="shouldBackupDatabaseBeforeRestore">
            <label for="shouldBackupDatabaseBeforeRestore" class="checkbox-label checkbox-label-manage" style="margin-right: 0px">
              <mdicon name="check-bold" class="checkbox-label-icon" :class="shouldBackupDatabaseBeforeRestore ? 'checkbox-label-icon-active' : ''" size="15" />
            </label>
            <span class="overview-list-section-card-main-admin">maakt eerst een back-up van de huidige database</span><br>
            <button class="button" @click="initRestore()" style="margin-top: 10px;">Zet geselecteerde backup terug naar {{selectedDatabase}}!</button>
          </div>
          

        </div>

      </div>

    </form>


  </div>
</template>

<script>
  import isEqual from 'lodash.isequal'
  import { mapGetters } from 'vuex'
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'

  export default {
    name: "ModelTypes",

    components: {
      LoadingButton
    },

    data () {
      return {
        users: [],
        user: {},
        pages: [
          {
            title: "Artikelen",
            route: "/cms/components",
          },
          {
            title: "Modellen",
            route: "/cms/models",
          },
          {
            title: "Model categorieën",
            route: "/cms/model-categories",
          },
          {
            title: "Model typen",
            route: "/cms/types",
          },
          {
            title: "Menu kleuren",
            route: "/cms/menu-colors",
          },
          {
            title: "Tags overzicht",
            route: "/cms/component-tags",
          },
          {
            title: "Textures",
            route: "/cms/textures",
          },
          {
            title: "Materials",
            route: "/cms/materials",
          },
          {
            title: "Plank types",
            route: "/cms/plank-types",
          },
          {
            title: "Menu glastypes",
            route: "/cms/glas-types",
          },          
          {
            title: "Menu infobox settings",
            route: "/cms/infoboxsettings",
          },
          {
            title: "Menu ui settings",
            route: "/cms/uisettings",
          },
          {
            title: "globale instellingen",
            route: "/cms/globalsettings"
          },
          {
            title: "Berging types",
            route: "/cms/berging-types",
          },
          {
            title: "Schuifwand types",
            route: "/cms/schuifwand-types",
          },
          {
            title: "Isolatie types",
            route: "/cms/isolatie-types",
          },
        ],
        formValid: false,
        loading: false,
        source: "DEV",
        destination: "TEST",
        backup: "dev",
        selectedDatabase: "dev",
        shouldBackupDatabaseBeforeRestore: true,
        selectedBackup: "",
        needsToConfirmDatabaseMigration: false,
        needsToConfirmBackupRestore : false
      }
    },

    methods: {
      async get () {
        await this.$store.dispatch('getUsers')
        this.users = JSON.parse(JSON.stringify(this.getUsers))

        await this.$store.dispatch('getUser')
        this.user = JSON.parse(JSON.stringify(this.getUser))

        console.log(this.user)
      },

      async update () {
        this.loading = true

        let update = []

        for (let i = 0; i < this.getUsers.length; i++) {

          const adminChanged = this.users.find((t) => t.id === this.getUsers[i].id && t.superUser !== this.getUsers[i].superUser)
          const collectionsChanged = this.users.find((t) => t.id === this.getUsers[i].id && t.collectionsAllowedToEdit !== this.getUsers[i].collectionsAllowedToEdit)

          if (adminChanged) {
            update.push(adminChanged)
          }

          if (collectionsChanged) {
            update.push(collectionsChanged)
          }
        }


        try {
          await this.$store.dispatch('updateUsers', {
            update: update,
          })
        } catch (err) {
          console.log(err)
          this.loading = false
        }

        this.loading = false
        console.log(JSON.parse(JSON.stringify(this.getUsers)))
        this.users = JSON.parse(JSON.stringify(this.getUsers))
      },

      updateCATE (user, page) {
        if(user.collectionsAllowedToEdit.includes(page.route)) {
          user.collectionsAllowedToEdit = user.collectionsAllowedToEdit.filter((route) => route !== page.route)
        } else {
          user.collectionsAllowedToEdit.push(page.route)
        }
        console.log(JSON.parse(JSON.stringify(user.collectionsAllowedToEdit)))
      },

      migrateDatabase() {
        this.$store.dispatch('migrateDatabase', {source: this.source, destination: this.destination})
        this.needsToConfirmDatabaseMigration = false
      },

      initRestore() {
        if(this.selectedBackup) {
          this.needsToConfirmBackupRestore = true
        } else {
          alert('Selecteer een backup om terug te zetten!')
        }
      },

      restoreBackup() {
        this.$store.dispatch('restoreBackup', {database: this.selectedDatabase, filename: this.selectedBackup, shouldBackupDatabaseBeforeRestore: this.shouldBackupDatabaseBeforeRestore})
      },
      
    },

    computed: {
      ...mapGetters({
        getUsers: "getUsers",
        getUser: "getUser",
        getBackups: "getBackups"
      }),
    },

    created () {
      this.get()

      this.$store.dispatch('getBackups', this.selectedDatabase)

      document.addEventListener('keydown', e => {
        if (e.ctrlKey && e.key === 's') {
          e.preventDefault();
          this.update()
        }
      });
    },

    watch: {
      users: {
        deep: true,
        immediate: false,
        handler () {
          if (isEqual(this.users, this.getUsers)) {
            this.formValid = false
          } else {
            this.formValid = true
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.edit {
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  min-width: min(100%, 800px);

  &-form {
    padding: 25px 0px;
    gap: 50px;
    min-width: min(100%, 800px);
    max-width: min(100%, 800px);
  }
}


.overview {
  display: flex;
  padding: 25px;
  justify-content: center;

  &-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: min(100%, 800px);
    max-width: min(100%, 800px);

    &-section {
      display: flex;
      text-decoration: none;
      min-width: 100%;
      color: black;

      &-card {
        text-align: left;
        min-width: calc(100% - 30px);
        padding: 10px 15px;
        border-radius: $radius-m-1;
        box-shadow: $shadow-m-0;
        background-color: white;
        transition: background-color 125ms ease;

        &:hover {
          background-color: hsl(0, 0%, 0%, 0.08);
        }

        &-main {

          &-title {
            margin: 0px;
          }

          &-description {
            font-weight: 500;
            display: inline-block;
            margin-top: 10px;
          }

          &-admin {
            font-weight: 500;
            margin-left: 10px;
          }
        }

        &-backup {
          cursor: pointer;
          text-align: left;
          min-width: calc(100% - 30px);
          padding: 10px 15px;
          border-radius: $radius-m-1;
          box-shadow: $shadow-m-0;
          background-color: white;
          transition: background-color 125ms ease;

          &:hover {
            background-color: $c-secondary;
          }

          &-text {
            margin-top: 6px;
            margin-bottom: 6px;
          }
        }
      }
    }
  }
}

.checkbox-label-manage {
  display: inline-block;
  span {
    margin-top: -3px;
    display: block;
  }
}

.adminbox {
  margin-top: 10px;
}

.machtiging {
  display: inline-block;
  padding: 5px;
  padding-right: 20px;
}

.button {
  display: inline-block;
  align-content: center;
  justify-content: center;
  border-radius: $radius-s;
  background-color: $c-secondary;
  color: $c-primary;
  border: none;
  padding: 5px 12px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 100ms ease, color 100ms ease;

  &:disabled {
    background-color: $c-background-1;
    cursor: default;
  }

  &-loading {
    background-color: $c-primary;
  }

}

.database-migration-text {
  display: inline-block;
  margin-right: 10px;
  font-size: 0.95rem;
  font-weight: 500;
}

.confirmation {
  position: fixed;
  width: 100%;
  height: calc(100% + 85px);
  z-index: 100;
  background-color: #00000088;
  top: -85px;
}

.modal {
  width: 450px;
  margin: 0px auto;
  margin-top: calc(50vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 20px;
  gap: 25px;
  box-shadow: 
    0px 7px 8px -4px adjust-color($color: black, $alpha: -0.8),
    0px 12px 17px 2px adjust-color($color: black, $alpha: -0.86),
    0px 5px 22px 4px adjust-color($color: black, $alpha: -0.88);
  background-color: white;

  &-message {
    width: 400px;
    font-size: 1.3rem;
    padding: 10px 10px;
    
    @media screen and (max-width: 850px) {
      width: 250px;
    }
  }

  &-actions {
    display: flex;
    gap: 25px;

    &-confirm {
      background-color: $c-secondary;
      @include standard-button;

      &:hover {
        background-color: $c-secondary;
      }
    }

    &-cancel {
      background-color: #dd2727;
      @include standard-button;

      &:hover {
        background-color: #dd2727;
      }
    }
  }
}

.selected-backup {
  background-color: $c-secondary;
}

.backup-list {
  border: 1px solid black;
  padding: 10px;
  overflow-y: scroll;
  max-height: 500px;
  height: 400px;
  margin-top: -10px;

  &-header {
    border: 1px solid black;
    border-bottom: none;
    padding-left: 25px;
  }

  &-footer {
    border: 1px solid black;
    border-top: none;
    padding-left: 10px;
    margin-top: -10px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
}
</style>