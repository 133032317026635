<template>
  <div class="counter">
    <button  class="counter-button" @click="counter > min ? ( counter = counter - step) : ''">
      <mdicon name="minus" class="counter-button-icon" size="20px"/>
    </button>

    <input 
      type="number" 
      class="counter-input" 
      v-model="counter" 
      :min="min" 
      :max="max" 
      :step="step"
    >
    
    <div class="counter-slot">
      <slot></slot>
    </div>

    <button class="counter-button" @click="counter < max ? (counter = counter + step) : ''">
      <mdicon name="plus" class="counter-button-icon" size="20px"/>
    </button>
  </div>
</template>

<script>
  export default {
    name: "CounterButton",

    props: {
      value: {
        typeof: Number,
        default: 0,
      },
      min: {
        typeof: Number,
        default: 0,
      },
      max: {
        typeof: Number,
        default: 10,
      },
      step: {
        typeof: Number,
        default: 1,
      },
    },

    data () {
      return {
        counter: 0,
      }
    },

    methods: {
      updateCounter() {
        this.$emit('update', parseInt(this.counter))
      },
    },

    created () {
      this.counter = this.value
      this.counterStep = this.step
    },

    watch: {
      value () {
        this.counter = this.value >= this.min || this.value <= this.max ? this.value : this.counter
        // this.updateCounter()
      },

      counter () {
        this.updateCounter()
      }
    },
  }
</script>

<style lang="scss" scoped>

.counter {
  display: flex;
  border-radius: $radius-s;
  overflow: hidden;
  outline: 3px solid $c-border-1;
  outline-offset: -2px;
  height: 36px;

  &-button {
    border-radius: 0px;
    padding: 5px 8px;
    margin: 0px;
    outline: none;
    list-style: none;
    border: none;
    background: none;
    cursor: pointer;
    transition: background-color 100ms ease;
    background: $c-background-1;

    &:hover {
    }
  }

  &-slot {
    display: flex;
  }

  &-input {
    flex: 1;
    display: flex;
    text-align: center;
    width: 100%;
    border-style: none;
    text-decoration: none;
    border-radius: 0px;
    margin: 0px;
    padding: 10px;
    font-size: 1.15rem;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    & {
      -moz-appearance: textfield;
    }

    &:focus {
      border: none;
      margin: 0px;
      outline: none;
      resize: none;
    }
  }
}

</style>