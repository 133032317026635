<template>
  <div class="overview">
    <div class="overview-list">
      <router-link class="overview-list-section" v-for="(section, i) in sections" :key="i" :to="section.route">
        <div class="overview-list-section-card">
          <div class="overview-list-section-card-main">
            <h3 class="overview-list-section-card-main-title">{{ section.title }}</h3>
            <span class="overview-list-section-card-main-description">{{ section.description }}</span>
          </div>

          <div class="overview-list-section-card-action">
            <mdicon name="chevron-right" class="overview-list-section-card-action-icon" size="25" />
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "CMSOverview",

    data () {
      return {
        allSections: [
          {
            title: "AFAS artikelen",
            description: "Aanpassen van artikelen uit afas.",
            route: "/cms/components",
          },
          {
            title: "Modellen",
            description: "Aanpassen van de beschikbare modellen.",
            route: "/cms/models",
          },
          {
            title: "Model categorieën",
            description: "Aanpassen van de model categorieën.",
            route: "/cms/model-categories",
          },
          {
            title: "Model typen",
            description: "Aanpassen van de model typen.",
            route: "/cms/types",
          },
          {
            title: "Menu kleuren",
            description: "Aanpassen van kleur opties voor menu items.",
            route: "/cms/menu-colors",
          },
          {
            title: "Tags overzicht",
            description: "Bekijk tag statestieken.",
            route: "/cms/component-tags",
          },
          {
            title: "Textures",
            description: "Wijzig texture afbeeldingen.",
            route: "/cms/textures",
          },
          {
            title: "Materials",
            description: "Wijzig materials.",
            route: "/cms/materials",
          },
          {
            title: "Plank types",
            description: "Wijzig plank types.",
            route: "/cms/plank-types",
          },
          {
            title: "Menu glastypes",
            description: "Aanpassen van de verschillende type glas.",
            route: "/cms/glas-types",
          },
          {
            title: "BergingTypes",
            description: "Wijzig berging types.",
            route: "/cms/berging-types",
          },
          {
            title: "Schuifwand types",
            description: "Wijzig schuifwand types.",
            route: "/cms/schuifwand-types",
          },
          {
            title: "Isolatie types",
            description: "Wijzig isolatie types.",
            route: "/cms/isolatie-types",
          },
          {
            title: "Menu infobox settings",
            description: "Aanpassen van infobox settings voor een Model.",
            route: "/cms/infoboxsettings",
          },
          {
            title: "Menu ui settings",
            description: "Aanpassen van ui settings",
            route: "/cms/uisettings"
          },
          {
            title: "globale instellingen",
            description: "Aanpassen van instellingen voor de gehele configurator",
            route: "/cms/globalsettings"
          },
          {
            title: "Manage",
            requiresSuperUser: true,
            description: "Beheer users en databases",
            route: "/cms/manage",
          },
        ],
        sections: [],
        user: {}
      }
    },

    async created () {
      await this.$store.dispatch('getUser')
      this.user = JSON.parse(JSON.stringify(this.getUser))

      this.allSections.forEach(section => {
        if(section.requiresSuperUser) {
          if(this.user.superUser) {
            this.sections.push(section)
          }
        } else {
          if(this.user.collectionsAllowedToEdit.includes(section.route)) {
            this.sections.push(section)
          } 
        }
      })
    },

    computed: {
      ...mapGetters({
        getUser: "getUser",
      }),
    },
  }
</script>

<style lang="scss" scoped>

.overview {
  display: flex;
  padding: 25px;
  justify-content: center;

  &-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: min(100%, 800px);
    max-width: min(100%, 800px);

    &-section {
      display: flex;
      text-decoration: none;
      min-width: 100%;
      color: black;

      &-card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: calc(100% - 30px);
        padding: 10px 15px;
        border-radius: $radius-m-1;
        box-shadow: $shadow-m-0;
        background-color: white;
        transition: background-color 125ms ease;

        &:hover {
          background-color: hsl(0, 0%, 0%, 0.08);
        }

        &-main {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;

          &-title {
            margin: 0px;
          }

          &-description {
            color: $c-sub-text;
            font-weight: 500;
          }
        }
      }
    }
  }
}

</style>