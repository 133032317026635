<template>
  <div class="expand">
    <div class="expand-header" @click="$emit('updateStatus', !status)">
      <h3>{{ title }}</h3>

      <!-- <button class="expand-header-button"  :class="expanded ? 'expand-header-button-open' : ''" >
        <mdicon class="expand-header-button-icon" name="chevron-down" size="30" />
      </button> -->

      <!--<input type="checkbox" class="checkboxA" id="expand" name="expand" v-model="expanded">-->
      <!--<toggle-button id="expand" name="expand" v-model="expanded" :color="{checked: '#D4F028', unchecked: '#E3E6E9', disabled: '#CCCCCC'}" :width="40"/>-->
      <!--<label for="expand" class="labelA">-->
        <mdicon :name="expanded ? 'toggle-switch' : 'toggle-switch-off'" class="labelA-icon" :class="expanded ? 'labelA-icon-active' : 'labelA-icon'" size="45" v-on:click="expanded = !expanded"/>
      <!--</label>-->
    </div>

    <!--<transition name="expand" style=""
      enter-active-class="enter-active"
      leave-active-class="leave-active"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave"
    >-->
      <div class="expand-content" v-show="true">
        <slot></slot>
      </div>
    <!--</transition>-->
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      status: Boolean
    },

    data () {
      return {
        expanded: true,
      }
    },

    methods: {
      beforeEnter(element) {
        requestAnimationFrame(() => {
          if (!element.style.height) {
            element.style.height = '0px';
          }

          element.style.display = null;
        });
      },

      enter(element) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            element.style.height = `${element.scrollHeight}px`;
          });
        });
      },

      afterEnter(element) {
        element.style.height = null;
      },

      beforeLeave(element) {
        requestAnimationFrame(() => {
          if (!element.style.height) {
            element.style.height = `${element.offsetHeight}px`;
          }
        });
      },

      leave(element) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            element.style.height = '0px';
          });
        });
      },

      afterLeave(element) {
        element.style.height = null;
      },
    },

    created () {
      this.expanded = this.status
    },

    mounted () {
      this.expanded = this.status
    },

    watch: {
      status () {
        this.expanded = this.status  
      },

      $router () {
        this.expanded = this.status  
      }
    }
  }
</script>

<style lang="scss" scoped>

.expand {
  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 2px solid $c-border-1;
    cursor: pointer;

    & h3 {
      margin: 0px;
      font-size: 1.5rem;
    }

    &-button {
      border: none;
      background-color: transparent;
      transform: rotate(0deg);
      transition: transform 150ms ease;
      border-radius: 50%;

      &-open {
        transform: rotate(180deg);
      }
    }
  }
}

.enter-active,
.leave-active {
  overflow: hidden;
  transition: height 300ms ease;
}

.checkboxA {
  opacity: 0;
  display: none;
}

.labelA {
  background-color: white;
  border: 2px solid $c-primary;;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  border-radius: $radius-s;
  position: relative;
  margin: 7px;
  height: 20px;
  width: 20px;
  transition: background-color 100ms ease;
  cursor: pointer;
  pointer-events: none;

  &-icon {
    height: 35px;
    color: $c-background-1;
    margin-left: 1px;
    margin-bottom: 2px;
    transition: color 200ms ease;

    &-active {
      color: $c-secondary;
    }
  }
}

.checkboxA:checked + .labelA {
  background-color: $c-primary;
}
</style>