<template>
  <div class="color-page">
    <ExpansionCard title="Frame en dakbeschot">
      <ColorSelectorPreview :options="frameColors" :selected="selectedFrameColor" class="color-page-preview" @change="updateFrameTexture" />
    </ExpansionCard>

    <ExpansionCard title="Boeidelen" v-if="afmetingen.boeideel.enabled && afmetingen.wall.position !== 'buiten'">
      <ColorSelectorPreview :options="afmetingen.omlijstingPalen ? frameColors : boardColors" :selected="selectedBoardColors" class="color-page-preview" @change="updateBoardTexture"  />
    </ExpansionCard>

    <ExpansionCard title="Hoekprofielen" v-if="afmetingen.wall.position === 'buiten'">
      <ColorSelectorPreview :options="hoekprofielcolors" :selected="selectedHoekprofielColor" class="color-page-preview" @change="updateHoekprofielTexture"  />
    </ExpansionCard>

    <ExpansionCard title="Trim" v-if="config.typeId === '629a060f2fbe252c87361bdc' || config.typeId === '629a060f2fbe252c87361be0'">
      <ColorSelectorPreview :options="textures.filter(color => selectedSize.trim.color.includes(color.id))" :selected="selectedTrimColor" class="color-page-preview" @change="updateTrimColor"  />
    </ExpansionCard>
  </div>
</template>

<script>
  import ColorSelectorPreview from '@/components/inputs/ColorSelectorPreview.vue'
  import ExpansionCard from '@/components/layout/ExpansionCard.vue'
   import { mapGetters } from 'vuex'

  export default {
    components: {
      ColorSelectorPreview,
      ExpansionCard
    },

    data () {
      return {
      }
    },

    methods: {
      updateFrameTexture(color, canChangeOther) {
        this.$store.dispatch('updateFrameTexture', color)
        if(this.afmetingen.omlijstingPalen && !canChangeOther) {
          this.updateBoardTexture(color, true)
        }
        console.log(this.afmetingen)
      },

      updateBoardTexture (color, canChangeOther) {
        this.$store.dispatch('updateBoeideelTexture', color)
        if(this.afmetingen.omlijstingPalen && !canChangeOther) {
          this.updateFrameTexture(color, true)
        }
      },

      updateHoekprofielTexture (color) {
        this.$store.dispatch('updateHoekprofielTexture', color)
      },

      updateTrimColor (color) {
        this.$store.dispatch('updateTrimColor', color)
      }
    },

    computed: {
      ...mapGetters({
        selectedFrameColor: 'getSelectedFrameColor',
        frameColors: 'getFrameColors',
        selectedBoardColors: 'getSelectedBoeideelColor',
        boardColors: 'getBoeideelColors',
        selectedHoekprofielColor: 'getSelectedHoekprofielColor',
        hoekprofielcolors: 'getHoekprofielColors',
        menuColors: 'getMenuColors',
        textures: 'getTextures',
        selectedSize: 'getSelectedSizes',
        selectedTrimColor: 'getSelectedTrimColor',
        afmetingen: 'getSelectedSizes',
        config: 'getConfig'
      })
    },

    created () {
      this.$store.dispatch('getWallColors')

      console.log(this.menuColors,this.selectedSize,this.config)
    }
  }
</script>

<style lang="scss" scoped>

.color-page-preview {
  padding-bottom: 25px;
}

</style>