<script>
import { mapGetters } from 'vuex'
import OptionCard from '@/components/inputs/OptionCard.vue'

export default {
    components: {
      OptionCard
    },

    data() {
      return {
        insulation: [],
      }
    },

    computed: {
      ...mapGetters({
        config: 'getConfig',
        getInsulation: 'getInsulation',
        selectedInsulation: 'getSelectedInsulation',
      }),
    },

    async created () {
      this.refresh()
    },

    mounted() {
      this.refresh()
    },

    methods: {
      async refresh() {
        if (!this.insulation?.length) {
          await this.$store.dispatch('getInsulation')
          this.insulation = this.getInsulation
        }
      },
      setSelectedInsulation(insulation) {
        if(this.selectedInsulation !== insulation.code) {
          this.$store.commit('setSelectedInsulation', insulation.code)
          this.$store.commit('setConfigInsulation', insulation.id)
        } else {
          this.$store.commit('setSelectedInsulation', '')
          this.$store.commit('setConfigInsulation', '')
        }
      },
    },
}
</script>
<template>
  <!-- <div v-if="selectedDakafwerking.id === '62e7c729de63cb0cfe032453'"> -->
  <section 
    v-if="insulation.length > 1"
    id="mainsection"
  >    
    <OptionCard 
      v-for="(ins, index) in insulation" 
      :key="index"
      :data="ins" 
      :selected="selectedInsulation === ins.code"
      :has-automargin="false"
      @select="setSelectedInsulation(ins)"
    />
  </section>
  <section 
    v-else
    id="mainsectionsolo"
  >    
    <OptionCard 
      v-for="(ins, index) in insulation" 
      :key="index"
      :data="ins" 
      :selected="selectedInsulation === ins.code"
      :has-automargin="true"
      :has-widthlimit="false"
      @select="setSelectedInsulation(ins)"
    />
  </section>
  <!-- </div> -->
</template>
<style lang="scss" scoped>
#mainsection {
  // margin: 1rem;
  // margin: 1rem 1rem 1rem 3px;
  display: grid;
  padding: 25px 0px;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
  // width: 100%;
}
#mainsectionsolo {
  padding: 25px 0px;
}
</style>