<template>
  <div class="expand">
    <div class="expand-header" @click="expanded = !expanded">
      <h3>{{ title }}</h3>

      <button class="expand-header-button"  :class="expanded ? 'expand-header-button-open' : ''" >
        <mdicon class="expand-header-button-icon" name="chevron-down" size="30" />
      </button>
    </div>

    <transition name="expand" style=""
      enter-active-class="enter-active"
      leave-active-class="leave-active"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <div class="expand-content" v-show="expanded">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      title: String
    },

    data () {
      return {
        expanded: true,
      }
    },

    methods: {
      beforeEnter(element) {
        requestAnimationFrame(() => {
          if (!element.style.height) {
            element.style.height = '0px';
          }

          element.style.display = null;
        });
      },

      enter(element) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            element.style.height = `${element.scrollHeight}px`;
          });
        });
      },

      afterEnter(element) {
        element.style.height = null;
      },

      beforeLeave(element) {
        requestAnimationFrame(() => {
          if (!element.style.height) {
            element.style.height = `${element.offsetHeight}px`;
          }
        });
      },

      leave(element) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            element.style.height = '0px';
          });
        });
      },

      afterLeave(element) {
        element.style.height = null;
      },
    }
  }
</script>

<style lang="scss" scoped>

.expand {
  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 2px solid $c-border-1;
    cursor: pointer;

    & h3 {
      margin: 0px;
      font-size: 1.5rem;
    }

    &-button {
      border: none;
      background-color: transparent;
      transform: rotate(0deg);
      transition: transform 150ms ease;
      border-radius: 50%;

      &-open {
        transform: rotate(180deg);
      }
    }
  }
}

.enter-active,
.leave-active {
  overflow: hidden;
  transition: height 300ms ease;
}
</style>