<template>
  <div class="edit-isotypes">
    <h1>Menu isolatie types</h1>

    <form class="default-form edit-isotypes-form" @submit.prevent="">

      <div class="default-form-field">

        <div class="default-form-field-glastypes">
          <div class="default-form-field-glastypes-header default-form-field-glastypes-edit-row">
            <span>Name</span>
            <span>Description</span>
          </div>

          <div v-for="(glastype, i) in isolatietypes" :key="i" class="default-form-field-glastypes-edit-row">
            <input type="text" class="default-form-field-input" v-model.trim="glastype.name">
            <input type="text" class="default-form-field-input" v-model.trim="glastype.description">            
            <button class="default-form-field-button" @click="removeIsolatieType(i)">
              <mdicon name="minus" class="default-form-field-button-icon" size="25" />
            </button>
          </div>

          <button class="default-form-field-button" @click="addIsolatieType()">
            <mdicon name="plus" class="default-form-field-button-icon" size="25" />
          </button>
        </div>
      </div>

      <div class="default-form-button">
        <LoadingButton @click="updateIsolatieTypes()" :loading="loading" :disabled="!formValid">Update</LoadingButton>
      </div>

    </form>
  </div>
</template>

<script>
  import isEqual from 'lodash.isequal'
  import { mapGetters } from 'vuex'
  import LoadingButton from '@/components/inputs/buttons/LoadingButton.vue'

  export default {
    name: "MenuIsolatieTypes",

    components: {
      LoadingButton
    },

    data () {
      return {
        isolatietypes: [],
        formValid: false,
        loading: false,
      }
    },

    methods: {
      // async getMenuGlasTypes () {
      //   await this.$store.dispatch('getMenuGlasTypes')
      //   this.glastypes = JSON.parse(JSON.stringify(this.menuGlasTypes))
      // },
      async getMenuIsolatieTypes () {
        await this.$store.dispatch('getMenuIsolatieTypes')
        this.isolatietypes = JSON.parse(JSON.stringify(this.menuIsolatieTypes))
      },

      async updateIsolatieTypes () {
        this.loading = true

        try {
          await this.$store.dispatch('updateMenuIsolatieTypes', this.isolatietypes)
        } catch (err) {
          console.log(err)
          this.loading = false
        }

        this.loading = false
        this.isolatietypes = JSON.parse(JSON.stringify(this.menuIsolatieTypes))
      },
      
      addIsolatieType () {
        this.isolatietypes.push({
          name: "",
          description: "",
        })
      },

      removeIsolatieType (i) {
        this.isolatietypes.splice(i, 1)
      }
    },

    computed: {
      ...mapGetters({
        // menuGlasTypes: "getMenuGlasTypes"
        menuIsolatieTypes: "getMenuIsolatieTypes"
      }),
    },

    created () {
      this.getMenuIsolatieTypes()
    },

    watch: {
      isolatietypes: {
        deep: true,
        immediate: false,
        handler () {
          if (isEqual(this.isolatietypes, this.menuIsolatieTypes)) {
            this.formValid = false
          } else {
            this.formValid = true
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

.edit-isotypes {
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  min-width: min(100%, 800px);

  &-form {
    padding: 25px 0px;
    gap: 50px;
    min-width: min(100%, 800px);

    &-display {
      border-radius: $radius-s;
      outline: 3px solid $c-background-1;
      outline-offset: -1px;
      height: 29px;
      align-self: center;
    }
  }
}

</style>