<template>
  <div class="steel-page">
    <ComponentCard 
      v-for="(component, index) in filteredComponents"
      :label="component.category"
      :key="index"
      ref="component"
      :data="component"
      :selected="selected ? component.code === selected.code : false"
      :cselected="getComponentSelected(component)"
      :wSelected="selectedWallData"
      :scolor="selectedSettings.color"
      :soption="selectedSettings.option"
      :svariant="selectedSettings.variant"
      :sglasType="selectedSettings.glasType"
      @select="changeSelectedComponent(component, index)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ComponentCard from "@/components/inputs/ComponentCard.vue";

export default {
  components: {
    ComponentCard,
  },
  name: "Steellook",

  computed: {
    ...mapGetters({
      components: "getSteellookComponents",
      selected: 'getSelectedWallComponent',
      selectedSettings: 'getSelectedWallComponentSettings',
      // TODO change names above so they're the same as in SteellookDoors.vue etc
      selectedElement: 'getSelectedElement',
      selectedWall: 'getSelectedWall',
      getMuurInstances: 'getMuurInstances',      
    }),
    selectedWallData() {
      return this.getMuurInstances.find((muur) => muur.group.uuid === this.selectedWall?.wall?.parent?.uuid)
    },
    filteredComponents() {
      return this.components.filter(c => c.category === "Steellook Wand BW")
    }
  },

  methods: {

    changeSelectedComponent (component, index, shouldNotReplaceModel) {        
      const sglasType = this.$refs.component[index].selectedGlasType;
      const sVariant = this.$refs.component[index].selectedVariant;
      const sColor = this.$refs.component[index].selectedColor;
      const sOption = this.$refs.component[index].selectedOption      
      const sLastCode = this.$refs.component[index].lastCodeSelection;

      const settings = {
          glasType: component.glasTypes && component.glasTypes.length>1 ? component.glasTypes[sglasType] : component.glasTypes ? component.glasTypes[0] : null,
          variant: component.variants && component.variants.length>1 ? component.variants[sVariant] : component.variants ? component.variants[0] : null,
          color: component.colors.length>1 ? component.colors[sColor] : component.colors[0],
          option: component.options.length>1 ? component.options[sOption] : component.options[0],
          lastCode: sLastCode,
          hasBetonBand: this.checkKeyWords(component.keyWords) // task 1455
      }      
      console.log("changeSelectedWallComponent", component, this.selectedElement)
      this.$store.dispatch('changeSelectedWallComponent', {component: component, settings: settings})

      if(this.selectedElement !== null && !shouldNotReplaceModel) {
        const muur = this.getMuurInstances.find((muur) => muur.group.uuid === this.selectedElement.parent.parent.uuid)

        this.$store.dispatch('changeElement', {
          obj: this.selectedElement, 
          wall: null, 
          component: this.selected, 
          settings: this.selectedSettings,
          Ewall: muur,
          Elocation: this.selectedElement.metaData.ModelPosition,
          Eside: this.selectedElement.metaData.Side
        })
      }

      // Select steellook that was placed as a wall
      const selectedSteellook = this.getMuurInstances.find((muurInstance) => muurInstance.group.uuid === this.selectedWall?.wall?.parent.uuid)

      // isSteellook and code checks
      if(selectedSteellook?.isBorstwering && !shouldNotReplaceModel){// && this.isSameSet()){

        this.$store.dispatch('changeSelectedSteellookModel', {
          wall: selectedSteellook,
          component: this.selected, 
          settings: this.selectedSettings,
        })        
      }
    },
    checkKeyWords(array){
      // betonband visibility should be set to false for the following key word:
      // "noBetonband"
      // where any value other than -1 means that the key word has been found
      if (array && array.indexOf("noBetonband") > -1) { return false; } 
      else return true;
    },

    async getComponents () {
      if (this.components.length === 0) {
        let categories = ['Steellook']
        let trueOnline = true

        // this.$store.dispatch('getSteellookComponents', {categories: categories, trueOnline: trueOnline})
        await Promise.all([
          this.$store.dispatch('getSteellookComponents', {categories: categories, trueOnline: trueOnline}),
          this.$store.dispatch('getGlassVariantsMain', {categories: categories, trueOnline: trueOnline})
        ])
      }
    },

    getComponentSelected (component) {
      return false//this.selectedWallData?.borstweringComponent?.component === component
    },
  },

  async mounted () {
    if(this.components.length == 0) await this.getComponents();
    if (this.selectedElement == null && !this.selectedWallData?.isBorstwering) {
      this.changeSelectedComponent(this.filteredComponents[0], 0, true);
    }
    // select current steellook in steellook met borstwering
    if (this.selectedWallData?.isBorstwering) {
      const component = this.selectedWallData.borstweringComponent.component
      const modelCode = this.selectedWallData.borstweringComponent.modelCode
      const settings = {
        glasType: component.glasTypes ? component.glasTypes.find(g => g.code === modelCode) || component.glasTypes[0] || null : null,
        variant: component.variants ? component.variants?.find(v => v.code === modelCode) || component.variants[0] || null : null,
        color: component.colors ? component.colors?.find(c => c.code === modelCode) || component.colors[0] || null : null,
        lastCode: modelCode,
        hasBetonBand: this.checkKeyWords(component.keyWords) // task 1455
      }
      console.log("mounted slmbw settings", settings)
      this.$store.dispatch('changeSelectedWallComponent', {component, settings})
    }
  },
};
</script>

<style lang="scss" scoped>

.steel-page {
  display: grid;
  padding: 25px 0px;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
}

</style>